import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cross from "assets/Icons/Assessment/greyCross.svg";
import PreviousBtn from "assets/Icons/Assessment/PreviousBtn.svg";
import startArrow from "assets/Icons/MyHackathons/startArrow.svg";
import AddQuestion from "assets/Icons/Assessment/AddQuestion.svg";
import RemoveQuestion from "../../../../assets/Icons/Assessment/RemoveQuestion.svg";
import { timer } from "SharedMethods/CommenMethods";
const tm = require("markdown-it-texmath");
const md = require("markdown-it")({
  html: true,
  typographer: true,
}).use(tm, {
  engine: require("katex"),
  delimiters: "dollars",
  katexOptions: { macros: { "\\RR": "\\mathbb{R}" } },
});

export default function QuestionPreviewPopup(props) {
  const {
    questionType,
    currentQuestion,
    setCurrentQuestion,
    questions,
    setOpenPreview,
    isQuestionSelected,
    handleRemoveQuestions,
    removedQuestions,
    handleScroll
  } = props;
  const selectedQuestion = questions[currentQuestion];

  const onPagination = () => {
    if(currentQuestion === questions.length - 1){
      handleScroll(null,true);
      setCurrentQuestion((prev) => ++prev)
    }
  }

  return (
    <>
      <Modal
        show={props.show}
        className="ModalQuestionPreview"
        onHide={() => setOpenPreview(false)}
      >
        <Modal.Header className="QuestionModalHeader">
          <div>
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center flex-wrap">
                  <h3
                    className="QuestionTitle"
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedQuestion?.title || selectedQuestion?.questionTitle
                          ? md.render(
                            selectedQuestion?.title ||
                            selectedQuestion?.questionTitle
                          )
                          : md.render(""),
                    }}
                  ></h3>

                  <span
                    className={`QuestionDiffculty ${selectedQuestion?.difficulty?.toLowerCase() === "beginner"
                      ? "EasyQuestion"
                      : selectedQuestion?.difficulty?.toLowerCase() ===
                        "intermediate"
                        ? "QuestionListIntermediateTypeBtn"
                        : "QuestionListAdvancedTypeBtn"
                      }`}
                  >
                    {selectedQuestion?.difficulty}
                  </span>
                </div>
                {questionType === "mcq" && <h3
                  className="QuestionTitle"
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedQuestion?.description
                        ? md.render(
                          selectedQuestion?.description
                        )
                        : md.render(""),
                  }}
                ></h3>}
              </div>

              <img
                src={Cross}
                className="mx-1 cursor-pointer"
                onClick={() => setOpenPreview(false)}
              />
            </div>
            <div className="d-flex ModalQuestionDetails align-items-center">
              {/* <p>
                <span>Score:</span>
                <span>{selectedQuestion?.score}</span>
              </p> */}
              <p>
                <span>Recommended Time: </span>
                <span>
                  {timer(
                    selectedQuestion?.duration || selectedQuestion?.time,
                    "words"
                  )}{" "}
                </span>
              </p>
              {selectedQuestion?.skills?.length > 0 && (
                <p className="d-flex flex-wrap align-items-center">
                  <span>Skills:</span>
                  {selectedQuestion.skills.map((skill, index) => (
                    <span className="skillsPreview" key={index}>
                      {skill}
                    </span>
                  ))}
                </p>
              )}
              <p>
                {(selectedQuestion?.question_set?.title ||
                  selectedQuestion?.question_set?.title) && <span>Type: </span>}
                <span>
                  {questionType === "mcq"
                    ? selectedQuestion?.question_set?.title
                    : selectedQuestion?.categoryId?.title}
                </span>
              </p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {questionType === "coding" ? (
            <div
              style={{ lineHeight: "20.06px" }}
              className="paragraph codeHTML pt-3"
              dangerouslySetInnerHTML={{
                __html: selectedQuestion?.description,
              }}
            ></div>
          ) : (
            <div>
              {/* <span className="px-2 mt-2 d-block paragraph">
                {selectedQuestion?.title}
              </span> */}
              <div className="pt-4">
                {selectedQuestion?.options?.map((optionObject) => (
                  <div
                    className="ModalQuestionPreviewOption ms-4 d-flex align-items-center pb-4"
                    key={optionObject?._id}
                  >
                    <input
                      type="radio"
                      checked={optionObject.is_correct}
                      disabled
                    />
                    <span className="ms-3">{optionObject.option}</span>
                  </div>
                ))}
              </div>
              <div className="ModalQuestionPreviewDivider mt-3"></div>
            </div>
          )}
          <div className="pt-4 pb-2 d-flex justify-content-between">
            <div className="">
              {currentQuestion === 0 ? (
                <button className="leftarrowbtn me-3">
                  <img src={PreviousBtn} className="img-fluid" alt="prev" />
                </button>
              ) : (
                <button
                  className="leftarrowbtn me-3"
                  onClick={() => setCurrentQuestion((prev) => --prev)}
                >
                  <img src={PreviousBtn} className="img-fluid" alt="prev" />
                </button>
              )}
              {currentQuestion === questions.length - 1 ? (
                <button className="rightarrowbtn" onClick={onPagination}>
                  <img src={startArrow} className="img-fluid" alt="next" />
                </button>
              ) : (
                <button
                  className="rightarrowbtn"
                  onClick={() => setCurrentQuestion((prev) => ++prev)}
                >
                  <img src={startArrow} className="img-fluid" alt="next" />
                </button>
              )}
            </div>
            {isQuestionSelected && props.selectedQuestion.length === 0 ? (
              <button
                className={`secondaryOutlineBtn py-2 px-4 ${!removedQuestions?.includes(selectedQuestion?._id)
                  ? "disabledRemoveQestionBtn"
                  : ""
                  }`}
                onClick={(e) => handleRemoveQuestions(e, selectedQuestion)}
              >
                Remove
                <img
                  src={Cross}
                  alt="add"
                  className="img-fluid ms-2"
                  loading="lazy"
                  width="10"
                />
              </button>
            ) : (
              <button
                className={`secondaryColoredBtn py-2 px-4 ${removedQuestions?.includes(selectedQuestion?._id)
                  ? `disabledQestionBtn`
                  : ``
                  }`}
                onClick={(e) =>
                  !selectedQuestion.selected &&
                  handleRemoveQuestions(e, selectedQuestion)
                }
              >
                Add
                <img
                  src={AddQuestion}
                  alt="add"
                  className="img-fluid ms-2"
                  loading="lazy"
                />
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
