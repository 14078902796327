import logo from "../assets/machineHack-logo.jpg";
import { getInnerHTML } from "SharedMethods/CommenMethods";
import { SubscriptionPlanService } from "services/subscription.service";
import { setSubscriptionData } from "store/reducers/subscriptionSlice";
import { store } from "store";
import { userPaymentStatus } from "store/reducers/subscriptionSlice";

const prodConfig = {
  apiKey: "rzp_live_Me4eUrG6InhGxG",
};
const devConfig = {
  apiKey: "rzp_test_52Pv3q2JKauxce",
};
const config =
  process.env.REACT_APP_ENV_TYPE === "production" ? prodConfig : devConfig;
export const initPayment = (data) => {
  var options = {
    key: config.apiKey,
    subscription_id: data?.razorpay_subscription_id,
    interval: data?.interval,
    name: data.name,
    description: getInnerHTML(data.description),
    image: logo,
    handler: function (response) {
      checkPaymentSubscription(response);
    },
    prefill: {
      name: data.Name,
      email: data.Email,
      contact: data.Mobile,
    },
    theme: {
      color: "#F44D5E",
    },
    modal: {
      ondismiss: () => {
        console.log("Payment Cancelled");
      },
    },
  };
  var rzp = new window.Razorpay(options);
  rzp.open();
};

const checkPaymentSubscription = async (request) => {
  try {
    const requestData = {
      razorpay_subscription_id: request.razorpay_subscription_id,
      razorpay_payment_id: request.razorpay_payment_id,
      razorpay_signature: request.razorpay_signature,
    };
    const callback_response =
      await SubscriptionPlanService.subscriptionCallback(requestData);
    if (callback_response?.data) {
      const subscriptionData = JSON.parse(JSON.stringify(callback_response));
      subscriptionData.data.mh_subscription_plan_id = subscriptionData?.data?.planData;
      store.dispatch(userPaymentStatus(true));
      store.dispatch(setSubscriptionData(subscriptionData));
    }
  } catch (err) {
    console.log(err);
  }
};
