import React from "react";

//CSS
import "./interviewStatistics.css";

//ICONS,IMAGES
import ViewMoreIcon from "../../../../assets/Icons/Dashboard/Viewmore_Icon.svg";
import { useHistory } from "react-router-dom";
import positiveGraph from "assets/Icons/DashboardNew/positiveGraph.svg";
import negativeGraph from "assets/Icons/DashboardNew/negativeGraph.svg";
export default function InterviewStatistics({ interviews, isLoading }) {
  const history = useHistory();
  const {
    candidateHired,
    instantInterview,
    totalApplicants,
    interviewsScheduled,
    interviewDeclined,
    interviewsCompleted,
  } = interviews;
  const interviewStatisticsOptions = [
    { label: "Total Applications", value: totalApplicants?.count },
    { label: "Completed", value: interviewsCompleted?.count },
    { label: "Scheduled", value: interviewsScheduled?.count },
    { label: "Instant", value: instantInterview?.count },
    { label: "Cancelled", value: 0 },
    { label: "Declined", value: interviewDeclined?.count },
    { label: "Hired", value: candidateHired?.count },
  ];
  return (
    <div className="col-xl-12 col-lg-12">
      {interviews && (
        <div className="dashboardTotalInterviewSection my-0 mt-2">
          <div className="dashboardInterviewSection">
            <div className="dashboardInterviewImageText">
              <h5>Interview Statistics</h5>
            </div>
            <div className="dashboardMoreInterviewIcons">
              <div
                className="d-flex dashboardInterviewBtn"
                onClick={() => {
                  localStorage.setItem("interviewType", "myIntetviews");
                  history.push("/interviews?type=my-interview");
                }}
              >
                <p className="mb-0 dashboardNormalText">My Interviews</p>
                <img
                  className="img-fluid ml-2"
                  src={ViewMoreIcon}
                  alt="ViewMore Icon"
                />
              </div>
              <div
                className="d-flex dashboardInterviewBtn"
                onClick={() => {
                  history.push("/interviews");
                }}
              >
                <p className="mb-0 dashboardNormalText">All Interviews</p>
                <img
                  className="img-fluid ml-2"
                  src={ViewMoreIcon}
                  alt="ViewMore Icon"
                />
              </div>
            </div>
          </div>

          <div className="dashboardInterviewHld d-flex justify-content-between m-2 my-4">
            {interviewStatisticsOptions.map((option, index) => (
              <div
                key={`interviewStatistics${index}`}
                className={`${
                  index === 0 ? `col-3` : ``
                } dashboardCard dashboardInterviewCard`}
              >
                <p className="dashboardInterviewCardMargin dashboardInterviewText">
                  {option.label}
                </p>
                <h4 className="dashboardInterviewHeading">
                  {option.value < 9 ? `0${option.value}` : option.value}
                </h4>
                {(option.label === "Total Applications" ||
                  option.label === "Completed" ||
                  option.label === "Hired") && (
                  <>
                    {option?.changePercent > 0 ? (
                      <>
                        <img className="me-1" src={positiveGraph} />
                        <span className="positiveGraphText graphText">
                          {option.changePercent}%
                        </span>
                      </>
                    ) : (
                      <>
                        <img className="me-1" src={negativeGraph} />
                        <span className="negativeGraphText graphText">
                          {option.changePercent ? option.changePercent : 0}%{" "}
                        </span>
                      </>
                    )}
                    <span className="graphText">(30 days)</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
