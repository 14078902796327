import moment from "moment";
import React from "react";
import AddQuestion from "../../../../assets/Icons/Assessment/AddQuestion.svg";
import RemoveQuestion from "../../../../assets/Icons/Assessment/RemoveQuestion.svg";
import EditIcon from "assets/Icons/edit-icon.svg";
import { timer } from "SharedMethods/CommenMethods";
import axios from "axios";
import { BootcampBackend } from "backend";
const tm = require("markdown-it-texmath");
const md = require("markdown-it")({
  html: true,
  typographer: true,
}).use(tm, {
  engine: require("katex"),
  delimiters: "dollars",
  katexOptions: { macros: { "\\RR": "\\mathbb{R}" } },
});

export default function QuestionCard(props) {
  const {
    index,
    questionObj,
    questionType,
    removedQuestions,
    handleRemoveQuestions,
    isEdit,
    sectionId,
    questionlistType,
    history,
    setCurrentQuestion,
    setOpenPreview,
    assessmentId
  } = props;

  const removeQuestionFromsection = (e) => {
    e.stopPropagation();
    const requestBody = {
      sectionType: questionType === "mcq" ? "MCQ" : "CODING",
      assessmentId: assessmentId,
      questionsToRemove: [questionObj._id],
    };
    axios
      .put(
        `${BootcampBackend}/enterpriseAssessment/deleteQuestionsFromSection/${sectionId}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        handleRemoveQuestions(e, questionObj);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        onClick={() => {
          setCurrentQuestion(index);
          setOpenPreview(true);
        }}
        key={`questionCard${index}`}
        className="QuestionListTableData cursor-pointer"
      >
        <div className="d-flex align-items-center">
          <span className="me-2 QuestionListTitle">
            {questionType === "mcq" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: questionObj?.title?.slice(0, 90)
                    ? md.render(
                      questionObj?.title?.replace("![image.png](data:image/png;base64,", "").slice(0, 90) + "..."
                    )
                    : md.render(""),
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: questionObj?.questionTitle?.slice(0, 90)
                    ? md.render(
                      questionObj?.questionTitle
                        ?.replace("![image.png](data:image/png;base64,", "")
                        .slice(0, 110) + "..."
                    )
                    : md.render(""),
                }}
              ></span>
            )}
          </span>
          <button
            className={`ms-1 ${questionObj?.difficulty?.toLowerCase() === "beginner"
              ? "QuestionListEasyTypeBtn"
              : questionObj.difficulty.toLowerCase() === "intermediate"
                ? "QuestionListIntermediateTypeBtn"
                : "QuestionListAdvancedTypeBtn"
              }`}
          >
            {questionObj.difficulty.toUpperCase() === "BEGINNER"
              ? "EASY"
              : questionObj.difficulty.toUpperCase()}
          </button>
          <div className="ms-auto">
            {(questionObj?.practiceQuestionType === "custom" ||
              questionObj?.enterpriseMock) &&
              removedQuestions.includes(questionObj?._id) && (
                <img
                  src={EditIcon}
                  className="img-fluid edit-icon me-2"
                  alt="edit"
                  width="25"
                  height="25"
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("questionId", questionObj?._id);
                    history.push(
                      `/assessments/${assessmentId}/update/${questionType}/${sectionId}`
                    );
                  }}
                />
              )}
            {isEdit && questionObj?.isSectionQues && (props?.selectedQuestion?.length === 0 || props?.selectedQuestion?.length === undefined) ? (
              <img
                src={RemoveQuestion}
                alt="remove"
                className={`img-fluid ${!removedQuestions.includes(questionObj._id)
                  ? "disabledRemoveQestionBtn"
                  : ""
                  }`}
                loading="lazy"
                onClick={(e) => removeQuestionFromsection(e)}
              />
            ) : (
              <div
                className={`AddQuestionBtn cursor-pointer ${removedQuestions.includes(questionObj._id)
                  ? `disabledQestionBtn`
                  : ``
                  }`}
                onClick={(e) =>
                  !questionObj.selected && handleRemoveQuestions(e, questionObj)
                }
              >
                <img
                  src={AddQuestion}
                  alt="add"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex mt-2 align-items-center">
          <span className="paragraph me-4">Score: {questionObj.score}</span>
          <span className="paragraph me-4">
            Recommended Time:{" "}
            {timer(questionObj?.duration || questionObj?.time || 0, "words")}
          </span>
          {questionObj?.skills?.length > 0 && (
            <div className="paragraph me-4">
              Skills:
              {questionObj.skills.map((element, i) => (
                <span className="QuestionListSkillData ms-2" key={`title${i}`}>
                  {element}
                </span>
              ))}
            </div>
          )}
          {(questionObj?.question_set?.title ||
            questionObj?.categoryId?.title) && (
              <span className="paragraph">Type: </span>
            )}
          <span className="paragraph questionSetTitle ps-1">
            {" "}
            {questionType === "mcq"
              ? questionObj?.type
              : questionObj?.categoryId?.title}
          </span>
        </div>
      </div>
    </>
  );
}
