import React, { useEffect, useState } from "react";
import "./UpComingInterviews.css";
import ViewReport from "../../../assets/Icons/Interview/New/viewReportIcon.svg";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import { LoadingShape, Pagination } from "react-rainbow-components";
const moment = require("moment");
import ReactStars from "react-rating-stars-component";
import { useHistory } from "react-router-dom";

function CompletedInterviews() {
  const [completedInterviews, setCompletedInterviews] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [interviewStatus, setInterviewStatus] = useState("pending");
  const history = useHistory();

  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const lastItem = activePage * 9;
  const firstItem = lastItem - 9;
  const handleOnChange = (event, page) => {
    setActivePage(page);
  };
  const viewReport = (e, data) => {
    e.stopPropagation();
    history.push(
      `/interviews/interview-report/${data._id}/${data.interviewRounds._id}`
    );
  };
  async function fetchInterviewsCompleted() {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/getAllInterviewsAdminPanel/completed?current_page=${activePage}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setIsPageLoading(false);
          setCompletedInterviews(res.data.result.interviewData);
          setTotalCount(res?.data?.result?.totalCompletedInterviews);
        });
      return res;
    } catch (err) {
      setIsPageLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchInterviewsCompleted();
  }, [activePage]);

  const onCompletedInterviewChange = (interview) => {
    if (interview?.interviewRounds?.interviewStatus == "completed") {
      history.push(
        history.push(`/interviews/interview-process/${interview._id}`)
      );
    }
  };

  return (
    <div>
      {isPageLoading ? (
        <div className="row m-2">
          <div className={`col-lg-12 mt-1`}>
            <LoadingShape style={{ height: "40px" }} />
          </div>
          <div className="col-lg-12 mt-4">
            <LoadingShape style={{ height: "110px" }} />
          </div>
        </div>
      ) : completedInterviews.length > 0 ? (
        <div className="interviewsBoard">
          <div className="table-responsive listTablehld">
            <table className="table px-3 completedInterviewsTable">
              <thead>
                <tr>
                  <th className="list-table-heading" scope="col">
                    INTERVIEW TITLE
                  </th>
                  <th className="list-table-heading text-center" scope="col">
                    CANDIDATE NAME
                  </th>
                  <th className="list-table-heading" scope="col">
                    RATING
                  </th>
                  <th className="list-table-heading" scope="col">
                    SCHEDULED ON
                  </th>
                  <th className="list-table-heading" scope="col">
                    INTERVIEWER NAME
                  </th>

                  <th className="list-table-heading" scope="col">
                    NO. OF ROUNDS
                  </th>
                  <th className="middle-align" scope="col"></th>
                  <th className="middle-align" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {completedInterviews?.map((interview, index) => (
                  <tr
                    key={index}
                    onClick={() => onCompletedInterviewChange(interview)}
                  >
                    <td className="middle-align interviewTabInterviewTitle">
                      <p>{interview?.interviewTitle}</p>
                    </td>
                    <td className="middle-align">
                      <div className="  d-flex align-items-center justify-content-center">
                        &nbsp;&nbsp;
                        <p className="interviewTabCandidateNameOptional">
                          {interview.candidateName
                            ? interview.candidateName
                            : "-"}
                        </p>
                      </div>
                    </td>
                    <td className="middle-align">
                      <ReactStars
                        count={5}
                        value={
                          interview.interviewRounds.interviewerFeedback &&
                          interview.interviewRounds.interviewerFeedback.overAll
                            ? interview.interviewRounds.interviewerFeedback
                                .overAll.ratings
                            : 0
                        }
                        size={16}
                        activeColor="#FF9F4B"
                        edit={false}
                        color="#C4C4C4"
                        className=""
                      />
                    </td>
                    <td className="scheduled-on-table">
                      <div
                        className={
                          interviewStatus == "completed"
                            ? "timings-card-div no-background-timings"
                            : "timings-card-div p-2 pe-4"
                        }
                      >
                        <ul className="timings-card-view ps-0">
                          {interview.currentRoundStatus == "completed" ? (
                            <>
                              {/* Empty because it will create a vertical-align problem for the time */}
                            </>
                          ) : (
                            <li className="awaiting-acceptance mb-2">
                              {interview.currentRoundStatus == "completed" ? (
                                <span
                                  className={`${
                                    interview.interviewRounds
                                      .acceptanceFromCandidate == "rejected"
                                      ? "acceptancyDecline"
                                      : interview.interviewRounds
                                          .acceptanceFromCandidate == "accepted"
                                      ? "acceptancyAccept"
                                      : "acceptancyAwait"
                                  }`}
                                ></span>
                              ) : (
                                <>
                                  <span
                                    className={`${
                                      interview.interviewRounds
                                        .acceptanceFromCandidate == "rejected"
                                        ? "acceptancyDecline"
                                        : interview.interviewRounds
                                            .acceptanceFromCandidate ==
                                          "accepted"
                                        ? "acceptancyAccept"
                                        : "acceptancyAwait"
                                    }`}
                                  >
                                    {interview.interviewRounds
                                      .acceptanceFromCandidate == "rejected"
                                      ? "Declined"
                                      : interview.interviewRounds
                                          .acceptanceFromCandidate == "accepted"
                                      ? "Accepted"
                                      : "Awaiting Acceptance"}
                                  </span>
                                </>
                              )}
                            </li>
                          )}
                          <div className="d-flex align-items-center interviewScheduleDate ">
                            <li className="">
                              {interview.interviewRounds &&
                                moment(
                                  interview.interviewRounds.interviewStartTime
                                ).format("h:mm")}{" "}
                              -{" "}
                              {interview.interviewRounds &&
                                moment(
                                  interview.interviewRounds.interviewEndTime
                                ).format("h:mm   a")}
                            </li>
                            <li className="interviewDateDot"></li>
                            <li className="d">
                              {interview.interviewRounds &&
                                moment(
                                  interview.interviewRounds.interviewStartTime
                                ).format("D MMM YYYY")}
                            </li>
                          </div>
                        </ul>
                      </div>
                    </td>
                    <td className="middle-align interviewTabInterviewName ps-5">
                      <p>
                        {interview.interviewRounds.interviewer.firstName}&nbsp;
                        {interview.interviewRounds.interviewer.lastName}
                      </p>
                    </td>
                    <td className="no-of-rounds-table font-size ">
                      <button className="no-of-rounds new-background NoOfRoundHld  ">
                        Round&nbsp;{interview?.interviewRounds?.round}
                      </button>
                    </td>
                    <td></td>
                    <td className="middle-align pe-4">
                      <button
                        className={` reportBtn ${
                          (interviewStatus == "completed" &&
                            interview?.interviewRounds
                              .candidateInterviewStatus == "selected") ||
                          interview?.interviewRounds.candidateInterviewStatus ==
                            "passed"
                            ? "greenBtnBackground"
                            : "redBtnBackground"
                        }
                                    `}
                        style={{
                          background: `${
                            moment().isAfter(
                              interview?.interviewRounds.interviewStartTime
                            ) &&
                            moment().isBefore(
                              interview?.interviewRounds.interviewEndTime
                            )
                              ? ""
                              : ""
                          }`,
                        }}
                        onClick={(e) => viewReport(e, interview)}
                      >
                        View Report{" "}
                        <img
                          src={ViewReport}
                          className="reportIcon ms-2 img-fluid"
                          alt="logo"
                          loading="lazy"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {completedInterviews ? (
            <div className="interviewPagination py-3">
              <Pagination
                className="rainbow-m_auto"
                pages={totalCount > 0 ? parseInt(totalCount / 9) + 1 : 1}
                activePage={activePage}
                onChange={handleOnChange}
                variant="shaded"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <h4 className="d-flex align-items-center justify-content-center my-5">
          No Interviews
        </h4>
      )}
    </div>
  );
}

export default CompletedInterviews;
