import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import arrowWhite from "assets/Icons/Assessment/arrowWhite.svg";
import Logo from "assets/Logos/MH_Logo.svg";
import { timer } from "SharedMethods/CommenMethods";
import { useSelector } from "react-redux";

export default function AssessmentPreviewModal(props) {
  const {
    showModal,
    handleModalClose,
    formData,
    AllSectionsSummary,
    SaveChanges,
  } = props;
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const getPlaceholder = (data) => {
    switch (data?.fieldName?.toLowerCase()) {
      case "name":
        return "Eg. Rahul";
      case "currentcompany":
        return "Eg. ABC Technologies";
      case "currentctc":
        return "Eg. 5";
      case "noticeperiod":
        return "Eg. 45";
      case "resume_key":
        return "Drag and drop your Resume file";
      case "experience":
        return "Eg. 5";
      case "latestdesignation":
        return "Eg. Full Stack Developer";
      case "expectingCtc":
        return "Eg. 6";
      case "city":
        return "Eg. Bangalore";
      case "frombangalore":
        return "Eg. YES";
      default:
        return "Eg. 10";
    }
  };
  function redirectToMachineHack() {
    window.open("https://machinehack.com", "_blank");
  }
  const redirectToProfile = () => {
    history.push("/settings/editProfile");
  };
  return (
    <Modal
      size="xl"
      centered="true"
      show={showModal}
      onHide={handleModalClose}
      className="assessmentSettingModalBody"
    >
      <Modal.Header closeButton className="py-2">
        <Modal.Title className="assessmentModalHeader col-lg-7 d-flex justify-content-between">
          {user?.company?.logo ? <img
            onClick={redirectToProfile}
            className="company-logo"
            src={user?.company?.logo}
            alt="logo"
          /> : <></>}
          <img
            onClick={redirectToMachineHack}
            className=""
            src={Logo}
            alt="logo"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="assessmentSettingModal">
        <div className="d-flex">
          <div className="col-4 d-flex flex-column justify-content-center me-3 pe-4">
            <span className="assessmentModalGreetTag mb-4">Hey user,</span>
            <span className="assessmentModalTestName col-9 mb-3">
              Welcome to {formData?.title}
            </span>
            <span className="assessmentModalTestDuration me-2 mb-5">
              by Applied Data Science
            </span>
            <div className="d-flex mb-3 align-items-center">
              <span className="assessmentModalTestDuration me-2">
                Test duration
              </span>
              <span className="assessmentModalTestTime">
                {timer(AllSectionsSummary?.totalTime, "words")}
              </span>
            </div>
            <div className="w-100 assessmentSettingGeneralDivider mb-3"></div>
            <div className="d-flex justify-content-between mb-5">
              <div className="d-grid">
                <span className="assessmentModalTestDuration">
                  No. of questions
                </span>
                <span className="assessmentModalTestTime">
                  {AllSectionsSummary?.totalQuestions} questions
                </span>
              </div>
              <div className="d-grid">
                <span className="assessmentModalTestDuration">Total MCQ</span>
                <span className="assessmentModalTestTime">
                  {AllSectionsSummary?.totalMcq} questions
                 
                </span>
              </div>
              <div className="d-grid">
                <span className="assessmentModalTestDuration">
                  Total Coding
                </span>
                <span className="assessmentModalTestTime">
                  {AllSectionsSummary?.totalCoding} questions
                </span>
              </div>
            </div>
            <span className="assessmentModalTestTime mb-2">Skills Covered</span>
            <div className="d-flex flex-wrap">
              {AllSectionsSummary?.allSkills?.map((skill, index) => (
                <p className="assessmentModalSKillTag m-1" key={index}>
                  {skill}
                </p>
              ))}
            </div>
          </div>
          <div className="col-8 d-flex flex-column assessmentModalInputSection ps-5 pe-5">
            <span className="assessmentModalTestTime mb-2 mt-2">
              Instructions
            </span>
            <div
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: formData?.testInstructions,
              }}
            ></div>
            {/* <span className="assessmentModalIntroduction mb-2">
              1. This is a timed test. Please make sure you are not interrupted
              during the test, as the timer cannot be paused once started.
            </span>
            <span className="assessmentModalIntroduction mb-2">
              2. Please ensure you have a stable internet connection.
            </span>
            <span className="assessmentModalIntroduction mb-2">
              3. We recommend you to try the sample test for a couple of
              minutes, before taking the main test.
            </span> */}
            <span className="assessmentModalTestTime mb-2">
              Confirmation Form
            </span>
            <span className="assessmentModalIntroduction mb-3">
              Before we start, here is some extra information we need to assess
              you better.
            </span>
            <div className="assessmentModalInputField row mb-4">
              {formData?.Fields?.map((field, index) => (
                <div className="col-6" key={index}>
                  <label className="assessmentModalInputLabel mb-1 d-flex">
                    {field?.label}
                    {field?.isMandate && (
                      <label className="assessmentModalMandatoryLabel">*</label>
                    )}
                  </label>

                  <input
                    placeholder={getPlaceholder(field)}
                    className={`mb-3 ${
                      field.fieldName === "Resume_key"
                        ? "resumehld"
                        : "formInput"
                    }`}
                    disabled
                  />
                </div>
              ))}
            </div>
            <span className="assessmentModalIntroduction mb-3 me-2">
              {formData?.testDisclaimer}
            </span>
            <div className="assessmentModalButton">
              <button className="primaryBtnActive mb-4" onClick={SaveChanges}>
                Continue{" "}
                <img
                  className="ms-3 img-fluid"
                  src={arrowWhite}
                  alt="continue"
                  loading="lazy"
                />
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
