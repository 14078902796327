import React, { useState } from "react";
//CSS
import "../DashboardPage/Dashboard.css";
import "./AdminView.css";
//LOGO and ICONS

//COMPONENTS
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";

//LIB
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import Companies from "./Companies";
import Jobs from "./Jobs";
import Interview from "./Interview";
import Assessment from "./Assessment";
import { useLocation } from "react-router";
import SubscriptionPlans from "./SubscriptionPlans/SubscriptionPlans";
import { useSelector } from "react-redux";
import Invoices from "./SubscriptionPlans/Invoices";
import { useSidebar } from "helper/SideBarContext";
SwiperCore.use([Navigation]);

function AdminView() {
  const user = useSelector((state) => state.auth.user);
  const { isSidebarCollapsed } = useSidebar();
  const location = useLocation();
  const currentRoute = location.pathname.split("/")[2];
  var myDate = new Date();
  var hrs = myDate.getHours();

  var greet;

  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

  return (
    <div>
      <Header />
      <div className="container-fluid body-margin-top px-0">
        <div className="dashboard ">
          <Navbar />
          <div
            className={`${
              isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
          >
            <div className="">
              {currentRoute !== "plans" && currentRoute !== "invoices" ? (
                <h5 className="goodMorning mx-3">
                  {greet} {user?.firstName}!
                </h5>
              ) : (
                <></>
              )}
              {currentRoute === "companies" ? (
                <>
                  <Companies />
                </>
              ) : null}
              {currentRoute === "jobs" ? (
                <>
                  <Jobs />
                </>
              ) : null}
              {currentRoute === "interviews" ? (
                <>
                  <Interview />
                </>
              ) : null}
              {currentRoute === "assessments" ? (
                <>
                  <Assessment />
                </>
              ) : null}
              {currentRoute === "plans" ? <SubscriptionPlans /> : <></>}
              {currentRoute === "invoices" ? <Invoices /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminView;
