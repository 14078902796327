
import io from "socket.io-client";
import { Socket_Connect_URL } from "../backend";

export const socket = io.connect(Socket_Connect_URL, {
  // secure: true,
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
  // rememberTransport: false
  // reconnection: false,
  // reconnectionDelay: 50000,
  // reconnectionLimit: 10,
  // timeout: 1000,
});
