import React, { useEffect, useState } from "react";
import "./Companies.css";
import User from "../../../assets/Icons/AdminView/User.svg";
import Email from "../../../assets/Icons/AdminView/Frame.svg";
import MoreIcon from "../../../assets/Icons/Dashboard/more.svg";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import { getUserRole } from "../../../helper/user";
import ErrorIcon from "../../../assets/Icons/warning.svg";
import NocompanyOne from "../../../assets/Icons/AdminView/NocompanyOne.svg";
import PremiumIcon from "../../../assets/Icons/Pricing/crown.svg";
const moment = require("moment");
import { LoadingShape, Pagination } from "react-rainbow-components";
import AddSubscriptionPopup from "./SubscriptionPlans/AddSubscriptionPopup";

// https:/bootcamp.futurekrux.com/iamUser/edit/6226d0c084c2fe0ef28083ee

function Companies() {
  const [show, setShow] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [usersTableData, setUsersTableData] = useState([]);
  const [allCompanies, SetAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [showAddSubscriptionPopup, setShowAddSubscriptionPopup] =
    useState(false);
  const [companySubscriptionData, setCompanySubscriptionData] = useState({});
  const lastItem = activePage * 8;
  const firstItem = lastItem - 8;
  const handleOnChange = (event, page) => {
    setActivePage(page);
  };

  async function fetchSetSelectedCOmpany(company) {
    setSelectedCompany(company);
  }

  const formatDate = (dateTime) => {
    var string = dateTime;
    var temp = string.split("T");

    var strdate = temp[0].split("-");
    var year = strdate[0];
    var month = strdate[1];
    var day = strdate[2];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("DD MMM YYYY");

    if (formattedDate === "Invalid date") return "";

    return formattedDate;
  };

  const userId = JSON.parse(localStorage.getItem("jwt")).userId
    ? JSON.parse(localStorage.getItem("jwt")).userId
    : null;
  const [menu, setMenu] = useState({
    _id: null,
  });

  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    role: "",
  });

  const [editUserData, setEditUserData] = useState(selectedUser);

  const [userError, setUserError] = useState({
    emailId: false,
    firstName: false,
    lastName: false,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    validation: false,
    passwordMatch: false,
  });

  const [submitError, setSubmitError] = useState({
    email: false,
    role: false,
  });

  function handleChangePassword(event) {
    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    const { name, value } = event.target;
    setPasswordData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleChangeEditUser(event) {
    setUserError({
      emailId: false,
      firstName: false,
      lastName: false,
    });

    const { name, value } = event.target;
    setEditUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleTableButtonClick(cell) {
    setSelectedUser({
      _id: cell._id,
      firstName: cell.lastName,
      lastName: cell.firstName,
      emailId: cell.emailId,
      phoneNumber: cell.phoneNumber,
      role: cell.role,
    });
    if (menu._id === null) {
      setMenu({
        _id: cell._id,
      });
    } else {
      setMenu({
        _id: null,
      });
    }
  }

  function showRemoveUserModal() {
    setRemoveUserModal(true);
    setMenu({
      _id: null,
    });
  }

  function showResetPasswordModal() {
    setResetPasswordModal(true);
    setMenu({
      _id: null,
    });
  }

  function showEditUserModal() {
    setEditUserModal(true);
    setMenu({
      _id: null,
    });
  }

  function hideRemoveUserModal() {
    setRemoveUserModal(false);
  }

  function hideResetPasswordModal() {
    setResetPasswordModal(false);

    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    setPasswordData({
      password: "",
      confirmPassword: "",
    });
  }

  function hideEditUserModal() {
    setEditUserModal(false);

    setUserError({
      firstName: false,
      lastName: false,
      emailId: false,
    });

    setSubmitError({
      email: false,
      role: false,
    });

    setSelectedUser({
      _id: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      role: "",
    });
  }

  async function handleDeleteUser() {
    try {
      const res = await axios.delete(
        `${BootcampBackend}/iamUser/revoke/${selectedUser._id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      hideRemoveUserModal(true);
    } catch (err) {
      console.log(err.response);
    }
  }

  async function handleResetPasswordSubmit(event) {
    try {
      event.preventDefault();
      const regPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

      if (!regPassword.test(passwordData.password)) {
        setPasswordError({
          validation: true,
        });
        return;
      }
      if (passwordData.password !== passwordData.confirmPassword) {
        setPasswordError({
          passwordMatch: true,
        });
        return;
      }
      const formData = {
        _id: selectedUser._id,
        password: passwordData.password,
      };

      const res = await axios
        .put(
          `${BootcampBackend}/adminPanel/editIamUserAdmin/${selectedCompany?._id}/${selectedUser._id}`,
          formData,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          if (res.data.result.message === "Success") {
            fetchIamUserData(selectedCompany?._id);
          }
        });
      hideResetPasswordModal(true);
    } catch (err) {
      console.log(err.response);
    }

    //setPasswordSuccess(true);
  }

  useEffect(() => {
    setEditUserData(selectedUser);
  }, [selectedUser]);

  async function fetchIamUserData(_id) {
    try {
      const res = await axios
        .get(`${BootcampBackend}/adminPanel/iamUserPerCompany/${_id}`, {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((res) => {
          setUsersTableData(res.data.result.data);
          setCompanySubscriptionData(res.data.result?.subscriptionData);
        });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, [activePage]);

  async function fetchCompanies() {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/getAllCompaniesAdminPanel?current_page=${activePage}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setIsPageLoading(false);
          var takeselectedCompany = res.data.result.data[0];
          setSelectedCompany(takeselectedCompany);
          setAnotherColor(res.data.result.data[0]._id);
          SetAllCompanies(res.data.result.data);
          setTotalCount(res.data.result.totalCount);
          fetchIamUserData(res.data.result.data[0]._id);
          setUsersTableData(res.data.result.data[0]);
        });
      return res;
    } catch (err) {
      setIsPageLoading(false);
      console.log(err);
    }
  }

  async function handleEditUserSubmit(event) {
    try {
      event.preventDefault();
      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!regName.test(editUserData.firstName)) {
        setUserError({
          firstName: true,
        });
        return;
      }
      if (!regName.test(editUserData.lastName)) {
        setUserError({
          lastName: true,
        });
        return;
      }

      if (!regEmail.test(editUserData.emailId)) {
        setUserError({
          emailId: true,
        });
        return;
      }

      const formData = {
        firstName: editUserData.firstName,
        lastName: editUserData.lastName,
        role: editUserData.role,
        phoneNumber: editUserData.phoneNumber,
      };

      const res = await axios
        .put(
          `${BootcampBackend}/adminPanel/editIamUserAdmin/${selectedCompany?._id}/${selectedUser._id}`,
          formData,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          if (res.data.result.message === "Success") {
            fetchIamUserData(selectedCompany?._id);
          }
        });
      hideEditUserModal(true);
    } catch (err) {
      console.log(err.response);
      if (err.response.statusText === "Conflict") {
        setSubmitError({
          email: true,
        });
      }
    }
  }

  const countDownTimer = (timer) => {
    const currentDate = moment(new Date());
    let timeDiff = moment.duration(currentDate.diff(moment(timer)));
    timeDiff = timeDiff.asMinutes();
    timeDiff = moment.duration({ minutes: timeDiff }).humanize();

    return timeDiff + " ago";
  };

  const [anotherColor, setAnotherColor] = useState("");
  const changeColor = (id) => {
    setAnotherColor(id);
  };

  return (
    <div className="row g-0">
      {isPageLoading ? (
        <div className="row m-2">
          <div className={`col-lg-12 mt-1`}>
            <LoadingShape style={{ height: "40px" }} />
          </div>
          <div className="col-lg-12 mt-4">
            <LoadingShape style={{ height: "110px" }} />
          </div>
        </div>
      ) : allCompanies.length > 0 ? (
        <>
          <>
            <div className="col-md-4 companiesBoard">
              <div className="totalTabs">
                <p className="totalCompanies m-2 mx-3">
                  Total Companies({totalCount})
                </p>
                {allCompanies &&
                  allCompanies?.map((company) => (
                    <div
                      key={company?._id}
                      onClick={() => fetchSetSelectedCOmpany(company)}
                    >
                      <div
                        key={company?._id}
                        className="eachCompany d-flex align-items-center pb-3"
                        style={{
                          backgroundColor:
                            anotherColor === company._id ? "#FFFFFF" : "",
                          boxShadow:
                            anotherColor === company._id
                              ? "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
                              : "",
                          zIndex: anotherColor === company._id ? "1" : "0",
                          borderBottom:
                            anotherColor === company._id
                              ? ""
                              : "1px solid #e4e4e5",
                        }}
                        onClick={() => {
                          fetchIamUserData(company?._id),
                            changeColor(company?._id);
                        }}
                      >
                        <div className="eachCompanyImage">
                          <img
                            className="img-fluid companyImage"
                            src={company?.logo ? company?.logo : NocompanyOne}
                            alt="CompanyImage"
                          />
                        </div>
                        <div className="col-10">
                          <p className="creativeVenus">
                            {company?.companyName}
                          </p>
                          <div className="d-flex">
                            <div className="d-flex emailContainer">
                              <div className="email-emailId">
                                <img
                                  className="img-fluid CompanyTabEmailImage"
                                  src={Email}
                                  alt="CompanyImage"
                                />
                              </div>
                              <div>
                                <p className="email">Email id</p>
                                <p className="email-id">
                                  {`${
                                    company?.contactEmail
                                      ? company.contactEmail.length > 5
                                        ? company?.contactEmail.slice(0, 5) +
                                          "..."
                                        : company.contactEmail
                                      : "-----"
                                  }`}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex userContainer ms-auto">
                              <div className="email-emailId">
                                <img
                                  className="img-fluid CompanyTabEmailImage"
                                  src={User}
                                  alt="CompanyImage"
                                />
                              </div>
                              <div>
                                <p className="email">Last Login</p>
                                <p className="email-id">
                                  {/* {moment(company?.lastLoginActivity).fromNow("HH")
                            ? moment(company?.lastLoginActivity).fromNow("HH")
                            : ""} */}
                                  {company?.lastLoginActivity
                                    ? countDownTimer(company?.lastLoginActivity)
                                    : countDownTimer(
                                        company?.timestamps.updated_at
                                      )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {allCompanies ? (
                <div className="jobPagination py-3">
                  <Pagination
                    className="rainbow-m_auto"
                    pages={totalCount > 0 ? parseInt(totalCount / 8) + 1 : 1}
                    activePage={parseInt(activePage)}
                    // setActivePage={parseInt(activePage)}
                    onChange={handleOnChange}
                    variant="shaded"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
          <>
            <div className="col-md-8 usersBoard px-0">
              <div className="d-flex align-items-center justify-content-between deleteCompanySection px-3 py-3">
                <h6>{selectedCompany?.companyName}</h6>
                <button
                  className="premium-btn d-flex align-items-baseline plansHld"
                  onClick={() =>
                    setShowAddSubscriptionPopup(!showAddSubscriptionPopup)
                  }
                >
                  {companySubscriptionData?.mh_subscription_plan_id?.name !==
                  "basic" ? (
                    <img
                      src={PremiumIcon}
                      alt="premium"
                      className={`img-fluid me-2 ${
                        companySubscriptionData ? "" : "company-premium-icon"
                      }`}
                    />
                  ) : (
                    <></>
                  )}
                  {companySubscriptionData?._id ? (
                    <>
                      <p className="subheadingText text-capitalize">
                        {companySubscriptionData?.mh_subscription_plan_id?.name}
                      </p>
                      <p className="mb-0 pricingText ms-2">
                        <span className="subheadingText">
                          {companySubscriptionData?.mh_subscription_plan_id
                            ?.currencyType === "INR"
                            ? "₹"
                            : "$"}
                        </span>{" "}
                        {
                          companySubscriptionData?.mh_subscription_plan_id
                            ?.amount
                        }
                        <span className="subheadingText">
                          /
                          {companySubscriptionData?.mh_subscription_plan_id
                            ?.period === "monthly"
                            ? "mth"
                            : "yr"}
                        </span>
                      </p>
                    </>
                  ) : (
                    "Add Subscription"
                  )}
                </button>
              </div>

              <div className="d-flex align-items-center justify-content-between px-3 py-3 amUsers">
                <h6>IAM users ({usersTableData?.length})</h6>
              </div>

              <div className="table-responsive-xl">
                <table className="table companyTable ">
                  <thead>
                    <tr>
                      <th
                        className="py-3 companyTabTableHead middle-align ps-3"
                        scope="col"
                      >
                        <p>Name</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      >
                        <p>Email&nbsp;Id</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      >
                        <p>Phone&nbsp;Number</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      >
                        <p>Role</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      >
                        <p>Joined&nbsp;on</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      >
                        <p>Last&nbsp;Activity</p>
                      </th>
                      <th
                        className="py-3 middle-align companyTabTableHead"
                        scope="col"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTableData.length > 0 &&
                      usersTableData.map((dataPerson) => (
                        <tr key={dataPerson._id} className="getModal">
                          <td className="middle-align d-flex align-items-center ps-3 companyTabUserTable">
                            <div className="userIcon d-flex align-items-center justify-content-center">
                              <h6 className="userFirstLastIcon">
                                {dataPerson?.firstName[0].toUpperCase()}
                                {dataPerson?.lastName[0].toUpperCase()}
                              </h6>
                            </div>
                            <p className="ms-1 userFirstLastName">
                              &nbsp;&nbsp;&nbsp;&nbsp;{dataPerson?.firstName}
                              {dataPerson?.lastName}
                            </p>
                          </td>
                          <td className="middle-align companyTabUserTable userRemainingData">
                            {dataPerson?.emailId}
                          </td>
                          <td className="middle-align companyTabUserTable userRemainingData">
                            {dataPerson?.phoneNumber}
                          </td>
                          <td className="middle-align companyTabUserTable userRemainingData">
                            {dataPerson?.role == "super_admin"
                              ? "Super_Admin"
                              : ""}
                            {dataPerson?.role == "admin" ? "Admin" : ""}
                            {dataPerson?.role == "admin_panel"
                              ? "Admin_Panel"
                              : ""}
                          </td>
                          <td className="middle-align companyTabUserTable userRemainingData">
                            {dataPerson.timestamps.created_at &&
                              formatDate(dataPerson?.timestamps?.created_at)}
                          </td>
                          <td className="middle-align companyTabUserTable userRemainingData">
                            {dataPerson?.lastLoginActivity
                              ? formatDate(dataPerson?.lastLoginActivity)
                              : formatDate(dataPerson?.timestamps?.updatedAt)}
                          </td>
                          <td className="middle-align">
                            <img
                              src={MoreIcon}
                              className="companyTableMenu"
                              alt="more Icon"
                              onClick={() => handleTableButtonClick(dataPerson)}
                            />
                            <div
                              id={dataPerson._id}
                              className={`tableMenu ${
                                menu._id === dataPerson._id ? "menuActive" : ""
                              } menuSection`}
                            >
                              <div
                                className="reset insideMenu"
                                onClick={showResetPasswordModal}
                              >
                                <p>Reset Password</p>
                              </div>
                              <div
                                className="edit insideMenu"
                                onClick={showEditUserModal}
                              >
                                <p>Edit User</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        </>
      ) : (
        <h4 className="d-flex align-items-center justify-content-center my-5">
          No Companies
        </h4>
      )}

      <Modal
        centered="true"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-custom-modal-styling-title"
      >
        {" "}
        {/* reusing from IAM table */}
        <Modal.Header closeButton>
          {/* <Modal.Title>Are you sure ?</Modal.Title> */}
          <Modal.Title id="example-custom-modal-styling-title">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="revokeUserDialog">
            <div>
              <p>{`Are you sure, you want to delete the company? `}</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div>
                <button type="submit" className="modalButtonSubmit mx-2">
                  Confirm
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleClose}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        dialogClassName="removeUserModal"
        show={removeUserModal}
        onHide={hideRemoveUserModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="revokeUserDialog">
            <div>
              <p>{`You are removing ${
                selectedUser.firstName + " " + selectedUser.lastName
              }  from the user list 
            & he wont be able to access any data`}</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div>
                <button
                  onClick={handleDeleteUser}
                  type="submit"
                  className="modalButtonSubmit mx-2"
                >
                  Confirm
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={hideRemoveUserModal}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        dialogClassName="resetPasswordModal"
        show={resetPasswordModal}
        onHide={hideResetPasswordModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="resetPasswordForm"
            onSubmit={handleResetPasswordSubmit}
          >
            <div className="row">
              <div
                className="col-6 form-group"
                style={{ paddingRight: "10px" }}
              >
                <label htmlFor="password">Password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="password"
                    name="password"
                    value={passwordData.password}
                    placeholder="*****"
                    onChange={handleChangePassword}
                    required
                    className={`form-input ${
                      passwordError.validation ? "inputError" : ""
                    }`}
                  />
                  {passwordError.validation && (
                    <div className="invalid">Invalid format</div>
                  )}
                  {passwordError.passwordMatch && (
                    <div className="invalid">Passwords do not match</div>
                  )}
                  {(passwordError.passwordMatch ||
                    passwordError.validation) && (
                    <img
                      className="inputErrorIcon"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                </div>
              </div>
              <div className="col-6 form-group" style={{ paddingLeft: "10px" }}>
                <label htmlFor="confirmPassword">Confirm password</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    placeholder=""
                    onChange={handleChangePassword}
                    required
                    className={`form-input ${
                      passwordError.passwordMatch ? "inputError" : ""
                    }`}
                  />
                  {passwordError.passwordMatch && (
                    <img
                      className="inputErrorIcon"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                </div>
              </div>
              <div className="form-group my-3  d-flex justify-content-center">
                <button type="submit" className="modalButtonSubmit mx-2">
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={hideResetPasswordModal}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        size="lg"
        dialogClassName="editUserModal"
        show={editUserModal}
        onHide={hideEditUserModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="editUserForm" onSubmit={handleEditUserSubmit}>
            <div className="row">
              <div
                className="col-6 form-group"
                style={{ paddingRight: "20px" }}
              >
                <label htmlFor="firstName">First Name</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    name="firstName"
                    value={editUserData.firstName}
                    placeholder=""
                    onChange={handleChangeEditUser}
                    required
                    className={`form-input ${
                      userError.firstName ? "inputError" : ""
                    }`}
                  />
                  {userError.firstName && (
                    <div className="invalid">Invalid Name</div>
                  )}
                  {userError.firstName && (
                    <img
                      className="inputErrorIcon"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                </div>
              </div>
              <div className="col-6 form-group" style={{ paddingLeft: "20px" }}>
                <label htmlFor="lastName">Last Name</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    name="lastName"
                    value={editUserData.lastName}
                    placeholder=""
                    onChange={handleChangeEditUser}
                    required
                    className={`form-input ${
                      userError.lastName ? "inputError" : ""
                    }`}
                  />
                  {userError.lastName && (
                    <div className="invalid">Invalid Name</div>
                  )}
                  {userError.lastName && (
                    <img
                      className="inputErrorIcon"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                </div>
              </div>
              <div
                className="col-6 form-group"
                style={{ paddingRight: "20px" }}
              >
                <label htmlFor="phoneNumber">Phone</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={editUserData.phoneNumber}
                    placeholder=""
                    onChange={handleChangeEditUser}
                    required
                    className={`form-input`}
                  />
                </div>
              </div>
              <div className="col-6 form-group" style={{ paddingLeft: "20px" }}>
                <label htmlFor="role">Role</label>
                <select
                  disabled={getUserRole() === "admin" ? true : false}
                  name="role"
                  value={editUserData.role}
                  onChange={handleChangeEditUser}
                  required
                  className="form-input"
                >
                  <option disabled value="default">
                    Select Role
                  </option>
                  <option value="admin">Admin</option>
                  <option value="super_admin">Super Admin</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="emailId">Email Id</label>
                <div style={{ position: "relative" }}>
                  <input
                    disabled
                    type="email"
                    name="emailId"
                    value={editUserData.emailId}
                    placeholder=""
                    onChange={handleChangeEditUser}
                    required
                    className={`form-input ${
                      userError.emailId || submitError.email ? "inputError" : ""
                    }`}
                  />
                  {userError.emailId && (
                    <div className="invalid">Invalid email</div>
                  )}
                  {userError.emailId && (
                    <img
                      className="inputErrorIcon"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                  {submitError.email && (
                    <>
                      <div className="invalid">Email already registered</div>
                      <img
                        className="inputErrorIcon"
                        src={ErrorIcon}
                        alt="error"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="form-group my-3  d-flex justify-content-center">
                <button type="submit" className="modalButtonSubmit mx-2">
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={hideEditUserModal}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <AddSubscriptionPopup
        showAddSubscriptionPopup={showAddSubscriptionPopup}
        setShowAddSubscriptionPopup={setShowAddSubscriptionPopup}
        selectedCompany={selectedCompany}
        companySubscriptionData={companySubscriptionData}
        setCompanySubscriptionData={setCompanySubscriptionData}
      />
    </div>
  );
}

export default Companies;
