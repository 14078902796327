export function getSvgPathFromStroke(stroke) {
    if (!stroke.length) return "";
  
    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ["M", ...stroke[0], "Q"]
    );
  
    d.push("Z");
    return d.join(" ");
  }
  
  export function toPairs(arr) {
    let pairs = [];
  
    for (let i = 0; i < arr.length - 1; i += 2) {
      pairs.push([arr[i], arr[i + 1]]);
    }
  
    return pairs;
  }
  