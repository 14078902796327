import React from "react";
import NoInterviews from "../../../../assets/Icons/Interview/noInterviews.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { isAuthenticated } from "helper/user";

export const NoInterview = (props) => {
  const {subscription, StarterSubscriptionInterviewsLimit} = props;
  return (
    <>
      <div className="d-flex justify-content-center noInterviewContainer">
        <div
          type="button"
          className="d-felx align-items-center text-center addInterview add-button-card"
          onClick={props.handleShow}
        >
          <img
            src={NoInterviews}
            className="img-fluid"
            alt="logo"
            loading="lazy"
          />
          {isAuthenticated()?.role !== "admin_panel" &&
          subscription.subscriptionData?.mh_subscription_plan_id?.name !==
            "professional" ? (
            <p
              className={`premium-limitations mt-3 ${
                subscription.totalCreatedInterviewCount <
                (subscription.subscriptionData?.mh_subscription_plan_id
                  ?.liveInterviewCount || StarterSubscriptionInterviewsLimit)
                  ? "under-limit-plan-hld"
                  : "limit-crossed-plan-hld"
              }`}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="close-icon me-2"
              />
              {subscription.totalCreatedInterviewCount >
              (subscription.subscriptionData?.mh_subscription_plan_id
                ?.liveInterviewCount || StarterSubscriptionInterviewsLimit)
                ? subscription.subscriptionData?.mh_subscription_plan_id
                    ?.liveInterviewCount || StarterSubscriptionInterviewsLimit
                : subscription.totalCreatedInterviewCount}
              /
              {subscription.subscriptionData?.mh_subscription_plan_id
                ?.liveInterviewCount || StarterSubscriptionInterviewsLimit}{" "}
              Assessment created
            </p>
          ) : (
            <></>
          )}
          <p className={`noInterviewHeading ${isAuthenticated()?.role !== "admin_panel" &&
          subscription.subscriptionData?.mh_subscription_plan_id?.name !==
            "professional" ? "mt-3" : ""}`}>
            Create online interview instantly
          </p>
          <p className="noInterviewTagline">
            Create instant interview or schedule an interview for the future
          </p>
          <button
            className="createInterviewBtn addInterviewBtn align-items-center"
            onClick={props.handleShow}
          >
            <img
              className="ms-2 me-3 img-fluid"
              src={props.CreateInterview}
              alt="logo"
              loading="lazy"
            />
            Create Interview
          </button>
        </div>
      </div>
    </>
  );
};
