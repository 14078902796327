import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmationModal.css";

function ConfirmationModal(props) {
  const { ModalHeading } = props;
  const { ModalBody } = props;
  const { closeIcon } = props;
  const { ActionBtnName } = props;
  const { closeBtnName } = props;
  const { ActionBtn } = props;
  const { showPopup } = props;
  const { setShowPopup } = props;
  return (
    <Modal
      centered="true"
      dialogClassName="confirmationModal"
      show={showPopup}
      onHide={() => setShowPopup(false)}
      // onBackdropClick={()=>setShowPopup(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{ModalHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4">
        <div className="">
          <p className="bodytext mb-4">{ModalBody}</p>
          <button className="cancelbtn me-4" onClick={()=>setShowPopup(false)}>{closeBtnName}</button>
          <button className="actionbtn" onClick={ActionBtn}>
            {ActionBtnName}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal;
