import React, { useEffect, useState } from "react";
import "./Jobs.css";
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";

//COMPONENTS
import JobList from "./JobList";

import axios from "axios";
import { API, BootcampBackend } from "../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import { useSidebar } from "helper/SideBarContext";

export default function Jobs(props) {
  const [activePage, setActivePage] = useState(1);
  const [showTabs, setShowTabs] = useState("published");

  const [jobList, setJobList] = useState([]);
  const [jobCount, setJobCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const { isSidebarCollapsed } = useSidebar();

  const history = useHistory();

  const fetchJobsList = async (jobListType) => {
    setIsLoading(true);
    try {
      const joblistRes = await axios.get(
        `${BootcampBackend}/jobs/getJobForCompany/${jobListType}?current_page=${activePage}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      return joblistRes;
    } catch (err) {
      console.log(err);
    }
  };

  const getListOfJobs = (jobStatusType) => {
    fetchJobsList(jobStatusType)
      .then((res) => {
        setIsLoading(false);
        setJobList(res.data.result.data);
        setJobCount(res.data.result);
        setShowTabs(jobStatusType);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = () => {
    searchJobs()
      .then((res) => {
        if (res.data.data) {
          setJobList(res.data.data);
        }
        else{
          setJobList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSearchReset = () => {
    setSearchText("");
    getListOfJobs(showTabs);
  };
  const searchJobs = async () => {
    try {
      const res = await axios.get(
        `${API}/enterprise/job_search/${searchText}?company_id=${
          JSON.parse(localStorage.getItem("jwt")).eid
        }&unpublish=false`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    setIsLoading(true);
    getListOfJobs(showTabs);
    localStorage.removeItem("stepperForm");
  }, [activePage]);

  return (
    <>
      {jobList.length > 0 ? (
        <Header heading="Jobs" />
      ) : (
        <Header heading="Add New Job" />
      )}
      <Navbar/>
      <div
        className={`jobsCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="jobsDataContentContainer">
          {isLoading ? (
            <div className="jobsDataLoader">
              <div className="allJobsHead my-3 mx-5 mt-4 mb-5 align-items-center jobsHeadLoader">
                <LoadingShape style={{ height: "30px", width: "10%" }} />
                <div
                  className="tabsWindow d-flex  flex-nowrap loaderTab mt-3"
                  style={{ gap: "20px" }}
                >
                  {" "}
                  <LoadingShape
                    style={{
                      height: "30px",
                      width: "30%",
                    }}
                  />{" "}
                  <LoadingShape
                    style={{
                      height: "30px",
                      width: "30%",
                    }}
                  />{" "}
                  <LoadingShape
                    style={{
                      height: "30px",
                      width: "30%",
                    }}
                  />
                </div>
                <LoadingShape style={{ height: "30px", width: "20%" }} />
              </div>
              <div className="postedJobs">
                {Array.from(
                  {
                    length: 10,
                  },
                  (_, i) => (
                    <div
                      className="jobCard d-flex flex-row justify-content-between"
                      style={{
                        border: "none",
                        background: "#dfe0e470",
                        overflow: "hidden",
                      }}
                      key={i}
                    >
                      <div className="d-flex">
                        <LoadingShape
                          variant="image"
                          className="img-fluid"
                          style={{ paddingBottom: "10px", width: "40px" }}
                        />
                        <div className="ms-3">
                          {" "}
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "100%",
                            }}
                          />
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "50%",
                            }}
                          />
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "120px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <JobList
              isLoading={isLoading}
              jobList={jobList}
              setJobList={setJobList}
              getListOfJobs={getListOfJobs}
              jobCount={jobCount}
              showTabs={showTabs}
              handleSearch={handleSearch}
              handleSearchReset={handleSearchReset}
              searchText={searchText}
              setSearchText={setSearchText}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )}
        </div>
      </div>
    </>
  );
}
