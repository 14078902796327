import React, { useEffect, useState, useRef } from "react";
import "./report.css";
import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { BootcampBackend } from "../../../../backend";
import goBackIcon from "../../../../assets/Icons/MyHackathons/arrowLeftBlack.svg";
import moreOptionIcon from "../../../../assets/Icons/Interview/more-option-icon.svg";
import passedIcon from "../../../../assets/Icons/Interview/passes-icon.svg";
import rejectedIcon from "../../../../assets/Icons/Interview/Rejected.svg";
import calenderIcon from "../../../../assets/Icons/Interview/New/calenderIcon.svg";
import personIcon from "../../../../assets/Icons/Interview/New/personIcon.svg";
import Cross from "../../../../assets/Icons/Interview/New/Cross.svg";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "../interviewpaper.css";
import EditIcon from "../../../../assets/Icons//Interview/New/editIcon.svg";
import { useHistory } from "react-router";
import { useSidebar } from "helper/SideBarContext";

export default function Report(props) {
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const interviewId = props.match.params.interviewId;
  const interviewRoundId = props.match.params.interviewRoundId;
  const [isEditStatus, setIsEditStatus] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("");
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [interviewRoundData, setInterviewRoundData] = useState({});
  const [interviewRounds, setInterviewRounds] = useState({});
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [notesData, setNotesData] = useState({
    notes: "",
    languageProficiency: 0,
    codeQuality: 0,
    overallRating: 0,
  });
  const { isSidebarCollapsed } = useSidebar();
  const history = useHistory();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEditStatus("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleClose = () => {
    setShow(false);
  };

  const onEditStatusChange = (roundData) => {
    if (isEditStatus === roundData._id) {
      setIsEditStatus("");
    } else {
      setIsEditStatus(roundData._id);
    }
  };
  const onEditCandidateInterviewStatus = (data, statusType) => {
    axios
      .put(
        `${BootcampBackend}/interview/updateInterviewCandidateStatus/${interviewId}/${data._id}`,
        {
          candidateInterviewStatus: statusType,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setIsEditStatus("");
        setInterviewRoundData(
          res?.data?.result?.interviewData?.candidateInterviewStatus
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInterviewRoundReport();
  }, []);
  function getInterviewRoundReport() {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };
    axios
      .get(
        `${BootcampBackend}/interview/getInterviewRoundReport/${interviewId}/${interviewRoundId}`,
        headers
      )
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setReport(res.data.result.interviewData);
          setInterviewRoundData(
            res.data.result.interviewData.candidateInterviewStatus
          );
          setInterviewRounds(res.data.result.interviewRoundData);
          setNotesData((prev) => ({
            ...prev,
            notes:
              res?.data?.result?.interviewData?.interviewerFeedback
                ?.interviewFeedBack,
            languageProficiency:
              res?.data?.result?.interviewData?.interviewerFeedback
                ?.languageProficiency?.ratings,
            codeQuality:
              res?.data?.result?.interviewData?.interviewerFeedback?.codeQuality
                ?.ratings,
            overallRating:
              res?.data?.result?.interviewData?.interviewerFeedback?.overAll
                ?.ratings,
          }));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  const submitInterviewNotes = () => {
    const notesDataa = {};
    notesDataa.interviewFeedBack = notesData.notes;

    notesData.languageProficiency !== 0
      ? (notesDataa.languageProficiency = {
          notes: "",
          ratings: notesData.languageProficiency,
        })
      : notesData.languageProficiency;

    notesData.codeQuality !== 0
      ? (notesDataa.codeQuality = { notes: "", ratings: notesData.codeQuality })
      : notesDataa.codeQuality;

    notesData.overallRating !== 0
      ? (notesDataa.overAll = { notes: "", ratings: notesData.overallRating })
      : notesDataa.overAll;
    const paramsData = {};
    paramsData.interviewVideoLink = "https://s3-link.com";
    paramsData.candidateInterviewStatus = candidateStatus;
    paramsData.interviewerFeedback = notesDataa;
    paramsData.sessionEnded = true;
    axios
      .put(
        `${BootcampBackend}/interview/updateInterviewFeedBack/${report.interviewToken}`,
        paramsData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((response) => {
        setReport(response.data.result.interviewData.interviewerFeedback);
        setIsEdit(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onNotesChange = (e) => {
    setNotesData((prev) => ({
      ...prev,
      notes: e.target.value,
    }));
  };

  const ratingData = ["Language Proficiency", "Code Quality", "Overall Rating"];

  return (
    <div>
      <Header />
      <div className="container-fluid body-margin-top interviewProcess px-0">
        <div className="interview">
          <Navbar />
          <div
            className={`position-relative reportBackground ${
              isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
          >
            <div className="reportHeading">
              <div className="d-flex justify-content-between align-items-center  py-3  col-11 mx-auto">
                <p
                  className="d-flex align-items-center"
                  style={{ float: "left", cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/interviews/interview-process/${interviewId}`)
                  }
                >
                  <img
                    src={goBackIcon}
                    alt="goback"
                    className="img-fluid me-2"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <p className="intervieweeDetails mb-0">
                    {(report?.interviewId?.candidateName
                      ? report?.interviewId?.candidateName + " " + "-" + " "
                      : "") +
                      report?.interviewId?.interviewTitle +
                      " " +
                      "Report"}
                  </p>
                </p>
              </div>
            </div>
            <hr className="dividerLine" />
            <div className="col-11 mx-auto reportBody">
              {isLoading == true ? (
                <div className="row">
                  <div className="col-lg-9 col-md-7 mt-3"></div>
                </div>
              ) : (
                <div className="">
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-between ">
                      <div className="d-flex">
                        <p className="interviewDetails">
                          Interview With{" "}
                          {report?.interviewer?.firstName +
                            " " +
                            "-" +
                            " " +
                            report?.interviewId?.interviewTitle}
                        </p>
                        {report?.interviewerFeedback?.overAll && (
                          <div className="starRating ms-4 px-2">
                            <ReactStars
                              count={5}
                              value={
                                report?.interviewerFeedback?.overAll.ratings
                              }
                              size={16}
                              activeColor="#FF9F4B"
                              edit={false}
                              color="#C4C4C4"
                              className=""
                            />
                          </div>
                        )}
                      </div>
                      <div className=" candidateInterviewStatus">
                        {interviewRoundData === "passed" ||
                        interviewRoundData === "selected" ? (
                          <>
                            <img
                              src={passedIcon}
                              alt="applied"
                              className="img-fluid me-2"
                              style={{ width: "18px" }}
                            />
                            <span className="passedText">
                              {interviewRoundData === "passed"
                                ? "Passed"
                                : interviewRoundData === "selected" &&
                                  "Selected"}
                            </span>
                          </>
                        ) : (
                          <>
                            <img
                              src={rejectedIcon}
                              alt="applied"
                              className="img-fluid me-2"
                              style={{ width: "18px" }}
                            />
                            <span className="passedText">Rejected</span>
                          </>
                        )}

                        <img
                          src={moreOptionIcon}
                          alt="applied"
                          className="position-relative img-fluid px-3 "
                          onClick={() => onEditStatusChange(report)}
                          style={{ cursor: "pointer" }}
                        />
                        {isEditStatus === report._id && (
                          <div
                            className="editStatushld viewReportEditStatus"
                            ref={wrapperRef}
                          >
                            <p
                              className="p-3 mb-0"
                              onClick={() =>
                                onEditCandidateInterviewStatus(report, "passed")
                              }
                            >
                              Pass
                            </p>
                            <p
                              className="p-3 mb-0"
                              onClick={() =>
                                onEditCandidateInterviewStatus(
                                  report,
                                  "selected"
                                )
                              }
                            >
                              Select
                            </p>
                            <p
                              className="p-3 mb-0"
                              onClick={() =>
                                onEditCandidateInterviewStatus(
                                  report,
                                  "rejected"
                                )
                              }
                            >
                              Reject
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <ul className="interviewDetailsList my-2 me-2 mt-3 ps-0 align-items-center">
                      <li>
                        {" "}
                        <img
                          src={calenderIcon}
                          alt="calender"
                          className="img-fluid me-2 calenderIcon"
                        />
                        {moment(report?.interviewStartTime).format(
                          "MMMM Do YYYY"
                        )}
                      </li>
                      <li className="interviewDateDot"></li>
                      <li>
                        {moment(report?.interviewStartTime).format("LT")} -{" "}
                        {moment(report?.interviewEndTime).format("LT")}
                      </li>
                    </ul>

                    <ul className="interviewDetailsList mt-2 me-2 mb-3 ps-0 align-items-center">
                      <li className="interviewerName">
                        <img
                          src={personIcon}
                          alt="calender"
                          className="img-fluid me-2 calenderIcon"
                        />
                        {report?.interviewer?.firstName}
                        {report?.interviewer?.lastName}
                      </li>
                      <li className="interviewDateDot"></li>
                      <li>{report?.interviewer?.emailId}</li>
                    </ul>
                    {interviewRounds?.tabs?.map((tab, index) => (
                      <React.Fragment key={tab?._id}>
                        {tab?.tabType.toLowerCase().includes("assessment") ? (
                          <>
                            {index === 0 ? <hr /> : <></>}
                            <div className="assessment-round-report-card">
                              <p className="roundstext">
                                Round {report?.round}
                              </p>
                              <p className="assessment-title">
                                {tab.tabType} - {tab.assessmentSlugRef}
                              </p>
                              <div className="assessment-user-result-cntr mb-4">
                                <ul className="assessment-user-result-hld">
                                  <li>
                                    Total Questions
                                    <span>
                                      {tab?.assessmentSubmissionData?.numOfQuestions || 0}
                                    </span>
                                  </li>
                                  <li>
                                    Answered Correct
                                    <span>
                                      {tab?.assessmentSubmissionData?.correctlyAnswered || 0}
                                    </span>
                                  </li>
                                  <li>
                                    Answered Wrong
                                    <span>
                                      {(tab?.assessmentSubmissionData
                                        ?.questionsAttempted -
                                        tab?.assessmentSubmissionData
                                          ?.correctlyAnswered) || 0}
                                    </span>
                                  </li>
                                  <li>
                                    Unattended
                                    <span>
                                      {(tab?.assessmentSubmissionData
                                        ?.numOfQuestions -
                                        tab?.assessmentSubmissionData
                                          ?.questionsAttempted) || 0}
                                    </span>
                                  </li>
                                </ul>
                                <div className="d-flex align-items-center">
                                  <p className="user-result pe-3">
                                    {tab?.assessmentSubmissionData
                                      ?.totalPercent > 60 ? (
                                      <img
                                        src={passedIcon}
                                        alt="applied"
                                        className="img-fluid me-2"
                                        style={{ width: "18px" }}
                                      />
                                    ) : (
                                      <img
                                        src={rejectedIcon}
                                        alt="applied"
                                        className="img-fluid me-2"
                                        style={{ width: "18px" }}
                                      />
                                    )}
                                    {tab?.assessmentSubmissionData
                                      ?.totalPercent > 60
                                      ? "Passed"
                                      : "Rejected"}
                                  </p>
                                  <p
                                    className={`user-result-score ${
                                      tab?.assessmentSubmissionData
                                        ?.totalPercent > 60
                                        ? "user-pass-score"
                                        : "user-reject-score"
                                    }`}
                                  >
                                    {(tab?.assessmentSubmissionData
                                      ?.totalPercent || 0) %
                                      2 ===
                                    0
                                      ? tab?.assessmentSubmissionData
                                          ?.totalPercent || 0
                                      : tab?.assessmentSubmissionData?.totalPercent.toFixed(
                                          2
                                        )}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                    <div className="d-flex align-items-center mt-4">
                      <div className="candidateDetailsSection">
                        Candidate Details
                      </div>
                      <hr className="w-100" />
                    </div>
                    {report?.interviewId?.candidateName ||
                    report?.interviewId?.candidateEmail ? (
                      <div className="d-flex flex-row mt-4 candidateDetails">
                        {report?.interviewId?.candidateName && (
                          <>
                            <div>
                              <p>Full name</p>
                              <p className="candidateDetailsValue">
                                {report?.interviewId?.candidateName}
                              </p>
                            </div>
                            <div className="verticalDivider"></div>
                          </>
                        )}
                        <div>
                          <p>Email</p>
                          <p className="candidateDetailsValue">
                            {report?.interviewId?.candidateEmail}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-row mt-4 candidateDetails">
                        {" "}
                        <p>Candidate details not available!!</p>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className=" mt-3 p-0">
                    <div className="notesHld">
                      <h6 className="py-2 mb-2 notesHeading">
                        Interview Notes
                        <span className="editIconBorder editIconPad">
                          <img
                            src={EditIcon}
                            alt="edit"
                            className="img-fluid feedbackeditIcon reportFeedbackIcon"
                            onClick={() => {
                              setIsEdit(!isEdit);
                              setShow(true);
                            }}
                          />
                        </span>
                      </h6>
                      <div className="interviewNotesHld pe-4">
                        <div className="d-flex">
                          <div className="px-3 col-7">
                            {report?.interviewerFeedback?.interviewFeedBack && (
                              <p className="notesText">
                                {report?.interviewerFeedback?.interviewFeedBack}
                              </p>
                            )}
                          </div>
                          <div
                            className="verticalDivider"
                            style={{ height: "150px" }}
                          ></div>
                          {report.interviewerFeedback && (
                            <div className="margin-fix-InterviewReport">
                              {Object.entries(report.interviewerFeedback).map(
                                (element, key) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between my-3"
                                    key={key}
                                  >
                                    {element[0] !== "assessment" &&
                                      element[0] !== "interviewFeedBack" && (
                                        <>
                                          <h6 className="notestitle me-2 ">
                                            {ratingData[key - 1]}
                                          </h6>
                                          <div className="starRating px-2 ">
                                            <ReactStars
                                              count={5}
                                              value={
                                                element[1]["ratings"]
                                                  ? element[1]["ratings"]
                                                  : 0
                                              }
                                              size={18}
                                              activeColor="#FF9F4B"
                                              edit={false}
                                              color="#C4C4C4"
                                              className=""
                                            />
                                          </div>
                                        </>
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered
        className="interviewReportContainer"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <img
              src={Cross}
              onClick={handleClose}
              className="img-fluid modalCloseBtn ms-auto"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <h5 className="text-center">Interview Status</h5>
            <textarea
              type="text"
              placeholder="Your Feedback here..."
              className="form-cntrol w-100 py-1 SbtextArea p-3"
              rows="9"
              value={
                notesData.notes ||
                report?.interviewerFeedback?.interviewFeedBack
              }
              onChange={onNotesChange}
            ></textarea>
            {false && (
              <p
                style={{
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                This fields is required
              </p>
            )}

            <div>
              <div className="d-flex align-items-center my-3 justify-content-between">
                <h6 className="notestitle me-2">Language Proficiency </h6>
                <div className="px-2">
                  <ReactStars
                    count={5}
                    value={
                      report.interviewerFeedback?.languageProficiency
                        ?.ratings || 0
                    }
                    onChange={(e) =>
                      setNotesData((prev) => ({
                        ...prev,
                        languageProficiency: e,
                      }))
                    }
                    size={18}
                    activeColor="#FF9F4B"
                    color="#C4C4C4"
                    className=""
                  />
                </div>
              </div>
              <div className="d-flex align-items-center my-3 justify-content-between">
                <h6 className="notestitle me-2">Code Quality</h6>
                <div className="px-2">
                  <ReactStars
                    count={5}
                    value={
                      report.interviewerFeedback?.codeQuality?.ratings || 0
                    }
                    onChange={(e) =>
                      setNotesData((prev) => ({
                        ...prev,
                        codeQuality: e,
                      }))
                    }
                    size={18}
                    activeColor="#FF9F4B"
                    color="#C4C4C4"
                    className=""
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between my-3">
                <h6 className="notestitle me-2">Overall Rating</h6>
                <div className="px-2 ">
                  <ReactStars
                    count={5}
                    value={report?.interviewerFeedback?.overAll?.ratings || 0}
                    onChange={(e) =>
                      setNotesData((prev) => ({
                        ...prev,
                        overallRating: e,
                      }))
                    }
                    size={18}
                    activeColor="#FF9F4B"
                    color="#C4C4C4"
                    className=""
                  />
                </div>
              </div>
            </div>

            <h6 className="notestitle mb-3 mt-5">Status of the Candidate</h6>
            <div className="d-flex status0ftheCandidate mb-3">
              <p
                onClick={() => setCandidateStatus("selected")}
                className={`py-2 ${
                  candidateStatus === "selected"
                    ? "selectedstatusOftheCandidate"
                    : ""
                }`}
              >
                Select
              </p>
              <p
                onClick={() => setCandidateStatus("passed")}
                className={`py-2 ${
                  candidateStatus === "passed"
                    ? "selectedstatusOftheCandidate"
                    : ""
                }`}
              >
                Pass
              </p>
              <p
                onClick={() => setCandidateStatus("rejected")}
                className={`py-2 ${
                  candidateStatus === "rejected"
                    ? "selectedstatusOftheCandidate"
                    : ""
                }`}
              >
                Reject
              </p>
            </div>
            <h6 className="notestitle mb-3">Candidate’s Email</h6>
            <div className="candidateDetailBox">
              <span className="ms-3">
                <h6>{report?.interviewId?.candidateName}</h6>
                <p>{report?.interviewId?.candidateEmail}</p>
              </span>
            </div>
            <div className="text-center mt-4 mb-2">
              <button
                className="submitbtn"
                type="button"
                onClick={() => {
                  submitInterviewNotes();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
