import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import closeModal from "assets/Icons/MyHackathons/closeModal.svg";
import submitArrow from "assets/Icons/MyHackathons/submitArrow.svg";
import "./ContactUs.css";
import { API } from "backend";
import axios from "axios";
import { useSelector } from "react-redux";
import { SubscriptionPlanService } from "services/subscription.service";
import ToastMessages from "pages/PrivatePages/Assessment/components/ToastMessages";

const initialErrorFormData = {
  name: null,
  email: null,
  organization: null,
  phoneNumber: null,
  comments: null,
};
export default function ContactUs({ showContactUs, setShowContactUs, type }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
    phoneNumber: "",
    comments: "",
  });
  const [formError, setFormError] = useState(initialErrorFormData);
  const user = useSelector((state) => state.auth.user);
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const handleChange = (e) => {
    setFormError(initialErrorFormData);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateFrorm = () => {
    const regPhoneno = /^\d{6,13}$/;
    const regName = /^[a-zA-Z\s]{3,30}$/;
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setFormError(initialErrorFormData);
    if (formData.name == null || !regName.test(formData.name)) {
      setFormError({
        ...formError,
        name: "Name should contain atleast 3 alphabets and atmost 30 alphabets",
      });
    } else if (!regEmail.test(formData.email)) {
      setFormError({ ...formError, email: "Enter a valid email id" });
    } else if (!regPhoneno.test(formData.phoneNumber)) {
      setFormError({ ...formError, phoneNumber: "Enter a valid phone number" });
    } else if (formData.organization == null) {
      setFormError({
        ...formError,
        organization: "This field cannot be empty",
      });
    } else {
      // For Subscription handleSubscription method and rest handleSubmit method
      type === "default" ? handleSubmit() : handleSubscription();
    }
  };
  const handleSubscription = async () => {
    try {
      const requestData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phoneNumber,
        organization: formData.organization,
        content: formData.comments,
      };
      const contact_us_response = await SubscriptionPlanService.Cantact(
        requestData
      );
      setToastMessage({
        variant: "success",
        show: true,
        message: "successfull",
        duration: 3000,
      });
      setShowContactUs(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = () => {
    const contactUsData = new FormData();
    contactUsData.append("name", formData.name);
    contactUsData.append("email", formData.email);
    contactUsData.append("mobile", formData.phoneNumber);
    contactUsData.append("company", formData.organization);
    contactUsData.append("content", formData.comments);
    contactUsData.append("type", "Enterprise");
    contactUsData.append("subject", "EnterPrise Query");
    axios
      .post(`${API}/contactus`, contactUsData)
      .then((response) => {
        setShowContactUs(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (user?._id) {
      setFormData((prev) => ({
        ...prev,
        organization: user?.company?.companyName,
      }));
    }
  }, [user?._id]);
  return (
    <div>
      <Modal
        centered={true}
        show={showContactUs}
        onHide={() => setShowContactUs(false)}
        className="contactUsModal my-auto"
      >
        <Modal.Header className="position-relative contactUsModalHeader">
          <div className="Header-heading">Contact Us</div>
          <img
            className="contactUsModalClose cursor-pointer"
            onClick={() => setShowContactUs(false)}
            src={closeModal}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="contactUdFormHld px-2">
            <div className="formLabel">
              Full Name <span>*</span>
            </div>
            <input
              onChange={handleChange}
              name="name"
              className={`contactUsInput mt-1 ${
                formError?.name ? "contactUsErrorField" : ""
              }`}
              placeholder="Eg. Rahul Gupta"
            />
            <p className="mb-0 formErrorMessage">{formError.name}</p>
            <div className="formLabel mt-3">
              Official Email<span>*</span>
            </div>
            <input
              onChange={handleChange}
              name="email"
              className={`contactUsInput mt-1 ${
                formError.email ? "contactUsErrorField" : ""
              }`}
              placeholder="Eg. example@gmail.com"
            />
            <p className="mb-0 formErrorMessage">{formError.email}</p>
            <div className="formLabel mt-3">
              Organization<span>*</span>
            </div>
            <input
              onChange={handleChange}
              name="organization"
              className={`contactUsInput mt-1 ${
                formError.organization ? "contactUsErrorField" : ""
              }`}
              placeholder="organization"
              value={formData?.organization}
              disabled
            />
            <p className="mb-0 formErrorMessage">{formError.organization}</p>
            <div className="formLabel mt-3">Phone Number</div>
            <input
              type="number"
              onChange={handleChange}
              name="phoneNumber"
              className={`contactUsInput mt-1 ${
                formError?.phoneNumber ? "contactUsErrorField" : ""
              }`}
              placeholder="9999999999"
            />
            <p className="mb-0 formErrorMessage">{formError.phoneNumber}</p>
            <div className="formLabel mt-3">Your comments</div>
            <textarea
              name="comments"
              onChange={handleChange}
              rows="2"
              className="contactUsModalTextArea px-3 mt-1"
              placeholder="Clarify your query"
            />
          </div>
          <div className="text-center my-3">
            <button onClick={validateFrorm} className="primaryBtnActive">
              Submit <img className="ms-2" src={submitArrow} />
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {toastMessage.show && (
        <ToastMessages
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
    </div>
  );
}
