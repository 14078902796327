import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import CalendarIcon from "assets/Icons/lms/lms-calendar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSidebar } from "helper/SideBarContext";
import { useHistory } from "react-router-dom";
import FileImg from "assets/Images/file-img.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import moment from "moment";
import { LoadingShape } from "react-rainbow-components";
import ContactUs from "components/ContactUs/ContactUs";
import "../studyPlan.css";

const ListOfStudyPlan = () => {
  const { isSidebarCollapsed } = useSidebar();
  const [studyPlans, setStudyPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showContactUs, setShowContactUs] = useState(false);
  const history = useHistory();

  const fetchStudyPlans = async () => {
    try {
      const studyPlanResponse = await axios.get(
        `${BootcampBackend}/v1/lms/studyplans`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt")
              ? JSON.parse(localStorage.getItem("jwt")).token
              : null,
          },
        }
      );
      setIsLoading(false);
      setStudyPlans(studyPlanResponse.data?.result?.data);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStudyPlans();
  }, []);
  return (
    <>
      <Header />
      <Navbar />
      <div
        className={`body-margin-top px-4 py-3 lms-container ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="lms-heading">
          <h1
            className={
              studyPlans.length === 0 && !isLoading ? "text-center" : ""
            }
          >
            LMS
          </h1>
        </div>
        {isLoading ? (
          <div className="row">
            {Array.from({ length: 4 })?.map((_, i) => (
              <div className="col-4" key={i}>
                <div className={`study-plan-card cursor-pointer`}>
                  <div className="studyplan-status-hld">
                    <LoadingShape className="w-25" />
                    <LoadingShape className="w-25" />
                  </div>
                  <div className="studyplan-title col-10">
                    <LoadingShape className="mb-2" />
                    <LoadingShape className="mb-4" />
                  </div>
                  <LoadingShape />
                  <div className="studyplan-details-hld my-3">
                    <span className="studyplan-total-learners d-flex align-items-center">
                      <LoadingShape
                        variant="avatar"
                        shape="circle"
                        className="studyplan-top-learner"
                      />
                      <LoadingShape
                        variant="avatar"
                        shape="circle"
                        className="studyplan-top-learner"
                      />
                      <LoadingShape
                        variant="avatar"
                        shape="circle"
                        className="studyplan-top-learner"
                      />
                    </span>
                    <LoadingShape className="w-50" />
                  </div>
                  <LoadingShape className="w-75" />
                </div>
              </div>
            ))}
          </div>
        ) : studyPlans.length === 0 ? (
          <div className="lms-no-data-cntr">
            <div className="col-lg-10 lms-no-data-hld mx-auto">
              <img
                src={FileImg}
                className=""
                alt="file"
                height={118}
                width={118}
              />
              <p className="lms-no-data-text my-4 col-lg-10 mx-auto">
                LMS focuses on educational content and training to be updated{" "}
                <br /> To know more about LMS
              </p>
              <button
                className="lms-no-data-call-to-action"
                onClick={() => setShowContactUs(!showContactUs)}
              >
                Contact us
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            {studyPlans?.map((plan, i) => (
              <div className="col-4" key={plan?._id}>
                <div
                  className={`study-plan-card cursor-pointer ${
                    plan?.studyPlanState === "Finished"
                      ? "completed-study-plan-card"
                      : ""
                  }`}
                  onClick={() => history.push(`/lms/${plan?.slug}`)}
                >
                  <div className="studyplan-status-hld">
                    <span className="studyplan-duration-text">
                      <img src={CalendarIcon} className="me-1" alt="duration" />
                      Duration -{" "}
                      {plan?.studyPlanState === "Ongoing"
                        ? plan?.currentDay
                        : plan?.studyPlanState === "Finished"
                        ? plan?.totalNoOfDays
                        : 0}
                      /{plan?.totalNoOfDays} Days
                    </span>
                    <span className="studyplan-status-percentage">
                      {plan?.studyPlanState === "Not Started"
                        ? 0
                        : plan?.studyPlanState === "Finished"
                        ? 100
                        : (plan?.currentDay / plan?.totalNoOfDays) * 100}
                      %
                    </span>
                  </div>
                  <p className="studyplan-title col-10">{plan?.title}</p>
                  <div className="studyplan-percentage-representation-hld">
                    <div
                      className="studyplan-percentage-representation"
                      style={{ width: `${plan?.studyPlanState === "Not Started"
                      ? 0
                      : plan?.studyPlanState === "Finished"
                      ? 100
                      : (plan?.currentDay / plan?.totalNoOfDays) * 100}%` }}
                    ></div>
                  </div>
                  <div className="studyplan-details-hld mb-3">
                    <span className="studyplan-total-learners">
                      {plan.learnerDetails?.map((learner, i) => (
                        <img
                          src={learner?.Picture}
                          alt="user"
                          className={`studyplan-top-learner ${
                            (i + 1) === plan.learnerDetails.length ? "me-2" : ""
                          }`}
                          key={"learner" + i}
                        />
                      ))}
                      {plan?.totalLearners} Learner{plan?.totalLearners > 1 ? "s" : ""}
                    </span>
                    <span className="studyplan-startedAt">
                      {moment(plan?.startDate).fromNow()}
                    </span>
                  </div>
                  {plan?.studyPlanState === "Finished" ? (
                    <p className="studyplan-completed-hld">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="me-2 circle-check"
                        fontSize={15}
                      />
                      Total Completion - {plan?.participationPercentage}%
                    </p>
                  ) : (
                    <span className="studyplan-dayChange">
                      {plan?.dayChangePercentage === 0 ? (
                        <></>
                      ) : plan?.dayChangePercentage < 0 ? (
                        <>
                          <FontAwesomeIcon
                            icon={faArrowTrendDown}
                            className="me-2 circle-check"
                            fontSize={15}
                            color="#F44D5E"
                          />
                          <span className="studyplan-dayChange-negative me-1">
                            {plan?.dayChangePercentage % 2 === 0
                              ? plan?.dayChangePercentage
                              : plan?.dayChangePercentage?.toFixed(1)}
                            %
                          </span>
                          Down from yesterday
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faArrowTrendUp}
                            className="me-2 circle-check"
                            fontSize={15}
                            color="#4EA184"
                          />
                          <span className="studyplan-dayChange-positive me-1">
                            +
                            {plan?.dayChangePercentage % 2 === 0
                              ? plan?.dayChangePercentage
                              : plan?.dayChangePercentage?.toFixed(1)}
                            %
                          </span>
                          Up from yesterday
                        </>
                      )}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type={"default"}
      />
    </>
  );
};

export default ListOfStudyPlan;
