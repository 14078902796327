import React, { useCallback, useRef, useState } from "react";
import csv from "csv";
import { useDropzone } from "react-dropzone";
import SampleDownload from "../../../../../assets/sample-emails.csv";
import uploadCSV from "../../../../../assets/Icons/Assessments/uloadCSV.svg";
import { Spinner } from "react-bootstrap";
function AddCandidateEmail(props) {
  const [error, setError] = useState("");
  const [emailItems, setEmailItems] = useState([]);
  const [emailList, setEmailList] = useState("");
  const inputEl = useRef(null);
  const {
    selectedCSV,
    setSelectedCSV,
    loader,
    setLoader,
    submitCandidatesEmail,
    duplicateEmails,
    errorEmail,
    setErrorEmail
  } = props;
  function handleFileUpload(files) {
    files && files.length > 0
      ? (setSelectedCSV(files[0]), setError(false), setErrorEmail(""))
      : setSelectedCSV(null);
  }
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        props.setTotalEmailId(data?.slice(1)?.length);
        props.setInvalidEmailList([]);
        let csvData = [];
        let invalidemaillist = [];
        data?.length > 0 &&
          data.slice(1).forEach((element) => {
            var regEmail =
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!regEmail.test(element)) {
              invalidemaillist.push(element.join(""));
              return false;
            }
            csvData.push(element.join(""));
          });
        props.setCsvEmailData(csvData);
        props.setInvalidEmailList(invalidemaillist);
        props.checkDuplicateEmail(csvData,false);
      });
    };
    reader.readAsBinaryString(acceptedFiles[0]);
    handleFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: ".csv",
    onDrop,
  });
  const handleDelete = (item) => {
    setEmailItems(emailItems.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    setError(false);
    setErrorEmail("");
    let paste = evt.clipboardData.getData("text");
    //eslint-disable-next-line
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));
      setEmailItems(toBeAdded);
    }
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = emailList.trim();

      if (value && isValid(value)) {
        setEmailItems([...emailItems, emailList]);
        setEmailList("");
      }
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setErrorEmail(error);
      return false;
    }

    return true;
  };
  const isInList = (email) => {
    return emailItems.includes(email);
  };

  const isEmail = (email) => {
    //eslint-disable-next-line
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };
  const handleEmailChange = (e) => {
    setError(false);
    setErrorEmail("");
    setEmailList(e.target.value);
  };
  function sendEmailToCandidates() {
    setLoader(true);
    if (selectedCSV === null) {
      setError(true);
      setLoader(false);
    }
    if (emailItems?.length > 0 || emailList !== "" || selectedCSV !== null) {
      setLoader(true);
      setError(false);
      if (emailList !== "") {
        var regEmail =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!regEmail.test(emailList)) {
          setErrorEmail(`${emailList} is not a valid email address.`);
          return false;
        }
      }
      let allEmails = [];
      emailList !== "" && allEmails.push(emailList);
      emailItems.length !== 0 && allEmails.push(...emailItems);
      // errorEmail === "" &&
      //   submitCandidatesEmail(
      //     allEmails.map((email) => email.trim()).filter((email) => email)
      //   );
      props.checkDuplicateEmail(
        allEmails.map((email) => email.trim()).filter((email) => email),true
      );
    }
  }
  const onClickDivFocusInput = () => {
    inputEl.current.focus();
  };
  return (
    <>
      <p className="paragraph mb-3 pt-3">
        Share this test with another team or user in your organization.
      </p>
      <h6 className="modalHeadings mb-2">Candidate’s Email</h6>
      <div className="userEmailInput mb-3" onClick={onClickDivFocusInput}>
        {emailItems?.map((item) => (
          <div
            className={`tag-item ${
              duplicateEmails.includes(item) ? "errortag-item" : ""
            }`}
            key={item}
          >
            {item}
            <button
              type="button"
              className="button"
              onClick={() => handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          placeholder={`${
            emailItems.length > 0 ? "" : "Type your email contacts"
          }`}
          value={emailList}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          ref={inputEl}
        />
      </div>
      {/* {errorEmail && <p>{errorEmail}</p>} */}
      {error && (
        <p style={{ color: "red", fontSize: "12px" }}>
          Please Enter candidate's email
        </p>
      )}
      {errorEmail && (
        <p style={{ color: "red", fontSize: "12px" }}>{errorEmail}</p>
      )}
      <p className="paragraph mb-3 text-center">(or)</p>
      <h6 className="modalHeadings mb-2">Bulk Upload</h6>
      <div className="dropzoneArea text-center" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : error ? (
          <p style={{ color: "red" }}>Wrong File Format</p>
        ) : selectedCSV === null ? (
          <>
            <img src={uploadCSV} className="image-fluid mt-4" />
            <p>Upload .csv or .xls</p>
          </>
        ) : (
          <p>{selectedCSV.name} uploaded successfully</p>
        )}
        {selectedCSV !== null && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCSV(null);
              setError(false);
            }}
            className="csvBtn"
          >
            Cancel
          </button>
        )}
      </div>
      <p className="my-4 paragraph">
        Download an example CSV file{" "}
        <a href={SampleDownload} target="_blank" download>
          Click here
        </a>
      </p>
      <div className="text-center mb-3">
        {/* <button
          className="shareWithCandidateBtn candidateShareBtn"
          onClick={() => {
            sendEmailToCandidates();
            props.setModalOpen("emailVerified");
          }}
        >
          Share
        </button> */}
        {selectedCSV === null && !loader && (
          <button
            className="shareWithCandidateBtn candidateShareBtn"
            onClick={sendEmailToCandidates}
          >
            Share
          </button>
        )}
        {selectedCSV === null && loader && (
          <button
            className="shareWithCandidateBtn candidateShareBtn"
            style={{ width: "20%" }}
          >
            {loader && <Spinner animation="border" />}
          </button>
        )}
        {selectedCSV !== null && (
          <button
            className="shareWithCandidateBtn candidateShareBtn"
            onClick={() => props.setModalOpen("emailVerified")}
          >
            Validate
          </button>
        )}
      </div>
    </>
  );
}

export default AddCandidateEmail;
