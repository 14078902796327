import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import dots from "../../../../assets/Icons/Settings/dots.svg";
import copyLink from "../../../../assets/Icons/MyHackathons/profileEmailCopy.svg";
import { LoadingShape } from "react-rainbow-components";
import { Machinehack_LMS_Url, Machinehack_Url } from "backend";

function AssessmentTabs(props) {
  const {
    totalQuestions,
    totalParticipants,
    activeTab,
    assessmentId,
    assessmentData,
    setShowPublishPopup,
    DeleteAssessments,
    setPublishMOdal,
    publishAssessment,
    organizationDetails,
    setIsLms,
    isLms
  } = props;
  const [showOption, setShowOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [activeTab, setActiveTab] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const params = useParams();
  const history = useHistory();
  const optionDropDown = useRef();

  const currentTab = useLocation().pathname.split("/")[3];

  const logState = (state) => {
    setIsLms(state);
  };

  const handleTestRedirect = () => {
    const url = isLms ? `${Machinehack_LMS_Url}/enterprise/assessment/${assessmentData?.slug}` : `${Machinehack_Url}/enterprise/assessment/${assessmentData?.slug}`;
    window.open(url);
  };
  const oncopyLink = () => {
    setIsCopied(true);
    const url = isLms ? `${Machinehack_LMS_Url}/enterprise/assessment/${assessmentData?.slug}` : `${Machinehack_Url}/enterprise/assessment/${assessmentData?.slug}`;
    navigator.clipboard.writeText(url);
  };

  const onPublishChange = () => {
    setPublishMOdal({
      heading: "Publish Assessment",
      body: "You can configure setting anytime under setting tab",
      actionBtnName: `${assessmentData.publish ? "Unpublish" : "Publish"}`,
      closeBtnName: "Cancel",
      actionBtn: () => {
        publishAssessment();
      },
      show: true,
    });
    setShowPublishPopup(true);
  };
  const onDeleteChange = () => {
    setPublishMOdal({
      heading: "Delete Assessment",
      body: "Are you sure you want to delete ?",
      actionBtnName: "Delete",
      closeBtnName: "Cancel",
      actionBtn: () => {
        DeleteAssessments();
      },
      show: true,
    });
    setShowPublishPopup(true);
  };

  useEffect(() => {
    const closeSalaryDropDown = (e) => {
      if (
        optionDropDown?.current &&
        !optionDropDown?.current?.contains(e.target)
      )
        setShowOption(false);
    };
    document.body.addEventListener("mousedown", closeSalaryDropDown);
  }, []);

  return (
    <div className="AssessmentTabsHeader py-2 d-flex justify-content-between">
      <div className="d-flex ms-4">
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <div
            className={`cursor-pointer ${
              activeTab === "questions" ? "active" : ""
            }`}
            onClick={() => {
              history.push(`/assessments/${assessmentId}/questions`);
            }}
          >
            <span className="AssessmentTabsTags">Questions</span>
            {totalQuestions > 0 && (
              <span className="assessmentParticipantsTagCount ms-1">
                {totalQuestions}
              </span>
            )}
          </div>
        )}
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <>
            {assessmentData?.publish && (
              <div
                className={`ms-4 cursor-pointer ${
                  activeTab === "participants" ? "active" : ""
                }`}
                onClick={() => {
                  history.push(`/assessments/${assessmentId}/participants`);
                }}
              >
                <span className="AssessmentTabsTags">Participants</span>
                {totalParticipants > 0 && (
                  <span className="assessmentQuestionTagCount ms-1">
                    {totalParticipants}
                  </span>
                )}
              </div>
            )}
          </>
        )}
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <div
            onClick={() => {
              history.push(`/assessments/${assessmentId}/setting/general`);
            }}
            className={`ms-4 cursor-pointer ${
              activeTab === "setting" ? "active" : ""
            }`}
          >
            <span className="AssessmentTabsTags">Settings</span>
          </div>
        )}
      </div>

      <div className="me-2 position-relative d-flex">
        {organizationDetails.lmsOption ? (
          <Toggle label="LMS" toggled={isLms} onClick={logState} />
        ) : (
          <></>
        )}
        {assessmentData?.publish && (
          <button
            className="AssessmentTabsCopyLinkBtn me-3"
            onClick={oncopyLink}
          >
            {isCopied ? "Copied" : "Copy Link"}
            <img
              className="ms-2 img-fluid"
              src={copyLink}
              loading="lazy"
              alt="copy"
            />
          </button>
        )}
        {assessmentData?.publish && (
          <button
            className="AssessmentTabsTryBtn me-3"
            onClick={handleTestRedirect}
          >
            Try Test
          </button>
        )}
        <button
          className="AssessmentTabsArchiveBtn mx-3"
          onClick={onPublishChange}
        >
          {assessmentData?.publish ? "Unpublish" : "Publish"}
        </button>
        <div className="px-2" onClick={() => setShowOption(!showOption)}>
          <img src={dots} className="pe-2 ps-2 cursor-pointer" />
        </div>

        {showOption ? (
          <div className="AssessmentTabsOptions" ref={optionDropDown}>
            {assessmentData?.publish && (
              <span
                onClick={() => {
                  setShowOption(false);
                  props?.setOpen("shareWithCandidate");
                }}
              >
                Share
              </span>
            )}
            {/* <span>Clone</span> */}
            {/* <span>Move to draft</span> */}
            {/* <span>Archive</span> */}
            <span onClick={onDeleteChange}>Delete</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export const Toggle = ({ label, toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <div className="d-flex align-items-center">
      <label className="toggle-label">
        <input
          className="toggleInput"
          type="checkbox"
          defaultChecked={isToggled}
          onClick={callback}
        />
        <span className="togglehld" />
      </label>
      <p className="ps-2">{label}</p>
    </div>
  );
};

export default AssessmentTabs;
