import HackathonLevel5 from "assets/badges/hackathon-level-5.svg";
import HackathonLevel4 from "assets/badges/hackathon-level-4.svg";
import HackathonLevel3 from "assets/badges/hackathon-level-3.svg";
import HackathonLevel2 from "assets/badges/hackathon-level-2.svg";
import HackathonLevel1 from "assets/badges/hackathon-level-1.svg";
import BlogLevel5 from "assets/badges/blog-level-5.svg";
import BlogLevel4 from "assets/badges/blog-level-4.svg";
import BlogLevel3 from "assets/badges/blog-level-3.svg";
import BlogLevel2 from "assets/badges/blog-level-2.svg";
import BlogLevel1 from "assets/badges/blog-level-1.svg";
import AssessmentLevel5 from "assets/badges/assessment-level-5.svg";
import AssessmentLevel4 from "assets/badges/assessment-level-4.svg";
import AssessmentLevel3 from "assets/badges/assessment-level-3.svg";
import AssessmentLevel2 from "assets/badges/assessment-level-2.svg";
import AssessmentLevel1 from "assets/badges/assessment-level-1.svg";
import PracticeLevel5 from "assets/badges/practice-level-5.svg";
import PracticeLevel4 from "assets/badges/practice-level-4.svg";
import PracticeLevel3 from "assets/badges/practice-level-3.svg";
import PracticeLevel2 from "assets/badges/practice-level-2.svg";
import PracticeLevel1 from "assets/badges/practice-level-1.svg";
import * as Y from "yjs";

export const timer = (seconds, returnAs) => {
  if (seconds === null || seconds === undefined) {
    seconds = 0;
  }
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (minutes > 0) {
    seconds = seconds - minutes * 60;
  }

  if (hours > 0) {
    minutes = minutes - hours * 60;
  }

  switch (returnAs) {
    case "words": {
      return (
        (hours ? `${getFormat(hours)} hr ` : "") +
        (minutes ? `${getFormat(minutes)} min ` : "") +
        (seconds ? `${getFormat(seconds)} sec ` : "")
      );
    }
    case "default":
    default:
      return `${getFormat(hours) + " : "}${getFormat(minutes)} : ${getFormat(
        seconds
      )}`;
  }
};

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};
// const debounce = (func, delay) => {
//   let debounceTimer;
//   return function () {
//     const context = this;
//     const args = arguments;
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func.apply(context, args), delay);
//   };
// };
const getFormat = (v) => {
  return `0${v}`.slice(-2);
};

export const getInnerHTML = (data) => {
  if (data) {
    const regex = /(<([^>]+)>)/gi;
    return data?.replace(regex, "");
  }
};

export const getCurrencyTypePlans = (plansArray) => {
  let country = new Date().toString().split("(")[1].split(" ")[0];
  const indianCurrencyPlans = plansArray.filter((x) => {
    if (
      country === "India" ? x.currencyType === "INR" : x.currencyType === "USD"
    ) {
      return x;
    }
  });
  return indianCurrencyPlans;
};

const hackathoBadges = [
  HackathonLevel1,
  HackathonLevel2,
  HackathonLevel3,
  HackathonLevel4,
  HackathonLevel5,
];
const practiceBadges = [
  PracticeLevel1,
  PracticeLevel2,
  PracticeLevel3,
  PracticeLevel4,
  PracticeLevel5,
];
const assessmentBadges = [
  AssessmentLevel1,
  AssessmentLevel2,
  AssessmentLevel3,
  AssessmentLevel4,
  AssessmentLevel5,
];
const blogbadges = [BlogLevel1, BlogLevel2, BlogLevel3, BlogLevel4, BlogLevel5];

const getBadge = (level, imagesArray) => {
  let image_name = "";
  switch (level) {
    case "GrandMaster":
      image_name = imagesArray[4];
      break;
    case "Master":
      image_name = imagesArray[3];
      break;
    case "Champion":
      image_name = imagesArray[2];
      break;
    case "Apprentice":
      image_name = imagesArray[1];
      break;
    default:
      image_name = imagesArray[0];
      break;
  }
  return image_name;
};

export const getUserBadge = (level, type) => {
  switch (type) {
    case "hackathon":
      return getBadge(level, hackathoBadges);
    case "practice":
      return getBadge(level, practiceBadges);
    case "assessment":
      return getBadge(level, assessmentBadges);
    case "blog":
      return getBadge(level, blogbadges);
    default:
      break;
  }
};

export const ydoc = new Y.Doc();

export const parseJwt = (token) => {
  if (token) {
    var base64Url = token.split(".")[1];
    if (base64Url) {
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      try {
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      console.error("Invalid token format");
    }
  }
  return null; // for token undefined, null, empty string
};
