import React, { useEffect, useRef, useState } from "react";
import arrowWhite from "../../../../assets/Icons/Assessment/arrowWhite.svg";
import CreatePlanPopup from "./CreatePlanPopup";
import { SubscriptionPlanService } from "services/subscription.service";
import NoDataimage from "../../../../assets/Images/noData.svg";
import NewAddIcon from "../../../../assets/Icons/Jobs/newAddJob.svg";
import "../../PricingPages/pricing.css";
import "./subscriptionPlan.css";
import { useDispatch, useSelector } from "react-redux";
import CreateFAQPopup from "./CreateFAQPopup";
import ChevronDown from "../../../../assets/Icons/DashboardNew/Dropdown.svg";
import EditIcon from "../../../../assets/Icons/edit.svg";
import DeleteIcon from "../../../../assets/Icons/delete-icon.svg";
import ConfirmationModal from "pages/PrivatePages/JobsPage/components/ConfirmationModal/ConfirmationModal";
import { updateSubscriptionFAQ } from "store/reducers/subscriptionSlice";
import { deleteSubscriptionFAQ } from "store/reducers/subscriptionSlice";

const SubscriptionPlans = () => {
  const [subscriptionplans, setSubscriptionplans] = useState([]);
  const [activeTab, setActiveTab] = useState("plans");
  const [isLoading, setIsLoading] = useState(true);
  const [showCreatePlanPopup, setShowCreatePlanPopup] = useState(false);
  const [showCreateFAQPopup, setShowCreateFAQPopup] = useState(false);
  const subscriptions = useSelector((state) => state.subscription.plans);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      const plans = JSON.parse(JSON.stringify(subscriptions));
      setSubscriptionplans(plans);
    }
    setIsLoading(false);
  }, [subscriptions?.length]);

  return (
    <div className="Subscription-plan-container">
      <div className="d-flex justify-content-between align-items-center subscriptionheadinghld">
        <button
          className="primaryBtnActive"
          onClick={() =>
            activeTab === "plans"
              ? setShowCreatePlanPopup(!showCreatePlanPopup)
              : setShowCreateFAQPopup(!showCreateFAQPopup)
          }
        >
          Create {activeTab === "plans" ? "Plan" : "FAQ"}
          <img
            className="ms-3 assessmentSelectRoleModalButtonImg"
            src={arrowWhite}
          />
        </button>
        <div className="subscription-tabs">
          <p
            className={`text-center subsheading mb-0 ${
              activeTab === "plans" ? "active-subscription-tab" : ""
            }`}
            onClick={() => setActiveTab("plans")}
          >
            Subscription Plans
          </p>
          <p
            className={`text-center subsheading mb-0 ${
              activeTab === "faq" ? "active-subscription-tab" : ""
            }`}
            onClick={() => setActiveTab("faq")}
          >
            Faq
          </p>
        </div>
        <div className="w200"></div>
      </div>
      <div className="row py-5 px-4">
        {activeTab === "plans" ? (
          <Plans
            subscriptionplans={subscriptionplans}
            setSubscriptionplans={setSubscriptionplans}
            isLoading={isLoading}
            showCreatePlanPopup={showCreatePlanPopup}
            setShowCreatePlanPopup={setShowCreatePlanPopup}
            activeTab={activeTab}
          />
        ) : (
          <FAQS
            showCreateFAQPopup={showCreateFAQPopup}
            setShowCreateFAQPopup={setShowCreateFAQPopup}
            activeTab={activeTab}
          />
        )}
      </div>
    </div>
  );
};

const NoData = ({ action, activeTab }) => {
  return (
    <div className="text-center">
      <img
        src={NoDataimage}
        className="img-fluid nodata-img mb-4"
        alt="no-data"
      />
      <p className="nodata-text mb-4">
        Create your Customized plan for your enterprise
      </p>
      <div className="text-center">
        <button className="primaryBtnActive" onClick={() => action(false)}>
          Create {activeTab === "plans" ? "Plan" : "FAQ"}
          <img
            src={NewAddIcon}
            className="img-fluid ms-2"
            alt="create"
            width="12"
            height="12"
          />
        </button>
      </div>
    </div>
  );
};

const Plans = ({
  subscriptionplans,
  setSubscriptionplans,
  isLoading,
  showCreatePlanPopup,
  setShowCreatePlanPopup,
  activeTab,
}) => {
  const publishSubscriptionPlan = async (planId, publishType) => {
    try {
      const requesbody = {
        publish: publishType,
      };
      const updated_plan_response =
        await SubscriptionPlanService.updateSubscriptionPlans(
          planId,
          requesbody
        );
      if (updated_plan_response.data) {
        setSubscriptionplans((prev) =>
          prev.filter((x) => {
            if (x._id === planId) {
              x.publish = updated_plan_response.data.publish;
            }
            return prev;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          {subscriptionplans.length === 0 ? (
            <NoData
              action={() => setShowCreatePlanPopup(!showCreatePlanPopup)}
              activeTab={activeTab}
            />
          ) : (
            <>
              {subscriptionplans?.map((plan) => (
                <div className="col-lg-3 col-12 plansCntr" key={plan?._id}>
                  <div className="plansHld">
                    <p className="subheadingText text-capitalize">
                      {plan?.name}
                    </p>
                    <p className="mb-3 pricingText">
                      <span className="subheadingText">
                        {plan?.currencyType === "USD" ? "$" : "₹"}
                      </span>{" "}
                      {plan?.amount}
                      <span className="subheadingText">
                        /{plan?.period === "monthly" ? "mth" : "Yr"}
                      </span>
                    </p>
                    <div className="planFeatureshld">
                      <div className="planList d-flex align-items-center">
                        <div
                          className="planModalText"
                          dangerouslySetInnerHTML={{
                            __html: plan?.description,
                          }}
                        ></div>
                      </div>
                    </div>
                    {plan.publish ? (
                      <button
                        className="unpublish-btn"
                        onClick={() =>
                          publishSubscriptionPlan(plan?._id, !plan.publish)
                        }
                      >
                        Unpublish
                      </button>
                    ) : (
                      <button
                        className="publish-btn"
                        onClick={() =>
                          publishSubscriptionPlan(plan?._id, !plan.publish)
                        }
                      >
                        Publish
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
      <CreatePlanPopup
        showCreatePlanPopup={showCreatePlanPopup}
        setShowCreatePlanPopup={setShowCreatePlanPopup}
        setSubscriptionplans={setSubscriptionplans}
        subscriptionplans={subscriptionplans}
      />
    </>
  );
};

const FAQS = ({ showCreateFAQPopup, setShowCreateFAQPopup, activeTab }) => {
  const subscriptionsFaqs = useSelector((state) => state.subscription.faqs);
  const [showFaqOptions, setShowFaqOptions] = useState("");
  const [showDeleteFaqsPopup, setShowDeleteFaqsPopup] = useState("");
  const [editFaqData, setEditFaqData] = useState({});
  const [isFAQLoading, setIsFAQLoading] = useState(true);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const dispatch = useDispatch();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFaqOptions("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onFaqOptionClick = (faqs) => {
    if (showFaqOptions) {
      setShowCreateFAQPopup(true);
      setShowFaqOptions("");
      setEditFaqData(faqs);
    } else {
      setShowFaqOptions(faqs?._id);
    }
  };

  const deleteFaq = async (faqId) => {
    try {
      const updated_plan_response =
        await SubscriptionPlanService.deleteSubscriptionFaqs(faqId);
      if (updated_plan_response.data) {
        setShowFaqOptions("");
        setShowDeleteFaqsPopup("");
        dispatch(deleteSubscriptionFAQ(updated_plan_response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const publishSubscriptionFAQ = async (faqId, publishType) => {
    try {
      const requesbody = {
        publish: publishType,
      };
      const updated_plan_response =
        await SubscriptionPlanService.updateSubscriptionFaqs(faqId, requesbody);
      if (updated_plan_response.data) {
        dispatch(updateSubscriptionFAQ(updated_plan_response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setIsFAQLoading(false);
  }, []);
  return (
    <>
      {isFAQLoading ? (
        <></>
      ) : (
        <>
          {subscriptionsFaqs.length === 0 ? (
            <NoData
              action={() => setShowCreateFAQPopup(!showCreateFAQPopup)}
              activeTab={activeTab}
            />
          ) : (
            <div className="subscription-list-page">
              {subscriptionsFaqs?.map((faqs) => (
                <div className="user-subscription-hld pe-0" key={faqs._id}>
                  <div className="col-lg-9 col-12">
                    <p className="faqQuestion mb-2">{faqs?.question}</p>
                    <p className="faqAnswer">{faqs?.answer}</p>
                  </div>
                  <div className="position-relative">
                    <button
                      className={`faqEditbtn me-3 ${
                        showFaqOptions === faqs?._id
                          ? "faqdropdownActiveeditbtn"
                          : ""
                      }`}
                      onClick={() => onFaqOptionClick(faqs)}
                    >
                      <img src={EditIcon} className="img-fluid" />
                      <span>Edit</span>{" "}
                      <img src={ChevronDown} className="img-fluid" />
                    </button>
                    {showFaqOptions === faqs?._id ? (
                      <button
                        className="faqEditbtn faqdeletebtn me-3 faqOptiondropdown"
                        onClick={() => setShowDeleteFaqsPopup(faqs?._id)}
                      >
                        <img src={DeleteIcon} className="img-fluid" />
                        <span>Delete</span>
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className="faqbtn"
                      onClick={() =>
                        publishSubscriptionFAQ(faqs._id, !faqs.publish)
                      }
                    >
                      {faqs?.publish ? "Unpublish" : "Publish"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <CreateFAQPopup
        showCreateFAQPopup={showCreateFAQPopup}
        setShowCreateFAQPopup={setShowCreateFAQPopup}
        editFaqData={editFaqData}
        setEditFaqData={setEditFaqData}
      />
      <ConfirmationModal
        ModalHeading={"Delete FAQ"}
        ModalBody={"Are you sure you want to delete?"}
        closeIcon={true}
        ActionBtnName={"Yes"}
        closeBtnName={"No"}
        ActionBtn={() => deleteFaq(showDeleteFaqsPopup)}
        showPopup={showDeleteFaqsPopup}
        setShowPopup={() => {
          setShowDeleteFaqsPopup("");
          setShowFaqOptions("");
        }}
      />
    </>
  );
};

export default SubscriptionPlans;
