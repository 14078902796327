import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import arrowWhite from "../../../../assets/Icons/Assessment/arrowWhite.svg";
import { SubscriptionPlanService } from "services/subscription.service";

const periodType = [
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "yearly" },
];
const currencyType = [
  { value: "USD", label: "USD" },
  { value: "INR", label: "INR" },
];
const planType = [
  { value: "basic", label: "Basic" },
  { value: "professional", label: "Professional" },
];
const limitType = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: Number.MAX_SAFE_INTEGER, label: "Unlimited" },
];
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#3c3e4d",
    padding: 10,
    textCenter: "left",
    zIndex: 999,
    fontSize: "14px",
    backgroundColor: "#ffffff",
    textTransform: "capitalize",
  }),
  control: (base, state) => ({
    ...base,
    marginTop: "10px",
    backgroundColor: state.isFocused ? "rgba(150, 155, 160, 0.20)" : "#ffffff",
    border: "solid 1px #DDD",
    borderRadius: 8,
    paddingRight: "15px",
    paddingLeft: "3px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
    textTransform: "capitalize",
    fontWeight: 400,
    fontSize: 14,
    color: "#000",
  }),
  placeholder: (base, state) => ({
    color: "#BBB",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    color: "#575757",
    fontSize: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
  }),
};

const CreatePlanPopup = (props) => {
  const {
    showCreatePlanPopup,
    setShowCreatePlanPopup,
    setSubscriptionplans,
    subscriptionplans,
  } = props;
  const [subscriptionFormData, setSubscriptionFormData] = useState({
    name: "",
    description: "",
    amount: "",
    period: "monthly",
    currency: "USD",
    actualPrice: "",
    liveInterviewCount: "",
    assessmentCount: "",
    shortDescription: "",
  });
  const [submitClick, setSubmitClick] = useState(false);
  const onhandleChange = (event) => {
    const { name, value } = event.target;
    setSubscriptionFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setShowCreatePlanPopup(!showCreatePlanPopup);
  };
  const createSubscriptionPlan = async () => {
    try {
      setSubmitClick(true);
      const requesbody = { ...subscriptionFormData };
      requesbody.name = subscriptionFormData.name.value;
      requesbody.currency = subscriptionFormData.currency.value;
      requesbody.period = subscriptionFormData.period.value;
      requesbody.assessmentCount = subscriptionFormData.assessmentCount.value;
      requesbody.liveInterviewCount =
        subscriptionFormData.liveInterviewCount.value;
      const create_plans_res =
        await SubscriptionPlanService.createSubscriptionPlan(requesbody);
      setSubmitClick(false);
      if (create_plans_res.data) {
        setShowCreatePlanPopup(false);
        setSubscriptionplans((prev) => [...prev, create_plans_res.data]);
        setSubscriptionFormData({
          name: "",
          description: "",
          amount: "",
          period: "monthly",
          currency: "USD",
          actualPrice: "",
          liveInterviewCount: "",
          assessmentCount: "",
          shortDescription: "",
        });
      }
    } catch (err) {
      setSubmitClick(false);
      console.log(err);
    }
  };
  return (
    <Modal
      centered="true"
      dialogClassName="createPlanModal"
      size="lg"
      show={showCreatePlanPopup}
      onHide={handleClose}
    >
      <Modal.Header className="px-4 mx-2" closeButton>
        <Modal.Title>Create a Subscription Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 mx-2">
        <div className="createPlanModalBody">
          <div className="row">
            <div className="col-lg-6">
              <div className="plan-form-group">
                <label>Plan</label>
                <Select
                  name="currency"
                  styles={customStyles}
                  onChange={(event) =>
                    setSubscriptionFormData((prev) => ({
                      ...prev,
                      name: event,
                    }))
                  }
                  options={planType}
                  value={subscriptionFormData.name}
                  placeholder="Currency"
                />
              </div>
              <div className="row">
                <div className="col-lg-6 plan-form-group">
                  <label>Assessments </label>
                  <Select
                    name="assessmentCount"
                    styles={customStyles}
                    onChange={(event) =>
                      setSubscriptionFormData((prev) => ({
                        ...prev,
                        assessmentCount: event,
                      }))
                    }
                    options={limitType}
                    value={subscriptionFormData.assessmentCount}
                    placeholder="Select Limit"
                  />
                </div>
                <div className="col-lg-6 plan-form-group">
                  <label>Interviews</label>
                  <Select
                    name="liveInterviewCount"
                    styles={customStyles}
                    onChange={(event) =>
                      setSubscriptionFormData((prev) => ({
                        ...prev,
                        liveInterviewCount: event,
                      }))
                    }
                    options={limitType}
                    value={subscriptionFormData.liveInterviewCount}
                    placeholder="Select Limit"
                  />
                </div>
                <div className="plan-form-group">
                  <label>Currency Type</label>
                  <Select
                    name="currency"
                    styles={customStyles}
                    onChange={(event) =>
                      setSubscriptionFormData((prev) => ({
                        ...prev,
                        currency: event,
                      }))
                    }
                    options={currencyType}
                    value={subscriptionFormData.currency}
                    placeholder="Currency"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="plan-form-group">
                <label>Actual Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Eg: 199.99"
                  name="actualPrice"
                  value={subscriptionFormData.actualPrice}
                  onChange={onhandleChange}
                />
              </div>
              <div className="plan-form-group">
                <label>Discount Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Eg: 149.99"
                  name="amount"
                  value={subscriptionFormData.amount}
                  onChange={onhandleChange}
                />
              </div>
              <div className="plan-form-group">
                <label>Period</label>
                <Select
                  name="period"
                  styles={customStyles}
                  onChange={(event) =>
                    setSubscriptionFormData((prev) => ({
                      ...prev,
                      period: event,
                    }))
                  }
                  options={periodType}
                  value={subscriptionFormData.period}
                  placeholder="Period"
                />
              </div>
            </div>
          </div>
          <div className="plan-form-group">
            <label>Short Description</label>
            <textarea
              type="number"
              className="form-control"
              placeholder="Eg: description about the plan(Razorpay)"
              name="shortDescription"
              value={subscriptionFormData.shortDescription}
              onChange={onhandleChange}
              rows={3}
            />
          </div>
          <div className="mt-3">
            <label>Description</label>
            <CKEditor
              editor={Editor}
              config={{
                toolbar: {
                  items: [
                    "Essentials",
                    "Heading",
                    "Paragraph",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "code",
                    "subscript",
                    "superscript",
                    "removeFormat",
                    "   ",
                    "link",
                    "insertImage",
                    "blockQuote",
                    "mediaEmbed",
                    "codeBlock",
                    "htmlEmbed",
                    "    ",
                    "bulletedList",
                    "numberedList",
                    "alignment",
                    "    ",
                    "undo",
                    "redo",
                    "    ",
                  ],
                },
              }}
              data={subscriptionFormData.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSubscriptionFormData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
              name="description"
            />
          </div>
          {subscriptionFormData.name &&
          subscriptionFormData.description &&
          subscriptionFormData.amount &&
          subscriptionFormData.actualPrice &&
          subscriptionFormData.assessmentCount &&
          subscriptionFormData.currency &&
          subscriptionFormData.liveInterviewCount &&
          subscriptionFormData.period &&
          subscriptionFormData.shortDescription &&
          !submitClick ? (
            <button
              className="primaryBtnActive mx-auto d-block mt-4 mb-2"
              onClick={createSubscriptionPlan}
            >
              Submit
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          ) : (
            <button
              className="primaryBtnActive mx-auto d-block mt-4 mb-2 opacity50"
              disabled
            >
              Submit
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePlanPopup;
