import { JitsiMeeting } from "@jitsi/react-sdk";
import axios from "axios";
import { BootcampBackend } from "backend";
import { isAuthenticated } from "helper/user";

import React, { memo, useEffect, useRef, useState } from "react";

const JitsiSDK = memo(function JitsiComponent() {
  const [jitisiLink, setJitsiLink] = useState({});
  const [interviewData, setInterviewData] = useState({});
  const apiRef = useRef();
  const interviewToken = window.location?.href?.split("/")[5];
  const getInterviewRoundData = async () => {
    try {
      const res = await axios.get(
        `${BootcampBackend}/interview/getInterviewRoundTabData/${interviewToken}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      );

      setJitsiLink({
        roomName: res.data.result.interviewData?.interviewRoundId._id,
        jitisiLink: res.data.result.interviewData?.interviewRoundId.jitisiLink,
      });
      setInterviewData(res.data.result.interviewData);
    } catch (error) {
      console.error("Oops", error);
      setJitsiLink({});
    }
  };
  // const generateRoomName = () =>
  //   `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;
  const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    // updateLog((items) => [
    //   ...items,
    //   `you have ${payload.unreadCount} unread messages`,
    // ]);
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "200px";
    iframeRef.style.marginBottom = "20px";
  };

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.on("chatUpdated", handleChatUpdates);
  };

  const handleReadyToClose = () => {
    alert("Ready to close...");
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );

  useEffect(() => {
    getInterviewRoundData();
  }, []);

  return (
    <>
      {jitisiLink.jitisiLink && (
        <JitsiMeeting
          domain={new URL(jitisiLink.jitisiLink).host}
          roomName={jitisiLink.roomName}
          spinner={renderSpinner}
          configOverwrite={{
            prejoinPageEnabled: false,
            subject: "Enterprise Meet",
            hideConferenceSubject: false,
          }}
          interfaceConfigOverwrite={{
            TOOLBAR_BUTTONS: [
              "microphone",
              "camera",
              isAuthenticated() ? "recording" : "",
              "desktop",
            ],
          }}
          onApiReady={(externalApi) => handleApiReady(externalApi)}
          onReadyToClose={handleReadyToClose}
          getIFrameRef={handleJitsiIFrameRef1}
          userInfo={{
            displayName: isAuthenticated()
              ? interviewData?.interviewerId?.firstName || "Interviewer"
              : interviewData?.interviewId?.candidateName || "Candidate",
            email: isAuthenticated()
              ? interviewData?.interviewerId?.emailId || ""
              : interviewData?.interviewId?.candidateEmail || "",
          }}
        />
      )}
    </>
  );
});

export default JitsiSDK;
