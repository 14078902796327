import axios from "axios";
import { BootcampBackend } from "backend";

export function GetAuthorizationConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : null,
    },
  };
}

export const lmsService = {
  fetchLMSLeaderboard: async (studyplanSlug, currentPage) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/leaderboard?limit=10&current_page=${currentPage}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchParticipantsStats: async (studyplanSlug) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/participants`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchDayplansStats: async (studyplanSlug,filterData) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/dayplans?filter=${filterData}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchContentsStats: async (studyplanSlug, filterData) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/contents?filter=${filterData}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchUserDaywiseStats: async (studyplanSlug, userId, currentPage,filterData) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/leaderboard/${userId}?current_page=${currentPage}&filter=${filterData}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchUserStats: async (studyplanSlug, userId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/leaderboard/${userId}/all`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchStudyPlanDetails: async (studyplanSlug) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  searchUser: async (studyplanSlug,searchValue) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/elastic/search/${studyplanSlug}?filter=${searchValue}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          let response = {
            data :[],
            totalUsersInLeaderboard:0
          }
          if (err.response.status === 404) {
            return resolve(response);
          }
          else{
            return reject(err);
          }
        });
    });
  },
  fetchDownloadData: async (studyplanSlug) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/lms/studyplans/${studyplanSlug}/stats/csv`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
