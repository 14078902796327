// export const role = getUserRole();

export function getUserRole() {
  if (JSON.parse(localStorage.getItem("jwt"))) {
    if (JSON.parse(localStorage.getItem("jwt")).role) {
      return JSON.parse(localStorage.getItem("jwt")).role;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    if (Number(localStorage.getItem("loginTime")) < Date.now() - 1000 * 86400 * 7) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("loginTime");
      window.location.replace("/login");
    }

    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};
