import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingShape } from "react-rainbow-components";
import { useSelector } from "react-redux";
import EmptyData from "./EmptyData";

function SampleNextArrow(props) {
  const { onClick, className } = props;

  return (
    <div
      className="carousel-arrow-hld carousel-arrow-hld-left"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        className="circle-check"
        fontSize={className?.includes("slick-disabled") ? 12 : 13}
        color={className?.includes("slick-disabled") ? "#979797" : "#757575"}
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick, className } = props;
  return (
    <div
      className="carousel-arrow-hld carousel-arrow-hld-right"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="circle-check"
        fontSize={className?.includes("slick-disabled") ? 12 : 13}
        color={className?.includes("slick-disabled") ? "#979797" : "#757575"}
      />
    </div>
  );
}
var settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 3,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 4000,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
export default function TopPerformers() {
  const lmsLeaderboard = useSelector((store) => store.lms.lms_leaderboard);
  const isLoading = useSelector((store) => store.lms.lms_leaderboard_loader);
  return (
    <div className="study-plan-card-inner mt-4">
      <div className="study-plan-carousel-hld d-flex justify-content-between align-items-center mb-4 pb-2">
        {isLoading ? (
          <LoadingShape className="mb-4 w-25" />
        ) : (
          <h1 className="card-heading">Top Performers</h1>
        )}
      </div>
      <div className="">
        {isLoading ? (
          <div className="d-flex justify-content-between align-items-center">
            <LoadingShape className="me-2" shape="square" />
            {Array.from({ length: 3 }).map((_, i) => (
              <div className="user-card col-3 me-1" key={i}>
                <div className="">
                  <LoadingShape
                    className="mb-2 d-flex justify-content-center"
                    variant="avatar"
                    shape="circle"
                  />

                  <LoadingShape className="mb-2" />
                  <LoadingShape className="mb-2 h-50" />
                </div>
              </div>
            ))}
            <LoadingShape className="ms-2" shape="square" />
          </div>
        ) : (
          <>
            {lmsLeaderboard.length === 0 ? (
              <EmptyData />
            ) : (
              <Slider {...settings} className="CarouselEvents">
                {lmsLeaderboard.map((topper, index) => (
                  <div className="user-card text-center" key={index}>
                    <img
                      src={topper?.user?.Picture}
                      alt="user"
                      className="studyplan-inner-user-image mb-2"
                    />
                    <h3 className="user-name-text">
                      {topper?.user?.Name?.length > 38
                        ? topper?.user?.Name?.substring(0, 35) + "..."
                        : topper?.user.Name}
                    </h3>
                    <div className={`score-tab mt-2 score-tab-${index + 1}`}>
                      <FontAwesomeIcon
                        icon={faArrowTrendUp}
                        className="me-1 circle-check"
                        fontSize={10}
                      />
                      Score
                      <span className="ps-1">{topper?.score}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </>
        )}
      </div>
    </div>
  );
}
