import React from "react";
import "./assessment.css";
import { useHistory } from "react-router-dom";
import NoAssessmentImage from "../../../../assets/Icons/Dashboard/NoAssessment.svg";
import AdditionIcon from "../../../../assets/Icons/Dashboard/AdditionIcon.png";

function NoAssessment({ myAssessments, isLoading }) {
  const history = useHistory();

  return (
    <>
      <div className="col-xl-6 col-lg-12">
        <div className="dashboardCard assessment">
          <div className="d-flex assessmentData justify-content-between align-items-center">
            <h5 className="dashboardHeading">
              Recent Assessments ({myAssessments.length})
            </h5>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center dashboardNoSchedule">
            <img
              className="image-fluid dashboardNoAssessmentImage mt-5"
              src={NoAssessmentImage}
              alt="hackthon"
            />
            <p className="dashboardNothingFoundText mt-3">
              No assessment created, try creating your assessment now.
            </p>
            <div
              className="dashboardNoAssessmentButton mt-3 mb-5 d-flex align-items-center justify-content-center"
              onClick={() => {
                history.push("/assessments");
              }}
            >
              <p>Create Assessment</p>
              <img
                className="image-fluid ms-2 dashboardNoAssessmentAdditionIcon"
                src={AdditionIcon}
                alt="hackthon"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoAssessment;
