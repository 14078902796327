import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Pagination } from "react-rainbow-components";
import { SubscriptionPlanService } from "services/subscription.service";
import DefaultCompanyLogo from "../../../../assets/Icons/AdminView/NocompanyOne.svg";
import PremiumIcon from "../../../../assets/Icons/Pricing/crown.svg";
import InvoicePopup from "pages/PrivatePages/SettingsPage/Sections/InvoicePopup/InvoicePopup";
import NoDataimage from "../../../../assets/Images/noData.svg";
import ContactUs from "components/ContactUs/ContactUs";
import cross from "../../../../assets/Icons/Assessment/cross.svg";

const subscriptionStatus = [
  {
    id: "1",
    name: "Active",
    slug: "active",
  },
  {
    id: "2",
    name: "Expired",
    slug: "expired",
  },
  {
    id: "3",
    name: "Cancelled",
    slug: "cancelled",
  },
  {
    id: "4",
    name: "Upgraded",
    slug: "upgraded",
  },
];

const subscriptionType = [
  {
    id: "1",
    name: "Free",
    slug: "free",
  },
  {
    id: "2",
    name: "Paid",
    slug: "paid",
  },
];

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [currentpage, setCurrentPage] = useState(1);
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState({
    type: "",
    status: [],
  });
  const [showContactUs, setShowContactUs] = useState(false);

  const resetAll = () => {
    setFilters({ type: "", status: [] });
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilterOptions(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const getAllInvoices = async () => {
    const paramaData = {
      current_page: currentpage,
      limit: 10
    };
    if (filters.type) {
      paramaData.type = filters.type;
    }
    if (filters.status.length > 0) {
      paramaData.status = filters.status.join();
    }
    try {
      const all_invoices = await SubscriptionPlanService.fetchAllInvoices(
        paramaData
      );
      setIsLoading(false);
      setInvoices(all_invoices?.data);
      setTotalInvoices(all_invoices?.count);
      setShowFilterOptions(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getAllInvoices();
  }, [currentpage]);
  return (
    <div className="invoices-cntr">
      <div className="my-subscription-header">
        <h5 className="">Invoices</h5>
        <button
          className="filter-btn filter-btn-right me-4"
          onClick={() => setShowFilterOptions(!showFilterOptions)}
        >
          filter
        </button>
        {showFilterOptions ? (
          <div className="filter-dropdown pt-0" ref={wrapperRef}>
            <div className="pt-3">
              <p className="filter-head">SUBSCRIPTION STATUS </p>
              {subscriptionStatus?.map((status, index) => (
                <div className="d-flex align-items-center" key={status?.id}>
                  <input
                    type="checkbox"
                    className="newAssessmentListCustomCheckBox me-1"
                    checked={filters.status?.includes(status.slug)}
                    onChange={() => {
                      const newFilterArray = { ...filters };
                      if (newFilterArray.status.includes(status.slug)) {
                        const index = newFilterArray.status.indexOf(
                          status.slug
                        );
                        if (index > -1) {
                          newFilterArray.status.splice(index, 1);
                        }
                      } else {
                        newFilterArray.status.push(status.slug);
                      }
                      setFilters((prev) => ({
                        ...prev,
                        status: newFilterArray.status,
                      }));
                    }}
                  />
                  <span className="newAssessmentFilterRadio ps-3 cursor-pointer">
                    {status.name}
                  </span>
                </div>
              ))}
              <hr className="mb-3 mt-2" />
              <p className="filter-head">SUBSCRIPTION TYPE </p>

              {subscriptionType.map((type) => (
                <div className="d-flex align-items-center" key={type.id}>
                  <input
                    type="radio"
                    className="AssessmentListRadioBtn me-1"
                    checked={filters.type === type.slug}
                    onChange={() => setFilters({ ...filters, type: type.slug })}
                  />{" "}
                  <span className="newAssessmentFilterRadio ps-3 cursor-pointer">
                    {type.name}
                  </span>
                </div>
              ))}
              <div className={`d-flex ${filters.type || filters.status.length > 0 ? "justify-content-between" : "justify-content-center"}`}>
                {filters.type || filters.status.length > 0 ? (
                  <button
                    className="filter-btn active-filter-btn mx-auto d-block mt-3 px-4 w-50"
                    onClick={() => getAllInvoices()}
                  >
                    Apply
                  </button>
                ) : (
                  <button className="filter-btn mx-auto d-block mt-3 w-50" disabled>
                    Apply
                  </button>
                )}
                {filters.type || filters.status.length > 0 ? (
                  <button
                    className="newAssessmentListFiltersButton mt-3 px-2"
                    onClick={resetAll}
                  >
                    Clear all <img className="ms-2" src={cross} />
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="subscription-list-page">
        {isLoading ? (
          <></>
        ) : totalInvoices === 0 ? (
          <div className="text-center my-5 py-5">
            <img
              src={NoDataimage}
              className="img-fluid nodata-img mb-4"
              alt="no-data"
            />
            <p className="nodata-text mb-4">No Invoices</p>
          </div>
        ) : (
          invoices?.map((userPlan, index) => (
            <React.Fragment key={`invoice-${index}`}>
              <p className="subscription-plan-text mb-2 mt-4">
                {userPlan?.subscribedOn}
              </p>
              {userPlan?.subscriptions.map((plan) => (
                <div className="user-subscription-hld" key={plan?._id}>
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <img
                        src={plan?.companyId?.logo || DefaultCompanyLogo}
                        alt="company-logo"
                        className="company-logo"
                      />
                    </div>
                    <div className="">
                      <div className="d-flex align-items-center">
                        <p className="subscription-details subscription-plan-name text-capitalize me-3">
                          {plan?.companyId?.companyName} Plan
                        </p>
                        <p
                          className={`subscription-status text-capitalize ${
                            plan?.subscriptionStatus === "success" &&
                            moment().isBefore(plan?.endsAt)
                              ? "subscription-active-status"
                              : ""
                          }`}
                        >
                          {plan?.subscriptionStatus === "success"
                            ? moment().isBefore(plan?.endsAt)
                              ? "Active"
                              : "Expired"
                            : plan?.subscriptionStatus}
                        </p>
                      </div>
                      <div className="subscription-details-hld mt-1">
                        <p className="subscription-details subscription-plan-name text-capitalize">
                          {plan?.mh_subscription_plan_id?.name ===
                          "professional" ? (
                            <img
                              src={PremiumIcon}
                              alt="premium"
                              className={`img-fluid me-2`}
                            />
                          ) : (
                            <></>
                          )}
                          {plan?.mh_subscription_plan_id?.name}
                        </p>
                        <p className="subscription-details subscription-time-period">
                          {moment(plan?.startsAt).format("MMM DD, YYYY")} -{" "}
                          {moment(plan?.endsAt).format("MMM DD, YYYY")}
                        </p>
                        <p className="subscription-details subscription-plan-text text-capitalize">
                          {plan?.subscriptionPeriod}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="downloadInvoice-btn"
                      onClick={() => {
                        setSelectedPlan(plan);
                        setShowInvoicePopup(!showInvoicePopup);
                      }}
                    >
                      Download Invoice
                    </button>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))
        )}
        {totalInvoices > 10 ? (
          <Pagination
            className="rainbow-m_auto candidatesListPagination"
            pages={totalInvoices > 0 ? parseInt(totalInvoices / 10) + 1 : 1}
            activePage={currentpage}
            onChange={(event, page) => setCurrentPage(page)}
            variant="shaded"
          />
        ) : (
          <></>
        )}
      </div>
      <InvoicePopup
        showInvoicePopup={showInvoicePopup}
        setShowInvoicePopup={setShowInvoicePopup}
        selectedPlan={selectedPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type={"pricing"}
      />
    </div>
  );
};
export default Invoices;
