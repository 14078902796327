import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/authSlice";
import subscriptionSlice from "./reducers/subscriptionSlice";
import organizationSlice from "./reducers/organizationSlice";
import lmsSlice from "./reducers/lmsSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    subscription: subscriptionSlice,
    organization: organizationSlice,
    lms: lmsSlice,
  },
});
