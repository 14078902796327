import React from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import heatmapColor_1 from "../../../../../../assets/Icons/MyHackathons/heatmapColor_1.svg";
import heatmapColor_2 from "../../../../../../assets/Icons/MyHackathons/heatmapColor_2.svg";
import heatmapColor_3 from "../../../../../../assets/Icons/MyHackathons/heatmapColor_3.svg";
import heatmapColor_4 from "../../../../../../assets/Icons/MyHackathons/heatmapColor_4.svg";
import heatmapColor from "../../../../../../assets/Icons/MyHackathons/heatmapColor.svg";
import "./UserCalendarHeatmap.css";
function UserCalendarHeatmap() {
  return (
    <div className="hackathonCalendar">
      <CalendarHeatmap
        startDate={new Date("2021-01-01")}
        endDate={new Date("2021-12-31")}
        showWeekdayLabels={true}
        values={[
          { date: "2021-01-02", count: 1 },
          { date: "2021-01-03", count: 4 },
          { date: "2021-03-06", count: 2 },
          { date: "2021-02-02", count: 1 },
          { date: "2021-04-03", count: 4 },
          { date: "2021-05-06", count: 2 },
          { date: "2021-01-12", count: 1 },
          { date: "2021-01-24", count: 4 },
          { date: "2021-08-15", count: 3 },
        ]}
        gutterSize={4}
        classForValue={(value) => {
          if (!value) {
            return "color-empty";
          }
          return `color-scale-${value.count}`;
        }}
      />
      <div className="float-end d-flex">
        Less
        <img className="mx-2" src={heatmapColor} />
        <img className="me-2" src={heatmapColor_1} />
        <img className="me-2" src={heatmapColor_2} />
        <img className="me-2" src={heatmapColor_3} />
        <img className="me-2" src={heatmapColor_4} />
        More
      </div>
    </div>
  );
}

export default UserCalendarHeatmap;
