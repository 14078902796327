//CSS
import "./hackathonStatistics.css";

//ICONS
import NoHackthonImage from "../../../../assets/Icons/Dashboard/NoHackthon.svg";

//TILES
import React from "react";

export default function NoHackathonStatistics() {


  return (
    <>
        <div className="col-xl-8 col-lg-12">
          <div className="dashboardCard dashboardHackthonItem">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <h5 className="dashboardHeading">Hackathon Statistics</h5>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <img
                className="image-fluid dashboardNoHackthonStatistics mt-4"
                src={NoHackthonImage}
                alt="hackthon"
              />
              <p className="dashboardNothingFoundText mt-3 mb-5">
                You don't have any data to display.
              </p>
            </div>
          </div>
        </div>
    </>
  );
}
