import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AssessmentIcon from "../../../../assets/Icons/Interview/New/assessment.svg";
import PythonCodingIcon from "../../../../assets/Icons/Interview/New/pythonCoding.svg";
import WhiteboardIcon from "../../../../assets/Icons/Interview/New/whiteBoard.svg";
import { BootcampBackend } from "../../../../backend";
import axios from "axios";
// import "./interviewpage.css";

export default function QuestionType(props) {
  const [hoOver, setHoOver] = useState("");
  const interviewId = props.interviewId;
  const interviewRoundId = props.interviewRoundId;
  const interviewToken = props.interviewToken;
  const history = useHistory();
  useEffect(() => {}, []);
  const assessmentType = [
    {
      id: "1",
      name: "Python Coding",
      type: "coding",
      img: PythonCodingIcon,
      desc: "Test Python programming skills live",
      class: "codingImg",
    },
    {
      id: "2",
      name: "Whiteboard",
      type: "white_board",
      img: WhiteboardIcon,
      desc: " Discuss the solution approach on the whiteboard",
      class: "whiteBoardImg",
    },
    {
      id: "3",
      name: "Attach an Assessment",
      type: "assessment",
      img: AssessmentIcon,
      desc: "Assessment bank to test candidates live",
      class: "assessmentImg",
    },
  ];

  const addnewTabData = async (data) => {
    let body = {};
    body = {
      tabs: {
        tabID: props.interviewData?.tabs
          ? props.interviewData?.tabs?.length + 1
          : 1,
        tabType: data.name.includes("Coding")
          ? "coding"
          : data.name.includes("Whiteboard")
          ? "white_board"
          : data.name.includes("Diagram")
          ? "diagram"
          : "assessment",
        assessmentSlugRef: data?.name.includes("Assessment") && data.slug,
      },
    };
    body.currentInterviewerTab = props.interviewData?.currentInterviewerTab
      ? props.interviewData.currentInterviewerTab
      : 1;
    body.currentUserTab = props.interviewData?.currentUserTab
      ? props.interviewData.currentUserTab
      : 1;
    await axios
      .put(
        `${BootcampBackend}/interview/updateInterviewRoundTabData/${interviewToken}`,
        body,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        history.push(`/interview-paper/${data.id}/${interviewToken}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onquestionSelect = (data) => {
    props.selectedQuesType(data);
    if (data.id !== "3") {
      props.isFirstTime ? addnewTabData(data) : props.tabTitleUpdate(data);
    }
  };

  const isTabCreated = (type) => {
    let created = false;
    props?.Listoftabs?.map((tab) => {
      if (tab.tabType === type) {
        created = true;
      }
    });
    return !created;
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        {assessmentType.map((item) => (
          <React.Fragment key={item.id}>
            {isTabCreated(item.type) || item.type === "assessment" ? (
              <div className="assessmentTypeHld text-center ">
                <img
                  src={item.img}
                  alt="item.img"
                  loading="lazy"
                  className={`img-fluid assessmentTypeImg mx-4 ${item.class} ${
                    props.interviewType === "main" && "interviewTypeImg"
                  }`}
                  onClick={() => onquestionSelect(item)}
                />
                <p
                  className={`assessmentTypeName ${
                    props.interviewType === "main" && "interviewTypeName"
                  }`}
                >
                  {item.name}
                </p>
                <p
                  className={`assessmentTypeDescription mx-auto ${
                    props.interviewType === "main" && "interviewTypeDescription"
                  }`}
                >
                  {item.desc}
                </p>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
