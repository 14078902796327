import {
  faArrowDown,
  faArrowTrendDown,
  faArrowTrendUp,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CalendarIcon from "assets/Icons/lms/lms-calendar.svg";
import contactUs from "assets/Icons/DashboardNew/contactUs.svg";
import { lmsService } from "services/lms.service";
import { Machinehack_LMS_Url } from "backend";
import moment from "moment";
import Papa from 'papaparse';

const StudyPlanCard = ({ studyplanSlug }) => {
  const [studyPlanDetails, setStudyPlanDetails] = useState({});
  const [downloadData,setDownloadData] = useState([])
  const goToPlayground = () => {
    window.open(`${Machinehack_LMS_Url}/playground/${studyPlanDetails?.slug}`);
  };
  const getStudyPlanDeatils = async () => {
    try {
      const studyPlanDeatilsResponse = await lmsService.fetchStudyPlanDetails(
        studyplanSlug
      );
      setStudyPlanDetails(studyPlanDeatilsResponse);
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };
  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const downloadCsv = async()=>{
    if(downloadData?.length>0){
      const csvData = Papa.unparse(downloadData);
      exportData(csvData, `${studyPlanDetails?.slug}_${new Date().getTime()}.csv`, 'text/csv;charset=utf-8;');
    }
    else{
      const response = await lmsService.fetchDownloadData(studyplanSlug)
      setDownloadData(response);
      const csvData = Papa.unparse(response);
      exportData(csvData, `${studyPlanDetails?.slug}_${new Date().getTime()}.csv`, 'text/csv;charset=utf-8;');
    }
  }
  useEffect(() => {
    getStudyPlanDeatils();
  }, []);
  return (
    <div className={`study-plan-card study-plan-details`}>
      <div className="studyplan-status-hld">
        <span className="studyplan-duration-text">
          <img src={CalendarIcon} className="me-1" alt="duration" />
          Duration -{" "}
          {studyPlanDetails?.studyPlanState === "Ongoing"
            ? studyPlanDetails?.currentDay
            : studyPlanDetails?.studyPlanState === "Finished"
            ? studyPlanDetails?.totalNoOfDays
            : 0}
          /{studyPlanDetails?.totalNoOfDays} Days
        </span>
        <div className="">
          <span className="studyplan-startedAt me-3">
            {moment(studyPlanDetails?.startDate).fromNow()}
          </span>
          <span className="studyplan-status-percentage py-1">
            {studyPlanDetails?.studyPlanState === "Ongoing"
              ? (studyPlanDetails?.currentDay /
                  studyPlanDetails?.totalNoOfDays) *
                100
              : studyPlanDetails?.studyPlanState === "Finished"
              ? (studyPlanDetails?.totalNoOfDays /
                  studyPlanDetails?.totalNoOfDays) *
                100
              : 0}
            %
          </span>
        </div>
      </div>
      <p className="studyplan-title col-10" onClick={goToPlayground}>
        {studyPlanDetails?.title}
        {studyPlanDetails?.slug ? (
          <button className="view-play-ground-btn">
            View Play ground <img src={contactUs} alt="contact us" />
          </button>
        ) : (
          <></>
        )}
      </p>
      <div className="studyplan-percentage-representation-hld">
        <div
          className="studyplan-percentage-representation"
          style={{
            width: `${
              studyPlanDetails?.studyPlanState === "Ongoing"
                ? (studyPlanDetails?.currentDay /
                    studyPlanDetails?.totalNoOfDays) *
                  100
                : studyPlanDetails?.studyPlanState === "Finished"
                ? (studyPlanDetails?.totalNoOfDays /
                    studyPlanDetails?.totalNoOfDays) *
                  100
                : 0
            }%`,
          }}
        ></div>
      </div>
      <div className="d-flex justify-content-between">
        <span className="studyplan-dayChange">
          {studyPlanDetails?.studyPlanState === "Finished" ? (
            <>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2 circle-check"
                fontSize={15}
              />
              Total Completion - {studyPlanDetails?.participationPercentage}%
            </>
          ) : studyPlanDetails?.dayChangePercentage < 0 ? (
            <>
              <FontAwesomeIcon
                icon={faArrowTrendDown}
                className="me-2 circle-check"
                fontSize={15}
                color="#F44D5E"
              />
              <span className="studyplan-dayChange-negative">
                {studyPlanDetails?.dayChangePercentage % 2 === 0
                  ? studyPlanDetails?.dayChangePercentage
                  : studyPlanDetails?.dayChangePercentage.toFixed(1)}
                %
              </span>{" "}
              Down from yesterday
            </>
          ) : studyPlanDetails?.dayChangePercentage > 0 ? (
            <>
              <FontAwesomeIcon
                icon={faArrowTrendUp}
                className="me-2 circle-check"
                fontSize={15}
                color="#4EA184"
              />
              <span className="studyplan-dayChange-positive">
                +
                {studyPlanDetails?.dayChangePercentage % 2 === 0
                  ? studyPlanDetails?.dayChangePercentage
                  : studyPlanDetails?.dayChangePercentage.toFixed(1)}
                %
              </span>{" "}
              Up from yesterday
            </>
          ) : (
            <>
            </>
          )}
        </span>
        <button className="download-csv-button cursor-pointer" onClick={downloadCsv}>
          Download CSV <FontAwesomeIcon icon={faArrowDown} className="ms-1" />
        </button>
      </div>
    </div>
  );
};

export default StudyPlanCard;
