import React, { useEffect, useState } from "react";
import { LoadingShape } from "react-rainbow-components";
import { useSelector } from "react-redux";
import { lmsService } from "services/lms.service";
import EmptyData from "./EmptyData";

const CourseCompletion = ({ studyplanSlug, durationFilter }) => {
  const [courseCompletionStats, setCourseCompletionStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const totalParticipants = useSelector(
    (store) => store.lms.total_participants
  );
  const colors = ["#3480F1", "#FEC033", "#00E37E", "#FF78C1"];
  const fetchCourseCompletionStats = async () => {
    try {
      const courseCompletionStatsResponse = await lmsService.fetchDayplansStats(studyplanSlug,durationFilter.slug);
      setCourseCompletionStats(courseCompletionStatsResponse);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCourseCompletionStats();
  }, [durationFilter?.slug]);
  return (
    <div className="col-lg-6">
      <div className="overview-card-hld">
        {isLoading ? (
          <LoadingShape className="w-25 mb-4" />
        ) : (
          <h5>Course Completion</h5>
        )}
        {isLoading ? (
          <LoadingShape className="w-25 mb-2" />
        ) : (
          <p className="total-number-of-participants total-participants-heading py-2 my-2">
            {totalParticipants} Participants
          </p>
        )}
        <div className="row pt-3">
          {isLoading ? (
            <>
              {Array.from({ length: 4 }).map((_, i) => (
                <div key={i} className="col-6 mb-3">
                  <LoadingShape
                    className="mb-2 bar-graph-loader"
                    style={{ width: "10px", height: "10px" }}
                    shape="rounded-rect"
                  />
                  <LoadingShape className="w-25 mb-2 h-auto" />
                  <LoadingShape className="w-50 mb-2 h-auto" />
                </div>
              ))}
            </>
          ) : (
            <>
              {courseCompletionStats.length === 0 ? (
                <EmptyData />
              ) : (
                courseCompletionStats.map((item, i) => (
                  <div
                    className={`col-6 d-flex align-items-baseline ${
                      i < 2 ? "mb-4 pb-3" : "pb-2"
                    }`}
                    key={"course-stats" + i}
                  >
                    <div
                      className="course-completion-labels me-2"
                      style={{ background: colors[i] }}
                    />
                    <div className="">
                      <p className="studyplan-startedAt">
                        DAY {item.start} - {item.end}
                      </p>
                      <p className="studyplan-percentage-status">
                        {item.learnersPercentage % 2 === 0
                          ? item.learnersPercentage
                          : item.learnersPercentage.toFixed(1)}
                        %
                        {item.dayChangePercentage !== 0 ? (
                          <span
                            className={`course-studyplan-dayChange ms-2 ${
                              item?.dayChangePercentage > 0
                                ? "studyplan-positive-dayChange"
                                : item?.dayChangePercentage < 0
                                ? "studyplan-negative-dayChange"
                                : "studyplan-not-started-dayChange"
                            }`}
                          >
                            {item.dayChangePercentage > 0 ? "+" : ""}
                            {item?.dayChangePercentage % 2 === 0 ||
                            typeof item?.dayChangePercentage === "string"
                              ? item?.dayChangePercentage
                              : item?.dayChangePercentage?.toFixed(1)}
                            {typeof item.dayChangePercentage === "number"
                              ? "%"
                              : ""}
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCompletion;
