import React, { useEffect } from "react";
import "./Assessment.css";
import Assessments from "pages/PrivatePages/Assessment/index";


function Assessment() {
  // const [myAssessments, setMyAssessments] = useState([]);
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [isPageLoading, setIsPageLoading] = useState(true);
  // const [totalCount, setTotalCount] = useState(null);
  // const history = useHistory();
  // const [activePage, setActivePage] = useState(1);

  // var takeAssessment = myAssessments[0];
  // const [selectAssessment, setSelectedAssessment] = useState({});

  // async function fetchSelectedAssessment(data) {
  //   setSelectedAssessment(data);
  // }

  // const onDeleteAssessment = () => {
  //   // console.log(
  //   //   "selecetd company",
  //   //   selectAssessment?.assesmentCompany?._id,
  //   //   selectAssessment?._id
  //   // );
  //   axios
  //     .delete(
  //       `${BootcampBackend}/adminPanel/deleteAssessmentByIdAdminPanel/${selectAssessment?.assesmentCompany?._id}/${selectAssessment?._id}`,
  //       {
  //         headers: {
  //           Authorization: JSON.parse(localStorage.getItem("jwt")).token,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setMyAssessments(
  //         myAssessments.filter((x) => {
  //           if (x._id === selectAssessment?._id) {
  //             return x._id !== selectAssessment?._id;
  //           }
  //           return myAssessments;
  //         })
  //       );
  //       setDeleteModal(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const onParticipantsChange = (_id, assesmentReference, assesmentType) => {
  //   switch (assesmentType) {
  //     case "mocks":
  //       history.push(`/admin/assessments/mocks/${assesmentReference}/leaderboard`);
  //       break;
  //     case "hackathon":
  //       history.push(
  //         `/admin/assessments/hackathons/${assesmentReference}/${_id}/leaderboard`
  //       );
  //       break;
  //     case "custom_mcq":
  //       history.push(`/admin/assessments/mocks/${assesmentReference}/leaderboard`);
  //       break;
  //   }
  // };

  // function showDeleteModal() {
  //   setDeleteModal(true);
  // }

  // const editCustomMcQ = (assesmentReference) => {
  //   history.push(`/admin/assessments/create-question-set/${assesmentReference}`);
  // };

  // function hideDeleteModal() {
  //   // selectAssessment({});
  //   setDeleteModal(false);
  // }
  // const onSharedListChange = (id) => {
  //   history.push(`/admin/assessments/invitations/${id}`);
  // };

  // async function getMyAssessments() {
  //   await axios
  //     .get(
  //       `${BootcampBackend}/adminPanel/getAllAssesmentAdminPanel?current_page=${activePage.page}`,
  //       {
  //         headers: {
  //           Authorization: JSON.parse(localStorage.getItem("jwt")).token,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setIsPageLoading(false);
  //       setMyAssessments(res.data.result.data);
  //       setTotalCount(res?.data?.result?.total);
  //     })
  //     .catch((err) => {
  //       setIsPageLoading(false);
  //       console.log(err);
  //     });
  // }

  // useEffect(() => {
  //   getMyAssessments();
  // }, [activePage]);

  useEffect(() => {
    localStorage.setItem(
      "adminView",
      true
    );
  }, [])


  return (
    <div>
      <Assessments adminView={true} />
    </div>
  );
}

export default Assessment;
