import axios from "axios";
import { BootcampBackend } from "backend";
import React, { useState, useEffect } from "react";
import forgotPassword from "../../../assets/Icons/forgotPassword.svg";
import activeEmail from "../../../assets/Icons/activeEmail.svg";
import email from "../../../assets/Icons/email.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
const ForgotPassword = (props) => {
  const [resetEmailId, setResetEmailId] = useState("");
  const [resetPassError, setResetPassError] = useState("");
  const [resetPassSuccess, setResetPassSuccess] = useState(false);
  const requestReset = (e) => {
    e.preventDefault();

    setResetPassError("");
    setResetPassSuccess("");

    if (resetEmailId === "") {
      setResetPassError("Email cannot be empty");
      return;
    }

    const formData = new FormData();
    formData.append("emailId", resetEmailId);

    axios
      .post(`${BootcampBackend}/iamUser/resetPasswordRequest`, {
        emailId: resetEmailId,
      })
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          setResetPassSuccess(
            `An email with the password reset link has been sent to ${resetEmailId}.Please check your inbox.`
          );
        }
      })
      .catch((err) => {
        setResetPassError("Requested Email id does not exist");
      });
  };

  return (
    <form
      className={`col-6 p-0  ${
        resetPassSuccess
          ? `d-flex flex-column justify-content-around h-100`
          : `pt-5`
      }`}
    >
      <div className={`forgotPasswordForm text-center col-7 m-auto `}>
        {!resetPassSuccess && (
          <>
            <img src={forgotPassword} />
            <h3 className="formHeading mb-4 mt-3">Forgot Password</h3>
            <p className="loginText darkText m-0">
              Enter email associated with your account and
            </p>
            <p className="loginText darkText m-0">
              we will send an email with password reset link
            </p>
            <form className="col-12 py-4 mt-4">
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="email" className="mb-2 pt-2 text-left">
                    Email
                  </label>
                  <div
                    className={`loginInputHld ${
                      resetPassError !== "" ? `mb-0 errorInputField` : `mb-4`
                    }`}
                  >
                    <img
                      className="img-fluid inputLogo"
                      src={resetEmailId ? activeEmail : email}
                    />
                    <input
                      type="text"
                      name="email"
                      value={resetEmailId}
                      onChange={(e) => setResetEmailId(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    {resetPassError !== "" && (
                      <label
                        style={{
                          color: "#F44D5E",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {resetPassError}
                      </label>
                    )}
                    {resetPassSuccess !== "" && (
                      <label
                        style={{
                          color: "#00C5A6",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {resetPassSuccess}
                      </label>
                    )}
                    <span
                      className="fontGray cursor-pointer"
                      onClick={() => props.setFormState("login")}
                    >
                      <img className="me-2" src={backArrow} />
                      Go back
                    </span>
                    <button
                      type="submit"
                      className="submitButton my-5"
                      onClick={requestReset}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
        {resetPassSuccess && (
          <div>
            <div className="successSvg">
              <svg className="checkmarkSvg" viewBox="0 0 52 52">
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <h3 className="formHeading mb-4 mt-2">Reset link sent</h3>
            <p className="fontGray px-2  text-center">
              An email with the password reset link has been
            </p>
            <p className="fontGray  text-center">
              sent to {resetEmailId}. Please check your inbox.
            </p>
          </div>
        )}
      </div>
      <p className=" mt-3 mb-4 text-center fontGray">
        For any queries &nbsp;
        <a className="fontGray" href="mailto:support@machinehack.com">
          support@machinehack.com
        </a>
      </p>
    </form>
  );
};
export default ForgotPassword;
