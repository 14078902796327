import React, { useCallback, useEffect, useState } from "react";
import search from "assets/Icons/Settings/search.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { lmsService } from "services/lms.service";
import { setLMSLeaderboard } from "store/reducers/lmsSlice";
import { LoadingShape } from "react-rainbow-components";
import EmptyData from "./EmptyData";

const UserActivity = ({ studyplanSlug, durationFilter }) => {
  const lmsLeaderboard = useSelector((store) => store.lms.lms_leaderboard);
  const currentPage = useSelector(
    (store) => store.lms.lms_leaderboard_current_page
  );
  const totalCount = useSelector(
    (store) => store.lms.lms_total_leaderboard_Count
  );
  const [hasMoreData, setHasMoreData] = useState(
    lmsLeaderboard?.length !== totalCount
  );
  const [selectedUserStats, setSelectedUserStats] = useState({});
  const [daysPlanData, setDaysPlanData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(lmsLeaderboard[0]);
  const [daysPagination, setDaysPagination] = useState({
    currentPage: 1,
    limit: 8,
  });
  const [searchText, setSearchText] = useState("");
  const [userDetailsLoader, setUserDetailsLoader] = useState(false);
  const [isDayPlanLoader, setIsDayPlanLoader] = useState(false);
  const [searchResult,setSearchResult] = useState([])
  const dispatch = useDispatch();
  const loadMoreParticipants = () => {
    if (lmsLeaderboard.length !== totalCount) {
      fetchLMSParticipants();
    }
  };
  function addZeroIfLessThan10(inputStr) {
    try {
        const num = parseInt(inputStr, 10);
        if (isNaN(num)) {
            throw new Error("Invalid input: Please provide a valid numerical string.");
        }

        return num < 10 ? '0' + inputStr : inputStr;
    } catch (error) {
        return error.message;
    }
}
  const handlePrevPage = () => {
    if (daysPagination.currentPage === 1) {
      return;
    }
    const newPage = Math.max(1, daysPagination.currentPage - 1);
    setDaysPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(
      selectedUserStats?.totalDayPlans / daysPagination.limit
    );
    if (daysPagination.currentPage === totalPages) {
      return;
    }
    const newPage = Math.min(totalPages, daysPagination.currentPage + 1);
    setDaysPagination((prev) => ({ ...prev, currentPage: newPage }));
  };
  const fetchLMSParticipants = async () => {
    try {
      const topPerformerResponse = await lmsService.fetchLMSLeaderboard(
        studyplanSlug,
        currentPage
      );
      const topPerformerResponseData = {
        data: [...lmsLeaderboard, ...topPerformerResponse.data],
        totalUsersInLeaderboard: topPerformerResponse.topPerformerResponse,
      };
      dispatch(setLMSLeaderboard(topPerformerResponseData));
      setHasMoreData(topPerformerResponseData?.data?.length !== totalCount);
    } catch (err) {
      console.log(err);
    }
  };
  const getDayWiseUserStats = useCallback(async () => {
    try {
      const response = await lmsService.fetchUserDaywiseStats(
        studyplanSlug,
        selectedUser?.user?._id,
        daysPagination.currentPage,
        durationFilter.slug
      );
      setIsDayPlanLoader(false);
      setDaysPlanData(response.dayWiseLmsUserStats);
    } catch (error) {
      setIsDayPlanLoader(false);
      console.log(error);
    }
  }, [daysPagination.currentPage, durationFilter.slug]);
  const getSelectedUserStats = async () => {
    try {
      setUserDetailsLoader(true);
      const response = await lmsService.fetchUserStats(
        studyplanSlug,
        selectedUser?.user?._id
      );
      setUserDetailsLoader(false);
      setSelectedUserStats(response);
    } catch (error) {
      console.log(error);
      setUserDetailsLoader(false);
    }
  };

  const handleSearch = async () => {
    try {
      const searchResult = await lmsService.searchUser(studyplanSlug, searchText);
      setSearchResult(searchResult?.data || []);
      setHasMoreData(searchResult?.data?.length !== searchResult?.totalUsersInLeaderboard);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if(searchText.length > 0) {
        handleSearch();
      }
    }, 2000)
    return () => clearInterval(timer)
  },[searchText])

  useEffect(() => {
    if (lmsLeaderboard && lmsLeaderboard[0]) {
      getSelectedUserStats();
    }
  }, [selectedUser?._id]);

  useEffect(() => {
    getDayWiseUserStats();
  }, [daysPagination.currentPage, selectedUser?._id, durationFilter.slug]);
  
  return (
    <div className="d-flex user-activity-hld">
      {lmsLeaderboard.length === 0 && searchText!=="" ? (
        <div className="mx-auto d-flex align-items-center">
          <EmptyData />
        </div>
      ) : (
        <>
          <div className="col-3 py-3">
            <div className="position-relative mb-3 mx-2">
              <input
                type="text"
                value={searchText}
                className="lms-search-participants"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <img src={search} alt={"search"} className="lms-search-icon" />
            </div>
            <div className="user-activity-users-list">
              {(searchText.length>0&&searchResult.length==0)&&<div className="noMatchFoundCard">
                  <span>No match found</span>
              </div>}
              <InfiniteScroll
                dataLength={lmsLeaderboard.length}
                next={loadMoreParticipants}
                hasMore={hasMoreData}
                loader={<UserCardLoader />}
              >
                {(searchText.length>0?searchResult:lmsLeaderboard)?.map((participant, i) => (
                  <div
                    className={`lms-participants-card cursor-pointer mx-2 ${
                      selectedUser?._id === participant?._id
                        ? " lms-participants-active-card"
                        : ""
                    }`}
                    key={"participant" + i}
                    onClick={() => {
                      setSelectedUser(participant);
                      setIsDayPlanLoader(true);
                      setDaysPagination(prev => ({
                        ...prev,
                        currentPage: 1
                      }))
                    }}
                  >
                    <img
                      src={participant?.user?.Picture}
                      className="lms-user-img"
                      height={25}
                      width={25}
                    />
                    <div className="lms-participants-user-details-hld">
                      <div className="row mx-0 pb-1">
                        <p className="studyplan-total-learners px-0 col-9">
                          {participant?.user?.Name}
                        </p>
                        <p className="lms-participants-rank ms-2 col">
                          {addZeroIfLessThan10(participant?.currentRank)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <ul className="lms-overall-score-hld">
                          {participant.coverages?.todayCoverage !== null ? (
                            <li className="lms-overall-score-li">
                              Today{" "}
                              <span>
                                {participant.coverages?.todayCoverage}%
                              </span>
                            </li>
                          ) : (
                            <></>
                          )}
                          {participant.coverages?.overallCoverage !== null ? (
                            <li className="lms-overall-score-li">
                              Overall{" "}
                              <span>
                                {participant.coverages?.overallCoverage}%
                              </span>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                        <p className="lms-user-score">
                          {participant?.currentRank >=
                          participant?.previousRank ? (
                            <FontAwesomeIcon
                              icon={faArrowTrendUp}
                              className="me-1 circle-check"
                              color="#4EA184"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faArrowTrendDown}
                              className="me-1 circle-check"
                              color="#F44D5E"
                            />
                          )}
                          Score <span>{participant?.score}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <div className="col-9 user-details-hld">
            <div className="d-flex lms-user-details-hld align-items-center">
              {userDetailsLoader ? (
                <LoadingShape
                  variant="avatar"
                  shape="circle"
                  style={{ width: "120px", height: "120px" }}
                />
              ) : (
                <img
                  src={`${
                    selectedUserStats?.lmsUserData?.Picture
                      ? selectedUserStats?.lmsUserData?.Picture
                      : "https://cdn.machinehack.com/default/M.gif"
                  }`}
                  className="lms-user-img"
                  height={144}
                  width={144}
                />
              )}
              <div className="w-100 ms-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    {userDetailsLoader ? (
                      <LoadingShape className="w-25 mb-2" />
                    ) : (
                      <p className="lms-user-detatails-username">
                        {selectedUserStats?.lmsUserData?.Name}
                      </p>
                    )}
                    {userDetailsLoader ? (
                      <LoadingShape className="w-100" />
                    ) : (
                      <p className="lms-user-detatails-description">
                        {selectedUserStats?.lmsUserData?.LatestDesignation}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    {userDetailsLoader ? (
                      <LoadingShape className="w-50 mx-4" />
                    ) : (
                      selectedUserStats?.studyPlanStats?.todayCoverage && (
                        <p className="overall-score today-score-bg">
                          Covered Today{" "}
                          <span>
                            {selectedUserStats?.studyPlanStats?.todayCoverage}%
                          </span>
                        </p>
                      )
                    )}
                    {userDetailsLoader ? (
                      <LoadingShape className="w-50 mx-4" />
                    ) : (
                      selectedUserStats?.studyPlanStats?.overallCoverage && (
                        <p className="overall-score overall-score-bg">
                          Covered Overall{" "}
                          <span>
                            {selectedUserStats?.studyPlanStats?.overallCoverage}
                            %
                          </span>
                        </p>
                      )
                    )}
                    {userDetailsLoader ? (
                      <LoadingShape className="w-25 mx-4" />
                    ) : (
                      <p className="lms-rank">
                        Rank {selectedUser?.currentRank}
                      </p>
                    )}
                  </div>
                </div>
                <hr className="my-2 lms-user-details-separator" />
                {userDetailsLoader ? (
                  <LoadingShape className="w-25 mb-2" />
                ) : (
                  <p className="lms-courses-card-info-text mb-2 pt-1">
                    based on overall data
                  </p>
                )}
                <div className="d-flex justify-content-between me-4">
                  {userDetailsLoader ? (
                    Array.from({ length: 4 }).map((_, i) => (
                      <div className="lms-courses-card" key={i}>
                        <LoadingShape
                          shape="square"
                          className="mb-2 w-25 h-auto"
                        />
                        <LoadingShape className="mb-2 w-auto h-auto" />
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="lms-courses-card">
                        <p className="total-number-of-participants total-participants-heading">
                          {
                            selectedUserStats?.studyPlanStats
                              ?.totalTestAttempted
                          }
                        </p>
                        <p className="doughnut-label">Total Tests Attempted</p>
                      </div>
                      <div className="lms-courses-card">
                        <p className="total-number-of-participants total-participants-heading">
                          {selectedUserStats?.studyPlanStats?.overallCoverage
                            ? selectedUserStats?.studyPlanStats?.overallCoverage
                            : 0}
                          %
                        </p>
                        <p className="doughnut-label">
                          Total Playground Covered
                        </p>
                      </div>
                      <div className="lms-courses-card">
                        <p className="total-number-of-participants total-participants-heading">
                          {
                            selectedUserStats?.studyPlanStats
                              ?.totalPracticesAttempted
                          }
                        </p>
                        <p className="doughnut-label">Practice</p>
                      </div>
                      <div className="lms-courses-card">
                        <p className="total-number-of-participants total-participants-heading">
                          {
                            selectedUserStats?.studyPlanStats
                              ?.totalAssessmentsAttempted
                          }
                        </p>
                        <p className="doughnut-label">Assessment</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="days-covered-hld mx-4">
              {!isDayPlanLoader ? (
                <div className="d-flex align-items-center px-4 days-covered-card-hld">
                  <p className="col-lg-7 studyplan-title">
                    Days Covered
                    {selectedUserStats?.studyPlanStats?.totalDaysCovered}/
                    {selectedUserStats?.totalDayPlans}
                  </p>
                  <p className="col-lg-3 lms-courses-card-info-text">Status</p>
                  <p className="col-lg-1 lms-courses-card-info-text">
                    Progress
                  </p>
                  <p className="col-lg-1 lms-courses-card-info-text text-center">
                    Score
                  </p>
                </div>
              ) : (
                <></>
              )}
              {isDayPlanLoader ? (
                <>
                  {Array.from({ length: 12 }).map((_, i) => (
                    <LoadingShape
                      className="mb-1"
                      shape="rect"
                      style={{ height: "37px" }}
                      key={i}
                    />
                  ))}
                </>
              ) : (
                <div className="days-plan-list-hld">
                  {daysPlanData?.map((item, i) => (
                    <div
                      className={`d-flex align-items-center days-covered-card-hld px-4 ${
                        item.isCurrentDay ? "lms-present-day-card" : ""
                      }`}
                      key={`day${i + 1}`}
                    >
                      <p className="col-lg-1 lms-courses-card-info-text">
                        Day {item.day}
                      </p>
                      <p className="col-lg-6 download-csv-button">
                        {item.title}
                      </p>
                      <p className="col-lg-3 course-completed-text">
                        <FontAwesomeIcon
                          icon={
                            item.status === "Not Attempted"
                              ? faCircleXmark
                              : faCircleCheck
                          }
                          className="me-2 circle-check"
                          fontSize={16}
                          color={
                            item.status === "Completed"
                              ? "#34C471"
                              : item.status === "Not Completed"
                              ? item.isCurrentDay
                                ? "rgba(0, 0, 0, 0.5)"
                                : "#D9D9D9"
                              : "#F44D5E"
                          }
                        />
                        {item.status}
                      </p>
                      <p className="col-lg-1 download-csv-button">
                        {item?.studyMaterialProgress?.completedMaterials}/
                        {item?.studyMaterialProgress?.totalMaterials}
                      </p>
                      <p className="col-lg-1 download-csv-button text-center position-relative">
                        {item?.testScore ? item?.testScore : "-"}
                        <span className="score-overlay" />
                      </p>
                    </div>
                  ))}
                </div>
              )}
              {!isDayPlanLoader ? (
                <div className="position-relative user-activity-pagination col-lg-6">
                  <div
                    className={`d-flex align-items-center ${
                      daysPagination.currentPage === 1
                        ? "cursor-no-drop"
                        : "cursor-pointer"
                    }`}
                    onClick={handlePrevPage}
                  >
                    <div
                      className={`carousel-arrow-hld carousel-arrow-hld-right ${
                        daysPagination.currentPage === 1 ? "cursor-no-drop" : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="circle-check"
                        fontSize={12}
                        color="#979797"
                      />
                    </div>
                    <p className="download-csv-button ps-2">Prev</p>
                  </div>
                  <p className="course-completed-text">
                    Page {daysPagination.currentPage} to{" "}
                    {Math.ceil(
                      selectedUserStats?.totalDayPlans / daysPagination.limit
                    )}
                  </p>
                  <div
                    className={`d-flex align-items-center ${
                      daysPagination.currentPage ===
                      Math.ceil(
                        selectedUserStats?.totalDayPlans / daysPagination.limit
                      )
                        ? "cursor-no-drop"
                        : "cursor-pointer"
                    }`}
                    onClick={handleNextPage}
                  >
                    <p className="download-csv-button pe-2">Next</p>
                    <div
                      className={`carousel-arrow-hld carousel-arrow-hld-left ${
                        daysPagination.currentPage ===
                        Math.ceil(
                          selectedUserStats?.totalDayPlans /
                            daysPagination.limit
                        )
                          ? "cursor-no-drop"
                          : "cursor-pointer"
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="circle-check"
                        fontSize={12}
                        color="#979797"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserActivity;

function UserCardLoader() {
  return (
    <div className={`lms-participants-card cursor-pointer mx-2`}>
      <LoadingShape className="w-25" shape="circle" variant="avatar" />
      <div className="lms-participants-user-details-hld">
        <LoadingShape className="mb-3" />
        <div className="d-flex justify-content-between">
          <LoadingShape className="" />
          <LoadingShape className="" />
        </div>
      </div>
    </div>
  );
}
