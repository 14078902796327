import axios from "axios";
import { BootcampBackend } from "backend";
import React, { useState, useEffect, useRef } from "react";
import closeIcon from "../../../../assets/Icons/cross.svg";

export default function SelectSkills({ skillSet, setSkillSet, setTagsErr }) {
  const [skillslist, setSkillslist] = useState([]);
  const [showSkillsDrop, setShowSkillsDrop] = useState(false);
  const [skills, setSkills] = React.useState("");
  const [currentSkillsPage, setCurrentSkillsPage] = useState(1);
  const inputEl = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSkillsDrop(false);
          setSkills("");
          setCurrentSkillsPage(1);
          getAllSkills(currentSkillsPage, "");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    getAllSkills(currentSkillsPage, "");
  }, [skills]);

  const onClickDivFocusInput = () => {
    inputEl.current.focus();
  };

  const getAllSkills = async (page, type) => {
    try {
      const paramData = {
        current_page: page,
      };
      skills !== "" && (paramData.searchString = skills);
      const response = await axios
        .get(`${BootcampBackend}/skills/getAllSkillsEnterprise`, {
          params: paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((response) => {
          type === "scroll"
            ? setSkillslist((prev) => [
                ...prev,
                ...response?.data?.result?.data,
              ])
            : setSkillslist(response?.data?.result?.data);

          setCurrentSkillsPage(page);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSkillChange = (e) => {
    setSkills(e.target.value);
    setShowSkillsDrop(true);
    setCurrentSkillsPage(1);
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (skillslist.length > 0) {
        addSkills(skillslist[0]?.name);
      } else {
        setSkills("");
      }
    }
  };
  const addSkills = (skills) => {
    if (skills) {
      setSkillSet((prev) => ({
        ...prev,
        tags: [...(skillSet || []), skills],
      }));
      inputEl.current.focus();
      setTagsErr("");
      setSkills("");
      setShowSkillsDrop(false);
    }
  };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      getAllSkills(currentSkillsPage + 1, "scroll");
    }
  };

  const handleDelete = (item) => {
    setSkillSet((prev) => ({
      ...prev,
      tags: skillSet.filter((i) => i !== item),
    }));
  };

  return (
    <div className="tagsHld">
      <div className="tagsSearchBar py-2" onClick={onClickDivFocusInput}>
        {skillSet?.length > 0 &&
          skillSet?.map((item) => (
            <div className="tag" key={item}>
              {item}
              <img
                className="img-fluid ms-3"
                src={closeIcon}
                alt="x"
                onClick={() => handleDelete(item)}
              />
            </div>
          ))}
        <input
          className="tagsInput"
          type="text"
          name="search"
          placeholder={`${skillSet?.length > 0 ? "" : "Eg Python"}`}
          ref={inputEl}
          value={skills}
          onChange={handleSkillChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {showSkillsDrop && (
        <div
          className="tagsDropdown mt-2"
          ref={wrapperRef}
          onScroll={handleScroll}
        >
          {skillslist.length > 0 &&
            skillslist?.map((skill) => (
              <p
                key={skill._id}
                onClick={() => addSkills(skill.name)}
                className="mb-0"
              >
                {skill?.name}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}
