import React, { useEffect, useRef, useState } from "react";
import dots from "../../../../../assets/Icons/Settings/dots.svg";
import InvitePartcipants from "../../../../../assets/Icons/Assessment/inviteParticipant.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BootcampBackend } from "backend";
import { Pagination } from "react-rainbow-components";
function SharedList({
  participantsList,
  reInvite,
  deleteparticipants,
  setOpen,
  setCurrentCandidate,
  isLms
}) {
  const [showDropdown, setShowDropdown] = useState("");
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const sharedListActions = [
    {
      action: "Add Extra Time",
      handleClick: (participant) => {
        setCurrentCandidate(participant);
        setOpen("addAdditionalTime");
      },
      isShow: () => {
        return true;
      },
    },
    {
      action: "Re-Invite",
      handleClick: (participant) => {
        reInvite([participant?.email]);
        setShowDropdown("");
      },
      isShow: () => {
        return true;
      },
    },
    {
      action: "Delete",
      handleClick: (participant) => {
        deleteparticipants(participant, "single");
        setOpen("");
      },
      isShow: () => {
        return true;
      },
    },
    {
      action: "View Timeline",
      handleClick: (participant) => {
        history.push(`/assessments/${params.assessmentId}/timeline/${participant?.userId?._id}`);
      },
      isShow: (participant) => {
        if (participant?.userId?._id) {
          return true;
        }
      },
    },
  ];
  const params = useParams();
  const [pagination, setPagination] = useState({ current_page: 1, limit: 5 });
  const [sharedList, setSharedlist] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const getParticipants = async () => {
    const paramData = {
      limit: pagination.limit,
      current_page: pagination.current_page,
    };
    const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/getAllLmsParticipants/${params.assessmentId}`
        : `${BootcampBackend}/enterpriseAssessment/getAllParticipants/${params.assessmentId}`;
    await axios
      .get(
        url,
        {
          paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setSharedlist(res.data.result.data);
        setTotalParticipants(res.data.result.totalParticipants);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnChange = (event, page) => {
    setPagination({ ...pagination, current_page: page });
  };
  useEffect(() => {
    getParticipants();
  }, []);
  return (
    <>
      <div className="shareListHeader">Shared List ({sharedList.length})</div>
      <div className="invitedParticiantsList">
        {sharedList && sharedList.length > 0 ? (
          sharedList.map((participant) => (
            <div className="sharedListItem" key={participant._id}>
              <div className="d-flex justify-content-between mb-1 position-relative">
                <p className="paragraph paragraphBlack">
                  {participant?.userId?.Name
                    ? participant?.userId?.Name
                    : participant?.email.split("@")[0]}
                </p>
                <img
                  className="img-fluid ps-2 cursor-pointer"
                  src={dots}
                  onClick={() => setShowDropdown(participant._id)}
                />
                {showDropdown === participant._id && (
                  <div className="tableMenu menuActive" ref={wrapperRef}>
                    {sharedListActions.map((actionData, index) => (
                      <div
                        key={`action${index}`}
                        onClick={() => actionData.handleClick(participant)}
                      >
                        {actionData.isShow(participant) && (
                          <p className="paragraph">{actionData.action}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <p className="paragraph">{participant?.email}</p>
            </div>
          ))
        ) : (
          <div className="inviteParticipant text-center">
            <img
              src={InvitePartcipants}
              className="img-fluid"
              alt="invite"
              loading="lazy"
            />

            <p className="paragraph text-center">
              There are currently no invited participants.
            </p>
          </div>
        )}
      </div>
      {sharedList && totalParticipants > pagination.limit && (
        <div className="my-2">
          <Pagination
            className="rainbow-m_auto sharedListPage"
            pages={Math.ceil(totalParticipants / pagination.limit)}
            activePage={pagination.current_page + 1}
            setActivePage={pagination.current_page}
            onChange={handleOnChange}
            variant="shaded"
          />
        </div>
      )}
    </>
  );
}

export default SharedList;
