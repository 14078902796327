/*
The main shared yjs data structures for the app (the doc, lines, and services).
*/

import * as Y from "yjs";
import { WebrtcProvider } from "y-webrtc";
import { WebRTC_URL } from "../../../../backend";
import { parseJwt } from "SharedMethods/CommenMethods";

const InterviewData = window.location.pathname.includes("interview-paper") ? parseJwt(window.location.pathname.split('/')[window.location.pathname.split('/')?.length - 1]) : "";
const VERSION = InterviewData?.interviewerId;
// Create the doc
export const doc = new Y.Doc();

// Create a websocket provider (but don't connect just yet)
// export const provider = new WebsocketProvider(
//   "wss://machinehack.futurekrux.com/websocket/",
//   `draw-demo-${VERSION}`,
//   doc,
//   {
//     connect: false
//   }
// );
export const provider = new WebrtcProvider(`draw-${VERSION}`, doc, {
    //Remember the other tab or
    //other user should be in same room for seeing real-time changes
    signaling: [
      `${WebRTC_URL}`
    ],
  },
  {
    connect: false
  });

// Export the provider's awareness API
export const awareness = provider.awareness;

// Get a shared array of our line maps
export const yLines = doc.getArray(`lines-${VERSION}`);

// Create an undo manager for the line maps
export const undoManager = new Y.UndoManager(yLines);
