import React from "react";

import "./ErrorPage.css";

import Error404Icon from "../../../assets/Icons/Error/Error404.svg"
export default function ErrorPage(){
    return(
        <div className="errorPage bg-light">
            <div className="errorMessage">
                <img src={Error404Icon} alt="error" />
                <h3>Error!</h3>
            </div>
        </div>
    )
}