import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import leaderBoard from "assets/Icons/DashboardNew/leaderBoard.svg";

Chart.register(...registerables);

const ParticipantChart = ({ donutSeries, donutLabel }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    Chart.defaults.elements.arc.borderWidth = 0;
    Chart.defaults.datasets.doughnut.cutout = "65%";

    // Initialize chart
    chartInstanceRef.current = new Chart(chartRef.current, {
      type: "doughnut",
      data: {
        labels: donutLabel ? [...donutLabel] : [],
        datasets: [
          {
            label: "",
            data: donutSeries,
            backgroundColor: [
              "#4F3FF0",
              "#DF3670",
              "#FFD378",
              "#F8DAED",
              "#FB8669",
              "#34C471",
            ],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "left",
            labels: {
              color: "black", // Fixed fontColor as it's now `color`
              font: {
                size: 16,
              },
              zIndex: 9999,
              boxWidth: 20,
              padding: 35,
            },
          },
        },
        tooltips: {
          enabled: false,
        },
      },
    });

    return () => {
      // Destroy chart on component unmount
      chartInstanceRef.current.destroy();
    };
  }, []); // Initialize chart only once

  useEffect(() => {
    // Update chart when props change
    if (chartInstanceRef.current) {
      chartInstanceRef.current.data.labels = donutLabel ? [...donutLabel] : [];
      chartInstanceRef.current.data.datasets[0].data = donutSeries;
      chartInstanceRef.current.update();
    }
  }, [donutSeries, donutLabel]);

  return (
    <div className="position-relative">
      <div className="leaderboardImgHld">
        <img className="leaderboardImg" src={leaderBoard} alt="leaderboard" />
      </div>
      <canvas ref={chartRef} id="chartJSContainer" />
    </div>
  );
};

export default ParticipantChart;
