import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  organizationDetails: {},
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationDetails: (state, action) => {
      state.organizationDetails = action.payload
    },
  },
})

export const { setOrganizationDetails } = organizationSlice.actions

export default organizationSlice.reducer