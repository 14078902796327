import React, { useState } from "react";
import { LoadingShape } from "react-rainbow-components";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import samplePdf from "./Nishin-resume.pdf";
import "./UserProfileInfo.css";

export default function UserProfileInfo(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="userAllDetails">
      <div className="userWorkExperience">
        <div className="title">
          <h4>Work</h4>
        </div>
        <div className="jobInfo">
          <ul>
            {!props.isLoading && props.jobDetails.length > 0
              ? props.jobDetails.map((job) => {
                  return (
                    <li>
                      <div className="my-3">
                        <h6>{job.designation}</h6>
                        <div className="d-flex align-items-center">
                          <p>{` ${job.org} `}</p>{" "}
                          <p style={{ margin: "0 10px" }}>|</p>{" "}
                          <p> {`${job.start_date} - ${job.end_date} `}</p>
                        </div>

                        <p>{job.description}</p>
                      </div>
                    </li>
                  );
                })
              : !props.isLoading && (
                  <div className="my-3">
                    <div>No experience available</div>
                  </div>
                )}
            {props.isLoading && (
              <li>
                <div className="my-3">
                  <h6>
                    <LoadingShape />
                  </h6>
                  <p>
                    <LoadingShape />
                  </p>
                  <p>
                    <LoadingShape />
                  </p>
                </div>
              </li>
            )}
            {/* <li>
              <div className="my-3">
                <h6>Full stack developer</h6>
                <p>{` Company Name  | March2020-Present `}</p>
                <p> Job description</p>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="userEducation">
        <div className="title">
          <h4>Education</h4>
        </div>
        <div className="educationInfo">
          <ul>
            {!props.isLoading && props.eduDetails.length > 0
              ? props.eduDetails.map((edu) => {
                  return (
                    <li>
                      <div className="my-3">
                        <h6>{`${edu.degree} ${edu.course}`}</h6>
                        <div className="d-flex align-items-center">
                          <p>{` ${edu.institute}`}`</p>
                          <p style={{ margin: "0 10px" }}>|</p>
                          <p>{`${edu.start_date} - ${edu.end_date} `}</p>
                        </div>

                        <p>{edu.description}</p>
                      </div>
                    </li>
                  );
                })
              : !props.isLoading && (
                  <div className="my-3">
                    <div>No experience available</div>
                  </div>
                )}
            {props.isLoading && (
              <li>
                <div className="my-3">
                  <h6>
                    <LoadingShape />
                  </h6>
                  <p>
                    <LoadingShape />
                  </p>
                  <p>
                    <LoadingShape />
                  </p>
                </div>
              </li>
            )}
            {/* <li>
              <div className="my-3">
                <h6>Degree</h6>
                <p>{` College Name  | 2018-2020 `}</p>
                <p>description</p>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      <hr style={{ margin: "45px 0 55px", color: "black" }} />
      <div className="userResume">
        {props.resume && (
          <>
            
            <Document
              file={props.resume}
              options={{ workerSrc: "pdf.worker.js" }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {/* <Page pageNumber={pageNumber} /> */}
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page pageNumber={page} />
                ))}
            </Document>
          </>
        )}
      </div>
    </div>
  );
}
