import React, { useEffect, useState } from "react";
import MocksCard from "../Assessments/Mocks/Sections/MocksCard";
import MockQuestion from "../Assessments/Mocks/Sections/MockQuestion";
import "./SelectAssessment.css";
import { BootcampBackend } from "../../../backend";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Pagination } from "react-rainbow-components";
import AssessmentWhite from "../../../assets/Icons/Interview/Attach-Assessment-White-icon.svg";
import AssessmentIcon from "../../../assets/Icons/Interview/Attach-AssessmentColor.svg";
import NoJobsIcon from "../../../assets/Icons/Jobs/Frame.svg";
import attachAssessment from "../../../assets/Icons/Interview/New/attachAssess.svg";
import questionList from "../../../assets/Icons/Interview/New/questionList.svg";
import ActiveTick from "../../../../src/assets/Icons/Interview/New/activeTick.svg";

import TimerIcon from "../../../assets/Icons/Interview/New/time.svg";

const moment = require("moment");

export default function SelectAssessment(props) {
  const [mocksList, setMocksList] = useState([]);
  const [selectedMock, setSelectedMock] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState([]);
  const [activeQuestionsPage, setActiveQuestionsPage] = useState(1);
  const [mocksLength, setMocksLength] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const showAssessmentType = props.showAssessmentType;
  const handleSelectMock = (data) => {
    if (selectedMock !== data) {
      setSelectedMock(data);
      getMockQuestions(data, "1");
    }
  };

  const getMockQuestions = (questionSet, page) => {
    const url =
      showAssessmentType === "myCompany"
        ? `${BootcampBackend}/enterpriseAssessment/getAllEnterpriseAssessmentQuestions/${questionSet?.slug}?current_page=${page}&limit=20`
        : `${BootcampBackend}/enterpriseAssessment/getAllQuestionsFromQuestionSet?current_page=${page}&limit=20&questionSetIds=${questionSet?._id}`;
    axios
      .get(url,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setQuestions(res?.data?.result?.data);
        showAssessmentType === "myCompany" ? setTotalQuestions(res?.data?.result?.total) : setTotalQuestions(res?.data?.result?.totalQuestions);
        // if (showAssessmentType === "myCompany") {
        //   setMocksList(res?.data?.result?.data);
        //   setSelectedMock(res?.data?.result?.questionSet);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListOfMocks = (page) => {
    const url =
      showAssessmentType === "myCompany"
        ? `${BootcampBackend}/enterpriseAssessment/getAllAssessmentsUnderCompany?current_page=${page}&limit=10&assessmentType=companyAssessment`
        : `${BootcampBackend}/enterpriseAssessment/getAllMcqQuestionSet?current_page=${page}&limit=10`;
    axios
      .get(url, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let listofmocks = res.data.result.data;
        setMocksList(listofmocks);
        showAssessmentType === "myCompany" ? setMocksLength(res.data.result.total) : setMocksLength(res.data.result.totalQuestionSet);
        setSelectedMock(res.data.result.data[0]);
        getMockQuestions(res.data.result.data[0], "1");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const addnewTabData = async () => {
    await axios
      .put(
        `${BootcampBackend}/interview/updateInterviewRoundTabData/${props.interviewToken}`,
        {
          tabs: {
            tabID: props.interviewData.tabs
              ? props.interviewData.tabs.length + 1
              : 1,
            tabType: showAssessmentType === "myCompany" ? "enterpriseAssessment" : "assessment",
            assessmentSlugRef: selectedMock.slug,
          },
          currentInterviewerTab: props.interviewData.currentInterviewerTab
            ? props.interviewData.currentInterviewerTab
            : 1,
          currentUserTab: props.interviewData.currentUserTab
            ? props.interviewData.currentUserTab
            : 1,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        history.push(`/interview-paper/3/${props.interviewToken}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddAssessment = () => {
    if (props.type === "Assessment") {
      // axios
      //   .post(
      //     `${BootcampBackend}/assesments/addAssesmentFromExistingMock`,
      //     {
      //       assesmentType: selectedMock.questionSetType,
      //       assesmentTitle: "Multiple choice questions",
      //       assesmentReference: selectedMock.slug,
      //       timeStamp: Date(),
      //     },
      //     {
      //       headers: {
      //         Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     const assignmentid = res.data.result.data._id;
      //     props?.selectedUserEmail &&
      //       axios
      //         .put(
      //           `${BootcampBackend}/assesments/buildSharedList/${assignmentid}`,
      //           {
      //             emails: [`${props?.selectedUserEmail}`],
      //             timeStamp: Date(),
      //           },
      //           {
      //             headers: {
      //               Authorization: JSON.parse(localStorage.getItem("jwt"))
      //                 .token,
      //             },
      //           }
      //         )
      //         .then((res) => {
      //           history.push("/assessments");
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      const assessmentTitleData = {
        id: "3",
        name: "Attach an  EnterpriseAssessment",
        desc: "Lorem ipsum lorem ipsumoprem lorem  lorem iosyumm",
        img: AssessmentIcon,
        hoverImg: AssessmentWhite,
        slug: selectedMock.slug,
        tabType: showAssessmentType === "myCompany" ? "enterpriseAssessment" : 'assessment'
      };
      selectedMock.questions = questions;
      props.isFirstTime
        ? addnewTabData(assessmentTitleData)
        : props.tabTitleUpdate(assessmentTitleData);
    }
  };

  const stripDurationValue = (duration) => {
    const formatTime = duration.split(":");

    var formattedTime = "".concat(
      formatTime[0].concat("h:"),
      formatTime[1].concat("m")
    );

    return formattedTime;
  };

  const handleOnChangePagination = (event, page) => {
    setActivePage(page);
    getListOfMocks(page);
  };
  const handleOnChangePaginationQuestions = (event, page) => {
    setActiveQuestionsPage(page);
    getMockQuestions(selectedMock.slug, page);
  };

  useEffect(() => {
    // if (showAssessmentType !== "myCompany") {
    getListOfMocks(activePage);
    // } else {
    //   getMockQuestions("", 1);
    // }
  }, []);

  return (
    <>
      {mocksList.length > 0 && (
        <div
          className={`interviewAssess pb-5 ${
            props.interviewType === "main" && "mainInterviewInvert"
          }`}
        >
          <div className="row questionSetBody col-10 mx-auto">
            <div className="interviewAssessSubHeader d-flex justify-content-between align-items-center">
              <div>
                <h2>
                  Select any one question set from MachineHack ({mocksLength}{" "}
                  Sets)
                </h2>
              </div>
              <div>
                <button
                  onClick={() => {
                    AddAssessment();
                  }}
                >
                  <img
                    className="me-2 img-fluid"
                    src={attachAssessment}
                    loading="lazy"
                    alt="attachAssessment"
                  />
                  Attach Assessment
                </button>
              </div>
            </div>
            <div className="col-md-4 px-0">
              <div className="assessCardContainer">
                {/* {showAssessmentType === "myCompany" ? (
                  <div className="col-md-12 mockCard cursor-pointer activeQuestionSet questionSetBox position-relative">
                    <div className="activeQuestionSetBar"></div>

                    <div className="row">
                      <div className="col-md-11 mockCardContentContainer d-flex align-items-center">
                        <img src={ActiveTick} className="me-4" />

                        <div className=" questionSetTitle">
                          <h2>Multiple choice questions</h2>
                        </div>
                      </div>

                      <div className="col-md-1 mockSelectContainer d-none"></div>
                    </div>
                  </div>
                ) : ( */}
                <>
                  {mocksList.length > 0 &&
                    mocksList.map((mock) => {
                      return (
                        <MocksCard
                          key={mock._id}
                          id={mock.slug}
                          onSelect={handleSelectMock}
                          selectedMock={selectedMock}
                          mocksData={mock}
                          mockType={"interviewAssess"}
                        />
                      );
                    })}
                </>
                {/* )} */}
              </div>
              <div className="leaderBoardPagination">
                <Pagination
                  className="rainbow-m_auto"
                  pages={mocksLength > 10 ? parseInt(mocksLength / 10) + 1 : 1}
                  activePage={activePage}
                  onChange={handleOnChangePagination}
                  variant="shaded"
                />
              </div>
            </div>
            <div className="col-md-8 mocksDetails">
              <>
                <div className="containerAssess">
                  <div className="assessDetailsHeader">
                    <div>
                      <img
                        src={questionList}
                        className="me-3 img-fluid"
                        alt="question"
                        loading="lazy"
                      />
                      <span className="assessQuestionsCount">
                        <span>{totalQuestions}</span> Questions
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="assessDuration">
                        <img
                          src={TimerIcon}
                          className="me-2 img-fluid"
                          alt="timer"
                          loading="lazy"
                        />
                        <p>
                          {selectedMock && selectedMock.duration
                            ? Math.floor(selectedMock.duration / 60)
                            : "N/A"}min
                        </p>
                      </div>
                      {selectedMock.score && <div className="assessScore">
                        <span>Total Score: </span>

                        <p className="ms-2">{selectedMock.score}</p>
                      </div>}
                    </div>
                  </div>
                </div>
                {showAssessmentType !== "myCompany" && (
                  <div className="questionSkills">
                    {selectedMock?.skills?.length !== 0 && (
                      <span className="skillsTitle">Skills:</span>
                    )}

                    {selectedMock?.skills?.map((skill, i) => {
                      return <span className="tagsSkills">{skill}</span>;
                    })}
                  </div>
                )}
                <div className="mockQuestionList">
                  {questions.length > 0 ? (
                    questions.map((question) => (
                      <MockQuestion
                        key={question._id}
                        question={question}
                        selectedMock={selectedMock}
                      />
                    ))
                  ) : (
                    <div className="text-center pt-3">
                      There is no MCQ question under this assessment
                    </div>
                  )}
                </div>
                {questions.length > 0 && (
                  <Pagination
                    className="rainbow-m_auto pb-5"
                    pages={
                      totalQuestions > 20
                        ? parseInt(totalQuestions / 20) + 1
                        : 1
                    }
                    activePage={activeQuestionsPage}
                    onChange={handleOnChangePaginationQuestions}
                    variant="shaded"
                  />
                )}
              </>
            </div>
          </div>
        </div>
      )}
      {!isLoading && mocksList.length === 0 && (
        <div className="noSearchResult text-center">
          <img
            src={NoJobsIcon}
            alt="nojobs"
            className="img-fluid"
            loading="lazy"
          />
          <p style={{ color: `${props.noAssessmentTextColor}` }}>
            No Assessment found!
          </p>
        </div>
      )}
    </>
  );
}
