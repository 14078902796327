import { SidebarProvider } from "helper/SideBarContext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "routes";
import { SubscriptionPlanService } from "services/subscription.service";
import { OrganizationService } from "services/organization.service";
import { setOrganizationDetails } from "store/reducers/organizationSlice";
import { setSubscriptionFAQs } from "store/reducers/subscriptionSlice";
import { addPlans } from "store/reducers/subscriptionSlice";
import { setSubscriptionData } from "store/reducers/subscriptionSlice";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  async function getOrganizationData() {
    try {
      const res = await OrganizationService.fetchOrganizationData();
      dispatch(setOrganizationDetails(res));
    } catch (err) {
      console.log(err);
    }
  }

  const getUserSubscriptionData = async () => {
    try {
      const userSubscriptionResponse =
        await SubscriptionPlanService.checkIsUserSubscribed();
      if (userSubscriptionResponse?.data) {
        dispatch(setSubscriptionData(userSubscriptionResponse));
      }
    } catch (err) {
      if (err.response.status === 404) {
        const res = {
          data: {},
          totalCreatedAssessmentCount:
            err.response?.data?.result?.totalCreatedAssessmentCount,
          totalCreatedInterviewCount:
            err.response?.data?.result?.totalCreatedInterviewCount,
        };
        dispatch(setSubscriptionData(res));
      }
    }
  };
  const getAllSubscriptionData = async () => {
    try {
      const subscription_plans_response =
        await SubscriptionPlanService.fetchAllSubscriptionPlans();
      dispatch(addPlans(subscription_plans_response.data));
    } catch (err) {
      console.log(err);
    }
  };

  const getSubscriptionFaqs = async () => {
    try {
      const subscription_faqs_response = await SubscriptionPlanService.fetchFAQS();
      dispatch(setSubscriptionFAQs(subscription_faqs_response.data));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (user?._id) {
      getAllSubscriptionData();
      getUserSubscriptionData();
      getSubscriptionFaqs();
      getOrganizationData();
    }
  }, [user?._id]);
  
  return <SidebarProvider><Routes /></SidebarProvider>;
}

export default App;
