import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionData: {},
  payment_status: false,
  totalCreatedAssessmentCount: 0,
  totalCreatedInterviewCount: 0,
  plans: [],
  isLoaded: false,
  faqs: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload.data;
      state.isLoaded = true;
      state.totalCreatedAssessmentCount =
        action.payload.totalCreatedAssessmentCount;
      state.totalCreatedInterviewCount =
        action.payload.totalCreatedInterviewCount;
    },
    userPaymentStatus: (state, action) => {
      state.payment_status = action.payload;
    },
    addPlans: (state, action) => {
      state.plans = action.payload;
    },
    increementAssessmentCount: (state, action) => {
      state.totalCreatedAssessmentCount += 1;
    },
    decreementAssessmentCount: (state, action) => {
      state.totalCreatedAssessmentCount -= action.payload;
    },
    increementInterviewCount: (state, action) => {
      state.totalCreatedInterviewCount += 1;
    },
    decreementInterviewCount: (state, action) => {
      state.totalCreatedInterviewCount -= 1;
    },
    setSubscriptionFAQs: (state, action) => {
      state.faqs = action.payload;
    },
    addSubscriptionFAQ: (state, action) => {
      state.faqs = [action.payload, ...state.faqs];
    },
    updateSubscriptionFAQ: (state, action) => {
      state.faqs = state.faqs.filter((x) => {
        if (x._id === action.payload?._id) {
          Object.assign(x, action.payload);
        }
        return state.faqs;
      });
    },
    deleteSubscriptionFAQ: (state, action) => {
      state.faqs = state.faqs.filter((x) => {
        return x._id !== action.payload?._id;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSubscriptionData,
  userPaymentStatus,
  addPlans,
  increementAssessmentCount,
  decreementAssessmentCount,
  increementInterviewCount,
  decreementInterviewCount,
  setSubscriptionFAQs,
  addSubscriptionFAQ,
  updateSubscriptionFAQ,
  deleteSubscriptionFAQ,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
