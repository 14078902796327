import React from "react";
import { LoadingShape } from "react-rainbow-components";
import "./HackathonUserDetails.css";

function HackathonUserDetails({
  isLoading,
  isLeaderBoardLoading,
  userInfo,
  uId,
}) {
  return (
    <div className="hackathonUserDetails d-flex justify-content-between">
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
          <div className="col paragraph">
            Experience
            <div>
              {userInfo?.Experience?`${userInfo?.Experience} Yrs`:"NA"}
            </div>
          </div>
      )}

      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
          <div className="col paragraph">
            Current CTC
            <div>{userInfo?.currentCtc?`${userInfo?.currentCtc} LPA`:"NA"} </div>
          </div>
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
          <div className="col paragraph">
            Designation
            <div>{userInfo?.LatestDesignation?userInfo?.LatestDesignation:"NA"}</div>
          </div>
      )}
      {isLoading || isLeaderBoardLoading ? (
        <LoadingShape style={{ width: "15%", height: "40px" }} />
      ) : (
         (
          <div className="col paragraph">
            Company
            <div>{userInfo?.Organization?userInfo?.Organization:"NA"}</div>
          </div>
        )
      )}
    </div>
  );
}

export default HackathonUserDetails;
