import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import selectRoleModal from "../../../../assets/Icons/Assessment/selectRoleModal.svg";
import selectRoleDownArrow from "../../../../assets/Icons/Assessment/selectRoleDownArrow.svg";
import AddRole from "../../../../assets/Icons/Assessment/AddRole.svg";
import arrowWhite from "../../../../assets/Icons/Assessment/arrowWhite.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SelectRole(props) {
  const {
    jobRoles,
    setJobRoles,
    onJobRoleSubmit,
    totalJobrole,
    setJobRoleCurrentPage,
    jobRoleCurrentPage,
    selectRoleValue,
    setSelectRoleValue,
  } = props;
  const [showRoleDropDown, setShowRoleDropDown] = useState(false);
  const [selectRole, setSelectRole] = useState({});
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowRoleDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const fetchMore = () => {
    setJobRoleCurrentPage(jobRoleCurrentPage + 1);
  };
  const filteredRoles = jobRoles?.filter((selectRole) => {
    return selectRole.title
      .toLowerCase()
      .includes(selectRoleValue.toLowerCase());
  });

  const handleCreateRole = async () => {
    try {
      const response = await axios.post(
        `${BootcampBackend}/enterpriseAssessment/createJobRoleEnterprise`,
        { title: selectRoleValue },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (response) {
        setJobRoles((prev) => [...prev, response?.data?.result?.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        size="lg"
        centered="true"
        show={true}
        onHide={() => {
          props.setShowBuildAssessmentModal(false);
          props.setShowBuildAssessmentPage(false);
        }}
        className="assessmentSelectRoleModalBody"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="assessmentSelectRoleModal">
          <div className="align-items-center d-flex mt-5">
            <div className="ms-5">
              <img src={selectRoleModal} alt="select-role" />
            </div>
            <div className="me-3 ms-3 w-100">
              <div className="assessmentSelectRoleModal ms-4 pb-5 position-relative">
                <label className="assessmentSelectRoleModalLabel mb-2">
                  Select Role
                </label>
                <div
                  className="d-flex AssessmentSelectRoleDropDown"
                  onClick={() => setShowRoleDropDown(!showRoleDropDown)}
                >
                  <input
                    placeholder="Select a Role or Type in"
                    className="formInput col-11"
                    value={selectRoleValue}
                    onChange={(e) => setSelectRoleValue(e.target.value)}
                    name="selectRole"
                  />
                  <img
                    src={selectRoleDownArrow}
                    alt="selectRoleDownArrow"
                    className="ms-auto"
                  />
                </div>
                {showRoleDropDown ? (
                  <div
                    className="assessmentSelectrRoleDropDownField col-10"
                    ref={wrapperRef}
                  >
                    <div className="assessmentJobrolelist">
                      {jobRoles?.length <= 0 && filteredRoles?.length < 1 ? (
                        <div className="text-center noRoleFound my-2">
                          <span>No match found!</span>
                          <div
                            onClick={handleCreateRole}
                            className="cursor-pointer d-flex align-items-center justify-content-center"
                          >
                            <span className="me-2 sidebarInactiveText">
                              Add New Role
                            </span>
                            <img src={AddRole} />
                          </div>
                        </div>
                      ) : (
                        <InfiniteScroll
                          dataLength={jobRoles.length}
                          next={fetchMore}
                          hasMore={jobRoles.length < totalJobrole}
                          loader={<></>}
                          height={200}
                        >
                          {jobRoles?.map((selectRole, i) => {
                            return (
                              selectRole?.title
                                .toLowerCase()
                                .includes(selectRoleValue.toLowerCase()) && (
                                <p
                                  key={i}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setSelectRoleValue(selectRole.title);
                                    setShowRoleDropDown(false);
                                    setSelectRole(selectRole);
                                  }}
                                >
                                  {selectRole.title}
                                </p>
                              )
                            );
                          })}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="assessmentSelectRoleModalButton text-center">
                {Object.keys(selectRole).length > 0 ? (
                  <button
                    className="primaryBtnActive"
                    onClick={() => {
                      props.setShowBuildAssessmentPage(true);
                      props.setShowBuildAssessmentModal(false);
                      onJobRoleSubmit(selectRole);
                    }}
                  >
                    Submit
                    <img
                      className="ms-3 assessmentSelectRoleModalButtonImg"
                      src={arrowWhite}
                    />
                  </button>
                ) : (
                  <button className="primaryBtnActive disabledRemoveQestionBtn">
                    Submit
                    <img
                      className="ms-3 assessmentSelectRoleModalButtonImg"
                      src={arrowWhite}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
