import React, { useEffect, useState } from "react";
import "./yourJobs.css";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import viewAllSvg from "assets/Icons/DashboardNew/viewAll.svg";
const moment = require("moment");

function YourJobs({ jobList, isLoading }) {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState("1D");

  return (
    <>
      {jobList.length > 0 ? (
        <div className="col-xl-4 col-lg-4">
          {isLoading && jobList ? (
            <div className="row m-5">
              <div className={`col-lg-12 mt-1`}>
                <LoadingShape style={{ height: "40px" }} />
              </div>
              <div className="col-lg-12 mt-4">
                <LoadingShape style={{ height: "110px" }} />
              </div>
            </div>
          ) : (
            jobList.length > 0 && (
              <div className="dashboardCard jobs">
                <div className="d-flex align-items-center justify-content-between hackthonsTop">
                  <h5 className="dashboardHeading">
                    Your Jobs({jobList?.length})
                  </h5>
                  <div className="d-flex align-items-center viewAll">
                    <div className="dashboardFiltersTab d-flex">
                      <p
                        className={`${
                          selectedValue === "12M"
                            ? "dashboardFiltersActive"
                            : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("12M")}
                      >
                        12M
                      </p>
                      <p
                        className={`${
                          selectedValue === "1M" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("1M")}
                      >
                        1M
                      </p>
                      <p
                        className={`${
                          selectedValue === "7D" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("7D")}
                      >
                        7D
                      </p>
                      <p
                        className={`${
                          selectedValue === "1D" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("1D")}
                      >
                        1D
                      </p>
                    </div>
                    <div
                      className="dashboardViewAllImgHld ms-2 d-flex align-items-center"
                      onClick={() => {
                        history.push("/jobs");
                      }}
                    >
                      <img className="" src={viewAllSvg} alt="view all" />
                    </div>
                    {/* <p className="dashboardViewAll">View all</p> */}
                  </div>
                </div>

                <div className="totalJobCards row mx-0">
                  {jobList.length > 0 &&
                    jobList.map((job) => (
                      <div
                        key={job._id}
                        className="col-12 jobsCard jobCardOne px-0"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="yourJobsLeft">
                            <span
                              className={`yourJobStatus ${
                                job?.unpublish
                                  ? `yourJobStatusExpired`
                                  : `yourJobStatusActive`
                              } dashboardNormalText ms-3`}
                            >
                              {job?.unpublish ? "Expired" : "Active"}
                            </span>
                            <p className="yourJobTitle dashboardSubHeadText ps-3">
                              {job?.jobTitle}
                            </p>
                            <p className="yourJobPosted ps-3">
                              Posted on{" "}
                              {moment(job.createdAt).format("MMMM Do YYYY")}
                            </p>
                          </div>
                          <div className="yourJobsRight">
                            <p className="">Applicants - {job?.applicants}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default YourJobs;
