import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import arrowWhite from "../../../../assets/Icons/Assessment/arrowWhite.svg";
import { SubscriptionPlanService } from "services/subscription.service";
import { updateSubscriptionFAQ } from "store/reducers/subscriptionSlice";
import { useDispatch } from "react-redux";
import { addSubscriptionFAQ } from "store/reducers/subscriptionSlice";

const CreateFAQPopup = (props) => {
  const {
    showCreateFAQPopup,
    setShowCreateFAQPopup,
    editFaqData,
    setEditFaqData,
  } = props;
  const [FAQFormData, setFAQFormData] = useState({
    question: "",
    answer: "",
    priority: "",
  });
  const [submitClick, setSubmitClick] = useState(false);
  const dispatch = useDispatch();
  const onhandleChange = (event) => {
    const { name, value } = event.target;
    setFAQFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setShowCreateFAQPopup(!showCreateFAQPopup);
    if (editFaqData?._id) {
      setEditFaqData({});
      setFAQFormData({
        answer: "",
        question: "",
        priority: "",
      });
    }
  };
  const createSubscriptionFAQ = async () => {
    try {
      setSubmitClick(true);
      const create_faq_res = editFaqData?._id
        ? await SubscriptionPlanService.updateSubscriptionFaqs(
            editFaqData?._id,
            FAQFormData
          )
        : await SubscriptionPlanService.createSubscriptionFAQ(FAQFormData);
      setSubmitClick(false);
      if (create_faq_res.data) {
        setShowCreateFAQPopup(false);
        editFaqData?._id
          ? (dispatch(updateSubscriptionFAQ(create_faq_res.data)),
            setEditFaqData({}))
          : dispatch(addSubscriptionFAQ(create_faq_res.data));
          setFAQFormData({
            answer: "",
            question: "",
            priority: "",
          });
      }
    } catch (err) {
      setSubmitClick(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (editFaqData?._id) {
      setFAQFormData({
        answer: editFaqData?.answer,
        question: editFaqData?.question,
        priority: editFaqData?.priority,
        publish: editFaqData?.publish,
      });
    }
  }, [editFaqData?._id]);
  return (
    <Modal
      centered="true"
      dialogClassName="createPlanModal"
      size="lg"
      show={showCreateFAQPopup}
      onHide={handleClose}
    >
      <Modal.Header className="px-4 mx-2" closeButton>
        <Modal.Title>{editFaqData?._id ? "Edit" : "Create"} a FAQ </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 mx-2">
        <div className="createPlanModalBody">
          <div className="plan-form-group">
            <label>Question</label>
            <input
              type="text"
              className="form-control"
              placeholder="Write about your question here"
              name="question"
              value={FAQFormData.question}
              onChange={onhandleChange}
            />
          </div>
          <div className="plan-form-group">
            <label>Answer</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Describe your answer for the above "
              name="answer"
              rows={6}
              value={FAQFormData.answer}
              onChange={onhandleChange}
            />
          </div>
          <div className="plan-form-group">
            <label>
              Priority{" "}
              <span className="light-label">
                (We will show this question in which ever order you enter)
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Eg: 1"
              name="priority"
              value={FAQFormData.priority}
              onChange={onhandleChange}
            />
          </div>
          {FAQFormData.question &&
          FAQFormData.question &&
          FAQFormData.priority &&
          !submitClick ? (
            <button
              className="primaryBtnActive mx-auto d-block mt-4 mb-2"
              onClick={createSubscriptionFAQ}
            >
              {editFaqData?._id ? "Save" : "Submit"}
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          ) : (
            <button
              className="primaryBtnActive mx-auto d-block mt-4 mb-2 opacity50"
              disabled
            >
              {editFaqData?._id ? "Save" : "Submit"}
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateFAQPopup;
