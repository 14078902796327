import React, { useEffect } from "react";
import success from "assets/Icons/Settings/success.svg";
import cancel from "assets/Icons/Settings/cancel.svg";
import ErrorIcon from "assets/Icons/Interview/New/not-selected-icon.png";

export default function ToastMessages(props) {
  const { toastMessage, setToastMessage } = props;
  useEffect(() => {
    setTimeout(() => {
      setToastMessage({ variant: "",show: false, message: "", duration: 0 });
    }, toastMessage.duration);
  }, []);
  return (
    <div className="col-6 enterpriseModal">
      <div className=" d-flex justify-content-between m-1">
        <div>
          <img
            src={toastMessage?.variant === "success" ? success : ErrorIcon}
            className="img-fluid"
            loading="lazy"
            alt="success"
          />
          <span className="ms-3 enterprisePopUpText">
            {toastMessage?.message}
          </span>
        </div>
        <div
          onClick={() =>
            setToastMessage({ show: false, message: "", duration: 0 })
          }
        >
          <img
            className="me-1 img-fluid"
            src={cancel}
            loading="lazy"
            alt="close"
          />
        </div>
      </div>
    </div>
  );
}
