import React, { useEffect, useState } from "react";

import TimerIcon from "../../../../../assets/Icons/Assessments/timerIcon.svg";
import inActiveTick from "../../../../../assets/Icons/Interview/New/inActiveTick.svg";
import ActiveTick from "../../../../../assets/Icons/Interview/New/activeTick.svg";

import "./MocksCard.css";
import "../../../Interview/SelectAssessment.css";
const moment = require("moment");
export default function MocksCard(props) {
  function handleClick() {
    props.onSelect(props.mocksData);
  }
  const stripDurationValue = (duration) => {
    const formatTime = duration.split(":");

    var formattedTime = "".concat(
      formatTime[0].concat("h:"),
      formatTime[1].concat("m")
    );

    return formattedTime;
  };
  return (
    <div
      id={props.id}
      className={` col-md-12 mockCard cursor-pointer ${
        props.mockType === "interviewAssess"
          ? props.selectedMock && props.selectedMock.slug === props.id
            ? "activeQuestionSet questionSetBox position-relative "
            : "questionSetBox"
          : props.selectedMock && props.selectedMock.slug === props.id
          ? "activeCard"
          : ""
      }`}
      onClick={handleClick}
    >
      {props.mockType === "interviewAssess" &&
      props.selectedMock &&
      props.selectedMock.slug === props.id ? (
        <div className="activeQuestionSetBar"></div>
      ) : (
        <></>
      )}

      <div className="row">
        <div
          className={`col-md-11 mockCardContentContainer ${
            props.mockType === "interviewAssess" && "d-flex align-items-center "
          } `}
        >
          {props.mockType === "interviewAssess" &&
          props.selectedMock &&
          props.selectedMock.slug === props.id ? (
            <img src={ActiveTick} className="me-4" />
          ) : (
            <img src={inActiveTick} className="me-4" />
          )}

          <div
            className={` ${
              props.mockType === "interviewAssess"
                ? "questionSetTitle"
                : "mockTitle"
            }`}
          >
            <h2>{props.mocksData.title}</h2>
          </div>
          {props.mockType === "interviewAssess" ? (
            <></>
          ) : (
            <div className="mockInfoContainer">
              <div className="mockInfo">
                <img src={TimerIcon} />{" "}
                <span>
                  {props.mocksData && props.mocksData.duration
                    ? stripDurationValue(
                        moment
                          .utc(
                            moment
                              .duration(props.mocksData.duration, "seconds")
                              .asMilliseconds()
                          )
                          .format("HH:mm:ss")
                      )
                    : "N/A"}
                </span>
              </div>
              <div className="mockInfo">
                <span>No. of Questions </span>
                <p>{props.mocksData.questions.length}</p>
              </div>
              <div className="mockInfo">
                <span>Average score </span>{" "}
                <p>
                  {props.mocksData.avg_score !== null &&
                    props.mocksData.avg_score.toFixed(2)}
                </p>
                <p>{props.mocksData.avg_score === null && 0}</p>
              </div>
            </div>
          )}
        </div>

        <div
          className={`col-md-1 mockSelectContainer ${
            props.mockType === "interviewAssess" && "d-none"
          } `}
        >
          <input
            type="radio"
            onChange={handleClick}
            checked={
              props.selectedMock && props.selectedMock.slug === props.id
                ? true
                : false
            }
          />
        </div>
      </div>
    </div>
  );
}
