import React, { useEffect, useState } from "react";
import "./Form.css";

import SubmitSuccessIcon from "../../../../../assets/Icons/Jobs/submitSuccess.svg";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { TagsInput } from "react-tag-input-component";

import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

import axios from "axios";
import { BootcampBackend } from "../../../../../backend";

import RupeeIcon from "../../../../../assets/Icons/Dashboard/rupee1.svg";
import TimeIcon from "../../../../../assets/Icons/Dashboard/time1.svg";
import BadgeIcon from "../../../../../assets/Icons/Dashboard/badge1.svg";
import LocationIcon from "../../../../../assets/Icons/Dashboard/location1.svg";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const moment = require("moment");

export default function NewFormComponent(props) {
  const history = useHistory();

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState([]);

  const [title, showTitle] = useState("");
  const [experience, showExperience] = useState("");
  const [addJobLocation, showAddJobLocation] = useState("");
  const [addJobsalary, showAddJobSalary] = useState("");
  const [addJobsalaryTwo, showAddJobSalaryTwo] = useState("");
  const [addJobType, showAddJobType] = useState("");
  const [addJobDescription, setaddJobDescription] = useState("");
  const [addJobPostedBy, showaddJobPostedBy] = useState("");

  const [addJobSkills, showAddJobSkills] = useState([]);

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: undefined,
    experienceRequired: undefined,
    location: "",
    startRange: "",
    endRange: "",
    postedBy: undefined,
  });

  const [users, setUsers] = useState([]);

  const [submitSuccessModal, setSubmitSuccessModal] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function showModal() {
    setSubmitSuccessModal(true);
  }

  function hideModal() {
    setSubmitSuccessModal(false);
    // history.push("/jobs");
    if (splitLocation.includes("admin")) {
      history.push("/adminview");
    } else {
      history.push("/jobs");
    }
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (props.type === "Add") {
        const res = await axios.post(
          `${BootcampBackend}/jobs/create`,
          {
            jobTitle: formData.jobTitle,
            jobDescription: description,
            jobType: formData.jobType,
            experienceRequired: formData.experienceRequired,
            skills: skills,
            location: formData.location,
            startRange: formData.startRange,
            endRange: formData.endRange,
            timeStamp: Date(),
            postedBy: formData.postedBy,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        );
        console.log(res);
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          showModal();
        }
      } else if (props.type === "Edit") {
        const res = await axios.put(
          `${BootcampBackend}/jobs/update/${props.id ? props.id : ""}`,
          {
            jobTitle: formData.jobTitle,
            jobDescription: description,
            jobType: formData.jobType,
            experienceRequired: formData.experienceRequired,
            skills: skills,
            location: formData.location,
            startRange: formData.startRange,
            endRange: formData.endRange,
            timeStamp: Date(),
            postedBy: formData.postedBy,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        );
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          showModal();
        }
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleCancel() {
    history.push("/jobs");
  }

  async function fetchAllUsers() {
    try {
      const res = await axios.get(`${BootcampBackend}/iamUser/all`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchJobDetails() {
    try {
      const res = await axios.get(
        `${BootcampBackend}/jobs/getJobFromId/${props.id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAllUsers()
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          setUsers(res.data.result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (props.id) {
      fetchJobDetails()
        .then((res) => {
          setFormData({
            jobTitle: res.data.result.data.jobTitle,
            jobType: res.data.result.data.jobType,
            experienceRequired: res.data.result.data.experienceRequired,
            location: res.data.result.data.location,
            startRange: res.data.result.data.AnnualSalaryRange.start,
            endRange: res.data.result.data.AnnualSalaryRange.end,
            postedBy: res.data.result.data.postedBy,
          });

          setSkills(res.data.result.data.skills);
          setDescription(res.data.result.data.jobDescription);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function numDifferentiation(value) {
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 100000).toFixed(0);
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(1);
    } else if (val <= 100000) {
      val = (val / 100000).toFixed(2);
    }
    return val;
  }

  return (
    <>
      <div className="row g-0">
        <form
          className="jobForm col-md-6 addJobFormScroller"
          onSubmit={handleSubmit}
        >
          <div className="row mt-4">
            <div className="mb-3">
              <label htmlFor="jobTitle">Job Title</label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  placeholder="Eg: Software Engineer"
                  onChange={handleChange}
                  required
                  className={`form-input`}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="jobDescription">Job Description</label>
              <div className="jobsCkEditor">
                <CKEditor
                  editor={Editor}
                  data={description}
                  config={{
                    toolbar: [
                      "Essentials",
                      "Heading",
                      "Paragraph",
                      "Bold",
                      "Italic",
                      "Link",
                      "BlockQuote",
                      "bulletedList",
                      "numberedList",
                      "TextTransformation",
                    ],
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="jobType">Type of Position</label>
              <select
                name="jobType"
                value={formData.jobType}
                onChange={handleChange}
                required
                className="form-input"
                defaultValue=""
              >
                <option disabled value="">
                  Select type of position
                </option>
                <option value="Full Time">Full-time employee</option>
                <option value="Intern">Intern</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="experienceRequired">Work Experience</label>
              <select
                name="experienceRequired"
                value={formData.experienceRequired}
                onChange={handleChange}
                required
                className="form-input"
                defaultValue=""
              >
                <option disabled value="">
                  Select years of experience
                </option>
                <option value="0-1">0-1</option>
                <option value="1-3">1-3</option>
                <option value="3-6">3-6</option>
                <option value="6-10">6-10</option>
                <option value="10-15">10-15</option>
                <option value="15+">15+</option>
              </select>
            </div>

            <div className="addJobTotalSkillsSection mb-3">
              <label htmlFor="skills">Skills</label>
              {skills.length > 0 && (
                <TagsInput //TO cause re-render (issue -> values not changing on state change )
                  value={skills}
                  onChange={setSkills}
                  name="skills"
                  placeHolder="Enter skills"
                />
              )}
              {!skills.length && (
                <TagsInput
                  value={skills}
                  onChange={setSkills}
                  name="skills"
                  placeHolder="Enter skills"
                />
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="location">Where are you hiring this role ?</label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  placeholder=""
                  onChange={handleChange}
                  required
                  className={`form-input`}
                />
              </div>
            </div>

            <hr />

            <div className="mb-3 mt-3">
              <div style={{ width: "35%" }}>
                <label htmlFor="salary">Annual Salary Range</label>
              </div>
              <div
                style={{
                  width: "65%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      ₹
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0000"
                      name="startRange"
                      value={formData.startRange}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>-</div>
                <div style={{ width: "40%" }}>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      ₹
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0000"
                      name="endRange"
                      value={formData.endRange}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="mt-3 mb-3">
              <label htmlFor="postedBy">Posted by</label>
              <select
                name="postedBy"
                value={formData.postedBy}
                onChange={handleChange}
                required
                className="form-input"
                defaultValue=""
              >
                <option disabled value="">
                  Select User
                </option>
                {users.length > 0 &&
                  users.map((user) => {
                    return (
                      <option
                        value={user._id}
                      >{`${user.firstName} ${user.lastName}`}</option>
                    );
                  })}
              </select>
            </div>

            <div className="form-group mt-3 d-flex justify-content-center">
              <button type="submit" className="buttonSubmit mx-2">
                Submit
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="buttonCancel mx-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>

        <div className="col-md-6 addJobPreview addJobFormScroller">
          <div className="addJobPreviewTextSection">
            <p className="addJobPreviewText py-3 ms-4">Job Preview</p>
          </div>
          <div className="addJobPreviewTextSectionBottom m-4 mt-3">
            {formData.jobTitle ? (
              <p className="addJobShowTitle">{formData.jobTitle}</p>
            ) : (
              <p className="addJobShowTitleBefore">Job Title</p>
            )}
            <p className="addJobPostedBy">
              Posted date:&nbsp;{moment().format("DD MMM YYYY")}
            </p>

            <div className="addJobDetials mt-3 d-flex">
              <div className=" d-flex align-items-center ">
                <img src={BadgeIcon} alt="badge" />
                &nbsp;&nbsp;&nbsp;
                {formData.experienceRequired ? (
                  <p className="addJobExpirence">
                    {formData.experienceRequired}&nbsp;Yrs
                  </p>
                ) : (
                  <p className="addJobExpirenceBefore">
                    0&nbsp;-&nbsp;0&nbsp;Yrs
                  </p>
                )}
              </div>
              <div className="eachAddJobDetials d-flex align-items-center ">
                <img src={RupeeIcon} alt="badge" />
                &nbsp;&nbsp;&nbsp;
                {formData.startRange ? (
                  <p className="addJobExpirence">
                    {formData.startRange &&
                      numDifferentiation(formData.startRange)}
                    -
                  </p>
                ) : (
                  <p className="addJobExpirenceBefore">0&nbsp;-&nbsp;</p>
                )}
                {formData.endRange ? (
                  <p className="addJobExpirence">
                    {formData.endRange && numDifferentiation(formData.endRange)}
                    &nbsp;LPA
                  </p>
                ) : (
                  <p className="addJobExpirenceBefore">0&nbsp;LPA</p>
                )}
              </div>
              <div className="eachAddJobDetials d-flex align-items-center ">
                <img src={LocationIcon} alt="badge" />
                &nbsp;&nbsp;&nbsp;
                {formData.location ? (
                  <p className="addJobExpirence">
                    {`${
                      formData.location.length > 8
                        ? formData.location.slice(0, 8) + "."
                        : formData.location
                    }`}
                  </p>
                ) : (
                  <p className="addJobExpirenceBefore">Location</p>
                )}
              </div>
              <div className="eachAddJobDetials d-flex align-items-center ">
                <img src={TimeIcon} alt="badge" />
                &nbsp;&nbsp;&nbsp;
                {formData.jobType ? (
                  <p className="addJobExpirence">
                    {formData.jobType && formData.jobType}
                  </p>
                ) : (
                  <p className="addJobExpirenceBefore">PositionType</p>
                )}
              </div>
            </div>

            <div>
              <div className="addJobAboutInfoTab mt-4">
                <div className="addJobTitleInfoTab mb-3">
                  <h3 className="addJobTabInsideDescription">Skills</h3>
                </div>
              </div>

              <div className="addJobPreviewTotalSkills mt-3">
                {skills.length >= 1 ? (
                  skills.map((eachSkill, index) => (
                    <h6
                      className="addJobPreviewSkills d-flex align-items-center justify-content-center py-2 px-3"
                      key={index}
                    >
                      {eachSkill}
                    </h6>
                  ))
                ) : (
                  <div className="d-flex mb-3">
                    <p className="addJobPreviewSkillsBefore px-4 py-1">
                      Skill&nbsp;1
                    </p>
                    <p className="addJobPreviewSkillsBefore px-4 py-1">
                      Skill&nbsp;2
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="addJobAboutInfoTabDescription">
                <div className="addJobTitleInfoTabDescription mb-3">
                  <h3 className="addJobTabInsideDescriptionDescription">
                    Description
                  </h3>
                </div>
              </div>
              {description ? (
                <div
                  className="mt-3 addJobDescriptionInnerText"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></div>
              ) : (
                <p className="addJobExpirenceBefore mt-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sed augue lacus viverra vitae congue eu consequat. Fames ac
                  turpis egestas sed tempus urna et pharetra pharetra. In nisl
                  nisi scelerisque eu ultrices. Faucibus interdum posuere lorem
                  ipsum dolor sit. Tincidunt tortor aliquam nulla facilisi cras
                  fermentum odio eu feugiat. Odio ut enim blandit volutpat
                  maecenas volutpat. Semper feugiat nibh sed pulvinar. Elit eget
                  gravida cum sociis natoque penatibus et magnis dis. Pharetra
                  sit amet aliquam id diam maecenas ultricies.
                </p>
              )}
            </div>
          </div>
          {/* <div>
            <div className="addJobAboutInfoTabPostedBy mt-3">
              <div className="addJobTitleInfoTabPostedBy mb-2">
                <h3 className="addJobTabInsideDescriptionPostedBy">
                  Posted By
                </h3>
              </div>
            </div>

            <div>
              <p>{addJobPostedBy}</p>
            </div>
          </div> */}
        </div>
      </div>
      <Modal
        centered="true"
        dialogClassName="submitSuccessModal"
        show={submitSuccessModal}
        onHide={hideModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="submitSuccessMessage text-center">
            <img src={SubmitSuccessIcon} alt="success" />
            <h4>Success!</h4>
            <p>Your job is posted successfully</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
