import React from "react";
import arrowSvg from "assets/Icons/ResumeSearch/arrow.svg";
import crossSvg from "assets/Icons/cross.svg";
export default function Filters({
  filter,
  filterSearchText,
  index,
  setFilterSearchText,
  setFilters,
  filters,
  handleScrollFilter,
  handleChangeFilter,
  setShowDropdown,
  showDropDown,
  wrapperRef,
}) {
  return (
    <div key={`filter${index}`} className="resumeFormGroup me-3">
      <label className="grayBtnText">{filter.label}</label>
      <input
        type="text"
        placeholder={`Search ${filter.label}`}
        className={`resumefilterInput ${
          filters[filter.name] && filters[filter.name]?.length > 0
            ? `activeInput`
            : ``
        }`}
        name={filter.name}
        value={
          filter.type === "dropdown"
            ? filterSearchText[filter.name]
            : filters[filter.name]
        }
        onChange={(e) =>
          handleChangeFilter(filter.name, e.target.value, filter.type)
        }
        onClick={() =>
          filter.type === "dropdown" && setShowDropdown(filter.name)
        }
      />
      {filter.type === "dropdown" && (
        <img
          src={filterSearchText[filter.name] ? crossSvg : arrowSvg}
          className="dropDownArrow cursor-pointer"
          alt={"arrow"}
          onClick={(e) => {
            e.stopPropagation();
            if (filterSearchText[filter.name] || filters[filter.name]) {
              setFilterSearchText({
                ...filterSearchText,
                [filter.name]: "",
              });
              setFilters({
                ...filters,
                [filter.name]: filter.name === "skills" ? [] : "",
              });
            }
          }}
        />
      )}
      {filter.type === "dropdown" &&
      showDropDown === filter.name &&
      filter.optionState?.length ? (
        <div
          ref={wrapperRef}
          className={`resumeDropdown ${
            filter.name !== "location" ? `wideFilter` : ``
          }`}
          onScroll={(e) => handleScrollFilter(e, filter.name)}
        >
          {filter.optionState?.map((option, i) => (
            <div key={`resumeOptionHld${i}`} className="resumeOptionHld d-flex align-items-center">
              {filter.name !== "location" ? (
                <input
                  type="checkbox"
                  className="ms-2 me-2 AssessmentCheckbox "
                  onChange={() => {
                    if (filters[filter.name].includes(option.name)) {
                      const index = filters[filter.name].indexOf(option.name);
                      if (index > -1) {
                        filters[filter.name].splice(index, 1);
                      }
                      setFilters((prev) => ({
                        ...prev,
                        [filter.name]: filters[filter.name],
                      }));
                      setFilterSearchText({
                        ...filterSearchText,
                        [filter.name]: `${filter.name}(${
                          filters[filter.name]?.length
                        })`,
                      });
                    } else {
                      setFilters((prev) => ({
                        ...prev,
                        [filter.name]: [...prev[filter.name], option.name],
                      }));
                      setFilterSearchText({
                        ...filterSearchText,
                        [filter.name]: `${filter.name}(${
                          filters[filter.name]?.length + 1
                        })`,
                      });
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  checked={filters[filter.name].includes(option.name)}
                />
              ) : (
                <></>
              )}
              <p
                key={`resumeDropdown${i}`}
                className="resumeDropdownOption"
                onClick={() => {
                  handleChangeFilter(filter.name, option.name, "input");
                }}
              >
                {filter.name === "location"
                  ? `${option?.name},${option.state_name}`
                  : option.name}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
