import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import calenderIcon from "../../../assets/Icons/Assessment/calender.svg";
import arrowRightIcon from "../../../assets/Icons/Assessment/arrowRight.svg";
import downloadIcon from "../../../assets/Icons/Assessment/download.svg";
import shareIcon from "../../../assets/Icons/Assessment/share.svg";
import React, { useEffect, useState } from "react";
import "./userReport.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import { BootcampBackend, Domain } from "backend";
import axios from "axios";
import moment from "moment";
import { useSidebar } from "helper/SideBarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserTimeLine(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [isCopied, setIsCopied] = useState("");
  const [userTimeLine, setUserTimeLine] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const query = useQuery();
  const params = useParams();
  const isLms = query.get("lms");

  const onCopy = (data) => {
    navigator.clipboard.writeText(`${Domain}/assessments/${params.assessmentId}/report/${data?._id}${isLms === "true" ? "?lms=true" : ""}`);
    setIsCopied(data?._id);
  };

  const getUserTimeline = async () => {
    try {
      const url = isLms === "true"
        ? `${BootcampBackend}/enterpriseAssessment/getLmsParticiapntTimeline/${props.match.params.userId}`
        : `${BootcampBackend}/enterpriseAssessment/getParticiapntTimeline/${props.match.params.userId}`;
      const timelineResponse = await axios.get(url, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (timelineResponse) {
        setIsLoading(false);
        setUserTimeLine(timelineResponse.data?.result?.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getUserTimeline();
  }, []);
  return (
    <>
      <Header
        heading={`${
          userTimeLine.length > 0 ? userTimeLine[0]?.userId?.Name : "Timeline"
        }`}
        route={`/assessments/${params.assessmentId}/participants`}
      />
      <Navbar/>
      <div
        className={`AssessmentCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="userTimeline">
          <ul>
            {isLoading ? (
              <>
                {Array.from(
                  {
                    length: 3,
                  },
                  (_, i) => (
                    <li
                      key={i}
                      className="userTimeline-body col-lg-10  mx-auto"
                    >
                      <div>
                        <div className="userCompletedTime d-flex px-4 pt-3">
                          <LoadingShape className="dateTimeLoader" />
                        </div>
                        <div className="d-flex justify-content-between mt-1 align-items-centerpx-4 py-3 px-4">
                          <LoadingShape className="testNameLoader" />
                          <div className="d-flex">
                            <LoadingShape className="shareButtonLoader me-4" />
                            <LoadingShape className="viewReportButtonLoader me-4" />
                            <LoadingShape className="shareButtonLoader" />
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </>
            ) : (
              <>
                {userTimeLine?.map((timeline, index) => (
                  <li
                    className={`userTimeline-body col-lg-10  mx-auto ${
                      timeline?.interviewStatus === "Hire"
                        ? "hire-userTimeline-body"
                        : timeline?.interviewStatus === "Reject"
                        ? "reject-userTimeline-body"
                        : ""
                    }`}
                    key={timeline._id}
                  >
                    <div>
                      <div className="d-flex justify-content-between mt-1 px-4 pt-3 pb-1">
                        <p>{timeline?.assessmentId?.title}</p>
                        {timeline?.assessmentStatus === "Completed" && (
                          <div>
                            {isCopied === timeline?._id ? (
                              <button className="share-button copiedButton">
                                <img src={shareIcon} className="me-2" />
                                <span>Copied</span>
                              </button>
                            ) : (
                              <button
                                className="share-button"
                                onClick={() => onCopy(timeline)}
                              >
                                <img src={shareIcon} className="me-2" />
                                <span>Copy Link</span>
                              </button>
                            )}

                            <button
                              className="report-button"
                              onClick={() =>
                                history.push(
                                  `/assessments/${params.assessmentId}/report/${timeline?._id}${isLms === "true" ? "?lms=true" : ""}`
                                )
                              }
                            >
                              <span>View Report</span>
                              <img
                                src={arrowRightIcon}
                                className="ms-3 arrowIcon"
                              />
                            </button>
                            {timeline?.userId?.Resume_key && (
                              <span
                                className="report-download"
                                onClick={() =>
                                  window.open(
                                    timeline?.userId?.Resume_key,
                                    "_blank"
                                  )
                                }
                              >
                                <span>Resume</span>
                                <img src={downloadIcon} className="ms-3" />
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="userCompletedTime align-items-center d-flex px-4">
                        <img
                          src={calenderIcon}
                          className="me-2 img-fluid"
                          loading="lazy"
                          alt="calender"
                        />
                        <span className=" me-2">
                          {timeline?.completedAt
                            ? moment(timeline?.completedAt).format(
                                "MMM Do YYYY"
                              )
                            : timeline?.startedAt
                            ? moment(timeline?.startedAt).format("MMM Do YYYY")
                            : timeline?.invitedAt
                            ? moment(timeline?.invitedAt).format("MMM Do YYYY")
                            : moment(timeline?.createdAt).format("MMM Do YYYY")}
                        </span>
                        <div className="timelineDot me-2 ms-1"></div>
                        <span>
                          {timeline?.completedAt
                            ? moment(timeline?.completedAt).format("h:mm A")
                            : timeline?.startedAt
                            ? moment(timeline?.startedAt).format("h:mm A")
                            : timeline?.invitedAt
                            ? moment(timeline?.invitedAt).format("h:mm A")
                            : moment(timeline?.createdAt).format("h:mm A")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ps-4 pt-2 userCompletedTime">
                        <div className="d-flex align-items-center pb-2">
                          <span className=" me-2">
                            {timeline?.invitedBy?.firstName +
                              " " +
                              timeline?.invitedBy?.lastName}
                          </span>
                          <div className="timelineDot me-2 ms-1"></div>
                          <span>{timeline?.invitedBy?.emailId}</span>
                        </div>
                        <div
                          className={`d-flex align-items-center interviewStatushld ${
                            timeline?.interviewStatus === "Hire"
                              ? "hiredInterviewStatusHld"
                              : timeline?.interviewStatus === "Reject"
                              ? "rejectedInterviewStatusHld"
                              : ""
                          }`}
                        >
                          <span>Status </span>
                          <p className="ms-2"> {timeline?.interviewStatus}</p>
                        </div>
                      </div>
                    </div>
                    <div className="dot"></div>
                    {userTimeLine.length - 1 !== index && (
                      <div className="dottedLine"></div>
                    )}
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
