import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import QuestionList from "./QuestionList";
import QuestionSetList from "./QuestionSetList";

export default function Questions(props) {
  const location = useLocation();
  const [isSidebarCollapse, setIsSidebarCollapse] = useState(false);
  const [selectedQuestion, setSelectedQuestions] = useState([]);
  const QuestionType = location.pathname.split("/")[4];

  const renderQuestion = () => {
    switch (QuestionType) {
      case "questions-set":
        return (
          <QuestionSetList
            props={props}
            selectedQuestion={selectedQuestion}
            setSelectedQuestions={setSelectedQuestions}
          />
        );
      case "questions":
        return (
          <QuestionList props={props} selectedQuestion={selectedQuestion} />
        );
    }
  };
  useEffect(() => {
    localStorage.getItem("selectedQuestions") !== null &&
      setSelectedQuestions(
        localStorage.getItem("selectedQuestions").split(",")
      );
  }, []);
  return (
    <div>
      {/* {console.log(selectedQuestion)} */}
      <Header heading="Questions" isSidebarCollapse={isSidebarCollapse} />
      <Navbar
        sidebarCollapseChange={(value) => setIsSidebarCollapse(value)}
        isSidebarCollapse={isSidebarCollapse}
      />
      {renderQuestion()}
    </div>
  );
}
