import axios from "axios";
import { BootcampBackend } from "backend";
import { GetAuthorizationConfig } from "./auth.service";

export const OrganizationService = {
  fetchOrganizationData: async () => {
    return new Promise((resolve, reject) => {
       axios
        .get(
          `${BootcampBackend}/organization_profile`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
