import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import "./userSubscription.css";
import { SubscriptionPlanService } from "services/subscription.service";
import moment from "moment";
import SubscriptionPlanScreen from "pages/PrivatePages/PricingPages/SubscriptionPlanScreen";
import { useSelector } from "react-redux";
import { Pagination } from "react-rainbow-components";
import ConfirmationModal from "pages/PrivatePages/JobsPage/components/ConfirmationModal/ConfirmationModal";
import InvoicePopup from "../InvoicePopup/InvoicePopup";
import ContactUs from "components/ContactUs/ContactUs";
import NoDataimage from "assets/Images/noData.svg";
import NewAddIcon from "assets/Icons/Jobs/newAddJob.svg";
import { useSidebar } from "helper/SideBarContext";

const UserSubscriptions = () => {
  const { isSidebarCollapsed } = useSidebar();
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showPlan, setShowPlan] = useState(false);
  const [showCancelSubscriptionPopup, setShowCancelSubscriptionPopup] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const subscriptionData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const subscriptionPaymentStatus = useSelector(
    (state) => state.subscription.payment_status
  );
  const [showContactUs, setShowContactUs] = useState(false);
  const organizationDetails = useSelector((state) => state.organization.organizationDetails);
  const onCancelSubscription = async () => {
    try {
      const requestData = {
        mh_order_id: selectedPlan?.orderId,
      };
      if (selectedPlan?.razorpay_subscription_id) {
        requestData.razorpay_subscription_id =
          selectedPlan?.razorpay_subscription_id;
      }
      const cancel_subscription_response =
        await SubscriptionPlanService.cancelSubscription(requestData);
      if (
        cancel_subscription_response?.data?.mh_subscription_id ===
        subscriptionData?.mh_subscription_id
      ) {
        setUserSubscriptionList((prev) =>
          prev.filter((x) => {
            if (x?._id === selectedPlan?._id) {
              x.subscriptionStatus =
                cancel_subscription_response?.data?.subscriptionStatus;
            }
            return prev;
          })
        );
        setShowCancelSubscriptionPopup(!showCancelSubscriptionPopup);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllUserSubscriptionlist = async () => {
    try {
      const user_subscription_list =
        await SubscriptionPlanService.fetchAllUserSubscribedPlans(currentPage);
      setIsLoading(false);
      if (user_subscription_list.count > 0) {
        setUserSubscriptionList(user_subscription_list.data);
        setTotalCount(user_subscription_list.count);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getAllUserSubscriptionlist();
  }, [currentPage, subscriptionPaymentStatus]);
  return (
    <div>
      <Header heading="" />
      <div className="container-fluid body-margin-top px-0">
        <Navbar showApiTab={organizationDetails?.apiKey} />
        <div
          className={`${
            isSidebarCollapsed ? "collapsed-content-page" : "content-page"
          }`}
        >
          <div className="my-subscription-header">
            <h5 className="">My Subscriptions</h5>
          </div>
          <div className="subscription-list-page">
            {isLoading ? (
              <></>
            ) : userSubscriptionList?.length === 0 ? (
              <div className="text-center my-5 py-5">
                <img
                  src={NoDataimage}
                  className="img-fluid nodata-img mb-4"
                  alt="no-data"
                />
                <p className="nodata-text mb-4">
                  you have not subscribed to any plan
                </p>
                <div className="text-center">
                  <button
                    className="primaryBtnActive"
                    onClick={() => setShowPlan(!showPlan)}
                  >
                    Create Plan
                    <img
                      src={NewAddIcon}
                      className="img-fluid ms-2"
                      alt="create"
                      width="12"
                      height="12"
                    />
                  </button>
                </div>
              </div>
            ) : (
              userSubscriptionList?.map((userPlan) => (
                <div className="user-subscription-hld" key={userPlan?._id}>
                  <div className="">
                    <div className="d-flex align-items-center">
                      <p className="subscription-plan-text me-3">
                        Subscription Plan
                      </p>
                      <p
                        className={`subscription-status text-capitalize ${
                          userPlan?.subscriptionStatus === "success" &&
                          moment().isBefore(userPlan?.endsAt)
                            ? "subscription-active-status"
                            : ""
                        }`}
                      >
                        {userPlan?.subscriptionStatus === "success"
                          ? moment().isBefore(userPlan?.endsAt)
                            ? "Active"
                            : "Expired"
                          : userPlan?.subscriptionStatus}
                      </p>
                    </div>
                    <div className="subscription-details-hld mt-1">
                      <p className="subscription-details subscription-plan-name text-capitalize">
                        {userPlan?.mh_subscription_plan_id?.name} Plan
                      </p>
                      <p className="subscription-details subscription-time-period">
                        {moment(userPlan?.startsAt).format("MMM DD, YYYY")} -{" "}
                        {moment(userPlan?.endsAt).format("MMM DD, YYYY")}
                      </p>
                      <p className="subscription-details subscription-plan-text text-capitalize">
                        {userPlan?.subscriptionPeriod}
                      </p>
                    </div>
                    {userPlan?.subscriptionStatus === "success" &&
                    moment().isBefore(userPlan?.endsAt) ? (
                      <button
                        className="cancel-btn ms-3"
                        onClick={() => {
                          setSelectedPlan(userPlan);
                          setShowCancelSubscriptionPopup(
                            !showCancelSubscriptionPopup
                          );
                        }}
                      >
                        Cancel Subscription
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="">
                    <button
                      className="downloadInvoice-btn"
                      onClick={() => {
                        setSelectedPlan(userPlan);
                        setShowInvoicePopup(!showInvoicePopup);
                      }}
                    >
                      Download Invoice
                    </button>
                    {userPlan?.subscriptionStatus === "success" &&
                    moment().isBefore(userPlan?.endsAt) ? (
                      <button
                        className="ms-3 upgrade-btn"
                        onClick={() => setShowPlan(!showPlan)}
                      >
                        Upgrade to Professional
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))
            )}

            {totalCount > 10 ? (
              <Pagination
                className="rainbow-m_auto candidatesListPagination"
                pages={totalCount > 0 ? parseInt(totalCount / 10) + 1 : 1}
                activePage={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                variant="shaded"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        titleType="generic"
      />
      <ConfirmationModal
        ModalHeading={"Cancel Subscription"}
        ModalBody={"Are you sure you want to cancel?"}
        closeIcon={true}
        ActionBtnName={"Yes"}
        closeBtnName={"No"}
        ActionBtn={() => onCancelSubscription()}
        showPopup={showCancelSubscriptionPopup}
        setShowPopup={setShowCancelSubscriptionPopup}
      />
      <InvoicePopup
        showInvoicePopup={showInvoicePopup}
        setShowInvoicePopup={setShowInvoicePopup}
        selectedPlan={selectedPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type={"pricing"}
      />
    </div>
  );
};
export default UserSubscriptions;
