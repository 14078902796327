import React, { useEffect, useRef, useState } from "react";
import "./interviewprocess.css";
import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import rightWhiteArrowIcon from "../../../../assets/Icons/Interview/New/rightWhiteArrow.svg";
import AppliedIcon from "../../../../assets/Icons/Jobs/rightArrowBlack.svg";
import ViewReport from "../../../../assets/Icons/Interview/New/viewReportIcon.svg";
import calenderIcon from "../../../../assets/Icons/Interview/New/calenderIcon.svg";
import personIcon from "../../../../assets/Icons/Interview/New/personIcon.svg";
import moreOptionIcon from "../../../../assets/Icons/Interview/more-option-icon.svg";
import selectedIcon from "../../../../assets/Icons/Interview/New/selectedIcon.svg";
import rejectedredIcon from "../../../../assets/Icons/Interview/New/rejectedIcon.svg";
import passedIcon from "../../../../assets/Icons/Interview/passes-icon.svg";
import rejectedIcon from "../../../../assets/Icons/Interview/Rejected.svg";
import EditJobIcon from "../../../../assets/Icons/Interview/New/editIcon.svg";
import { useHistory } from "react-router";
import ReactStars from "react-rating-stars-component";
import dropdownArrow from "../../../../assets/Icons/Interview/New/dropdownArrow.svg";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import CreateInterview from "../../../../assets/Icons/Interview/New/createInterview.svg";
import { BootcampBackend, Machinehack_Url } from "../../../../backend";
import interviewLink from "../../../../assets/Icons/Interview/New/newLink.svg";
import axios from "axios";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Cross from "../../../../assets/Icons/Interview/New/Cross.svg";
import LinkIcon from "../../../../assets/Icons/Interview/New/Link.svg";
import DateIcon from "../../../../assets/Icons/Interview/New/DateIcon.svg";
import correctIcon from "../../../../assets/Icons/Interview/New/greenTick.svg";

import Clock from "../../../../assets/Icons/Interview/New/clock.svg";
import Datetime from "react-datetime";
import { useSidebar } from "helper/SideBarContext";

// Functional components
export default function InterviewProcess(props) {
  const [interviewData, setInterviewData] = useState({});
  const [isEditStatus, setIsEditStatus] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [Notes, setNotes] = useState("");
  const { isSidebarCollapsed } = useSidebar();
  const [startTime, setStartTime] = useState(moment());
  const [instantLink, setInstantLink] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [modalInterview, setModalInterview] = useState(0);
  const [token, setInterviewToken] = useState("");
  const [mailList, setMailList] = useState("email");
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [candidateEmailError, setCandidateEmailError] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [show, setShow] = useState(false);
  const [Mhlist, setMhlist] = useState([]);
  const [editableItem, setIsEditableItem] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [endTime, setEndTime] = useState();
  const [createRound, setCreateRound] = useState(false);
  const [interviewRoundNo, setInterviewRoundNo] = useState(0);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      color: "#3c3e4d",
      padding: 8,
      zIndex: 999,
      fontSize: "14px",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "#ffffff",
      borderBottom: "solid 1px #e4e4e4",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      fontSize: "14px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    placeholder: () => ({
      color: "#9d9fa6",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex",
    }),
  };

  const interviewId = props.match.params.interviewId;
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEditStatus("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  let resumkey;
  const getInterviewRoundsData = () => {
    axios
      .get(
        `${BootcampBackend}/interview/getCompleteInterViewData/${interviewId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setInterviewData(res.data.result.interviewData);
        resumkey = res.data.result.interviewData?.candidate?.Resume_key;
        setInterviewRoundNo(
          res.data.result.interviewData.interviewRounds[
            res.data.result.interviewData.interviewRounds.length - 1
          ].round
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startInterview = (data) => {
    if (
      moment().isAfter(data.interviewStartTime) &&
      moment().isBefore(data.interviewEndTime)
    ) {
      history.push(`/interview-paper/${data?.interviewToken}`);
    } else {
      return;
    }
  };

  const onEditStatusChange = (roundData) => {
    if (isEditStatus === roundData._id) {
      setIsEditStatus("");
    } else {
      setIsEditStatus(roundData._id);
    }
  };

  const goToProfile = (e) => {
    window.open(
      `${Machinehack_Url}/user/${interviewData.candidate._id}`,
      "_blank"
    );
  };
  function ValidateEmail(userEmail) {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (userEmail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
  const handlenextInterviewerChange = (value) => {
    setSelectedCandidate(value);
    setCandidateEmail(value.value);
  };
  const copyLink = (data) => {
    setIsCopied(data);
    navigator.clipboard.writeText(instantLink);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };
  // Handle Date change END

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
  };

  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };

  const handleClose = () => {
    setShow(false);
    setMailList("email");
    setModalInterview(0);
    setInterviewTitleError(false);
    setCandidateEmailError("");
    setIsCopied("");
  };
  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };
  const onCandidateEmailChange = (e) => {
    setCandidateEmail(e.target.value);
    setCandidateEmailError("");
  };

  const createScheduledInterview = () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();

    // Generate Interview Link & then call the API
    // Call the API

    const options = {
      interviewStartTime: interviewStartTime,
      interviewEndTime: interviewEndTime,
      currentRound: interviewRoundNo,
      interviewlink: instantLink,
      interviewToken: token,
    };

    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    axios
      .post(
        `${BootcampBackend}/interview/createNewRound/` + editableItem._id,
        options,
        headers
      )
      .then((result) => {
        handleClose();
        history.push("/interviews");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onEditCandidateInterviewStatus = (data, statusType) => {
    axios
      .put(
        `${BootcampBackend}/interview/updateInterviewCandidateStatus/${interviewId}/${data._id}`,
        {
          candidateInterviewStatus: statusType,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setIsEditStatus("");
        setInterviewData((prevState) => ({
          ...prevState,
          interviewRounds: prevState.interviewRounds.filter((x) => {
            if (x._id === data._id) {
              x.candidateInterviewStatus = statusType;
            }
            return prevState.interviewRounds;
          }),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onEditchange = (e) => {
    setNotes(e.target.value);
  };
  const editFeedback = (round) => {
    const notesData = {};
    notesData.interviewFeedBack = Notes;
    notesData.languageProficiency = {
      notes: "",
      ratings: round?.interviewerFeedback?.languageProficiency?.ratings,
    };
    notesData.codeQuality = {
      notes: "",
      ratings: round?.interviewerFeedback?.codeQuality?.ratings,
    };
    notesData.overAll = {
      notes: "",
      ratings: round?.interviewerFeedback?.overAll?.ratings,
    };
    const paramsData = {};
    paramsData.interviewerFeedback = notesData;
    paramsData.interviewVideoLink = "https://s3-link.com";
    paramsData.sessionEnded = true;

    axios
      .put(
        `${BootcampBackend}/interview/updateInterviewFeedBack/${round.interviewToken}`,
        paramsData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((response) => {
        setInterviewData((prev) => ({
          ...prev,
          interviewRounds: prev.interviewRounds.map((x) => {
            if (x._id === round._id) {
              x.interviewerFeedback.interviewFeedBack = Notes;
            }
            return x;
          }),
        }));
        setIsEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createAnotherRound = (e, interviews) => {
    e.stopPropagation();
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    // Generate the scheduled link
    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.url);
          setModalInterview("schedule");
          setInterviewToken(result.data.result.token);
          setShow(true);
          setIsEditable(true);
          setIsEditableItem(interviews);
          // setInstantLink(interviews.interviewRounds.interviewLink);

          setInterviewTitle(interviews.interviewTitle);
          setSelectedDate(
            moment(interviews.interviewRounds.interviewStartTime).format(
              "ddd, ll"
            )
          );
          setStartTime(moment(interviews.interviewRounds.interviewStartTime));
          setEndTime(moment(interviews.interviewRounds.interviewEndTime));
          setCandidateEmail(interviews.candidateEmail);
          setIsEditable(false);
          setCreateRound(true);
          console.log();
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getInterviewRoundsData();
  }, []);
  const handleShow = () => {
    setShowCreateModal(true);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid body-margin-top interviewProcess px-0">
        <div className="interview">
          <Navbar />
          {interviewData && (
            <div
              className={`interviewProcessBackground ${
                isSidebarCollapsed ? "collapsed-content-page" : "content-page"
              }`}
              style={{ height: "100%" }}
            >
              <div className="py-3 px-5 interviewProcessHeading d-flex justify-content-between">
                {interviewData?.interviewRounds?.candidateInterviewStatus ===
                "selected" ? (
                  <p></p>
                ) : (
                  <button
                    className="createInterviewBtn"
                    onClick={(e) => {
                      createAnotherRound(e, interviewData);
                    }}
                  >
                    <img
                      className="ms-2 me-3 img-fluid"
                      loading="lazy"
                      alt="CreateInterview"
                      src={CreateInterview}
                    />
                    Schedule Next Round
                  </button>
                )}

                <p className="reportTimelineHeader">
                  {interviewData.candidateName &&
                    interviewData.candidateName + " " + "-" + " "}

                  {interviewData.interviewTitle}
                </p>
                <button
                  className="goToScheduleButton"
                  onClick={() => history.push(`/interviews`)}
                >
                  Go to schedule
                  <img
                    className="ms-2 img-fluid"
                    alt="rightWhiteArrowIcon"
                    loading="lazy"
                    src={rightWhiteArrowIcon}
                  />
                </button>
              </div>

              <div className="timeLiineReportInterview">
                <div className="col-lg-8 mx-auto mt-4">
                  <ul className="hackathonRulesList mb-5">
                    {interviewData &&
                      interviewData.interviewRounds &&
                      interviewData.interviewRounds.map((round) => (
                        <li key={round._id}>
                          <div
                            className={`interviewList pt-3 mb-3 ${
                              round?.candidateInterviewStatus === "selected"
                                ? "selectedInterview"
                                : round?.candidateInterviewStatus ===
                                    "rejected" && "rejectedInterview"
                            }${
                              round?.interviewStatus === "pending"
                                ? "interviewScheduled"
                                : ""
                            }`}
                          >
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center mb-3">
                                <p className="jobDesc mb-0 ms-4">
                                  Interview With {round?.interviewer?.firstName}{" "}
                                  {round?.interviewer?.lastName} -{" "}
                                  {interviewData.interviewTitle}
                                </p>
                                {round?.interviewerFeedback?.overAll && (
                                  <div className="starRating ms-4 px-2">
                                    <ReactStars
                                      count={5}
                                      value={
                                        round?.interviewerFeedback?.overAll
                                          .ratings
                                      }
                                      size={16}
                                      activeColor="#FF9F4B"
                                      edit={false}
                                      color="#C4C4C4"
                                      className=""
                                    />
                                  </div>
                                )}
                              </div>

                              <div className=" candidateInterviewStatus">
                                {/* Passed Section */}
                                {round?.candidateInterviewStatus === "passed" ||
                                round?.candidateInterviewStatus ===
                                  "selected" ? (
                                  <>
                                    <img
                                      src={passedIcon}
                                      alt="applied"
                                      loading="lazy"
                                      className="img-fluid me-2"
                                      style={{ width: "18px" }}
                                    />
                                    <span className="passedText">
                                      {round?.candidateInterviewStatus ===
                                      "passed"
                                        ? "Passed"
                                        : round?.candidateInterviewStatus ===
                                            "selected" && "Selected"}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={rejectedIcon}
                                      alt="applied"
                                      loading="lazy"
                                      className="img-fluid me-2"
                                      style={{ width: "18px" }}
                                    />
                                    <span className="passedText">Rejected</span>
                                  </>
                                )}

                                <img
                                  src={moreOptionIcon}
                                  alt="applied"
                                  loading="lazy"
                                  className="img-fluid px-3 position-relative"
                                  onClick={() => onEditStatusChange(round)}
                                  style={{ cursor: "pointer" }}
                                />
                                {isEditStatus === round._id && (
                                  <div
                                    className="editStatushld"
                                    ref={wrapperRef}
                                  >
                                    <p
                                      className="p-3 mb-0"
                                      onClick={() =>
                                        onEditCandidateInterviewStatus(
                                          round,
                                          "passed"
                                        )
                                      }
                                    >
                                      Pass
                                    </p>
                                    <p
                                      className="p-3 mb-0"
                                      onClick={() =>
                                        onEditCandidateInterviewStatus(
                                          round,
                                          "selected"
                                        )
                                      }
                                    >
                                      Select
                                    </p>
                                    <p
                                      className="p-3 mb-0"
                                      onClick={() =>
                                        onEditCandidateInterviewStatus(
                                          round,
                                          "rejected"
                                        )
                                      }
                                    >
                                      Reject
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="">
                              <ul className="interviewDetailsList my-2 ms-4 me-2 ps-0 align-items-center">
                                <li>
                                  {" "}
                                  <img
                                    src={calenderIcon}
                                    alt="calender"
                                    className="img-fluid me-2 calenderIcon"
                                    loading="lazy"
                                  />
                                  {moment(round?.interviewStartTime).format(
                                    "MMMM Do YYYY"
                                  )}
                                </li>
                                <li className="interviewDateDot"></li>
                                <li>
                                  {moment(round?.interviewStartTime).format(
                                    "LT"
                                  )}{" "}
                                  -{" "}
                                  {moment(round?.interviewEndTime).format("LT")}
                                </li>
                              </ul>

                              <ul className="interviewDetailsList mt-2 ms-4 me-2 mb-3 ps-0 align-items-center">
                                <li className="interviewerName">
                                  <img
                                    src={personIcon}
                                    alt="calender"
                                    className="img-fluid me-2 calenderIcon"
                                    loading="lazy"
                                  />
                                  {round?.interviewer?.firstName}{" "}
                                  {round?.interviewer?.lastName}
                                </li>
                                <li className="interviewDateDot"></li>
                                <li>{round?.interviewer?.emailId}</li>
                              </ul>
                              {round?.interviewerFeedback && (
                                <div className="feedbackDesc py-3 px-4">
                                  <p className="feedbackhead">Feedback</p>
                                  <span className="editIconBorder">
                                    <img
                                      src={EditJobIcon}
                                      alt="edit"
                                      className="img-fluid feedbackeditIcon"
                                      loading="lazy"
                                      onClick={() => {
                                        setIsEdit(round._id);
                                        setNotes(
                                          round?.interviewerFeedback
                                            ?.interviewFeedBack
                                        );
                                      }}
                                    />
                                  </span>
                                  {round?.interviewStatus === "completed" && (
                                    <button
                                      className={`viewProfilebtn me-2 ${
                                        round?.candidateInterviewStatus ===
                                        "selected"
                                          ? "greenReportBtn"
                                          : round?.candidateInterviewStatus ===
                                            "rejected"
                                          ? "redReportBtn"
                                          : ""
                                      }`}
                                      style={{
                                        float: "right",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          `/interviews/interview-report/${interviewData?._id}/${round?._id}`
                                        )
                                      }
                                    >
                                      View Report
                                      <img
                                        src={ViewReport}
                                        alt="applied"
                                        className="img-fluid ms-2 viewReportImgIcon"
                                        loading="lazy"
                                      />
                                    </button>
                                  )}
                                  {round?.interviewStatus === "pending" && (
                                    <button
                                      className="viewProfilebtn me-2"
                                      style={{
                                        float: "right",
                                        background: `${
                                          moment().isAfter(
                                            round.interviewStartTime
                                          ) &&
                                          moment().isBefore(
                                            round.interviewEndTime
                                          )
                                            ? "#fff"
                                            : "#E8E8EA"
                                        }`,
                                        opacity: `${
                                          moment().isAfter(
                                            round.interviewStartTime
                                          ) &&
                                          moment().isBefore(
                                            round.interviewEndTime
                                          )
                                            ? ""
                                            : "0.3"
                                        }`,
                                      }}
                                      onClick={() => startInterview(round)}
                                    >
                                      Start Interview
                                      <img
                                        src={AppliedIcon}
                                        alt="applied"
                                        className="img-fluid ms-2"
                                        loading="lazy"
                                      />
                                    </button>
                                  )}
                                  {isEdit === round._id ? (
                                    <div className="position-relative">
                                      <textarea
                                        type="text"
                                        placeholder="Your Feedback here..."
                                        className="form-cntrol w-100 pt-1 pb-4 px-2 interviewPtextArea mt-2"
                                        rows="6"
                                        value={Notes}
                                        onChange={onEditchange}
                                      ></textarea>
                                      <button
                                        className="IPsubmitbtn"
                                        type="button"
                                        onClick={() => {
                                          editFeedback(round);
                                          setNotes("");
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  ) : (
                                    <p className="feedbacktext">
                                      {
                                        round?.interviewerFeedback
                                          ?.interviewFeedBack
                                      }
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          {round.candidateInterviewStatus === "selected" ||
                          round.candidateInterviewStatus === "passed" ? (
                            <img
                              src={selectedIcon}
                              className="li-dot img-fluid"
                              loading="lazy"
                              alt="selectedIcon"
                            />
                          ) : (
                            <img
                              src={rejectedredIcon}
                              className="li-dot img-fluid"
                              loading="lazy"
                              alt="rejectedredIcon"
                            />
                          )}

                          <div className="left-line"></div>
                        </li>
                      ))}

                    {/* <li>
                  <div className="overview">
                    <p>Start Date: 00</p>
                  </div>
                  <div className="li-dot"></div>
                </li> */}
                  </ul>
                  <div className="d-flex justify-content-center">
                    {interviewData.interviewRounds?.slice(-1)[0]
                      ?.candidateInterviewStatus === "passed" && (
                      <button
                        className="createInterviewBtn passedNextRound"
                        onClick={(e) => {
                          createAnotherRound(e, interviewData);
                        }}
                      >
                        <img
                          className="ms-2 me-3 img-fluid"
                          loading="lazy"
                          alt="CreateInterview"
                          src={CreateInterview}
                        />
                        Schedule Next Round
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <h5>
              {/* {createRound == true ? (
                "Create another Round"
              ) : (
                <>{isEditable ? "Edit an Interview" : "Create an Interview"}</>
              )} */}
            </h5>
            <img
              src={Cross}
              onClick={handleClose}
              className="img-fluid"
              style={{ width: "15px", height: "15px" }}
              alt="logo"
              loading="lazy"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalInterview === "schedule" && (
            <div className="text-center schedule">
              <h4 className="modalHeading">Schedule an Interview</h4>
              <div className="copyLinkBox">
                <img
                  src={interviewLink}
                  className="linkIconModal img-fluid"
                  alt="logo"
                  loading="lazy"
                />
                <input
                  className="copyLink"
                  value={instantLink}
                  readOnly={isEditable || createRound}
                  disabled
                />
                <button
                  className={`copyBtn text-center ${
                    isCopied !== "schedule" ? "" : "greenBackground"
                  }`}
                  onClick={() => copyLink("schedule")}
                >
                  <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
                </button>
              </div>

              <div className="mt-3 schedulingDiv">
                <h6>Interview Title</h6>
                <input
                  className={`scheduleInputs p-2  ${
                    createRound ? "disabled-class" : ""
                  }`}
                  placeholder="Enter Interview Title"
                  onChange={oninterviewTitleChange}
                  value={interviewTitle}
                  readOnly={createRound}
                />
                {interviewTitleError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    This Field is required
                  </p>
                )}
                {/* Date Picker Here START */}

                <h6 className="mt-4 mb-2">Select Date</h6>
                <div className="datepicker">
                  <img
                    src={DateIcon}
                    className="img-fluid dateIconImg "
                    alt="logo"
                    loading="lazy"
                  />

                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    isValidDate={valid}
                    dateFormat="DD MMM YYYY"
                    value={selectedDate}
                  />
                  <img
                    src={dropdownArrow}
                    className="img-fluid dropdownArrowImg"
                    alt="logo"
                    loading="lazy"
                  />
                  {/* Date Picker Here End */}
                </div>

                {/* <ComboDatePicker/> */}

                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">Start time</label>
                    <div className="timePicker">
                      <img
                        src={Clock}
                        className="interviewClock img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                      <TimePicker
                        value={startTime}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleTimeChange}
                        // onOpen={handleTimeClick}
                        defaultValue={moment()}
                        clearText=""
                        clearIcon=""
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">End time</label>
                    <div className="timePicker">
                      <img src={Clock} className="interviewClock" />
                      <TimePicker
                        value={moment(startTime).add("30", "minutes")}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleEndTimeChange}
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>

                {!createRound && (
                  <div className="mt-5 mb-2 d-flex emailSelection">
                    <div className="d-flex w-50">
                      <input
                        id="email"
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("email");
                        }}
                        checked={mailList}
                      />
                      Email ID
                    </div>
                    <div className="d-flex w-50">
                      <input
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("MHList");
                        }}
                      />
                      From MH List
                    </div>
                  </div>
                )}
                {(mailList === "email" || createRound) && (
                  <>
                    <div>
                      <h6 className={`mb-1 ${createRound ? "mt-4" : ""}`}>
                        Candidate Email
                      </h6>
                      <input
                        className={`scheduleInputs mt-2 p-2  ${
                          createRound ? "disabled-class" : ""
                        }`}
                        onChange={onCandidateEmailChange}
                        value={candidateEmail}
                        placeholder="Enter Candidate Email"
                        readOnly={createRound}
                      />
                      <img src={correctIcon} className="verified-icon" />
                    </div>
                    {candidateEmailError !== "" && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {candidateEmailError}
                      </p>
                    )}
                  </>
                )}
                {mailList === "MHList" && (
                  <div style={{ color: "#e4e4e4" }}>
                    <h6>Candidate Name</h6>
                    <div className="mt-2">
                      <Select
                        styles={customStyles}
                        value={selectedCandidate}
                        onChange={handlenextInterviewerChange}
                        options={Mhlist}
                        placeholder="Search or choose from List"
                      />
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="px-3">
                {interviewTitle === "" || candidateEmail === "" ? (
                  <button className="my-4 scheduleInterviewlg greyScheduleBtn ">
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                ) : (
                  <button
                    className="my-4 scheduleInterviewlg"
                    // onClick={() => setModalInterview("")}
                    onClick={() => createScheduledInterview()}
                  >
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
