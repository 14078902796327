import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { LoadingShape } from "react-rainbow-components";
import { useDispatch } from "react-redux";
import { lmsService } from "services/lms.service";
import { setTotalParticipants } from "store/reducers/lmsSlice";

const labels = ["Participants", "Active", "Appeared", "Completed"];
const gradientConfigs = [
  { colorStop0: "rgba(0, 227, 126, 0.38)", colorStop1: "#00E37E" },
  {
    colorStop0: "rgba(128, 97, 219, 0.94)",
    colorStop1: "rgba(128, 97, 219, 0.38)",
  },
  { colorStop0: "rgba(1, 113, 220, 0.38)", colorStop1: "#0173DF" },
  { colorStop0: "rgba(0, 221, 213, 0.38)", colorStop1: "#00DDD5" },
];
const options = {
  elements: {
    bar: {
      borderRadius: 4,
    },
  },
  responsive: true,
  plugins: {
    legend: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        fontSize: 10,
        color: "#636466",
      },
    },
    y: {
      grid: {
        borderWidth: 0.5,
        borderDash: [2, 3],
        drawTicks: false,
      },
      ticks: {
        callback: (value, index, values) => (index % 2 === 0 ? value : ""), // Display every alternate value
        fontSize: 10,
        color: "#636466",
        padding: 10,
      },
    },
  },
  maintainAspectRatio: false,
};

const ParticipantsStats = ({ studyplanSlug }) => {
  const [participantsStats, setParticipantsStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const generateGradients = (context) => {
    const ctx = context.chart.ctx;
    const gradients = gradientConfigs.map((config) => {
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(1, config.colorStop1);
      gradient.addColorStop(0, config.colorStop0);
      return gradient;
    });
    return gradients;
  };

  const getData = (participantsStats) => {
    const data = {
      labels,
      datasets: [
        {
          label: "",
          data: [
            participantsStats?.totalParticipants,
            participantsStats?.activeParticipantsStats?.totalActiveParticipants,
            participantsStats?.totalAppearedParticipants,
            participantsStats?.totalCompletedParticipants,
          ],
          backgroundColor: generateGradients,
          barPercentage: 0.35,
        },
      ],
    };
    return data;
  };

  const fetchParticipantsStats = async () => {
    try {
      const participantsStatsResponse = await lmsService.fetchParticipantsStats(
        studyplanSlug
      );
      setParticipantsStats(participantsStatsResponse);
      dispatch(
        setTotalParticipants(participantsStatsResponse?.totalParticipants)
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchParticipantsStats();
  }, []);

  return (
    <div className="col-lg-6">
      <div className="overview-card-hld">
        {isLoading ? (
          <LoadingShape className="mb-2 w-25" />
        ) : (
          <h5>Participants Stats</h5>
        )}
        <div className="row mt-4">
          <div className="col-7 ps-0 bar-chart-hld">
            {isLoading ? (
              <>
                <div className="d-flex align-items-baseline">
                  <LoadingShape
                    className="mx-4 bar-graph-loader"
                    shape="rect"
                    style={{
                      height: "130px",
                      width: "20px",
                    }}
                  />
                  <LoadingShape
                    className="mx-4 bar-graph-loader"
                    shape="rect"
                    style={{
                      height: "100px",
                      width: "20px",
                    }}
                  />
                  <LoadingShape
                    className="mx-4 bar-graph-loader"
                    shape="rect"
                    style={{
                      height: "75px",
                      width: "20px",
                    }}
                  />
                  <LoadingShape
                    className="mx-4 bar-graph-loader"
                    shape="rect"
                    style={{
                      height: "50px",
                      width: "20px",
                    }}
                  />
                </div>
              </>
            ) : (
              <Bar options={options} data={getData(participantsStats)} />
            )}
          </div>
          <div className="col-5 row pt-2">
            {isLoading ? (
              <>
                {Array.from({ length: 4 }).map((_, i) => (
                  <div key={i} className="col-6">
                    <LoadingShape
                      className="mb-2 bar-graph-loader"
                      style={{ width: "10px", height: "10px" }}
                      shape="square"
                    />
                    <LoadingShape className="w-25 mb-2 h-auto" />
                    <LoadingShape className="w-50 mb-2 h-auto" />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="col-lg-8">
                  <div className="doughnut-label-color-representation green-color mb-3" />
                  <p className="studyplan-title mb-1">
                    {participantsStats?.totalParticipants}
                  </p>
                  <p className="doughnut-label">Total Participants</p>
                </div>
                <div className="col-lg-4">
                  <div className="doughnut-label-color-representation blue-color mb-3" />
                  <p className="studyplan-title mb-1">
                    {participantsStats?.totalAppearedParticipants}
                  </p>
                  <p className="doughnut-label">Appeared</p>
                </div>
                <div className="col-lg-8">
                  <div className="doughnut-label-color-representation violet-color mb-3" />
                  <p className="studyplan-title mb-1">
                    {
                      participantsStats?.activeParticipantsStats
                        ?.totalActiveParticipants
                    }
                    {participantsStats?.activeParticipantsStats
                      ?.dayChangePercentage ? (
                      <span
                        className={`course-studyplan-dayChange ms-2 ${
                          participantsStats?.activeParticipantsStats
                            ?.dayChangePercentage > 0
                            ? "studyplan-positive-dayChange"
                            : "studyplan-negative-dayChange"
                        }`}
                      >
                        {participantsStats?.activeParticipantsStats
                          ?.dayChangePercentage > 0
                          ? "+"
                          : ""}
                        {participantsStats?.activeParticipantsStats
                          ?.dayChangePercentage %
                          2 ===
                        0
                          ? participantsStats?.activeParticipantsStats
                              ?.dayChangePercentage
                          : participantsStats?.activeParticipantsStats?.dayChangePercentage?.toFixed(
                              1
                            )}
                        %
                      </span>
                    ) : (
                      <></>
                    )}
                  </p>
                  <p className="doughnut-label">People Active</p>
                </div>
                <div className="col-lg-4">
                  <div className="doughnut-label-color-representation skyblue-color mb-3" />
                  <p className="studyplan-title mb-1">
                    {participantsStats?.totalCompletedParticipants}
                  </p>
                  <p className="doughnut-label">Completed</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsStats;
