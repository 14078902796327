import axios from "axios";
import { BootcampBackend } from "backend";
import moment from "moment";
import React, { useEffect, useState } from "react";

const headers = ["NAME", "STATUS", "TIMELINE", "SCORE", "Action"];
export default function ParticipantsTable({ assessmentId }) {
  const [participantsList, setParticipantsList] = useState([]);
  const [pagination, setPagination] = useState({ limit: 5, current_page: 1 });
  const [participantTotal, setParticipantTotal] = useState(0);
  const getToTalQues = (participant) => {
    let totalQues = 0;
    let noOfCorrectAns = 0;
    participant?.EvaluationData?.filter((section) => {
      totalQues += section?.totalQuestions;
      noOfCorrectAns += section?.correctlyAnswered;
    });
    return { totalQues: totalQues, noOfCorrectAns: noOfCorrectAns };
  };
  const getScoreinPer = (participant) => {
    return (
      (
        (getToTalQues(participant)?.noOfCorrectAns /
          getToTalQues(participant)?.totalQues) *
          100 || 0
      )?.toFixed(2) + "%"
    );
  };
  const fetchParticipantsData = async () => {
    const paramData = {
      limit: pagination.limit,
      current_page: pagination.current_page,
    };
    const participantslistResponse = await axios.get(
      `${BootcampBackend}/enterpriseAssessment/getAllParticipants/${assessmentId}`,
      {
        params: paramData,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      }
    );
    if (participantslistResponse) {
      setParticipantsList(participantslistResponse.data?.result?.data);
      setParticipantTotal(
        participantslistResponse.data?.result?.totalParticipants
      );
    }
  };
  useEffect(() => {
    fetchParticipantsData();
  }, []);
  return (
    <div className="dashboardParticipantTable">
      {participantsList.length>0&&<table>
        <thead>
          <tr>
            {headers.map((headerItem, i) => (
              <th key={`headerItem${i}Th`}>{headerItem}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {participantsList.map((participant, index) => (
            <tr key={`headerRow${index}`}>
              <td id={`headerColumnTD`}>
                {" "}
                {participant?.userId?.Name
                  ? participant?.userId?.Name
                  : participant?.email.split("@")[0]}
              </td>
              <td id={`headerColumnTD`}>{participant?.assessmentStatus}</td>
              <td id={`headerColumnTD`}>
                {participant?.completedAt
                  ? "Completed"
                  : participant?.startedAt
                  ? "Started"
                  : participant?.invitedAt
                  ? "Invited at"
                  : "Created at"}{" "}
                {participant?.completedAt
                  ? moment(participant?.completedAt).fromNow()
                  : participant?.startedAt
                  ? moment(participant?.startedAt).fromNow()
                  : participant?.invitedAt
                  ? moment(participant?.invitedAt).fromNow()
                  : moment(participant?.createdAt).fromNow()}
              </td>
              <td id={`headerColumnTD`}>
                {getScoreinPer(participant) !== "NaN%"
                  ? getScoreinPer(participant)
                  : "-"}
              </td>
              <td id={`headerColumnTD`}>
                {" "}
                <div className="actionTab">-</div>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>}
    </div>
  );
}
