//CSS
import "../LoginPage/Login.css";
//LOGO and ICONS
import loginGif from "../../../assets/For-Side.gif";
import checked from "../../../assets/Icons/Settings/checked.svg";
import PasswordSuccesIcon from "../../../assets/Icons/Group784.svg";
import activePassword from "../../../assets/Icons/activePassword.svg";
import hidePassword from "../../../assets/Icons/hidePassword.svg";
import showPassword from "../../../assets/Icons/showPassword.svg";
import password from "../../../assets/Icons/password.svg";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import LoginSignUpHeader from "../NewLogin/LoginSignUpHeader";
export default function SetupPassword(props) {
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const history = useHistory();
  //
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    validation: false,
    passwordMatch: false,
  });

  const [internalServerError, setInternalServerError] = useState("");

  function redirectLogin() {
    window.location.reload();
  }

  function handleChange(event) {
    setError({
      validation: false,
      passwordMatch: false,
    });

    const { name, value } = event.target;
    setPasswordData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function togglePassword() {
    setViewPassword((prevValue) => {
      return !prevValue;
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const regPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

    if (!regPassword.test(passwordData.password)) {
      setError({
        validation: true,
        passwordMatch: false,
      });
      return;
    }
    if (passwordData.password !== passwordData.confirmPassword) {
      setError({
        validation: false,
        passwordMatch: true,
      });
      return;
    }

    axios
      .put(`${BootcampBackend}/iamUser/setPassword`, {
        _id: props.match.params.userId,
        password: passwordData.password,
      })
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          localStorage.setItem("firstlogin", true)
          setPasswordSuccess(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          // if(err.response.data.status.code === 404){

          // }

          // if(err.response && err.response.data.status.code === 403){
          // }

          if (err.response.data.status.code === 500) {
            setInternalServerError(
              "Internal server error.Please try after sometime."
            );
          }
        } else {
          setInternalServerError(
            "Internal server error.Please try after sometime."
          );
        }
      });
  }

  const loginNow = (e) => {
    history.push("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      if (localStorage.getItem("prevPath"))
        history.push(localStorage.getItem("prevPath"));

      history.replace("/dashboard");
    }
  }, [props.match.params.userId]);

  return (
    <div className="container-fluid loginSignUpHld">
      <LoginSignUpHeader />
      <div className="row loginSection">
        <div className="col-6 p-5 loginLeftContainer">
          <div className="col-8 pt-3 ps-2 m-auto">
            <h2 className="text-left loginHeadText mb-2 mt-2">
              Hello Recruiter!
            </h2>
            <p className="text-left loginText fontGray  pb-5">
              Login to MachineHack and start hiring the best talents.
            </p>
            <div className="mt-4 d-flex listContent">
              <img className="img-fluid" src={checked} />
              <p className="ms-3">Platform with 1000+ advanced assessment</p>
            </div>
            <div className="mt-4 d-flex listContent">
              <img className="img-fluid" src={checked} />
              <p className="ms-3">
                Host online hackathon to reach 100k+ developers
              </p>
            </div>
            <div className="mt-4 d-flex listContent">
              <img className="img-fluid" src={checked} />
              <p className="ms-3">Advanced live coding interview </p>
            </div>
            <div className="mt-4 mb-5 d-flex listContent">
              <img className="img-fluid" src={checked} />
              <p className="ms-3">Intensive applicant tracking</p>
            </div>
          </div>
          <img
            className="gifContainer mt-2 me-5"
            src={loginGif}
            height="432"
            width="432"
          />
        </div>
        <div className="col-6 p-0 pt-5 d-flex flex-column justify-content-between h-100">
          <div className="mx-auto pt-3 col-8">
            {!passwordSuccess && (
              <div className="col mt-3 text-center">
                <h3 className="formHeading"> Setup Password Now</h3>
                {/* <p className="loginLinkText">
                  Please create a strong and secure password
                </p> */}
                <p id="passwordHelp" className="fontGray mt-3  px-4">
                  Password must have minimum 8 and maximum 30 characters,
                  atleast a smaller case alphabet, an upper case alphabet, a
                  number and a special character.
                </p>
              </div>
            )}
            {!passwordSuccess && (
              <>
                <form onSubmit={handleSubmit} className="p-4 px-5 mt-3">
                  <label className="mb-2" htmlFor="password">
                    Password
                  </label>
                  <div
                    className={`loginInputHld  ${
                      error.validation ? "mb-0 errorInputField" : "mb-4"
                    }`}
                  >
                    <img
                      className="img-fluid inputLogo"
                      src={passwordData.password ? activePassword : password}
                    />
                    <input
                      type={viewPassword ? "text" : "password"}
                      name="password"
                      value={passwordData.password}
                      onChange={handleChange}
                    />

                    <img
                      className={`img-fluid ms-2 ${
                        viewPassword ? `viewEye` : ``
                      }`}
                      src={viewPassword ? showPassword : hidePassword}
                      onClick={togglePassword}
                    />
                  </div>
                  {error.validation && (
                    <p className="errorText my-1">Incorrect format</p>
                  )}

                  <label className="mb-2" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <div
                    className={`loginInputHld  ${
                      error.passwordMatch ? "mb-0 errorInputField" : "mb-4"
                    }`}
                  >
                    <img
                      className="img-fluid inputLogo"
                      src={
                        passwordData.confirmPassword ? activePassword : password
                      }
                    />
                    <input
                      type={viewPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={passwordData.confirmPassword}
                      onChange={handleChange}
                      placeholder=""
                    />
                    <img
                      className={`img-fluid ms-2 ${
                        viewPassword ? `viewEye` : ``
                      }`}
                      src={viewPassword ? showPassword : hidePassword}
                      onClick={togglePassword}
                    />
                  </div>
                  {error.passwordMatch && (
                    <p className="errorText my-1">Passwords do not match</p>
                  )}
                  <div>
                    {internalServerError !== "" && (
                      <>
                        <p className="errorText mt-3">{internalServerError}</p>
                      </>
                    )}
                    <button className="submitButton mt-5">Submit</button>
                  </div>
                </form>
              </>
            )}

            {passwordSuccess && (
              <div className="passwordSuccess">
                <img src={PasswordSuccesIcon} alt="register sucess" />
                <h3> All Set Now!</h3>
                <p>Please Login to get started</p>
                <button
                  type="submit"
                  className="submitButton mt-4"
                  onClick={loginNow}
                >
                  Login now
                </button>
              </div>
            )}
          </div>
          <p className="mt-5 mb-4 text-center fontGray">
            For any queries &nbsp;
            <a className="fontGray" href="mailto:support@machinehack.com">
              support@machinehack.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
