import React, { useEffect, useRef, useState } from "react";
import "./PerformanceCard.css";
import Dropdown from "../dropDown";

export default function PerformanceCard({
  data,
  selectedQuestion,
  setSelectedQuestion,
  sectionOptions,
  setSelectedSection,
  selectedSection,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectedQuestion(null);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleQuestionHover = (option) => {
    setSelectedQuestion(option);
  };
  const isAnsweredCorrectly = (optionArray) => {
    return optionArray.options.some(
      (option) => option.isSelected && option.is_correct
    );
  };
  return (
    <>
      <div className="performanceCardHld position-relative mt-4">
        <div className="d-flex performanceCardTab justify-content-between align-items-center">
          <h1 className="performanceCardTabText">Overall Performance</h1>
          <Dropdown
            dropDownClass={"px-3 sectionSelection cursor-pointer"}
            dropDownOptions={sectionOptions}
            ondropdownItemClick={(value) => {
              setSelectedSection(value);
            }}
            selectedValue={selectedSection?.sectionTitle}
            value="sectionTitle"
          />
        </div>
        <div
          className={`${
            selectedQuestion ? `isHovered` : ``
          } performanceOverview`}
        >
          {data.mcqWithOptions &&
            data.mcqWithOptions.map((option, index) => (
              <div
                key={`question${index}`}
                style={
                  option?._id === selectedQuestion?._id
                    ? { background: "#45A967" }
                    : {}
                }
                className={`${
                  option.status !== "answered"
                    ? `unattemptedAnswer`
                    : !isAnsweredCorrectly(option)
                    ? `wrongAnswer`
                    : ``
                } questionTab cursor-pointer`}
                onClick={() => handleQuestionHover(option)}
                onMouseEnter={() => handleQuestionHover(option)}
              >
                <span>{index + 1}</span>
              </div>
            ))}
        </div>
        {selectedQuestion !== null && (
          <div ref={wrapperRef} className="solutionOverview p-4">
            <h3 className="questionTitle mb-5 mt-1">
              {selectedQuestion.title}
            </h3>
            {selectedQuestion.options.map((element, index) => (
              <div
                key={`option${index}`}
                className={`${
                  element.isSelected ? `selectedQuestionOption` : ``
                } position-relative questionOption d-flex align-items-center py-2 px-3`}
              >
                {console.log(element)}
                {element.isSelected && (
                  <span
                    className="selctionTab"
                    style={
                      element.is_correct
                        ? { color: "#00BD3F", backgroundColor: "#E5F8EC" }
                        : { color: "#F44D5E", backgroundColor: "#feedef" }
                    }
                  >
                    Candidate Selection
                  </span>
                )}
                <span
                  className={`${
                    element.isSelected && !element.is_correct
                      ? `incorrectOuterCircle`
                      : ``
                  } ${element.is_correct ? `correctOuterCircle` : ``} ${
                    element.isSelected ? `selected` : ``
                  } outerCircle me-3`}
                >
                  {(element.isSelected || element.is_correct) && (
                    <span className={`innerCircle`}></span>
                  )}
                </span>{" "}
                {element.option}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
