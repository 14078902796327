import React, { useEffect, useState, useRef } from "react";
import { Form, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import GoBack from "../../../../assets/Icons/Jobs/Arrow1.svg";
import companyLogoToRemove from "../../../../assets/Icons/Jobs/companyLogoToRemove.svg";
import SaveJobsGray from "../../../../assets/Icons/Jobs/SaveJobsGray.svg";
import jobShare from "../../../../assets/Icons/Jobs/jobShare.svg";
import companyBuilding from "../../../../assets/Icons/Jobs/companyBuilding.svg";
import pepoleApplicants from "../../../../assets/Icons/Jobs/pepoleApplicants.svg";
import JobsGlobalLink from "../../../../assets/Icons/Jobs/JobsGlobalLink.svg";
import linkedInLogo from "../../../../assets/Icons/Jobs/linkedInLogo.svg";
import twitterLogo from "../../../../assets/Icons/Jobs/twitterLogo.svg";
import gitLogo from "../../../../assets/Icons/Jobs/gitLogo.svg";
import JobsCompanyLogoRound from "../../../../assets/Icons/Jobs/JobsCompanyLogoRound.svg";
import JobsRedirect from "../../../../assets/Icons/Jobs/JobsRedirect.svg";
import closeModelBtn from "../../../../assets/Icons/Jobs/closeRed.svg";

import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";

import NewFormComponent from "../components/FormComponent/NewForm";
import "./AddJob.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import cross from "../../../../assets/Icons/cross.svg";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import axios from "axios";
import { API, BootcampBackend, Machinehack_Url } from "../../../../backend";
import { data } from "dom7";
import { useSidebar } from "helper/SideBarContext";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#3c3e4d",
    padding: 10,
    textCenter: "left",
    zIndex: 999,
    fontSize: "14px",
    backgroundColor: state.isFocused ? "#F2F3F4" : "#ffffff",
  }),
  control: (base, state) => ({
    ...base,
    marginTop: "10px",
    // height: 40 + "px",
    // alignItems: "start",
    backgroundColor: "#ffffff",
    border: "solid 1px #E2E2E9",
    borderRadius: 50,
    paddingRight: "15px",
    paddingLeft: "15px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: (base, state) => ({
    color: "#B1B2B8",
  }),
  indicatorSeparator: () => ({
    display: "none",
    paddingRight: 20,
  }),
  dropdownIndicator: () => ({
    color: "#F44D5E",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
  }),
};
export default function NewAddJob(props) {
  const history = useHistory();
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [showQuestionInput, setShowQuestionInput] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const [formStep, setFormStep] = useState(1);
  const [skillItems, setSkillItems] = useState([]);
  const inputEl = useRef(null);
  const { isSidebarCollapsed } = useSidebar();

  const [formDataId, setFormDataId] = useState("");
  const [companyData, setCompanyData] = useState([]);
  //city search states
  const [citySearchTxt, setCitySearchTxt] = React.useState("");
  const [city, setCity] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const [showCitiesDropdown, setShowCitiesDropdown] = useState(false);

  //city search states
  const [showModal, setShowModal] = useState(false);
  const jobId = useLocation().hash.split("#")[1];
  const [formData, setFormData] = useState({
    jobTitle: "",
    workType: {},
    location: [],
    jobType: {},
    experienceStart: "",
    experienceEnd: "",
    startRange: "",
    endRange: "",
    jobDescription: "",
    skills: [],
    questions: [],
  });
  const [skills, setSkills] = React.useState("");
  const [skillSet, setSkillSet] = useState([]);
  const [skillslist, setSkillslist] = useState([]);
  const [showSkillsDrop, setShowSkillsDrop] = useState(false);
  const [currentSkillsPage, setCurrentSkillsPage] = useState(1);
  //navgation functionality

  function formincrement() {
    if (formStep < 3) {
      setFormStep(formStep + 1);
    }
    if (formStep == 3) {
      setShowModal(true);
    }
  }
  const handleCheckEmptyField = (formData) => {
    for (let key in formData) {
      if (formData[key]) {
        return false;
      }
      return true;
    }
  };
  const getJobDataById = async () => {
    await axios
      .get(`${BootcampBackend}/jobs/getJobFromId/${props.match.params.jobId}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((res) => {
        setFormData({
          jobTitle: res.data.result.data.jobTitle,
          workType: {
            label: res.data.result.data.workType,
            value: res.data.result.data.workType,
          },
          location: res.data.result.data.location,
          jobType: {
            label: res.data.result.data.jobType,
            value: res.data.result.data.jobType,
          },
          experienceStart: res.data.result.data.experienceRequired.start,
          experienceEnd: res.data.result.data.experienceRequired.end,
          startRange: res.data.result.data.AnnualSalaryRange.start,
          endRange: res.data.result.data.AnnualSalaryRange.end,
          jobDescription: res.data.result.data.jobDescription,
          skills: res.data.result.data.skills,
          questions: res.data.result.data.questions,
        });
      });
  };
  //selct styles

  //Select change
  const handleWorkType = (event) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        ["workType"]: { label: event.value, value: event.value },
      };
    });
  };
  const handleEmploymentType = (event) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        ["jobType"]: { label: event.value, value: event.value },
      };
    });
  };

  //form Changes Handler
  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  // cutom questions
  const toggleInput = (e) => {
    e.preventDefault();
    if (showQuestionInput === true) {
      setShowQuestionInput(false);
    } else {
      setShowQuestionInput(true);
    }
  };
  //delete question

  ///tag skills
  const handleDelete = (item) => {
    setFormData((prev) => ({
      ...prev,
      skills: prev.skills.filter((i) => i !== item),
    }));
  };
  // const handleSkillChange = (e) => {
  //   setSkills(e.target.value);
  // };
  const handleKeyDownSkills = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var value = skills.trim();

      if (value) {
        setFormData((prev) => ({
          ...prev,
          skills: [...prev.skills, skills],
        }));
        setSkills("");
      }
    }
  };

  //select Options
  const workTypes = [
    { value: "Hybrid", label: "Hybrid" },
    { value: "Onsite", label: "On-Site" },
    { value: "Remote", label: "Remote" },
  ];

  const employmentTypes = [
    { value: "Permanent", label: "Permanent" },
    { value: "Intern", label: "Intern" },
    { value: "Contract", label: "Contract" },
  ];

  const experienceyears = [
    { value: "1-3years", label: " 1-3 yrs" },
    { value: "3-5years", label: " 3-5 yrs" },
  ];
  //tags add
  const questionset = () => {
    const newObj = {
      id: questions.length + 1,
      questions: "",
    };
    setQuestions((prev) => [...prev, newObj]);
  };
  const handleQuestionChange = (event, index) => {
    let data = [...questions];
    data[index] = event.target.value;
    setQuestions(data);
  };

  //form data handling
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  const createJob = () => {
    const paramData = {
      timeStamp: Date(),
    };
    if (formData.jobTitle !== "") {
      paramData.jobTitle = formData.jobTitle;
    }
    if (formData.workType.value !== "") {
      paramData.workType = formData.workType.value;
    }
    if (formData.location?.length > 0) {
      paramData.location = formData.location;
    }
    if (formData.jobType?.value !== "") {
      paramData.jobType = formData.jobType?.value;
    }
    if (formData.experienceStart !== "") {
      paramData.experienceStart = formData.experienceStart;
    }
    if (formData.experienceEnd !== "") {
      paramData.experienceEnd = formData.experienceEnd;
    }
    axios
      .post(`${BootcampBackend}/jobs/create`, paramData, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((res) => {
        setFormDataId(res.data.result.data._id);
        localStorage.setItem("stepperForm", formStep + 1);
        setFormStep(2);
        history.push(`/jobs/addJob/${res.data.result.data._id}`);
      })
      .catch((err) => {});
  };
  const updateJob = () => {
    const paramData = {
      timeStamp: Date(),
    };
    if (formData.jobTitle !== "") {
      paramData.jobTitle = formData.jobTitle;
    }
    if (formData.workType.value !== "") {
      paramData.workType = formData.workType.value;
    }
    if (formData.location?.length > 0) {
      paramData.location = formData.location;
    }
    if (formData.jobType.value !== "") {
      paramData.jobType = formData.jobType.value;
    }
    if (formData.experienceStart !== "") {
      paramData.experienceStart = formData.experienceStart;
    }
    if (formData.experienceEnd !== "") {
      paramData.experienceEnd = formData.experienceEnd;
    }
    if (formData.startRange !== "") {
      paramData.startRange = formData.startRange;
    }
    if (formData.endRange !== "") {
      paramData.endRange = formData.endRange;
    }
    if (formData.jobDescription !== "") {
      paramData.jobDescription = formData.jobDescription;
    }
    if (formData.skills?.length > 0) {
      paramData.skills = formData.skills;
    }
    if (formStep === 3) {
      paramData.questions = formData.questions;
    }
    axios
      .put(
        `${BootcampBackend}/jobs/update/${props.match.params.jobId}`,
        paramData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setFormData({
          jobTitle: res.data.result.data.jobTitle,
          jobType: {
            label: res.data.result.data.jobType,
            value: res.data.result.data.jobType,
          },
          location: res.data.result.data.location,
          workType: {
            label: res.data.result.data.workType,
            value: res.data.result.data.workType,
          },
          experienceStart: res.data.result.data.experienceRequired.start,
          experienceEnd: res.data.result.data.experienceRequired.end,
          startRange: res.data.result.data.AnnualSalaryRange.start,
          endRange: res.data.result.data.AnnualSalaryRange.end,
          jobDescription: res.data.result.data.jobDescription,
          skills: res.data.result.data.skills,
          questions: res.data.result.data.questions,
        });
        setFormStep(parseInt(localStorage.getItem("stepperForm")) + 1);
        localStorage.setItem("stepperForm", formStep + 1);
        showModal && history.push("/jobs");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //submit form data handling
  const handleSubmit = () => {
    handleCheckEmptyField(formData);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        ["location"]: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      };
    });
    if (formStep == 1) {
      if (jobId) {
        axios
          .put(
            `${BootcampBackend}/jobs/update/${jobId}`,
            {
              jobTitle: formData.jobTitle,
              workType: formData.workType,
              location: formData.location,
              employmentType: formData.jobType.value,
              experienceStart: formData.experienceStart,
              experienceEnd: formData.experienceEnd,
              timeStamp: Date(),
            },
            {
              headers: {
                Authorization: JSON.parse(localStorage.getItem("jwt")).token,
              },
            }
          )
          .then((res) => {
            setFormDataId(res.data.result.data._id);
            formincrement();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(
            `${BootcampBackend}/jobs/create`,
            {
              jobTitle: formData.jobTitle,
              workType: formData.workType,
              location: formData.location,
              employmentType: formData.jobType.value,
              experienceStart: formData.experienceStart,
              experienceEnd: formData.experienceEnd,
              timeStamp: Date(),
            },
            {
              headers: {
                Authorization: JSON.parse(localStorage.getItem("jwt")).token,
              },
            }
          )
          .then((res) => {
            setFormDataId(res.data.result.data._id);
            formincrement();
          })
          .catch((err) => {});
      }
    } else if (formStep == 2) {
      axios
        .put(
          `${BootcampBackend}/jobs/update/${formDataId}`,
          {
            startRange: formData.startRange,
            endRange: formData.endRange,
            jobDescription: formData.jobDescription,
            skills: skillItems,
            timeStamp: Date(),
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          formincrement();
        })
        .catch((err) => {});
    } else if (formStep == 3) {
      axios
        .put(
          `${BootcampBackend}/jobs/update/${formDataId}`,
          {
            questions: questions,
            timeStamp: Date(),
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setShowModal(false);
          history.push("/jobs");
        })
        .catch((err) => {});
    }
  };
  //City search Code
  const getAllCities = (searchString) => {
    axios
      .get(
        `${BootcampBackend}/locations/getAllCites${
          searchString ? `?searchString=${searchString}` : ""
        }`
      )
      .then((response) => {
        response.data.result.data?.filter((x) => {
          x.value = x._id;
          x.label = `${x.name}`;
        });
        setListOfCities(response.data.result.data);
      })
      .catch((err) => {
        console.log(err);
        setListOfCities([]);
      });
  };

  const onCityChange = (e) => {
    setShowCitiesDropdown(true);
    setCitySearchTxt(e.target.value);
    if (e.target.value.length > 0) {
      getAllCities(e.target.value);
    } else if (e.target.value.length === 0) {
      getAllCities("");
    }
  };

  const handleCityChange = (e) => {
    setCitySearchTxt(e.target.value);
    setShowCitiesDropdown(true);
    // setCurrentSkillsPage(1);
  };

  const handleKeyDown = (evt, data) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (data?.name) {
        addCities(data?.name);
      } else {
        setCitySearchTxt("");
      }
    }
  };
  const addCities = (citySearchTxt) => {
    if (citySearchTxt) {
      setFormData((prev) => ({
        ...prev,
        location: [...prev.location, citySearchTxt],
      }));
      setCitySearchTxt("");
      setShowCitiesDropdown(false);
    }
  };

  const handleCityDelete = (item) => {
    setFormData((prev) => ({
      ...prev,
      location: prev?.location?.filter((i) => i !== item),
    }));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    // setErrorEmail("");
    var paste = evt.clipboardData.getData("text");
    //eslint-disable-next-line
    var data = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (data) {
      // var toBeAdded = emails.filter((email) => !isInList(email));
      setCity([...city, ...paste]);
    }
  };

  const onClickDivFocusInput = () => {
    inputEl.current.focus();
  };

  const getAllSkills = (page, type) => {
    const paramData = {
      current_page: page,
    };
    skills !== "" && (paramData.searchString = skills);
    axios
      .get(`${BootcampBackend}/skills/getAllSkillsEnterprise`, {
        params: paramData,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((response) => {
        type === "scroll"
          ? setSkillslist((prev) => [...prev, ...response.data.result.data])
          : setSkillslist(response.data.result.data);
        setCurrentSkillsPage(page);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSkillChange = (e) => {
    setSkills(e.target.value);
    setShowSkillsDrop(true);
    setCurrentSkillsPage(1);
  };

  const handleSkillsKeyDown = (evt, data) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (data?.name) {
        addSkills(data?.name);
      } else {
        setSkills("");
      }
    }
  };
  const addSkills = (skills) => {
    if (skills) {
      setFormData((prev) => ({
        ...prev,
        skills: [...prev.skills, skills],
      }));
      setSkills("");
      setShowSkillsDrop(false);
    }
  };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      getAllSkills(currentSkillsPage + 1, "scroll");
    }
  };

  useEffect(() => {
    getAllSkills(currentSkillsPage, "");
  }, [skills]);

  useEffect(() => {
    props.match.params.jobId && getJobDataById();
    localStorage.getItem("stepperForm")
      ? setFormStep(parseInt(localStorage.getItem("stepperForm")))
      : (setFormStep(1), localStorage.setItem("stepperForm", 1));
  }, []);
  useEffect(() => {
    getAllCities(citySearchTxt);
  }, [citySearchTxt]);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCitiesDropdown(false);
          setCitySearchTxt("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCitiesDropdown(false);
          setCitySearchTxt("");
          setShowSkillsDrop(false);
          setSkills("");
          setCurrentSkillsPage(1);
          getAllSkills(currentSkillsPage, "");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div className="">
      <Header
        heading={
          location.pathname.includes("editJob") ? "Edit Job" : "Add New Job"
        }
        route="/jobs"
        showcompanyData={true}
        getCompanyData={setCompanyData}
      />
      <Navbar/>

      <div
        className={` job-Body ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="jobPostingBody">
          <div id="multi-step-form-container">
            {/* <!-- Form Steps / Progress Bar --> */}
            <ul className="form-stepper form-stepper-horizontal text-center mx-auto p-3">
              {/* <!-- Step 1 --> */}
              <li
                className="form-stepper-active text-center form-stepper-list"
                step="1"
              >
                <a>
                  <span
                    className="form-stepper-circle"
                    onClick={() => {
                      setFormStep(1);
                      localStorage.setItem("stepperForm", 1);
                    }}
                  >
                    <span>1</span>
                  </span>
                </a>
              </li>
              {/* <!-- Step 2 --> */}
              <li
                className={`${
                  formStep > 1
                    ? "form-stepper-active"
                    : "form-stepper-unfinished"
                } text-center form-stepper-list`}
                step="2"
              >
                <a>
                  <span
                    className="form-stepper-circle text-muted"
                    onClick={() => {
                      if (props.match.params.jobId) {
                        setFormStep(2);
                        localStorage.setItem("stepperForm", 2);
                      }
                    }}
                  >
                    <span>2</span>
                  </span>
                </a>
              </li>
              {/* <!-- Step 3 --> */}
              <li
                className={`${
                  formStep > 2
                    ? "form-stepper-active"
                    : "form-stepper-unfinished"
                } text-center form-stepper-list`}
                step="3"
              >
                <a>
                  <span
                    className="form-stepper-circle text-muted"
                    onClick={() => {
                      if (props.match.params.jobId) {
                        setFormStep(3);
                        localStorage.setItem("stepperForm", 3);
                      }
                    }}
                  >
                    <span>3</span>
                  </span>
                </a>
              </li>
            </ul>
            <hr></hr>
            {/* <!-- Step Wise Form Content --> */}
            <form
              id="userAccountSetupForm"
              name="userAccountSetupForm"
              encType="multipart/form-data"
              className="formBodyMultiStep"
              style={{ height: " calc(100% - 70px)" }}
            >
              {/* <!-- Step 1 Content --> */}
              {formStep == 1 && (
                <>
                  <div id="step-1" className="form-step addJobSelect">
                    <div className="postJobForm form1">
                      <label htmlFor="jobtitle" className="formLabel ">
                        Job Title<span className="text-pink">*</span>
                      </label>
                      <input
                        type="text"
                        name="jobTitle"
                        id="jobTitle"
                        value={formData.jobTitle}
                        placeholder=" Eg. Full Stack Developer"
                        className="formInput mt-2"
                        required
                        onChange={handleChange}
                      />
                      <label htmlFor="workType formLabel" className="mt-3 ">
                        Work Place Type <span className="text-pink">*</span>
                      </label>
                      <Select
                        name="workType"
                        value={formData.workType}
                        styles={customStyles}
                        onChange={handleWorkType}
                        options={workTypes}
                        placeholder="Hybrid"
                        required
                      />

                      <div className="form-group w-100 position-relative">
                        <label className="mb-2">Job Location</label>
                        <div
                          className="skillSethld"
                          onClick={onClickDivFocusInput}
                        >
                          {formData.location?.length > 0 &&
                            formData?.location?.map((item) => (
                              <div className="tag-item" key={item}>
                                {item}
                                <button
                                  className="button"
                                  onClick={() => handleCityDelete(item)}
                                >
                                  &times;
                                </button>
                              </div>
                            ))}
                          <input
                            // placeholder="Eg Python"
                            ref={inputEl}
                            value={citySearchTxt}
                            onChange={handleCityChange}
                            onKeyDown={(e) => handleKeyDown(e, listOfCities[0])}
                            onPaste={handlePaste}
                          />
                        </div>
                        {showCitiesDropdown && (
                          <div
                            className="cityDropdown mt-2"
                            ref={wrapperRef}
                            onScroll={handleScroll}
                          >
                            {listOfCities.length > 0 &&
                              listOfCities?.map((item) => (
                                <p
                                  key={item._id}
                                  onClick={() => addCities(item.name)}
                                  className="dropDownList mb-0"
                                >
                                  {item?.name}, {item?.state_name},{" "}
                                  {item?.country_name}
                                </p>
                              ))}
                          </div>
                        )}
                      </div>

                      <label
                        htmlFor="EmploymentType formLabel"
                        className="mt-3 "
                      >
                        Employment Type
                      </label>
                      <Select
                        name="employmentType"
                        styles={customStyles}
                        onChange={handleEmploymentType}
                        options={employmentTypes}
                        value={formData.jobType}
                        placeholder="Full Time"
                      />

                      <label
                        htmlFor="experience formLabel"
                        className="mt-3 mb-2"
                      >
                        Experience <span className="text-pink">*</span>
                      </label>
                      <div id="experienceRange">
                        <div className="d-flex align-items-center">
                          <input
                            id="minYrs"
                            type="number"
                            value={formData.experienceStart}
                            name="experienceStart"
                            onChange={handleChange}
                            required
                          />
                          <span className="labelYrs">Yrs</span>

                          <hr className="dashExperience" />

                          <input
                            id="maxYrs"
                            type="number"
                            name="experienceEnd"
                            value={formData.experienceEnd}
                            onChange={handleChange}
                            required
                          />
                          <span className="labelYrs">Yrs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {formData.jobTitle &&
                    formData?.workType?.value &&
                    formData.experienceStart &&
                    formData.experienceEnd &&
                    parseFloat(formData.experienceStart) <
                      parseFloat(formData.experienceEnd) ? (
                      <button
                        className={`text-center nextButton mb-4 btn-pink`}
                        type="button"
                        onClick={
                          props.match.params.jobId ? updateJob : createJob
                        }
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className={`text-center nextButton mb-4`}
                        type="button"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </>
              )}

              {/* <!-- Step 2 Content, default hidden on page load. --> */}
              {formStep == 2 && (
                <>
                  <div id="step-2" className="form-step ">
                    <div className="form2">
                      <label htmlFor="salaryRange" className="formLabel ">
                        Salary Range
                      </label>
                      <div id="salaryRange">
                        <div className="d-flex align-items-center inputBoxes">
                          <span className="labelRupee" htmlFor="minSalary">
                            ₹
                          </span>
                          <input
                            id="minSalary"
                            type="number"
                            name="startRange"
                            value={formData.startRange}
                            onChange={handleChange}
                            required
                          />
                          <label className="priceLabel formLabel">LPA</label>

                          <hr className="dashSalary" />

                          <span className="labelRupee" htmlFor="maxSalary">
                            ₹
                          </span>
                          <input
                            id="maxSalary"
                            type="number"
                            name="endRange"
                            value={formData.endRange}
                            onChange={handleChange}
                            required
                          />
                          <label className="priceLabel formLabel">LPA </label>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="" className="formLabel mt-4 ">
                          Add Job description
                          <span className="text-pink">*</span>
                        </label>
                      </div>
                      <div className="jobsDescriptionEditor">
                        <CKEditor
                          editor={Editor}
                          config={{
                            toolbar: {
                              items: [
                                "Essentials",
                                "Heading",
                                "Paragraph",
                                "bold",
                                "italic",
                                "strikethrough",
                                "underline",
                                "code",
                                "subscript",
                                "superscript",
                                "removeFormat",
                                "   ",
                                "link",
                                "insertImage",
                                "blockQuote",
                                "mediaEmbed",
                                "codeBlock",
                                "htmlEmbed",
                                "    ",
                                "bulletedList",
                                "numberedList",
                                "alignment",
                                "    ",
                                "undo",
                                "redo",
                                "    ",
                              ],
                            },
                          }}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          data={formData.jobDescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData((prevValue) => {
                              return {
                                ...prevValue,
                                ["jobDescription"]: data,
                              };
                            });
                          }}
                          name="jobDescription"
                        />
                      </div>
                      <div className="form-group skillTags">
                        <label htmlFor="skills" className="formLabel ">
                          Add Skills<span className="text-pink">*</span>
                        </label>
                        <div
                          className="skillSethld"
                          onClick={onClickDivFocusInput}
                        >
                          {formData?.skills?.length > 0 &&
                            formData?.skills?.map((item) => (
                              <div className="tag-item" key={item}>
                                {item}
                                <button
                                  type="button"
                                  className="button"
                                  onClick={() => handleDelete(item)}
                                >
                                  &times;
                                </button>
                              </div>
                            ))}
                          <input
                            // placeholder="Eg Python"
                            ref={inputEl}
                            value={skills}
                            onChange={handleSkillChange}
                            onKeyDown={(e) =>
                              handleSkillsKeyDown(e, skillslist[0])
                            }
                          />
                        </div>
                        {showSkillsDrop && (
                          <div
                            className="cityDropdown mt-2"
                            ref={wrapperRef}
                            onScroll={handleScroll}
                          >
                            {skillslist.length > 0 &&
                              skillslist?.map((skill) => (
                                <p
                                  key={skill._id}
                                  onClick={() => addSkills(skill.name)}
                                  className="mb-0 dropDownList"
                                >
                                  {skill?.name}
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {formData?.jobDescription && formData?.skills.length > 0 ? (
                      <button
                        className={`text-center nextButton mb-4 btn-pink`}
                        type="button"
                        onClick={updateJob}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className={`text-center nextButton mb-4`}
                        type="button"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </>
              )}
              {/* <!-- Step 3 Content, default hidden on page load. --> */}
              {formStep == 3 && (
                <>
                  <div id="step-3" className="form-step">
                    <div className="text-center form3">
                      <div className="questions">
                        {formData.questions?.length > 0 &&
                          formData.questions.map((question, i) => (
                            <div key={i} className="d-flex mb-3 questionBox">
                              <p className="questioList">{question}</p>
                              <img
                                src={cross}
                                onClick={() => {
                                  setFormData((prev) => ({
                                    ...prev,
                                    questions: prev.questions.filter(
                                      (i) => i !== question
                                    ),
                                  }));
                                }}
                              ></img>
                            </div>
                          ))}

                        {showQuestionInput === true ? (
                          <div className="inputBox text-left">
                            <p className="">Add your custom question</p>
                            <input
                              placeholder="Eg. Have you completed bachelor's degree?"
                              type="text"
                              id="makeQuestion"
                              className="mt-3 addQuestionInputBox formInput"
                              value={inputValue}
                              onKeyDown={(e) => {
                                if (e.code == "Enter") {
                                  setShowQuestionInput(false);
                                  const prevValue = [...questions, inputValue];
                                  setFormData((prev) => ({
                                    ...prev,
                                    questions: [...prev.questions, inputValue],
                                  }));
                                  setInputValue("");
                                }
                              }}
                              onChange={(e) => setInputValue(e.target.value)}
                            ></input>
                          </div>
                        ) : (
                          <></>
                        )}

                        <p className="text-muted lineInfo">
                          Press enter to end the question or click{"  "}
                          <strong>"Add Questions"</strong>
                        </p>
                        <div className="addQuestionContainer">
                          <button
                            className="addQuestionBtn btn mt-4"
                            onClick={(e) => toggleInput(e)}
                          >
                            + &nbsp; &nbsp; Add Questions
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className={`text-center nextButton mb-4 btn-pink`}
                      type="button"
                      onClick={() => setShowModal(true)}
                    >
                      {location.pathname.includes("editJob")
                        ? "Save"
                        : "Submit"}
                    </button>
                  </div>
                </>
              )}
              {formStep == 3 && (
                <p className="paraInactive">
                  Click on submit, we will see a preview
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
      <Modal show={showModal} className="jobPostPreviewModal" onHide={() => {}}>
        <Modal.Header className="position-relative modalJobHeader">
          <img
            className="jobPostPreviewModalClose"
            onClick={() => setShowModal(false)}
            src={closeModelBtn}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="jobDescription mt-1 ">
            <div className="jobDescriptionHeader pe-2">
              <div className="jobDescriptionCompanyDetails col-md-12 ps-0 d-flex">
                <div className="jobDescriptioncompanylogohld ms-4 me-2 mt-3 p-1">
                  <img
                    className="jobDescriptionCompanyLogo"
                    src={companyData?.company?.logo}
                    alt="logo"
                    loading="lazy"
                  />
                </div>
                <div className="col-md-10 ">
                  <div className="d-flex justify-content-between pt-3">
                    <div className="px-0">
                      <span className="jobDescriptionPositionName block">
                        {formData?.jobTitle}
                      </span>
                      <div className="jobDescriptionCompanyName ">
                        {companyData?.company?.companyName}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-start px-0 py-2">
                      <img
                        className="me-2 jobDescriptionSavejobImg"
                        src={SaveJobsGray}
                      />
                      <button className="jobDescriptionApplyBtn me-0">
                        Apply Now
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-1 col-12 px-0">
                    <a
                      className="jobDescriptionShareBtn ms-auto"
                      target="_blank"
                      href={`https://www.linkedin.com/shareArticle?url=${Machinehack_Url}/jobs/?currentJobId%3D${props.match.params.jobId}`}
                    >
                      Share Job
                      <img className="ms-2" src={jobShare} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="jobDescriptionCompanyData py-3">
              <span className="me-4">
                <img className="me-2" src={companyBuilding} />
                {companyData?.company?.employerSize} People
              </span>
              <span className="me-4">
                <img className="me-2" src={pepoleApplicants} />
                {0} Applicants
              </span>
              <a
                href={companyData?.company?.website}
                className="me-4"
                target="_blank"
              >
                <img className="me-2" src={JobsGlobalLink} />
                {companyData?.company?.website}
              </a>
              <span className="inline-block ms-1">
                {companyData?.company?.linkedin && (
                  <img
                    className="me-1 img-fluid"
                    src={linkedInLogo}
                    alt="linkedin"
                    loading="lazy"
                  />
                )}
                {companyData?.company?.twitter && (
                  <img
                    className="me-1 img-fluid"
                    src={twitterLogo}
                    alt="twitter"
                    loading="lazy"
                  />
                )}
                {companyData?.company?.github && (
                  <img
                    className="me-1 img-fluid"
                    src={gitLogo}
                    alt="github"
                    loading="lazy"
                  />
                )}
              </span>
            </div>
            <hr />
            <div className="d-flex flex-wrap ps-3 py-3">
              <div className="jobDescriptionType col-md-4 pe-0">
                <span className="me-2">Experience:</span>
                {formData?.experienceStart} - {formData?.experienceEnd}Yrs
              </div>
              <div className="jobDescriptionType col-md-4 px-0">
                <span className="me-2">Salary:</span>
                {formData?.startRange} - {formData?.endRange} LPA
              </div>
              <div className="jobDescriptionType col-md-4 px-0">
                <span className="me-2">Job Type:</span>
                {formData?.jobType?.value}
              </div>
              <div className="jobDescriptionType col-md-4 mt-2 pe-0">
                <span className="me-3">Location:</span>
                {formData?.location?.toString().replaceAll(",", ", ")}
              </div>
              <div className="jobDescriptionType col-md-4 mt-2 pe-0">
                <span className="me-3">Work Place Type:</span>
                {formData?.workType?.value}
              </div>
            </div>
            <hr />
            <div className="ps-4">
              <div className="jobDescriptionSkills">Key Skills</div>
              <div className="jobDescriptionSkillsList ps-2 mt-3">
                {formData.skills?.map((skill, i) => {
                  return (
                    <span className="me-2" key={i}>
                      {skill}
                    </span>
                  );
                })}
              </div>
              <div className="mt-2 px-2 pt-2">
                <div className="JobDescriptionSkillTitle my-2">
                  Job Description
                </div>
                <div
                  className="jobDescriptionPreview"
                  dangerouslySetInnerHTML={{
                    __html: formData?.jobDescription,
                  }}
                ></div>
                <div className="JobDescriptionSkillTitle mt-4 mb-2">
                  Recently Posted Jobs (5)
                </div>
                <div className="JobsCompanyDetails">
                  <div className="d-flex col-md-12 JobsCompanyDetailsHeader align-items-center">
                    <img
                      className="me-4 companylogo img-fluid"
                      src={companyData?.company?.logo}
                      alt="logo"
                      loading="lazy"
                    />
                    <div>
                      Job poster
                      <div className="JobsCompanyDetailsName">
                        {companyData?.firstName} {companyData?.lastName}
                      </div>
                    </div>
                    <button className="JobsCompanyDetailsBtn">
                      Message <img className="ms-3" src={JobsRedirect} />
                    </button>
                  </div>
                  {companyData?.company?.description !== "" && (
                    <div className="JobsCompanyDetailsTitle mt-3 mb-2">
                      <div> About the company</div>
                      <span
                        className="JobsCompanyDetailsDescription"
                        dangerouslySetInnerHTML={{
                          __html: companyData?.company?.description,
                        }}
                      ></span>
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    onClick={() => {
                      updateJob();
                      () => {
                        history.push("/jobs");
                        setShowModal(false);
                      };
                    }}
                    className="primaryBtnActive jobPostBtn mb-3 m-auto"
                  >
                    Post Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
