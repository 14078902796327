import "./MyHackathons.css";
import Remove1 from "../../../assets/Images/MyHackathons/Remove1.svg";
import Praticipant from "../../../assets/Images/MyHackathons/Praticipant.svg";
import Prize from "../../../assets/Images/MyHackathons/prize.svg";

import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";
import AddIcon from "../../../assets/Icons/Jobs/Group1187.svg";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { API, BootcampBackend } from "../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import RequestHackathon from "./RequestHackathon/RequestHackathon";
import { useSidebar } from "helper/SideBarContext";

const moment = require("moment");
export default function MyHackathons() {
  const [hackathons, setHackathons] = useState([]);

  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState({
    name: "",
    emailId: "",
    phone: "",
    description: "",
    designation: "",
    organization: "",
  });

  const [userError, setUserError] = useState({
    emailId: false,
    name: false,
  });

  const { isSidebarCollapsed } = useSidebar();

  const history = useHistory();

  const formatDate = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("MMM DD");

    return formattedDate;
  };

  function showModal() {
    setShow(true);
  }
  function hideModal() {
    setUserData({
      name: "",
      emailId: "",
      phone: "",
      description: "",
      designation: "",
      organization: "",
    });
    setShow(false);
  }

  function handleChange(event) {
    setUserError({
      emailId: false,
      name: false,
    });

    const { name, value } = event.target;
    setUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    var regName = /^[a-zA-Z\s]{1,30}$/;
    var regEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!regName.test(userData.name)) {
      setUserError({
        name: true,
      });
      return;
    }

    if (!regEmail.test(userData.emailId)) {
      setUserError({
        emailId: true,
      });
      return;
    }
    if (!regName.test(userData.designation)) {
      setUserError({
        designation: true,
      });
      return;
    }

    if (userData?.phone?.length < 10 || userData?.phone?.length > 12) {
      setUserError({
        phone: true,
      });
      return;
    }

    if (!userData.organization.match(/^[0-9a-zA-Z]+$/)) {
      setUserError({
        organization: true,
      });
      return;
    }

    axios
      .post(
        `${BootcampBackend}/submitHackathon/create`,
        {
          name: userData.name,
          phoneNumber: userData.phone,
          emailId: userData.emailId,
          Description: userData.description || "I want to host a hackathon.",
          designation: userData.designation,
          organization: userData.organization,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          hideModal();
        }
      })
      .catch((err) => {
        // if(err.response.data.status.code === 404){
        // }
        // if(err.response.data.status.code === 500){
        //   console.log("Internal Server Error");
        // }
      });
  }
  const getTimeLeft = (HEnddate) => {
    const isoDateString = moment.utc(HEnddate, "DD/MM/YYYY HH:mm:ss");
    const now = moment.utc();
    const diff = isoDateString.diff(now);
    const duration = moment.duration(diff);
    const monthsLeft = Math.round(duration.asMonths(true));
    const daysLeft = Math.floor(duration.asDays());
    const hoursLeft = duration.hours();
    let finalTimeLeft = "";
    if (monthsLeft) {
      if (monthsLeft > 0) {
        if (monthsLeft == 1 && daysLeft <= 30) {
          finalTimeLeft = `${daysLeft} ${daysLeft > 1 ? "days" : "day"} Left`
        } else if (monthsLeft >= 12) {
          finalTimeLeft = `more than a year left`
        } else {
          finalTimeLeft = `${monthsLeft} ${monthsLeft > 1 ? "months" : "month"} Left`
        }
      } else if (monthsLeft < 0) {
        if (monthsLeft * -1 >= 12) {
          finalTimeLeft = `more than a year ago`
        } else {
          finalTimeLeft = `${monthsLeft * -1} ${monthsLeft * -1 > 1 ? "months" : "month"} ago`
        }
      }
    } else if (daysLeft) {
      if (daysLeft > 0) {
        finalTimeLeft = `${daysLeft} ${daysLeft > 1 ? "days" : "day"} Left`
      } else if (daysLeft < 0) {
        finalTimeLeft = `${daysLeft * -1} ${daysLeft * -1 > 1 ? "days" : "day"} ago`
      }
    } else if (hoursLeft) {
      if (hoursLeft > 0) {
        finalTimeLeft = `${hoursLeft} hours Left`
      }
    }
    return finalTimeLeft;
  }

  //const e_id = "60fd50e33233120548e0e79d";

  async function fetchData() {
    try {
      const res = await axios.get(
        `${BootcampBackend}/v1/hackathons`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    fetchData()
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setHackathons(res.data.result.hackathonData)
        };
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Header
        className="header"
        heading="My Hackathons"
      />
      <div className="container-fluid hackathonLandingContainer px-0">
        <div
          className={`${isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
        >
          <Navbar />
          <div className="px-4">
          {isLoading ? (
              <LoadingShape
                className="my-3"
                style={{ width: "15%", height: "25px" }}
              />
            ) : (
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="secondaryColoredBtn my-3 requestHackathonBtn"
              >
                Request Hackathon <img className="ms-2" src={AddIcon} />
              </button>
            )}
          </div>
          <div className="pr-0 hackathonListContainer">
            {hackathons.map((hackathon, i) => {
              return (
                <div className="hackathonItem cursor-pointer" onClick={()=>history.push(`/myHackathons/${hackathon.HREFName}`)}>
                  <div className="header">
                    <img src={hackathon?.HImage} className="hackathonBanner" />
                    <div className="hackathonStatusContainer">
                      <div className={`hStatusPill ${hackathon?.HStatus==="Active"?"hStatusPillActive":"hStatusPillInactive"}`}>
                        <div style={{ height: "5px", width: "5px", borderRadius: "50px", backgroundColor: hackathon?.HStatus==="Active"?"#2BBD10":"#ec5050", marginRight: "5px" }}></div>
                        <p>{hackathon?.HStatus}</p>
                      </div>
                      <p className="creationDate">Posted on  {moment(hackathon.HStartDate,"DD/MM/YYYY HH:mm:ss").format("MMM Do YY")}</p>
                    </div>
                  </div>
                  <div>
                    <h4 className="hackathonTitle">
                     {hackathon.HName}
                    </h4>
                    <div className="hackthonProgress">
                      <div style={{ width: "50%", background: "linear-gradient(97deg, #00F4A4 2.88%, #00F4C1 18.54%, #00CCF2 35.1%, #5EF3A8 47.07%, #DDF040 64.25%)", height: "100%" }}></div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "15px" }}>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                        <img src={Praticipant} style={{ marginRight: "5px" }}></img>
                        <p style={{
                          color: "#3C3E4D",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500
                        }}>{moment(hackathon.HStartDate, "DD/MM/YYYY HH:mm:ss").format("MMM D")} - {moment(hackathon.HEnddate, "DD/MM/YYYY HH:mm:ss").format("MMM D")}</p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                        <img src={Praticipant} style={{ marginRight: "5px" }}></img>
                        <p style={{
                          color: "#3C3E4D",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500
                        }}>{hackathon.HUsers} Paricipants</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <RequestHackathon
            show={show}
            hideModal={hideModal}
            userError={userError}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
