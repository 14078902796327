import React, { useEffect, useState } from "react";
import "./Jobs.css";
import SearchIcon from "../../../assets/Icons/Jobs/search.svg";
import AddIcon from "../../../assets/Icons/Jobs/Group1187.svg";
import appliedIcon from "../../../assets/Icons/Jobs/AppliedIcon.svg";
import dotsThree from "../../../assets/Icons/Jobs/svg.svg";
import closeIcon from "assets/Icons/Settings/closeBtn.svg";
import { Pagination } from "react-rainbow-components";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import Dropdown from "./components/Dropdown/Dropdown";
import NoJobsIcon from "assets/Icons/Jobs/noJobs.svg";
import plusIcon from "assets/Icons/Jobs/plus.svg";

const moment = require("moment");

export default function JobList({
  isLoading,
  jobList,
  setJobList,
  getListOfJobs,
  jobCount,
  showTabs,
  handleSearch,
  handleSearchReset,
  searchText,
  setSearchText,
  activePage,
  setActivePage,
}) {
  const [showJobOptions, setShowJobOptions] = useState("");

  const history = useHistory();

  function handleAddjob() {
    history.push("/jobs/addJob");
  }

  const formatDate = (dateTime) => {
    if (dateTime) {
      var string = dateTime;
      var temp = string.split("T");

      var strdate = temp[0].split("-");
      var year = strdate[0];
      var month = strdate[1];
      var day = strdate[2];

      var formattedDate = moment({
        year: Number(year),
        month: Number(month) - 1,
        day: Number(day),
      }).format("DD MMM YYYY");

      if (formattedDate === "Invalid date") return "";

      return formattedDate;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
  }, []);

  function handleOnChangePagination(event, page) {
    setActivePage(page);
  }

  const handleKeyDown = (evt) => {
    if (["Enter"].includes(evt.key)) {
      evt.preventDefault();
      handleSearch();
    }
  };
  const onJobsTabChange = (type) => {
    getListOfJobs(type);
  };

  useEffect(() => {
    const getSearchData = setTimeout(() => {
      if (searchText.length) {
        handleSearch();
      }
    }, 2000);

    return () => clearTimeout(getSearchData);
  }, [searchText]);

  return (
    <>
      <div className="allJobsContainer">
        {jobCount.totalPublished ||
        jobCount.totalDrafts ||
        jobCount.totalClosed ? (
          <>
            <div className="allJobsHead my-3">
              <button
                className="secondaryColoredBtn mx-4 postBtn"
                onClick={handleAddjob}
              >
                Post Job
                <img className="ms-2" src={AddIcon} />
              </button>
              <div className="tabsWindow d-flex  flex-nowrap">
                <div className="tabBtnGroup">
                  <button
                    className={`btn-tabs ${
                      showTabs === "published" && "active-tab"
                    }`}
                    onClick={() => onJobsTabChange("published")}
                  >
                    Active &nbsp;({jobCount.totalPublished})
                  </button>
                  <button
                    className={`btn-tabs ${
                      showTabs === "drafts" && "active-tab"
                    }`}
                    onClick={() => onJobsTabChange("drafts")}
                  >
                    Drafts &nbsp;({jobCount.totalDrafts})
                  </button>
                  <button
                    className={`btn-tabs ${
                      showTabs === "closed" && "active-tab"
                    }`}
                    onClick={() => onJobsTabChange("closed")}
                  >
                    Closed &nbsp;({jobCount.totalClosed})
                  </button>
                </div>
              </div>
              <div className="searchHeadJobs d-flex mx-4">
                <div className="searchBox text-left d-flex align-items-center">
                  <img
                    className="me-2"
                    src={SearchIcon}
                    width="15"
                    height="15"
                  />
                  <input
                    className="formInput"
                    placeholder="Search for job title"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  {searchText.length > 1 && (
                    <img
                      className="cursor-pointer"
                      src={closeIcon}
                      alt="x"
                      width="10"
                      height="10"
                      onClick={handleSearchReset}
                    />
                  )}
                </div>
              </div>
            </div>
            <hr></hr>
          </>
        ) : (
          <></>
        )}

        <div className="jobTabs">
          <div className="postedJobs">
            {isLoading ? (
              Array.from(
                {
                  length: 10,
                },
                (_, i) => (
                  <div
                    className="jobCard d-flex flex-row justify-content-between"
                    style={{
                      border: "none",
                      background: "#dfe0e470",
                      overflow: "hidden",
                    }}
                    key={i}
                  >
                    <div className="d-flex">
                      <LoadingShape
                        variant="image"
                        className="hackathonImg"
                        style={{ width: "67px", height: "62px" }}
                      />
                      <div>
                        {" "}
                        <LoadingShape
                          className="m-3"
                          style={{ height: "3px", width: "100%" }}
                        />
                        <LoadingShape
                          className="m-3"
                          style={{ height: "3px", width: "50%" }}
                        />
                        <LoadingShape
                          className="m-3"
                          style={{ height: "3px", width: "50%" }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )
            ) : jobList.length ? (
              jobList.map((job, i) => (
                <div
                  className="jobCard d-flex flex-row justify-content-between"
                  key={i}
                  onClick={() => {
                    if (showTabs === "drafts") {
                      history.push(`/jobs/editJob/${job?._id || job?.job_id}`);
                    } else {
                      history.push(`/jobs/${job?._id || job?.job_id}`);
                    }
                  }}
                >
                  <div className="d-flex">
                    <img src={job?.companyId?.logo} className="companyLogo" />
                    <div className="jobCardData text-left ps-4">
                      <p className="jobTitle">{job?.jobTitle}</p>
                      {showTabs === "published" || showTabs === "closed" ? (
                        <p className="views">
                          <span>
                            {formatDate(job?.createdAt) ||
                              moment(job?.created).format("DD MMM YYYY")}{" "}
                            {}
                          </span>

                          <span>
                            {job?.views?.length
                              ? job?.views?.length
                              : job?.views}{" "}
                            Views
                          </span>
                        </p>
                      ) : (
                        showTabs === "drafts" && (
                          <p className="views">
                            <span>
                              Last Edited on &nbsp;
                              {formatDate(job?.createdAt) ||
                                moment(job?.created).format("DD MMM YYYY")}
                            </span>
                          </p>
                        )
                      )}

                      {showTabs === "published" || showTabs === "closed" ? (
                        <p>
                          <img src={appliedIcon} />
                          {typeof job.applicants === "number" ? (
                            <span className="applyCount">
                              {job.applicants +
                                job.rejectedApplicants +
                                job.savedApplicants +
                                job.shortlistedApplicants}{" "}
                              Applied
                            </span>
                          ) : (
                            <span className="applyCount">
                              {job.applicants.length +
                                job.rejectedApplicants.length +
                                job.savedApplicants.length +
                                job.shortlistedApplicants.length}{" "}
                              Applied
                            </span>
                          )}
                        </p>
                      ) : (
                        showTabs === "drafts" && <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="dotOptions position-relative"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={dotsThree}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowJobOptions(job?._id || job?.job_id);
                      }}
                      className="threeDotOptions"
                    />
                    <Dropdown
                      show={showJobOptions === (job?._id || job?.job_id)}
                      showMenuitems={
                        showTabs === "published"
                          ? [
                              "edit",
                              "delete",
                              "move-to-closed",
                              "share",
                              "view-as-candidate",
                              "copy-link",
                            ]
                          : showTabs === "drafts"
                          ? ["edit", "delete"]
                          : showTabs === "closed"
                          ? ["edit", "delete", "repost"]
                          : ["all"]
                      }
                      updateshow={setShowJobOptions}
                      jobId={job?._id || job?.job_id}
                      updateJobList={setJobList}
                      isjobList={true}
                      classes="joblistDropDown"
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="noJobs text-center">
                <img className="noJobsIconNew" src={NoJobsIcon} alt="nojobs" />
                <p className="noJobsHeading">Go Ahead Post Your Jobs</p>
                <p className="noJobsTagLine">
                  Start posting your jobs now to reach millions of Developers.
                </p>

                <button onClick={handleAddjob}>
                  Post Job
                  <img src={plusIcon} />
                </button>
              </div>
            )}
          </div>
          {(showTabs === "published" && jobCount.totalPublished > 20) ||
          (showTabs === "drafts" && jobCount?.totalDrafts > 20) ||
          (showTabs === "closed" && jobCount?.totalClosed > 20) ? (
            <Pagination
              className="rainbow-m_auto"
              pages={
                jobList.length > 0
                  ? parseInt(
                      (showTabs === "published"
                        ? jobCount.totalPublished
                        : showTabs === "drafts"
                        ? jobCount?.totalDrafts
                        : jobCount?.totalClosed) / 20
                    ) + 1
                  : 1
              }
              activePage={activePage}
              onChange={handleOnChangePagination}
              variant="shaded"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
