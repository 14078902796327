import React, { useEffect, useState } from "react";

//CSS
import "./participants.css";

import NoParticipantsImage from "../../../../assets/Icons/Dashboard/NoPaticipants.svg";

export default function NoParticipants() {
  return (
    <>
      <div className="col-xl-6 col-lg-12">
        <div className="dashboardCard dashboardParticipantsItem ">
          <div className="d-flex">
            <div className="dashboardHackthonRightItem text-center py-4 ">
              <h5 className="dashboardHeading">Participants by Experience</h5>
            </div>
            <div className="dashboardHackthonRightItem text-center py-4">
              <h5 className="dashboardHeading">Participants by Location</h5>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <img
              className="image-fluid dashboardNoParticipantStatistics mt-4"
              src={NoParticipantsImage}
              alt="hackthon"
            />
            <p className="dashboardNoParticipantsStatisticsText mt-3">
              You don't have any data to display.
            </p>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
}
