//CSS
import "./Settings.css";
//LOGO and ICONS
//COMPONENTS
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";
import React, { useEffect, useState } from "react";

//SECTIONS
import { useHistory } from "react-router-dom";

export default function Settings() {
  const [isSidebarCollapse, setIsSidebarCollapse] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
  }, []);

  return (
    <div className="settingsPage">
      <Header heading="Settings" isSidebarCollapse={isSidebarCollapse} />
      <Navbar
        sidebarCollapseChange={(value) => setIsSidebarCollapse(value)}
        isSidebarCollapse={isSidebarCollapse}
      />
    </div>
  );
}
