import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import EmailIcon from "assets/Icons/ResumeSearch/Email.svg";
import PhoneIcon from "assets/Icons/ResumeSearch/Phone.svg";
import CopyIcon from "assets/Icons/ResumeSearch/copy.svg";
import ExternalLinkIcon from "assets/Icons/DashboardNew/contactUs.svg";
import User from "assets/Icons/ResumeSearch/user.svg";
import ContributionHeatMap from "../ContributionHeatMap/ContributionHeatMap";
import moment from "moment";
import BadgeBgImg from "assets/badges/propfile-badge-bg.svg";
import { getUserBadge } from "SharedMethods/CommenMethods";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import { BootcampBackend } from "backend";
import { Machinehack_Url } from "backend";
import { API } from "backend";
import { MH_BootcampBackend } from "backend";
import CreateScheduledInterview from "pages/PrivatePages/Interview/components/CreateScheduledInterview";
import { useHistory } from "react-router-dom";
import cross from "assets/Icons/cross.svg";
import "./candidateInfo.css";
import ToastMessages from "pages/PrivatePages/Assessment/components/ToastMessages";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const weekNames = ["", "M", "", "W", "", "F", ""];
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const panelAttributes = { rx: 2, ry: 2 };

const Candidateinfo = ({ userId, setSelectedUser, selectedUser, candidateHldRef}) => {
  const [numPages, setNumPages] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [userActivity, setuserActivity] = useState({});
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [instantLink, setInstantLink] = useState("");
  const [interviewToken, setInterviewToken] = useState("");
  const [Badges, setBadges] = useState([
    {
      _id: "1",
      type: "Hackathon",
      tier: "",
      slug: "hackathon",
    },
    {
      _id: "2",
      type: "Assessment",
      tier: "",
      slug: "assessment",
    },
    {
      _id: "3",
      type: "Practice",
      tier: "",
      slug: "practice",
    },
    {
      _id: "4",
      type: "Blogs",
      tier: "",
      slug: "blog",
    },
  ]);
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const history = useHistory();

  const copyUserDetails = () => {
    navigator.clipboard.writeText(
      userDetails?.Email + " " + userDetails?.Mobile
    );
    setToastMessage({
      variant: "success",
      show: true,
      message: "Copied Successfully",
      duration: 3000,
    });
  };

  const goToAssessments = () => {
    history.push("/assessments");
  };

  async function generateScheduledLink() {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };
    await axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status === 200) {
          setShowInterviewModal(true);
          setInstantLink(result.data.result.url);
          setInterviewToken(result.data.result.token);
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const StartInterview = () => {
    generateScheduledLink();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleResume = () => {
    window.open(userDetails?.Resume_key, "_blank");
  };
  const GoToMHProfile = () => {
    window.open(`${Machinehack_Url}/user/${userId}`);
  };
  const getCoinHistory = async () => {
    try {
      const paramData = {
        startDate: moment().subtract(365, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      const coin_history_response = await axios.get(
        `${MH_BootcampBackend}/mhCoins/getUserMhCoinTransactionHistory/${userId}`,
        {
          params: paramData,
        }
      );
      const coin_history = coin_history_response.data.result.data;
      const history_groupby_date = coin_history.reduce(function (r, a) {
        const initial = moment(a?.createdAt).format("YYYY-MM-DD");
        r[initial] = r[initial] || [];
        r[initial].push(a);
        return r;
      }, Object.create(null));
      Object.entries(history_groupby_date).filter(([key, value]) => {
        history_groupby_date[key] = value.length;
      });
      setuserActivity(history_groupby_date);
    } catch (err) {
      console.log(err);
    }
  };
  const getHackathonTier = async () => {
    try {
      await axios.get(`${API}/user/newprofile//${userId}`).then((response) => {
        if (response.status === 201 || response.status === 200) {
          setUserDetails((prev) => ({
            ...prev,
            hackathonTier: response.data.user?.Level || "Novice",
          }));
          setBadges((prev) =>
            prev.filter((x) => {
              switch (x.slug) {
                case "hackathon":
                  x.tier = response.data.user?.Level;
                  break;
                default:
                  break;
              }
              return prev;
            })
          );
        } else {
          console.error(response.statusText);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getCandidateInfo = async () => {
    try {
      const response = await axios.get(
        `${BootcampBackend}/v1/candidates/profile/${userId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      const user_data = response?.data?.result?.data;
      if (user_data?._id) {
        setUserDetails(user_data);
        setBadges((prev) =>
          prev.filter((x) => {
            switch (x.slug) {
              case "assessment":
                x.tier = user_data?.assessmentTier || "Novice";
                break;
              case "practice":
                x.tier = user_data?.practiceTier || "Novice";
                break;
              case "blog":
                x.tier = user_data?.blogsTier || "Novice";
                break;
              //   case "hackathon":
              //     x.tier = user_data?.hackathonTier;
              //     break;
              default:
                break;
            }
            return prev;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCandidateInfo();
    getHackathonTier();
    getCoinHistory();
  }, [userId]);
  return (
    <div className={` ${ selectedUser?._id?`scrollSection user-list-hld`:``} candidate-info-cntr`} >
      <div ref={candidateHldRef}className="d-flex align-items-center mb-2">
        <img
          src={userDetails?.Picture}
          alt={userDetails?.Name}
          className="user-profile-picture"
        />
        <div className="ms-4">
          <p className="user-name mb-2">{userDetails?.Name}</p>
          <p className="mb-1 user-job-details-text">
            {userDetails?.LatestDesignation}
          </p>
          <p className="mb-1 user-job-details-text">
            {userDetails?.Organization}
            <span className="ps-3">{userDetails?.city}</span>
          </p>
        </div>
        <div className="candiate-cross cursor-pointer" onClick={()=>setSelectedUser({})}>
          <img src={cross} alt="cross"/>
        </div>
      </div>
      <div className="candidate-personal-details d-flex align-items-center justify-content-between">
        <ul>
          <li>
            <img src={EmailIcon} alt="" className="me-2" />
            {userDetails?.Email}
          </li>
          <li>
            <img src={PhoneIcon} alt="" className="me-2" />
            {userDetails?.Mobile}
          </li>
          <li>
            <span
              className="copy-hld ms-2 cursor-pointer"
              onClick={copyUserDetails}
            >
              <img src={CopyIcon} className="" />
            </span>
          </li>
        </ul>
        <button className="mh-profile-btn" onClick={GoToMHProfile}>
          <img src={User} className="me-1" />
          MH Profile
          <img src={ExternalLinkIcon} className="external-link" />
        </button>
      </div>
      <ul className="user-ctc-details">
        <li>
          Experience <span>{userDetails?.Experience || 0} Yrs</span>
        </li>
        <li>
          Current CTC <span>{userDetails?.currentCtc || "NA"} LPA</span>
        </li>
        <li>
          Notice Period <span>{userDetails?.noticePeriod || "NA"} Month</span>
        </li>
      </ul>
      <div className="my-3">
        <button className="assess-btn" onClick={goToAssessments}>
          Assess
        </button>
        <button className="start-interview-btn ms-3" onClick={StartInterview}>
          Start Interview
          <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
        </button>
      </div>
      <div className="about-section contributionHeatMapHld">
        <ContributionHeatMap
          weekNames={weekNames}
          monthNames={monthNames}
          panelAttributes={panelAttributes}
          panelColors={["#EBEDF0", "#0A8969", "#1ACCA0", "#00F4B7", "#8EF3DA"]}
          dateFormat={"YYYY-MM-DD"}
          values={userActivity}
          until={moment().format("YYYY-MM-DD")}
        />
      </div>
      {userDetails?.Bio ? (
        <div className="about-section">
          <p className="section-heading">About</p>
          <p className="section-description">{userDetails?.Bio}</p>
        </div>
      ) : (
        <></>
      )}
      <div className="about-section d-flex">
        {userDetails?.skills?.length ? (
          <div className="col-lg-6">
            <p className="section-heading">Skills</p>
            <div className="skillset-hld">
              {userDetails?.skills?.map((skill, index) => (
                <p
                  className="skills-text user-job-details-text"
                  key={skill + index}
                >
                  {skill}
                </p>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="col-lg-6 d-flex justify-content-between mb-4">
          {Badges.map((badge) => (
            <div className="bagde-hld mt-3" key={badge?.type + badge?._id}>
              <img src={BadgeBgImg} className="img-fluid badge-bg" alt="bg" />
              <h5 className={`badgeType`}>{badge?.type}</h5>
              <p className="badgeTier">{badge?.tier}</p>
              <img
                src={getUserBadge(badge?.tier, badge?.slug)}
                alt="badges"
                className={`user-badge-img`}
              />
            </div>
          ))}
        </div>
      </div>
      {userDetails?.JobDetails?.length ? (
        <>
          <h5 className="user-name">Work Experience</h5>
          <div className="about-section">
            {userDetails?.JobDetails?.map((work, index) => (
              <React.Fragment key={work?.id + index}>
                <div className="d-flex align-items-center justify-content-between my-2">
                  <ul className="education-details-list">
                    <li>{work?.designation}</li>
                    <li>
                      {work?.org}{" "}
                      {work?.location && work?.org ? ", " + work?.location : ""}
                    </li>
                  </ul>
                  <p className="year-of-passing">
                    {moment(work?.start_date).format("MMMM YYYY")} -{" "}
                    {moment(work?.end_date).format("MMMM YYYY")}
                  </p>
                </div>
                <p className="section-description">{work?.description}</p>
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {userDetails?.EducationDetails?.length ? (
        <>
          <h5 className="user-name">Education </h5>
          <div className="about-section">
            {userDetails?.EducationDetails.map((education, index) => (
              <div
                className="d-flex align-items-center justify-content-between my-2"
                key={education?.id + index}
              >
                <ul className="education-details-list">
                  <li>{education?.degree}</li>
                  <li>
                    {education?.institute}{" "}
                    {education?.location && education?.institute
                      ? ", " + education?.location
                      : ""}
                  </li>
                </ul>
                <p className="year-of-passing">
                  {moment(education.start_date).format("YYYY")} -{" "}
                  {moment(education.end_date).format("YYYY")}
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {userDetails?.Resume_key ? (
        <>
          <button
            className="start-interview-btn d-block mx-auto mb-4"
            onClick={handleResume}
          >
            Resume
            <FontAwesomeIcon icon={faArrowDown} className="ms-2" />
          </button>
          <Document
            file={userDetails?.Resume_key}
            options={{ workerSrc: "pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} key={page} />
              ))}
          </Document>
        </>
      ) : (
        <></>
      )}
      <CreateScheduledInterview
        showInterviewModal={showInterviewModal}
        setShowInterviewModal={setShowInterviewModal}
        instantLink={instantLink}
        applicantInfo={userDetails}
        interviewToken={interviewToken}
      />
      {toastMessage.show && (
        <ToastMessages
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
    </div>
  );
};

export default Candidateinfo;
