import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { BootcampBackend } from "backend";
//assets
import VmWare from "../../../assets/Icons/vmWare.svg";
import Genpact_logo from "assets/Icons/Genpact_logo.png";
import Quotes from "../../../assets/Icons/quotes.svg";
import userIcon from "../../../assets/Icons/userIcon.svg";
import companyLogo from "../../../assets/Icons/company.svg";
import activeCompanyLogo from "../../../assets/Icons/activeCompany.svg";
import activeUserIcon from "../../../assets/Icons/activeName.svg";
import activeEmail from "../../../assets/Icons/activeEmail.svg";
import email from "../../../assets/Icons/email.svg";
import callLog from "../../../assets/Icons/callLog.svg";
import successEmail from "../../../assets/Icons/successEmail.svg";
import "./signup.css";
import LoginSignUpHeader from "../NewLogin/LoginSignUpHeader";
export default function signup() {
  const history = useHistory();
  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    companyName: "",
    phoneNumber: "",
  });

  const [userError, setUserError] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    companyName: "",
    phoneNumber: "",
  });
  const [success, setSuccess] = useState(false);
  function handleChangeRegister(event) {
    const { name, value } = event.target;
    let regEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let regPhoneNumber = /[0-9]{10,13}$/;
    if (name === "emailId") {
      if (regEmail.test(value)) {
        setUserError({ ...userError, [name]: "" });
      } else {
        setUserError({ ...userError, [name]: "Enter valid email" });
      }
    } else if (name === "phoneNumber") {
      if (regPhoneNumber.test(value)) {
        setUserError({ ...userError, [name]: "" });
      } else {
        setUserError({ ...userError, [name]: "Enter valid phonenumber" });
      }
    } else {
      setUserError({ ...userError, [name]: "" });
    }
    setRegisterData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function handleRegister(event) {
    event.preventDefault();
    var regName = /^[a-zA-Z\s]{1,30}$/;
    var regEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!registerData.firstName) {
      setUserError({
        ...userError,
        firstName: "Name cannot be empty",
      });
      return;
    }
    if (!registerData.lastName) {
      setUserError({
        ...userError,
        lastName: "Last name cannot be empty",
      });
      return;
    }
    if (!registerData.companyName) {
      setUserError({
        ...userError,
        companyName: "Company name cannot be empty",
      });
      return;
    }
    if (!registerData.emailId) {
      setUserError({
        ...userError,
        emailId: "Email cannot be empty",
      });
      return;
    }

    if (!regName.test(registerData.firstName)) {
      setUserError({
        ...userError,
        firstName: "Enter valid name",
      });
      return;
    }
    if (!regName.test(registerData.lastName)) {
      setUserError({
        ...userError,
        lastName: "Enter valid last name",
      });
      return;
    }

    if (!regName.test(registerData.companyName)) {
      setUserError({ ...userError, companyName: "Enter valid company name" });
      return;
    }

    if (!regEmail.test(registerData.emailId)) {
      setUserError({ ...userError, emailId: "Enter valid email" });
      return;
    }

    setUserError({
      firstName: "",
      lastName: "",
      email: "",
      name: "",
    });

    axios
      .post(
        `${BootcampBackend}/iamUser/register`,
        {
          firstName: registerData.firstName,
          lastName: registerData.lastName,
          companyName: registerData.companyName,
          emailId: registerData.emailId,
          phoneNumber: registerData.phoneNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        if (err.response) {
          let errMessage = err.response.data.result.message;

          if (errMessage === "Company already exist") {
            setUserError({
              companyName: "Company already exist",
            });
          }

          if (errMessage === "Email already exist") {
            setUserError({
              email: "Email already exist",
            });
          }

          if (errMessage === "Both Email and Company exist") {
            setUserError({
              companyName: "Both Email and Company already exist",
              email: "Both Email and Company already exist",
            });
          }
        } else {
          //   setInternalServerError(
          //     "Internal Server Error.Please try after some time."
          //   );
        }
      });
  }
  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <div className="container-fluid loginSignUpHld">
      <LoginSignUpHeader />
      <div className="row signUpSection">
        <div className="col-6 p-5 signUpLeftContainer">
          <h2 className="text-center loginHeadText signUpHeadText mb-4 mt-2">
            Hello Recruiter!
          </h2>
          <p className="text-center loginSubHead pt-2">
            Hire best talent from one of the largest
          </p>
          <p className="text-center loginSubHead mb-3">
            data science community
          </p>
          <p className="text-center loginText  mb-5">
            One stop solution to transform all your hiring needs
          </p>
          <div className="carouselContainer pt-4 ">
            <Carousel controls={false} interval={3500}>
              <Carousel.Item>
                <img src={Quotes} />
                <p className="mt-3">
                  This is a good way to test last mile optimisation skill sets.
                  <br />
                  We will test the rest in the interviews
                </p>
                <h6 className="mt-5">Kiran R </h6>
                <p>Senior Director at VMware</p>
                <div className="logoBtn mt-3">
                  <img
                    src={VmWare}
                    alt=""
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img src={Quotes} />
                <p className="mt-3">
                  Flawless execution of the hackathon from start to end. Great
                  to partner with MachineHack for this Global Hackathon and it
                  was a great event for us to increase our brand in the Market.
                  MachineHack is holistically designed for superior developer
                  experience. Thanks for the great partnership here.
                </p>
                <h6 className="mt-5">Sreekanth Menon </h6>
                <p>
                  Vice President {"&"} Global Leader - AI/ML Services at Genpact
                </p>
                <div className="logoBtn mt-3">
                  <img
                    src={Genpact_logo}
                    alt="genpact"
                    className="img-fluid genpact_logo"
                    loading="lazy"
                    height="35"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img src={Quotes} />
                <p className="mt-3">
                  This is a good way to test last mile optimisation skill sets.
                  We will test the rest in the interviews
                </p>
                <h6 className="mt-5">Kiran R </h6>
                <p>Senior Director at VMware</p>
                <div className="logoBtn mt-3">
                  <img src={VmWare} />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <form
          className={`col-6 p-0 pt-5 ${
            success ? `d-flex flex-column justify-content-around h-100` : ``
          }`}
        >
          <div className="registerContainer m-auto ">
            {success ? (
              <>
                <div className="text-center mt-3 mb-4">
                  {" "}
                  <img src={successEmail} className="img-fluid successEmail " />
                </div>
                <h3 className="formHeading mb-4 mt-2">
                  Successfully Registered
                </h3>
                <p className="fontGray px-3 text-center">
                  Please check your inbox for the email
                </p>
                <p className="fontGray px-3 text-center">
                  that contains the link to set up your password.
                </p>
              </>
            ) : (
              <>
                {" "}
                <h3 className="formHeading mb-4 mt-2">Create an account</h3>
                <div className="d-flex justify-content-between userName">
                  <div>
                    <label htmlFor="firstName" className="mb-2 pt-2">
                      First Name<span>*</span>
                    </label>
                    <div
                      className={`loginInputHld ${
                        userError.firstName ? `mb-0 errorInputField` : `mb-4`
                      }`}
                    >
                      <img
                        className="img-fluid inputLogo"
                        src={registerData.firstName ? activeUserIcon : userIcon}
                      />
                      <input
                        type="text"
                        name="firstName"
                        value={registerData.firstName}
                        onChange={handleChangeRegister}
                        required
                      />
                    </div>
                    <p className="errorText">{userError.firstName}</p>
                  </div>
                  <div>
                    <label htmlFor="lastName" className="mb-2 pt-2">
                      Last Name<span>*</span>
                    </label>
                    <div
                      className={`loginInputHld ${
                        userError.lastName ? `mb-0 errorInputField` : `mb-4`
                      }`}
                    >
                      <img
                        className="img-fluid inputLogo"
                        src={registerData.firstName ? activeUserIcon : userIcon}
                      />
                      <input
                        type="text"
                        name="lastName"
                        value={registerData.lastName}
                        onChange={handleChangeRegister}
                        required
                      />
                    </div>
                    {userError.lastName && (
                      <p className="errorText">{userError.lastName}*</p>
                    )}
                  </div>
                </div>
                <label htmlFor="companyName" className="mb-2 pt-1">
                  Company Name<span>*</span>
                </label>
                <div
                  className={`loginInputHld ${
                    userError.companyName ? `mb-0 errorInputField` : `mb-4`
                  }`}
                >
                  <img
                    className="img-fluid inputLogo"
                    src={
                      registerData.companyName ? activeCompanyLogo : companyLogo
                    }
                  />
                  <input
                    type="text"
                    name="companyName"
                    value={registerData.organizationName}
                    onChange={handleChangeRegister}
                    required
                  />
                </div>
                {userError.companyName && (
                  <p className="errorText">{userError.companyName}</p>
                )}
                <label htmlFor="businessEmail" className="mb-2 pt-1">
                  Business Email<span>*</span>
                </label>
                <div
                  className={`loginInputHld ${
                    userError.emailId ? `mb-0 errorInputField` : `mb-4`
                  }`}
                >
                  <img
                    className="img-fluid inputLogo"
                    src={registerData.emailId ? activeEmail : email}
                  />
                  <input
                    type="text"
                    name="emailId"
                    value={registerData.emailId}
                    onChange={handleChangeRegister}
                    required
                  />
                </div>
                {userError.emailId && (
                  <p className="errorText">{userError.emailId}*</p>
                )}
                <label htmlFor="phoneNumber" className="mb-2 pt-1">
                  Phone Number <span className="grayText">- Optional</span>
                </label>
                <div
                  className={`loginInputHld ${
                    userError.phoneNumber ? `mb-0 errorInputField` : `mb-4`
                  }`}
                >
                  <img className="img-fluid inputLogo" src={callLog} />
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={registerData.phoneNumber}
                    onChange={handleChangeRegister}
                    required
                  />
                </div>
                {userError.phoneNumber && (
                  <p className="errorText">
                    Please enter a valid phone number*
                  </p>
                )}
                <button
                  className="submitButton mt-4"
                  onClick={(e) => handleRegister(e)}
                >
                  Start Hiring
                </button>
                <p className="text-center loginLinkText">
                  Already have an account?
                  <span className="cursor-pointer" onClick={goToLogin}>
                    &nbsp;Login
                  </span>
                </p>
                <p className="mt-4 pt-2 text-center fontGray">
                  By creating your account, you agree to the
                </p>
                <p className="text-center fontGray">
                  <a href="https://machinehack.com/terms-ui">
                    Terms of Service
                  </a>{" "}
                  &nbsp; and &nbsp;{" "}
                  <a href="https://machinehack.com/privacy-ui">
                    Privacy Policy
                  </a>
                </p>
              </>
            )}
          </div>
          <p className="mt-4 pt-2 mb-4 text-center fontGray">
            For any queries &nbsp;
            <a href="mailto:support@machinehack.com">support@machinehack.com</a>
          </p>
        </form>
      </div>
    </div>
  );
}
