import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Header from "../../../components/Header/Header";
import goBackIcon from "../../../assets/Icons/MyHackathons/arrowLeftBlack.svg";
import copyIcon from "../../../assets/Icons/Interview/copy-icon.svg";
import exitIcon from "../../../assets/Icons/Interview/exit-icon.svg";
import resizeIcon from "../../../assets/Icons/Interview/New/reSize.svg";
import WhiteboardWhite from "../../../assets/Icons/Interview/White-board-White.svg";
import AssessmentWhite from "../../../assets/Icons/Interview/Attach-Assessment-White-icon.svg";
import DiagramActiveIcon from "../../../assets/Icons/Interview/Diagram-White.svg";
import PythonCodingWhite from "../../../assets/Icons/Interview/Python-Coding-White.svg";
import RunCodeIcon from "../../../assets/Icons/Interview/run-code-icon.svg";
import codeTab from "../../../assets/Icons/Interview/New/codingTabIcon.svg";
import whiteBoardTab from "../../../assets/Icons/Interview/New/whiteBoardTabIcon.svg";
import assessmentTab from "../../../assets/Icons/Interview/New/assessmentTabIcon.svg";
import plusWhiteIcon from "../../../assets/Icons/Interview/New/plusWhiteIcon.svg";
import crossGrey from "../../../assets/Icons/Interview/New/crossGrey.svg";
import "./interviewpaper.css";
import QuestionType from "./components/QuestionType";
import { useHistory } from "react-router";
import JitsiSDK from "./components/NewJistsi";
import axios from "axios";
import SelectAssessment from "./SelectAssessment";
import { BootcampBackend, Compiler, Machinehack_Url } from "../../../backend";
import MockTest from "./components/MockTest";
import SelectAssessmentType from "./SelectAssessmentType";
import { isAuthenticated } from "../../../helper/user";
import moment from "moment";
import "./EditorAddons.js";
import { socket } from "../../../socket/Socket";
import Timer from "./Timer";
import { Helmet } from "react-helmet";
import InterviewEditor from "./components/InterviewEditor";
import InterviewExpiredPopup from "./components/InterviewExpiredPopup";
import InterviewStatusForm from "./components/InterviewStatusForm";
import InterviewWhiteboard from "./components/InterviewWhiteboard";

export const WindowContext = createContext(null);

const date = new Date();

date.setUTCHours(0, 0, 0, 0);

export default function InterviewPaper(props) {
  const [tabs, setTabs] = useState({});
  const [Listoftabs, setListofTabs] = useState([]);
  const [showAssessments, setshowAssessments] = useState(false);
  const [showAssessmentType, setShowAssessmentType] = useState("");
  const history = useHistory();
  const [interviewUserData, setInterviewUserData] = useState({});
  const [codeValue, setCodeValue] = useState("");
  const [collapseOutput, setCollapseOutput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [codeData, setCodeData] = useState({});
  const [expiryModal, setExpiryModal] = useState(false);
  const interviewToken = props.match.params.tokenId;
  const [tokenData, settokenData] = useState({});
  const [interviewData, setInterviewData] = useState({});
  const [show, setShow] = useState(false);
  const [s3url, setS3url] = useState("");
  const interviewDataMemo = useMemo(() => interviewData, [interviewData]);
  const [notes, setNotes] = useState({
    notes: "",
    error: false,
  });
  const tabsList = [
    {
      id: "1",
      name: "Coding",
      img: PythonCodingWhite,
      panelComponent: {
        data: "",
      },
    },
    {
      id: "2",
      name: "Whiteboard",
      img: WhiteboardWhite,
      panelComponent: {
        data: "",
      },
    },
    {
      id: "3",
      name: "Assessment",
      img: AssessmentWhite,
      panelComponent: {
        data: "",
      },
    },
  ];
  const addNewTab = () => {
    let istabContainUntitled = false;
    Listoftabs.filter(
      (x) => x.name === "Untitled" && (istabContainUntitled = true)
    );
    if (!istabContainUntitled) {
      const newtab = {
        id: `${Listoftabs.length + 1}`,
        name: "Untitled",
        img: DiagramActiveIcon,
      };
      setListofTabs((prev) => prev.concat(newtab));
      setTabs(newtab);
      selectedQuesType(false);
      setShowAssessmentType("");
    }
    if (tabs.name === "Coding" || tabs.name === "Whiteboard") {
      if (tabs.name === "Whiteboard") {
        const data = {
          tabID: tabs.tabID?.toString() || tabs.id,
          imgUrl: s3url,
        };
        savetabData(data);
      } else {
        const data = {
          tabID: tabs.tabID?.toString() || tabs.id,
          code: tabs.panelComponent.data,
        };
        savetabData(data);
      }
    }
  };
  const tabTitleUpdate = (data, tabType) => {
    setListofTabs(
      Listoftabs.filter((x) => {
        if (x.id === tabs.id) {
          if (data.name.includes("Coding")) {
            x.name = "Coding";
            x.panelComponent = {
              data: "",
            };
          } else if (data.name.includes("Whiteboard")) {
            x.name = "Whiteboard";
            x.panelComponent = {
              data: "",
            };
          } else if (data.name.includes("Diagram")) {
            x.name = "Diagram";
            x.panelComponent = {
              data: "",
            };
          } else if (data.name.includes("Assessment")) {
            x.name = "Assessment";
            x.panelComponent = {
              data: "",
            };
            x.assessmentSlugRef = data.slug;
          }
          x.img = data.hoverImg;
          x.tabType = tabType;
        }
        return Listoftabs;
      })
    );
  };

  const runcode = (sourceCode) => {
    axios
      .post(`${Compiler}`, {
        sourceCode: sourceCode.panelComponent.data,
      })
      .then((res) => {
        if(res?.data && res.data.stdout){
          setListofTabs(
            Listoftabs.filter((x) => {
              if (x.id === sourceCode.id) {
  
                x.stdout = res.data.stdout ;
              }
              return Listoftabs;
            })
          );
          setCollapseOutput(true);
        }
        const data = {
          result: res.data.stdout,
          type: "runcode",
        };
        socket.emit("sync_report", data);
        // getOutput(res.data)
        //   .then((res) => {
        //     setListofTabs(
        //       Listoftabs.filter((x) => {
        //         if (x.id === sourceCode.id) {
        //           x.stdout = res.data.stdout;
        //         }
        //         return Listoftabs;
        //       })
        //     );
        //     setCollapseOutput(true);
        //     const data = {
        //       result: res.data.stdout,
        //       type: "runcode",
        //     };
        //     socket.emit("sync_report", data);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getOutput = async (data) => {
  //   try {
  //     let compilationSatatus = null;
  //     let compilationResult = {};
  //     if (data) {
  //       compilationSatatus = "In Queue";
  //       compilationResult = null;

  //       // while (
  //       //   compilationSatatus &&
  //       //   (compilationSatatus == "In Queue" ||
  //       //     compilationSatatus == "Processing")
  //       // ) {
  //       //   const OutputResponse = await axios.get(
  //       //     `${Compiler}`
  //       //   );
  //       //   if (OutputResponse && OutputResponse.data) {
  //       //     compilationSatatus = OutputResponse.data.status.description;
  //       //     compilationResult = OutputResponse.data;
  //       //   } else {
  //       //     return "Exception in compilation";
  //       //   }
  //       // }
  //       if (compilationSatatus === "Accepted") {
  //         return compilationResult;
  //       } else if (
  //         compilationSatatus !== "Accepted" &&
  //         compilationResult.stderr
  //       ) {
  //         return compilationResult;
  //       }
  //     } else {
  //       return "Exception in compilation";
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const selectedQuesType = (data) => {
    if (data.id === "3") {
      setshowAssessments(true);
    } else {
      setshowAssessments(false);
    }
  };
  const [windowIsActive, setWindowIsActive] = useState(true);

  function handleActivity(forcedFlag) {
    if (typeof forcedFlag === "boolean") {
      return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
    }

    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  }

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);

    document.addEventListener("visibilitychange", handleActivity);
    // document.addEventListener("blur", handleActivityFalse);
    window.addEventListener("blur", handleActivityFalse);
    window.addEventListener("focus", handleActivityTrue);
    // document.addEventListener("focus", handleActivityTrue);

    return () => {
      window.removeEventListener("blur", handleActivity);
      // document.removeEventListener("blur", handleActivityFalse);
      window.removeEventListener("focus", handleActivityFalse);
      // document.removeEventListener("focus", handleActivityTrue);
      document.removeEventListener("visibilitychange", handleActivityTrue);
    };
  }, []);
  const validateFeedbackForm = () => {
    if (
      (notes.notes ||
        interviewDataMemo?.interviewRoundId?.interviewerFeedback
          ?.interviewFeedBack) === ""
    ) {
      setShow(false);
      setNotes((prevValue) => {
        return {
          ...prevValue,
          error: true,
        };
      });
    } else {
      setShow(true);
    }
  };

  const getInterviewRoundData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BootcampBackend}/interview/getInterviewRoundTabData/${interviewToken}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        setInterviewData(res.data.result.interviewData);
        setInterviewUserData(
          res.data.result.interviewData?.interviewRoundId.jitisiLink
        );
        if (res.data.result.interviewData) {
          if (res.data.result.interviewData.tabs.length > 0) {
            res.data.result.interviewData.tabs.filter((x, i) => {
              x.id = i + 1;
              (x.panelComponent = {
                data: "",
              }),
                (x.tabID = x.id);
              (x.name =
                x.tabType === "white_board"
                  ? "Whiteboard"
                  : x.tabType === "assessment" ||
                    x.tabType === "enterpriseAssessment"
                  ? "Assessment"
                  : x.tabType === "diagram"
                  ? "Diagram"
                  : "Coding"),
                (x.img =
                  x.tabType === "coding"
                    ? PythonCodingWhite
                    : x.tabType === "assessment"
                    ? AssessmentWhite
                    : x.tabType === "white_board"
                    ? WhiteboardWhite
                    : DiagramActiveIcon);
            });
            setListofTabs(res.data.result.interviewData.tabs);
            setTabs(res.data.result.interviewData.tabs[0]);
            if (res.data.result.interviewData.tabs[0].tabType === "coding") {
              setCodeValue(res.data.result.interviewData.tabs[0].code);
            }
          } else {
            const data = tabsList.filter((x) => {
              if (x.id === props.match.params.questionId) {
                x.id = "1";
                x.panelComponent = {
                  data: "",
                };
                return x;
              }
            });
            addnewTabData(data[0]);
            setListofTabs(data);
            setTabs(data[0]);
          }
        } else {
          const data = tabsList.filter((x) => {
            if (x.id === props.match.params.questionId) {
              x.id = "1";
              x.panelComponent = {
                data: "",
              };
              return x;
            }
          });
          addnewTabData(data[0]);
          setListofTabs(data);
          setTabs(data[0]);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addnewTabData = async (data) => {
    let body = {};
    body = {
      tabs: {
        tabID: Listoftabs.length,
        tabType: data.name.includes("Coding")
          ? "coding"
          : data.name.includes("Whiteboard")
          ? "white_board"
          : data.name.includes("Diagram")
          ? "diagram"
          : showAssessmentType === "myCompany"
          ? "enterpriseAssessment"
          : "assessment",
        assessmentSlugRef: data?.name.includes("Assessment") && data.slug,
      },
    };
    if (tokenData.companyId === isAuthenticated().eid) {
      body.currentInterviewerTab = tabs.id.toString();
    } else {
      body.currentUserTab = tabs?.id?.toString();
    }
    await axios
      .put(
        `${BootcampBackend}/interview/updateInterviewRoundTabData/${interviewToken}`,
        body,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        if (Listoftabs.length > 0) {
          tabTitleUpdate(data, body?.tabs?.tabType);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    settokenData(JSON.parse(jsonPayload));
  }

  function onCopyInviteLink() {
    navigator.clipboard.writeText(
      interviewData?.interviewRoundId?.interviewLink
    );
  }
  const exitInterview = () => {
    if (tokenData.companyId === isAuthenticated().eid) {
      validateFeedbackForm();
    } else {
      window.location.assign(`${Machinehack_Url}/hackathon`);
    }
  };
  useEffect(() => {
    parseJwt(interviewToken);
    getInterviewRoundData();
  }, []);

  const getSocketData = () => {
    socket.on("connect", () => {});
    socket.on("EnterpriseConnectionResponse", (payload) => {});
    socket.on("CreateTabResponse", (payload) => {
      if (interviewToken === payload.interviewRoundId.interviewToken) {
        setInterviewData(payload);
        let tabs = payload.tabs;
        tabs.filter((x) => {
          x.name = x.tabType.includes("coding")
            ? "Coding"
            : x.tabType.includes("white_board")
            ? "Whiteboard"
            : x.tabType.includes("diagram")
            ? "Diagram"
            : "Assessment";
          x.id = parseInt(x.tabID);
          x.img = x.tabType.includes("coding")
            ? PythonCodingWhite
            : x.tabType.includes("white_board")
            ? WhiteboardWhite
            : x.tabType.includes("diagram")
            ? DiagramActiveIcon
            : AssessmentWhite;
          x.panelComponent = {
            data: "",
          };
        });
        setListofTabs(tabs);
      }
    });
    socket.on("SyncReportResponse", (payload) => {
     // socket.emit("sync_report", data) - sync_report is an event which just broadcast whatever data you send
      if(payload.type === "tab-removed"){
        updatTabData(payload.data);
      }
      else{
        setCodeData(payload.result);
        setCollapseOutput(true);
      }
    });
  };

  useEffect(() => {
    getSocketData();
    return () => {
      socket.removeAllListeners();
    };
  }, []);

  const onChangeOfTabs = async (tab) => {
    if (!tab.name.includes("Untitled")) {
      let body = {};
      if (tokenData?.interviewerId === isAuthenticated()?.userId) {
        body.currentInterviewerTab = tab.id.toString();
      } else {
        body.currentUserTab = tab.id.toString();
      }
      await axios
        .put(
          `${BootcampBackend}/interview/updateInterviewRoundData/${interviewToken}`,
          body,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
            },
          }
        )
        .then((res) => {
          setTabs(tab);
          setShowAssessmentType("");
          if (tab.tabType === "coding") {
            setCodeValue(tab.code);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      if (tabs.name === "Coding" || tabs.name === "Whiteboard") {
        if (tabs.name === "Whiteboard") {
          const data = {
            tabID: tabs.tabID?.toString() || tabs.id,
            imgUrl: s3url,
          };
          savetabData(data);
        } else {
          const data = {
            tabID: tabs.tabID?.toString() || tabs.id,
            code: tabs.panelComponent.data,
          };
          savetabData(data);
        }
      }
    }
  };
  const savetabData = async (data) => {
    await axios
      .put(
        `${BootcampBackend}/interview/saveInterviewTabData/${interviewToken}`,
        data,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((res) => {
        setInterviewData(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tabsChange = (data) => {
    setTabs(data);
  };
  const UpdateListoftabs = (data) => {
    setListofTabs(data);
  };

  const updatTabData = (data) => {
    let tabs = data.tabs;
    tabs.filter((x) => {
      x.name = x.tabType.includes("coding")
        ? "Coding"
        : x.tabType.includes("white_board")
        ? "Whiteboard"
        : x.tabType.includes("diagram")
        ? "Diagram"
        : "Assessment";
      x.id = parseInt(x.tabID);
      x.img = x.tabType.includes("coding")
        ? PythonCodingWhite
        : x.tabType.includes("white_board")
        ? WhiteboardWhite
        : x.tabType.includes("diagram")
        ? DiagramActiveIcon
        : AssessmentWhite;
      x.panelComponent = {
        data: "",
      };
    });
    setListofTabs(tabs);
    setTabs(tabs[tabs.length - 1]);
  };

  const removeTab = async (id) => {
    let removedTabData = [];
    removedTabData = Listoftabs.filter((x) => {
      return x.id != id;
    });
    await axios
      .put(
        `${BootcampBackend}/interview/removeOrAddInterviewRoundTabData/${interviewToken}`,
        { tabs: removedTabData },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((response) => {
        const removeTabSocket = {
          type: "tab-removed",
          data: response.data.result.interviewData
        }
        socket.emit("sync_report", removeTabSocket);
        // updatTabData(response.data.result.interviewData);
        // removedTabData.length <= 0 &&
        // history.push(`/interview-paper/${interviewToken}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* <Helmet>
        <style>{".zopim { display: none !important; }"}</style>
      </Helmet> */}
      <WindowContext.Provider value={{ windowIsActive }}>
        <Header isInterview={true} />
        <div className="body-margin-top  interviewPaperpage position-relative">
          <div className="d-flex align-items-center justify-content-between interviewSubHeader px-5">
            <ul className="candidateDetails text-center mb-0">
              <li>
                {interviewDataMemo?.interviewId?.candidateName
                  ? interviewDataMemo?.interviewId?.candidateName + " - "
                  : interviewDataMemo?.interviewId?.candidateEmail
                  ? interviewDataMemo?.interviewId?.candidateEmail + " - "
                  : ""}
                {tokenData.interviewType === "instant"
                  ? "Instant Interview"
                  : interviewDataMemo?.interviewId?.interviewTitle}
              </li>
              <li>
                {moment(
                  interviewDataMemo?.interviewRoundId?.interviewStartTime
                ).format("LT")}
              </li>
            </ul>
            {tokenData.interviewType !== "instant" && (
              <Timer
                EndTimer={interviewDataMemo?.interviewRoundId?.interviewEndTime}
                setExpiryModal={setExpiryModal}
              />
            )}
            {tokenData.companyId === isAuthenticated().eid && (
              <button
                type="button"
                className="btn inviteLinkBtn me-3"
                onClick={onCopyInviteLink}
              >
                Invite Link
                <img
                  src={copyIcon}
                  alt="goback"
                  className="img-fluid ms-2"
                  loading="lazy"
                />
              </button>
            )}
            <button
              type="button"
              className="btn exitBtn"
              onClick={exitInterview}
            >
              Exit{" "}
              <img
                src={exitIcon}
                alt="goback"
                className="img-fluid"
                loading="lazy"
              />
            </button>
          </div>
          <div className="interviewMainSection">
            <div className="tablistHld clearfix d-flex">
              {Listoftabs.map((tab, i) => (
                <div
                  className={`d-flex tablist align-items-center ${
                    tabs.id === tab.id && "selectedTab"
                  }`}
                  onClick={() => onChangeOfTabs(tab)}
                  key={i}
                >
                  <img
                    src={
                      tab.name === "Coding"
                        ? codeTab
                        : tab.name === "Whiteboard"
                        ? whiteBoardTab
                        : tab.name === "Assessment"
                        ? assessmentTab
                        : tab.name === "Untitled" && plusWhiteIcon
                    }
                    loading="lazy"
                    alt="code"
                    className={`img-fluid ms-2 tabImg ${
                      tab.name === "Untitled" && "p-1"
                    }`}
                  />
                  <p className="title ms-2 me-3">{tab.name}</p>
                  {parseInt(interviewDataMemo?.currentInterviewerTab) ===
                    parseInt(tab?.id) && (
                    <div
                      className="usersIndication me-1"
                      style={{
                        background:
                          "linear-gradient(325.89deg, #0071FA -7.63%, #05D6FD 85.7%)",
                        border: "1.39081px solid rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      {interviewDataMemo?.interviewerId.firstName.slice(0, 1)}
                      <div
                        className=""
                        style={{
                          background:
                            "linear-gradient(325.89deg, #0071FA -7.63%, #05D6FD 85.7%)",
                          border: "1.39081px solid rgba(255, 255, 255, 0.2)",
                        }}
                      ></div>
                    </div>
                  )}
                  {parseInt(interviewDataMemo.currentUserTab) ===
                    parseInt(tab?.id) && (
                    <div
                      className={`usersIndication ms-auto me-2 ${
                        parseInt(interviewDataMemo.currentInterviewerTab) ===
                          parseInt(tab?.id) &&
                        parseInt(interviewDataMemo.currentUserTab) ===
                          parseInt(tab?.id)
                          ? "candiateNinterviewerIndication"
                          : ""
                      }`}
                      style={{
                        background:
                          "linear-gradient(311.53deg, #7850E9 -0.77%, #FF307B 113.88%)",
                        border: "1.39081px solid rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      {interviewDataMemo?.interviewId?.candidateName?.slice(
                        0,
                        1
                      ) ||
                        interviewDataMemo?.interviewId?.candidateEmail
                          .toString()
                          ?.slice(0, 1) ||
                        "A".slice(0, 1)}
                      <div
                        className=""
                        style={{
                          background:
                            "linear-gradient(311.53deg, #7850E9 -0.77%, #FF307B 113.88%)",
                          border: "1.39081px solid rgba(255, 255, 255, 0.2)",
                        }}
                      ></div>
                    </div>
                  )}
                  {tokenData.companyId === isAuthenticated().eid && (
                    <img
                      src={crossGrey}
                      alt="crossGrey"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTab(tab?.id);
                        setCodeData({});
                      }}
                      loading="lazy"
                      className="img-fluid tabCloseImg"
                    />
                  )}
                </div>
              ))}
              {isAuthenticated() && (
                <div className="addnewtab" onClick={addNewTab}>
                  <img src={plusWhiteIcon} alt="plusWhiteIcon" loading="lazy" />
                </div>
              )}
            </div>
            <div
              className="row w-100 mx-0 "
              style={{ height: "calc(100vh - 151px)" }}
            >
              <div className="col-md-9 p-0 position-relative interviewBodyPanel">
                {tabs?.name + tabs?.id === "Coding" + tabs?.id && (
                  <InterviewEditor
                    setListofTabs={setListofTabs}
                    Listoftabs={Listoftabs}
                    setTabs={setTabs}
                    tabs={tabs}
                    codeValue={codeValue}
                    interviewDataMemo={interviewDataMemo}
                    tokenData={tokenData}
                    setCodeData={setCodeData}
                  />
                )}
                <InterviewWhiteboard
                  tabs={tabs}
                  interviewToken={interviewToken}
                  setS3url={setS3url}
                />
                {tabs?.name === "Assessment" && (
                  <>
                    {tabs?.assessmentSlugRef && (
                      <MockTest
                        assessmentSlugRef={tabs?.assessmentSlugRef}
                        interviewData={interviewDataMemo}
                        tabData={tabs}
                        assessmentdata={tabs?.assessmentUserState}
                        interviewToken={interviewToken}
                        tabsChange={tabsChange}
                        updateListoftabs={UpdateListoftabs}
                        isReportPdf={false}
                      />
                    )}
                  </>
                )}
                {tabs?.name === "Untitled" && (
                  <div className="UntitledTabContainer position-relative">
                    {showAssessments ? (
                      <div className="m-3">
                        {showAssessmentType === "" && (
                          <>
                            <p
                              className="showQuestionAssessment mb-5 position-absolute innerAssessType"
                              style={{
                                color: "white",
                              }}
                              onClick={() => (
                                setshowAssessments(false),
                                setShowAssessmentType("")
                              )}
                            >
                              <img
                                src={goBackIcon}
                                alt="goback"
                                loading="lazy"
                                className="img-fluid me-2"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              Back
                            </p>
                            <div className="col-12 mx-auto interviewQuestionHold d-flex">
                              <SelectAssessmentType
                                isFirstTime={false}
                                interviewToken={interviewToken}
                                setShowAssessmentType={(value) =>
                                  setShowAssessmentType(value)
                                }
                                interviewType={"main"}
                              />
                            </div>
                          </>
                        )}
                        {showAssessmentType !== "" && tokenData && tabs && (
                          <>
                            <p
                              className="showQuestionAssessment mb-3"
                              style={{
                                color: "white",
                              }}
                              onClick={() => (
                                setshowAssessments(false),
                                setShowAssessmentType("")
                              )}
                            >
                              <img
                                src={goBackIcon}
                                alt="goback"
                                loading="lazy"
                                className="img-fluid me-2"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              Back
                            </p>
                            <SelectAssessment
                              isFirstTime={false}
                              interviewToken={interviewToken}
                              tabTitleUpdate={addnewTabData}
                              showAssessmentType={showAssessmentType}
                              noAssessmentTextColor="#ffffff"
                              interviewType={"main"}
                              interviewData={interviewData}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="col-12 mx-auto interviewQuestionHold">
                          <QuestionType
                            isFirstTime={false}
                            tabTitleUpdate={addnewTabData}
                            Listoftabs={Listoftabs}
                            selectedQuesType={selectedQuesType}
                            interviewType={"main"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
                {tabs?.name === "Coding" && (
                  <div
                    className={` outputSection ${
                      collapseOutput === true ? "expandedSectionOutput" : ""
                    }`}
                  >
                    <div className="collapseOutputContainer">
                      <button
                        type="button"
                        className="runcodebtn"
                        onClick={() => {
                          runcode(tabs);
                        }}
                      >
                        <img
                          src={RunCodeIcon}
                          alt="runcode"
                          className="img-fluid me-2"
                          loading="lazy"
                        />
                        Run Code
                      </button>
                      <img
                        src={resizeIcon}
                        alt="resize"
                        loading="lazy"
                        className="img-fluid cursor-pointer"
                        onClick={() => {
                          setCollapseOutput(!collapseOutput);
                        }}
                      />
                    </div>

                    {codeData?.stdout && (
                      <div className="outputDisplay p-3">
                        <p className="successfullCompilation">
                          Runtime: Success{" "}
                        </p>
                        <p>output: </p>
                        <p>{codeData?.stdout}</p>
                      </div>
                    )}
                    {codeData?.stderr && (
                      <div className="errorDisplay p-3 m-4">
                        <p>Compile Error: </p>
                        <p>{codeData?.stderr}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-3 p-0 position-relative backgroundVideoChat">
                <div
                  className="interviewFeedbackndJitsiHld"
                  style={{
                    height: `${tabs?.name === "Assessment" ? "200px" : ""}`,
                  }}
                >
                  <div className="expandedvideoChatBlock">
                    {interviewUserData && <JitsiSDK />}
                  </div>
                  <InterviewStatusForm
                    show={show}
                    tabs={tabs}
                    setShow={setShow}
                    notes={notes}
                    setNotes={setNotes}
                    interviewDataMemo={interviewDataMemo}
                    s3url={s3url}
                    savetabData={savetabData}
                    interviewToken={interviewToken}
                    isLoading={isLoading}
                    validateFeedbackForm={validateFeedbackForm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </WindowContext.Provider>
      <InterviewExpiredPopup
        expiryModal={expiryModal}
        validateFeedbackForm={validateFeedbackForm}
      />
    </>
  );
}
