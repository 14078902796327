import axios from "axios";
import { BootcampBackend } from "backend";
import { GetAuthorizationConfig } from "./auth.service";

export const SubscriptionPlanService = {
  createSubscriptionPlan: async (requestBody) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/plans`,
          requestBody,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchAllSubscriptionPlans: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/subscriptions/plans`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  updateSubscriptionPlans: async (planId, requestbody) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${BootcampBackend}/v1/subscriptions/plans/${planId}`,
          requestbody,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  createOrder: async (razorpayPlanId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions`,
          { razorpayPlanId: razorpayPlanId },
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  subscriptionCallback: async (requestData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/callback`,
          requestData,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  checkIsUserSubscribed: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/subscriptions/active`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchAllUserSubscribedPlans: async (pageNumber) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/subscriptions/history?current_page=${pageNumber}&limit=10`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  cancelSubscription: async (requestData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/cancel`,
          requestData,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  addFreeSubscription: async (requestData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/free`,
          requestData,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchAllInvoices: async (paramData) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BootcampBackend}/v1/subscriptions`, {
          params: paramData,
          ...GetAuthorizationConfig(),
        })
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchInvoiceData: async (razorpay_subscription_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/subscriptions/${razorpay_subscription_id}/invoice`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  Cantact: async (requestData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/contact`,
          requestData,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchFAQS: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/v1/subscriptions/faqs`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  createSubscriptionFAQ: async (requestBody) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BootcampBackend}/v1/subscriptions/faqs`,
          requestBody,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  updateSubscriptionFaqs: async (faqId, requestbody) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${BootcampBackend}/v1/subscriptions/faqs/${faqId}`,
          requestbody,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  deleteSubscriptionFaqs: async (faqId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${BootcampBackend}/v1/subscriptions/faqs/${faqId}`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
