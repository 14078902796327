import axios from "axios";
import { BootcampBackend } from "backend";
import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import regenerate from "assets/Icons/Settings/refresh.svg";
import deleteSvg from "assets/Icons/Settings/delete.svg";
import "./ApiKey.css";
import moment from "moment";
import { useSidebar } from "helper/SideBarContext";
export default function ApiKeys() {
  const { isSidebarCollapsed } = useSidebar();
  const [showGenerateToken, setShowGenerateToken] = useState(false);
  const [ApiData, setApiData] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  async function generateApiToken() {
    try {
      const token = JSON.parse(localStorage.getItem("jwt")).token;
      if (token) {
        const res = await axios.get(
          `${BootcampBackend}/iamUser/generateEnterpriseToken`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res?.data?.result?.data) {
          setApiData(res?.data?.result?.data);
          setShowGenerateToken(false);
        }
      }
    } catch (err) {
      console.log(err.response);
    }
  }
  async function fetchOrganizationData() {
    setShowLoader(true);
    try {
      const res = await axios.get(`${BootcampBackend}/organization_profile`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (res?.data.result?.data?.apiKey) {
        getTokenData();
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async function getTokenData() {
    try {
      const token = JSON.parse(localStorage.getItem("jwt")).token;
      if (token) {
        const res = await axios.get(
          `${BootcampBackend}/iamUser/getCurrentEnterpriseToken`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res?.data?.result?.data == null) {
          setShowGenerateToken(true);
        } else if (res?.data?.result?.data) {
          setApiData(res?.data?.result?.data);
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  }
  async function deleteToken() {
    try {
      const token = JSON.parse(localStorage.getItem("jwt")).token;
      if (token) {
        const res = await axios.delete(
          `${BootcampBackend}/iamUser/deleteEnterpriseToken`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res?.data?.result?.data == null) {
          setShowGenerateToken(true);
        } else if (res?.data?.result?.data) {
          setApiData(res?.data?.result?.data);
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  }
  useEffect(() => {
    fetchOrganizationData();
  }, []);

  return (
    <div className="boo">
      <Header heading="Settings" />
      <Navbar showApiTab={true} />
      <div
        className={` ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        } body-margin-top adjust-margin-top`}
      >
        <div className="container col-11 apiTable pt-5">
          <div className="row apiTableRowFirst">
            <div className="col-4 py-3 apiTableCol">API KEY</div>
            <div className="col-4 py-3 apiTableCol">CREATED AT</div>
            <div className="col-4 py-3 apiTableCol">ACTIONS</div>
          </div>
          <div className="row apiTableRowSecond">
            <div className="col-4 py-3 apiTableCol">
              <p className="apiString">
                {showGenerateToken ? `-` : ApiData?.token}
              </p>
            </div>
            <div className="col-4 py-3 apiTableCol">
              {showGenerateToken
                ? `-`
                : moment(ApiData?.createdAt).format("D MMM YYYY")}
            </div>
            <div className="col-4 py-3 apiTableCol">
              {showGenerateToken ? (
                `-`
              ) : (
                <>
                  <img
                    className="cursor-pointer me-3"
                    src={deleteSvg}
                    alt="delete"
                    onClick={deleteToken}
                  />
                  <img
                    className="cursor-pointer me-3"
                    src={regenerate}
                    alt="regenerate"
                    onClick={generateApiToken}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {showGenerateToken && (
          <div className="pt-5 pb-5 d-flex justify-content-center">
            <button onClick={generateApiToken} className="btn-genrateApi">
              Generate API Token
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
