//CSS
import "./Header.css";
//LOGO and ICONS
import Logo from "../../assets/Logos/MH_Logo.svg";
import userImage from "../../assets/Images/avatar.png";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isAuthenticated } from "../../helper/user";
import LeftNavIcon from "../../../src/assets/Icons/MyHackathons/arrowLeftBlack.svg";
import profileIcon from "../../../src/assets/Icons/Header/profileIcon.svg";
import settingsIcon from "../../../src/assets/Icons/Header/SettingsIcon.svg";
import supportIcon from "../../../src/assets/Icons/Header/supportIcon.svg";
import logoutIcon from "assets/Icons/Header/logoutIcon.svg";
import { LoadingShape } from "react-rainbow-components";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "services/auth.service";
import { setCurrentUser } from "store/reducers/authSlice";
import { useSidebar } from "helper/SideBarContext";
import hamburgerIcon from "assets/Icons/Header/hamburger.svg";

export default function Header(props) {
  const { heading, route, Data } = props;
  const user = useSelector((state) => state.auth.user);
  const [dropDown, setDropDown] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSidebarCollapsed, toggleSidebar } = useSidebar();
  function routeToSetting() {
    history.push("/settings/editProfile");
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const getCurrentUser = async () => {
    try {
      const currentUserRes = await authService.fetchCurrentUser();
      if (currentUserRes?.data) {
        dispatch(setCurrentUser(currentUserRes?.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      getCurrentUser();
    }
  }, [user?._id]);

  useEffect(() => {
    if (user?._id) {
      // tracker.setUserID(res.data.result.data.emailId);
      const localStorageObject = JSON.parse(localStorage.getItem("jwt"));
      Object.assign(localStorageObject, {
        userId: user._id,
      });
      localStorage.setItem("jwt", JSON.stringify(localStorageObject));
      props?.showcompanyData && props?.getCompanyData(user);
    }
  }, [user?._id]);

  function toggleDropDownMenu() {
    setDropDown((prevValue) => {
      return !prevValue;
    });
  }


  const logout = (e) => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("prevPath");
    localStorage.removeItem("loginTime");
    dispatch(setCurrentUser({}));

    history.replace("/login");
  };

  const profile = (e) => {
    history.push("/settings/editProfile");
  };

  return (
    <>
      {user?.company?.companyName && (
        <Helmet>
          <title>{` ${user?.company?.companyName} | MachineHack`}</title>
        </Helmet>
      )}
      <div className="d-flex align-items-center justify-content-between py-1 fixed-top navbar">
        <div
          className={`d-flex align-items-center ${
            props?.isInterview
              ? "interviewHeaderNav justify-content-between"
              : ""
          } ${
            isSidebarCollapsed
              ? "collapsed-content-page"
              : props.isInterview
              ? ""
              : "content-page"
          } ${props.isLoading ? "col-4" : ""}`}
        >
          <span className="me-3 hamburgerHld">{isSidebarCollapsed ? (
            <></>
          ) : (
            <img
              className="img-fluid"
              src={hamburgerIcon}
              alt="hamburgerIcon"
              onClick={toggleSidebar}
              style={{ cursor: "pointer" }}
            />
          )}</span>
          {props?.isInterview && (
            <>
              <img src={Logo} alt="machinehack" />
              {user?.company?.logo && (
                <img
                  className="intreviewCompanyLogo"
                  src={user.company.logo}
                  alt="machinehack"
                />
              )}
            </>
          )}
          {route && (
            <img
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              className="me-2"
              src={LeftNavIcon}
              onClick={() => {
                if (props.showAssessmentType) {
                  props.setShowAssessmentType("");
                } else {
                  history.push(`${route}`);
                }
              }}
            />
          )}
          {props.isLoading ? (
            <LoadingShape className="headerTitleLoader" />
          ) : heading ? (
            <h1 className={`pagetitle d-inline-block`}>{heading}</h1>
          ) : Data ? (
            <div className="d-flex flex-column me-auto ms-3">
              <p className="jobTitle">{Data.jobTitle}</p>

              <div className="align-items-center d-flex">
                {Data.draft == false && Data.unpublish == false && (
                  <div className="ApplicantJobStatusActive pe-2">Active</div>
                )}
                {Data.draft == true && Data.unpublish == true && (
                  <div className="ApplicantJobStatusDraft pe-2">Draft</div>
                )}
                {Data.draft == false && Data.unpublish == true && (
                  <div className="ApplicantJobStatusClosed pe-2">Closed</div>
                )}
                <div className="applicantJobLocation ps-2">
                  {Data.location?.join(", ")}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-4 col-sm-4 d-flex justify-content-end">
          {isAuthenticated() && (
            <div className="dropdown justify-content-around" ref={wrapperRef}>
              <div className="notificationIconContainer me-5">
                {/* <img src={NotificationIcon} alt="notifications" /> */}
              </div>
              <div className="avatar me-3" onClick={toggleDropDownMenu}>
                <img src={userImage} className="img-fluid" alt="user-picture" />
              </div>
              <button onClick={toggleDropDownMenu} className="dropbtn"></button>
              <div
                ref={wrapperRef}
                className={`dropdown-content   ${
                  dropDown ? "showDropDown" : ""
                }`}
              >
                <div className="userDetailshld">
                  <p className="userName mb-2">
                    {user.firstName + " " + user.lastName} &nbsp;{" "}
                  </p>
                  <p className="user-email">{user?.emailId}</p>
                </div>
                <p className="menuItems" onClick={profile}>
                  <img
                    src={profileIcon}
                    alt="profile"
                    className="img-fluid me-2"
                  />
                  Profile
                </p>
                <p className="menuItems" onClick={routeToSetting}>
                  <img
                    src={settingsIcon}
                    alt="settings"
                    className="img-fluid me-2"
                  />
                  Account Settings
                </p>
                <a href="mailto:support@machinehack.com" className="p-0 mh-support">
                  <p className="menuItems">
                    <img
                      src={supportIcon}
                      alt="mh-support"
                      className="img-fluid me-2"
                    />
                    MH Support
                  </p>
                </a>
                <p className="logoutHld" onClick={logout}>
                  <img
                    src={logoutIcon}
                    alt="logout-icon"
                    className="img-fluid me-2"
                  />
                  Logout
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
