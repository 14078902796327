import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../../assets/Icons/Settings/closeBtn.svg";
import SharedList from "./shareWithCandidateComponents/SharedList";
import SuccessImg from "../../../../assets/Icons/Assessments/success-email.svg";
import AddCandidateEmail from "./shareWithCandidateComponents/AddCandidate";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BootcampBackend } from "backend";
export default function ShareWithCandidates(props) {
  const {
    participantsList,
    getAllParticipants,
    reInvite,
    deleteparticipants,
    setOpen,
    setCurrentCandidate,
    isLms,
  } = props;
  const [totalEmailId, setTotalEmailId] = useState(0);
  const [invalidEmailList, setInvalidEmailList] = useState([]);
  const [duplicateEmails, setDuplicateEmails] = useState([]);
  const [csvEmailData, setCsvEmailData] = useState();
  const [modalOpen, setModalOpen] = useState("shareWithCandidate");
  const [selectedCSV, setSelectedCSV] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const history = useHistory();
  const gotoAssessments = () => {
    history.push("/assessments");
  };
  const checkDuplicateEmail = (email, sharewithcandidates) => {
    const url = isLms
      ? `${BootcampBackend}/enterpriseAssessment/lmsCheckDuplicateParticitants/${props.assessmentId}`
      : `${BootcampBackend}/enterpriseAssessment/checkDuplicateParticitants/${props.assessmentId}`;
    axios
      .post(
        url,
        {
          participantEmails: email,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setDuplicateEmails(res.data?.result?.data?.duplicateParticipants);
        sharewithcandidates &&
          res.data?.result?.data?.duplicateParticipants.length > 0 &&
          setErrorEmail(
            "This Assessment has been already shared with these candidate emails"
          );
        sharewithcandidates &&
          res.data?.result?.data?.duplicateParticipants.length === 0 &&
          submitCandidatesEmail(email);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const SaveChangesModal = () => {
    submitCandidatesEmail(csvEmailData);
  };
  const submitCandidatesEmail = async (email) => {
    const url = isLms
      ? `${BootcampBackend}/enterpriseAssessment/lmsAddParticipants/${props.assessmentId}`
      : `${BootcampBackend}/enterpriseAssessment/addParticipants/${props.assessmentId}`;
    await axios
      .post(
        url,
        {
          participantEmails: email,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        getAllParticipants(1);
        setModalOpen("shareSuccess");
        // props.setOpen("successModal");
        // setEmailList("");
        setErrorEmail("");
        setSelectedCSV(null);
        // setEmailItems([]);
        setLoader(false);
        setTimeout(() => {
          setModalOpen("");
        }, 3000);
      })
      .catch((err) => {
        setLoader(false);
        setErrorEmail("");
      });
  };
  return (
    <>
      <Modal
        centered="true"
        dialogClassName="shareWithCandidateModal"
        show={modalOpen === "shareWithCandidate" ? true : false}
        onHide={() => props.setOpen("")}
      >
        <Modal.Header className="ps-4">
          <Modal.Title>Share with candidates</Modal.Title>
          <img
            className="cursor-pointer"
            onClick={() => props.setOpen("")}
            src={closeIcon}
            alt="x"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="shareWithCandidate row m-0 ">
            <div className="col-6 pe-3 ps-4">
              <AddCandidateEmail
                setTotalEmailId={setTotalEmailId}
                setModalOpen={setModalOpen}
                setInvalidEmailList={setInvalidEmailList}
                setCsvEmailData={setCsvEmailData}
                csvEmailData={csvEmailData}
                checkDuplicateEmail={checkDuplicateEmail}
                selectedCSV={selectedCSV}
                setSelectedCSV={setSelectedCSV}
                submitCandidatesEmail={submitCandidatesEmail}
                loader={loader}
                setLoader={setLoader}
                duplicateEmails={duplicateEmails}
                setDuplicateEmails={setDuplicateEmails}
                errorEmail={errorEmail}
                setErrorEmail={setErrorEmail}
              />
            </div>
            <div className="col-6 p-0 rightContainer">
              <SharedList
                participantsList={participantsList}
                reInvite={reInvite}
                deleteparticipants={deleteparticipants}
                setOpen={setOpen}
                setCurrentCandidate={setCurrentCandidate}
                isLms={isLms}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        dialogClassName="verifyEmailMoadal"
        show={modalOpen === "shareSuccess" ? true : false}
        onHide={() => props.setOpen("")}
      >
        <Modal.Body>
          <div className="successEmails text-center">
            <img src={SuccessImg} className="img-fluid" alt="success" />
            <h5 className="mt-4">Success!</h5>
            {totalEmailId > 0 && (
              <p className="my-3 paragraph">
                Email sent to {totalEmailId} users successfully
              </p>
            )}
            {/* <button
              type="submit"
              className=" shareWithCandidateBtn mx-2 mt-3"
              onClick={gotoAssessments}
              style={{ width: "auto" }}
            >
              Go to My Assessment
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
      {modalOpen === "emailVerified" && (
        <Modal
          centered="true"
          dialogClassName="verifyEmailMoadal"
          show={modalOpen === "emailVerified" ? true : false}
          onHide={() => props.setOpen("")}
        >
          <Modal.Header>
            <Modal.Title>Verify and validate email</Modal.Title>
            <img
              className="cursor-pointer"
              onClick={() => props.setOpen("")}
              src={closeIcon}
              alt="x"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="shareWithCandidate row m-0 ">
              <div className="col-12 p-0">
                <p className="paragraph  mb-4 pt-3 px-3">
                  Hey your emails are successfully validated
                </p>
                <p className="modalHeadings  mb-2 px-3">Email Details</p>
                <div className="emailDetails px-3">
                  <div className="d-flex justify-content-between mb-1">
                    <p className="paragraph py-2">Total Email id's</p>
                    <p className="paragraph py-2">{totalEmailId}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="paragraph py-2">Invalid Email id's</p>
                    <p className="paragraph py-2">{invalidEmailList.length}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="paragraph py-2">Duplicate Email id's</p>
                    <p className="paragraph py-2">{duplicateEmails.length}</p>
                  </div>
                </div>
                {invalidEmailList.length > 0 && (
                  <div className="Invalid-email-ids px-2">
                    <p>Invalid Emails - </p>
                    {invalidEmailList.map((invalidemail, index) => (
                      <p key={index}>{invalidemail}</p>
                    ))}
                  </div>
                )}
                <div className="text-center my-5 d-flex justify-content-between align-items-center px-3">
                  <button
                    className="shareWithCandidateCancelBtn "
                    onClick={() => props.setOpen("")}
                  >
                    cancel
                  </button>
                  {invalidEmailList.length > 0 ||
                    (duplicateEmails.length > 0 && (
                      <button
                        className="shareWithCandidateBtn "
                        onClick={() => setModalOpen("shareWithCandidate")}
                      >
                        check and upload
                      </button>
                    ))}
                  {invalidEmailList.length === 0 &&
                    !loader &&
                    duplicateEmails.length === 0 && (
                      <button
                        type="submit"
                        className="shareWithCandidateBtn"
                        onClick={SaveChangesModal}
                      >
                        Share with candidates
                      </button>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
