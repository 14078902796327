import React from "react";
import Logo from "../../../assets/Logos/MachineHackResize.svg";
import { isMobile } from "react-device-detect";

export default function LoginSignUpHeader() {
  return (
    <div className="row justify-content-end align-items-center loginHeader">
      <div
        className={`${
          isMobile ? "col-12 text-center" : "col-lg-3 col-md-4 col-sm-4"
        } `}
      >
        <img
          src={Logo}
          alt="logo"
          className={`${isMobile ? "col-6" : ""} img-fluid logo`}
        />
      </div>
      <div className={`${isMobile ? "mt-3" : ""} col-lg-4 col-md-6 col-sm-6 `}>
        <div className="d-flex justify-content-end">
          <div>
            <p
              className="loginHeadingText cursor-pointer"
              onClick={() => {
                window.open("https://machinehack.com/home", "_blank");
              }}
            >
              Are you a Developer? &nbsp; | &nbsp; &nbsp;
            </p>
          </div>
          <div className="me-5">
            <p
              className="loginHeadingText cursor-pointer"
              onClick={() => {
                window.open("https://machinehack.com/contactus", "_blank");
              }}
            >
              Contact Us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
