//Python Backend API
export const API = environment_config().API;
//Bootcamp Backend
export const BootcampBackend = environment_config().BootcampBackend;
export const Compiler = environment_config().Compiler;
export const Socket_URL = environment_config().Socket_URL;
export const Socket_Connect_URL = environment_config().Socket_connect_URL;
export const Machinehack_Url = environment_config().Machinehack_Url;
export const WebRTC_URL = environment_config().WebRTC_URL;
export const Domain = environment_config().Domain;
export const Machinehack_LMS_Url = environment_config().Machinehack_LMS_Url;
export const MH_BootcampBackend = environment_config().MH_BootcampBackend

function environment_config()
{
    
    if(process.env.REACT_APP_ENV_TYPE === "production")
    {
        return {
            API:"https://api.machinehack.com",
            BootcampBackend:"https://api.enterprise.machinehack.com",
            Compiler:"https://compiler.machinehack.com",
            Socket_URL: "https://api.machinehack.com/socket/",
            Socket_connect_URL:"https://api.machinehack.com/enterprise",
            Machinehack_Url: "https://machinehack.com",
            WebRTC_URL: "wss://api.machinehack.com/webrtc/",
            Domain: "https://enterprise.machinehack.com",
            Machinehack_LMS_Url: "https://lms.machinehack.com",
            MH_BootcampBackend: "https://bootcamp.prod.machinehack.com",
        }
    }
    else{
        return {
            API:"https://machinehack.futurekrux.com",
            BootcampBackend:"https://api.dev.enterprise.machinehack.com",
            Compiler:"https://compiler.machinehack.com",
            Socket_URL: "https://machinehack.futurekrux.com/socket/",
            Socket_connect_URL: "https://machinehack.futurekrux.com/enterprise",
            Machinehack_Url: "https://staging.machinehack.com",
            WebRTC_URL: "wss://machinehack.futurekrux.com/webrtc/",
            Domain: "https://development.enterprise.machinehack.com",
            Machinehack_LMS_Url: "https://development.lms.machinehack.com",
            MH_BootcampBackend: "https://bootcamp.futurekrux.com",
        }
    }
} 
