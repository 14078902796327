import React, { useEffect, useRef, useState } from "react";
import ClearParticipantsFilter from "../../../../assets/Icons/Assessment/ClearParticipantsFilter.svg";
import hackathonSearch from "../../../../assets/Icons/MyHackathons/hackathonSearch.svg";
import ParticipantsFilterCalendar from "../../../../assets/Icons/Assessment/ParticipantsFilterCalendar.svg";
import GrayDropdownArrow from "../../../../assets/Icons/Assessment/GrayDropdownArrow.svg";
import axios from "axios";
import { BootcampBackend } from "backend";

export default function ParticipantsFilter({
  className,
  filter,
  setFilter,
  assessmentId,
  isLms,
}) {
  const [showDropdown, setShowDropdown] = useState("");
  const [candidateStatus, setCandidateStatus] = useState([
    {
      _id: "1",
      name: "candidateStatus",
      label: "All candidates",
      value: "allParticipants",
      getValue: "allParticipants",
      total: 0,
    },
    {
      _id: "2",
      name: "candidateStatus",
      label: "Invited",
      value: "Invited",
      getValue: "invited",
      total: 0,
    },
    {
      _id: "3",
      name: "candidateStatus",
      label: "In Progress",
      value: "Inprogress",
      getValue: "inProgress",
      total: 0,
    },
    {
      _id: "4",
      name: "candidateStatus",
      label: "Completed",
      value: "Completed",
      getValue: "completed",
      total: 0,
    },
  ]);
  const invitedBy = [
    {
      _id: "1",
      name: "invitedBy",
      label: "All",
      value: "all",
    },
    {
      _id: "2",
      name: "invitedBy",
      label: "By me",
      value: "mySelf",
    },
  ];

  const timeDropDown = [
    {
      _id: "1",
      label: "All Time",
      value: "",
    },
    {
      _id: "2",
      label: "Today",
      value: 0,
    },
    {
      _id: "3",
      label: "Yesterday",
      value: 1,
    },
    {
      _id: "4",
      label: "Last week",
      value: 7,
    },
    {
      _id: "5",
      label: "Last 30 days",
      value: 30,
    },
    {
      _id: "6",
      label: "Last 90 days",
      value: 90,
    },
    // {
    //   _id: "7",
    //   label: "Pick a date range",
    //   value: "range",
    // },
  ];
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const oncandidateStatusChange = (e) => {
    const { value, name } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const reset = () => {
    setFilter({
      candidateStatus: "",
      invitedBy: "",
      invitedOn: {},
      completedOn: {},
    });
  };
  const getParticipantTotalCount = async () => {
    try {
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/getAllLmsparticipantStatusCounts/${assessmentId}`
        : `${BootcampBackend}/enterpriseAssessment/getAllparticipantStatusCounts/${assessmentId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (response) {
        const total_count = response?.data?.result?.data;
        setCandidateStatus(
          candidateStatus.filter((x) => {
            if (Object.keys(total_count)?.includes(x?.getValue)) {
              x.total = total_count[x.getValue];
            }
            return candidateStatus;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getParticipantTotalCount();
  }, []);
  return (
    <div className={`ParticipantsFilter ${className}`}>
      <button
        className="ParticipantsFilterClearBtn ms-auto d-flex justify-content-between"
        onClick={reset}
      >
        Clear all
        <img src={ClearParticipantsFilter} className="ms-2" />
      </button>
      {/* <div className="SearchParticipantsLable mt-2 mb-3">
        Search Participant
      </div>
      <div className="w-100 mx-0 SearchParticipantsInput">
        <img
          className="me-2 img-fluid"
          src={hackathonSearch}
          loading="lazy"
          alt="search"
        />
        <input placeholder="Search for Participants" name="search" />
      </div> */}
      <div className="SearchParticipantsLable mt-4">Candidate Status</div>

      {candidateStatus?.map((status) => (
        <div className="d-flex my-3 align-items-center" key={status?._id}>
          <input
            type="radio"
            className="AssessmentCandidateStatus me-2"
            name={status.name}
            value={status.value}
            onChange={oncandidateStatusChange}
            checked={status.value === filter.candidateStatus}
          />
          <span className="paragraph">{status?.label}</span>
          <span className="ms-auto paragraph">{status?.total}</span>
        </div>
      ))}
      <div className="SearchParticipantsLable mt-4">Invited by</div>
      {invitedBy.map((inviteby) => (
        <div className="d-flex my-3 align-items-center" key={inviteby?._id}>
          <input
            type="radio"
            className="AssessmentCandidateStatus me-2"
            name={inviteby.name}
            value={inviteby.value}
            onChange={oncandidateStatusChange}
            checked={inviteby.value === filter.invitedBy}
          />
          <span className="paragraph">{inviteby.label}</span>
        </div>
      ))}
      <div className="SearchParticipantsLable mt-4">Invited on</div>
      <div className="position-relative">
        <div
          onClick={() => setShowDropdown("invitedOn")}
          className="w-100 mx-0 ParticipantsFilterDropdown d-flex align-items-center my-3 "
        >
          <img
            className="me-2 img-fluid"
            src={ParticipantsFilterCalendar}
            loading="lazy"
            alt="dropdown"
          />
          <span>{filter.invitedOn.label || "All time"}</span>
          <img src={GrayDropdownArrow} className="ms-auto float-right" />
        </div>

        {showDropdown === "invitedOn" && (
          <div className="AssessmentTabsOptions" ref={wrapperRef}>
            {timeDropDown.map((item) => (
              <p
                key={item?._id}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, invitedOn: item }));
                  setShowDropdown("");
                }}
              >
                {item.label}
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="SearchParticipantsLable mt-4">Completed on</div>
      <div className="position-relative">
        <div
          onClick={() => setShowDropdown("completedOn")}
          className="w-100 mx-0 ParticipantsFilterDropdown d-flex align-items-center my-3"
        >
          <img
            className="me-2 img-fluid"
            src={ParticipantsFilterCalendar}
            loading="lazy"
            alt="dropdown"
          />
          <span>{filter.completedOn.label || "All time"}</span>
          <img src={GrayDropdownArrow} className="ms-auto float-right" />
        </div>
        {showDropdown === "completedOn" && (
          <div className="AssessmentTabsOptions" ref={wrapperRef}>
            {timeDropDown.map((item) => (
              <p
                key={item?._id}
                onClick={() => {
                  setFilter((prev) => ({ ...prev, completedOn: item }));
                  setShowDropdown("");
                }}
              >
                {item.label}
              </p>
            ))}
          </div>
        )}
      </div>
      {/* <div className="SearchParticipantsLable mt-4">Expires on</div>
      <div className="w-100 mx-0 ParticipantsFilterDropdown d-flex align-items-center my-3">
        <img className="me-2" src={ParticipantsFilterCalendar} />
        <span>All Time</span>
        <img src={GrayDropdownArrow} className="ms-auto float-right" />
      </div> */}
    </div>
  );
}
