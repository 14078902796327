import React from "react";
import "./EnterpriseModal.css";
import success from "../../../../../assets/Icons/Settings/success.svg";
import cancel from "../../../../../assets/Icons/Settings/cancel.svg";

export default function EnterpriseModal(props) {
  return props.showModal ? (
    <div className="col-7 enterpriseModal ">
      <div className=" d-flex justify-content-between m-1">
        <div>
          <img src={success} className="img-fluid" loading="lazy" alt="success" />
          <span className="ms-3 enterprisePopUpText">Profile has been updated successfully.</span>
        </div>
        <div
          onClick={() => {
            props.handleClick(false);
          }}
        >
          <img className="me-1 img-fluid" src={cancel} loading="lazy" alt="close" />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
