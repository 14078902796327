import React, { useRef, useState, useEffect } from "react";
import "./Applicants.css";
import CandidatesList from "../../../../components/CandidatesList";
import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import ApplicantSearch from "../../../../assets/Icons/Jobs/ApplicantSearch.svg";
import dropdownArrow from "../../../../assets/Icons/Jobs/dropDownArrow.svg";
import filterIcon from "../../../../assets/Icons/Jobs/filterIcon.svg";
import shareIcon from "../../../../assets/Icons/Jobs/shareIcon.svg";
import experienceImg from "../../../../assets/Icons/Jobs/experienceImg.svg";
import downArrow from "../../../../assets/Icons/Jobs/downArrow.svg";
import upArrow from "../../../../assets/Icons/Jobs/upArrow.svg";
import manageIcon from "../../../../assets/Icons/Jobs/ManageIcon.svg";
import noApplicantsIcon from "../../../../assets/Icons/Jobs/noApplicants.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API, BootcampBackend, Machinehack_Url } from "../../../../backend";
import UserDetails from "../../MyHackathonsPage/Hackathon/UserDetails/UserDetails";
import { LoadingShape } from "react-rainbow-components";
import SelectAssessment from "pages/PrivatePages/Interview/SelectAssessment";
import Dropdown from "../components/Dropdown/Dropdown";
import { useSidebar } from "helper/SideBarContext";

export default function Applicants(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [manageJob, setManageJob] = useState(false);
  const [filterBy, setFilterBy] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [jobs, setJobs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIstabLoading] = useState(true);

  const [applicantData, SetApplicantData] = useState([]);
  const [applicantId, SetApplicantId] = useState({});

  const [applicantTabs, setApplicantTabs] = useState("all");
  const [searchString, setSearchString] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [assessmentModalData, setAssessmentModalData] = useState("");
  const [sortType, setSortType] = useState("");
  const [filters, setFilters] = useState({ filterName: "", filterValue: "" });
  const apllicantRef = useRef();
  const filterRef = useRef();
  const contantRef = useRef();
  const filterOptions = {
    noticePeriod: [
      { textValue: "1  Month", value: 30 },
      { textValue: "2  Months", value: 60 },
      { textValue: "3  Months", value: 90 },
    ],
    location: [
      "Mumbai",
      "Delhi",
      "Bengaluru",
      "Hyderabad",
      "Kolkata",
      "Chennai",
      "Pune",
      "Others",
    ],
    experience: [
      { textValue: "0 - 1 yrs", value: "0to1" },
      { textValue: "1 - 3 yrs", value: "1to3" },
      { textValue: "4 - 6 yrs", value: "4to6" },
      { textValue: "7 - 10 yrs", value: "7to10" },
      { textValue: "11 - 15 yrs", value: "11to15" },
      { textValue: "15+ yrs", value: "15" },
    ],
    salary: [
      { textValue: "3-6 Lakhs", value: "3to6", count: "211" },
      { textValue: "6-10 Lakhs", value: "6to10", count: "191" },
      { textValue: "10-15 Lakhs", value: "10to15", count: "211" },
      { textValue: "15-20 Lakhs ", value: "15to20", count: "118" },
      { textValue: "20-25 Lakhs ", value: "20to25", count: "100" },
      { textValue: "25-30 Lakhs ", value: "25to30", count: "310" },
      { textValue: "30-50 Lakhs ", value: "30to50", count: "211" },
      { textValue: "50+ Lakhs ", value: "50", count: "211" },
    ],
  };
  const adminView = localStorage.getItem("adminView");
  useEffect(() => {
    const closeSalaryDropDown = (e) => {
      if (apllicantRef?.current && !apllicantRef?.current?.contains(e.target))
        setManageJob(false);
      if (filterRef?.current && !filterRef?.current?.contains(e.target))
        setFilterBy(false);
    };
    document.body.addEventListener("mousedown", closeSalaryDropDown);
  }, []);

  const jobId = useLocation().pathname.split("/")[2];

  const fetchJoblistById = async () => {
    setIstabLoading(true);
    await axios
      .get(`${BootcampBackend}/jobs/getJobFromId/${jobId}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
        },
      })
      .then((res) => {
        setIstabLoading(false);
        setJobs(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = (nameProp, value) => {
    if (nameProp === "applicantLocation") {
      let valueString = `${filters.filterValue},${value}`;
      setFilters({ filterName: nameProp, filterValue: valueString });
    } else {
      setFilters({ filterName: nameProp, filterValue: value });
    }
  };
  const fetchApplicantsByJobId = async (selectionType) => {
    let paramsData = {};
    setIsLoading(true);
    if (filters.filterName && filters.filterValue) {
      paramsData[filters.filterName] = filters.filterValue;
    }
    if (selectionType) {
      paramsData.selectionType = selectionType;
    }
    if (sortType) {
      paramsData.sortType = sortType;
    }
    await axios
      .get(
        `${BootcampBackend}/jobs/savedRejectedShortlistedApplicants/${jobId}`,
        {
          params: paramsData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((result) => {
        SetApplicantData(
          result.data.result.data.applicants ||
          result.data.result.data.shortlistedApplicants ||
          result.data.result.data.rejectedApplicants ||
          result.data.result.data.savedApplicants
        );
        selectionType === "shortlisted"
          ? SetApplicantId(result.data.result.data?.shortlistedApplicants[0])
          : selectionType === "rejected"
            ? SetApplicantId(result.data.result.data?.rejectedApplicants[0])
            : selectionType === "saved"
              ? SetApplicantId(result.data.result.data?.savedApplicants[0])
              : SetApplicantId(result.data.result.data?.applicants[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApplicantsByJobId("");
  }, [filters, sortType]);
  useEffect(() => {
    fetchJoblistById();
  }, []);

  useEffect(() => {
    handleSelectUser(applicantId._id);
  }, []);

  function copyLink() {
    navigator.clipboard.writeText(
      `${Machinehack_Url}/jobs/?currentJobId=${props.match.params.jobId}`
    );
    setManageJob(false);
  }

  useEffect(() => {
    !isLoading && searchApplicants(searchString);
  }, [searchString]);

  async function searchApplicants(searchString) {
    try {
      if (searchString === "") {
        fetchApplicantsByJobId(
          applicantTabs === "selected"
            ? "saved"
            : applicantTabs === "all"
              ? ""
              : applicantTabs
        );
      } else {
        await axios
          .get(
            `${API}/enterprise/search/all_applicants/${searchString}?company_id=${jobs.companyId._id}&job_id=${jobId}`
          )
          .then((result) => {
            if (result.data.data) {
              SetApplicantData(result.data.data.applicants);
              SetApplicantId(result.data.data.applicants[0]);
            } else {
              SetApplicantData([]);
              SetApplicantId({});
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleSelectUser(applicantId) {
    let value = [];
    if (applicantTabs === "all") {
      value = applicantData.filter(
        (user) => user?.applicantId._id === applicantId
      );
    }
  }

  const ApplicantsView = async (applicantId) => {
    try {
      if (applicantId?.applicantId?._id) {
        const body = {
          jobId: props.match.params.jobId,
          applicantId: applicantId?.applicantId?._id,
          applicantType:
            applicantTabs === "selected"
              ? "savedApplicants"
              : applicantTabs === "all"
                ? "applicants"
                : applicantTabs === "shortlisted"
                  ? "shortlistedApplicants"
                  : applicantTabs === "rejected"
                    ? "rejectedApplicants"
                    : "",
        };
        await axios
          .put(`${BootcampBackend}/jobs/enterpriseJobViewed`, body, {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
            },
          })
          .then((result) => {
            SetApplicantData(
              applicantData.filter((x) => {
                if (x._id === applicantId._id) {
                  x.viewed = true;
                }
                return applicantData;
              })
            );
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const selectApplicant = (applicantId) => {
    SetApplicantId(applicantId);
  };
  return (
    <div>
      <Header
        className="header"
        route={`${adminView ? (selectedAssessment === "" ? "/admin/jobs" : `/jobs/${jobId}`) : (selectedAssessment === "" ? "/jobs" : `/jobs/${jobId}`)}`}
        showAssessmentType={selectedAssessment}
        setShowAssessmentType={setSelectedAssessment}
        Data={jobs}
        isLoading={isLoading}
      />
      <div
        className={`jobsApplicantsCntr ${isSidebarCollapsed ? "collapsed-content-page" : "content-page"
          }`}
      >
        <Navbar />
        {selectedAssessment === "" ? (
          <>
            {isTabLoading ? (
              <>
                <div className="applicantJobDetail justify-content-between">
                  <div className="col-lg-3 col-md-3 ps-4 position-relative text-start">
                    <LoadingShape style={{ height: "30px", width: "30%" }} />
                  </div>
                  <div className="applicantCategory d-flex flex-row">
                    <LoadingShape className="SkeletonLoading" />
                    <LoadingShape className="SkeletonLoading" />
                    <LoadingShape className="SkeletonLoading" />
                    <LoadingShape className="SkeletonLoading" />
                  </div>
                  <div className="d-flex pe-3 align-items-center">
                    <LoadingShape
                      shape="rounded-rect"
                      className="SkeletonLoading me-3"
                    />
                    <LoadingShape
                      shape="circle"
                      variant="image"
                      className="imageLoader"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="applicantJobDetail justify-content-between">
                <div className="col-lg-3 col-md-3 ps-4 position-relative text-start">
                  <button
                    className="secondaryColoredBtn applicantManageJobButton "
                    onClick={() => {
                      setManageJob(!manageJob);
                      setFilterBy(false);
                    }}
                  >
                    Manage Job <img className="ps-2 Icon" src={manageIcon} />
                  </button>
                  <Dropdown
                    show={manageJob}
                    showMenuitems={adminView ? [
                      "edit",
                      "move-to-closed",
                      "share",
                      "view-as-candidate",
                      "copy-link",
                    ] : [
                      "edit",
                      "delete",
                      `${jobs?.unpublish ? "repost" : "move-to-closed"}`,
                      "share",
                      "view-as-candidate",
                      "copy-link",
                    ]}
                    updateshow={setManageJob}
                    jobId={props.match.params.jobId}
                    updateJobList={SetApplicantId}
                    isjobList={false}
                    classes="applicantsDropdown"
                    copyLinkfunc={copyLink}
                  />
                </div>
                <div className="applicantCategory">
                  <button
                    className={`btn-tabs ${applicantTabs === "all" && "active-tab"
                      }`}
                    onClick={() => {
                      setApplicantTabs("all");
                      fetchApplicantsByJobId("");
                      SetApplicantId(applicantId);
                    }}
                  >
                    All &nbsp;({jobs.applicants})
                  </button>
                  <button
                    className={`btn-tabs ${applicantTabs === "shortlisted" && "active-tab"
                      }`}
                    onClick={() => {
                      setApplicantTabs("shortlisted");
                      fetchApplicantsByJobId("shortlisted");
                    }}
                  >
                    Shortlisted &nbsp;({jobs.shortlistedApplicants})
                  </button>
                  <button
                    className={`btn-tabs ${applicantTabs === "selected" && "active-tab"
                      }`}
                    onClick={() => {
                      setApplicantTabs("selected");
                      fetchApplicantsByJobId("saved");
                    }}
                  >
                    Selected &nbsp;({jobs.savedApplicants})
                  </button>
                  <button
                    className={`btn-tabs ${applicantTabs === "rejected" && "active-tab"
                      }`}
                    onClick={() => {
                      setApplicantTabs("rejected");
                      fetchApplicantsByJobId("rejected");
                    }}
                  >
                    Rejected &nbsp;({jobs.rejectedApplicants})
                  </button>
                </div>
                <div
                  className="applicantDataSearchFilter col-md-3 col-lg-3 me-4 text-end position-relative"
                  ref={filterRef}
                >
                  <span className="position-relative">
                    <span
                      className="applicantFilter cursor-pointer "
                      onClick={() => {
                        setFilterDropdown(!filterDropdown);
                        setManageJob(false);
                      }}
                    >
                      Filter by
                    </span>
                    <img
                      className="ms-2"
                      src={dropdownArrow}
                      onClick={() => {
                        setFilterDropdown(!filterDropdown);
                        setManageJob(false);
                      }}
                    />

                    {filterDropdown && (
                      <div className="applicantFilterByDropDown col-5">
                        <span className="dropDownItem">
                          <div className="innerContainer">
                            <div className="innerDropDown">
                              {filterOptions.experience.map(
                                (element, index) => (
                                  <p
                                    onClick={() =>
                                      handleFilter("Experience", element.value)
                                    }
                                    key={index}
                                  >
                                    {element.textValue}
                                  </p>
                                )
                              )}
                            </div>
                          </div>
                          Experience
                          <div className="filterDropdownImg me-2"></div>
                        </span>
                        <span className="dropDownItem">
                          <div
                            className="innerContainer innerContainerSalary"
                            style={{ left: "-340", width: "340" }}
                          >
                            <div
                              className="innerDropDown d-flex flex-wrap"
                              style={{ maxHeight: "200px", width: "320px" }}
                            >
                              {filterOptions.salary.map((element, index) => (
                                <p
                                  className="d-flex salary-range"
                                  onClick={() =>
                                    handleFilter("salaryRange", element.value)
                                  }
                                  key={index}
                                >
                                  <input
                                    className="styled-checkbox"
                                    type="checkbox"
                                  />
                                  <label className="ms-2">
                                    {element.textValue}({element.count})
                                  </label>
                                </p>
                              ))}
                            </div>
                          </div>
                          Salary
                          <div className="filterDropdownImg me-2"></div>
                        </span>
                        <span className="dropDownItem">
                          <div className="innerContainer">
                            <div className="innerDropDown">
                              {filterOptions.noticePeriod.map(
                                (element, index) => (
                                  <p
                                    onClick={() =>
                                      handleFilter(
                                        "noticePeriod",
                                        element.value
                                      )
                                    }
                                    key={index}
                                  >
                                    {element.textValue}
                                  </p>
                                )
                              )}
                            </div>
                          </div>
                          Notice Period
                          <div className="filterDropdownImg me-2"></div>
                        </span>
                        <span className="dropDownItem">
                          <div className="innerContainer">
                            <div className="innerDropDown">
                              {filterOptions.location.map((value, index) => (
                                <p
                                  onClick={() =>
                                    handleFilter("applicantLocation", value)
                                  }
                                  key={index}
                                >
                                  {value}
                                </p>
                              ))}
                            </div>
                          </div>
                          Location
                          <div className="filterDropdownImg me-2"></div>
                        </span>
                      </div>
                    )}
                  </span>
                  <span className="position-relative">
                    <img
                      className="ps-3"
                      src={filterIcon}
                      alt="filterIcon"
                      onClick={() => {
                        setFilterBy(!filterBy);
                      }}
                    />
                    {filterBy && (
                      <div className="applicantFilterByDropDown col-5">
                        <span
                          className=""
                          onClick={() => setSortType("experience")}
                        >
                          <img
                            className="me-3 ms-2"
                            src={experienceImg}
                            alt="experienceImg"
                          />
                          Experience
                        </span>
                        <span
                          className=" "
                          onClick={() => setSortType("alphabetic")}
                        >
                          <img
                            className="me-4 ms-3"
                            src={downArrow}
                            alt="downArrow"
                          />
                          Sort A to Z
                        </span>
                        <span className="" onClick={() => setSortType("ztoa")}>
                          <img
                            className="me-4 ms-3 "
                            src={upArrow}
                            alt="upArrow"
                          />
                          Sort Z to A
                        </span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            )}
            <hr className="mt-1" />
            {isLoading ? (
              <div className="align-items-center d-flex mb-3 mt-3 col-4 mx-auto">
                <LoadingShape className="loderH-30" />
              </div>
            ) : (
              <div className="align-items-center d-flex mb-3 mt-3">
                <div className="ApplicantPaginationNum col-md-4">
                  {jobs.shortlistedApplicants > 6 &&
                    `1 - ${Math.ceil(jobs.shortlistedApplicants / 6)} of{" "}
                  ${jobs.shortlistedApplicants} Candidates`}
                </div>
                <div className="applicantDataSearchInput text-left col-md-4">
                  <img className="me-2" src={ApplicantSearch} />
                  <input
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (["Enter"].includes(e.key)) {
                        searchApplicants(searchString);
                      }
                    }}
                    className="w-75 formInput"
                    placeholder="Search for participants"
                  />
                </div>
              </div>
            )}
            <div>
              <div className="pe-5 ps-5 pt-1">
                <div className="d-flex">
                  <div
                    className={`col-4 ${(!isLoading && applicantData.length > 0) || isLoading
                      ? "listContainerCandidates"
                      : ""
                      }`}
                  >
                    <CandidatesList
                      isLoading={isLoading}
                      type={"jobApplicants"}
                      applicantData={applicantData}
                      SetApplicantId={SetApplicantId}
                      applicantId={applicantId}
                      jobId={jobId}
                      applicantTabs={applicantTabs}
                      updateCandidatesList={(value) => SetApplicantData(value)}
                      updateJobs={(value) => setJobs(value)}
                      jobsData={jobs}
                      selectApplicant={selectApplicant}
                    />
                  </div>
                  {applicantId?._id ? (
                    <UserDetails
                      userInfo={applicantId}
                      selectedAssessment={selectedAssessment}
                      setSelectedAssessment={setSelectedAssessment}
                      assessmentModalData={assessmentModalData}
                      setAssessmentModalData={setAssessmentModalData}
                      isLoading={isLoading}
                      uId={applicantId?.applicantId?._id}
                      type="applicants"
                      ApplicantsView={ApplicantsView}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {!isLoading && applicantData.length === 0 && (
                  <div className="text-center noApplicants">
                    <img src={noApplicantsIcon} className="noApplicants_Icon" />
                    <p>Go ahead and share the Link on Linkedin</p>
                    <button
                      className="shareToLinkedIn"
                      onClick={() => {
                        window.open(
                          `https://www.linkedin.com/shareArticle?url=https://staging.machinehack.com/jobs/?currentJobId%${jobId}`
                        );
                      }}
                    >
                      Share <img src={shareIcon} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="pt-2">
            <SelectAssessment
              type="Assessment"
              showAssessmentType={selectedAssessment}
              selectedUserEmail={applicantId.email}
            />
          </div>
        )}
      </div>
    </div>
  );
}
