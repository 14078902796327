import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BootcampBackend } from "backend";
import Datetime from "react-datetime";
import moment from "moment";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import Cross from "../../../../assets/Icons/Interview/New/Cross.svg";
import createInterviewImg from "../../../../assets/Icons/Interview/New/createInterviewImg.svg";
import interviewLink from "../../../../assets/Icons/Interview/New/newLink.svg";
import Email from "../../../../assets/Icons/Interview/New/email.svg";
import linkedInIcon from "../../../../assets/Icons/Interview/LinkedIn_interview.svg";
import DateIcon from "../../../../assets/Icons/Interview/New/DateIcon.svg";
import dropdownArrow from "../../../../assets/Icons/Interview/New/dropdownArrow.svg";
import Clock from "../../../../assets/Icons/Interview/New/clock.svg";
import correctIcon from "../../../../assets/Icons/Interview/New/greenTick.svg";
import searchIcon from "../../../../assets/Icons/expandableSearch.svg";
import greenTick from "../../../../assets/Icons/Interview/New/greenTick.svg";
import pinkBgCalendar from "../../../../assets/Icons/Interview/New/pinkBgCalendar.svg";
import pinkBgClock from "../../../../assets/Icons/Interview/New/pinkBgClock.svg";
import { useHistory } from "react-router-dom";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#ffffff",
    color: "#3c3e4d",
    padding: 8,
    zIndex: 999,
    fontSize: "14px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    backgroundColor: "#ffffff",
    borderBottom: "solid 1px #e4e4e4",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: 0,
    fontSize: "14px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: () => ({
    color: "#9d9fa6",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
  }),
};

const CreateInterviewModal = ({ show, setShow, setIsEditable, isEditable, reportDetails }) => {
  const [instantLink, setInstantLink] = useState("");
  const [token, setInterviewToken] = useState("");
  const [modalInterview, setModalInterview] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(
    moment(new Date()).add("30", "minutes")
  );
  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [createRound, setCreateRound] = useState(false);
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [candidateEmailError, setCandidateEmailError] = useState("");
  const [editableItem, setIsEditableItem] = useState({});
  const [interviewRoundNo, setInterviewRoundNo] = useState(0);
  const [mailList, setMailList] = useState("email");
  const [showMsg, setShowMsg] = useState(false);
  const [isCopied, setIsCopied] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [Mhlist, setMhlist] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 2000,
  });
  const history = useHistory();
  async function generateScheduledLink() {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    // Generate the scheduled link
    await axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.url);
          setModalInterview("schedule");
          setInterviewToken(result.data.result.token);
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClose = () => {
    setShow(false);
    setMailList("email");
    setInterviewTitleError(false);
    setCandidateEmailError("");
    setIsCopied("");
    setModalInterview(0);
  };

  const handleMsgClose = () => {
    setShowMsg(false);
    setInterviewTitle("");
    setCandidateEmail("");
    history.push(`/interviews`);
  };

  const handlenextInterviewerChange = (value) => {
    setSelectedCandidate(value);
    setCandidateEmail(value.value);
  };

  const onCandidateEmailChange = (e) => {
    setCandidateEmail(e.target.value);
    setCandidateEmailError("");
  };

  // Disable the past dates START
  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };
  // Disable the past date END
  // Handle Date change Start
  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };
  // Handle Date change END

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
  };

  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };
  const copyLink = (data) => {
    setIsCopied(data);
    navigator.clipboard.writeText(instantLink);
  };
  function ValidateEmail(userEmail) {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (userEmail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
  const createInstantInterview = () => {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/instant`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.url);
          setInterviewToken(result.data.result.token);

          // Call the API
          axios
            .post(
              `${BootcampBackend}/interview/createInterview`,
              {
                interviewStartTime: new Date(),
                interviewType: "instant",
                interviewLink: result.data.result.url,
                interviewToken: result.data.result.token,
              },
              {
                headers: {
                  Authorization: JSON.parse(localStorage.getItem("jwt")).token,
                },
              }
            )
            .then((result) => {
              setModalInterview("instant");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Error in fetching the URL");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const createScheduledInterview = async () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();

    // Generate Interview Link & then call the API
    // Call the API
    if (
      interviewTitle === "" &&
      candidateEmail === "" &&
      !isEditable &&
      !createRound
    ) {
      setInterviewTitleError(true);
      setCandidateEmailError("This field is required");
      return;
    } else if (!ValidateEmail(candidateEmail) && !isEditable && !createRound) {
      setCandidateEmailError("Enter valid email");
    } else if (isEditable) {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        email: candidateEmail,
        interviewTitle: interviewTitle,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      await axios
        .put(
          `${BootcampBackend}/interview/updateInterview/` +
            editableItem._id +
            "/" +
            editableItem.interviewRounds._id,
          options,
          headers
        )
        .then((result) => {
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (createRound) {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        currentRound: interviewRoundNo,
        interviewlink: instantLink,
        interviewToken: token,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      await axios
        .post(
          `${BootcampBackend}/interview/createNewRound/` + editableItem._id,
          options,
          headers
        )
        .then((result) => {
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post(
          `${BootcampBackend}/interview/createInterview`,
          {
            interviewStartTime: interviewStartTime,
            interviewType: "scheduled",
            interviewLink: instantLink,
            interviewTitle: interviewTitle,
            interviewEndTime: interviewEndTime,
            email: candidateEmail,
            interviewToken: token,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((result) => {
          handleClose();
          setShowMsg(true);
          // setModalInterview("instant");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <img
              src={Cross}
              onClick={handleClose}
              className="img-fluid cursor-pointer"
              style={{ width: "15px", height: "15px" }}
              alt="logo"
              loading="lazy"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalInterview === 0 && (
            <div className="text-center">
              <h4 className="modalHeading">Create an Interview</h4>
              <img
                src={createInterviewImg}
                className="img-fluid"
                alt="logo"
                loading="lazy"
              />
              <button
                className="mt-4 mb-3 instantInterview"
                onClick={createInstantInterview}
              >
                Create an Instant Interview
              </button>
              <p className="orText">(or)</p>
              <button
                className="mt-3 mb-4 scheduleInterview"
                onClick={() => generateScheduledLink()}
              >
                Schedule an Interview
              </button>
            </div>
          )}
          {modalInterview === "instant" && (
            <div className="text-center instant">
              <h4 className="modalHeading">Share your Interview link</h4>
              <div className="copyLinkBox">
                <img
                  src={interviewLink}
                  className="linkIconModal img-fluid"
                  alt="logo"
                  loading="lazy"
                />
                <input
                  className="copyLink"
                  value={instantLink}
                  readOnly={isEditable || createRound}
                  disabled
                />
                <button
                  className={`copyBtn text-center ${
                    isCopied !== "schedule" ? "" : "greenBackground"
                  }`}
                  onClick={() => copyLink("schedule")}
                >
                  <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
                </button>
              </div>
              <p className="my-4 mb-4 py-3">
                Anyone with the link can attend the interview
              </p>
              <div className="shareInterview mb-3">
                <a href={`mailto:""?body=${instantLink}`} target="_blank">
                  <img
                    className="mt-0 mx-3 shareInterviewEmail img-fluid"
                    src={Email}
                    alt="logo"
                    loading="lazy"
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?url=${instantLink}`}
                  target="_blank"
                >
                  <img
                    className="mt-0 mx-3 shareInterviewWhatsapp img-fluid"
                    src={linkedInIcon}
                    alt="logo"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          )}
          {modalInterview === "schedule" && (
            <div className="text-center schedule">
              <h4 className="modalHeading">Schedule an Interview</h4>
              <div className="copyLinkBox">
                <img
                  src={interviewLink}
                  className="linkIconModal img-fluid"
                  alt="logo"
                  loading="lazy"
                />
                <input
                  className="copyLink"
                  value={instantLink}
                  readOnly={isEditable || createRound}
                  disabled
                />
                <button
                  className={`copyBtn text-center ${
                    isCopied !== "schedule" ? "" : "greenBackground"
                  }`}
                  onClick={() => copyLink("schedule")}
                >
                  <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
                </button>
              </div>

              <div className="mt-3 schedulingDiv">
                <h6>Interview Title</h6>
                <input
                  className={`scheduleInputs p-2  ${
                    createRound ? "disabled-class" : ""
                  }`}
                  placeholder="Enter Interview Title"
                  onChange={oninterviewTitleChange}
                  value={interviewTitle ||reportDetails?.assessmentId?.title}
                  readOnly={createRound}
                />
                {interviewTitleError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    This Field is required
                  </p>
                )}
                {/* Date Picker Here START */}

                <h6 className="">Select Date</h6>
                <div className="datepicker">
                  <img
                    src={DateIcon}
                    className="img-fluid dateIconImg "
                    alt="logo"
                    loading="lazy"
                  />

                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    isValidDate={valid}
                    dateFormat="DD MMM YYYY"
                    value={selectedDate}
                  />
                  <img
                    src={dropdownArrow}
                    className="img-fluid dropdownArrowImg"
                    alt="logo"
                    loading="lazy"
                  />
                  {/* Date Picker Here End */}
                </div>

                {/* <ComboDatePicker/> */}

                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">Start time</label>
                    <div className="timePicker allInterviewTimePicker">
                      <img
                        src={Clock}
                        className="interviewClock img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                      <TimePicker
                        popupClassName="allInterviewScheduleTimePicker"
                        value={startTime}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleTimeChange}
                        // onOpen={handleTimeClick}
                        defaultValue={moment()}
                        clearText=""
                        clearIcon=""
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">End time</label>
                    <div className="timePicker">
                      <img src={Clock} className="interviewClock" />
                      <TimePicker
                        value={endTime}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleEndTimeChange}
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>

                {!createRound && (
                  <div className="mt-4 mb-2 d-flex emailSelection">
                    <div className="d-flex w-50 align-items-center">
                      <input
                        id="email"
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("email");
                        }}
                        checked={mailList === "email"}
                      />
                      <span>Email ID</span>
                    </div>
                    <div className="d-flex w-50 align-items-center">
                      <input
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("MHList");
                        }}
                        checked={mailList === "MHList"}
                      />
                      <span>From MH List</span>
                    </div>
                  </div>
                )}
                {(mailList === "email" || createRound) && (
                  <>
                    <div className="postion-relative">
                      <h6 className={`mb-1 ${createRound ? "mt-4" : ""}`}>
                        Candidate Email
                      </h6>
                      <input
                        className={`scheduleInputs mt-2 p-2  ${
                          createRound ? "disabled-class" : ""
                        }`}
                        onChange={onCandidateEmailChange}
                        value={candidateEmail || reportDetails?.email}
                        placeholder="Enter Candidate Email"
                        readOnly={createRound}
                      />
                      {ValidateEmail(candidateEmail) && (
                        <img src={correctIcon} className="verified-icon" />
                      )}
                    </div>
                    {candidateEmailError !== "" && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {candidateEmailError}
                      </p>
                    )}
                  </>
                )}
                {mailList === "MHList" && (
                  <div style={{ color: "#e4e4e4" }}>
                    <h6>Candidate Name</h6>
                    <div className="mt-2 MhUserSearch">
                      <Select
                        styles={customStyles}
                        value={selectedCandidate}
                        onChange={handlenextInterviewerChange}
                        options={Mhlist}
                        placeholder="Search for MH users"
                      />
                      <img src={searchIcon} className="searchIconModal" />
                    </div>
                  </div>
                )}
              </div>
              <div className="px-3">
                {interviewTitle === "" || candidateEmail === "" && reportDetails?.email  ? (
                  <button className="my-4 scheduleInterviewlg greyScheduleBtn ">
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                ) : (
                  <button
                    className="my-4 scheduleInterviewlg"
                    // onClick={() => setModalInterview("")}
                    onClick={() => createScheduledInterview()}
                  >
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showMsg}
        onHide={handleMsgClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <img
              src={Cross}
              onClick={handleMsgClose}
              className="img-fluid ms-auto cursor-pointer"
              style={{ width: "15px", height: "15px" }}
              alt="logo"
              loading="lazy"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src={greenTick}
              className="img-fluid mx-auto"
              alt="logo"
              loading="lazy"
            />
            <h4 className="modalHeading mt-3">Interview Scheduled</h4>
            <div className="scheduledInterviewBoxMain">
              <div className=" d-flex justify-content-between scheduledInterviewBox">
                <div className="w-50 text-left">
                  <p className="heading9">User</p>
                  <p className="subHeading9 ">{candidateEmail}</p>
                </div>
                <div className="w-50 text-left">
                  <p className="heading9">Interview Title</p>
                  <p className="subHeading9 ">{interviewTitle}</p>
                </div>
              </div>

              <div className="mb-4">
                <h6 className="heading9 my-4">
                  <img
                    src={interviewLink}
                    className="me-3 img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  Interview Link
                </h6>
                <a
                  className="linkStyle"
                  onClick={() => {
                    copyLink("schedule");
                    setToastMessage({
                      variant: "success",
                      show: true,
                      message: "Link copied !",
                      duration: 500,
                    });
                  }}
                >
                  {instantLink.slice(0, 50) + "..."}
                </a>
              </div>
              <hr />
              <div className="d-flex justify-content-around p-4 position-relative">
                <div className="d-flex justify-content-center timesInterview align-items-center">
                  <img
                    src={pinkBgCalendar}
                    className="img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  <span className="InterviewTimings">
                    {moment(startTime).format("D MMM YYYY")}
                  </span>
                </div>
                <div className="verticalLine"></div>
                <div>
                  <img
                    src={pinkBgClock}
                    className="me-3 img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  <span className="InterviewTimings">
                    {moment(startTime).format("h:mm  a")} {"-"}{" "}
                    {moment(endTime).format("h:mm  a")}
                  </span>
                </div>
              </div>
            </div>
            <p className="msgInfo">
              Please check your email.
              <br />
              We have a send a confirmation mail as well.
            </p>
            <button
              className="scheduleInterviewlg w-75 mx-auto mb-4"
              onClick={handleMsgClose}
            >
              Go to your Schedule
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateInterviewModal;
