import React, { useState, useCallback, useEffect, useRef } from "react";
import "./SetupOrganization.css";
import { Modal, ModalFooter } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import imageCompression from "browser-image-compression";
import FormData from "form-data";
import ErrorIcon from "../../../../../assets/Icons/Settings/errorState.svg";
import DefaultLogo from "../../../../../assets/Logos/Frame.png";
import modalSubmitButton from "../../../../../assets/Icons/Settings/modalSubmitArrow.svg";
import axios from "axios";
import { BootcampBackend } from "../../../../../backend";
import { getUserRole } from "../../../../../helper/user";
import sitesLogo from "../../../../../assets/Icons/Settings/sitesLogo.svg";
import upload from "../../../../../assets/Icons/Settings/upload.svg";
import lock from "../../../../../assets/Icons/Settings/lock.svg";
import globe from "../../../../../assets/Icons/Settings/globe.svg";
import linkedIn from "../../../../../assets/Icons/Settings/linkedIn.svg";
import github from "../../../../../assets/Icons/Settings/github.svg";
import twitter from "../../../../../assets/Icons/Settings/twitter.svg";
import cameraIcon from "../../../../../assets/Icons/Settings/cameraIcon.svg";
import Select from "react-select";
import EnterpriseModal from "./EnterpriseModal";
import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import { LoadingShape } from "react-rainbow-components";
import { useSidebar } from "helper/SideBarContext";
import { useDispatch, useSelector } from "react-redux";
import ToastMessages from "pages/PrivatePages/Assessment/components/ToastMessages";
import { setCurrentUser } from "store/reducers/authSlice";
const customStyles = {
  container: (base, state) => ({
    ...base,
    // minWidth: "77.7%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#F2F3F4" : "#ffffff",
    color: "#3c3e4d",
    padding: 18,
  }),
  control: (base, state) => ({
    ...base,
    height: 42,
    minHeight: 42,
    padding: "0px 5px",
    backgroundColor: state.isDisabled ? "#e9ecef" : "#ffffff",
    borderColor: state.isDisabled ? "#f0f0f4" : "#e2e2e9",
    borderStyle: "solid",
    borderWidth: state.isFocused ? 2 : 1,
    borderRadius: 50,
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    fontWeight: 500,
    fontSize: "12px",
    color: "#b1b2b8",
    fontFamily: "Inter",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    color: "#ACADAD",
    paddingRight: "15px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = state.isDisabled ? "#121214" : "#666666";
    const fontSize = 12;
    const fontWeight = 500;

    return { ...provided, opacity, transition, color, fontSize, fontWeight };
  },
  valueContainer: (base, state) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    justifyItemContent: "center",
    color: "#b1b2b8",
  }),
};

export default function SetupOrganization() {
  const { isSidebarCollapsed } = useSidebar();
  const [showModal, setShowModal] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const CompanySize = [
    { value: "1 - 10 ", label: "1 - 10 " },
    { value: "11 - 50 ", label: "11 - 50 " },
    { value: "51 - 200 ", label: "51 - 200 " },
    { value: "201 - 500 ", label: "201 - 500 " },
    { value: "501 - 1000 ", label: "501 - 1000 " },
    { value: "1000 - 5000 ", label: "1000 - 5000 " },
    { value: "5000 - 10000 ", label: "5000 - 10000 " },
    { value: "10001+ ", label: "10001+ " },
  ];
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [companySizeState, setCompanySizeState] = useState("");
  const [image, setImage] = useState(DefaultLogo);
  const [selectedFile, setSelectedFile] = useState();
  const [compressedFile, setCompressedFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const organizationDetails = useSelector(
    (state) => state.organization.organizationDetails
  );
  const user = useSelector((state) => state.auth.user);
  const inputRef = useRef();

  const [organizationData, setOrganizationData] = useState({
    companyName: "",
    emailId: "",
    employerSize: "",
    address1: "",
    address2: "",
    description: "",
    website: "",
    linkedin: "",
    github: "",
    twitter: "",
    logo: null,
  });
  const [initialOrganizationData, setIntialOrganizationData] = useState({
    companyName: "",
    emailId: "",
    address1: "",
    address2: "",
    description: "",
    website: "",
    linkedin: "",
    github: "",
    twitter: "",
    logo: null,
  });

  const [organizationError, setOrganizationError] = useState({
    emailId: false,
    companyName: false,
  });

  const [submitError, setSubmitError] = useState({
    emailId: false,
    companyName: false,
  });

  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const dispatch = useDispatch();

  async function handleUpdatePassword(event) {
    try {
      event.preventDefault();
      await axios
        .post(`${BootcampBackend}/iamUser/resetPasswordRequest`, {
          emailId: user?.emailId,
        })
        .then((res) => {
          if (res.data.status.code === 201 || res.data.status.code === 200) {
            setToastMessage({
              variant: "success",
              show: true,
              message: `An email with the password reset link has been sent to ${user?.emailId}`,
              duration: 3000,
            });
            setTimeout(() => {
              localStorage.removeItem("jwt");
              localStorage.removeItem("prevPath");
              localStorage.removeItem("loginTime");
              dispatch(setCurrentUser({}));
            }, 4000);
          }
        })
        .catch((err) => {
          setToastMessage({
            variant: "error",
            show: true,
            message: "Requested Email id does not exist",
            duration: 3000,
          });
        });
    } catch (err) {
      console.log(err.response);
      setToastMessage({
        variant: "error",
        show: true,
        message: "Internal Server Error! Please try again later",
        duration: 3000,
      });
    }
  }

  const handleShow = () => {
    setSelectedFile(null);
    setZoom(1);
    setRotation(0);
    setShow(true);
  };

  const handleClose = () => {
    setSelectedFile(null);
    setImage(organizationData.logo);
    setShow(false);
    setZoom(1);
    setRotation(0);
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    if (
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "image/jpeg"
    ) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setImage(objectUrl);
    setSaveButton(true);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedFile.name,
        image,
        croppedAreaPixels,
        rotation
      );
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 200,
        useWebWorker: true,
      };

      const compressedImage = await imageCompression(croppedImage, options);
      setCompressedFile(compressedImage);

      const objectUrl = URL.createObjectURL(compressedImage);
      setImage(objectUrl);
      setShow(false);
    } catch (e) {
      //setErrorFlag(true);
      console.error(e);
    }
  }, [image, selectedFile, croppedAreaPixels, rotation]);

  //  API CALL
  async function uploadImage() {
    try {
      if (compressedFile) {
        const formData = new FormData();
        formData.append("files", compressedFile);
        const res = await axios.post(
          `${BootcampBackend}/enterpriseMediaUpload`,
          formData,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        );

        setShow(false);
        return res;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err.response);
    }
  }

  useEffect(() => {
    if (organizationDetails?._id) {
      const {
        adressLine1,
        adressLine2,
        companyName,
        contactEmail,
        description,
        employerSize,
        github,
        linkedin,
        twitter,
        website,
        logo,
      } = organizationDetails;

      setImage(logo ? logo : DefaultLogo);
      setOrganizationData({
        companyName: companyName ? companyName : "",
        emailId: contactEmail ? contactEmail : "",
        employerSize: employerSize ? employerSize : "",
        address1: adressLine1 ? adressLine1 : "",
        address2: adressLine2 ? adressLine2 : "",
        description: description ? description : "",
        website: website ? website : "",
        linkedin: linkedin ? linkedin : "",
        github: github ? github : "",
        twitter: twitter ? twitter : "",
        logo: logo ? logo : DefaultLogo,
      });
      setIntialOrganizationData({
        companyName: companyName ? companyName : "",
        emailId: contactEmail ? contactEmail : "",
        address1: adressLine1 ? adressLine1 : "",
        address2: adressLine2 ? adressLine2 : "",
        description: description ? description : "",
        website: website ? website : "",
        linkedin: linkedin ? linkedin : "",
        github: github ? github : "",
        twitter: twitter ? twitter : "",
        logo: logo ? logo : DefaultLogo,
      });
      setCompanySizeState({
        value: employerSize,
        label: employerSize,
      });
      setShowLoader(false);
    }
  }, [organizationDetails?._id]);

  function handleChange(event) {
    setOrganizationError({
      email: false,
      name: false,
    });
    setSaveButton(true);

    const { name, value } = event.target;
    setOrganizationData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function handleCompanySize(value) {
    setCompanySizeState(value);
    setSaveButton(true);
  }
  async function handleSubmit(event) {
    try {
      //setLoader(true);
      //event.preventDefault();

      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regName.test(organizationData.companyName)) {
        setOrganizationError({
          companyName: true,
        });
        return;
      }
      if (!regEmail.test(organizationData.emailId?.trim())) {
        setOrganizationError({
          emailId: true,
        });
        return;
      }
      setOrganizationError({
        email: false,
        name: false,
      });

      uploadImage()
        .then(async (res) => {
          console.log(res);
          const formData = {
            companyName: organizationData.companyName,
            adressLine1: organizationData.address1,
            adressLine2: organizationData.address2,
            employerSize: companySizeState.value || "",
            contactEmail: organizationData.emailId?.trim(),
            description: organizationData.description,
            website: organizationData.website,
            linkedin: organizationData.linkedin,
            github: organizationData.github,
            twitter: organizationData.twitter,
            logo: organizationData.logo,
          };
          if (res) {
            formData.logo = res.data.result.data[0].s3_url;
          }
          const submitRes = await axios.put(
            `${BootcampBackend}/organization_profile/edit`,
            formData,
            {
              headers: {
                Authorization: JSON.parse(localStorage.getItem("jwt")).token,
              },
            }
          );
          // setLoader(false);

          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(err.response);
          // setLoader(false);
          if (
            err.response.data.result.message === "Company name already exist"
          ) {
            setSubmitError({
              companyName: true,
            });
          }
          if (err.response.data.result.message === "Email already exist") {
            setSubmitError({
              email: true,
            });
          }
        });
    } catch (err) {
      console.log(err.response);
    }
  }

  return (
    <>
      <Header heading="Settings" />
      <Navbar
        showApiTab={organizationDetails?.apiKey}
      />
      <div
        className={`position-relative ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="enterpriseSettingFormField mt-5 pt-3">
          <div className="d-flex mb-3">
            <div className="col-md-8 offset-md-2 d-flex">
              <div className="align-items-center  d-flex enterpriseCompanyLogoContainer col-2">
                <div>
                  {organizationData?.logo ? (
                    <img
                      className="crop-container-logo img-fluid"
                      src={image}
                      alt="logo"
                    />
                  ) : showLoader ? (
                    <LoadingShape
                      variant="image"
                      shape="circle"
                      style={{ width: "30%", height: "30px" }}
                    />
                  ) : (
                    <img
                      className="crop-container img-fluid"
                      alt="img"
                      src={upload}
                    />
                  )}
                </div>
              </div>
              <div className="col-6 mt-4">
                <div className="enterpriseSettingCompanyText mb-3">
                  {showLoader ? (
                    <LoadingShape style={{ width: "40px", height: "10px" }} />
                  ) : (
                    <span>Company Profile</span>
                  )}
                </div>
                {showLoader ? (
                  <LoadingShape style={{ width: "30px", height: "30px" }} />
                ) : (
                  <div>
                    <button
                      onClick={() => handleShow()}
                      disabled={getUserRole() === "admin" ? true : false}
                      className="enterpriseSettingUploadButton "
                    >
                      Upload Logo
                    </button>
                  </div>
                )}{" "}
                <Modal
                  centered={true}
                  className="enterpriseSettingProfileModal"
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton={true}>
                    <Modal.Title>Change Company Profile Picture</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedFile ? (
                      <>
                        <div className="crop-container">
                          <Cropper
                            image={image}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1}
                            cropSize={{ width: 300, height: 300 }}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            restrictPosition={false}
                          />
                        </div>
                        <div className="">
                          <div className="controls d-flex justify-content-between">
                            <div className="col-5">
                              <label
                                htmlFor="customRange1"
                                className="enterpriseModalStepper"
                              >
                                Rotate
                              </label>
                              <input
                                type="range"
                                className="custom-range mt-4"
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                onChange={(e) => setRotation(e.target.value)}
                              />
                            </div>
                            <div className="col-5">
                              <label
                                htmlFor="customRange1"
                                className="enterpriseModalStepper"
                              >
                                Zoom
                              </label>
                              <input
                                type="range"
                                className="custom-range mt-4"
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(e) => setZoom(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="text-center pt-5">
                            <button
                              disabled={
                                getUserRole() === "admin" ? true : false
                              }
                              className="settingModalButton"
                              onClick={getCroppedImage}
                            >
                              Submit <img src={modalSubmitButton} />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="align-items-center d-flex flex-column">
                        <div className="modalCamera-icon position-relative cursor-pointer">
                          <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onSelectFile}
                            ref={inputRef}
                          />
                          <img
                            src={cameraIcon}
                            onClick={() => inputRef.current.click()}
                          />
                        </div>
                        <span className="modalUploadImage">
                          Upload a image of 500 X 500
                        </span>
                        <span className="modalFormat">
                          Formats Allowed .jpg or .png{" "}
                        </span>
                      </div>
                    )}
                  </Modal.Body>

                  <ModalFooter className="mt-5 pt-4"></ModalFooter>
                </Modal>
              </div>
              {saveButton ? (
                <div className="align-items-end d-flex ms-auto">
                  <button
                    disabled={getUserRole() === "admin" ? true : false}
                    className="enterpriseSettingCancelButton"
                    onClick={() => {
                      setSaveButton(false);
                      setOrganizationData({
                        companyName: initialOrganizationData.companyName,
                        emailId: initialOrganizationData.emailId,
                        employerSize: initialOrganizationData.employerSize,
                        address1: initialOrganizationData.address1,
                        address2: initialOrganizationData.address2,
                        description: initialOrganizationData.description,
                        website: initialOrganizationData.website,
                        linkedin: initialOrganizationData.linkedin,
                        github: initialOrganizationData.github,
                        twitter: initialOrganizationData.twitter,
                        logo: initialOrganizationData.logo,
                      });
                      setCompanySizeState({
                        value: organizationData.employerSize,
                        label: organizationData.employerSize,
                      });
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={getUserRole() === "admin" ? true : false}
                    className="enterpriseSettingSavaButton"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="sectionDivider"></div>
          <div className="forScroll">
            <div className="enterpriseSettingCompanyData companyDetails col-md-8 offset-md-2 mt-3">
              <div className="align-items-center d-flex mb-2">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "100px",
                      height: "40px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-2 companyDetailsTags enterpriseCompanyLogoContainer">
                    Company Name
                  </span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "40px" }} />
                ) : (
                  <input
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    value={organizationData.companyName}
                    disabled={getUserRole() === "admin" ? true : false}
                    className={
                      organizationError.companyName
                        ? "formInput enterpriseCompanyNameInputField"
                        : "formInput"
                    }
                    placeholder="Eg: Abc Company"
                  />
                )}
              </div>

              <div className="align-items-center d-flex">
                <div className="col-2 pe-5"></div>
                <div className="d-flex ps-5">
                  {(organizationError.companyName ||
                    submitError.companyName) && (
                    <img className="pe-3 mb-2" src={ErrorIcon} alt="error" />
                  )}

                  {submitError.companyName && (
                    <div className="enterpriseSettingErrorMessage">
                      Company already exists
                    </div>
                  )}
                  {organizationError.companyName && (
                    <div className="enterpriseSettingErrorMessage mb-2 ">
                      Invalid company name
                    </div>
                  )}
                </div>
              </div>
              <div className="align-items-center d-flex">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "100px",
                      height: "40px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-2 companyDetailsTags enterpriseCompanyLogoContainer">
                    Company Email
                  </span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "40px" }} />
                ) : (
                  <input
                    type="text"
                    name="emailId"
                    value={organizationData.emailId}
                    disabled={getUserRole() === "admin" ? true : false}
                    onChange={handleChange}
                    className={
                      organizationError.emailId
                        ? "enterpriseCompanyNameInputField formInput"
                        : "formInput"
                    }
                    placeholder="rakesh@applieddatatscience.ai"
                  />
                )}
              </div>
              <div className="align-items-center d-flex mb-2">
                <div className="col-2"></div>
                <div className="d-flex ps-5">
                  {(organizationError.emailId || submitError.email) && (
                    <img
                      className="pe-3 mb-2 mt-1"
                      src={ErrorIcon}
                      alt="error"
                    />
                  )}
                  {submitError.emailId && (
                    <div className="enterpriseSettingErrorMessage">
                      Email already registered
                    </div>
                  )}
                  {organizationError.emailId && (
                    <div className="enterpriseSettingErrorMessage mb-2 mt-1">
                      Invalid email
                    </div>
                  )}
                </div>
              </div>
              <div className="align-items-center d-flex mb-2">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "100px",
                      height: "40px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-2 companyDetailsTags enterpriseCompanyLogoContainer">
                    Company Size
                  </span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "40px" }} />
                ) : (
                  <Select
                    name="employerSize"
                    isDisabled={getUserRole() === "admin" ? true : false}
                    onChange={handleCompanySize}
                    placeholder="Select the Team Size"
                    value={companySizeState}
                    styles={customStyles}
                    options={CompanySize}
                    isSearchable={false}
                    isClearable={false}
                    className="w-100"
                  />
                )}
              </div>
              <div className="align-items-center d-flex mb-2">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "100px",
                      height: "40px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-2 companyDetailsTags enterpriseCompanyLogoContainer">
                    Address Line 1
                  </span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "40px" }} />
                ) : (
                  <input
                    type="text"
                    name="address1"
                    disabled={getUserRole() === "admin" ? true : false}
                    value={organizationData.address1}
                    onChange={handleChange}
                    className="formInput"
                    placeholder="Address Line 1"
                  />
                )}
              </div>

              <div className="align-items-center d-flex mb-3">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "100px",
                      height: "40px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-2 companyDetailsTags enterpriseCompanyLogoContainer">
                    Address Line 2
                  </span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "40px" }} />
                ) : (
                  <input
                    type="text"
                    name="address2"
                    disabled={getUserRole() === "admin" ? true : false}
                    value={organizationData.address2}
                    onChange={handleChange}
                    className="formInput"
                    placeholder="Address Line 2"
                  />
                )}
              </div>
              <div className="mb-5">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "10%",
                      height: "30px",
                      marginBottom: "10px",
                    }}
                  />
                ) : (
                  <span className="companyDetailsTags">About Company</span>
                )}
                {showLoader ? (
                  <LoadingShape style={{ width: "100%", height: "150px" }} />
                ) : (
                  <div>
                    <textarea
                      type="text"
                      name="description"
                      disabled={getUserRole() === "admin" ? true : false}
                      value={organizationData?.description}
                      onChange={handleChange}
                      className="enterpriseSettingCompanyDataTextArea mt-4"
                      placeholder="Write a short brief about company"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sectionDivider"></div>
            <div className="col-md-12 offset-md-2 resetPasswordContainer">
              <div className="align-items-end d-flex mb-5 mt-5">
                <div className="align-items-end col-6 d-flex me-5">
                  {showLoader ? (
                    <LoadingShape
                      style={{ width: "20%", height: "50px" }}
                      variant="image"
                    />
                  ) : (
                    <div>
                      <img className="img-fluid" src={lock} />
                    </div>
                  )}

                  {showLoader ? (
                    <LoadingShape
                      style={{
                        marginLeft: "60px",
                        width: "100%",
                        height: "50px",
                      }}
                    />
                  ) : (
                    <div className="d-flex flex-column ms-3">
                      <span className="enterpriseSettingSecurityText mb-1">
                        Security
                      </span>
                      <span className="enterpriseSettingResetPasswordText">
                        Wish to change password? Click on Update Password
                        button.
                      </span>
                    </div>
                  )}
                </div>
                {showLoader ? (
                  <LoadingShape
                    style={{
                      marginLeft: "35px",
                      width: "10%",
                      height: "50px",
                    }}
                  />
                ) : (
                  <button
                    className={`enterpriseSettingResetButton`}
                    onClick={handleUpdatePassword}
                  >
                    Reset Password
                  </button>
                )}
              </div>
            </div>
            <div className="sectionDivider"></div>
            <div className="offset-md-2">
              <div className="d-flex align-items-center mb-5 mt-5">
                {showLoader ? (
                  <LoadingShape
                    variant="image"
                    style={{ width: "10%", height: "30px" }}
                  />
                ) : (
                  <div className="col-1 ">
                    <img className="img-fluid" src={sitesLogo} />
                  </div>
                )}

                <div className="col-6 d-flex flex-column ps-5">
                  {showLoader ? (
                    Array.from(
                      {
                        length: 2,
                      },
                      (_, i) => (
                        <LoadingShape
                          key={i}
                          style={{
                            height: "14px",
                            width: "30%",
                            marginTop: "20px",
                          }}
                        />
                      )
                    )
                  ) : (
                    <>
                      <span className="enterpriseSettingSocialProfileText mb-1">
                        Social Profiles
                      </span>
                      <span className="enterpriseSettingResetPasswordText">
                        Update your Social Profile Links
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="align-items-center d-flex mb-4">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "10%",
                      height: "30px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-1 socialProfileTags">Website</span>
                )}

                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "70%",
                      height: "30px",
                    }}
                  />
                ) : (
                  <div className="align-items-center d-flex enterpriseHTTPTags col-8">
                    <span className="">https://</span>
                    <input
                      type="text"
                      name="website"
                      disabled={getUserRole() === "admin" ? true : false}
                      value={organizationData.website}
                      onChange={handleChange}
                      style={{ border: "none" }}
                    />
                    <img className="float-end pe-2" src={globe} />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-baseline">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "10%",
                      height: "30px",
                      marginRight: "30px",
                      marginBottom: "30px",
                    }}
                  />
                ) : (
                  <span className="col-1 socialProfileTags">LinkedIn</span>
                )}

                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "70%",
                      height: "30px",
                      marginBottom: "30px",
                    }}
                  />
                ) : (
                  <div className="align-items-center d-flex mb-4 enterpriseHTTPTags col-8">
                    <span className="">https://linkedin.com/in/</span>
                    <input
                      type="text"
                      name="linkedin"
                      disabled={getUserRole() === "admin" ? true : false}
                      value={organizationData.linkedin}
                      onChange={handleChange}
                      style={{ border: "none" }}
                    />
                    <img className="float-end pe-2" src={linkedIn} />
                  </div>
                )}
              </div>
              <div className="d-flex mb-4 align-items-center">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "10%",
                      height: "30px",
                      marginRight: "30px",
                      marginBottom: "30px",
                    }}
                  />
                ) : (
                  <span className="col-1 socialProfileTags">Github</span>
                )}
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "70%",
                      height: "30px",
                      marginBottom: "30px",
                    }}
                  />
                ) : (
                  <div className="align-items-center d-flex enterpriseHTTPTags col-8">
                    <span className="">https://github.com/</span>
                    <input
                      disabled={getUserRole() === "admin" ? true : false}
                      type="text"
                      name="github"
                      value={organizationData?.github}
                      onChange={handleChange}
                      style={{ border: "none" }}
                    />
                    <img className="float-end pe-2" src={github} />
                  </div>
                )}
              </div>
              <div className="d-flex  align-items-center mb-5">
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "10%",
                      height: "30px",
                      marginRight: "30px",
                    }}
                  />
                ) : (
                  <span className="col-1 socialProfileTags">Twitter</span>
                )}
                {showLoader ? (
                  <LoadingShape
                    style={{
                      width: "70%",
                      height: "30px",
                    }}
                  />
                ) : (
                  <div className="align-items-center d-flex enterpriseHTTPTags col-8">
                    <span className="">https://twitter.com/</span>
                    <input
                      type="text"
                      name="twitter"
                      disabled={getUserRole() === "admin" ? true : false}
                      value={organizationData.twitter}
                      onChange={handleChange}
                      style={{ border: "none" }}
                    />
                    <img className="float-end pe-2" src={twitter} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <EnterpriseModal handleClick={setShowModal} showModal={showModal} />
        </div>
      </div>
      {toastMessage.show && (
        <ToastMessages
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
    </>
  );
}
