/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory,useLocation } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {

	const history = useHistory();
	const location = useLocation();

	const goToLogin = () =>{

		localStorage.setItem("prevPath",location.pathname);

		history.push('/login')
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				localStorage.getItem("jwt") ? (
					<Component {...props} />
				) : (
					<>
						{goToLogin()}
					</>
					// <Redirect
					// 	to={{
					// 		pathname: "/login",
					// 		state: {
					// 			from: props.location,
					// 		},
					// 	}}
					// />
				)
			}
		/>
	);
}

export default PrivateRoute;
