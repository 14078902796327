import React, { useEffect, useState } from "react";
import { UnControlled as CodeMirrorEditor } from "react-codemirror2";
import { CodemirrorBinding } from "y-codemirror";
import * as Y from "yjs";
import { WebrtcProvider } from "y-webrtc";
import RandomColor from "randomcolor";
import { WebRTC_URL } from "backend";
import { isAuthenticated } from "helper/user";
import { ydoc } from "SharedMethods/CommenMethods";

const InterviewEditor = ({
  setListofTabs,
  Listoftabs,
  setTabs,
  tabs,
  codeValue,
  interviewDataMemo,
  tokenData,
  setCodeData
}) => {
  const onSourcecodeChange = (value, id) => {
    setListofTabs(
      Listoftabs.filter((x) => {
        if (x.id === id) {
          x.panelComponent.data = value;
        }
        return Listoftabs;
      })
    );
    setTabs((prev) => ({
      ...prev,
      panelComponent: {
        data: value,
      },
    }));
  };
  const [EditorRef, setEditorRef] = useState(null);

  const handleEditorDidMount = (editor) => {
    setEditorRef(editor);
  };
  let provider = null;
  const BindingEditor = () => {
    if (EditorRef && interviewDataMemo?.interviewRoundId?._id) {
      try {
        const roomName = `${interviewDataMemo?.interviewRoundId?._id}-editor-room-${tabs.id}`;
        provider = new WebrtcProvider(
          roomName,
          ydoc,
          {
            signaling: [`${WebRTC_URL}`],
          }
        );
        const yText = ydoc.getText("codemirror");

        const yUndoManager = new Y.UndoManager(yText);

        const awareness = provider.awareness; //awareness is what makes other user aware about your actions

        const color = RandomColor(); //Provied any random color to be used for each user

        tokenData?.companyId === isAuthenticated().eid
          ? awareness.setLocalStateField("Interviewer", {
              name: interviewDataMemo?.interviewerId.firstName.slice(0, 1),
              color: color,
            })
          : awareness.setLocalStateField("Interviewee", {
              name:
                interviewDataMemo?.interviewId?.candidateName?.slice(0, 1) ||
                interviewDataMemo?.interviewId?.candidateEmail
                  .toString()
                  ?.slice(0, 1) ||
                "A",
              color: color,
            });

        const getBinding = new CodemirrorBinding(yText, EditorRef, awareness, {
          yUndoManager,
        });
      } catch (err) {
        console.log(err);
        alert("error in collaborating try refreshing or come back later !");
      }
    }
  };

  useEffect(() => {
    BindingEditor();
    return () => {
      if (provider) {
        provider.disconnect(); //We destroy doc we created and disconnect
        ydoc.destroy(); //the provider to stop propagting changes if user leaves editor
      }
    };
  }, [interviewDataMemo?.interviewRoundId?._id, EditorRef]);
  return (
    <CodeMirrorEditor
      onChange={(editor, data, value) => {
        onSourcecodeChange(value, tabs.id);
      }}
      autoScroll
      options={{
        mode: "text/x-python",
        theme: "monokai",
        lineWrapping: true,
        smartIndent: true,
        lineNumbers: true,
        foldGutter: true,
        tabSize: 2,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        autoCloseTags: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        extraKeys: {
          "Ctrl-Space": "autocomplete",
        },
      }}
      value={codeValue}
      editorDidMount={(editor) => {
        handleEditorDidMount(editor);
        editor.setSize("100%", "calc(100vh - 147px)");
      }}
    />
  );
};

export default InterviewEditor;
