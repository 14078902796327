//CSS
import "./IamUserTable.css";

//ICONS
//menu icons
import revokeIcon from "../../../../../assets/Icons/Settings/delete.svg";
import resetIcon from "../../../../../assets/Icons/Settings/reset.svg";
import editIcon from "../../../../../assets/Icons/Settings/edit.svg";
import ErrorIcon from "../../../../../assets/Icons/warning.svg";
import search from "../../../../../assets/Icons/Settings/search.svg";
import exportFile from "../../../../../assets/Icons/Settings/exportFile.svg";
import add from "../../../../../assets/Icons/Settings/add.svg";
import dots from "../../../../../assets/Icons/Settings/dots.svg";
import arrowWhite from "../../../../../assets/Icons/Settings/arrowWhite.svg";
import { LoadingShape, Pagination } from "react-rainbow-components";
import closeIcon from "../../../../../assets/Icons/Settings/closeBtn.svg";
//HOOKS
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  usePagination,
  useRowSelect,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";

import { Modal } from "react-bootstrap";

import { CSVLink } from "react-csv";

import axios from "axios";
import { BootcampBackend } from "../../../../../backend";
import { getUserRole, isAuthenticated } from "../../../../../helper/user";
import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import { useSidebar } from "helper/SideBarContext";
import { useSelector } from "react-redux";
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="searchBar col-6">
      <img src={search} />
      <input
        type="text"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
        placeholder="Search for Users"
      />
    </div>
  );
}

export default function IamUserTable() {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const userId = JSON.parse(localStorage.getItem("jwt")).userId
    ? JSON.parse(localStorage.getItem("jwt")).userId
    : null;
  const [menu, setMenu] = useState("");
  const { isSidebarCollapsed } = useSidebar();
  const [userRemoveModal, setUserRemoveModal] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState("");
  const [tableData, setTableData] = useState([]);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [selectedUser, setSelectedUser] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    role: "",
  });
  const [addUserData, setAddUserData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    role: "admin",
  });
  const [userError, setUserError] = useState({
    emailId: false,
    firstName: false,
    lastName: false,
  });
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    validation: false,
    passwordMatch: false,
  });
  const [submitError, setSubmitError] = useState({
    email: false,
    role: false,
  });
  const [showLoader, setShowLoader] = useState(false);
  const organizationDetails = useSelector(
    (state) => state.organization.organizationDetails
  );
  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const IndeterminateCheckbox = React.forwardRef(function hadleSelection(
    { indeterminate, ...rest },
    ref
  ) {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input
          className="styled-checkbox"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  });

  function showRemoveUserModal() {
    setUserRemoveModal(true);
    setMenu("");
  }

  function showResetPasswordModal() {
    setPasswordResetModal(true);
    setMenu("");
  }

  const getExportData = () => {
    return selectedFlatRows.map((row) => row.original);
  };
  const tableActions = [
    {
      dropdownClass: "edit",
      iconSrc: editIcon,
      action: "Edit user",
      handleClick: () => {
        setAddUserModal("editUser");
      },
    },
    {
      dropdownClass: "revoke",
      iconSrc: revokeIcon,
      action: "Delete user",
      handleClick: showRemoveUserModal,
    },
    {
      dropdownClass: "reset",
      iconSrc: resetIcon,
      action: "Reset Password",
      handleClick: showResetPasswordModal,
    },
  ];
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenu("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  function handleChangePassword(event) {
    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    const { name, value } = event.target;
    setPasswordData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function handleChangeAddUser(event) {
    const { name, value } = event.target;
    setAddUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function handleTableButtonClick(cell) {
    setSelectedUser(cell.row.original);
    if (menu === "") {
      setMenu(cell.row.original._id);
    } else {
      setMenu("");
    }
  }
  function showAddUserModal() {
    setAddUserModal("addUser");
    setMenu("");
  }

  function hideUserRemoveModal() {
    setUserRemoveModal(false);
    setAddUserData({
      _id: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      role: "admin",
    });
  }

  function hidePasswordResetModal() {
    setPasswordResetModal(false);

    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    setPasswordData({
      password: "",
      confirmPassword: "",
    });
  }

  function hideAddUserModal() {
    setAddUserModal("");
    setAddUserData({
      _id: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      role: "admin",
    });
    setUserError({
      firstName: false,
      lastName: false,
      emailId: false,
    });

    setSubmitError({
      email: false,
      role: false,
    });
  }

  async function fetchIamUserData() {
    setShowLoader(true);
    try {
      const res = await axios.get(`${BootcampBackend}/iamUser/all`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function handleDeleteUser() {
    try {
      axios
        .delete(`${BootcampBackend}/iamUser/revoke/${selectedUser._id}`, {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then(() => {
          hideUserRemoveModal();
        });
    } catch (err) {
      console.log(err.response);
    }
  }

  async function handleResetPasswordSubmit(event) {
    try {
      event.preventDefault();
      const regPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

      if (!regPassword.test(passwordData.password)) {
        setPasswordError({
          validation: true,
        });
        return;
      }
      if (passwordData.password !== passwordData.confirmPassword) {
        setPasswordError({
          passwordMatch: true,
        });
        return;
      }
      const formData = {
        _id: selectedUser._id,
        password: passwordData.password,
      };

      const res = await axios.put(
        `${BootcampBackend}/iamUser/updatePassword`,
        formData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      res.then(() => {
        hidePasswordResetModal(true);
      });
    } catch (err) {
      console.log(err.response);
    }

    //setPasswordSuccess(true);
  }
  const validateForm = () => {
    var regName = /^[a-zA-Z\s]{1,30}$/;
    var regEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!regName.test(addUserData.firstName)) {
      setUserError({
        firstName: true,
      });
      return;
    }
    if (!regName.test(addUserData.lastName)) {
      setUserError({
        lastName: true,
      });
      return;
    }

    if (!regEmail.test(addUserData.emailId)) {
      setUserError({
        emailId: true,
      });
      return;
    }
  };
  function handleEditUserSubmit(event) {
    try {
      event.preventDefault();
      validateForm();
      const formData = {
        firstName: addUserData.firstName,
        lastName: addUserData.lastName,
        role: addUserData.role,
        phoneNumber: addUserData.phoneNumber,
      };

      axios
        .put(`${BootcampBackend}/iamUser/edit/${selectedUser._id}`, formData, {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((response) => {
          if (response.data.status.code === 200) {
            setAddUserModal("");
            setAddUserData({
              _id: "",
              firstName: "",
              lastName: "",
              emailId: "",
              phoneNumber: "",
              role: "admin",
            });
          }
          setMenu("");
        });
    } catch (err) {
      if (err.response?.statusText === "Conflict") {
        setSubmitError({
          email: true,
        });
      }
    }
  }

  async function handleAddUserSubmit(event) {
    event.preventDefault();
    try {
      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!regName.test(addUserData.firstName)) {
        setUserError({
          firstName: true,
        });
        return;
      }
      if (!regName.test(addUserData.lastName)) {
        setUserError({
          lastName: true,
        });
        return;
      }

      if (!regEmail.test(addUserData.emailId)) {
        setUserError({
          emailId: true,
        });
        return;
      }

      const formData = {
        firstName: addUserData.firstName,
        lastName: addUserData.lastName,
        emailId: addUserData.emailId,
        role: addUserData.role,
        phoneNumber: addUserData.phoneNumber,
      };
      const res = await axios.post(
        `${BootcampBackend}/iamUser/create`,
        formData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      hideAddUserModal(true);
    } catch (err) {
      if (err.response.statusText === "Conflict") {
        setSubmitError({
          email: true,
        });
      }
    }
  }

  useEffect(() => {
    setAddUserData({ ...selectedUser });
  }, [selectedUser]);

  useEffect(() => {
    fetchIamUserData()
      .then((res) => {
        setTableData(res.data.result.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userRemoveModal, addUserModal]);

  const columns = useMemo(
    () => [
      {
        Header: "FULL NAME",
        accessor: "firstName",
        Cell: function role({ row, value }) {
          return (
            <div key={row._id} className="nameCell">
              {/* <img className="userImg" src={userImg} /> */}
              <span>
                {value} {row?.original?.lastName}
              </span>
            </div>
          );
        },
      },
      {
        Header: "EMAIL ID",
        accessor: "emailId",
      },
      {
        Header: "PHONE",
        accessor: "phoneNumber",
      },
      {
        Header: "ROLE",
        accessor: "role",
        Cell: function role({ row, value }) {
          return (
            <div key={row._id} className={`roleCell ${value}`}>
              {value.replace("_", " ")}
            </div>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: (originalRow, rowIndex) => {
          const date = new Date(originalRow.timestamps.created_at);
          return `Joined on ${date.getDate()}${nth(date.getDate())} ${
            monthNames[date.getUTCMonth()]
          }`;
        },
      },
      {
        Header: " ",
        accessor: "",
        Cell: function userDetails({ cell }) {
          return (
            <div key={`userDetails${cell.row.original._id}`}>
              {getUserRole() === "super_admin" ||
              (getUserRole() === "admin" &&
                userId === cell.row.original._id) ? (
                <button
                  key={`action${cell.row.original._id}`}
                  className="tableAction"
                  onClick={() => handleTableButtonClick(cell)}
                >
                  <img src={dots} />
                </button>
              ) : (
                <></>
              )}

              {menu === cell.row.original._id && (
                <div
                  id={cell.row.original._id}
                  className={`tableMenu ${
                    menu === cell.row.original._id ? "menuActive" : ""
                  }`}
                  ref={wrapperRef}
                >
                  {tableActions.map((actionData) => (
                    <React.Fragment key={`action${actionData.dropdownClass}`}>
                      {(actionData.dropdownClass === "reset" && (isAuthenticated()?.userId === cell.row.original._id || getUserRole() == "super_admin" && cell.row.original.role !== "admin_panel") || actionData.dropdownClass !== "reset") ? (
                        <div
                          className={actionData.dropdownClass}
                          onClick={actionData.handleClick}
                        >
                          <img
                            src={actionData.iconSrc}
                            alt="icon"
                            className="menuIcons"
                          />
                          <p className="paragraph">{actionData.action}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [menu]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    visibleColumns,
    globalFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: function selectionHead({ getToggleAllRowsSelectedProps }) {
            return (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            );
          },
          Cell: function selectionCell({ row }) {
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <Header heading="Settings"/>
      <Navbar
        showApiTab={organizationDetails?.apiKey}
      />
      <div
        className={`position-relative iamContainer ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="iamUserTable">
          <div className="headerSection">
            <div className="d-flex" style={{ gap: "15px" }}>
              {showLoader ? (
                <LoadingShape style={{ width: "5%", height: "30px" }} />
              ) : (
                <button
                  className="InviteBtn primaryBtnActive"
                  onClick={showAddUserModal}
                >
                  Invite people <img src={add} alt="" />
                </button>
              )}
              {showLoader ? (
                <LoadingShape style={{ width: "10%", height: "30px" }} />
              ) : (
                <button
                  className={`exportBtn ${
                    Object.keys(selectedRowIds).length > 0
                      ? `exportBtnActive`
                      : ``
                  } btn`}
                  disabled={!Object.keys(selectedRowIds).length > 0}
                >
                  <CSVLink
                    style={{ textDecoration: "none" }}
                    data={getExportData()}
                  >
                    Export{" "}
                    <img src={exportFile} alt="" style={{ height: "15px" }} />
                  </CSVLink>
                </button>
              )}
            </div>
            {showLoader ? (
              <LoadingShape style={{ width: "50%", height: "30px" }} />
            ) : (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}
            {showLoader ? (
              <LoadingShape style={{ width: "10%", height: "30px" }} />
            ) : (
              <div className="col-2"></div>
            )}
          </div>
          {showLoader ? (
            <div className="d-flex justify-content-between m-4 pe-2 ps-2">
              {Array.from(
                {
                  length: 3,
                },
                (_, i) => (
                  <LoadingShape
                    className=""
                    key={i}
                    style={{
                      height: "30px",
                      width: "20%",
                    }}
                  />
                )
              )}
            </div>
          ) : (
            <></>
          )}
          {page ? (
            <div className="table-responsive mt-3 ">
              {showLoader ? (
                Array.from(
                  {
                    length: 10,
                  },
                  (_, i) => (
                    <LoadingShape
                      className="mt-4 ms-4 me-5 pe-4"
                      key={i}
                      style={{
                        height: "45px",
                        width: "100%",
                      }}
                    />
                  )
                )
              ) : (
                <table {...getTableProps()}>
                  <thead>
                    <>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </>
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {showLoader ? (
                <div className="d-flex justify-content-center">
                  <LoadingShape
                    style={{
                      width: "40%",
                      height: "40px",
                      marginTop: "40px",
                      textAlign: "center",
                    }}
                  />
                </div>
              ) : (
                <>
                  {tableData?.length > 10 && (
                    <div className="settingPagination my-3">
                      <Pagination
                        className="rainbow-m_auto"
                        pages={pageCount}
                        activePage={pageIndex + 1}
                        onChange={(e, page) => {
                          gotoPage(page - 1);
                        }}
                        variant="shaded"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              {Array.from(
                {
                  length: 6,
                },
                (_, i) => (
                  <div
                    className="row py-2 px-4  align-items-center"
                    style={{ height: "60px" }}
                  >
                    <div className="col-md-1 image-div">
                      <LoadingShape
                        shape="circle"
                        variant="image"
                        style={{ height: "34px", width: "34px" }}
                      />
                    </div>
                    <div
                      className="col-11 d-flex justify-content-between"
                      style={{ gap: "60px" }}
                    >
                      <LoadingShape className="my-2 " />
                      <LoadingShape className="my-2 " />
                      <LoadingShape className="my-2 " />
                      <LoadingShape className="my-2 " />
                    </div>
                  </div>
                )
              )}
            </>
          )}
          <Modal
            centered="true"
            dialogClassName="userRemoveModal"
            show={userRemoveModal}
            onHide={hideUserRemoveModal}
          >
            <Modal.Header>
              <Modal.Title>Are you sure ?</Modal.Title>
              <img
                className="cursor-pointer"
                onClick={() => setUserRemoveModal(false)}
                src={closeIcon}
                alt="x"
              />
            </Modal.Header>
            <Modal.Body>
              <div className="userRevokeDialog">
                <div>
                  <p>{`You are removing ${
                    selectedUser.firstName + " " + selectedUser.lastName
                  }  from the user list 
            & he wont be able to access any data`}</p>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div>
                    <button
                      onClick={handleDeleteUser}
                      type="submit"
                      className="modalButtonSubmitActive mx-2"
                    >
                      Confirm
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={hideUserRemoveModal}
                      className="modalButtonCancel mx-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered="true"
            dialogClassName="passwordResetModal"
            show={passwordResetModal}
            onHide={hidePasswordResetModal}
          >
            <Modal.Header>
              <Modal.Title>Reset Password</Modal.Title>
              <img
                className="cursor-pointer"
                onClick={() => setPasswordResetModal(false)}
                src={closeIcon}
                alt="x"
              />
            </Modal.Header>
            <Modal.Body>
              <form
                className="resetPasswordForm"
                onSubmit={handleResetPasswordSubmit}
              >
                <div className="row">
                  <div
                    className="col-6 form-group"
                    style={{ paddingRight: "10px" }}
                  >
                    <label htmlFor="password">Password</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="password"
                        name="password"
                        value={passwordData.password}
                        placeholder="*****"
                        onChange={handleChangePassword}
                        required
                        className={`form-input ${
                          passwordError.validation ? "inputError" : ""
                        }`}
                      />
                      {passwordError.validation && (
                        <div className="invalid">Invalid format</div>
                      )}
                      {passwordError.passwordMatch && (
                        <div className="invalid">Passwords do not match</div>
                      )}
                      {(passwordError.passwordMatch ||
                        passwordError.validation) && (
                        <img
                          className="inputErrorIcon"
                          src={ErrorIcon}
                          alt="error"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="col-6 form-group"
                    style={{ paddingLeft: "10px" }}
                  >
                    <label htmlFor="confirmPassword">Confirm password</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={passwordData.confirmPassword}
                        placeholder=""
                        onChange={handleChangePassword}
                        required
                        className={`form-input ${
                          passwordError.passwordMatch ? "inputError" : ""
                        }`}
                      />
                      {passwordError.passwordMatch && (
                        <img
                          className="inputErrorIcon"
                          src={ErrorIcon}
                          alt="error"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group my-3  d-flex justify-content-center">
                    <button
                      type="submit"
                      className="modalButtonSubmitActive mx-2"
                    >
                      Save Changes
                    </button>
                    <button
                      type="button"
                      onClick={hidePasswordResetModal}
                      className="modalButtonCancel mx-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <Modal
            centered="true"
            dialogClassName="settingsAddUser"
            show={addUserModal !== "" ? true : false}
            onHide={hideAddUserModal}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header
              style={{
                justifyContent: "center",
              }}
            >
              <Modal.Title>
                {addUserModal === "addUser" ? "Add New User" : "Edit User"}
              </Modal.Title>
              <img
                className="cursor-pointer"
                onClick={() => {
                  setAddUserData({
                    _id: "",
                    firstName: "",
                    lastName: "",
                    emailId: "",
                    phoneNumber: "",
                    role: "admin",
                  });
                  setAddUserModal("");
                }}
                src={closeIcon}
                alt="x"
              />
            </Modal.Header>
            <Modal.Body>
              <form
                className="addUserForm"
                onSubmit={
                  addUserModal === "addUser"
                    ? handleAddUserSubmit
                    : handleEditUserSubmit
                }
              >
                <div className="row m-0 justify-content-center">
                  {/* <div className="col-4 p-0 pe-3">
                    {file ? (
                      <img className="userImage" src={file} />
                    ) : (
                      <div className="addImage">
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                        />
                        // <img className="imageIcon" src={addImage} alt="+" /> 
                      </div>
                    )}
                    {file && (
                      <button
                        className="changePhoto mt-4"
                        onClick={() => setFile("")}
                      >
                        Change Photo
                      </button>
                    )}
                    <p className="mt-3">Upload a image of 500 X 500</p>
                    <p className="thintText mt-3 ">
                      Formats Allowed .jpg or .png{" "}
                    </p>
                  </div> */}
                  <div className="col-8 p-0 ps-3">
                    <div className="form-group">
                      <label htmlFor="firstName">
                        First Name<span>*</span>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="firstName"
                          value={addUserData.firstName}
                          placeholder="Eg. Rahul"
                          onChange={handleChangeAddUser}
                          required
                          className={`form-input ${
                            userError.firstName ? "inputError" : ""
                          }`}
                        />
                        {userError.firstName && (
                          <div className="invalid">Invalid Name</div>
                        )}
                        {userError.firstName && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div className=" form-group">
                      <label htmlFor="lastName">Last Name*</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="lastName"
                          value={addUserData.lastName}
                          placeholder="Eg. Raj"
                          onChange={handleChangeAddUser}
                          required
                          className={`form-input ${
                            userError.lastName ? "inputError" : ""
                          }`}
                        />
                        {userError.lastName && (
                          <div className="invalid">Invalid Name</div>
                        )}
                        {userError.lastName && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">
                        Phone<span>*</span>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="phoneNumber"
                          value={addUserData.phoneNumber}
                          placeholder="Eg. 91 98756423"
                          onChange={handleChangeAddUser}
                          className={`form-input`}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailId">
                        Email Id<span>*</span>{" "}
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="email"
                          name="emailId"
                          value={addUserData.emailId}
                          placeholder="Eg. rahul@gmail.com"
                          onChange={(e) => {
                            if (
                              isAuthenticated()?.userId === selectedUser?._id
                            ) {
                              return;
                            }
                            handleChangeAddUser(e);
                          }}
                          required
                          className={`form-input ${
                            userError.emailId || submitError.email
                              ? "inputError"
                              : ""
                          }`}
                          disabled={
                            isAuthenticated()?.userId === selectedUser?._id
                          }
                        />
                        {userError.emailId && (
                          <div className="invalid">Invalid email</div>
                        )}
                        {userError.emailId && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                        {submitError.email && (
                          <>
                            <div className="invalid">
                              Email already registered
                            </div>
                            <img
                              className="inputErrorIcon"
                              src={ErrorIcon}
                              alt="error"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">
                        Role<span>*</span>
                      </label>
                      <select
                        name="role"
                        value={addUserData.role}
                        onChange={(e) => {
                          if (isAuthenticated()?.userId === selectedUser?._id) {
                            return;
                          }
                          handleChangeAddUser(e);
                        }}
                        required
                        className="form-input"
                        disabled={
                          isAuthenticated()?.userId === selectedUser?._id
                        }
                      >
                        <option disabled value="default">
                          Select Role
                        </option>
                        <option value="admin">Admin</option>
                        <option
                          disabled={getUserRole() === "admin" ? true : false}
                          value="super_admin"
                        >
                          Super Admin
                        </option>
                      </select>
                    </div>
                    <div className="form-group mt-3 d-flex">
                      <button
                        type="submit"
                        className={`${
                          !(
                            addUserData.firstName &&
                            addUserData.emailId &&
                            addUserData.lastName
                          )
                            ? `modalButtonSubmit`
                            : `modalButtonSubmitActive`
                        } mx-2`}
                      >
                        Submit
                        <img style={{ marginLeft: "15px" }} src={arrowWhite} />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
