// import React, { useEffect, useState } from "react";
import "./users.css";

import MoreIcon from "../../../../assets/Icons/Dashboard/more.svg";
import axios from "axios";
import { BootcampBackend } from "../../../../backend";
import { useHistory } from "react-router-dom";
const moment = require("moment");

//ICONS
//menu icons
import revokeIcon from "../../../../assets/Icons/Settings/Revoke.svg";
import resetIcon from "../../../../assets/Icons/Settings/Group29.svg";
import editIcon from "../../../../assets/Icons/Settings/Group10.svg";
import ErrorIcon from "../../../../assets/Icons/warning.svg";
import viewall from "assets/Icons/DashboardNew/viewAll.svg"
//HOOKS
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

import { getUserRole } from "../../../../helper/user";
import { LoadingShape } from "react-rainbow-components";

function Users({ usersTableData, isLoading }) {
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const formatDate = (dateTime) => {
    var string = dateTime;
    var temp = string.split("T");

    var strdate = temp[0].split("-");
    var year = strdate[0];
    var month = strdate[1];
    var day = strdate[2];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("DD MMM YYYY");

    if (formattedDate === "Invalid date") return "";

    return formattedDate;
  };

  const userId = JSON.parse(localStorage.getItem("jwt")).userId
    ? JSON.parse(localStorage.getItem("jwt")).userId
    : null;
  const [menu, setMenu] = useState({
    _id: null,
  });

  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    role: "",
  });

  const [editUserData, setEditUserData] = useState(selectedUser);

  const [userError, setUserError] = useState({
    emailId: false,
    firstName: false,
    lastName: false,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    validation: false,
    passwordMatch: false,
  });

  const [submitError, setSubmitError] = useState({
    email: false,
    role: false,
  });

  function handleChangePassword(event) {
    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    const { name, value } = event.target;
    setPasswordData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleChangeEditUser(event) {
    setUserError({
      emailId: false,
      firstName: false,
      lastName: false,
    });

    const { name, value } = event.target;
    setEditUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleTableButtonClick(cell) {
    setSelectedUser({
      _id: cell._id,
      firstName: cell.lastName,
      lastName: cell.firstName,
      emailId: cell.emailId,
      phoneNumber: cell.phoneNumber,
      role: cell.role,
    });
    if (menu._id === null) {
      setMenu({
        _id: cell._id,
      });
    } else {
      setMenu({
        _id: null,
      });
    }
  }

  function showRemoveUserModal() {
    setRemoveUserModal(true);
    setMenu({
      _id: null,
    });
  }

  function showResetPasswordModal() {
    setResetPasswordModal(true);
    setMenu({
      _id: null,
    });
  }

  function showEditUserModal() {
    setEditUserModal(true);
    setMenu({
      _id: null,
    });
  }

  function hideRemoveUserModal() {
    setRemoveUserModal(false);
  }

  function hideResetPasswordModal() {
    setResetPasswordModal(false);

    setPasswordError({
      validation: false,
      passwordMatch: false,
    });

    setPasswordData({
      password: "",
      confirmPassword: "",
    });
  }

  function hideEditUserModal() {
    setEditUserModal(false);

    setUserError({
      firstName: false,
      lastName: false,
      emailId: false,
    });

    setSubmitError({
      email: false,
      role: false,
    });

    setSelectedUser({
      _id: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      role: "",
    });
  }
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenu({
            _id: null,
          });
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  async function handleDeleteUser() {
    try {
      const res = await axios.delete(
        `${BootcampBackend}/iamUser/revoke/${selectedUser._id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      hideRemoveUserModal(true);
    } catch (err) {
      console.log(err.response);
    }
  }

  async function handleResetPasswordSubmit(event) {
    try {
      event.preventDefault();
      const regPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

      if (!regPassword.test(passwordData.password)) {
        setPasswordError({
          validation: true,
        });
        return;
      }
      if (passwordData.password !== passwordData.confirmPassword) {
        setPasswordError({
          passwordMatch: true,
        });
        return;
      }
      const formData = {
        _id: selectedUser._id,
        password: passwordData.password,
      };

      const res = await axios.put(
        `${BootcampBackend}/iamUser/updatePassword`,
        formData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      hideResetPasswordModal(true);
    } catch (err) {
      console.log(err.response);
    }

    //setPasswordSuccess(true);
  }

  async function handleEditUserSubmit(event) {
    try {
      event.preventDefault();
      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!regName.test(editUserData.firstName)) {
        setUserError({
          firstName: true,
        });
        return;
      }
      if (!regName.test(editUserData.lastName)) {
        setUserError({
          lastName: true,
        });
        return;
      }

      if (!regEmail.test(editUserData.emailId)) {
        setUserError({
          emailId: true,
        });
        return;
      }

      const formData = {
        firstName: editUserData.firstName,
        lastName: editUserData.lastName,
        role: editUserData.role,
        phoneNumber: editUserData.phoneNumber,
      };

      const res = await axios.put(
        `${BootcampBackend}/iamUser/edit/${selectedUser._id}`,
        formData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      hideEditUserModal(true);
    } catch (err) {
      console.log(err.response);
      if (err.response.statusText === "Conflict") {
        setSubmitError({
          email: true,
        });
      }
    }
  }

  useEffect(() => {
    setEditUserData(selectedUser);
  }, [selectedUser]);

  return (
    <div>
      {isLoading && usersTableData ? (
        <div className="row m-2">
          <div className={`col-lg-12 mt-1`}>
            <LoadingShape style={{ height: "40px" }} />
          </div>
          <div className="col-lg-12 mt-4">
            <LoadingShape style={{ height: "110px" }} />
          </div>
        </div>
      ) : (
        usersTableData.length > 1 && (
          <div className="dashboardCard users my-3">
            <div className="d-flex usersData align-items-center justify-content-between">
              <h5 className="dashboardHeading">
                Recent IAM users ({usersTableData?.length})
              </h5>
              <div
                className="d-flex align-items-center viewAll"
                onClick={() => {
                  history.push("/settings/iamUser");
                }}
              >
                <p className="dashboardViewAll">View all</p>
                <img className="ms-2" src={viewall} alt="viewAll"/>
              </div>
            </div>
            <div className="table-responsive-xl dashboardUsersSectiontable">
              <table className="table usersTable">
                <tbody>
                  {usersTableData.length > 0 &&
                    usersTableData.map((dataPerson) => (
                      <tr key={dataPerson._id} className="getModal">
                        {/* <td scope="row">{dataPerson.firstName}</td> */}
                        <td className="middle-align">
                          <div className="userIcon d-flex align-items-center justify-content-center">
                            <h6 className="dashboardBoldText">{dataPerson.firstName[0].toUpperCase()}</h6>
                          </div>
                        </td>
                        <td className="middle-align dashboardBoldText">
                          {dataPerson.firstName}
                          {dataPerson.lastName}
                        </td>
                        <td className="middle-align userTableThinText">{dataPerson.emailId}</td>
                        <td className="middle-align userTableThinText">
                          {dataPerson.phoneNumber}
                        </td>
                        <td className="middle-align userTableThinText">{dataPerson.role}</td>
                        <td className="middle-align userTableThinText">
                          Added on{" "}
                          {dataPerson.timestamps.created_at &&
                            formatDate(dataPerson.timestamps.created_at)}
                        </td>
                        <td className="middle-align" ref={wrapperRef}>
                          {/* <img src={MoreIcon} alt="more Icon" /> */}

                          {getUserRole() === "super_admin" ||
                          (getUserRole() === "admin" &&
                            userId === dataPerson._id) ? (
                            <img
                              src={MoreIcon}
                              className=""
                              alt="more Icon"
                              onClick={() => handleTableButtonClick(dataPerson)}
                            />
                          ) : (
                            <></>
                          )}

                          <div
                            id={dataPerson._id}
                            className={`tableMenu ${
                              menu._id === dataPerson._id ? "menuActive" : ""
                            }`}
                          >
                            {userId !== dataPerson._id && (
                              <div
                                className="revoke dropdown-li-btn"
                                onClick={showRemoveUserModal}
                              >
                                <img
                                  src={revokeIcon}
                                  alt="icon"
                                  className="menuIcons"
                                />
                                <p>Revoke users</p>
                              </div>
                            )}

                            <div
                              className="reset dropdown-li-btn"
                              onClick={showResetPasswordModal}
                            >
                              <img
                                src={resetIcon}
                                alt="icon"
                                className="menuIcons"
                              />
                              <p>Reset Password</p>
                            </div>
                            <div className="edit dropdown-li-btn" onClick={showEditUserModal}>
                              <img
                                src={editIcon}
                                alt="icon"
                                className="menuIcons"
                              />
                              <p>Edit User</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Modal
              centered="true"
              dialogClassName="removeUserModal"
              show={removeUserModal}
              onHide={hideRemoveUserModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Are you sure ?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="revokeUserDialog">
                  <div>
                    <p>{`You are removing ${
                      selectedUser.firstName + " " + selectedUser.lastName
                    }  from the user list 
            & he wont be able to access any data`}</p>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <button
                        onClick={handleDeleteUser}
                        type="submit"
                        className="modalButtonSubmit mx-2"
                      >
                        Confirm
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={hideRemoveUserModal}
                        className="modalButtonCancel mx-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              centered="true"
              dialogClassName="resetPasswordModal"
              show={resetPasswordModal}
              onHide={hideResetPasswordModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form
                  className="resetPasswordForm"
                  onSubmit={handleResetPasswordSubmit}
                >
                  <div className="row">
                    <div
                      className="col-6 form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <label htmlFor="password">Password</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="password"
                          name="password"
                          value={passwordData.password}
                          placeholder="*****"
                          onChange={handleChangePassword}
                          required
                          className={`form-input ${
                            passwordError.validation ? "inputError" : ""
                          }`}
                        />
                        {passwordError.validation && (
                          <div className="invalid">Invalid format</div>
                        )}
                        {passwordError.passwordMatch && (
                          <div className="invalid">Passwords do not match</div>
                        )}
                        {(passwordError.passwordMatch ||
                          passwordError.validation) && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="col-6 form-group"
                      style={{ paddingLeft: "10px" }}
                    >
                      <label htmlFor="confirmPassword">Confirm password</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={passwordData.confirmPassword}
                          placeholder=""
                          onChange={handleChangePassword}
                          required
                          className={`form-input ${
                            passwordError.passwordMatch ? "inputError" : ""
                          }`}
                        />
                        {passwordError.passwordMatch && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group my-3  d-flex justify-content-center">
                      <button type="submit" className="modalButtonSubmit mx-2">
                        Save Changes
                      </button>
                      <button
                        type="button"
                        onClick={hideResetPasswordModal}
                        className="modalButtonCancel mx-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Modal
              centered="true"
              size="lg"
              dialogClassName="editUserModal"
              show={editUserModal}
              onHide={hideEditUserModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="editUserForm" onSubmit={handleEditUserSubmit}>
                  <div className="row">
                    <div
                      className="col-6 form-group"
                      style={{ paddingRight: "20px" }}
                    >
                      <label htmlFor="firstName">First Name</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="firstName"
                          value={editUserData.firstName}
                          placeholder=""
                          onChange={handleChangeEditUser}
                          required
                          className={`form-input ${
                            userError.firstName ? "inputError" : ""
                          }`}
                        />
                        {userError.firstName && (
                          <div className="invalid">Invalid Name</div>
                        )}
                        {userError.firstName && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="col-6 form-group"
                      style={{ paddingLeft: "20px" }}
                    >
                      <label htmlFor="lastName">Last Name</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="lastName"
                          value={editUserData.lastName}
                          placeholder=""
                          onChange={handleChangeEditUser}
                          required
                          className={`form-input ${
                            userError.lastName ? "inputError" : ""
                          }`}
                        />
                        {userError.lastName && (
                          <div className="invalid">Invalid Name</div>
                        )}
                        {userError.lastName && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="col-6 form-group"
                      style={{ paddingRight: "20px" }}
                    >
                      <label htmlFor="phoneNumber">Phone</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="phoneNumber"
                          value={editUserData.phoneNumber}
                          placeholder=""
                          onChange={handleChangeEditUser}
                          required
                          className={`form-input`}
                        />
                      </div>
                    </div>
                    <div
                      className="col-6 form-group"
                      style={{ paddingLeft: "20px" }}
                    >
                      <label htmlFor="role">Role</label>
                      <select
                        disabled={getUserRole() === "admin" ? true : false}
                        name="role"
                        value={editUserData.role}
                        onChange={handleChangeEditUser}
                        required
                        className="form-input"
                      >
                        <option disabled value="default">
                          Select Role
                        </option>
                        <option value="admin">Admin</option>
                        <option value="super_admin">Super Admin</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailId">Email Id</label>
                      <div style={{ position: "relative" }}>
                        <input
                          disabled
                          type="email"
                          name="emailId"
                          value={editUserData.emailId}
                          placeholder=""
                          onChange={handleChangeEditUser}
                          required
                          className={`form-input ${
                            userError.emailId || submitError.email
                              ? "inputError"
                              : ""
                          }`}
                        />
                        {userError.emailId && (
                          <div className="invalid">Invalid email</div>
                        )}
                        {userError.emailId && (
                          <img
                            className="inputErrorIcon"
                            src={ErrorIcon}
                            alt="error"
                          />
                        )}
                        {submitError.email && (
                          <>
                            <div className="invalid">
                              Email already registered
                            </div>
                            <img
                              className="inputErrorIcon"
                              src={ErrorIcon}
                              alt="error"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group my-3  d-flex justify-content-center">
                      <button type="submit" className="modalButtonSubmit mx-2">
                        Save Changes
                      </button>
                      <button
                        type="button"
                        onClick={hideEditUserModal}
                        className="modalButtonCancel mx-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        )
      )}
    </div>
  );
}

export default Users;
