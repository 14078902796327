import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total_participants: 0,
  lms_leaderboard: [],
  lms_leaderboard_loader: true,
  lms_leaderboard_current_page: 1,
  lms_total_leaderboard_Count: 0
};

export const lmsSlice = createSlice({
  name: "lms",
  initialState,
  reducers: {
    setTotalParticipants: (state, action) => {
      state.total_participants = action.payload;
    },
    setLMSLeaderboard: (state, action) => {
      state.lms_leaderboard = action.payload.data;
      state.lms_leaderboard_loader = false;
      state.lms_leaderboard_current_page = state.lms_leaderboard_current_page + 1;
      state.lms_total_leaderboard_Count = action.payload.totalUsersInLeaderboard;
    },
    setLMSLeaderboardLoader: (state, action) => {
      state.lms_leaderboard_loader = action.payload;
    },
    setLMSLeaderboardCurrentPage: (state, action) => {
      state.lms_leaderboard_current_page = action.payload;
    },
  },
});

export const { setTotalParticipants, setLMSLeaderboard, setLMSLeaderboardLoader, setLMSLeaderboardCurrentPage } = lmsSlice.actions;

export default lmsSlice.reducer;
