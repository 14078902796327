import React, { useState, useEffect } from "react";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import { useHistory } from "react-router-dom";
import activePassword from "../../../assets/Icons/activePassword.svg";
import hidePassword from "../../../assets/Icons/hidePassword.svg";
import showPassword from "../../../assets/Icons/showPassword.svg";
import password from "../../../assets/Icons/password.svg";

export default function ResetPassword(props) {
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    validation: false,
    passwordMatch: false,
  });

  const history = useHistory();

  const login = (e) => {
    history.push("/login");
  };

  function handleChange(event) {
    setError({
      validation: false,
      passwordMatch: false,
    });

    const { name, value } = event.target;
    setPasswordData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const regPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

    if (!regPassword.test(passwordData.password)) {
      setError({
        validation: true,
        passwordMatch: false,
      });
      return;
    }
    if (passwordData.password !== passwordData.confirmPassword) {
      setError({
        validation: false,
        passwordMatch: true,
      });
      return;
    }
    axios
      .put(
        `${BootcampBackend}/iamUser/resetPassword/${props.props.match.params.userId}/${props.props.match.params.token}`,
        {
          password: passwordData.password,
        }
      )
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          setPasswordSuccess(true);
        }
      })
      .catch((err) => {
        console.log("error occured" + err);
      });
  }

  return (
    <form
      className={`col-6 p-0 pt-5 ${
        passwordSuccess ? `d-flex flex-column justify-content-around h-100` : ``
      }`}
    >
      <div className="col-6 m-auto">
        {!passwordSuccess && (
          <>
            <h3 className="formHeading mb-4 mt-2">Reset Password</h3>
            <label htmlFor="password" className="mb-2 pt-2">
              Please type your new password
            </label>
            <div
              className={`loginInputHld ${
                error.validation ? `mb-0 errorInputField` : `mb-4`
              }`}
            >
              <img
                className="img-fluid inputLogo"
                src={passwordData.password ? activePassword : password}
              />
              <input
                type={viewPassword.password ? "text" : "password"}
                name="password"
                value={passwordData.password}
                onChange={(e) => handleChange(e)}
                required
              />
              <img
                className={`img-fluid ms-2 ${viewPassword ? `viewEye` : ``}`}
                src={viewPassword.password ? showPassword : hidePassword}
                onClick={() =>
                  setViewPassword({
                    ...viewPassword,
                    password: !viewPassword.password,
                  })
                }
              />
            </div>
            {error.validation && (
              <p className="errorText">
                Minimum eight characters, at least one uppercase letter one
                lowercase letter, one number and one special character*
              </p>
            )}
            <label htmlFor="password" className="mb-2 pt-2">
              Confirm Password
            </label>
            <div
              className={`loginInputHld ${
                error.validation ? `mb-0 errorInputField` : `mb-4`
              }`}
            >
              <img
                className="img-fluid inputLogo"
                src={passwordData.confirmPassword ? activePassword : password}
              />
              <input
                type={viewPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={(e) => handleChange(e)}
                required
              />
              <img
                className={`img-fluid ms-2 ${viewPassword ? `viewEye` : ``}`}
                src={viewPassword.confirmPassword ? showPassword : hidePassword}
                onClick={() =>
                  setViewPassword({
                    ...viewPassword,
                    confirmPassword: !viewPassword.confirmPassword,
                  })
                }
              />
            </div>
            {error.passwordMatch && (
              <p className="errorText">Passwords do not match</p>
            )}

            <button
              className="submitButton mt-4 mb-4"
              onClick={(e) => handleSubmit(e)}
            >
              Reset
            </button>
          </>
        )}
        {passwordSuccess && (
          <div>
            <div className="successSvg">
              {" "}
              <svg
                className="checkmarkSvg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                {" "}
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />{" "}
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <h3 className="formHeading mb-3 mt-2">
              Password Reset Successfull
            </h3>
            <p className="fontGray text-center mb-2">
              To begin using a new password, click the login button.
            </p>
            <button className="submitButton  mt-5" onClick={login}>
              Login
            </button>
          </div>
        )}
        {!passwordSuccess && (
          <>
            <div className="loginSeprator align-items-center d-flex mt-5 mb-5">
              <p className="seprator me-2"></p>
              <span>OR</span>
              <p className="seprator ms-2"></p>
            </div>
            <p className="darkText text-center mt-5">Don’t have an account?</p>
            <button
              className="submitButton registerButton mt-3 mb-5"
              onClick={() => history.push("/signup")}
            >
              Sign up for Hiring
            </button>
          </>
        )}
      </div>
      <p className="mt-5 mb-3 text-center fontGray">
        For any queries &nbsp;
        <a className="fontGray" href="mailto:support@machinehack.com">
          support@machinehack.com
        </a>
      </p>
    </form>
  );
}
