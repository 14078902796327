import React, { useState } from "react";
import { BootcampBackend } from "backend";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import TimePicker from "rc-time-picker";
import moment from "moment";
import closeModal from "assets/Icons/MyHackathons/closeModal.svg";
import Clock from "assets/Icons/Interview/Clock_interview.svg";
import DateIcon from "assets/Icons/Interview/Date_interview.svg";
import interviewLink from "assets/Icons/Interview/New/newLink.svg";
import { useHistory } from "react-router-dom";
import "../InterviewHome.css"; 

const CreateScheduledInterview = ({
  showInterviewModal,
  setShowInterviewModal,
  instantLink,
  applicantInfo,
  interviewToken,
}) => {
  const [interviewTitle, setInterviewTitle] = useState("");
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(
    moment(new Date()).add("30", "minutes")
  );
  const history = useHistory();

  const oncreateScheduledInterview = async () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();
    if (interviewTitle === "") {
      setInterviewTitleError(true);
      return;
    } else {
      await axios
        .post(
          `${BootcampBackend}/interview/createInterview`,
          {
            interviewStartTime: interviewStartTime,
            interviewType: "scheduled",
            interviewLink: instantLink,
            interviewTitle: interviewTitle,
            interviewEndTime: interviewEndTime,
            email: applicantInfo?.Email || applicantInfo?.applicantId?.Email,
            interviewToken: interviewToken,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then(() => {
          history.push("/interviews");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const customFunction = () => {
    const element = document.getElementsByClassName(
      "rc-time-picker-panel-input"
    );

    if (element[0]) {
      element[0].addEventListener("blur", (event) => {
        const newValue = event.target.value.toString();

        // 'LT' is short for 12 hour format in moment.js
        // If the time is not after 11:15 AM, put a validation for that

        if (
          moment(newValue, "LT", true).isValid() &&
          moment(newValue, "LT").isSameOrAfter()
        ) {
          setStartTime(moment(newValue, "LT"));
          setEndTime(moment(newValue, "LT").add(30, "minutes"));
        }
      });
    }
  };

  const handleTimeClick = () => {
    setTimeout(() => {
      customFunction();
    }, 500);
  };

  const copyLink = () => {
    setIsCopied("schedule");
    navigator.clipboard.writeText(instantLink);
    setTimeout(() => {
      setIsCopied("");
    }, 5000);
  };
  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };
  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };
  return (
    <Modal
      show={showInterviewModal}
      onHide={() => {}}
      size="sm"
      centered
      dialogClassName={"createInterviewLink"}
      enforceFocus={false}
      className="createInterviewModal createInterviewLink"
    >
      <Modal.Header>
        <img
          className="requestHackathonModalClose cursor-pointer"
          onClick={() => setShowInterviewModal(false)}
          src={closeModal}
        />
        <div className="mx-auto Header-heading">Create an Interview Link</div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center schedule mt-4">
          <div className="copyLinkBox">
            <img
              src={interviewLink}
              className="linkIconModal img-fluid"
              alt="logo"
              loading="lazy"
            />
            <input className="copyLink" value={instantLink} disabled />
            <button
              className={`copyBtn text-center ${
                isCopied !== "schedule" ? "" : "greenBackground"
              }`}
              onClick={() => copyLink("schedule")}
            >
              <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
            </button>
          </div>
          <div className="mt-3 schedulingDiv">
            <h6>Interview Title</h6>
            <input
              placeholder="Enter Interview Title"
              onChange={oninterviewTitleChange}
              value={interviewTitle}
              className="scheduleInputs ps-3"
            />
            {interviewTitleError && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                This Field is required
              </p>
            )}
            {/* Date Picker Here START */}

            <h6 className="mt-4 mb-3">Select Date</h6>
            <div className="datepicker position-relative">
              <img
                src={DateIcon}
                className="img-fluid me-2 dateIconJobInterview position-absolute"
                loading="lazy"
                alt="date"
              />

              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={handleDateChange}
                isValidDate={valid}
                dateFormat="DD MMM YYYY"
                value={selectedDate}
              />
              {/* Date Picker Here End */}
            </div>

            {/* <ComboDatePicker/> */}
            <h6 className="mt-4 mb-3">Time Slot</h6>
            <div className="d-flex align-items-center margin-top-bottom jobsInterview">
              <img
                src={Clock}
                className="img-fluid me-2"
                loading="lazy"
                alt="clock"
              />
              <TimePicker
                value={startTime}
                minuteStep={30}
                use12Hours={true}
                showSecond={false}
                onChange={handleTimeChange}
                onOpen={handleTimeClick}
                defaultValue={moment()}
                clearText=""
                clearIcon=""
              />
              <span className="rc-time-picker-tofield">TO</span>
              <TimePicker
                value={endTime}
                minuteStep={30}
                use12Hours={true}
                showSecond={false}
              />
            </div>
            <h6 className="mb-1">Candidate Email</h6>
            <input
              value={applicantInfo?.Email}
              placeholder="Enter Candidate Email"
              disabled
              className="scheduleInputs ps-3"
            />
          </div>
          <div className="px-3">
            <button
              className="my-4 scheduleInterviewlg"
              onClick={oncreateScheduledInterview}
            >
              Schedule an Interview
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateScheduledInterview;
