import React, { useEffect, useState } from "react";
import "./UpComingInterviews.css";
import Delete from "../../../assets/Icons/AdminView/AssessmentDelete.svg";
import Copy from "../../../assets/Icons/Interview/Copy_interview.svg";
import LinkIcon from "../../../assets/Icons/Interview/New/Link.svg";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import { LoadingShape, Pagination } from "react-rainbow-components";
import { Modal } from "react-bootstrap";
const moment = require("moment");
import Cross from "../../../assets/Icons/Interview/Cross_interview.svg";
import DateIcon from "../../../assets/Icons/Interview/Date_interview.svg";
import Clock from "../../../assets/Icons/Interview/Clock_interview.svg";
import Datetime from "react-datetime";

function UpComingInterviews() {
  const [pendingInterviews, setPendingInterviews] = useState([]);
  const [selectedInterview, setSelecetedInterview] = useState({});
  const [interviewStatus, setInterviewStatus] = useState("pending");
  const [isAllInterviews, setIsAllInterviews] = useState("allInterviews");
  const [clickedButton, setClickedButton] = useState("");
  const [show, setShow] = useState(false);
  const [interviewTitle, setInterviewTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [endTime, setEndTime] = useState(
    moment(new Date()).add("30", "minutes")
  );
  const [startTime, setStartTime] = useState(moment());
  const [instantLink, setInstantLink] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [mailList, setMailList] = useState("email");
  const [modalInterview, setModalInterview] = useState(0);
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [candidateEmailError, setCandidateEmailError] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [Mhlist, setMhlist] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const lastItem = parseInt(activePage) * 10;
  const firstItem = parseInt(lastItem) - 10;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      color: "#3c3e4d",
      padding: 8,
      zIndex: 999,
      fontSize: "14px",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "#ffffff",
      borderBottom: "solid 1px #e4e4e4",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      fontSize: "14px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    placeholder: () => ({
      color: "#9d9fa6",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex",
    }),
  };
  const handleOnChange = (event, page) => {
    setActivePage({ page });
  };

  async function fetchInterviewsPending() {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/getAllInterviewsAdminPanel/pending?current_page=${activePage.page}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setIsPageLoading(false);
          setPendingInterviews(res.data.result.interviewData);
          setTotalCount(res.data.result.totalPendingInterviews);
        });
      return res;
    } catch (err) {
      setIsPageLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchInterviewsPending();
  }, [activePage]);

  const deleteInterview = (data) => {
    axios
      .delete(
        `${BootcampBackend}/interview/deleteInterview/${data._id}/${data.interviewRounds._id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((result) => {
        setPendingInterviews(
          pendingInterviews.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return pendingInterviews;
          })
        );
        if (result.data.result.message == "Success") {
          fetchInterviewsPending();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSchedule = (item) => {
    setShow(true);
    setInterviewTitle(item.interviewTitle);
    setSelectedDate(
      moment(item.interviewRounds.interviewStartTime).format("ddd, ll")
    );
    setStartTime(moment(item.interviewRounds.interviewStartTime));
    setEndTime(moment(item.interviewRounds.interviewEndTime));
    setCandidateEmail(item.candidateEmail);

    setInstantLink(item.interviewRounds.interviewLink);
  };

  const handleClose = () => {
    setShow(false);
    setMailList("email");
    setModalInterview(0);
    setInterviewTitleError(false);
    setCandidateEmailError("");
    setIsCopied("");
    setInterviewTitle("");
    setCandidateEmail("");
  };

  const copyLink = (data) => {
    setIsCopied(data);
    navigator.clipboard.writeText(instantLink);
  };

  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };

  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };
  function onCopy(textToCopy, index) {
    navigator.clipboard.writeText(textToCopy);
    setClickedButton(index);
  }
  const handleTimeClick = () => {
    setTimeout(() => {
      customFunction();
    }, 500);
  };

  const onCandidateEmailChange = (e) => {
    setCandidateEmail(e.target.value);
    setCandidateEmailError("");
  };

  const customFunction = () => {
    const element = document.getElementsByClassName(
      "rc-time-picker-panel-input"
    );

    if (element[0]) {
      element[0].addEventListener("blur", (event) => {
        const newValue = event.target.value.toString();

        // 'LT' is short for 12 hour format in moment.js
        // If the time is not after 11:15 AM, put a validation for that

        if (
          moment(newValue, "LT", true).isValid() &&
          moment(newValue, "LT").isSameOrAfter()
        ) {
          setStartTime(moment(newValue, "LT"));
          setEndTime(moment(newValue, "LT").add(30, "minutes"));
        }
      });
    }
  };

  const handlenextInterviewerChange = (value) => {
    setSelectedCandidate(value);
    setCandidateEmail(value.value);
  };

  function ValidateEmail(userEmail) {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (userEmail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const createScheduledInterview = () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();
    if (interviewTitle === "" && candidateEmail === "") {
      setInterviewTitleError(true);
      setCandidateEmailError("This field is required");
      return;
    } else if (!ValidateEmail(candidateEmail)) {
      setCandidateEmailError("Enter valid email");
    } else {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        email: candidateEmail,
        interviewTitle: interviewTitle,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      axios
        .put(
          `${BootcampBackend}/interview/updateInterview/` +
            selectedInterview._id +
            "/" +
            selectedInterview.interviewRounds._id,
          options,
          headers
        )
        .then((result) => {
          if (result.data.result.message == "Success") {
            fetchInterviewsPending();
          }
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      {isPageLoading ? (
        <div className="row m-2">
          <div className={`col-lg-12 mt-1`}>
            <LoadingShape style={{ height: "40px" }} />
          </div>
          <div className="col-lg-12 mt-4">
            <LoadingShape style={{ height: "110px" }} />
          </div>
        </div>
      ) : pendingInterviews.length > 0 ? (
        <>
          <div className="interviewsBoard">
            <div className="table-responsive listTablehld">
              <table className="table px-3 py-2">
                <thead>
                  <tr key={pendingInterviews._id}>
                    <th scope="col" className="list-table-heading">
                      INTERVIEW TITLE
                    </th>
                    <th scope="col" className="list-table-heading">
                      CANDIDATE NAME
                    </th>
                    {interviewStatus == "completed" && (
                      <th scope="col" className="list-table-heading">
                        RATING
                      </th>
                    )}

                    <th scope="col" className="list-table-heading">
                      SCHEDULED ON
                    </th>
                    {isAllInterviews === "allInterviews" && (
                      <th scope="col" className="list-table-heading">
                        INTERVIEWER NAME
                      </th>
                    )}
                    <th scope="col" className="list-table-heading">
                      NO. OF ROUNDS{" "}
                    </th>
                    <th scope="col" className="list-table-heading"></th>
                  </tr>
                </thead>
                <tbody>
                  {pendingInterviews && pendingInterviews.length > 0 ? (
                    pendingInterviews?.map((interview) => (
                      <tr key={interview._id}>
                        <td className="middle-align interview-title">
                          <p>{interview?.interviewTitle}</p>
                        </td>
                        <td className="middle-align user-icon-table">
                          <div className="  d-flex align-items-center">
                            &nbsp;&nbsp;
                            <p>
                              {interview?.candidateName
                                ? interview?.candidateName
                                : "-"}
                            </p>
                          </div>
                        </td>
                        <td className="scheduled-on-table">
                          <div
                            className={
                              interviewStatus == "completed"
                                ? "timings-card-div no-background-timings"
                                : "timings-card-div p-2 pe-4"
                            }
                          >
                            <ul className="timings-card-view ps-0">
                              {interview.currentRoundStatus == "completed" ? (
                                <>
                                  {/* Empty because it will create a vertical-align problem for the time */}
                                </>
                              ) : (
                                <li className="awaiting-acceptance mb-2">
                                  {interview.currentRoundStatus ==
                                  "completed" ? (
                                    <span
                                      className={`${
                                        interview.interviewRounds
                                          .acceptanceFromCandidate == "rejected"
                                          ? "acceptancyDecline"
                                          : interview.interviewRounds
                                              .acceptanceFromCandidate ==
                                            "accepted"
                                          ? "acceptancyAccept"
                                          : "acceptancyAwait"
                                      }`}
                                    ></span>
                                  ) : (
                                    <>
                                      <span
                                        className={`${
                                          interview.interviewRounds
                                            .acceptanceFromCandidate ==
                                          "rejected"
                                            ? "acceptancyDecline"
                                            : interview.interviewRounds
                                                .acceptanceFromCandidate ==
                                              "accepted"
                                            ? "acceptancyAccept"
                                            : "acceptancyAwait"
                                        }`}
                                      >
                                        {interview.interviewRounds
                                          .acceptanceFromCandidate == "rejected"
                                          ? "Declined"
                                          : interview.interviewRounds
                                              .acceptanceFromCandidate ==
                                            "accepted"
                                          ? "Accepted"
                                          : "Awaiting Acceptance"}
                                      </span>
                                    </>
                                  )}
                                </li>
                              )}
                              <div className="d-flex align-items-center interviewScheduleDate ">
                                <li className="">
                                  {interview.interviewRounds &&
                                    moment(
                                      interview.interviewRounds
                                        .interviewStartTime
                                    ).format("h:mm")}{" "}
                                  -{" "}
                                  {interview.interviewRounds &&
                                    moment(
                                      interview.interviewRounds.interviewEndTime
                                    ).format("h:mm   a")}
                                </li>
                                <li className="interviewDateDot"></li>
                                <li className="d">
                                  {interview.interviewRounds &&
                                    moment(
                                      interview.interviewRounds
                                        .interviewStartTime
                                    ).format("D MMM YYYY")}
                                </li>
                              </div>
                            </ul>
                          </div>
                        </td>

                        <td className="middle-align interview-title-name ">
                          <p>
                            {interview.interviewRounds.interviewer.firstName}
                            &nbsp;
                            {interview.interviewRounds.interviewer.lastName}
                          </p>
                        </td>

                        <td className="no-of-rounds-table font-size ">
                          <button
                            className={`no-of-rounds new-background NoOfRoundHld ${
                              isAllInterviews !== "allInterviews"
                                ? "roundBorderRadius"
                                : ""
                            } ${
                              interviewStatus === "completed"
                                ? "round-completed"
                                : ""
                            }`}
                          >
                            Round{" "}
                            {interview.interviewRounds &&
                              interview.interviewRounds.round}
                          </button>
                        </td>
                        {interviewStatus != "completed" ? (
                          <td className="middle-align  no-padding-copy-link">
                            {
                              // clicked button == index of the button clicked
                              clickedButton == interview._id ? (
                                <button className=" copy-link-class copied ">
                                  <img
                                    src={LinkIcon}
                                    className="img-fluid"
                                    goto="link"
                                  />
                                  <span className="copy-link-text copied">
                                    Copied !
                                  </span>
                                </button>
                              ) : (
                                <button
                                  className="copy-link-class "
                                  onClick={() =>
                                    onCopy(
                                      interview.interviewRounds.interviewLink,
                                      interview._id
                                    )
                                  }
                                  id={interview._id}
                                >
                                  <img
                                    src={LinkIcon}
                                    className="img-fluid"
                                    goto="link"
                                  />
                                  <span className="copy-link-text ">
                                    Copy Link
                                  </span>
                                </button>
                              )
                            }
                          </td>
                        ) : (
                          " "
                        )}
                        <td className="middle-align pe-4">
                          <div
                            className="interviewTabDeleteButton d-flex align-items-center justify-content-center"
                            onClick={() => deleteInterview(interview)}
                          >
                            <img
                              src={Delete}
                              className="image-fluid interviewTabEditIcon"
                            />
                            &nbsp;&nbsp;
                            <p>Delete Interview</p>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h4 className="d-flex align-items-center justify-content-center">
                      No Interviews
                    </h4>
                  )}
                </tbody>
              </table>
            </div> 
            {pendingInterviews ? (
              <div className="interviewPagination py-3">
                <Pagination
                  className="rainbow-m_auto"
                  pages={Math.ceil(totalCount / 9)}
                  setActivePage={activePage}
                  onChange={handleOnChange}
                  variant="shaded"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <h4 className="d-flex align-items-center justify-content-center my-5">
          No Interviews
        </h4>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1">
            <h5>Edit an Interview</h5>
            <img
              src={Cross}
              onClick={handleClose}
              className="img-fluid"
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center schedule">
            <div>
              <input
                className="copyLink mt-3 p-2"
                value={instantLink}
                // readOnly={isEditable || createRound}
                disabled
              />
              <button
                className={
                  isCopied
                    ? "copyBtn text-center px-1"
                    : "copyBtn text-center p-2"
                }
                onClick={() => copyLink("schedule")}
              >
                <p>
                  <img className="me-1 my-0" src={Copy} />
                  {isCopied !== "schedule" ? (
                    <div style={{ display: "inline" }}>Copy</div>
                  ) : (
                    <div style={{ display: "inline", fontSize: "12px" }}>
                      Copied!
                    </div>
                  )}
                </p>
              </button>
            </div>

            <div className="mt-3 schedulingDiv px-3">
              <h6>Interview Title</h6>
              <input
                className="copyLink mt-3 p-2"
                placeholder="Enter Interview Title"
                onChange={oninterviewTitleChange}
                value={interviewTitle}
                // readOnly={createRound}
              />
              {interviewTitleError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  This Field is required
                </p>
              )}
              {/* Date Picker Here START */}

              <h6 className="mt-4 mb-3">Select Date</h6>
              <div className="datepicker">
                <img src={DateIcon} className="img-fluid me-2" />

                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={handleDateChange}
                  isValidDate={valid}
                  dateFormat="DD MMM YYYY"
                  value={selectedDate}
                />
                {/* Date Picker Here End */}
              </div>

              {/* <ComboDatePicker/> */}
              <h6 className="mt-4 mb-3">Time Slot</h6>
              <div className="d-flex justify-content-start margin-top-bottom">
                <img src={Clock} className="img-fluid me-2" />
                <TimePicker
                  value={startTime}
                  minuteStep={30}
                  use12Hours={true}
                  showSecond={false}
                  onChange={handleTimeChange}
                  onOpen={handleTimeClick}
                  defaultValue={moment()}
                  clearText=""
                  clearIcon=""
                />
                <span className="rc-time-picker-tofield">TO</span>
                <TimePicker
                  value={endTime}
                  minuteStep={30}
                  use12Hours={true}
                  showSecond={false}
                />
              </div>

              <div className="my-4 d-flex">
                <button
                  className={
                    mailList === "MHList"
                      ? `interviewListselected`
                      : `interviewList`
                  }
                  style={{ borderRadius: "3px 0 0 3px" }}
                  onClick={(e) => {
                    setMailList("MHList");
                  }}
                >
                  From your MH List
                </button>
                <button
                  className={
                    mailList === "email"
                      ? `interviewListselected`
                      : `interviewList`
                  }
                  style={{ borderRadius: "0 3px 3px 0" }}
                  onClick={(e) => {
                    setMailList("email");
                  }}
                >
                  Email ID
                </button>
              </div>
              {mailList === "email" && (
                <>
                  <div>
                    <h6 className="mb-1">Candidate Email</h6>
                    <input
                      className="copyLink mt-3 p-2"
                      onChange={onCandidateEmailChange}
                      value={candidateEmail}
                      placeholder="Enter Candidate Email"
                      // readOnly={createRound}
                    />
                  </div>
                  {candidateEmailError !== "" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {candidateEmailError}
                    </p>
                  )}
                </>
              )}
              {mailList === "MHList" && (
                <div style={{ color: "#e4e4e4" }}>
                  <h6>Candidate Name</h6>
                  <div className="mt-2">
                    <Select
                      styles={customStyles}
                      value={selectedCandidate}
                      onChange={handlenextInterviewerChange}
                      options={Mhlist}
                      placeholder="Search or choose from List"
                    />
                    <div></div>
                  </div>
                </div>
              )}
            </div>
            <div className="px-3">
              <button
                className="my-4 scheduleInterviewlg"
                // onClick={() => setModalInterview("")}
                onClick={() => createScheduledInterview()}
              >
                Edit an Interview
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpComingInterviews;
