import Header from "components/Header/Header";
import RangeSlider from "react-bootstrap-range-slider";
import Navbar from "components/Navbar/Navbar";
import hackathonSearch from "../../../assets/Icons/MyHackathons/hackathonSearch.svg";
import ClearParticipantsFilter from "../../../assets/Icons/Assessment/ClearParticipantsFilter.svg";
import TagCross from "../../../assets/Icons/Assessment/TagCross.svg";
import GrayDropdownArrow from "../../../assets/Icons/Assessment/GrayDropdownArrow.svg";
import startArrow from "../../../assets/Icons/MyHackathons/startArrow.svg";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import QuestionCard from "./components/QuestionCard";
import QuestionPreviewPopup from "./components/QuestionPreviewPopup";
import AddQuestion from "../../../assets/Icons/Assessment/AddQuestion.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import AddQuestions from "./components/AddQuestions";
import { useSidebar } from "helper/SideBarContext";
export default function QuestionList({ props, selectedQuestion }) {
  const { isSidebarCollapsed } = useSidebar();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState({
    original: [],
    totalQuestions: 0,
    totalSelectedQues: 0
  });
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [openPreview, setOpenPreview] = useState(false);
  const [removedQuestions, setRemovedQuestions] = useState([]);
  const [openQuesModal, setOpenQuesModal] = useState(false);
  const [filters, setFilters] = useState({
    role: "",
    difficulty: [],
    recommendedTime: "",
    tags: [],
    duration: 0,
    skills: [],
    categories: [],
  });
  const [collapsed, setCollapsed] = useState({
    category: true,
    difficulty: true,
    recommendedTime: true,
    tags: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [tagsList, setTagList] = useState([]);
  const [showQuesExceptSectionQues, setShowQuesExceptSectionQues] =
    useState(false);
  const [questionType, setQuestionType] = useState("All");
  const history = useHistory();
  const params = useParams();
  const isEditQuestions = JSON.parse(localStorage.getItem("isEditQuestions"));
  const [skillslist, setSkillslist] = useState([]);
  const [showSkillsDrop, setShowSkillsDrop] = useState(false);
  const [skills, setSkills] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [currentSkillsPage, setCurrentSkillsPage] = useState(1);
  const [categories, setCategories] = useState({
    original: [],
    filtered: [],
  });
  const [fromMhLibrary, setfromMhLibrary] = useState("");
  const [showCategoryDrop, setShowCategoryDrop] = useState(false);
  const [DontApplyFilter, setDontApplyFilter] = useState(false);
  const inputEl = useRef(null);
  const wrapperRef = useRef(null);

  const DifficultyLevel = [
    {
      _id: "1",
      label: "Easy",
      value: params?.questionType == "mcq" ? "BEGINNER" : "Beginner",
    },
    {
      _id: "2",
      label: "Intermediate",
      value: params?.questionType == "mcq" ? "INTERMEDIATE" : "Intermediate",
    },
    {
      _id: "3",
      label: "Advanced",
      value: params?.questionType == "mcq" ? "ADVANCED" : "Advanced",
    },
  ];
  useOutsideAlerter(wrapperRef);
  const handleCollapse = (e, prop, val) => {
    e.preventDefault();
    setCollapsed({ ...collapsed, [prop]: val });
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSkillsDrop(false);
          setSkills("");
          setCurrentSkillsPage(1);
          getAllSkills(currentSkillsPage, "");
          setShowCategoryDrop(false);
          setCategorySearch("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    getAllSkills(currentSkillsPage, "");
  }, [skills]);

  const getAllSkills = async (page, type) => {
    try {
      const paramData = {
        current_page: page,
      };
      skills !== "" && (paramData.searchString = skills);
      const response = await axios
        .get(`${BootcampBackend}/skills/getAllSkillsEnterprise`, {
          params: paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((response) => {
          type === "scroll"
            ? setSkillslist((prev) => [
              ...prev,
              ...response?.data?.result?.data,
            ])
            : setSkillslist(response?.data?.result?.data);

          setCurrentSkillsPage(page);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSkillChange = (e) => {
    setSkills(e.target.value);
    setShowSkillsDrop(true);
    setCurrentSkillsPage(1);
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (skillslist.length > 0) {
        addSkills(skillslist[0]?.name);
      } else {
        setSkills("");
      }
    }
  };
  const addSkills = (skills) => {
    if (skills) {
      setFilters((prev) => ({
        ...prev,
        skills: [...(filters?.skills || []), skills],
      }));
      inputEl.current.focus();
      setSkills("");
      setShowSkillsDrop(false);
    }
  };
  const addcategories = (categories) => {
    if (categories) {
      const Categories = [...filters.categories];
      if (Categories?.length === 0) {
        Categories.push(categories);
        setFilters((prev) => ({
          ...prev,
          categories: Categories,
        }));
      } else if (Categories?.some((item) => item._id === categories._id)) {
        setFilters((prev) => ({
          ...prev,
          categories: prev?.categories?.filter((x) => x._id !== categories._id),
        }));
      } else {
        setFilters((prev) => ({
          ...prev,
          categories: prev?.categories?.concat(categories),
        }));
      }
      setShowCategoryDrop(false);
      setCategorySearch("");
    }
  };
  const handleSkillScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      getAllSkills(currentSkillsPage + 1, "scroll");
    }
  };

  const handleDelete = (item) => {
    setFilters((prev) => ({
      ...prev,
      skills: filters?.skills.filter((i) => i !== item),
    }));
  };
  const handleCategoryDelete = (item) => {
    setFilters((prev) => ({
      ...prev,
      categories: filters?.categories.filter((i) => i._id !== item._id),
    }));
  };
  const getQuestions = async (currentPage) => {
    setIsLoading(true);
    const paramData = {
      limit: 20,
      current_page: currentPage,
    };
    if (selectedQuestion.length > 0) {
      params?.questionType == "mcq"
        ? (paramData.questionSetIds = selectedQuestion.toString())
        : (paramData.categories = selectedQuestion.toString());
    }
    if (filters.difficulty.length > 0) {
      let difficulty = [];
      filters.difficulty.filter((x) => {
        difficulty.push(x.value);
      });
      params?.questionType == "mcq"
        ? (paramData.difficultyType = difficulty.toString())
        : (paramData.difficulty = difficulty.toString());
    }
    questionType === "myCompany" && (paramData.questionType = questionType);
    filters.duration > 0 && (paramData.duration = filters.duration * 60);
    if (filters.skills.length > 0) {
      let skills = [];
      filters.skills.filter((x) => {
        skills.push(x);
      });
      paramData.skills = skills.toString();
    }
    if (filters.tags.length > 0) {
      let tags = [];
      filters.tags.filter((x) => {
        tags.push(x._id);
      });
      paramData.tags = tags.toString();
    }
    if (filters.categories.length > 0) {
      let categories = [];
      filters.categories.filter((x) => {
        categories.push(x._id);
      });
      paramData.categories = categories.toString();
    }
    let url = `${BootcampBackend}/enterpriseAssessment/${params?.questionType == "mcq"
      ? `getAllQuestionsFromQuestionSet`
      : `getAllPracticeQuestionSetEnterprise`
      }`;
    try {
      const res = await axios.get(url, {
        params: paramData,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (res) {
        setCurrentPage(currentPage);
      }
      return res;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleRemoveQuestions = (e, question) => {
    e.stopPropagation();
    let copyArray = [...removedQuestions];
    if (removedQuestions.includes(question._id)) {
      copyArray.splice(removedQuestions.indexOf(question._id), 1);
    } else {
      copyArray.push(question._id);
    }
    setRemovedQuestions(copyArray);
  };

  const handleClearFilter = () => {
    setFilters((prev) => ({
      ...prev,
      role: "",
      difficulty: [],
      recommendedTime: "0",
      tags: [],
      type: [],
      skills: [],
      duration: "",
    }));
  };

  const addQuestions = async () => {
    try {
      const requestBody = {
        assessmentId: params.assessmentId,
        sectionType: params?.questionType === "mcq" ? "MCQ" : "CODING",
        mcqQuestions: params?.questionType === "mcq" ? removedQuestions : [],
        practiceQuestions:
          params?.questionType === "mcq" ? [] : removedQuestions,
      };
      const addQuestionSetResponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/addQuestionsToEnterpriseSection/${params.sectionId}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (addQuestionSetResponse) {
        fromMhLibrary === "mh-library"
          ? (setShowQuesExceptSectionQues(false),
            getSectionQuestions(1, false, true),
            setDontApplyFilter(true),
            handleClearFilter(),
            setfromMhLibrary(""))
          : (history.push(
            `/assessments/${params.assessmentId}/questions`
          ),
            localStorage.removeItem("selectedQuestions"));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getSectionQuestions = async (page, isScroll, removeFilter) => {
    try {
      const paramData = {
        limit: 20,
        current_page: page,
      };
      if (filters.difficulty.length > 0 && !removeFilter) {
        let difficulty = [];
        filters.difficulty.filter((x) => {
          difficulty.push(x.value);
        });
        params?.questionType == "mcq"
          ? (paramData.difficultyType = difficulty.toString())
          : (paramData.difficulty = difficulty.toString());
      }
      questionType === "myCompany" &&
        !removeFilter &&
        (params?.questionType == "mcq"
          ? (paramData.questionType = questionType)
          : (paramData.codingQuestionType = questionType));
      filters.duration > 0 &&
        !removeFilter &&
        (params?.questionType == "mcq"
          ? (paramData.duration = filters.duration * 60)
          : (paramData.time = filters.duration * 60));
      if (filters.skills.length > 0 && !removeFilter) {
        let skills = [];
        filters.skills.filter((x) => {
          skills.push(x);
        });
        paramData.skills = skills.toString();
      }
      if (filters.tags.length > 0 && !removeFilter) {
        let tags = [];
        filters.tags.filter((x) => {
          tags.push(x._id);
        });
        paramData.tags = tags.toString();
      }
      if (filters.categories.length > 0 && !removeFilter) {
        let categories = [];
        filters.categories.filter((x) => {
          categories.push(x._id);
        });
        paramData.categories = categories.toString();
      }
      const addQuestionSetResponse = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllQuestionsUnderSections/${params.sectionId}`,
        {
          params: paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (addQuestionSetResponse) {
        setCurrentPage(page);
        // setfromMhLibrary("");
        setIsLoading(false);
        addQuestionSetResponse.data.result.questions.filter((x) => {
          x.isSectionQues = showQuesExceptSectionQues ? false : true;
          fromMhLibrary === "mh-library" && (x.isSectionQues = true);
        });
        isScroll
          ? setList((prev) => ({
            ...prev,
            original: [
              ...prev.original,
              // ...addQuestionSetResponse.data.result.questions,
            ],
            totalQuestions: prev.totalQuestions,
            totalSelectedQues: addQuestionSetResponse.data.result.totalQuestions
          }))
          : setList({
            original: addQuestionSetResponse.data.result.questions,
            totalQuestions: addQuestionSetResponse.data.result.totalQuestions,
            totalSelectedQues: addQuestionSetResponse.data.result.totalQuestions
          });
        if(removedQuestions.length < addQuestionSetResponse.data.result.totalQuestions){
          const questionIds = [];
          const questionArray = isScroll
            ? [...list.original, ...addQuestionSetResponse.data.result.questions]
            : addQuestionSetResponse.data.result.questions;
          questionArray.filter((x) => {
            questionIds.push(x._id);
          });
          setRemovedQuestions(questionIds);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addQuestion = async (questionType, questionfrom) => {
    try {
      questionfrom === "mh-library"
        ? history.push(
          `/assessments/${params.assessmentId}/${props.match.params.questionType}/questions-set/${props.match.params.sectionId}`
        )
        // (setShowQuesExceptSectionQues(true),
        //   setfromMhLibrary("mh-library"),
        //   getQuestions(currentPage, true)
        //     .then((response) => {
        //       setIsLoading(false);
        //       response.data.result.data.filter((x) => {
        //         x.isSectionQues = false;
        //       });
        //       setList((prev) => ({
        //         original: [...prev.original, ...response.data.result.data],
        //         totalQuestions:
        //           prev.totalQuestions +
        //           (params?.questionType === "mcq"
        //             ? response.data.result.totalQuestions
        //             : response.data.result.totalQuestionSet),
        //       }));
        //       setOpenQuesModal(false);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     }))
        : (setShowQuesExceptSectionQues(true),
          history.push(
            `/assessments/${params.assessmentId}/create/${questionType}/${params.sectionId}`
          ));
    } catch (err) {
      console.log(err);
    }
  };

  const getQuestionsExceptSelectedQues = () => {
    getQuestions(currentPage + 1, showQuesExceptSectionQues)
    .then((response) => {
      setIsLoading(false);
      response.data.result.data.filter((x) => {
        x.isSectionQues = showQuesExceptSectionQues ? false : true;
      });
      setList((prev) => ({
        ...prev,
        original: [...prev.original, ...response.data.result.data],
        totalQuestions: prev.totalQuestions,
      }));
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleScroll = (e,isBottom) => {
    const bottom = isBottom ? isBottom :
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    if (bottom) {
      if (list.totalQuestions > list.original.length) {
        // setCurrentPage((prev) => prev + 1);
        isEditQuestions && !showQuesExceptSectionQues
          ? list.totalSelectedQues > removedQuestions.length ? getSectionQuestions(currentPage + 1, true, false) : getQuestionsExceptSelectedQues()
          : getQuestions(currentPage + 1, showQuesExceptSectionQues)
            .then((response) => {
              setIsLoading(false);
              response.data.result.data.filter((x) => {
                x.isSectionQues = showQuesExceptSectionQues ? false : true;
              });
              setList((prev) => ({
                ...prev,
                original: [...prev.original, ...response.data.result.data],
                totalQuestions: prev.totalQuestions,
                // params?.questionType === "mcq"
                //   ? response.data.result.totalQuestions
                //   : response.data.result.totalQuestionSet,
              }));
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
  };

  const handleDifficultyFilter = (difficultyData) => {
    const difficulty = [...filters.difficulty];
    if (difficulty?.length === 0) {
      difficulty.push(difficultyData);
      setFilters((prev) => ({
        ...prev,
        difficulty: difficulty,
      }));
    } else if (difficulty?.some((item) => item._id === difficultyData._id)) {
      setFilters((prev) => ({
        ...prev,
        difficulty: prev?.difficulty?.filter(
          (x) => x._id !== difficultyData._id
        ),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        difficulty: prev?.difficulty?.concat(difficultyData),
      }));
    }
  };

  const handleTagsFilter = (tagsData) => {
    const tags = [...filters.tags];
    if (tags?.length === 0) {
      tags.push(tagsData);
      setFilters((prev) => ({
        ...prev,
        tags: tags,
      }));
    } else if (tags?.some((item) => item._id === tagsData._id)) {
      setFilters((prev) => ({
        ...prev,
        tags: prev?.tags?.filter((x) => x._id !== tagsData._id),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        tags: prev?.tags?.concat(tagsData),
      }));
    }
  };

  const getAllQuestion = (page) => {
    getQuestions(page, false)
      .then((response) => {
        setIsLoading(false);
        response.data.result.data.filter((x) => {
          x.isSectionQues = showQuesExceptSectionQues ? false : true;
        });
        setList(prev => ({
          ...prev,
          original: response.data.result.data,
          totalQuestions:
            params?.questionType === "mcq"
              ? response.data.result.totalQuestions
              : response.data.result.totalQuestionSet,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showClearAll = () => {
    if (
      filters?.categories?.length > 0 ||
      filters?.difficulty.length > 0 ||
      filters?.duration === "" ||
      filters?.role?.length > 0 ||
      filters?.skills?.length > 0 ||
      filters?.tags?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setCurrentQuestion(0);
    if (!DontApplyFilter) {
      if (isEditQuestions) {
        getSectionQuestions(1, false, false);
        selectedQuestion.length > 0 && getAllQuestion(1);
      } else {
        selectedQuestion.length > 0 && getAllQuestion(1);
      }
    } else {
      setDontApplyFilter(false);
    }
  }, [selectedQuestion, filters, questionType]);

  const getTags = () => {
    axios
      .get(`${BootcampBackend}/practices/getAllPracticeTagsUser`)
      .then((response) => {
        if (response.data.status.code === 200) {
          setTagList(response.data.result.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getCategories = () => {
    axios
      .get(`${BootcampBackend}/practices/getAllPracticeCategoriesUser`)
      .then((response) => {
        if (response.data.status.code === 200) {
          setCategories({
            original: response.data.result.data,
            filtered: response.data.result.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCategoryChange = (e) => {
    setCategorySearch(e.target.value);
    setShowCategoryDrop(true);
    let filteredList = categories.original.filter((ele) =>
      ele.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (filteredList !== categories.filtered) {
      setCategories({ ...categories, filtered: filteredList });
    }
  };

  useEffect(() => {
    params?.questionType == "coding" && (getTags(), getCategories());
  }, []);
  return (
    <>
      <Header heading="Questions"/>
      <Navbar/>
      <div
        className={`AssessmentCntr QuestionListContainer ${isSidebarCollapsed ? "collapsed-content-page" : "content-page"
          }`}
      >
        <div className="col-md-12 d-flex justify-content-center QuestionListHeader">
          <div className="d-flex align-items-center">
            {/* {isLoading ? (
              <LoadingShape className="loaderForLabel" />
            ) : ( */}
            <span
              className={`me-3 cursor-pointer ${questionType === "All" ? "cardHeading" : "formInput"
                }`}
              onClick={() => setQuestionType("All")}
            >
              All Questions
            </span>
            {/* )} */}
            {/* {isLoading ? ( 
              <LoadingShape className="loaderForLabel" />
            ) : ( */}
            <span
              className={`me-3 cursor-pointer ${questionType === "mhQues" ? "cardHeading" : "formInput"
                }`}
              onClick={() => setQuestionType("mhQues")}
            >
              MH Questions
            </span>
            <span
              className={`cursor-pointer ${questionType === "myCompany" ? "cardHeading" : "formInput"
                }`}
              onClick={() => setQuestionType("myCompany")}
            >
              My Company Questions
            </span>
            {/* )} */}
          </div>
          <div className="d-flex ms-auto col-md-4 justify-content-end">
            {/* <div className="SearchQuestionListInput d-flex ms-auto col-md-6 me-3">
              <img className="me-2" src={hackathonSearch} />
              <input placeholder="Search for a questions" />
            </div> */}
            {/* {removedQuestions?.length > 0 ? ( */}
            <button onClick={addQuestions} className="secondaryColoredBtn">
              Save Section
              <img
                className="ms-3 img-fluid"
                src={startArrow}
                alt="arrow"
                loading="lazy"
              />
            </button>
            {/* ) : (
              <button className="secondaryColoredBtn disabledRemoveQestionBtn">
                Submit
                <img
                  className="ms-3 img-fluid"
                  src={startArrow}
                  alt="arrow"
                  loading="lazy"
                />
              </button>
            )} */}
          </div>
        </div>
        <div className="d-flex">
          <div className="QuestionListTable position-relative">
            <div className="d-flex QuestionListCount align-items-center">
              <span className="paragraph">{list.totalQuestions} Questions</span>
              {/* <div className="ms-auto QuestionListSortBy">
                Sort by
                <img className="ms-2" src={SortDownArrow} />
              </div> */}
            </div>

            <div className="QuestionListTableBody" onScroll={handleScroll}>
              {list.original.map((questionObj, i) => (
                questionType === "mhQues" ? !questionObj.enterpriseCompany && <QuestionCard
                  index={i}
                  key={i}
                  questionObj={questionObj}
                  questionType={params?.questionType}
                  questionlistType={questionType}
                  setCurrentQuestion={setCurrentQuestion}
                  handleRemoveQuestions={handleRemoveQuestions}
                  removedQuestions={removedQuestions}
                  isEdit={isEditQuestions ? true : false}
                  sectionId={params.sectionId}
                  history={history}
                  setOpenPreview={setOpenPreview}
                  assessmentId={params.assessmentId}
                />
                  : <QuestionCard
                    index={i}
                    key={i}
                    questionObj={questionObj}
                    questionType={params?.questionType}
                    setCurrentQuestion={setCurrentQuestion}
                    handleRemoveQuestions={handleRemoveQuestions}
                    removedQuestions={removedQuestions}
                    isEdit={isEditQuestions ? true : false}
                    sectionId={params.sectionId}
                    history={history}
                    selectedQuestion={selectedQuestion}
                    setOpenPreview={setOpenPreview}
                    assessmentId={params.assessmentId}
                    />

              ))}
              {isEditQuestions && !showQuesExceptSectionQues && (
                <button
                  className="secondaryColoredBtn addquestionsfromlist py-2 px-4"
                  onClick={() => {
                    handleClearFilter(), setOpenQuesModal(true);
                  }}
                >
                  Add questions{" "}
                  <img
                    src={AddQuestion}
                    alt="add"
                    className="img-fluid"
                    loading="lazy"
                  />
                </button>
              )}
            </div>
          </div>
          {list.totalQuestions !== 0 && (
            <>
              <div className="QuestionListFilter pb-5">
                {showClearAll() && (
                  <button
                    className="QuestionListFilterClearBtn ms-auto d-flex justify-content-between"
                    onClick={handleClearFilter}
                  >
                    Clear all
                    <img src={ClearParticipantsFilter} className="ms-2" />
                  </button>
                )}
                {filters?.category?.length > 0 && (
                  <div className="CategoryQuestionListLable mt-3">Category</div>
                )}
                <div className="d-flex flex-wrap my-2">
                  {filters.category?.map((element, i) => (
                    <div className="CategoryQuestionListTag" key={i}>
                      {element}
                      <img className="ms-2" src={TagCross} />
                    </div>
                  ))}
                </div>
                {filters.difficulty.length > 0 && (
                  <>
                    <div className="CategoryQuestionListLable mt-3">
                      Difficulty
                    </div>
                    <div className="d-flex flex-wrap my-2">
                      {filters.difficulty?.map((object, i) => (
                        <div
                          className="CategoryQuestionListTag"
                          key={i}
                          onClick={() => handleDifficultyFilter(object)}
                        >
                          {object.label}
                          <img
                            className="ms-2 img-fluid"
                            src={TagCross}
                            loading="lazy"
                            alt="close"
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {/* <div className="CategoryQuestionListLable  mt-3">Select Role</div>
            <div className="SearchQuestionListInput d-flex my-2">
              <img className="me-2" src={hackathonSearch} />
              <input placeholder="Select a Role or Type in" />
              <img className="ms-auto" src={GrayDropdownArrow} />
            </div> */}
                {params?.questionType === "mcq" && (
                  <>
                    <div className="CategoryQuestionListLable d-flex justify-content-between align-items-center mt-3">
                      <span> Skills </span>
                      <img
                        src={GrayDropdownArrow}
                        loading="lazy"
                        className={`img-fluid ${collapsed.skills ? `arrowRotateCollapse` : ``}`}
                        alt="click"
                        onClick={(e) =>
                          handleCollapse(e, "skills", !collapsed.skills)
                        }
                      />
                    </div>
                    {!collapsed.skills && (
                      <>
                        <div className="d-flex flex-wrap my-2">
                          {filters?.skills?.length > 0 &&
                            filters?.skills?.map((item) => (
                              <div
                                className="CategoryQuestionListTag"
                                key={item}
                              >
                                {item}
                                <img
                                  className="img-fluid ms-3"
                                  src={TagCross}
                                  alt="x"
                                  onClick={() => handleDelete(item)}
                                />
                              </div>
                            ))}
                        </div>

                        <div className="SearchQuestionListInput d-flex my-2 position-relative">
                          <img className="me-2" src={hackathonSearch} />
                          <input
                            placeholder="Search skills"
                            ref={inputEl}
                            value={skills}
                            onChange={handleSkillChange}
                            onKeyDown={handleKeyDown}
                          />
                          {showSkillsDrop && (
                            <div
                              className="AssessmentTabsOptions mt-2"
                              ref={wrapperRef}
                              onScroll={handleSkillScroll}
                            >
                              {skillslist.length > 0 &&
                                skillslist?.map((skill) => (
                                  <p
                                    key={skill._id}
                                    onClick={() => addSkills(skill.name)}
                                    className="mb-0"
                                  >
                                    {skill?.name}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                        <div className="QuestionListFilterDivider"></div>
                      </>
                    )}
                  </>
                )}

                {params?.questionType === "coding" && (
                  <>
                    <div className="CategoryQuestionListLable d-flex justify-content-between align-items-center mt-3">
                      <span> Category </span>
                      <img
                        src={GrayDropdownArrow}
                        loading="lazy"
                        className={`img-fluid ${collapsed.category ? `` : `arrowRotateCollapse`}`}
                        alt="click"
                        onClick={(e) =>
                          handleCollapse(e, "category", !collapsed.category)
                        }
                      />
                    </div>
                    {collapsed.category && (
                      <>
                        {" "}
                        <div className="d-flex flex-wrap my-2">
                          {filters?.categories?.length > 0 &&
                            filters?.categories?.map((item) => (
                              <div
                                className="CategoryQuestionListTag"
                                key={item}
                              >
                                {item?.title}
                                <img
                                  className="img-fluid ms-3"
                                  src={TagCross}
                                  alt="x"
                                  onClick={() => handleCategoryDelete(item)}
                                />
                              </div>
                            ))}
                        </div>
                        <div className="SearchQuestionListInput d-flex my-2 position-relative">
                          <img className="me-2" src={hackathonSearch} />
                          <input
                            placeholder="Search skills"
                            value={categorySearch}
                            onChange={handleCategoryChange}
                          />
                          {showCategoryDrop && (
                            <div
                              className="AssessmentTabsOptions mt-2"
                              ref={wrapperRef}
                            >
                              {categories.filtered.length > 0 &&
                                categories.filtered?.map((category) => (
                                  <p
                                    key={category._id}
                                    onClick={() => addcategories(category)}
                                    className="mb-0"
                                  >
                                    {category?.title}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                        <div className="QuestionListFilterDivider"></div>
                      </>
                    )}
                  </>
                )}

                <div className="CategoryQuestionListLable d-flex justify-content-between align-items-center mt-3">
                  <span> Difficulty </span>
                  <img
                    src={GrayDropdownArrow}
                    className={`img-fluid ${collapsed.difficulty ? `` : `arrowRotateCollapse`}`}
                    alt="arrow"
                    loading="lazy"
                    onClick={(e) =>
                      handleCollapse(e, "difficulty", !collapsed.difficulty)
                    }
                  />
                </div>
                {collapsed.difficulty &&
                  DifficultyLevel.map((level) => (
                    <div
                      className="QuestionListSkillTagCheckboxDiv"
                      key={level?._id}
                    >
                      <input
                        type="checkbox"
                        className="QuestionListSkillTagCheckbox"
                        checked={filters.difficulty?.some(
                          (item) => item._id === level._id
                        )}
                        onChange={() => handleDifficultyFilter(level)}
                      />
                      <span className="paragraph mb-1">{level?.label}</span>
                    </div>
                  ))}
                <div className="QuestionListFilterDivider"></div>
                <div className="CategoryQuestionListLable d-flex justify-content-between align-items-center mt-3 mb-4">
                  <span> Recommended Time</span>
                  <img
                    src={GrayDropdownArrow}
                    className={`img-fluid ${collapsed.recommendedTime ? `` : `arrowRotateCollapse`}`}
                    alt=""
                    loading="lazy"
                    onClick={(e) =>
                      handleCollapse(
                        e,
                        "recommendedTime",
                        !collapsed.recommendedTime
                      )
                    }
                  />
                </div>
                {collapsed.recommendedTime && (
                  <>
                    <RangeSlider
                      value={filters.duration}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          duration: e.target.value,
                        }))
                      }
                      tooltip="auto"
                      variant="dark"
                      tooltipPlacement="top"
                      min={0}
                      max={200}
                    />
                    <div className="d-flex justify-content-between">
                      <span className="rangeSliderText">0 mins</span>
                      <span className="rangeSliderText">{">"}200 mins</span>
                    </div>
                  </>
                )}

                {tagsList.length > 0 && (
                  <div className="CategoryQuestionListLable d-flex justify-content-between align-items-center mt-3">
                    <span> Tags </span>
                    <img
                      src={GrayDropdownArrow}
                      className={`${collapsed.tags ? `` : `arrowRotateCollapse`}`}
                      onClick={(e) =>
                        handleCollapse(e, "tags", !collapsed.tags)
                      }
                    />
                  </div>
                )}

                {collapsed.tags &&
                  tagsList.map((tags, index) => (
                    <div
                      className="QuestionListSkillTagCheckboxDiv"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className="QuestionListSkillTagCheckbox"
                        onChange={() => handleTagsFilter(tags)}
                      />
                      <span className="paragraph mb-1">{tags.title}</span>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
      <QuestionPreviewPopup
        show={openPreview}
        setOpenPreview={setOpenPreview}
        questionType={params?.questionType}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        questions={list.original}
        isQuestionSelected={
          isEditQuestions && list.original[currentQuestion]?.isSectionQues
        }
        selectedQuestion={selectedQuestion}
        handleRemoveQuestions={handleRemoveQuestions}
        removedQuestions={removedQuestions}
        handleScroll={handleScroll}
      />
      <AddQuestions
        show={openQuesModal}
        onClose={() => setOpenQuesModal(false)}
        originPage="question-list"
        questionType={params?.questionType}
        addSection={addQuestion}
      />
    </>
  );
}
