import React from "react";
import activeDownload from "assets/Icons/ResumeSearch/activeDownload.svg";
import activeViewResume from "assets/Icons/ResumeSearch/activeViewResume.svg";
import downloadSvg from "assets/Icons/ResumeSearch/download.svg";
import eyeSvg from "assets/Icons/ResumeSearch/viewResume.svg";
import bookmarkSvg from "assets/Icons/ResumeSearch/bookmark.svg";
import ActiveBookmarkSvg from "assets/Icons/ResumeSearch/active-bookmark.svg";
export default function UserListHld({
  bookmarkResume,
  handleDownloadResume,
  selectedUser,
  handleScrollCandidateInfo,
  setSelectedUser,
  handleViewResume,
  candidateInfo,
  index,
}) {
  return (
    <div
      key={`candidateInfo${index}`}
      onClick={() => {
        handleScrollCandidateInfo();
        setSelectedUser(candidateInfo);
      }}
      className={`${
        selectedUser?._id === candidateInfo?._id ? `selectedUserCard` : ``
      } userInfoCard mb-2 col-md-4 cursor-pointer ${
        selectedUser?._id ? "userInfoCardWhenUserDetails" : ""
      }`}
    >
      <div className="row mx-0">
        <div className="col px-0">
          <div className="d-flex mx-0">
            <img className="userImage me-2" src={candidateInfo?.Picture} />
            <div className="pt-1">
              <p className="useNameText">{candidateInfo?.Name}</p>
              <p className="grayBtnText">{candidateInfo?.LatestDesignation}</p>
              <p className="darkBtnText grayBtnText mt-1">
                {candidateInfo?.city || candidateInfo?.country}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 px-0">
          <div
            className={`d-flex ${
              candidateInfo?.Resume_key
                ? "justify-content-between"
                : "justify-content-end"
            } mt-4`}
          >
            {candidateInfo?.Resume_key && (
              <div
                className={`${
                  selectedUser?._id === candidateInfo?._id ? `viewResume` : ``
                } bookmarkHld cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewResume(candidateInfo?.Resume_key);
                }}
              >
                <img
                  src={
                    selectedUser?._id === candidateInfo?._id
                      ? activeViewResume
                      : eyeSvg
                  }
                  alt="eye"
                />
              </div>
            )}
            <div
              className={`bookmarkHld cursor-pointer ${
                candidateInfo?.isBookmarked ? "activebookmark" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                bookmarkResume(candidateInfo);
              }}
            >
              <img
                className="bookmarkSvg"
                src={
                  candidateInfo?.isBookmarked ? ActiveBookmarkSvg : bookmarkSvg
                }
                alt="downloadSvg"
              />
            </div>
            {candidateInfo?.Resume_key && (
              <img
                className="downloadSvg cursor-pointer"
                src={
                  selectedUser?._id === candidateInfo?._id
                    ? activeDownload
                    : downloadSvg
                }
                alt="downloadSvg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownloadResume(candidateInfo?.Resume_key);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
