import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "./userReport.css";
import createInterviewIcon from "../../../assets/Icons/Interview/New/createInterview.svg";
import shareIcon from "../../../assets/Icons/Assessment/share.svg";
import checkedIcon from "../../../assets/Icons/Settings/checked.svg";
import DownArrow from "../../../assets/Icons/Assessment/downArrow.svg";
import Dropdown from "./components/dropDown";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { LoadingShape } from "react-rainbow-components";
import axios from "axios";
import { BootcampBackend } from "backend";
import ToastMessages from "./components/ToastMessages";
import { Domain } from "backend";
import CreateInterviewModal from "../Interview/components/CreateInterviewModal";
import { useSidebar } from "helper/SideBarContext";
import PerformanceCard from "./components/PerformanceCard/PerformanceCard";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserReport(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [interviewStatus, setInterviewStatus] = useState({});
  const [show, setShow] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([
    { value: "On Hold", label: "On Hold" },
    { value: "HR Interview", label: "HR Interview" },
    { value: "Technical Interview", label: "Technical Interview" },
    { value: "Hire", label: "Hire" },
    { value: "Reject", label: "Reject" },
  ]);
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const [reportDetails, setReportDetails] = useState({});
  const [sectionOptions, setSectionOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const query = useQuery();
  const params = useParams();
  const isLms = query.get("lms");
  const mhFields = [
    "Name",
    "Experience",
    "currentCompany",
    "LatestDesignation",
    "currentCtc",
    "expectingCtc",
    "noticePeriod",
    "city",
    "Resume_key",
    "state",
    "country",
  ];
  const onCopy = () => {
    navigator.clipboard.writeText(
      `${Domain}/assessments/${params.assessmentId}/report/${
        props.match.params.reportId
      }${isLms === "true" ? "?lms=true" : ""}`
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const handleShow = () => {
    setShow(true);
    setIsEditable(false);
  };

  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };

  const getUserReport = async () => {
    try {
      const url =
        isLms === "true"
          ? `${BootcampBackend}/enterpriseAssessment/getLmsParticipantReport/${props.match.params.reportId}`
          : `${BootcampBackend}/enterpriseAssessment/getParticipantReport/${props.match.params.reportId}`;
      const reportResponse = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("jwt")).token
          }`,
        },
      });
      if (reportResponse) {
        setIsLoading(false);
        setReportDetails(reportResponse.data?.result?.data);
        setInterviewStatus({
          value: reportResponse.data?.result?.data?.interviewStatus,
          label: reportResponse.data?.result?.data?.interviewStatus,
        });
        const optionsData = reportResponse.data?.result.mcqReport;
        setSectionOptions(optionsData);
        optionsData.length > 0 && setSelectedSection(optionsData[0]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const timer = (seconds, returnAs) => {
    if (seconds === null || seconds === undefined) {
      seconds = 0;
    }
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (minutes > 0) {
      seconds = seconds - minutes * 60;
    }

    if (hours > 0) {
      minutes = minutes - hours * 60;
    }

    switch (returnAs) {
      case "words": {
        return (
          (hours ? `${getFormat(hours)} hr ` : "") +
          (minutes ? `${getFormat(minutes)} min ` : "") +
          (seconds ? `${getFormat(seconds)} sec ` : "")
        );
      }
      case "default":
      default:
        return `${getFormat(hours) + " : "}${getFormat(minutes)} : ${getFormat(
          seconds
        )}`;
    }
  };
  const getFormat = (v) => {
    return `0${v}`.slice(-2);
  };

  const getToTalQues = () => {
    let totalQues = 0;
    let noOfCorrectAns = 0;
    reportDetails?.EvaluationData?.filter((section) => {
      totalQues += section?.totalQuestions;
      noOfCorrectAns += section?.correctlyAnswered;
    });
    return { totalQues: totalQues, noOfCorrectAns: noOfCorrectAns };
  };

  function isInt(n) {
    return n % 1 === 0;
  }

  const getScoreinPer = () => {
    return isInt(
      (getToTalQues()?.noOfCorrectAns / getToTalQues()?.totalQues) * 100
    )
      ? (
          (getToTalQues()?.noOfCorrectAns / getToTalQues()?.totalQues) *
          100
        ).toFixed() + "%"
      : "0%";
  };

  const UpdateAssessmentStatus = async (value) => {
    try {
      const requestBody = {
        liked: reportDetails.liked,
        termsAgreed: reportDetails?.termsAgreed,
        interviewStatus: value.value,
      };
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/lmsUpdateShareParticipant/${reportDetails?._id}`
        : `${BootcampBackend}/enterpriseAssessment/updateShareParticipant/${reportDetails?._id}`;
      const updateAssessmentStatusResponse = await axios.put(url, requestBody, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (updateAssessmentStatusResponse) {
        setReportDetails((prev) => ({
          ...prev,
          interviewStatus: interviewStatus.value,
        }));
        setToastMessage({
          variant: "success",
          show: true,
          message: "Interview status has been updated successfully.",
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleResume() {
    window.open(reportDetails?.userId?.Resume_key, "_blank");
  }

  useEffect(() => {
    getUserReport();
  }, []);
  return (
    <>
      <Header
        heading="Report"
        route={`/assessments/${params.assessmentId}/timeline/${
          reportDetails?.userId?._id
        }${isLms === "true" ? "?lms=true" : ""}`}
      />
      <Navbar />
      <div
        className={`AssessmentCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="userReport position-relative">
          {isLoading ? (
            <>
              <div className="col-lg-11 mx-auto d-flex">
                <LoadingShape className="interviewBtnLoader" />
                <LoadingShape className="timelineBtnLoader" />
              </div>
              <hr className="mt-3" />
              <LoadingShape className="mx-auto scoreBoard boardLoader" />

              <div className="d-flex col-lg-11 mx-auto justify-content-between mt-5">
                <LoadingShape className="emailLoader" />
                <div className="d-flex">
                  <LoadingShape className="linkBtnLoader" />
                  <LoadingShape className="statusBtnLoader" />
                </div>
              </div>
              <hr className="col-lg-11 mx-auto mt-3" />
              <div className="col-lg-11 mx-auto mt-3 details row">
                <div className="col-lg-2 d-flex flex-column ps-0">
                  {Array.from({ length: 8 }, (_, i) => (
                    <LoadingShape key={i} className="titleNames py-3" />
                  ))}
                </div>
                <div className="col-lg-5 d-flex flex-column">
                  {Array.from({ length: 8 }, (_, i) => (
                    <LoadingShape key={i} className="titleNames py-3 w-50" />
                  ))}
                </div>
                <div className="col-lg-5 d-flex flex-column ">
                  {Array.from({ length: 5 }, (_, i) => (
                    <div key={i} className="QnACards row mt-2">
                      <div className="QnA col-lg-10">
                        <LoadingShape className="QnaLoader w-50" />
                        <LoadingShape className="QnaLoader mt-2" />
                      </div>
                    </div>
                  ))}
                </div>
                <LoadingShape className="skillScoreLoader px-0 mt-5" />
                <hr className="mt-3" />
                <div className="col-lg-6 ps-0">
                  {Array.from({ length: 3 }, (_, i) => (
                    <div
                      key={i}
                      className="score d-flex mt-4 justify-content-between"
                    >
                      <LoadingShape className="skillTypeLoader" />
                      <LoadingShape className="ProgressBarLoader" />
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="col-lg-11 mx-auto">
                <button className="createInterviewButton" onClick={handleShow}>
                  {" "}
                  Create Interview{" "}
                  <img
                    src={createInterviewIcon}
                    className="ms-2"
                    loading="lazy"
                    alt="icon"
                  />
                </button>
                <button
                  className="viewTimelineButton"
                  onClick={() =>
                    history.push(
                      `/assessments/${params.assessmentId}/timeline/${
                        reportDetails?.userId?._id
                      }${isLms === "true" ? "?lms=true" : ""}`
                    )
                  }
                >
                  View Timeline
                </button>
              </div>
              <hr className="mt-3" />
              <div
                className={`col-lg-11 mx-auto scoreBoard d-flex ${
                  parseInt(getScoreinPer()) >=
                  reportDetails?.assessmentId?.cutOffScore
                    ? "aboveCutOff"
                    : "belowCutOff"
                }`}
              >
                <div
                  className={`col-lg-2 scoreDisplay ${
                    parseInt(getScoreinPer()) >=
                    reportDetails?.assessmentId?.cutOffScore
                      ? "displayAboveCutOff"
                      : "displayBelowCutOff"
                  }`}
                >
                  <h2
                    className={`scoreInPercentage ${
                      parseInt(getScoreinPer()) >=
                      reportDetails?.assessmentId?.cutOffScore
                        ? ""
                        : "cutOffScore"
                    }`}
                  >
                    {getScoreinPer()}
                  </h2>
                  <p className="score">
                    {getToTalQues()?.noOfCorrectAns}/{getToTalQues()?.totalQues}
                  </p>
                </div>
                <div className="detailedReport ms-4 my-auto">
                  <p className="description align-items-center">
                    {reportDetails?.assessmentId?.title}
                    <span>
                      {" "}
                      {timer(
                        reportDetails?.assessmentTime -
                          reportDetails?.remainingTime,
                        "words"
                      )}
                    </span>{" "}
                    on{" "}
                    <span>
                      {moment(reportDetails?.completedAt).format(
                        "MMM DD, YYYY hh:mm:ss a"
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className="d-flex col-lg-11 mx-auto justify-content-between mt-5">
                <p className="skillScore description px-0 ">Scores</p>
                <div className="d-flex">
                  {reportDetails?.userId?.Resume_key ? (
                    <button
                      className="share-button me-3"
                      onClick={() => {
                        handleResume();
                      }}
                    >
                      <img src={DownArrow} className="me-2" />
                      <span>Resume</span>
                    </button>
                  ) : (
                    <></>
                  )}
                  {isCopied ? (
                    <button className="share-button copiedButton">
                      <img src={shareIcon} className="me-2" />
                      <span>Copied</span>
                    </button>
                  ) : (
                    <button className="share-button" onClick={onCopy}>
                      <img
                        src={shareIcon}
                        className="me-2 img-fluid"
                        loading="lazy"
                        alt="share"
                      />
                      <span>Copy Link</span>
                    </button>
                  )}
                  <Dropdown
                    dropDownClass={"px-3 ms-3 statusButton cursor-pointer"}
                    dropDownOptions={statusOptions}
                    ondropdownItemClick={(value) => {
                      setInterviewStatus(value);
                      UpdateAssessmentStatus(value);
                    }}
                    selectedValue={"Status: " + interviewStatus.label}
                    value="value"
                  />
                </div>
              </div>
              <hr className="col-lg-11 mx-auto mt-3" />
              <div className="col-lg-11 mx-auto row">
                <div className="col-lg-3 ps-0 pe-5 my-4 borderLeft">
                  {reportDetails?.EvaluationData?.map((section, index) => (
                    <div
                      className="d-flex mb-3 justify-content-between"
                      key={section?._id}
                    >
                      <span className="description">Section {index + 1}</span>
                      <span
                        className={`sectionScore ${
                          section?.totalEarnedScore >=
                          reportDetails?.assessmentId?.cutOffScore
                            ? "sectionCutOffScore"
                            : ""
                        }`}
                      >
                        {section?.totalEarnedScore || 0}/
                        {section?.totalSectionScore}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="col-lg-9 ps-5 details">
                  {reportDetails?.EvaluationData?.map((section) => (
                    <div className="score d-flex mt-4" key={section?._id}>
                      <span className="skillType description col-lg-4 ms-2">
                        {section?.sectionTitle} ({section?.correctlyAnswered}/
                        {section?.totalQuestions})
                      </span>
                      <span className="scoreProgressBarBody w-100">
                        <span
                          className="scoreProgressBar"
                          style={{
                            width: `${
                              (section?.correctlyAnswered /
                                section?.totalQuestions) *
                              100
                            }%`,
                          }}
                        ></span>
                      </span>
                    </div>
                  ))}
                  {/* <div className="score d-flex mt-3 justify-content-between">
                    <span className="skillType description">
                      Problem Solving (Basic) (8/10)
                    </span>
                    <span className="scoreProgressBarBody">
                      <span
                        className="scoreProgressBar"
                        style={{ width: "260px" }}
                      ></span>
                    </span>
                  </div>
                  <div className="score d-flex mt-3  justify-content-between">
                    <span className="skillType description">
                      REST API (Advanced) (5/10)
                    </span>
                    <span className="scoreProgressBarBody">
                      <span
                        className="scoreProgressBar"
                        style={{ width: "162px" }}
                      ></span>
                    </span>
                  </div> */}
                </div>
              </div>
              <hr className="col-lg-11 mx-auto mt-0" />
              <div className="col-lg-11 mx-auto mt-3 details row">
                <div className="col-lg-1 d-flex flex-column ps-0">
                  <p className="titleNames text-nowrap py-3">Full name</p>
                  <p className="titleNames text-nowrap py-3">Email</p>
                  <p className="titleNames text-nowrap py-3">Test name</p>
                  <p className="titleNames text-nowrap py-3">Taken on</p>
                  <p className="titleNames text-nowrap py-3">Time taken</p>
                  <p className="titleNames text-nowrap py-3">Work Experience</p>
                  <p className="titleNames text-nowrap py-3">Invited by</p>
                  <p className="titleNames text-nowrap py-3">Invited on</p>
                </div>
                <div className="col-lg-5 ps-5 d-flex flex-column">
                  <p className="candidateReportDetails py-3">
                    {reportDetails?.userId?.Name}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {reportDetails?.email}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {reportDetails?.assessmentId?.title}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {moment(reportDetails?.completedAt).format(
                      "MMM DD, YYYY hh:mm:ss a"
                    )}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {timer(
                      reportDetails?.assessmentId?.time -
                        reportDetails?.remainingTime,
                      "words"
                    )}{" "}
                    / {timer(reportDetails?.assessmentId?.time, "words")}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {reportDetails?.userId?.Experience} years
                  </p>
                  <p className="candidateReportDetails py-3">
                    {reportDetails?.invitedBy?.firstName +
                      " " +
                      reportDetails?.invitedBy?.lastName}
                  </p>
                  <p className="candidateReportDetails py-3">
                    {" "}
                    {moment(reportDetails?.invitedAt).format(
                      "MMM DD, YYYY hh:mm:ss a"
                    )}
                  </p>
                </div>
                {/* Dynamic question */}
                <div className="col-lg-5 offset-1 d-flex flex-column">
                  {sectionOptions && selectedSection?.mcqWithOptions?.length>0 &&(
                    <>
                      <PerformanceCard data={selectedSection} selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} sectionOptions={sectionOptions} setSectionOptions={setSectionOptions} setSelectedSection={setSelectedSection} selectedSection={selectedSection}/>
                    </>
                  )}
                  {reportDetails?.mandataryFields?.length > 0 &&
                    Object.keys(reportDetails?.mandataryFields[0])?.map(
                      (field, index) => (
                        <div className="" key={index}>
                          {!mhFields.includes(field) && (
                            <div className="QnACards row mt-3">
                              <img
                                src={checkedIcon}
                                className="QnAResponseStatus col-lg-2"
                              />
                              <div className="QnA col-lg-10">
                                <p className="QnaQuestion">{field}</p>
                                <p className="QnaAnswer">
                                  {reportDetails?.mandataryFields[0][field] !==
                                  ""
                                    ? reportDetails?.mandataryFields[0][field]
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </>
          )}
          {selectedQuestion !== null &&<div className="performanceCardBackdrop" onClick={()=>setSelectedQuestion(null)}></div>}
        </div>
      </div>
      <div className="settingToast">
        {toastMessage.show && (
          <ToastMessages
            toastMessage={toastMessage}
            setToastMessage={setToastMessage}
          />
        )}
      </div>
      <CreateInterviewModal
        reportDetails={reportDetails}
        show={show}
        setShow={setShow}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      />
    </>
  );
}
