import React, { useEffect, useState } from "react";
import "../Assessments/Mocks/Mocks.css";
import MHAssessmentIcon from "../../../assets/Icons/Interview/mHassessment.svg";
import OwnAssessmentIcon from "../../../assets/Icons/Interview/customAssessment.svg";
import { useHistory } from "react-router";

const moment = require("moment");

export default function SelectAssessmentType(props) {
  const [hoOver, setHoOver] = useState("");
  const history = useHistory();
  const assessmentType = [
    {
      id: "1",
      name: "Attach your own Assessment",
      desc: "This allows you to attach your own created custom Assessments",
      img: OwnAssessmentIcon,
      hoverImg: OwnAssessmentIcon,
      value: "myCompany",
    },
    {
      id: "2",
      name: "Attach MachineHack Assessment ",
      desc: "This allows you to attach assessments created by MachineHack Expert Team",
      img: MHAssessmentIcon,
      hoverImg: MHAssessmentIcon,
      value: "mhLibrary",
    },
  ];
  const onquestionSelect = (data) => {
    // props.selectedQuesType(data);
    props.setShowAssessmentType(data.value);
  };

  return (
    <>
      {assessmentType.map((item) => (
        <div
          className="assessmentTypeHld m-4 text-center"
          key={item.id}
          onClick={() => onquestionSelect(item)}
        >
          <img
            src={item.img}
            alt="item"
            loading="lazy"
            className={`img-fluid assessmentTypeImg p-5 ${
              props.interviewType === "main" && "interviewTypeImg"
            }`}
          />

          <p
            className={`assessmentTypeTitle mx-auto ${
              props.interviewType === "main" && "interviewTypeName"
            }`}
          >
            {item.name}
          </p>
        </div>
      ))}
    </>
  );
}
