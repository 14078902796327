import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

const UserPlanPopup = (props) => {
  const { showCurrentPlan, setShowCurrentPlan, subscriptionData, setShowPlan, showPlan } = props;
  const upgradeNow = () => {
    setShowPlan(!showPlan);
    setShowCurrentPlan(!showCurrentPlan)
  }
  return (
    <Modal
      centered="true"
      dialogClassName="user-plan-popup"
      className="user-current-plan"
      show={showCurrentPlan}
    >
      <Modal.Body>
        <div className="plansHld userPlanScreenModalBody">
          <div
            className="closeModalHld"
            onClick={() => setShowCurrentPlan(!showCurrentPlan)}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="close-icon"
              transform="shrink-6 left-4"
            />
          </div>
          <h1 className="mt-2 mb-3">
            You’re on a {subscriptionData?.mh_subscription_plan_id?.name} Plan
          </h1>

          <ul className="my-4">
            <div
              className="planModalText"
              dangerouslySetInnerHTML={{
                __html: subscriptionData?.mh_subscription_plan_id?.description,
              }}
            ></div>
          </ul>
          <button className="upgrade-now" onClick={upgradeNow}>Upgrade now</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserPlanPopup;
