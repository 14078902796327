import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BootcampBackend } from "../../../../backend";
import axios from "axios";
import questionsCSV from "assets/quesions_format.csv";
import { Link, useHistory } from "react-router-dom";
import csv from "csv";
import { useDropzone } from "react-dropzone";
import { isAuthenticated } from "../../../../helper/user";
import closeIcon from "../../../../assets/Icons/cross.svg";
export default function QuestionCSVModal({
  bulkUploadModal,
  setBulkUploadModal,
  sectionId,
  assessmentId
}) {
  const [successModal, setSuccessModal] = useState(false);
  const [questionData, setQuestionData] = useState({
    title: "",
    duration: "",
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    correctOption: "",
  });
  const [questionSetId, setQuestionSetId] = useState("");
  const [questionSetSlug, setQuestionSetSlug] = useState("");
  const [QuestionListData, setQuestionListData] = useState([]);
  const [selectedCSV, setSelectedCSV] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState(false);
  const [csverror, setCsvError] = useState({
    title: "",
    duration: "",
    question: "",
    options: "",
    is_correct: "",
    data: "",
  });
  const history = useHistory();
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        setCsvData(data);
        if (data === undefined) {
          setCsvError((prevValue) => {
            return {
              ...prevValue,
              data: "Invalid CSV format",
            };
          });
          return;
        }
        if (data.length === 0) {
          setCsvError((prevValue) => {
            return {
              ...prevValue,
              data: "Invalid CSV format",
            };
          });
          return;
        }
        if (data[1][1] === "" || data[1][1] === undefined) {
          setCsvError((prevValue) => {
            return {
              ...prevValue,
              title: "Title is required",
            };
          });
        }
        if (data[1][2] === "" || data[1][2] === undefined) {
          setCsvError((prevValue) => {
            return {
              ...prevValue,
              duration: "Duration is required",
            };
          });
        }
        if (data.slice(3).length === 0) {
          setCsvError((prevValue) => {
            return {
              ...prevValue,
              question: "At least 1 question is required",
            };
          });
        }
        data.slice(3).forEach((x, i) => {
          if (x[1] === "" || x[1] === undefined) {
            setCsvError((prevValue) => {
              return {
                ...prevValue,
                question: "Question is required",
              };
            });
          }
          if (x[2] === "" || x[3] === "" || x[4] === "" || x[5] === "") {
            setCsvError((prevValue) => {
              return {
                ...prevValue,
                options: "4 Options are required",
              };
            });
          }
          if (x[6] === "" || x[6] === undefined) {
            setCsvError((prevValue) => {
              return {
                ...prevValue,
                is_correct: "Correct Option is required",
              };
            });
          }
          if (x[6] !== undefined) {
            if (
              !x[6].includes("Option 1") &&
              !x[6].includes("Option 2") &&
              !x[6].includes("Option 3") &&
              !x[6].includes("Option 4")
            ) {
              setCsvError((prevValue) => {
                return {
                  ...prevValue,
                  is_correct: "Correct Option is Invalid",
                };
              });
            }
          }
        });
      });
    };
    reader.readAsBinaryString(acceptedFiles[0]);
    handleFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: ".csv",
    onDrop,
  });
  function handleFileUpload(files) {
    files && files.length > 0
      ? (setSelectedCSV(files[0]), setError(false))
      : setSelectedCSV(null);
  }
  const hideModal = () => {
    setBulkUploadModal(false);
  };
  const showSuccessModal = () => {
    setBulkUploadModal(false);
    setSuccessModal(true);
  };
  const uploadQuestionCSV = () => {
    if (!isAuthenticated()) {
      history.push("/login");
      return;
    }
    if (selectedCSV === null) {
      setError(true);
    }
    if (selectedCSV !== null) {
      setError(false);
    }

    let data = [];
    csvData.slice(3).forEach((x, i) => {
      data.push({
        question: x[1],
        options: [
          {
            option: x[2],
            is_correct: x[6].includes("Option 1") ? true : false,
          },
          {
            option: x[3],
            is_correct: x[6].includes("Option 2") ? true : false,
          },
          {
            option: x[4],
            is_correct: x[6].includes("Option 3") ? true : false,
          },
          {
            option: x[5],
            is_correct: x[6].includes("Option 4") ? true : false,
          },
        ],
        duration: x[7],
        score: x[8],
        difficulty: x[9],
      });
    });
    csverror.title === "" &&
      csverror.duration === "" &&
      csverror.question === "" &&
      csverror.options === "" &&
      csverror.is_correct === "" &&
      selectedCSV !== null &&
      axios
        .post(
          `${BootcampBackend}/enterpriseAssessment/createCustomMockQuestionSetFromCsv/${sectionId}`,
          {
            title: csvData[1][1],
            duration: Math.floor(csvData[1][2] * 60),
            timeStamp: Date(),
            questions: data,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setQuestionData({
            title: res.data.result.questionSetData.title,
            duration: Math.floor(res.data.result.questionSetData.duration / 60),
            question: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            correctOption: "",
          });
          setQuestionSetId(res.data.result.questionSetData._id);
          setQuestionSetSlug(res.data.result.questionSetData.slug);
          setQuestionListData(res.data.result.questionData);
          localStorage.setItem("isEditQuestions", true);
          // axios
          //   .post(
          //     `${BootcampBackend}/assesments/addAssesmentFromExistingMock`,
          //     {
          //       assesmentType: "custom_mcq",
          //       assesmentTitle: res.data.result.questionSetData.title,
          //       assesmentReference: res.data.result.questionSetData.slug,
          //       timeStamp: Date(),
          //     },
          //     {
          //       headers: {
          //         Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          //       },
          //     }
          //   )
          //   .then((res) => {
              showSuccessModal();
              history.push(`/assessments/${assessmentId}/mcq/questions/${sectionId}`);
            // })
            // .catch((err) => {
            //   console.log(err);
            // });
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <div>
      <Modal
        centered="true"
        dialogClassName="bulkquesuploadModal"
        show={bulkUploadModal}
        onHide={hideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Bulk Upload MCQs</Modal.Title>
          <img
            className="img-fluid closeIcon"
            src={closeIcon}
            alt="x"
            onClick={hideModal}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="stepsToUpload text-center">
            <p>
              i) Upload a CSV file with one question per row (format described
              here)
            </p>
            <p>
              ii) Upload a CSV file with one question per row (format described
              here)
            </p>
          </div>
          <div className="uploadCSVQuestionsCntr">
            <p className="py-4">
              Download an example CSV file
              <Link to={questionsCSV} className="ms-2" target="_blank" download>
                Click here
              </Link>
            </p>
            <section style={{ position: "relative" }}>
              <div className="dropzoneArea text-center" {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : error ? (
                  <p style={{ color: "red" }}>Wrong File Format</p>
                ) : selectedCSV === null ? (
                  <p>Click here to upload or drag and drop CSV</p>
                ) : (
                  <p>{selectedCSV.name} uploaded successfully</p>
                )}
                {selectedCSV !== null && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCSV(null);
                      setError(false);
                      setCsvError({
                        title: "",
                        duration: "",
                        question: "",
                        options: "",
                        is_correct: "",
                        data: "",
                      });
                    }}
                    className="csvBtn"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </section>
            <p style={{ color: "red", fontSize: "12px" }}>{csverror.title}</p>
            <p style={{ color: "red", fontSize: "12px" }}>
              {csverror.duration}
            </p>
            <p style={{ color: "red", fontSize: "12px" }}>
              {csverror.question}
            </p>
            <p style={{ color: "red", fontSize: "12px" }}>{csverror.options}</p>
            <p style={{ color: "red", fontSize: "12px" }}>
              {csverror.is_correct}
            </p>
            <p style={{ color: "red", fontSize: "12px" }}>{csverror.data}</p>
            {csverror.title === "" &&
            csverror.duration === "" &&
            csverror.question === "" &&
            csverror.options === "" &&
            csverror.is_correct === "" &&
            csverror.data === "" &&
            selectedCSV !== null ? (
              <button
                className="btn mt-2 mb-4 uploadQuesCsv"
                onClick={uploadQuestionCSV}
              >
                Upload CSV
              </button>
            ) : (
              <button className="btn mt-2 mb-4 uploadQuesCsv disabled">
                Upload CSV
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
