import React from "react";
import { Modal } from "react-bootstrap";
import LockIcon from "assets/Icons/lockIcon.svg";
import BlackCrown from "assets/Icons/block-crown.svg";

const CreateAssessmentWithWarningPopup = ({
  showCreateAssessmentWithWarningPopup,
  setShowCreateAssessmentWithWarningPopup,
  showPlan,
  setShowPlan,
  setShowBuildAssessmentModal,
}) => {
  const closeWarningPopup = () => {
    setShowCreateAssessmentWithWarningPopup(
      !showCreateAssessmentWithWarningPopup
    );
  };
  return (
    <Modal
      size="lg"
      centered="true"
      show={showCreateAssessmentWithWarningPopup}
      onHide={() =>
        setShowCreateAssessmentWithWarningPopup(
          !showCreateAssessmentWithWarningPopup
        )
      }
      className=""
    >
      <Modal.Body className="">
        <div className="mask-data-upgrade-hld align-items-center d-flex flex-column justify-content-center text-center">
          <img src={LockIcon} alt="locked" className="img-fluid mb-3" />
          <p className="col-10 mx-auto mb-4">
            Please note that with this plan, you are permitted to access only
            the latest 5 assessments created. However, there is no limit on
            assessment creation.
          </p>
          <div className="d-flex">
            <button
              className="d-flex align-items-center Upgrade-btn border-radius-50 me-3 font-14-500"
              onClick={() => {
                setShowPlan(!showPlan);
                closeWarningPopup();
              }}
            >
              <img src={BlackCrown} alt="premium" className="img-fluid me-2" />
              Upgrade
            </button>
            <button
              className="secondaryColoredBtn font-14-500"
              onClick={() => {
                setShowBuildAssessmentModal(true);
                closeWarningPopup();
              }}
            >
              Go Ahead & Create
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAssessmentWithWarningPopup;
