import { Machinehack_Url } from "backend";
import { isAuthenticated } from "helper/user";
import React from "react";
import { Modal } from "react-bootstrap";

const InterviewExpiredPopup = ({
  expiryModal,
  validateFeedbackForm,
}) => {
  return (
    <Modal
      show={expiryModal}
      size="sm"
      centered
      enforceFocus={false}
      className="finalInterviewStatus"
    >
      <Modal.Body>
        <div className="px-3">
          <h5 className="d-flex justify-content-center mt-5">
            Interview Expired !
          </h5>
          <div className="text-center mt-4 mb-2 ">
            <button
              className="submitbtn w-50"
              type="button"
              onClick={() => {
                if (isAuthenticated()) {
                  validateFeedbackForm();
                } else {
                  window.location.assign(`${Machinehack_Url}/hackathon`);
                }
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default InterviewExpiredPopup;
