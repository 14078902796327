import React from "react";
import EmptyDataSVG from "assets/empty_data.svg";

const EmptyData = () => {
  return (
    <div className="text-center py-4">
      <img src={EmptyDataSVG} alt="no-data" />
      <p className="studyplan-startedAt mt-3">Not Enough data to show</p>
    </div>
  );
};

export default EmptyData;
