import React, { useEffect, useState } from "react";
import "./assessment.css";
import axios from "axios";
import { BootcampBackend } from "../../../../backend";
import { useHistory } from "react-router-dom";
import QuestionSet from "assets/Icons/Assessment/QuestionSet.svg";
import QuestionSetTime from "assets/Icons/Assessment/QuestionSetTime.svg";
import QuestionSetType from "assets/Icons/Assessment/QuestionSetType.svg";
import Users from "assets/Icons/DashboardNew/users.svg";
import { LoadingShape } from "react-rainbow-components";
import { timer } from "SharedMethods/CommenMethods";
import ParticipantsTable from "./ParticipantsTable";
import dropdownArrow from "assets/Icons/DashboardNew/dropdownArrowFilled.svg";
import viewAllSvg from "assets/Icons/DashboardNew/viewAll.svg";
function Assessment({ myAssessments, isLoading }) {
  const [participantList, setParticipantsList] = useState({});
  const [showTable, setShowTable] = useState(null);
  const [selectedValue, setSelectedValue] = useState("1D");
  const history = useHistory();
  useEffect(() => {
    getParticipants(myAssessments[0]._id, false);
  }, [myAssessments]);

  const getParticipants = async (id, isTable) => {
    const paramData = {
      limit: 10,
      current_page: 1,
    };
    if (showTable === id) {
      setShowTable(null);
    } else {
      await axios
        .get(
          `${BootcampBackend}/enterpriseAssessment/getAllParticipants/${id}`,
          {
            paramData,
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setParticipantsList(res.data.result.data);
          if (isTable && res.data.result?.totalParticipants > 0) {
            setShowTable(id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {myAssessments.length > 0 ? (
        <div className="col-xl-8 col-lg-8">
          {isLoading && myAssessments ? (
            <div className="row m-5">
              <div className={`col-lg-7 mt-1`}>
                <LoadingShape style={{ height: "40px" }} />
              </div>
              <div className="col-lg-7 mt-4">
                <LoadingShape style={{ height: "110px" }} />
              </div>
            </div>
          ) : (
            myAssessments.length > 0 && (
              <div className="dashboardCard assessment">
                <div className="d-flex assessmentData justify-content-between align-items-center">
                  <h5 className="dashboardHeading">
                    Recent Assessments ({myAssessments.length})
                  </h5>
                  <div className="d-flex align-items-center moreAssessments viewAll">
                    <div className="dashboardFiltersTab d-flex">
                      <p
                        className={`${
                          selectedValue === "12M"
                            ? "dashboardFiltersActive"
                            : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("12M")}
                      >
                        12M
                      </p>
                      <p
                        className={`${
                          selectedValue === "1M" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("1M")}
                      >
                        1M
                      </p>
                      <p
                        className={`${
                          selectedValue === "7D" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("7D")}
                      >
                        7D
                      </p>
                      <p
                        className={`${
                          selectedValue === "1D" ? "dashboardFiltersActive" : ""
                        } dashboardFiltersInnerTab`}
                        onClick={() => setSelectedValue("1D")}
                      >
                        1D
                      </p>
                    </div>
                    <div
                      className="dashboardViewAllImgHld ms-2 d-flex align-items-center"
                      onClick={() => {
                        history.push("/assessments");
                      }}
                    >
                      <img className="" src={viewAllSvg} alt="view all" />
                    </div>
                  </div>
                </div>
                <div className="row g-0 assessmentBottom">
                  <div className="col-12 assessmentLeft">
                    {myAssessments.length > 0 &&
                      myAssessments.slice(0, 3).map((assessmentData) => (
                        <div
                          key={assessmentData._id}
                          className="dashboardScheduleRow cursor-pointer"
                          onClick={() => {
                            getParticipants(assessmentData._id, true);
                          }}
                        >
                          <div className="p-3 assessmentHover">
                            <p className="dashboardSubHeadText mb-2">
                              {`${
                                assessmentData.title?.length > 15
                                  ? assessmentData.title
                                  : assessmentData.title
                              }`}
                            </p>

                            <div className="d-flex assessmentRow">
                              <span>
                                <img
                                  src={QuestionSet}
                                  className="img-fluid me-2"
                                />
                                <span className="QuestionSetCardDec me-5">
                                  {assessmentData?.sections?.length} Sections
                                </span>
                              </span>
                              <span>
                                <img
                                  src={QuestionSetTime}
                                  className="img-fluid me-2"
                                />
                                <span className="QuestionSetCardDec me-5">
                                  {timer(assessmentData?.duration, "words")}
                                </span>
                              </span>
                              <span>
                                <img
                                  src={QuestionSetType}
                                  className="img-fluid me-2"
                                />
                                <span className="QuestionSetCardDec me-5">
                                  {assessmentData?.createdBy?.firstName +
                                    " " +
                                    assessmentData?.createdBy?.lastName}
                                </span>
                              </span>
                              <span>
                                <img src={Users} className="img-fluid me-2" />
                                <span className="QuestionSetCardDec me-5">
                                  Participants -{" "}
                                  {assessmentData?.noOfParticipants
                                    ? assessmentData?.noOfParticipants
                                    : 0}
                                </span>
                              </span>
                              <img
                                className="ms-auto"
                                src={dropdownArrow}
                                alt="dropdown"
                              />
                            </div>
                          </div>
                          {showTable === assessmentData._id &&
                            participantList && (
                              <ParticipantsTable
                                assessmentId={assessmentData._id}
                              />
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Assessment;
