import React, { useEffect, useState } from "react";

import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import FormComponent from "../components/FormComponent/NewForm";
import "./EditJob.css";
import { useSidebar } from "helper/SideBarContext";

export default function EditJob(props) {
  const { isSidebarCollapsed } = useSidebar();
  return (
    <div>
      <Header />
      <div className="container-fluid body-margin-top px-0">
        <div className="addJob">
          <Navbar />
          <div
            className={`px-4 ${
              isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
          >
            <div className="row">
              <div className=" col-lg-12 text-center addJobHeadingContainer">
                <h2>Edit Job</h2>
              </div>
              <div className="addJobContentContainer g-0">
                <div className="addJob">
                  <FormComponent type="Edit" id={props.match.params.jobId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
