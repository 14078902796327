import React, { useEffect, useState } from "react";
import "./schedule.css";
import MoreIcon from "../../../../assets/Icons/Dashboard/more.svg";
import GreenTick from "../../../../assets/Icons/Dashboard/greenTick.svg";
import OrangeTick from "../../../../assets/Icons/Dashboard/orangeTick.svg";
import RedTick from "../../../../assets/Icons/Dashboard/redTick.svg";
import Message from "../../../../assets/Icons/Dashboard/message.svg";
import Dot from "../../../../assets/Icons/Dashboard/dot.svg";
import Link from "../../../../assets/Icons/Dashboard/link.svg";
import candidateSvg from "assets/Icons/DashboardNew/candidate.svg";
import Cross from "../../../../assets/Icons/Interview/Cross_interview.svg";
import axios from "axios";
import { BootcampBackend } from "../../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Datetime from "react-datetime";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import Copy from "../../../../assets/Icons/Interview/Copy_interview.svg";
import Email from "../../../../assets/Icons/Interview/Email_interview.svg";
import Linkedin from "../../../../assets/Icons/Interview/LinkedIn_interview.svg";
import DateIcon from "../../../../assets/Icons/Interview/Date_interview.svg";
import Clock from "../../../../assets/Icons/Interview/Clock_interview.svg";
import CompletedIconCalendar from "../../../../assets/Icons/Interview/New/completed-calendar.png";
import NotCompletedIconCalendar from "../../../../assets/Icons/Interview/New/not-completed-calendar.png";
import viewAllSvg from "assets/Icons/DashboardNew/viewAll.svg";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#ffffff",
    color: "#3c3e4d",
    padding: 8,
    zIndex: 999,
    fontSize: "14px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    backgroundColor: "#ffffff",
    borderBottom: "solid 1px #e4e4e4",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: 0,
    fontSize: "14px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: () => ({
    color: "#9d9fa6",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
  }),
};

function Schedule() {
  const [tableData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [selectedCandidate, setSelectedCandidate] = useState("1D");
  const [Mhlist, setMhlist] = useState([]);
  const [token, setInterviewToken] = useState("");
  const [show, setShow] = useState(false);
  const [modalInterview, setModalInterview] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [createRound, setCreateRound] = useState(false);
  const [startTime, setStartTime] = useState(moment());
  const [interviewRoundNo, setInterviewRoundNo] = useState(0);
  const [allInterviews, setAllInterviews] = useState([]);
  const [isAllInterviews, setIsAllInterviews] = useState("allInterviews");
  const [endTime, setEndTime] = useState(
    moment(new Date()).add("30", "minutes")
  );

  const [interviewStatus, setInterviewStatus] = useState("pending");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [instantLink, setInstantLink] = useState("");
  const [interviewTitle, setInterviewTitle] = useState("");
  const [numOfInterviews, setNumOfInterviews] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [editableItem, setIsEditableItem] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [mailList, setMailList] = useState("email");
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [candidateEmailError, setCandidateEmailError] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [interviewsCount, setInterviewsCount] = useState({
    upcoming: 0,
    completed: 0,
  });

  useEffect(() => {
    let abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, [interviewStatus]);

  const editSchedule = (item) => {
    setShow(true);
    setModalInterview("schedule");
    setIsEditable(true);
    setCreateRound(false);
    setIsEditableItem(item);
    setInterviewTitle(item.interviewId.interviewTitle);
    setSelectedDate(moment(item.interviewStartTime).format("ddd, ll"));
    setStartTime(moment(item.interviewStartTime));
    setEndTime(moment(item.interviewEndTime));
    setCandidateEmail(item.interviewId.candidateEmail);
    setInstantLink(item.interviewLink);
  };

  function ValidateEmail(userEmail) {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (userEmail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const deleteInterview = (data) => {
    axios
      .delete(
        `${BootcampBackend}/interview/deleteInterview/${data.interviewId._id}/${data._id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((result) => {
        // Update the num of pages in pagination

        document.getElementById("cardViewDropdown" + data._id) &&
          document
            .getElementById("cardViewDropdown" + data._id)
            .classList.remove("show");
        document.getElementById("myDropdown" + data._id) &&
          document
            .getElementById("myDropdown" + data._id)
            .classList.remove("show");
        setTableData(
          tableData.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return allInterviews;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createScheduledInterview = () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();
    if (
      interviewTitle === "" &&
      candidateEmail === "" &&
      !isEditable &&
      !createRound
    ) {
      setInterviewTitleError(true);
      setCandidateEmailError("This field is required");
      return;
    } else if (!ValidateEmail(candidateEmail) && !isEditable && !createRound) {
      setCandidateEmailError("Enter valid email");
    } else if (isEditable) {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        email: candidateEmail,
        interviewTitle: interviewTitle,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      axios
        .put(
          `${BootcampBackend}/interview/updateInterview/` +
            editableItem.interviewId._id +
            "/" +
            editableItem._id,
          options,
          headers
        )
        .then((result) => {
          handleClose();
          getAllInterviews(0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getAllInterviews = (pageNum, isMyInterview) => {
    const paramsData = {};
    // paramsData.current_page = pageNum;
    paramsData.interviewStatus = interviewStatus;
    paramsData.interviews = isMyInterview && "myInterview";
    axios
      .get(`${BootcampBackend}/interview/getInterviews`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
        params: paramsData,
      })
      .then((res) => {
        // Get the status of the API and assign the resul if the status is OK
        if (res.status == 200) {
          setIsPageLoading(false);
          isMyInterview
            ? setIsAllInterviews("myInterviews")
            : setIsAllInterviews("allInterviews");
          setNumOfInterviews(res.data.result.totalInterview);
          setTotalPages(Math.ceil(res.data.result.totalInterview / 6));
          setAllInterviews(res.data.result.interviewData);
          interviewStatus === "pending"
            ? setInterviewsCount((prev) => ({
                ...prev,
                upcoming: res.data.result.interviewData.length,
              }))
            : setInterviewsCount((prev) => ({
                ...prev,
                completed: res.data.result.interviewData.length,
              }));
        } else {
          // show the error message here
        }
      })
      .catch((err) => {
        setIsPageLoading(true);
        // show the error here.
        console.log(err);
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };

  const onCandidateEmailChange = (e) => {
    setCandidateEmail(e.target.value);
    setCandidateEmailError("");
  };

  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };

  const handlenextInterviewerChange = (value) => {
    setSelectedCandidate(value);
    setCandidateEmail(value.value);
  };

  const copyLink = (data) => {
    setIsCopied(data);
    navigator.clipboard.writeText(instantLink);
  };

  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };

  const handleClose = () => {
    setShow(false);
    setMailList("email");
    setModalInterview(0);
    setInterviewTitleError(false);
    setCandidateEmailError("");
    setIsCopied("");
    setInterviewTitle("");
    setCandidateEmail("");
  };

  const handleShow = () => {
    setShow(true);
    setIsEditable(false);
  };
  async function fetchScheduleData() {
    try {
      const res = await axios.get(
        `${BootcampBackend}/interview/getTodayInterview`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      return res;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    // If user is not logged in then ask him to login
    // Else call the getAllInterviews() funtion

    // For closing the Edit Schedule & Delete Interview on Click of otuside the menu
    window.addEventListener("mouseup", (event) => {
      const element = document.querySelector(
        ".dropdown-content.list-view-dropdown"
      );
      const cardElement = document.querySelectorAll(".dropdown-content");

      element && element.classList.remove("show");

      for (let i = 0; i <= cardElement.length - 1; i++) {
        if (cardElement[i].classList) {
          cardElement[i].classList.remove("show");
        }
      }

      // FOr list view & Filter Dropdowns
      const listDropdownElement = document.querySelector(
        ".dropdown-content-right.more-properties.random-class"
      );
      const filterDropdownElement = document.querySelector(
        ".dropdown-content-right.random-class-1"
      );

      if (listDropdownElement) {
        listDropdownElement.style.display = "none";
      }

      if (filterDropdownElement) {
        filterDropdownElement.style.display = "none";
      }
    });

    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    } else {
      getAllInterviews(1, null);
    }
  }, []);

  useEffect(() => {
    fetchScheduleData()
      .then((res) => {
        setIsLoading(false);
        setTableData(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function onCopy(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
  }

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };

  const handleTimeClick = () => {
    setTimeout(() => {
      customFunction();
    }, 500);
  };

  const customFunction = () => {
    const element = document.getElementsByClassName(
      "rc-time-picker-panel-input"
    );

    if (element[0]) {
      element[0].addEventListener("blur", (event) => {
        const newValue = event.target.value.toString();

        // 'LT' is short for 12 hour format in moment.js
        // If the time is not after 11:15 AM, put a validation for that

        if (
          moment(newValue, "LT", true).isValid() &&
          moment(newValue, "LT").isSameOrAfter()
        ) {
          setStartTime(moment(newValue, "LT"));
          setEndTime(moment(newValue, "LT").add(30, "minutes"));
        }
      });
    }
  };

  function myFunction(e, item) {
    e.stopPropagation();
    document.getElementById("myDropdown" + item).classList.toggle("show");
  }

  return (
    <>
      {tableData.length > 0 ? (
        <div className="col-12 col-lg-12">
          {isLoading && tableData.length > 0 ? (
            <div className="row m-5">
              <div className={`col-lg-12 mt-1`}>
                <LoadingShape style={{ height: "40px" }} />
              </div>
              <div className="col-lg-12 mt-4">
                <LoadingShape style={{ height: "110px" }} />
              </div>
            </div>
          ) : (
            tableData.length > 0 && (
              <div className="dashboardCard userSchedule">
                <div className="d-flex scheduleTop justify-content-between align-items-center">
                  <h5 className="dashboardHeading">
                    Today’s Schedule({tableData.length})
                  </h5>
                  <div
                    className="d-flex align-items-center viewAll"
                    onClick={() => {
                      history.push("/interviews");
                    }}
                  >
                    <p className="dashboardViewAll">View all</p>
                    <img className="ms-2" src={viewAllSvg} alt="viewAll" />
                  </div>
                </div>
                <div className="text-nowrap dashboardScheduleTable">
                  {tableData.slice(0, 10).map((scheduleData) => (
                    <div
                      className="d-flex justify-content-between py-3 ps-3 pe-4 dashboardScheduleRow"
                      key={scheduleData._id}
                    >
                      <div className="d-flex align-items-center">
                        <p className="interviewTitleText dashboardSubHeadText scheduleMarginRight">
                          {`${
                            scheduleData.interviewId.interviewTitle.length > 30
                              ? scheduleData.interviewId.interviewTitle.slice(
                                  0,
                                  30
                                ) + "..."
                              : scheduleData.interviewId.interviewTitle
                          }`}
                        </p>
                        <div className="sheduleDivider scheduleMarginRight"></div>
                        <img
                          className="me-1"
                          src={candidateSvg}
                          alt="candidate"
                        />
                        <p className="scheduleMarginRight scheduleCandidateName">
                          Candidate -{" "}
                          {`${
                            scheduleData.interviewId.candidateName?.length > 1
                              ? scheduleData.interviewId.candidateName
                              : scheduleData?.interviewId?.candidateEmail
                              ? scheduleData.interviewId.candidateEmail.split(
                                  "@"
                                )[0]
                              : "Instant Interview"
                          }`}
                        </p>
                        <p
                          className={`scheduleMarginRight scheduleStatus scheduleTimeText ${
                            scheduleData.acceptanceFromCandidate == "pending"
                              ? "schedulePending"
                              : ""
                          }`}
                        >
                          <img
                            className="me-2"
                            src={` ${
                              scheduleData.acceptanceFromCandidate == "pending"
                                ? OrangeTick
                                : ""
                            }
                      ${
                        scheduleData.acceptanceFromCandidate == "accepted"
                          ? GreenTick
                          : ""
                      }
                      ${
                        scheduleData.acceptanceFromCandidate == "rejected"
                          ? RedTick
                          : ""
                      }
                      `}
                            alt="more Icon"
                          />
                          {scheduleData.interviewStartTime &&
                            moment(scheduleData.interviewStartTime).format(
                              "h:mm"
                            )}
                          &nbsp; -&nbsp;
                          {scheduleData.interviewStartTime &&
                            moment(scheduleData.interviewStartTime)
                              .add(30, "minutes")
                              .format("h:mm   A")}
                          &nbsp;&nbsp;
                          <img src={Dot} alt="more Icon" />
                          &nbsp;&nbsp;
                          {scheduleData.interviewStartTime &&
                            moment(scheduleData.interviewStartTime).format(
                              "MMM D YYYY"
                            )}
                        </p>
                      </div>
                      <div className="d-flex">
                        <div className="scheduleMarginRight cursor-pointer">
                          <img
                            src={Message}
                            alt="more Icon"
                            className="ScheduleMessageIcon"
                          />
                        </div>
                        <div className="scheduleMarginRight cursor-pointer">
                          <img
                            src={Link}
                            alt="Link Icon"
                            className="link-icon-display ScheduleLinkIcon"
                            onClick={() => onCopy(scheduleData.interviewLink)}
                          />
                        </div>
                        <button
                          className={`scheduleMarginRight ${
                            moment().isSame(
                              scheduleData.interviewStartTime,
                              "day"
                            )
                              ? `schedulePrimaryBtn`
                              : `scheduleSecondayBtn`
                          }`}
                        >
                          Start Interview
                        </button>
                        <div
                          id="three_dots"
                          className="threeDotsdropdown cursor-pointer"
                        >
                          <img
                            src={MoreIcon}
                            alt="more Icon"
                            onClick={(e) => myFunction(e, scheduleData._id)}
                            className="dropbtn three-dots-1 dashboardScheduleSectionMore"
                          />
                          <div
                            id={"myDropdown" + scheduleData._id}
                            className="dropdown-content   list-view-dropdown"
                            style={{
                              textAlign: "start",
                            }}
                          >
                            <ul className="dropdown-ul">
                              {scheduleData?.interviewStatus == "pending" &&
                              scheduleData?.interviewId?.interviewType ==
                                "scheduled" ? (
                                <li className="dropdown-li-btn">
                                  <button
                                    className="menu-btn card-view"
                                    onClick={() => editSchedule(scheduleData)}
                                  >
                                    Edit Schedule
                                  </button>
                                </li>
                              ) : (
                                ""
                              )}

                              <li className="dropdown-li-btn">
                                <button
                                  className="menu-btn card-view"
                                  onClick={() => deleteInterview(scheduleData)}
                                >
                                  Delete Interview
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}

          <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            centered
            dialogClassName={"createInterviewLink"}
            enforceFocus={false}
            className="createInterviewModal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-between p-1">
                <h5>
                  {createRound == true ? (
                    "Create another Round"
                  ) : (
                    <>
                      {isEditable
                        ? "Edit an Interview"
                        : "Create an Interview Link"}
                    </>
                  )}
                </h5>
                <img
                  src={Cross}
                  onClick={handleClose}
                  className="img-fluid"
                  style={{ width: "15px", height: "15px" }}
                />
              </div>
            </Modal.Header>
            <Modal.Body>
              {modalInterview === "instant" && (
                <div className="text-center instant">
                  <input
                    className="copyLink mt-3 p-2"
                    value={instantLink}
                    disabled
                  />
                  <button
                    className="copyBtn text-center p-2"
                    onClick={() => copyLink("instant")}
                  >
                    <p>
                      <img className="me-1 my-0" src={Copy} />
                      {isCopied !== "instant" ? "Copy" : "Copied!"}
                    </p>
                  </button>
                  <p className="my-4">
                    Anyone with the link can attend the interview
                  </p>
                  <div className="shareInterview mb-3">
                    <a href={`mailto:""?body=${instantLink}`} target="_blank">
                      <img className="mt-0 mx-2" src={Email} />
                    </a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?url=${instantLink}`}
                      target="_blank"
                    >
                      <img className="mt-0 mx-2" src={Linkedin} />
                    </a>
                  </div>
                </div>
              )}
              {modalInterview === "schedule" && (
                <div className="text-center schedule">
                  <div>
                    <input
                      className={`copyLink mt-3 p-2  ${
                        isEditable || createRound ? "disabled-class" : ""
                      }`}
                      value={instantLink}
                      readOnly={isEditable || createRound}
                      disabled
                    />
                    <button
                      className={
                        isCopied
                          ? "copyBtn text-center px-1"
                          : "copyBtn text-center p-2"
                      }
                      onClick={() => copyLink("schedule")}
                    >
                      <p>
                        <img className="me-1 my-0" src={Copy} />
                        {isCopied !== "schedule" ? (
                          <div style={{ display: "inline" }}>Copy</div>
                        ) : (
                          <div style={{ display: "inline", fontSize: "12px" }}>
                            Copied!
                          </div>
                        )}
                      </p>
                    </button>
                  </div>

                  <div className="mt-3 schedulingDiv px-3">
                    <h6>Interview Title</h6>
                    <input
                      className={`copyLink mt-3 p-2  ${
                        createRound ? "disabled-class" : ""
                      }`}
                      placeholder="Enter Interview Title"
                      onChange={oninterviewTitleChange}
                      value={interviewTitle}
                      readOnly={createRound}
                    />
                    {interviewTitleError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        This Field is required
                      </p>
                    )}
                    {/* Date Picker Here START */}

                    <h6 className="mt-4 mb-3">Select Date</h6>
                    <div className="datepicker">
                      <img src={DateIcon} className="img-fluid me-2" />

                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={handleDateChange}
                        isValidDate={valid}
                        dateFormat="DD MMM YYYY"
                        value={selectedDate}
                      />
                      {/* Date Picker Here End */}
                    </div>

                    {/* <ComboDatePicker/> */}
                    <h6 className="mt-4 mb-3">Time Slot</h6>
                    <div className="d-flex justify-content-start margin-top-bottom">
                      <img src={Clock} className="img-fluid me-2" />
                      <TimePicker
                        value={startTime}
                        minuteStep={30}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleTimeChange}
                        onOpen={handleTimeClick}
                        defaultValue={moment()}
                        clearText=""
                        clearIcon=""
                      />
                      <span className="rc-time-picker-tofield">TO</span>
                      <TimePicker
                        value={endTime}
                        minuteStep={30}
                        use12Hours={true}
                        showSecond={false}
                      />
                    </div>

                    <div className="my-4 d-flex">
                      <button
                        className={
                          mailList === "MHList"
                            ? `interviewListselected`
                            : `interviewList`
                        }
                        style={{ borderRadius: "3px 0 0 3px" }}
                        onClick={(e) => {
                          setMailList("MHList");
                        }}
                      >
                        From your MH List
                      </button>
                      <button
                        className={
                          mailList === "email"
                            ? `interviewListselected`
                            : `interviewList`
                        }
                        style={{ borderRadius: "0 3px 3px 0" }}
                        onClick={(e) => {
                          setMailList("email");
                        }}
                      >
                        Email ID
                      </button>
                    </div>
                    {mailList === "email" && (
                      <>
                        <div>
                          <h6 className="mb-1">Candidate Email</h6>
                          <input
                            className={`copyLink mt-3 p-2  ${
                              createRound ? "disabled-class verified-icon" : ""
                            }`}
                            onChange={onCandidateEmailChange}
                            value={candidateEmail}
                            placeholder="Enter Candidate Email"
                            readOnly={createRound}
                          />
                        </div>
                        {candidateEmailError !== "" && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {candidateEmailError}
                          </p>
                        )}
                      </>
                    )}
                    {mailList === "MHList" && (
                      <div style={{ color: "#e4e4e4" }}>
                        <h6>Candidate Name</h6>
                        <div className="mt-2">
                          <Select
                            styles={customStyles}
                            value={selectedCandidate}
                            onChange={handlenextInterviewerChange}
                            options={Mhlist}
                            placeholder="Search or choose from List"
                          />
                          <div></div>
                        </div>
                      </div>
                    )}

                    <div className="px-3">
                      <button
                        className="my-4 scheduleInterviewlg"
                        onClick={createScheduledInterview}
                      >
                        {createRound == true ? (
                          "Create another Round"
                        ) : (
                          <>
                            {isEditable
                              ? "Edit an Interview"
                              : "Schedule an Interview"}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Schedule;
