import React, { useEffect, useRef, useState } from "react";
import { LoadingShape } from "react-rainbow-components";
import { useHistory } from "react-router-dom";
import QuestionSet from "../../../../assets/Icons/Assessment/QuestionSet.svg";
import QuestionSetTime from "../../../../assets/Icons/Assessment/QuestionSetTime.svg";
import QuestionSetType from "../../../../assets/Icons/Assessment/QuestionSetType.svg";
import Star from "../../../../assets/Icons/Assessment/star.svg";
import ActiveStar from "assets/Icons/Assessment/activeStar.svg";
import Dots from "../../../../assets/Icons/Settings/dots.svg";
import InviteParticipants from "../../../../assets/Icons/Assessment/InviteParticipants.svg";
import ConfirmationModal from "pages/PrivatePages/JobsPage/components/ConfirmationModal/ConfirmationModal";
import { timer } from "SharedMethods/CommenMethods";
import { isAuthenticated } from "helper/user";
export default function QuestionSetCard(props) {
  const {
    questionData,
    handleQuestionClick,
    type,
    isLoading,
    selectedQuestion,
    isSidebarCollapse,
    bookmarkAssessment,
    DeleteAssessments,
    setShowPublishPopup,
    publishQuestion,
    setPublishQuestion,
    setPublishMOdal,
    publishAssessment,
    published,
  } = props;
  const [showOptions, setShowOptions] = useState("");
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOptions("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onShowmoreOption = (event) => {
    event.stopPropagation();
    setPublishQuestion(questionData);
    setShowOptions(questionData?._id);
  };

  return (
    <div
      key={props.index || "index"}
      className={`QuestionSetCardContainer ${
        props.type === "assessments" ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (props.type === "assessments") {
          history.push(`/assessments/${questionData?._id}/questions`);
          setPublishQuestion(questionData);
        }
      }}
    >
      {isLoading ? (
        <LoadingShape className="loaderForInput" />
      ) : (
        <div
          className={`d-flex flex-wrap justify-content-between
        pe-4 `}
        >
          <div className={`d-flex flex-wrap align-items-center `}>
            <input
              type="checkbox"
              className="me-3 AssessmentCheckbox"
              onChange={() => {
                handleQuestionClick(questionData);
              }}
              onClick={(e) => e.stopPropagation()}
              checked={selectedQuestion?.includes(questionData?._id)}
            />
            <span className="cardHeading">{questionData?.title}</span>
            {type !== "questionSet" && (
              <>
                <div className="AssessmentQuestionSetCardVerticalBar mx-3"></div>
                <div className="QuestionSetCardDec">
                  {questionData?.jobRole?.title}
                </div>
              </>
            )}
          </div>{" "}
          {type !== "questionSet" && (
            <div className={`d-flex flex-wrap align-items-center `}>
              <img
                className={`img-fluid me-4 `}
                src={questionData?.starred ? ActiveStar : Star}
                alt="bookmrk"
                loading="lazy"
                onClick={(e) => {
                  e.stopPropagation();
                  bookmarkAssessment();
                }}
                width="20"
                height="20"
              />
              <div className="position-relative">
                <div
                  className="moreOptionsImg py-1 px-2"
                  onClick={onShowmoreOption}
                >
                  <img
                    className="img-fluid ms-2"
                    src={Dots}
                    loading="lazy"
                    alt="options"
                  />
                </div>
                {showOptions === questionData._id && (
                  <div className="AssessmentTabsOptions" ref={wrapperRef}>
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        setPublishMOdal({
                          heading: "Publish Assessment",
                          body: "You can configure setting anytime under setting tab",
                          actionBtnName: `${
                            publishQuestion?.publish ? "Unpublish" : "Publish"
                          }`,
                          closeBtnName: "Cancel",
                          actionBtn: () => {
                            publishAssessment(publishQuestion);
                          },
                          show: true,
                        });
                        setShowPublishPopup(true);
                      }}
                    >
                      {questionData?.publish ? "Unpublish" : "Publish"}
                    </p>
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(
                          `/assessments/${questionData?._id}/setting/general`
                        );
                      }}
                    >
                      Settings
                    </p>
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        setPublishMOdal({
                          heading: "Delete Assessment",
                          body: "Are you sure you want to delete ?",
                          actionBtnName: `Delete`,
                          closeBtnName: "Cancel",
                          actionBtn: () => {
                            DeleteAssessments(questionData?._id, "single");
                          },
                          show: true,
                        });
                        setShowPublishPopup(true);
                        // DeleteAssessments(questionData?._id, "single");
                      }}
                    >
                      Delete
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="d-flex flex-wrap QuestionSetCardDescription align-items-center">
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <>
            <img src={QuestionSet} className="img-fluid me-2" />
            <span className="QuestionSetCardDec me-5">
              {questionData?.questions || questionData?.sections?.length}{" "}
              {questionData?.questions ? "Questions" : "Sections"}
            </span>
          </>
        )}
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <>
            <img src={QuestionSetTime} className="img-fluid me-2" />
            <span className="QuestionSetCardDec me-5">
              {timer(questionData?.duration, "words")}
            </span>
          </>
        )}
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          <>
            <img src={QuestionSetType} className="img-fluid me-2" />
            <span className="QuestionSetCardDec me-5">
              {props.type === "assessments"
                ? questionData?.createdBy?.firstName +
                  " " +
                  questionData?.createdBy?.lastName
                : "MH Library"}
            </span>
          </>
        )}
      </div>

      <div className="d-flex QuestionSetCardDescription justify-content-between">
        {isLoading ? (
          Array.from(
            {
              length: 4,
            },
            (_, i) => (
              <div className="d-flex" key={`loader${i}`}>
                <LoadingShape className="loaderForLabel" />
              </div>
            )
          )
        ) : (
          <>
            <div
              className={`d-flex flex-wrap align-items-center ${
                isSidebarCollapse ? "col-lg-7" : "col-lg-6"
              }`}
            >
              {questionData?.skills?.length > 0 && (
                <span className="Skillsheading me-2 mb-1">Skills:</span>
              )}
              {questionData?.skills?.map((skill, index) => {
                return (
                  skill !== null && (
                    <div
                      className="AssessmentQuestionSetSkillsTag me-2 mb-1"
                      key={index}
                    >
                      {skill}
                    </div>
                  )
                );
              })}
            </div>
            {type !== "questionSet" && published && (
              <div className="d-flex pe-4 align-items-center">
                {/* <span className="QuestionSetCardDec px-3 seprator">
                  Invited:{questionData?.invited}
                </span>
                <span className="QuestionSetCardDec px-3 seprator">
                  Attempted:{questionData?.attempted}
                </span>
                <span className="QuestionSetCardDec px-3">
                  Completed:{questionData?.completed}
                </span> */}
                <button
                  className="secondaryColoredBtn d-flex align-items-center"
                  onClick={(e) => (
                    e.stopPropagation(),
                    history.push(
                      `/assessments/${questionData?._id}/participants`
                    )
                  )}
                >
                  <img src={InviteParticipants} className="img-fluid" />
                </button>
              </div>
            )}
          </>
        )}
        {isLoading ? (
          <LoadingShape className="loaderForLabel" />
        ) : (
          props.recommended && (
            <div className="ms-auto QuestionSetRecommmendedFlag d-flex align-items-center">
              <div className="QuestionSetRecommmendedFlagDiv me-3"></div>
              Recommmended by MH
            </div>
          )
        )}
      </div>
    </div>
  );
}
