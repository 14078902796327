import axios from "axios";
import { BootcampBackend } from "backend";

export function GetAuthorizationConfig() {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("jwt")
          ? JSON.parse(localStorage.getItem("jwt")).token
          : null,
      },
    };
  }

export const authService = {
  fetchCurrentUser: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/iamUser/getIamUserData`,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
