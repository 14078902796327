import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import hackathonSearch from "../../../assets/Icons/MyHackathons/hackathonSearch.svg";
import startArrow from "../../../assets/Icons/MyHackathons/startArrow.svg";
import React, { useEffect, useState } from "react";
import "./questions.css";
import QuestionSetCard from "./components/QuestionSetCard";
import {useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { BootcampBackend } from "backend";
import { useSidebar } from "helper/SideBarContext";
import InfiniteScroll from "react-infinite-scroll-component";


export default function QuestionSetList({
  props,
  selectedQuestion,
  setSelectedQuestions,
}) {
  const { isSidebarCollapsed } = useSidebar();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [questionSet, setQuestionSet] = useState([]);
  const [totalQuestionSet, setTotalQuestionSet] = useState(0);
  const location = useLocation();
  const params = useParams();
  const getMcqQuestionSet = async (page) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllMcqQuestionSet?current_page=${page}&limit=${10}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      setIsLoading(false);
      setTotalQuestionSet(res.data.result.totalQuestionSet);
      setQuestionSet((prev) => [...prev, ...res.data.result.data]);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getCodingQuestiotnSet = async (page) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllPracticeQuestionCategories?current_page=${page}&limit=${10}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      setIsLoading(false);
      location.pathname?.includes("mcq") ? setTotalQuestionSet(res.data.result.totalQuestionSet) : setTotalQuestionSet(res.data.result.total);
      setQuestionSet((prev) => [...prev, ...res.data.result.data]);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const getQuestionList = () => {
    props.history.push({
      pathname: `/assessments/${params.assessmentId}/${params.questionType}/questions/${params.sectionId}`,
      // state: { data: selectedQuestion, type: "" },
    });
    localStorage.setItem("selectedQuestions", selectedQuestion);
  };
  const handleQuestionClick = (questionObj) => {
    if (selectedQuestion.includes(questionObj._id)) {
      let copy = [...selectedQuestion];
      let indexOfQuestion = copy.indexOf(questionObj._id);
      copy.splice(indexOfQuestion, 1);
      setSelectedQuestions(copy);
    } else {
      setSelectedQuestions([...selectedQuestion, questionObj._id]);
    }
  };
  const loadMoreQuestionSet = () => {
    if (totalQuestionSet > questionSet.length) {
      location.pathname?.includes("mcq") ? getMcqQuestionSet(currentPage+1) : getCodingQuestiotnSet(currentPage+1);
      setCurrentPage((prev) => prev + 1);
    }
  }
  const hasMoreData = () => totalQuestionSet > questionSet.length;
  useEffect(()=>{
    location.pathname?.includes("mcq") ? getMcqQuestionSet(currentPage) : getCodingQuestiotnSet(currentPage);
  },[])
  return (
    <>
      <Header
        heading={`${localStorage.getItem("assessment_title")} Hiring Test`}
        route={`/assessments/${params.assessmentId}/questions`}
      />
      <Navbar />
      <div
        className={`AssessmentCntr QuestionSetList px-3 pt-2 ${isSidebarCollapsed ? "collapsed-content-page" : "content-page"
          }`}
      >
        <div className="d-flex QuestionSetListHeader justify-content-between align-items-center">
          <div>
            <div className="cardHeading">MachineHack Curated Question Set</div>
            <div className="paragraph">
              Select question sets from the below list
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="SearchQuestionSetInput me-3">
              <img className="me-2" src={hackathonSearch} />
              <input placeholder="Search for a questions" />
            </div>
            {selectedQuestion.length > 0 ? (
              <button onClick={getQuestionList} className="secondaryColoredBtn">
                <span> Next</span>
                <img className="ms-3" src={startArrow} />
              </button>
            ) : (
              <button className="secondaryColoredBtn disabledRemoveQestionBtn">
                <span> Next</span>
                <img className="ms-3" src={startArrow} />
              </button>
            )}
          </div>
        </div>
        <div className="ListOfQuestionSet mt-3">
        <InfiniteScroll
          dataLength={questionSet.length}
          next={loadMoreQuestionSet}
          hasMore={hasMoreData}
          // loader={<LoadingShape className="loaderForInput" />}
        >
          {questionSet.map((questionData, i) => (
            <QuestionSetCard
              questionData={questionData}
              index={i}
              handleQuestionClick={handleQuestionClick}
              type="questionSet"
              isLoading={isLoading}
              key={i}
              selectedQuestion={selectedQuestion}
              isSidebarCollapse={isSidebarCollapsed}
            />
          ))}
        </InfiniteScroll>
        </div>
      </div>
    </>
  );
}
