import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Popper from "assets/Images/Pricing/popper.svg";
import "./pricing.css";
import { useSelector } from "react-redux";

const Plans = [
  {
    id: "1",
    name: "Setup your Account",
    route: "/settings/editProfile",
  },
  {
    id: "2",
    name: "Add Employees",
    route: "/settings/iamUser",
  },
  {
    id: "3",
    name: "Create Assessments",
    route: "/assessments",
  },
  {
    id: "4",
    name: "Create Interviews",
    route: "/interviews",
  },
  {
    id: "5",
    name: "Host Hackathons",
    route: "/myHackathons",
  },
];

const Benefits = [
  {
    id: "1",
    name: "Request for Hackathons",
  },
  {
    id: "2",
    name: "Unlimited Job Posting",
  },
  {
    id: "3",
    name: "Unlimited enterprise users",
  },
  {
    id: "4",
    name: "Chat support",
  },
  {
    id: "5",
    name: "1 Assessment / month",
  },
];

const WelcomeUserPlanScreen = ({
  showUserPlanWelcomeScreen,
  setShowUserPlanWelcomeScreen,
}) => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const closeModal = () => {
    setShowUserPlanWelcomeScreen(false);
  };
  return (
    <Modal
      centered="true"
      dialogClassName="UserPlanWelcomeScreenModal"
      show={showUserPlanWelcomeScreen}
    >
      <Modal.Body>
        <div className="userPlanScreenModalBody">
          <div className="closeModalHld" onClick={closeModal}>
            <FontAwesomeIcon
              icon={faXmark}
              className="close-icon"
              transform="shrink-6 left-4"
            />
          </div>
          <h1 className="userName">Hello {user?.firstName + " " + user?.lastName} <img src={Popper} className="img-fluid popper-img" alt="congrats" /></h1>
          <p className="UserPlanWelcomeScreenText">
            Welcome to MachineHack Enterprises
          </p>

          <p className="howToStart">How to Start with</p>
          {Plans.map((x) => (
            <button className="PlansBtn" key={x.id} onClick={()=> history.push(x.route)}>
              <span className="planName">{x.name} <FontAwesomeIcon icon={faArrowRight} className="arrow-right" /></span>
            </button>
          ))}
          <div className="benefitsHld">
            <span className="benefitsHead">Starter Plan benefits</span>
            <ul>
              {Benefits.map((x) => (
                <li className="col-4" key={x.id}>
                  <FontAwesomeIcon icon={faCheck} className="tick-icon" />
                  {x.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default WelcomeUserPlanScreen;
