import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import "./createCustomQuestions.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import arrowRight from "../../../assets/Icons/Assessment/whiteArrowRight.svg";
import greenTick from "../../../assets/Icons/Assessment/greenTick.svg";
import { useHistory, useParams } from "react-router-dom";
import SelectSkills from "./components/Skill";
import QuestionCSVModal from "./components/UploadQuestionCSV";
import axios from "axios";
import { BootcampBackend } from "backend";
import Dropdown from "./components/dropDown";
import { useSidebar } from "helper/SideBarContext";
const difficultyOptions = [
  {
    id: "1",
    label: "Beginner",
    value: "BEGINNER",
  },
  {
    id: "2",
    label: "Intermediate",
    value: "INTERMEDIATE",
  },
  {
    id: "3",
    label: "Advanced",
    value: "ADVANCED",
  },
];
export default function CreateCustomMCQ(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [options, setOptions] = useState([
    {
      _id: "1",
      option: "",
      is_correct: false,
    },
    {
      _id: "2",
      option: "",
      is_correct: false,
    },
    {
      _id: "3",
      option: "",
      is_correct: false,
    },
    {
      _id: "4",
      option: "",
      is_correct: false,
    },
  ]);
  const [questionData, setQuestionData] = useState({
    title: "",
    tags: [],
    problemDescription: "",
    score: 1,
    duration: 1,
    difficulty: "BEGINNER",
  });
  const [titleError, setTitleError] = useState("");
  const [tagsErr, setTagsErr] = useState("");
  const [problemDescriptionErr, setProblemDescriptionErr] = useState("");
  const [scoreErr, setScoreErr] = useState("");
  const [durationErr, setDurationErr] = useState("");
  const [optionsErr, setOptionsErr] = useState("");
  const [question, setQuestion] = useState({});
  const history = useHistory();
  const params = useParams();
  const questionId = localStorage.getItem("questionId");
  let isEdit = location.pathname.split("/")[3] === "update";
  const changeOption = (value, index) => {
    let newOptions = options.slice();
    let newOption = {
      _id: options[index]?._id,
      option: value,
      is_correct: options[index]?.is_correct,
    };
    newOptions[index] = newOption;
    setOptions(newOptions);
  };
  const changeOptionValue = (value, index) => {
    // let newOptions = [...options];
    // newOptions[index] = { ...newOptions[index], is_correct: value };
    setOptions(
      options?.filter((x) => {
        if (x._id === index) {
          x.is_correct = true;
        } else {
          x.is_correct = false;
        }
        return options;
      })
    );
  };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setQuestionData((prevValue) => {
  //     return {
  //       ...prevValue,
  //       [name]: name === "score" ? Number(value) : value,
  //     };
  //   });
  // };
  const createQuestion = async (request, addAnother) => {
    await axios
      .post(
        `${BootcampBackend}/enterpriseAssessment/createAssessmentMcqQuestion/${params.sectionId}`,
        request,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        reset();
        if (!addAnother && response) {
          localStorage.setItem("isEditQuestions", true);
          history.push({
            pathname: `/assessments/${params.assessmentId}/mcq/questions/${params.sectionId}`,
            // state: {
            //   type: "edit",
            // },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateQuestion = async (request, addAnother) => {
    await axios
      .put(
        `${BootcampBackend}/enterpriseAssessment/updateMcq/${questionId}`,
        request,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        reset();
        if (!addAnother && response) {
          localStorage.setItem("isEditQuestions", true);
          history.push({
            pathname: `/assessments/${params.assessmentId}/mcq/questions/${params.sectionId}`,
            // state: {
            //   type: "edit",
            // },
          });
        }
        localStorage.removeItem("questionId");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reset = () => {
    setQuestionData({
      difficulty: "",
      problemDescription: "",
      duration: "",
      score: "",
      tags: [],
      title: "",
    });
    setOptions([
      {
        _id: "1",
        option: "",
        is_correct: false,
      },
      {
        _id: "2",
        option: "",
        is_correct: false,
      },
      {
        _id: "3",
        option: "",
        is_correct: false,
      },
      {
        _id: "4",
        option: "",
        is_correct: false,
      },
    ]);
  };
  const createMCQquestion = async (event, addAnother) => {
    event.preventDefault();
    try {
      if (
        questionData?.title === "" ||
        questionData?.problemDescription === "" ||
        questionData?.score === "" ||
        questionData?.duration === ""
      ) {
        // if (questionData?.tags?.length === 0) {
        //   setTagsErr("This field is required");
        // }
        if (questionData?.title === "") {
          setTitleError("This field is required");
        }
        if (questionData?.problemDescription === "") {
          setProblemDescriptionErr("This field is required");
        }
        if (questionData?.score === "") {
          setScoreErr("This field is required");
        }
        if (questionData?.duration === "") {
          setDurationErr("This field is required");
        }
        return;
      }
      let isSelect = [];
      options?.map((x) => {
        isSelect.push(x.is_correct.toString());
        if (x?.option === "") {
          setOptionsErr("this field is required");
          return;
        }
      });
      if (!isSelect.includes("true")) {
        setOptionsErr((prev) => prev + " " + "select correct option");
        return;
      }
      const newOptions = [];
      options.filter((x) =>
        newOptions.push({ option: x.option, is_correct: x.is_correct })
      );
      const requestBody = {
        title: questionData?.title,
        difficulty: questionData?.difficulty,
        duration: parseInt(questionData.duration) * 60,
        score: parseInt(questionData.score),
        type: "MCQ",
        assessmentId: params.assessmentId,
        description: questionData?.problemDescription,
        options: isEdit ? options : newOptions,
        skills: questionData?.tags,
      };
      isEdit && (requestBody.questionSetId = question?.question_set?._id);
      isEdit && (requestBody.sectionId = params?.sectionId);
      isEdit
        ? updateQuestion(requestBody, addAnother)
        : createQuestion(requestBody, addAnother);
    } catch (err) {
      console.log(err);
    }
  };

  const getSpecificQuestion = async () => {
    try {
      const response = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getSpecificMcqQuestionEnterprise/${questionId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (response) {
        const question_data = response?.data?.result?.data;
        setQuestion(question_data);
        setQuestionData({
          title: question_data?.title,
          tags: question_data?.skills,
          problemDescription: question_data?.description,
          score: question_data?.score,
          duration: Math.round(question_data?.duration / 60),
          difficulty: question_data?.difficulty,
        });
        setOptions(question_data?.options);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (isEdit) {
      getSpecificQuestion();
    }
  }, []);

  return (
    <>
      <Header
        heading="Custom MCQ"
        route={questionData.title === ""? `/assessments/${params.assessmentId}/questions`:`/assessments/${params.assessmentId}/mcq/questions/${props.match.params.sectionId}`}
      />
      <Navbar />
      <div
        className={`assessmentCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="d-flex justify-content-between mcqNavigation align-items-center">
          <p>
            <span
              onClick={() => {
                history.push("/assessments");
              }}
              className="cursor-pointer"
            >
              Assessments
            </span>{" "}
            {">"}{" "}
            <span
              onClick={() => {
                history.push(
                  `/assessments/${params.assessmentId}/questions`
                );
              }}
              className="cursor-pointer"
            >
              {localStorage.getItem("assessment_title") &&
                localStorage.getItem("assessment_title")}
            </span>{" "}
            {">"} Multiple Choice
          </p>

          <div className="d-flex align-items-center">
            <span>Have your MCQs handy? Upload them in Bulk.</span>
            <button
              className="ms-3 uploadCSVBtn"
              onClick={() => setBulkUploadModal(true)}
            >
              Upload your CSV &nbsp;
              <img src={arrowRight} />
            </button>
          </div>
        </div>
        <form className="col-lg-11 mx-auto flex-wrap mcqForm">
          <label className="questionLabel">
            Question<span className="text-pink">*</span>
          </label>
          <input
            className="form-control mcqFields"
            type="text"
            placeholder="What’s your problem title?"
            name="title"
            value={questionData?.title}
            onChange={(e) => {
              //handleChange(e);
              setQuestionData({
                ...questionData,
                title: e.target.value,
              });
              setTitleError("");
            }}
          />
          {titleError !== "" && <p className="errortext">{titleError}</p>}
          <div className="row scoreAndDuration">
            <div className="col-lg-2">
              <label className="mt-3">
                Score
              </label>
              <input
                className="form-control mcqFields"
                type="number"
                placeholder="Enter Score"
                name="score"
                value={questionData?.score === 0 ? "" : questionData?.score}
                onChange={(e) => {
                  // handleChange(e);
                  setQuestionData({
                    ...questionData,
                    score: e.target.value,
                  });
                  setScoreErr("");
                }}
              />
              {scoreErr !== "" && <p className="errortext">{scoreErr}</p>}
            </div>
            <div className="col-lg-2">
              <label className="mt-3">
                Time
              </label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control mcqDuration"
                  type="number"
                  placeholder="Duration"
                  name="duration"
                  value={
                    questionData?.duration === 0 ? "" : questionData?.duration
                  }
                  onChange={(e) => {
                    //handleChange(e);
                    const IntegerDuration = Number(
                      e.target.value.replace(/-/gi, "")
                    );
                    setQuestionData({
                      ...questionData,
                      duration: IntegerDuration,
                    });
                    setDurationErr("");
                  }}
                />
                <span className="durationCapsule">min</span>
              </div>
              {durationErr !== "" && <p className="errortext">{durationErr}</p>}
            </div>
            <div className="col-lg-2">
              <label className="mt-3 mb-2">
                Difficulty<span className="text-pink">*</span>
              </label>
              <Dropdown
                style={{ width: "100%" }}
                dropDownOptions={difficultyOptions}
                ondropdownItemClick={(object) =>
                  setQuestionData({ ...questionData, difficulty: object.value })
                }
                dropDownClass={`p-2`}
                selectedValue={questionData.difficulty}
                value="value"
              />
            </div>
            <div className="col-lg-12">
              <label className="mt-3 tagsMcq">
                Tags
              </label>
              <SelectSkills
                skillSet={questionData?.tags}
                setSkillSet={setQuestionData}
                setTagsErr={setTagsErr}
              />
              {/* {tagsErr !== "" && <p className="errortext">{tagsErr}</p>} */}
            </div>
          </div>
          <div className="mt-4">
            <label className="problemText">
              Problem Description<span className="text-pink">*</span>
            </label>
            <div className="mt-3">
              <CKEditor
                editor={Editor}
                data={questionData?.problemDescription}
                config={{
                  toolbar: {
                    items: [
                      "Essentials",
                      "Heading",
                      "Paragraph",
                      "bold",
                      "italic",
                      "uploadImage",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "outdent",
                      "indent",
                      "mediaEmbed",
                      "insertTable",
                      "alignment",
                      "codeBlock",
                      "undo",
                      "redo",
                    ],
                  },
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setQuestionData((prev) => ({
                    ...prev,
                    problemDescription: data,
                  }));
                  setProblemDescriptionErr("");
                }}
                name="mcqDescription"
              />
              {problemDescriptionErr !== "" && (
                <p className="errortext">{problemDescriptionErr}</p>
              )}
            </div>
            <div className="mcqOptions ">
              <p>
                Choices<span className="text-pink">*</span>
              </p>
              {options.map((option, i) => {
                return (
                  <div key={i}>
                    <div className="d-flex flex-wrap">
                      <div className="d-flex justify-content-between  align-items-center mt-3 w-100">
                        <div className="d-flex ">
                          <input
                            type="radio"
                            onChange={() =>
                              changeOptionValue(true, option?._id)
                            }
                            name="McqOptions"
                            value={option.is_correct}
                            checked={option.is_correct}
                          />
                          <label className="ms-2 mt-1">
                            Option {(i + 10).toString(36).toUpperCase()}
                          </label>
                        </div>
                        {option.is_correct ? (
                          <div className="markAsAns ms-auto correctOption">
                            <img src={greenTick} className="me-3" />
                            Marked as answer
                          </div>
                        ) : (
                          <div className="markAsAns ms-auto">
                            Mark as answer
                          </div>
                        )}
                      </div>
                      <input
                        className="form-control optionAnswerBox ms-4"
                        placeholder={`Enter your option ${(i + 10)
                          .toString(36)
                          .toUpperCase()}...`}
                        value={option.option}
                        onChange={(e) => {
                          changeOption(e.target.value, i);
                          setOptionsErr("");
                        }}
                      />
                    </div>
                    {option.option === "" && optionsErr !== "" && (
                      <p className="errortext">{optionsErr}</p>
                    )}
                    {option.option !== "" && optionsErr !== "" && i === 0 && (
                      <p className="errortext">{optionsErr}</p>
                    )}
                  </div>
                );
              })}

              {/* <div
                className="addOptionsBtn"
                onClick={() =>
                  setOptions([
                    ...options,
                    {
                      option: "",
                      is_correct: false,
                    },
                  ])
                }
              >
                <span>+</span>
                Add more options
              </div> */}
              <div className="text-center my-5">
                <button
                  type="button"
                  className="saveBtns"
                  onClick={(e) => createMCQquestion(e, false)}
                >
                  Save
                </button>
                {!isEdit && (
                  <>
                    <span className="text-muted mx-4  ">(or)</span>
                    <button
                      className="saveBtns"
                      onClick={(e) => createMCQquestion(e, true)}
                    >
                      Save & Add another
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
        <QuestionCSVModal
          bulkUploadModal={bulkUploadModal}
          setBulkUploadModal={setBulkUploadModal}
          sectionId={props.match.params.sectionId}
          assessmentId={params.assessmentId}
        />
      </div>
    </>
  );
}
