import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useState, useEffect } from "react";
import arrow from "../../../assets/Icons/Assessment/arrow.svg";
import addButton from "../../../assets/Icons/Assessment/addButton.svg";
import candidateFormview from "../../../assets/Icons/Assessment/candidateFormview.svg";
import "./settings.css";
import { useHistory, useParams } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import AssessmentTabs from "./components/AssessmentTabs";
import { LoadingShape } from "react-rainbow-components";
import moment from "moment";
import axios from "axios";
import { BootcampBackend } from "backend";
import AssessmentPreviewModal from "./components/AssessmentPreviewModal";
import ToastMessages from "./components/ToastMessages";

export default function AssessmentSettings(props) {
  const {
    assessmentId,
    completeData,
    assessmentData,
    AllSectionsSummary,
    setAssessmentData,
    candidateDetailsList,
    setCandidateDetailsList,
  } = props;
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    jobLink: "",
    cutOffScore: "0",
    negativeMarks: "0",
    mandataryFields: [],
    fieldsForCandidate: [],
    testDisclaimer: "",
    testInstructions: "",
    starred: false,
    publish: false,
    time: "",
    score: "",
  });
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const [cufoffErr, setCufoffErr] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [fieldsErr, setFieldsErr] = useState("");
  const [disclaimerErr, setDisclaimerErr] = useState("");
  const [instructionsErr, setInstructionsErr] = useState("");
  const [customfieldname, setCustomfieldname] = useState("");

  const params = useParams();
  const history = useHistory();

  const handleModalClose = () => {
    setShowModal(false);
  };
  const onFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onCandidateslistchange = (data) => {
    if (formData.fieldsForCandidate.includes(data)) {
      setFormData((prev) => ({
        ...prev,
        fieldsForCandidate: prev.fieldsForCandidate.filter((x) => x != data),
        mandataryFields: prev.mandataryFields.filter((x) => x !== data),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        fieldsForCandidate: [...prev.fieldsForCandidate, data],
      }));
      setFieldsErr("");
    }
  };
  const onmandatoryFieldschange = (data) => {
    if (formData.fieldsForCandidate.includes(data)) {
      if (formData.mandataryFields.includes(data)) {
        setFormData((prev) => ({
          ...prev,
          mandataryFields: prev.mandataryFields.filter((x) => x !== data),
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          mandataryFields: [...prev.mandataryFields, data],
        }));
      }
    } else {
      setToastMessage({
        variant: "error",
        show: true,
        message: "check the field first to make the field mandatory",
        duration: 3000,
      });
    }
  };
  const SaveChanges = async () => {
    try {
      if (
        formData.title === "" ||
        formData.cutOffScore === "" ||
        formData.cutOffScore === "0" ||
        formData.negativeMarks === "" ||
        formData.fieldsForCandidate.length === 0 ||
        formData.testInstructions === "" ||
        formData.testDisclaimer === "" ||
        formData.score === "" ||
        formData.time === ""
      ) {
        if (
          formData.cutOffScore === "" ||
          parseInt(formData.cutOffScore) === 0
        ) {
          setCufoffErr("This Field is required");
        }
        if (formData.title === "") {
          setTitleErr("This Field is required");
        }
        if (formData.fieldsForCandidate.length === 0) {
          setFieldsErr(
            "form should contain atlease one field, please select one"
          );
        }
        if (formData.testInstructions === "") {
          setInstructionsErr("This Field is required");
        }
        if (formData.testDisclaimer === "") {
          setDisclaimerErr("This Field is required");
        }
        return;
      }
      if (20 > parseInt(formData.cutOffScore) > 100) {
        setCufoffErr(
          "cutoff percentage should be greater than 20 and less than 100"
        );
        return;
      }
      const requestData = formData;
      requestData.time = parseInt(formData.time) * 60;
      const settingresponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/updateEnterpriseAssessment/${params.assessmentId}`,
        requestData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (settingresponse) {
        setAssessmentData(settingresponse.data.result.data.data);
        switch (params.settingtype) {
          case "general":
            history.push(`/assessments/${params.assessmentId}/setting/scores`);
            break;
          case "scores":
            history.push(
              `/assessments/${params.assessmentId}/setting/candidate-details`
            );
            break;
          case "candidate-details":
            setToastMessage({
              variant: "success",
              show: true,
              message: "Asssessment settings has been saved successfully.",
              duration: 3000,
            });
            handleModalClose();
            // setTimeout(() => {
            //   history.push(`/assessments`);
            // }, 4000);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const showPreview = () => {
    formData.Fields = [];
    formData?.fieldsForCandidate.filter((x) =>
      formData.Fields.push({
        fieldName: x,
        isMandate: formData?.mandataryFields.includes(x),
        label: candidateDetailsList.filter((detail) => {
          if (x === detail.value) {
            return detail.label;
          }
        })[0]?.label,
      })
    );
    setFormData(formData);
    setShowModal(true);
  };
  const cancel = () => {
    history.goBack();
  };
  const addCustomField = () => {
    setFormData((prev) => ({
      ...prev,
      fieldsForCandidate: [...prev.fieldsForCandidate, customfieldname],
    }));
    const new_field = {
      label: customfieldname,
      value: customfieldname,
    };
    setCandidateDetailsList((prev) => prev.concat(new_field));
    setShowCustomFields(false);
    setCustomfieldname("");
  };
  useEffect(() => {
    setFormData({
      title: assessmentData?.title || "",
      jobLink: assessmentData?.jobLink || "",
      cutOffScore: assessmentData?.cutOffScore || "0",
      negativeMarks: assessmentData?.negativeMarks?.toString() || "0",
      mandataryFields: assessmentData?.mandataryFields || [],
      fieldsForCandidate: assessmentData?.fieldsForCandidate || [],
      testDisclaimer: assessmentData?.testDisclaimer || "",
      testInstructions: assessmentData?.testInstructions || "",
      starred: assessmentData?.starred || false,
      publish: assessmentData?.publish || false,
      score: completeData?.totalScore || 0,
      time: assessmentData?.time / 60 || 0,
    });
  }, [assessmentData, completeData]);
  return (
    <>
      <div className="assessmentSetting">
        <div className="d-flex ">
          <div className="assessmentSettingNavbar">
            <div
              className={
                params.settingtype === "general"
                  ? "assessmentSettingNavbarTagsActive d-flex justify-content-between w-100"
                  : "assessmentSettingNavbarTags d-flex justify-content-between w-100"
              }
              onClick={() =>
                history.push(`/assessments/${assessmentId}/setting/general`)
              }
            >
              <div className="me-3 ms-3">General</div>{" "}
              <img className="ms-4" src={arrow} />
            </div>

            <div
              className={
                params.settingtype === "scores"
                  ? "assessmentSettingNavbarTagsActive d-flex justify-content-between w-100"
                  : "assessmentSettingNavbarTags d-flex w-100 justify-content-between"
              }
              onClick={() =>
                history.push(`/assessments/${assessmentId}/setting/scores`)
              }
            >
              <div className="me-4 ms-3">Scores</div>{" "}
              <img className="ms-4" src={arrow} />
            </div>
            <div
              className={
                params.settingtype === "candidate-details"
                  ? "assessmentSettingNavbarTagsActive d-flex w-100 justify-content-between"
                  : "assessmentSettingNavbarTags d-flex w-100 justify-content-between"
              }
              onClick={() =>
                history.push(
                  `/assessments/${assessmentId}/setting/candidate-details`
                )
              }
            >
              <div className="text-nowrap me-3 ms-3">Candidate Details</div>

              <img className="ms-4" src={arrow} />
            </div>
          </div>
          <div className="pt-5 col-10 d-flex justify-content-center generalContent">
            {params.settingtype === "general" ? (
              <div className="w-100">
                <div className="col-6 offset-md-3">
                  <div className="testDetails">
                    <div className="mb-4">
                      {isLoading ? (
                        <LoadingShape className="loaderForLabel" />
                      ) : (
                        <label className="assessmentTestDetailsLabel  mb-2">
                          Test Name<span className="text-pink">*</span>
                        </label>
                      )}
                      {isLoading ? (
                        <LoadingShape className="loaderForInput" />
                      ) : (
                        <input
                          name="title"
                          placeholder="Test name"
                          className="w-100 formInput testDetails"
                          value={formData.title}
                          onChange={(e) => (
                            onFormDataChange(e), setTitleErr("")
                          )}
                        //disabled={true}
                        />
                      )}
                      {titleErr !== "" && (
                        <p className="errMessage">{titleErr}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      {isLoading ? (
                        <LoadingShape className="loaderForLabel" />
                      ) : (
                        <label className="assessmentTestDetailsLabel mb-2">
                          Job Description Link
                        </label>
                      )}
                      {isLoading ? (
                        <LoadingShape className="loaderForInput" />
                      ) : (
                        <input
                          name="jobLink"
                          placeholder="Eg. https://www.linkedin.com/jobs/collections/recommended/?currentJobId"
                          className="w-100 formInput"
                          value={formData?.jobLink}
                          onChange={onFormDataChange}
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-5">
                      <div className="col-6">
                        {isLoading ? (
                          <LoadingShape className="loaderForLabel" />
                        ) : (
                          <label className="assessmentTestDetailsLabel mb-2">
                            Score
                          </label>
                        )}
                        {isLoading ? (
                          <LoadingShape className="loaderForInput" />
                        ) : (
                          <input
                            name="score"
                            value={formData?.score}
                            className=" formInput"
                            onChange={onFormDataChange}
                            disabled
                          />
                        )}
                      </div>
                      <div className="col-4">
                        {isLoading ? (
                          <LoadingShape className="loaderForLabel" />
                        ) : (
                          <label className="assessmentTestDetailsLabel mb-2">
                            Duration
                          </label>
                        )}
                        {isLoading ? (
                          <LoadingShape className="loaderForInput" />
                        ) : (
                          <div className="d-flex align-items-center">
                            <input
                              name="time"
                              value={formData?.time}
                              onChange={onFormDataChange}
                              className=" formInput me-3"
                            />
                            <span className="assessmentSettingMinuteLabel">
                              Minutes
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 assessmentSettingGeneralDivider"></div>
                <div className="assessmentSettngSaveBtn offset-md-3 mt-5">
                  {isLoading ? (
                    <LoadingShape className="loaderForButton" />
                  ) : (
                    <button
                      className="secondaryOutlineBtn me-4"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForButton" />
                  ) : (
                    <button className="primaryBtnActive" onClick={SaveChanges}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {params.settingtype === "scores" ? (
              <div className="w-100">
                <div className="col-6 offset-md-3">
                  <div className="testDetails">
                    {isLoading ? (
                      <LoadingShape className="loaderForLabel" />
                    ) : (
                      <label className="assessmentTestDetailsLabel mb-2">
                        Cutoff Score (in percentage)
                      </label>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForDescription" />
                    ) : (
                      <p className="assessmentSettingScoreDescription mb-3">
                        Set a cut off score for the test. All candidates with
                        score greater than cutoff score will automatically be
                        marked qualified.
                      </p>
                    )}
                    <div className="mb-4">
                      {isLoading ? (
                        <LoadingShape className="loaderForInput" />
                      ) : (
                        <input
                          className="formInput"
                          name="cutOffScore"
                          placeholder="For eg.  20, 30, 40 or any other number"
                          value={formData?.cutOffScore}
                          onChange={(e) => (
                            onFormDataChange(e), setCufoffErr("")
                          )}
                        />
                      )}
                      {cufoffErr !== "" && (
                        <p className="errMessage">
                          {cufoffErr || "This Field is required"}
                        </p>
                      )}
                    </div>
                    {isLoading ? (
                      <LoadingShape className="loaderForLabel" />
                    ) : (
                      <label className="assessmentTestDetailsLabel mb-2">
                        MCQ Settings
                      </label>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForDescription" />
                    ) : (
                      <p className="assessmentSettingScoreDescription mb-4">
                        Use this setting to specify a common score for all MCQs
                        just for this test. You can penalise candidates for
                        guessing by specifying a negative number for Incorrect
                        Answer Score.
                      </p>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForDescription" />
                    ) : (
                      <div className="assessmentSettingScoreNote mb-4">
                        NOTE: This score will override MCQ scores explicitly set
                        on a per question basis.
                      </div>
                    )}
                    {/* <div className="d-flex mb-5"> */}
                    {/* <div className="me-5 pe-4">
                        {isLoading ? (
                          <LoadingShape className="loaderForLabel" />
                        ) : (
                          <label className="assessmentTestDetailsLabel mb-1">
                            Correct Answer Score
                          </label>
                        )}
                        {isLoading ? (
                          <LoadingShape className="loaderForInput" />
                        ) : (
                          <input
                            placeholder="For eg.  20"
                            className="formInput w-75"
                          />
                        )}
                      </div> */}
                    {/* <div className=""> */}
                    {isLoading ? (
                      <LoadingShape className="loaderForLabel" />
                    ) : (
                      <label className="assessmentTestDetailsLabel mb-1">
                        Negative marking for MCQ
                      </label>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForInput" />
                    ) : (
                      <input
                        placeholder="For eg.  -2"
                        name="negativeMarks"
                        className="formInput w-25 mb-5"
                        value={formData?.negativeMarks}
                        onChange={onFormDataChange}
                      />
                    )}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="w-100 assessmentSettingGeneralDivider"></div>
                <div className="assessmentSettngSaveBtn offset-md-3 mt-5">
                  {isLoading ? (
                    <LoadingShape className="loaderForButton" />
                  ) : (
                    <button
                      className="secondaryOutlineBtn me-4"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForButton" />
                  ) : (
                    <button className="primaryBtnActive" onClick={SaveChanges}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {params.settingtype === "candidate-details" ? (
              <div className="w-100 assessmentCandidateFormForScroll">
                <div className="col-6 offset-md-3">
                  <div className="testDetails">
                    {isLoading ? (
                      <LoadingShape className="loaderForLabel" />
                    ) : (
                      <label className="assessmentTestDetailsLabel mb-2">
                        Candidate Details
                      </label>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForDescription" />
                    ) : (
                      <p className="assessmentSettingScoreDescription mb-3">
                        You may wish to collect some details from your
                        candidates before they begin their test. Select from the
                        list below or add a field of your own. Note these
                        settings will show up in the candidate's report.
                      </p>
                    )}
                    <div className="d-flex assessmentSettingScoreDescription align-items-center mb-5">
                      <h6 className="assessmentTestDetailsLabel me-3">Note:</h6>
                      <input
                        className="assessmentSettingCandidateDetails me-2"
                        type="checkbox"
                        checked
                        onChange={() => { }}
                      />
                      Included in the form
                      <Form className="ms-5 pb-1">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked
                          onChange={() => { }}
                        />
                      </Form>
                      Mandatory field
                    </div>
                    <div className="row">
                      {candidateDetailsList.map((x, id) => {
                        return (
                          <div
                            className="align-items-center assessmentCandidateDetails d-flex mb-2 col-6"
                            key={id}
                          >
                            <input
                              className="assessmentSettingCandidateDetails me-3"
                              type="checkbox"
                              onChange={() => onCandidateslistchange(x.value)}
                              checked={formData?.fieldsForCandidate.includes(
                                x.value
                              )}
                            />

                            <span className="assessmentSettingScoreDescription me-3  col-6">
                              {x.label}
                            </span>

                            <Form className="ms-2">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() =>
                                  onmandatoryFieldschange(x.value)
                                }
                                checked={formData?.mandataryFields.includes(
                                  x.value
                                )}
                              />
                            </Form>
                            {fieldsErr !== "" && (
                              <p className="errMessage">{fieldsErr}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {showCustomFields ? (
                      <div className="mb-5">
                        {isLoading ? (
                          <LoadingShape className="loaderForLabel" />
                        ) : (
                          <label className="assessmentTestDetailsLabel mb-2 mt-4">
                            Field Name
                          </label>
                        )}
                        <div className="assessmentAddCustomFields d-flex">
                          <input
                            className="col-1 formInput w-75"
                            value={customfieldname}
                            onChange={(e) => setCustomfieldname(e.target.value)}
                          />
                          <button
                            className="primaryBtnActive ms-3 me-2"
                            onClick={addCustomField}
                          >
                            Add
                          </button>
                          <button
                            className="secondaryOutlineBtn"
                            onClick={() => setShowCustomFields(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="assessmentSettngAddBtn mt-4 mb-5 ms-3">
                        {isLoading ? (
                          <LoadingShape className="loaderForButton" />
                        ) : (
                          <button
                            className="secondaryOutlineBtn"
                            onClick={() => setShowCustomFields(true)}
                          >
                            <img src={addButton} alt="add" className="me-4" />
                            Add Custom Fields
                          </button>
                          // <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 assessmentSettingGeneralDivider"></div>
                <div className="offset-md-3 mt-4">
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <label className="assessmentTestDetailsLabel mb-3">
                      Test Instruction
                    </label>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <p className="assessmentSettingScoreDescription mb-4">
                      These instructions will be displayed on the test's login
                      page.
                    </p>
                  )}

                  <div className="col-8 assessmentSettingEditor">
                    {isLoading ? (
                      <LoadingShape className="loaderForDescription col-5" />
                    ) : (
                      <CKEditor
                        editor={Editor}
                        className=""
                        data={formData?.testInstructions}
                        config={{
                          toolbar: [
                            "Heading",
                            "Bold",
                            "Italic",
                            "Link",
                            "bulletedList",
                            "numberedList",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormData((prev) => ({
                            ...prev,
                            testInstructions: data,
                          }));
                          setInstructionsErr("");
                        }}
                      />
                    )}
                    {instructionsErr !== "" && (
                      <p className="errMessage">{instructionsErr}</p>
                    )}
                  </div>
                </div>
                <div className="offset-md-3 mt-4 col-6">
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <label className="assessmentTestDetailsLabel mb-2">
                      Disclaimer
                    </label>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForDescription" />
                  ) : (
                    <p className="assessmentSettingScoreDescription">
                      When enabled, this declaration will appear on the test
                      login page along with a checkbox. Candidates will not be
                      able to commence this test unless they check the box,
                      expressing assent.
                    </p>
                  )}
                  {isLoading ? (
                    <LoadingShape className="loaderForInput" />
                  ) : (
                    <textarea
                      type="text"
                      name="testDisclaimer"
                      className="enterpriseAssessmentSettingCandidateDataTextArea mt-4"
                      placeholder="Start typing here..."
                      value={formData?.testDisclaimer}
                      onChange={(e) => (
                        onFormDataChange(e), setDisclaimerErr("")
                      )}
                    />
                  )}
                  {disclaimerErr !== "" && (
                    <p className="errMessage">{disclaimerErr}</p>
                  )}
                </div>

                <div className="align-items-center assessmentSettngSaveBtn col-6 d-flex justify-content-between mb-5 mt-5 offset-md-3">
                  <div>
                    {isLoading ? (
                      <LoadingShape className="loaderForButton" />
                    ) : (
                      <button
                        className="secondaryOutlineBtn me-4"
                        onClick={cancel}
                      >
                        Cancel
                      </button>
                    )}
                    {isLoading ? (
                      <LoadingShape className="loaderForButton" />
                    ) : (
                      <button
                        className="primaryBtnActive"
                        onClick={SaveChanges}
                      >
                        Save
                      </button>
                    )}
                  </div>
                  {isLoading ? (
                    <LoadingShape className="loaderForLabel" />
                  ) : (
                    <div className="candidateViewForm" onClick={showPreview}>
                      Preview Form
                      <img className="ms-2" src={candidateFormview} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <AssessmentPreviewModal
            showModal={showModal}
            handleModalClose={handleModalClose}
            formData={formData}
            AllSectionsSummary={AllSectionsSummary}
            SaveChanges={SaveChanges}
          />
          <div className="settingsToast">
            {toastMessage.show && (
              <ToastMessages
                toastMessage={toastMessage}
                setToastMessage={setToastMessage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
