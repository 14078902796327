import React, { useEffect, useRef, useState } from "react";
import dropDownIcon from "../../../../assets/Icons/Assessment/dropdownArrow.svg";

function Dropdown({
  dropDownOptions,
  ondropdownItemClick,
  selectedValue,
  dropDownClass,
  value,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [open, setOpen] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div
      className={`customDropdown d-flex justify-content-between align-items-center ${dropDownClass} `}
      onClick={() => setOpen(!open)}
    >
      {selectedValue}
      <img src={dropDownIcon} className="" />
      {open && (
        <div className="statusList" ref={wrapperRef}>
          {dropDownOptions &&
            dropDownOptions.map((item, index) => (
              <div
                key={`${value}${index}`}
                className="dropdownItem"
                onClick={() => ondropdownItemClick(item)}
              >
                {item[value]}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
