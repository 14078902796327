import React, { useEffect, useState } from "react";
import moment from "moment";
import interviewTimerIcon from "../../../assets/Icons/Interview/New/interviewTimer.svg";

export const Timer = (props) => {
  const [counter, setCounter] = useState(60);
  const startTime = moment(new Date());
  const endTime = moment(props.EndTimer);
  const duration = moment.duration(endTime.diff(startTime));

  const interviewTime = {
    hours: parseInt(duration.asHours()),
    mins: parseInt(duration.asMinutes()) % 60,
    secs: parseInt(duration.asSeconds()) % 60,
  };
  if (moment().isAfter(props.EndTimer)) {
    props.setExpiryModal(true);
  }

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  }, [counter]);
  return (
    <>
      <div className="d-flex interviewTimer">
        <img
          src={interviewTimerIcon}
          className="me-2 img-fluid"
          alt="timer"
          loading="lazy"
        />
        {moment().isAfter(endTime) ? (
          <>
            {/* {props.setExpiryModal(true)} */}
            <span>00:00:00</span>
          </>
        ) : (
          (interviewTime.hours.toString().length === 1
            ? "0" + interviewTime.hours
            : interviewTime.hours) +
          ":" +
          (interviewTime.mins.toString().length === 1
            ? "0" + interviewTime.mins
            : interviewTime.mins) +
          ":" +
          (interviewTime.secs.toString().length === 1
            ? "0" + interviewTime.secs
            : interviewTime.secs)
        )}
      </div>
    </>
  );
};
export default Timer;
