import React, { useEffect, useState } from "react";

import "./MockQuestion.css";

export default function MockQuestion(props) {
  return (
    <div
      className={`${props.mockType === "interviewAssess" ? "assessQuestion" : "mockQuestion"
        }`}
    >
      <p
        dangerouslySetInnerHTML={{
          __html:
            props?.question?.description?.length >
            props?.question?.title?.length
              ? props?.question?.description
              : props?.question?.title,
        }}
        className={`${props.mockType === "interviewAssess" ? "questionHeading" : ""
          }`}
      ></p>
      <span
        className={`${props.mockType === "interviewAssess"
            ? "assessAnswerList"
            : "mockAnswerList"
          }`}
      >
        {props.question.options &&
          props.question.options.map((option, index) => (
            <p
              key={option._id}
              style={
                option.is_correct
                  ? { fontWeight: "600" }
                  : { fontWeight: "400" }
              }
            >
              {String.fromCharCode(index + 65).toLowerCase()})
              <span className="ms-3">{option.option}</span>
            </p>
          ))}
      </span>
    </div>
  );
}
