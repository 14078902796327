import dayjs from "dayjs";
import React, { useState } from "react";

const ContributionHeatMap = (props) => {
  const {
    weekNames,
    monthNames,
    panelColors,
    values,
    until,
    dateFormat,
    weekLabelAttributes,
    monthLabelAttributes,
    panelAttributes,
  } = props;
  const [columns, setColumns] = useState(53);
  const [maxWidth, setmaxWidth] = useState(53);
  const monthLabelHeight = 15;
  const weekLabelWidth = 15;
  const panelSize = 9;
  const panelMargin = 3;

  const getPanelPosition = (row, col) => {
    const bounds = panelSize + panelMargin;
    return {
      x: weekLabelWidth + bounds * row,
      y: monthLabelHeight + bounds * col,
    };
  };

  const makeCalendarData = (history, lastDay, columns) => {
    const d = dayjs(lastDay, { format: dateFormat });
    const lastWeekend = d?.endOf("week");
    const endDate = d?.endOf("day");

    var result = [];
    for (var i = 0; i < columns; i++) {
      result[i] = [];
      for (var j = 0; j < 7; j++) {
        var date = lastWeekend.subtract((columns - i - 1) * 7 + (6 - j), "day");
        if (date <= endDate) {
          result[i][j] = {
            value: history[date.format(dateFormat)] || 0,
            month: date.month(),
          };
        } else {
          result[i][j] = null;
        }
      }
    }

    return result;
  };
  if (
    panelColors == undefined ||
    weekNames == undefined ||
    monthNames == undefined
  ) {
    return;
  }

  var contributions = makeCalendarData(values, until, columns);
  var innerDom = [];

  // panels
  for (var i = 0; i < columns; i++) {
    for (var j = 0; j < 7; j++) {
      var contribution = contributions[i][j];
      if (contribution === null) continue;
      const pos = getPanelPosition(i, j);
      const numOfColors = panelColors.length;
      const color =
        contribution.value >= numOfColors
          ? contribution.value <= 5
            ? panelColors[1]
            : contribution.value <= 10 && contribution.value > 5
            ? panelColors[2]
            : contribution.value <= 15 && contribution.value > 10
            ? panelColors[3]
            : panelColors[4]
          : panelColors[contribution.value];
      const dom = (
        <rect
          key={"panel_key_" + i + "_" + j}
          x={pos.x}
          y={pos.y}
          width={panelSize}
          height={panelSize}
          fill={color}
          {...panelAttributes}
        />
      );
      innerDom.push(dom);
    }
  }

  // week texts
  for (var i = 0; i < weekNames.length; i++) {
    const textBasePos = getPanelPosition(0, i);
    const dom = (
      <text
        key={"week_key_" + i}
        style={{
          fontSize: 10,
          alignmentBaseline: "central",
          fill: "#000000",
          fontWeight: 500,
          fontFamily: "Inter",
        }}
        x={textBasePos.x - panelSize / 2 - 4}
        y={textBasePos.y + panelSize / 2}
        textAnchor={"middle"}
        {...weekLabelAttributes}
      >
        {weekNames[i]}
      </text>
    );
    innerDom.push(dom);
  }

  // month texts
  var prevMonth = -1;
  for (var i = 0; i < columns; i++) {
    const c = contributions[i][0];
    if (c === null) continue;
    if (columns > 1 && i == 0 && c.month != contributions[i + 1][0]?.month) {
      // skip first month name to avoid text overlap
      continue;
    }
    if (c.month != prevMonth) {
      var textBasePos = getPanelPosition(i, 0);
      innerDom.push(
        <text
          key={"month_key_" + i}
          style={{
            fontSize: 10,
            alignmentBaseline: "central",
            fill: "#000000",
            fontWeight: 500,
            textTransform: "capitalize",
            fontFamily: "Inter",
          }}
          x={textBasePos.x + panelSize / 2}
          y={textBasePos.y - panelSize / 2 - 2}
          textAnchor={"middle"}
          {...monthLabelAttributes}
        >
          {monthNames[c.month]}
        </text>
      );
    }
    prevMonth = c.month;
  }
  return (
    <>
      <svg
        style={{
          fontFamily:
            "Helvetica, arial, nimbussansl, liberationsans, freesans, clean, sans-serif",
          width: "100%",
        }}
        height="120"
      >
        {innerDom}
      </svg>
      <div className="activityInfo mt-1">
        <span className="activityInfoText">Less</span>
        {panelColors.map((color) => (
          <svg className="inline-block" key={color}>
            <rect width="12" height="6" rx="2" ry="2" fill={color}></rect>
          </svg>
        ))}
        <span className="activityInfoText">More</span>
      </div>
    </>
  );
};
export default ContributionHeatMap;
