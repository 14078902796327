import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeModal from "../../../../assets/Icons/MyHackathons/closeModal.svg";
import FromLibrary from "../../../../assets/Icons/Assessment/FromLibrary.svg";
import AddQuestionDownArrow from "../../../../assets/Icons/Assessment/AddQuestionDownArrow.svg";
import OwnQuestion from "../../../../assets/Icons/Assessment/OwnQuestion.svg";
import CodingLogo from "../../../../assets/Icons/Assessment/CodingLogo.svg";
import MultipuleChoice from "../../../../assets/Icons/Assessment/MultipuleChoice.svg";
import { useHistory } from "react-router-dom";

export default function AddQuestions(props) {
  const [showDropDownFromMH, setDropDownFromMH] = useState(false);
  const [showDropDownFromOwn, setShowDropDownFromOwn] = useState(false);
  const history = useHistory();
  const addSection = (questionType, questionFrom) => {
    props.addSection(questionType, questionFrom);
  };
  const addOwnQuestion = () => {
    switch (props.originPage) {
      case "question-list":
        addSection(
          props.questionType === "coding" ? "coding" : "mcq",
          "create-custom-ques"
        );
        break;
      case "question-summary":
        setShowDropDownFromOwn(!showDropDownFromOwn);
        setDropDownFromMH(false);
        break;
    }
  };
  const addfromMHLibQuestion = () => {
    switch (props.originPage) {
      case "question-list":
        addSection(
          props.questionType === "coding" ? "coding" : "mcq",
          "mh-library"
        );
        break;
      case "question-summary":
        setShowDropDownFromOwn(false);
        setDropDownFromMH(!showDropDownFromMH);
        break;
    }
  };
  return (
    <Modal
      centered={true}
      show={props.show}
      onHide={() => { }}
      size="sm"
      className="AddQuestionModal"
    >
      <Modal.Header>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <span className="cardHeading ms-2">Add Questions</span>
          <img
            onClick={props.onClose}
            className="cursor-pointer"
            src={closeModal}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center position-relative flex-wrap">
          <div className="addQuestionFrom w-50" onClick={addfromMHLibQuestion}>
            <img
              src={FromLibrary}
              className="img-fluid me-4"
              alt="libraryLogo"
            />
            <span className="me-3">Add from library</span>
            {props.originPage !== "question-list" && <img
              src={AddQuestionDownArrow}
              className="img-fluid"
              alt="DownLogo"
            />}
          </div>
          <div className="addQuestionFrom w-50" onClick={addOwnQuestion}>
            <img
              src={OwnQuestion}
              className="img-fluid me-4"
              alt="libraryLogo"
            />
            <span className="me-3">Add own questions</span>
            {props.originPage !== "question-list" && <img
              src={AddQuestionDownArrow}
              className="img-fluid"
              alt="DownLogo"
            />}
          </div>
          {showDropDownFromMH && (
            <div className="addQuestionFromMHDropdown position-absolute">
              {/* {(props.originPage === "question-list"
                ? props.questionType === "coding"
                : true) && ( */}
              <div
                onClick={() => addSection("coding", "mh-library")}
                className="d-flex align-items-center"
              >
                <img
                  src={CodingLogo}
                  className="img-fluid me-3"
                  alt="CodingLogo"
                />
                <div>Coding</div>
              </div>
              {/* )} */}
              {/* {(props.originPage === "question-list"
                ? props.questionType === "mcq"
                : true) && ( */}
              <div
                onClick={() => addSection("mcq", "mh-library")}
                className="d-flex align-items-center mb-3"
              >
                <img
                  src={MultipuleChoice}
                  className="img-fluid me-3"
                  alt="MultipuleChoiceLogo"
                />
                <div>Multiple Choice</div>
              </div>
              {/* )} */}
            </div>
          )}
          {showDropDownFromOwn && (
            <div className="addQuestionFromOwnDropdown position-absolute">
              {/* {(props.originPage === "question-list"
                ? props.questionType === "coding"
                : true) && ( */}
              <div
                onClick={() => addSection("coding", "create-custom-ques")}
                className="d-flex align-items-center"
              >
                <img
                  src={CodingLogo}
                  className="img-fluid me-3"
                  alt="CodingLogo"
                />
                <div>Coding</div>
              </div>
              {/* )} */}
              {/* {(props.originPage === "question-list"
                ? props.questionType === "mcq"
                : true) && ( */}
              <div
                onClick={() => addSection("mcq", "create-custom-ques")}
                className="d-flex align-items-center mb-3"
              >
                <img
                  src={MultipuleChoice}
                  className="img-fluid me-3"
                  alt="MultipuleChoiceLogo"
                />
                <div>Multiple Choice</div>
              </div>
              {/* )} */}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
