import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";
import "rc-time-picker/assets/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Routes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "App";
const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
