import React, { useCallback, useRef } from "react";
import { Line } from "./Line";
import { UserToken } from "./UserToken";
import axios from "axios";
import { BootcampBackend } from "backend";
import * as html2canvas from "html2canvas";
import { useUsers } from "../hooks/useUsers";
import { useLines } from "../hooks/useLines";
import imageCompression from "browser-image-compression";
import { useUser } from "../hooks/useUser";
import { useKeyboardEvents } from "../hooks/useKeyboardEvents";

function getYOffset() {
  return (1 - 0) / 80;
}
const offset = getYOffset();

function getPoint(x, y) {
  return [x, y];
}

const InterviewWhiteboard = ({ tabs, interviewToken, setS3url }) => {
  const drawRef = useRef(null);
  const {
    user: self,
    updateUserPoint,
    activateUser,
    deactivateUser,
  } = useUser();
  const { users } = useUsers();
  const {
    lines,
    isSynced,
    startLine,
    addPointToLine,
    completeLine,
    clearAllLines,
    undoLine,
    redoLine,
  } = useLines();
  useKeyboardEvents();

  function takeshot() {
    let div = document.getElementById("photo");
    html2canvas(div).then(function (canvas) {
      let image;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };
      canvas.toBlob(async (file) => {
        file.name = "imageName";
        image = file;
        const compressedImage = await imageCompression(image, options);
        const objectUrl = URL.createObjectURL(compressedImage);
        const formData = new FormData();
        formData.append("files", compressedImage);
        const res = await axios
          .post(
            `${BootcampBackend}/enterpriseMediaUpload/mediaUploadInterview`,
            formData,
            {
              headers: {
                Authorization: interviewToken,
              },
            }
          )
          .then((res) => {
            setS3url(res.data.result.data[0].s3_url);
          })
          .catch((err) => console.log(err));
      }, "image/jpeg");
    });
  }

  // On pointer down, start a new current line
  const handlePointerDown = useCallback(
    (e) => {
      var rect = drawRef.current.getBoundingClientRect();
      e.currentTarget.setPointerCapture(e.pointerId);
      startLine(getPoint(e.clientX - rect?.left, e.clientY - rect?.top));
    },
    [startLine]
  );

  // On pointer move, update awareness and (if down) update the current line
  const handlePointerMove = useCallback(
    (e) => {
      var rect = drawRef.current.getBoundingClientRect();
      const point = getPoint(e.clientX - rect?.left, e.clientY - rect?.top);

      updateUserPoint(point);

      if (e.currentTarget.hasPointerCapture(e.pointerId)) {
        addPointToLine(point);
      }
    },
    [addPointToLine, updateUserPoint]
  );

  // On pointer up, complete the current line
  const handlePointerUp = useCallback(
    (e) => {
      e.currentTarget.releasePointerCapture(e.pointerId);

      completeLine();
      takeshot();
    },
    [completeLine]
  );
  return (
    <div
      className=""
      id="photo"
      style={{
        height: `${tabs?.name === "Whiteboard" ? "calc(100vh - 147px)" : ""}`,
        display: `${tabs?.name === "Whiteboard" ? "block" : "none"}`,
      }}
    >
      <div className="canvas-container" ref={drawRef}>
        <svg
          className="canvas-layer"
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onPointerEnter={activateUser}
          onPointerLeave={deactivateUser}
          style={{ touchAction: "none" }}
        >
          <g transform={`translate(0, -${offset})`}>
            {lines.map((line, i) => (
              <React.Fragment key={i}>
                <Line line={line} />
              </React.Fragment>
            ))}
          </g>
          {users?.slice(0, 2)?.map((user, i) => (
            <React.Fragment key={i}>
              <UserToken
                key={user.id}
                user={user}
                index={i}
                isSelf={user?.id === self?.id}
              />
            </React.Fragment>
          ))}
        </svg>
        <div className="canvas-controls">
          <button onClick={redoLine}>Redo</button>
          <button onClick={undoLine}>Undo</button>
          <button onClick={clearAllLines}>Clear</button>
        </div>
      </div>
    </div>
  );
};

export default InterviewWhiteboard;
