import React from "react";
import "./assessment.css";
import { useHistory } from "react-router-dom";
import NoInterviewImage from "../../../../assets/Icons/Dashboard/NoInterview.svg";
import AdditionIcon from "../../../../assets/Icons/Dashboard/AdditionIcon.png";

function NoSchedule({ tableData, isLoading }) {
  const history = useHistory();

  return (
    <>
      <div className="col-12 col-lg-12">
        <div className="dashboardCard assessment">
          <div className="d-flex assessmentData justify-content-between align-items-center">
            <h5 className="dashboardHeading">
              Today's Schedule ({tableData.length})
            </h5>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center dashboardNoSchedule">
            <img
              className="image-fluid dashboardNoAssessmentImage mt-2"
              src={NoInterviewImage}
              alt="hackthon"
            />
            <p className="dashboardNothingFoundText mt-3">
              No interview scheduled, start interviewing now.
            </p>
            <div
              className="dashboardNoAssessmentButton mt-3 mb-5 d-flex align-items-center justify-content-center"
              onClick={() => {
                history.push("/interviews");
              }}
            >
              <p>Start Interview</p>
              <img
                className="image-fluid ms-2 dashboardNoAssessmentAdditionIcon"
                src={AdditionIcon}
                alt="hackthon"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoSchedule;
