import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Delete from "../../../assets/Icons/Assessment/deleteQuestion.svg";
import "./createCustomQuestions.css";
import Select from "react-select";
import Dropdown from "./components/dropDown";
import TagsAutoSearch from "./components/TagsAutoSearch";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { BootcampBackend } from "backend";
import { useSidebar } from "helper/SideBarContext";
export default function CreateCustomCoding(props) {
  const difficultyOptions = [
    {
      id: "1",
      label: "Beginner",
      value: "Beginner",
    },
    {
      id: "2",
      label: "Intermediate",
      value: "Intermediate",
    },
    {
      id: "3",
      label: "Advanced",
      value: "Advanced",
    },
  ];
  const [categories, setCategories] = useState([]);
  const [allTags, setAlltags] = useState([]);
  const [testCases, setTestCases] = useState([
    { input: "", expectedOutput: "" },
    { input: "", expectedOutput: "" },
  ]);
  const params = useParams();
  const location = useLocation();
  const isEdit = location.pathname.split("/")[3] === "update";
  const questionId = localStorage.getItem("questionId");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#3c3e4d",
      padding: 10,
      textCenter: "left",
      zIndex: 999,
      fontSize: "14px",
      backgroundColor: state.isFocused ? "#F2F3F4" : "#ffffff",
    }),
    control: (base, state) => ({
      ...base,
      marginTop: 10 + "px",
      height: 40 + "px",
      alignItems: "start",
      backgroundColor: "#ffffff",
      border: "solid 1px #E2E2E9",
      borderRadius: 20,
      paddingRight: 15 + "px",
      paddingLeft: 15 + "px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? null : null,
      },
    }),
    placeholder: (base, state) => ({
      color: "#B1B2B8",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      color: "#F44D5E",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex",
    }),
  };
  const [defaultTestCases, setDefaultTestCases] = useState({
    input: "",
    expectedOutput: "",
  });
  const { isSidebarCollapsed } = useSidebar();
  const [formData, setFormData] = useState({
    tags: [],
    difficulty: "Beginner",
    preCode: "",
    category: "",
    solutionCode: "",
    categoryId: "",
    questionTitle: "",
    description: "",
    solutionDescription: "",
  });
  const [error, setErrors] = useState({
    tags: "",
    difficulty: "",
    preCode: "",
    category: "",
    solutionCode: "",
    categoryId: "",
    questionTitle: "",
    description: "",
    solutionDescription: "",
  });
  const DeleteTestCase = (index) => {
    let testCaseCopy = [...testCases];
    testCaseCopy.splice(index, 1);
    setTestCases(testCaseCopy);
  };
  const handletagsData = (tags) => {
    setFormData({ ...formData, tags: tags });
  };
  const handleTestCase = (e, index) => {
    let copy = [...testCases];
    copy[index] = { ...copy[index], [e.target.name]: e.target.value };
    setTestCases(copy);
  };
  const getAllTags = async () => {
    await axios
      .get(`${BootcampBackend}/practices/getAllPracticeTagsUser`)
      .then((response) => {
        if (response.data.status.code === 200) {
          setAlltags(
            response.data.result.data.filter((x) => {
              x.label = x.title;
              x.value = x._id;
              return x;
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDefaultTestCasesFormChange = (event) => {
    setDefaultTestCases({
      ...defaultTestCases,
      [event.target.name]: event.target.value,
    });
  };
  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const getAllCategories = async () => {
    await axios
      .get(`${BootcampBackend}/practices/getAllPracticeCategoriesUser`)
      .then((response) => {
        if (response.data.status.code === 200) {
          setCategories(response.data.result.data);
          isEdit
            ? getSpecificCodingQuestion()
            : setFormData((prev) => ({
                ...prev,
                category: response.data.result.data[0].title,
                categoryId: response.data.result.data[0]._id,
              }));
        }
      })
      .catch((err) => console.log(err));
  };
  const validateForm = () => {
    let errorObject;
    for (const prop in formData) {
      if (formData[prop] == "" || formData[prop] == []) {
        errorObject = { ...errorObject, [prop]: "Field cannot be empty*" };
      } else {
        errorObject = { ...errorObject, [prop]: "" };
      }
    }
    if (defaultTestCases.expectedOutput == "" || defaultTestCases.input == "") {
      errorObject = {
        ...errorObject,
        defaultTestCases: "Test case fileds cannot be empty*",
      };
    }
    if (testCases[0].expectedOutput == "" || testCases[0].input == "") {
      errorObject = {
        ...errorObject,
        testCases: "Test case fileds cannot be empty*",
      };
    }
    setErrors(errorObject);
  };
  const createQuestion = async (data, tagsArray, reset) => {
    await axios
      .post(
        `${BootcampBackend}/enterpriseAssessment/createAssessmentPracticeQuestion/${params.sectionId}`,
        {
          ...data,
          testCases,
          defaultTestCases,
          tags: tagsArray,
          assessmentId: params.assessmentId,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        if (!reset && response) {
          localStorage.setItem("isEditQuestions", true);
          history.push({
            pathname: `/assessments/${params.assessmentId}/coding/questions/${params.sectionId}`,
            // state: {
            //   type: "edit",
            // },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateQuestion = async (data, tagsArray, reset) => {
    await axios
      .put(
        `${BootcampBackend}/enterpriseAssessment/updatePracticeQuestionEnterprise/${questionId}`,
        {
          ...data,
          testCases,
          defaultTestCases,
          tags: tagsArray,
          assessmentId: params.assessmentId,
          sectionId: params?.sectionId,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        if (!reset && response) {
          localStorage.setItem("isEditQuestions", true);
          history.push({
            pathname: `/assessments/${params.assessmentId}/coding/questions/${params.sectionId}`,
            // state: {
            //   type: "edit",
            // },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCreateQuestion = async (reset) => {
    validateForm();
    try {
      if (
        formData.title === "" ||
        formData.difficulty === "" ||
        formData.description === "" ||
        formData.preCode === "" ||
        formData.solutionCode === "" ||
        formData.categoryId === "" ||
        formData.tags.length === 0 ||
        testCases[0].expectedOutput === ""
      ) {
        return;
      }
      let { category, ...data } = formData;
      let assessmentId = params.assessmentId;
      let tagsArray = formData.tags.map((ele) => {
        return ele._id;
      });
      isEdit
        ? updateQuestion(data, tagsArray, reset)
        : createQuestion(data, tagsArray, reset);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSave = () => {
    handleCreateQuestion(true);
    setFormData({
      tags: [],
      difficulty: "Beginner",
      preCode: "",
      category: "",
      solutionCode: "",
      categoryId: "",
      questionTitle: "",
      description: "",
      solutionDescription: "",
    });
    setTestCases([
      { input: "", expectedOutput: "" },
      { input: "", expectedOutput: "" },
    ]);
    setDefaultTestCases({ input: "", expectedOutput: "" });
  };
  const history = useHistory();
  const getSpecificCodingQuestion = async () => {
    await axios
      .get(
        `${BootcampBackend}/enterpriseAssessment/getSpecificCodingQuestionEnterprise/${questionId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        const coding_question = response.data?.result?.data;
        coding_question.tags.filter((x) => {
          x.label = x.title;
          x.value = x._id;
          return x;
        });
        setFormData({
          questionTitle: coding_question?.questionTitle || "",
          categoryId: coding_question?.categoryId._id || "",
          tags: coding_question?.tags || [],
          description: coding_question?.description || "",
          preCode: coding_question?.preCode || "",
          solutionCode: coding_question?.solution?.code || "",
          solutionDescription: coding_question?.solution?.description || "",
          difficulty: coding_question?.difficulty || "",
          category: coding_question?.categoryId?.title,
        });
        setTestCases(coding_question?.testCases);
        setDefaultTestCases(coding_question?.defaultTestCases);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCategories();
    getAllTags();
  }, []);
  return (
    <>
      <Header heading="coding" />
      <Navbar/>
      <div
        className={`AssessmentCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="mcqNavigation ">
          <p>
            <span
              onClick={() => {
                history.push("/assessments");
              }}
              className="cursor-pointer"
            >
              Assessments
            </span>{" "}
            {">"}{" "}
            <span
              onClick={() => {
                history.push(
                  `/assessments/${params.assessmentId}/questions`
                );
              }}
              className="cursor-pointer"
            >
              {localStorage.getItem("assessment_title") &&
                localStorage.getItem("assessment_title")}
            </span>{" "}
            {">"} Coding
          </p>
        </div>
        <div className="col-lg-11 mx-auto customCode">
          <div className="codeFormInputHld">
            <label className="codeFormLabel">
              Question<span className="astrick">*</span>
            </label>
            <input
              placeholder="Maximum units on a Truck"
              className="w-100 formInput "
              name="questionTitle"
              onChange={(e) => handleFormData(e)}
              value={formData.questionTitle}
            />
            <p className="errorMsg ms-2">{error.questionTitle}</p>
          </div>
          <div className="d-flex mb-4">
            {/* <div>
              <label className="codeFormLabel">
                Score<span className="astrick">*</span>
              </label>
              <input type="number" name="score" placeholder="20" className="formInput "onChange={(e) => handleFormData(e)} />
            </div>
            <div>
              <label className="codeFormLabel">
                Time<span className="astrick">*</span>
              </label>
              <div className="d-flex  codeDuration">
                <input
                  className="formInput"
                  type="number"
                  placeholder="Duration"
                />
                <span className="durationCapsule">min</span>
              </div>
            </div> */}
            <div>
              <label className="codeFormLabel">
                Difficulty<span className="astrick">*</span>
              </label>
              <Dropdown
                style={{ width: "100%" }}
                dropDownOptions={difficultyOptions}
                ondropdownItemClick={(object) =>
                  setFormData({ ...formData, difficulty: object.value })
                }
                dropDownClass={`p-2`}
                selectedValue={formData.difficulty}
                value="value"
              />
            </div>
            <div className="ms-5">
              <label className="codeFormLabel">
                Category<span className="astrick">*</span>
              </label>
              <Dropdown
                dropDownOptions={categories}
                ondropdownItemClick={(object) =>
                  setFormData({
                    ...formData,
                    categoryId: object._id,
                    category: object.title,
                  })
                }
                dropDownClass={`p-2`}
                selectedValue={formData.category}
                value="title"
              />
            </div>
          </div>
          <div className="codeFormInputHld">
            <label className="codeFormLabel">
              Tags<span className="astrick">*</span>
            </label>
            <Select
              name="tags"
              value={formData.tags}
              styles={customStyles}
              onChange={(e) => handletagsData(e)}
              options={allTags}
              placeholder="Hybrid"
              isSearchable={true}
              isMulti
              required
            />
            <p className="errorMsg ms-2">{error.tags}</p>
          </div>
          <div className="codeFormInputHld">
            {" "}
            <label className="codeFormLabel mt-5">
              Problem Description<span className="astrick">*</span>
            </label>
            <CKEditor
              editor={Editor}
              data={formData.description}
              config={{
                toolbar: {
                  items: [
                    "paragraph",
                    "Bold",
                    "uploadImage",
                    "Italic",
                    "Link",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "code",
                    "mediaEmbed",
                    "codeBlock",
                    "htmlEmbed",
                    "insertTable",
                  ],
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormData((prev) => ({ ...prev, description: data }));
              }}
            />
            <p className="errorMsg">{error.description}</p>
          </div>

          <div className="codeFormInputHld">
            <label className="codeFormLabel mt-5">
              Pre code<span className="astrick">*</span>
            </label>
            <input
              className=" grayInputField formInput"
              type="text"
              placeholder="def evenExpectN(list1.K):"
              name="preCode"
              value={formData.preCode}
              onChange={(e) => handleFormData(e)}
            />
            <p className="errorMsg">{error.preCode}</p>
          </div>
          <div className="codeFormInputHld">
            {" "}
            <label className="codeFormLabel">
              Solution Code<span className="astrick">*</span>
            </label>
            <input
              className="grayInputField formInput"
              type="text"
              placeholder="def evenExpectN(list1.K)"
              name="solutionCode"
              value={formData.solutionCode}
              onChange={(e) => handleFormData(e)}
            />
            <p className="errorMsg">{error.solutionCode}</p>
          </div>
          <div className="testCaseHld">
            {" "}
            <label className="codeFormLabel">
              Default Test Cases<span className="astrick">*</span>
            </label>
            <div className="d-flex justify-content-between ">
              <div className="caseExample">
                <p className="secondaryLabel">Input:</p>
                <input
                  className="grayInputField formInput"
                  type="text"
                  name="input"
                  placeholder="def evenExpectN(list1.K):"
                  value={defaultTestCases.input}
                  onChange={(e) => handleDefaultTestCasesFormChange(e)}
                />
              </div>
              <div className="caseExample">
                <p className="secondaryLabel">Expected Output:</p>
                <input
                  className="grayInputField formInput"
                  type="text"
                  placeholder="def evenExpectN(list1.K):"
                  name="expectedOutput"
                  value={defaultTestCases.expectedOutput}
                  onChange={(e) => handleDefaultTestCasesFormChange(e)}
                />
              </div>
            </div>
            <p className="errorMsg">{error.defaultTestCases}</p>
          </div>

          <label className="codeFormLabel">
            Test Cases<span className="astrick">*</span>
          </label>
          {testCases.map((testCasesObject, index) => (
            <div className="testCaseHld" key={`testCase${index}`}>
              <div className="d-flex justify-content-between">
                <div className="caseExample">
                  <p className="secondaryLabel">Input:</p>
                  <input
                    className="grayInputField formInput"
                    type="text"
                    placeholder="def evenExpectN(list1.K):"
                    value={testCasesObject.input}
                    name="input"
                    onChange={(e) => handleTestCase(e, index)}
                  />
                </div>
                <div className="caseExample">
                  <p className="secondaryLabel">Expected Output:</p>
                  <div className="d-flex">
                    <input
                      className="grayInputField formInput"
                      type="text"
                      placeholder="def evenExpectN(list1.K):"
                      value={testCasesObject.expectedOutput}
                      name="expectedOutput"
                      onChange={(e) => handleTestCase(e, index)}
                    />
                    {index > 0 && (
                      <img
                        className="deleteTestCase align-self-baseline cursor-pointer  align ms-3"
                        src={Delete}
                        onClick={() => DeleteTestCase(index)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {index === 0 && (
                <p key={`testCase${index + 7}`} className="errorMsg">
                  {error.testCases}
                </p>
              )}
            </div>
          ))}
          <div
            className="addOptionsBtn"
            onClick={() =>
              setTestCases([...testCases, { input: "", expectedOutput: "" }])
            }
          >
            <button className="addTestCase">
              <span className="me-2">+</span>
              Add Test Case
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button
              className="primaryBtnActive"
              onClick={() => handleCreateQuestion(false)}
            >
              Save
            </button>
            {!isEdit && (
              <>
                <span className="mx-4 createCodeBtnOr">(or)</span>
                <button className="primaryBtnActive" onClick={handleSave}>
                  Save & Add Another
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
