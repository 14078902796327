import React, { useEffect, useRef, useState } from "react";
import viewIcon from "assets/Icons/Jobs/view.svg";
import shareIcon from "assets/Icons/Jobs/share.svg";
import rePostIcon from "assets/Icons/Jobs/repost.svg";
import editIcon from "assets/Icons/Jobs/editJobGrey.svg";
import linkIcon from "assets/Icons/Jobs/linkto.svg";
import draftIcon from "assets/Icons/Jobs/draft.svg";
import deleteIcon from "assets/Icons/Jobs/Delete.svg";
import { useHistory } from "react-router";
import { Machinehack_Url } from "backend";
import axios from "axios";
import { BootcampBackend } from "backend";
import "./dropdown.css";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";


const adminView = localStorage.getItem("adminView")
const dropdownList = [
  {
    id: "1",
    name: "Edit Job",
    nameref: "edit",
    active: false,
    icon: editIcon,
  },
  {
    id: "2",
    name: "Delete Job",
    nameref: "delete",
    active: false,
    icon: deleteIcon,
  },
  {
    id: "3",
    name: adminView ? "Unpublish" : "Move to Closed",
    nameref: "move-to-closed",
    active: false,
    icon: draftIcon,
  },
  {
    id: "4",
    name: "Repost",
    nameref: "repost",
    active: false,
    icon: rePostIcon,
  },
  {
    id: "5",
    name: "Share Job",
    nameref: "share",
    active: false,
    icon: shareIcon,
  },
  {
    id: "6",
    name: "View as Candidate",
    nameref: "view-as-candidate",
    active: false,
    icon: viewIcon,
  },
  {
    id: "7",
    name: "Copy link",
    nameref: "copy-link",
    active: false,
    icon: linkIcon,
  },
];
export default function Dropdown({
  show,
  showMenuitems,
  updateshow,
  jobId,
  updateJobList,
  publishJob,
  isjobList,
  classes,
  copyLinkfunc,
}) {
  const [dropdownMenuList, setDropdownMenuList] = useState(dropdownList);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          updateshow(isjobList ? "" : false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const menuClick = (e, value) => {
    e.stopPropagation();
    switch (value.nameref) {
      case "edit":
        history.push(`/jobs/editJob/${jobId}`);
        updateshow(isjobList ? "" : false);
        break;
      case "delete":
        // deleteJob(jobId);
        setShowPopup(true);
        break;
      case "move-to-closed":
        publishJob("unpublish");
        break;
      case "repost":
        publishJob("publish");
        break;
      case "share":
        window.open(
          `https://www.linkedin.com/shareArticle?url=${Machinehack_Url}/jobs/?currentJobId%3D${jobId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "view-as-candidate":
        window.open(
          `${Machinehack_Url}/jobs/?currentJobId=${jobId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "copy-link":
        isjobList ? copyLink() : copyLinkfunc();
        break;
    }
  };

  async function deleteJob() {
    try {
      await axios.delete(`${BootcampBackend}/jobs/deleteJob/${jobId}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      isjobList
        ? updateJobList((prev) =>
          prev.filter((x) => {
            if (x._id === jobId) {
              return x._id !== jobId;
            }
            return prev;
          })
        )
        : history.push("/jobs");
      updateshow(isjobList ? "" : false);
      setShowPopup(false);
    } catch (err) {
      console.log(err.response);
    }
  }

  async function publishJob(jobstatus) {
    try {
      const res = await axios.put(
        `${BootcampBackend}/jobs/unpublish/${jobId}?publishType=${jobstatus}`,
        {
          timeStamp: Date(),
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      isjobList
        ? updateJobList((prev) =>
          prev.filter((x) => {
            if (x._id === jobId) {
              return x._id !== jobId;
            }
            return prev;
          })
        )
        : updateJobList((prev) => ({
          ...prev,
          unpublish: jobstatus === "unpublish" ? true : false,
        }));
      updateshow(isjobList ? "" : false);
    } catch (err) {
      console.log(err.response);
    }
  }

  function copyLink() {
    navigator.clipboard.writeText(
      `${Machinehack_Url}/jobs/?currentJobId=${jobId}`
    );
    updateshow(isjobList ? "" : false);
  }

  useEffect(() => {
    setDropdownMenuList((prev) =>
      prev.filter((x) => {
        if (showMenuitems.includes(x.nameref)) {
          x.active = true;
        } else {
          x.active = false;
        }
        return prev;
      })
    );
  }, [showMenuitems]);
  return (
    <>
      {show && (
        <div
          className={`jobsDropDown ${classes ? classes : ""}`}
          ref={wrapperRef}
        >
          {dropdownMenuList.map((menuItem) => (
            <React.Fragment key={menuItem.id}>
              {menuItem.active && (
                <p className="mb-0" onClick={(e) => menuClick(e, menuItem)}>
                  <img
                    src={menuItem.icon}
                    className="img-fluid me-4"
                    loading="lazy"
                    alt="icon"
                    width="15"
                    height="15"
                  />
                  {menuItem.name}
                </p>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <ConfirmationModal
        ModalHeading="Delete Job"
        ModalBody="Once the job is deleted, cannot be retrieved"
        closeIcon={true}
        ActionBtnName="Delete"
        closeBtnName="Leave"
        ActionBtn={deleteJob}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
    </>
  );
}
