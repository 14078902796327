import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useRef, useState } from "react";
import ActiveBookmarkSvg from "assets/Icons/ResumeSearch/active-bookmark.svg";
import aiSearch from "assets/Icons/ResumeSearch/aiSearch.svg";
import SearchPage from "./SearchPage";
import searchSvg from "assets/Icons/Jobs/search.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import Candidateinfo from "./components/CandidateInfo/CandidateInfo";
import { isAuthenticated } from "helper/user";
import { Pagination } from "react-rainbow-components";
import Header from "components/Header/Header";
import SubscriptionPlanScreen from "../PricingPages/SubscriptionPlanScreen";
import ContactUs from "components/ContactUs/ContactUs";
import { useSelector } from "react-redux";
import NoData from "assets/Icons/ResumeSearch/no-data.svg";
import { LoadingShape } from "react-rainbow-components";
import "./ResumeSearch.css";
import Filters from "./components/Filters";
import UserListHld from "./components/UserListHld";
import { useHistory, useLocation } from "react-router-dom";
import PremiumIcon from "assets/Icons/Pricing/crown.svg";
import { useSidebar } from "helper/SideBarContext";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ResumeSearch() {
  const query = useQuery();
  const history = useHistory();
  const candidateHldRef = useRef(null);
  const { isSidebarCollapsed } = useSidebar();
  const [showResult, setShowResult] = useState(false);
  const [location, setLocation] = useState();
  const [skills, setSkills] = useState([]);
  const [showDropDown, setShowDropdown] = useState("");
  const [filters, setFilters] = useState(initialFilters);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [candidatesData, setCandidatesData] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [pagination, setPagination] = useState({ limit: 20, current_page: 1 });
  const [selectedUser, setSelectedUser] = useState({});
  const [skillsPagination, setSkillsPagination] = useState({
    limit: 18,
    current_page: 1,
  });
  const [currentLocationPage, setCurrentLocationPage] = useState(1);
  const [showBookmark, setShowBookmark] = useState(false);
  const [filterSearchText, setFilterSearchText] = useState({
    location: "",
    skills: "",
    experience: "",
    currentCtc: "",
  });
  const [totalSkills, setTotalSkills] = useState(0);
  const [showPlan, setShowPlan] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const subscriptionData = useSelector((state) => state.subscription);
  const [isLoading, setIsLoading] = useState(true);
  const userDataHld = useRef(null);
  const filterTypes = [
    {
      type: "dropdown",
      optionState: location,
      label: "Location",
      name: "location",
    },
    {
      type: "input",
      label: "Notice period",
      name: "noticePeriod",
    },
    {
      type: "dropdown",
      optionState: experienceOptions,
      label: "Experience",
      name: "experience",
    },
    {
      type: "dropdown",
      optionState: ctcOptions,
      label: "Current CTC",
      name: "currentCtc",
    },
    { type: "dropdown", optionState: skills, label: "Skills", name: "skills" },
    {
      type: "input",
      label: "Company",
      name: "company",
    },
    {
      type: "input",
      label: "Designation",
      name: "designation",
    },
    { type: "input", label: "University", name: "university" },
  ];
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleChangeFilter = (name, value, eventType) => {
    if (eventType === "dropdown") {
      setFilterSearchText({ ...filterSearchText, [name]: value });
    } else {
        setFilters({ ...filters, [name]: value });
        setPagination({ ...pagination, current_page: 1 });
        setFilterSearchText({ ...filterSearchText, [name]: value });
        if (name === "location") {
          setShowDropdown("");
        }
    }
  };
  const getAllCities = (page, type) => {
    const paramData = {
      current_page: page,
      searchString: filterSearchText.location,
    };
    axios
      .get(`${BootcampBackend}/locations/getAllCites`, {
        params: paramData,
      })
      .then((response) => {
        if (type === "scroll") {
          setLocation([...location, ...response.data.result.data]);
        } else {
          setLocation(response.data.result.data);
        }
        setCurrentLocationPage(page);
      })
      .catch((err) => {
        console.log(err);
        setLocation([]);
      });
  };
  const getAllSkills = (page, type) => {
    const paramData = {
      current_page: page,
      limit: skillsPagination.limit,
    };
    filterSearchText.skills?.length &&
      (paramData.searchString = filterSearchText.skills);
    axios
      .get(`${BootcampBackend}/skills/getAllSkillsEnterprise`, {
        params: paramData,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((response) => {
        type === "scroll"
          ? setSkills((prev) => [...prev, ...response.data.result.data])
          : setSkills(response.data.result.data);
        setSkillsPagination({ ...setSkillsPagination, current_page: page });
        setTotalSkills(response?.data?.result?.totalCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleScrollFilter = (e, filterName) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (filterName === "skills") {
      if (bottom && skills.length < totalSkills) {
        getAllSkills(skillsPagination.current_page + 1, "scroll");
      }
    }
    if (filterName === "location") {
      if (bottom) {
        getAllCities(currentLocationPage + 1, "scroll");
      }
    }
  };
  const handleSearch = async () => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const filter_data = { ...filters };
    if (filter_data.skills.length) {
      filter_data.skills = filter_data.skills.join(",");
    }
    if (filter_data.experience.length) {
      filter_data.experience = filter_data.experience.join(",");
    }
    if (filter_data.currentCtc.length) {
      filter_data.currentCtc = filter_data.currentCtc.join(",");
    }
    const nonEmptyFilters = Object.fromEntries(
      Object.entries(filter_data).filter(([key, value]) => value !== "")
    );
    await axios
      .get(`${BootcampBackend}/v1/candidates`, {
        params: { ...nonEmptyFilters, ...pagination },
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((response) => {
        if (!showResult) {
          setShowResult(true);
        }
        setCandidatesData(response?.data?.result?.data || []);
        setTotalCandidate(response?.data?.result?.totalCount);
        userDataHld?.current?.scrollIntoView({ behavior: "smooth" });
        setTimeout(()=>setIsLoading(false),1000);
        history.push(`?search=${filters?.search}${filters.location ? `&location=${filters.location}` : ''}${filters.noticePeriod ? `&notice=${filters.noticePeriod}` : ''}${filters.experience.length ? `&exp=${filters.experience.join(',')}` : ''}${filters.currentCtc.length ? `&ctc=${filters.currentCtc.join(',')}` : ''}${filters.skills.length ? `&skill=${filters.skills.join(',')}` : ''}${filters.company ? `&org=${filters.company}` : ''} `);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(()=>setIsLoading(false),1000);
      });
  };
  const onSearchTextChange = (value) => {
      setFilters({ ...filters, search: value });
      setPagination({ ...pagination, current_page: 1 });
  };
  const bookmarkResume = async (candidateInfo) => {
    try {
      const res = await axios.put(
        `${BootcampBackend}/v1/candidates/bookmark`,
        {
          mhUserId: candidateInfo?._id,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (showBookmark) {
        setCandidatesData((prev) =>
          prev.filter((x) => x._id !== candidateInfo._id)
        );
      } else {
        setCandidatesData((prev) =>
          prev.filter((x) => {
            if (x?._id === candidateInfo?._id) {
              x.isBookmarked = !candidateInfo.isBookmarked;
            }
            return prev;
          })
        );
      }
    } catch (error) {
      console.error("Error while bookmarking resume:", error);
    }
  };
  const fetchBookmarkedResume = async (isBookmarked) => {
    try {
      let token = localStorage.getItem("jwt");
      if (!token) {
        console.error("JWT token not found in localStorage");
        return;
      }
      token = JSON.parse(token).token;
      if (isBookmarked) {
        handleSearch();
        setShowBookmark(false);
      } else {
        const response = await axios.get(
          `${BootcampBackend}/v1/candidates/bookmark`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setShowBookmark(true);
        setPagination({ ...pagination, current_page: 1 });
        setCandidatesData(response.data.result.data);
        setTotalCandidate(response.data?.result?.totalCount);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setCandidatesData([]);
        setTotalCandidate(0);
      }
      setShowBookmark(!showBookmark);
      console.error("Error fetching bookmarked resume:", error);
    }
  };
  const handleViewResume = (url) => {
    window.open(url, "_blank");
  };
  const handleDownloadResume = (fileUrl) => {
    axios({
      method: "get",
      url: fileUrl,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "file.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
  };
  const handleScrollCandidateInfo = () => {
    candidateHldRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown("");
          // setFilterSearchText((prev) => ({
          //   ...prev,
          //   skills: "",
          // }));
          // setSkillsPagination({ ...setSkillsPagination, current_page: 1 });
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    if (subscriptionData?.isLoaded) {
      if (
        isAuthenticated()?.role !== "admin_panel" &&
        !subscriptionData?.subscriptionData?._id
      ) {
        setShowPlan(!showPlan);
        return;
      }
    }
  }, [subscriptionData?.isLoaded]);
  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }
  }, []);
  useEffect(() => {
    getAllCities(currentLocationPage);
  }, [filterSearchText?.location]);
  useEffect(() => {
    if (!filterSearchText.skills.includes("skills")) {
      getAllSkills(skillsPagination.current_page);
    }
  }, [filterSearchText?.skills]);
  useEffect(() => {
    if (showResult&&!showBookmark) {
      handleSearch();
    }
  }, [filters, pagination.current_page]);
  useEffect(() => {
    const search = query.get("search");
    const location = query.get("location");
    const noticePeriod = query.get("notice");
    const exp = query.get("exp");
    const ctc = query.get("ctc");
    const skill = query.get("skill")
    const org = query.get("org")
    if(skill){
      const skillArray = skill.split(",");
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        skills: skillArray
      }));
      setFilterSearchText(prev => ({
        ...prev,
        skills: `skills(${
          skillArray?.length
        })`
      }))
    }
    if(search){
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        search: search
      }))
    }
    if(location){
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        location: location
      }));
      setFilterSearchText(prev => ({
        ...prev,
        location: location
      }))
    }
    if(noticePeriod){
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        noticePeriod: noticePeriod
      }));
      setFilterSearchText(prev => ({
        ...prev,
        noticePeriod: noticePeriod
      }))
    }
    if(exp){
      const experience = exp.split(",");
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        experience: experience
      }));
      setFilterSearchText(prev => ({
        ...prev,
        experience: `experience(${
          experience?.length
        })`
      }))
    }
    if(ctc){
      const currentCtc = ctc.split(",");
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        currentCtc: currentCtc
      }));
      setFilterSearchText(prev => ({
        ...prev,
        currentCtc: `currentCtc(${
          currentCtc?.length
        })`
      }))
    }
    if(org){
      setShowResult(true);
      setFilters(prev => ({
        ...prev,
        company: org
      }));
      setFilterSearchText(prev => ({
        ...prev,
        company: org
      }))
    }
  },[])
  return (
    <>
      <div className="d-none">
        <Header />
      </div>
      <Navbar />
      <div
        className={`resumeCntr ${!showResult ? `searchBack` : ``} ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        {!showResult && subscriptionData?.subscriptionData?._id && (
          <SearchPage
            onSearchTextChange={onSearchTextChange}
            handleSearch={handleSearch}
          />
        )}
        {showResult && !subscriptionData?.subscriptionData?._id && (
          <div className="resumeSubscriptionCard px-4 pb-4">
          <span className="premiumTag"><img className="me-2" src={PremiumIcon} alt="PremiumIcon"/> Premium</span>
          <p className="boldText mt-3 mb-2">Seems, you’re on Starter Plan</p>
          <p className="thinText mb-4">Pls upgrade to at least basic plan to access this feature</p>
          <button className="upgradeNowBtn">Upgrade Now</button>
        </div>
        )}
        {showResult && (
          <>
            <div className="topSection px-5 pt-5">
              <div className={`graySearchHld`}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <img
                      className="aiSearch me-3"
                      src={aiSearch}
                      alt="aiSearch"
                    />
                    <input
                      className="resumeSearchInput"
                      name="searchText"
                      type="text"
                      value={filters?.search}
                      placeholder="Search with job role, skills"
                      onChange={(e) => onSearchTextChange(e.target.value)}
                    />
                  </div>
                  <img
                    className="searchSvg me-1"
                    src={searchSvg}
                    alt="aiSearch"
                  />
                </div>
              </div>
              <div className="resumeFilters d-flex flex-wrap mt-4 align-items-end">
                {filterTypes.slice(0, 5).map((filter, index) => (
                  <Filters
                    handleScrollFilter={handleScrollFilter}
                    setFilters={setFilters}
                    setFilterSearchText={setFilterSearchText}
                    filter={filter}
                    filters={filters}
                    filterSearchText={filterSearchText}
                    index={index}
                    handleChangeFilter={handleChangeFilter}
                    showDropDown={showDropDown}
                    setShowDropdown={setShowDropdown}
                    wrapperRef={wrapperRef}
                  />
                ))}

                <button
                  className={`grayBtn me-3 px-3 ${
                    showBookmark ? "bookmarked-btn" : ""
                  }`}
                  onClick={() => {
                    setSelectedUser({});
                    fetchBookmarkedResume(showBookmark);
                  }}
                >
                  <img
                    src={ActiveBookmarkSvg}
                    className={`me-2 filterbookmarkIcon ${
                      showBookmark ? "activeFilterBookmark" : ""
                    }`}
                  />
                  <span className="grayBtnText">Bookmarked Resumes</span>
                </button>
                <button
                  className="showMoreBtn grayBtn px-3 grayBtnText"
                  onClick={() => setShowMoreFilters(!showMoreFilters)}
                >
                  {showMoreFilters ? `Less` : `More`}
                </button>
              </div>
            </div>
            <div className="morefilterSection px-5 pb-3">
              {showMoreFilters && (
                <div className="resumeFilters d-flex flex-wrap mt-4 align-items-end">
                  {filterTypes.slice(5, 8).map((filter, index) => (
                    <div
                      key={`filter${index}`}
                      className="resumeFormGroup me-3"
                    >
                      <label className="grayBtnText">{filter.label}</label>
                      <input
                        type="text"
                        placeholder="Search Location"
                        className={`resumefilterInput ${
                          filters[filter.name] &&
                          filters[filter.name]?.length > 0
                            ? `activeInput`
                            : ``
                        }`}
                        name={filter.name}
                        value={filters[filter.name]}
                        onChange={(e) =>
                          handleChangeFilter(
                            e.target.name,
                            e.target.value,
                            filter.type
                          )
                        }
                      />
                      {filter.type === "dropdown" &&
                        showDropDown === filter.name && (
                          <div
                            key={`showDropDown${index}`}
                            ref={wrapperRef}
                            className="resumeDropdown"
                          >
                            {filter.optionState?.map((option, i) => (
                              <p
                                key={`optionState${i}`}
                                className="resumeDropdownOption"
                                onClick={() => {
                                  handleChangeFilter(
                                    filter.name,
                                    option.name,
                                    "input"
                                  );
                                }}
                              >
                                {filter.name === "location"
                                  ? `${option?.name},${option.state_name}`
                                  : option.name}
                              </p>
                            ))}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {isLoading ? (
              <div
                className={`d-flex justify-content-between flex-wrap mt-5 mx-5`}
              >
                {Array.from(
                  {
                    length: 10,
                  },
                  (_, i) => (
                    <div
                      className="d-flex userInfoCard mb-3 flex-row justify-content-between"
                      style={{
                        border: "none",
                        background: "#dfe0e470",
                        overflow: "hidden",
                      }}
                      key={i}
                    >
                      <div className="d-flex">
                        <LoadingShape
                          variant="image"
                          className="img-fluid"
                          style={{ padding: "5px 10px", width: "50px" }}
                        />
                        <div className="ms-3" style={{ padding: "5px 10px" }}>
                          {" "}
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "100%",
                            }}
                          />
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "50%",
                            }}
                          />
                          <LoadingShape
                            style={{
                              marginBottom: "14px",
                              height: "3px",
                              width: "120px",
                            }}
                          />
                        </div>
                      </div>
                      <div className=" col-3 d-flex align-self-end">
                        <LoadingShape
                          style={{
                            borderRadius: "50px",
                            height: "35px",
                            width: "35px",
                            minWidth: "30px !important",
                            marginRight: "5px",
                            animation: "spin 1s linear infinite",
                          }}
                        />
                        <LoadingShape
                          style={{
                            borderRadius: "50px",
                            height: "35px",
                            width: "35px",
                            minWidth: "30px !important",
                            marginRight: "5px",
                            animation: "spin 1s linear infinite reverse",
                          }}
                        />
                        <LoadingShape
                          style={{
                            borderRadius: "50px",
                            height: "35px",
                            width: "35px",
                            minWidth: "30px !important",
                            animation: "spin 1s linear infinite",
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : candidatesData.length ? (
              <>
                <div className={`${selectedUser?._id ? `` : ``} user-list-hld`}>
                  <div
                    className={`${
                      selectedUser?._id ? `user-list-hidden` : ``
                    } user-list-cntr`}
                  >
                    <div
                      className={`${
                        selectedUser?._id
                          ? `scrollSection user-list-hld w-50`
                          : ``
                      }`}
                    >
                      <p
                        className="grayBtnText px-5 pb-2 pt-3"
                        ref={userDataHld}
                      >
                        Showing{" "}
                        <span className="highlightedText">
                          {totalCandidate}
                        </span>{" "}
                        MH freaks{" "}
                      </p>
                      <div
                        className={`row mt-2 ${
                          selectedUser?._id ? "flex-column ms-5" : "mx-5"
                        }`}
                      >
                        {candidatesData &&
                          candidatesData.map((candidateInfo, index) => (
                            <UserListHld
                              handleDownloadResume={handleDownloadResume}
                              candidateInfo={candidateInfo}
                              bookmarkResume={bookmarkResume}
                              index={index}
                              selectedUser={selectedUser}
                              handleScrollCandidateInfo={
                                handleScrollCandidateInfo
                              }
                              setSelectedUser={setSelectedUser}
                              handleViewResume={handleViewResume}
                            />
                          ))}
                      </div>
                    </div>
                    {selectedUser?._id ? (
                      <Candidateinfo
                        userId={selectedUser?._id}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        candidateHldRef={candidateHldRef}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {totalCandidate > pagination.limit ? (
                    <div className="mx-4">
                      <Pagination
                        className="rainbow-m_auto resumeCardPagination"
                        pages={
                          totalCandidate > 0
                            ? parseInt(totalCandidate / pagination.limit) + 1
                            : 1
                        }
                        activePage={pagination.current_page}
                        onChange={(event, page) =>
                          setPagination({ ...pagination, current_page: page })
                        }
                        variant="shaded"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <NoDataSection />
            )}
          </>
        )}
      </div>
      <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        titleType="feature"
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type="pricing"
      />
    </>
  );
}
const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const experienceOptions = [
  { name: "0-1" },
  { name: "1-3" },
  { name: "4-6" },
  { name: "7-10" },
  { name: "11-15" },
  { name: "15-100000" },
];
const ctcOptions = [
  { name: "3-6" },
  { name: "6-10" },
  { name: "10-15" },
  { name: "15-20" },
  { name: "20-25" },
  { name: "25-30" },
  { name: "30-50" },
  { name: "50-1000000" },
];
const initialFilters = {
  search: "",
  location: "",
  skills: [],
  noticePeriod: "",
  experience: [],
  currentCtc: [],
  company: "",
  designation: "",
  university: "",
};

const NoDataSection = () => {
  return (
    <div className="BuildYourAssessment text-center">
      <img src={NoData} alt="create-assessment" className="img-fluid" />
      {/* <div className="BuildYourAssessmentText mt-4">No Data found</div> */}
    </div>
  );
};
