import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { customStyles } from "./CreatePlanPopup";
import Select from "react-select";
import arrowWhite from "../../../../assets/Icons/Assessment/arrowWhite.svg";
import PremiumIcon from "../../../../assets/Icons/Pricing/crown.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import { SubscriptionPlanService } from "services/subscription.service";
import { isAuthenticated } from "helper/user";

const AddSubscriptionPopup = (props) => {
  const {
    showAddSubscriptionPopup,
    setShowAddSubscriptionPopup,
    selectedCompany,
    companySubscriptionData,
    setCompanySubscriptionData,
  } = props;
  const plans = useSelector((state) => state.subscription.plans);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const addfreeSubscription = async () => {
    try {
      const requestData = {
        companyId: selectedCompany?._id,
        razorpayPlanId: selectedPlan?.id,
      };
      const add_free_subscription_res =
        await SubscriptionPlanService.addFreeSubscription(requestData);
      if (add_free_subscription_res?.data) {
        setShowAddSubscriptionPopup(false);
        const subscriptionData = JSON.parse(
          JSON.stringify(add_free_subscription_res.data)
        );
        subscriptionData.mh_subscription_plan_id = subscriptionData?.planData;
        setCompanySubscriptionData(subscriptionData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (plans.length > 0) {
      const subscription_plans = JSON.parse(JSON.stringify(plans));
      const publishedPlans = subscription_plans.filter((x) => {
        if (x.publish) {
          x.label = x.name + " - " + x.period + " - " + x.currencyType;
          x.value = x.id;
          if (x.id === companySubscriptionData?.razorpay_plan_id) {
            return x.id !== companySubscriptionData?.razorpay_plan_id;
          } else {
            return x;
          }
        }
      });
      setSubscriptionPlans(publishedPlans);
    }
  }, [plans?.length, companySubscriptionData?._id]);
  return (
    <Modal
      centered="true"
      dialogClassName="user-plan-popup"
      className="add-subscription-popup user-current-plan"
      show={showAddSubscriptionPopup}
      onHide={() => setShowAddSubscriptionPopup(!showAddSubscriptionPopup)}
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title className="modalHeading mb-0">
          Add Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="">
          <div className="plan-form-group">
            <label>Select Plan</label>
            <Select
              name="currency"
              styles={customStyles}
              onChange={(event) => setSelectedPlan(event)}
              options={subscriptionPlans}
              value={selectedPlan}
              placeholder="Select"
            />
          </div>
          {selectedPlan?._id ? (
            <div className="d-flex align-items-center selected-premium-hld">
              {selectedPlan?.name === "professional" ? (
                <img
                  src={PremiumIcon}
                  alt="premium"
                  className="img-fluid me-2"
                />
              ) : (
                <></>
              )}
              <div>
                <p className="text-capitalize selected-plan-name">
                  {selectedPlan?.name} - {selectedPlan.period}
                </p>
                <p className="selected-plan-time-period">
                  {moment().format("MMM DD, YYYY")} -{" "}
                  {moment(
                    moment()
                      .add(
                        1,
                        selectedPlan.period === "monthly" ? "months" : "years"
                      )
                      .calendar()
                  ).format("MMM DD, YYYY")}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {selectedPlan?._id ? (
            <button
              className="primaryBtnActive mx-auto d-block mt-5 mb-5"
              onClick={addfreeSubscription}
            >
              Submit
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          ) : (
            <button
              className="primaryBtnActive mx-auto d-block mt-5 mb-5 disabledprimarybtn"
              disabled
            >
              Submit
              <img
                className="ms-3 assessmentSelectRoleModalButtonImg"
                src={arrowWhite}
              />
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSubscriptionPopup;
