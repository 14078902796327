import axios from "axios";
import React, { useEffect, useState } from "react";
import { BootcampBackend } from "../../../backend";
import CompletedInterviews from "./CompletedInterviews";
import "./Interview.css";
import UpComingInterviews from "./UpComingInterviews";

function Interview() {
  const [currentNavigation, setCurrentNavigation] = useState("Upcoming");
  const [Interviews, setInterviews] = useState();

  async function fetchInterviewsPending() {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/getAllInterviewsAdminPanel/pending?current_page=1`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setInterviews(res.data.result);
        });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchInterviewsPending();
  }, []);

  return (
    <div>
      <div>
        <div className="d-flex py-3 row align-items-center interviewsBorder g-0">
          <p className="interviewTabTotalInterviews col-5 ps-4">
            Total Interviews (
            {Interviews?.totalInterview ? Interviews?.totalInterview : "0"})
          </p>

          <div className="button-items center">
            <ul className="mb-0">
              <li
                className={currentNavigation == "Upcoming" ? "active" : ""}
                onClick={() => {
                  setCurrentNavigation("Upcoming");
                }}
              >
                <a id="upcoming">
                  Upcoming <span>{Interviews?.totalPendingInterviews}</span>
                </a>
              </li>
              <li
                className={currentNavigation == "Completed" ? "active" : ""}
                onClick={() => {
                  setCurrentNavigation("Completed");
                }}
              >
                <a id="completed">
                  Completed <span>{Interviews?.totalCompletedInterviews}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {currentNavigation === "Upcoming" ? (
        <>
          <UpComingInterviews />
        </>
      ) : null}

      {currentNavigation === "Completed" ? (
        <>
          <CompletedInterviews />
        </>
      ) : null}
    </div>
  );
}

export default Interview;
