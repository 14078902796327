import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LoadingShape, Pagination } from "react-rainbow-components";
import dots from "../../../assets/Icons/Settings/dots.svg";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import "./questions.css";
import AddSection from "../../../assets/Icons/Assessment/addSection.svg";
import EditSection from "../../../assets/Icons/Assessment/editSection.svg";
import viewAll from "../../../assets/Icons/Assessment/viewAll.svg";
import collapse from "../../../assets/Icons/Assessment/collapse.svg";
import closeIcon from "../../../assets/Icons/Settings/closeBtn.svg";
import ShareWithCandidates from "./components/ShareWithCandidates";
import Checked from "../../../assets/Icons/Settings/checked.svg";
import AssessmentTabs from "./components/AssessmentTabs";
import AddQuestions from "./components/AddQuestions";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import { BootcampBackend } from "backend";
import Participants from "./Participants";
import AssessmentSettings from "./Settings";
import ConfirmationModal from "../JobsPage/components/ConfirmationModal/ConfirmationModal";
import ToastMessages from "./components/ToastMessages";
import { Modal } from "react-bootstrap";
import { timer } from "SharedMethods/CommenMethods";
import { useSidebar } from "helper/SideBarContext";
export default function QuestionSummary(props) {
  const { isSidebarCollapsed } = useSidebar();
  const [tableData, setTableData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState({
    tags: [...tags.slice(0, 8)],
    viewAll: false,
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState("");
  const [editableSection, setEditableSection] = useState("");
  const [SectionSummary, setSectionSummary] = useState([]);
  const [AllSectionsSummary, setAllSectionsSummary] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [completeData, setCompleteData] = useState({});
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const [participantsList, setParticipantsList] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    variant: "",
    show: false,
    message: "",
    duration: 500,
  });
  const [activePage, setActivePage] = useState(1);
  const [participantTotal, setParticipantTotal] = useState(0);
  const [currentCandidate, setCurrentCandidate] = useState({});
  const [duration, setDuration] = useState("");
  const [durationErr, setDurationErr] = useState("");
  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [filter, setFilter] = useState({
    candidateStatus: "",
    invitedBy: "",
    invitedOn: {},
    completedOn: {},
  });
  const mhFields = [
    "Name",
    "Experience",
    "currentCompany",
    "LatestDesignation",
    "currentCtc",
    "expectingCtc",
    "noticePeriod",
    "city",
    "Resume_key",
  ];
  const [candidateDetailsList, setCandidateDetailsList] = useState([
    {
      label: "Full Name",
      value: "Name",
    },
    {
      label: "Work Experience",
      value: "Experience",
    },
    {
      label: "Current Company",
      value: "currentCompany",
    },
    {
      label: "Current Designation",
      value: "LatestDesignation",
    },
    {
      label: "Current CTC",
      value: "currentCtc",
    },
    {
      label: "Expecting CTC",
      value: "expectingCtc",
    },
    {
      label: "Notice Period",
      value: "noticePeriod",
    },
    {
      label: "City",
      value: "city",
    },
    {
      label: "Attach Resume",
      value: "Resume_key",
    },
  ]);
  const [publishMOdal, setPublishMOdal] = useState({
    heading: "",
    body: "",
    actionBtnName: "",
    closeBtnName: "",
    actionBtn: () => {},
    show: false,
  });
  const [skillwidth, setSkillwidth] = useState(0);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isLms, setIsLms] = useState(false);
  const params = useParams();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const skillsref = useRef(null);
  useOutsideAlerter(wrapperRef);
  const adminView = localStorage.getItem("adminView");
  const tableActions = [
    {
      dropdownClass: "delete",
      action: "Delete",
      handleClick: (data) => {
        setPublishMOdal({
          heading: "Delete Assessment",
          body: "Are you sure you want to delete ?",
          actionBtnName: "Delete",
          closeBtnName: "Cancel",
          actionBtn: () => {
            deleteSections(data._id, "singlesection");
          },
          show: true,
        });
        setShowPublishPopup(true);
      },
    },
    {
      dropdownClass: "edit",
      action: "Edit",
      handleClick: (data) => {
        oneditSetion(data);
      },
    },
  ];

  const oneditSetion = (data) => {
    history.push({
      pathname: `/assessments/${params.assessmentId}/${
        data.sectionType === "MCQ" ? "mcq" : "coding"
      }/questions/${data._id}`,
    });
    localStorage.setItem("isEditQuestions", true);
    localStorage.removeItem("selectedQuestions");
    if (assessmentData) {
      localStorage.setItem("assessment_title", assessmentData.title);
    }
  };
  const handleAction = (e, cell) => {
    e.stopPropagation();
    setOpen(cell.row.original._id);
  };
  const deleteSections = async (sectionId, sectionstype) => {
    let sectionIds = [];
    sectionstype !== "singlesection"
      ? sectionId?.map((x) => {
          sectionIds.push(x.original._id);
        })
      : sectionIds.push(sectionId);
    try {
      const deleteSectionResponse = await axios.delete(
        `${BootcampBackend}/enterpriseAssessment/deleteSection/${params.assessmentId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
          data: {
            sectionIds: sectionIds,
          },
        }
      );
      if (deleteSectionResponse) {
        setSectionSummary(
          SectionSummary.filter((o1) => !sectionIds.some((o2) => o1._id === o2))
        );
        getQuestionSummary();
        setShowPublishPopup(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getQuestionSummary = async () => {
    try {
      const sectionResponse = await axios.get(
        `${BootcampBackend}/enterpriseAssessment/getAllSectionsUnderAssessment/${params.assessmentId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (sectionResponse) {
        setAssessmentData(sectionResponse?.data?.result?.assessmentData);
        setCompleteData(sectionResponse?.data?.result?.completeSectionData);
        sectionResponse?.data?.result?.assessmentData?.fieldsForCandidate
          ?.length > 0 &&
          sectionResponse?.data?.result?.assessmentData?.fieldsForCandidate?.filter(
            (x) => {
              if (!mhFields.includes(x)) {
                let newCandidateField = {
                  label: x,
                  value: x,
                };
                setCandidateDetailsList((prev) => [...prev, newCandidateField]);
              }
            }
          );
        setSectionSummary(sectionResponse?.data?.result?.data);
        setAssessmentTitle(
          sectionResponse?.data?.result?.assessmentData?.title
        );
        setAllSectionsSummary(
          sectionResponse?.data?.result?.completeSectionData
        );

        setIsLoading(false);
        setSkillwidth(skillsref?.current?.offsetWidth);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const updateSectionTitle = async (dataId, title) => {
    try {
      const requestBody = {
        title: title,
        assessmentId: params.assessmentId,
      };
      const updateSectionTitleResponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/updateSectionTitle/${dataId}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (updateSectionTitleResponse) {
        setSectionSummary(
          SectionSummary.filter((x) => {
            if (x._id === dataId) {
              x.title = title;
            }
            return SectionSummary;
          })
        );
        setEditableSection("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleKeyDown = (evt, data, title) => {
    if (["Enter"].includes(evt.key)) {
      evt.preventDefault();
      updateSectionTitle(data, title);
    }
  };

  const publishAssessment = async () => {
    try {
      const requestBody = assessmentData;
      requestBody.publish = assessmentData.publish ? false : true;
      const publishResponse = await axios.put(
        `${BootcampBackend}/enterpriseAssessment/updateEnterpriseAssessment/${params.assessmentId}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (publishResponse) {
        setShowPublishPopup(false);
        setAssessmentData(publishResponse.data.result.data.data);
        setToastMessage({
          variant: "success",
          show: true,
          message: `Assessment has been ${
            requestBody.publish ? "published" : "unpublished"
          } successfully`,
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllParticipants = async (page) => {
    try {
      const limit = 20;
      const paramData = {
        limit: limit,
        current_page: page,
      };
      filter?.candidateStatus !== "" &&
        filter?.candidateStatus !== "allParticipants" &&
        (paramData.participantStatus = filter?.candidateStatus);
      filter?.invitedBy !== "" && (paramData.invitedBy = filter.invitedBy);
      filter?.invitedOn?.value !== "" &&
        (paramData.invitedAt = filter?.invitedOn?.value);
      filter?.completedOn?.value !== "" &&
        (paramData.completedAt = filter?.completedOn?.value);
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/getAllLmsParticipants/${params.assessmentId}`
        : `${BootcampBackend}/enterpriseAssessment/getAllParticipants/${params.assessmentId}`;
      const participantslistResponse = await axios.get(url, {
        params: paramData,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (participantslistResponse) {
        setParticipantsList(participantslistResponse.data?.result?.data);
        setParticipantTotal(
          participantslistResponse.data?.result?.totalParticipants
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChangePagination = (event, page) => {
    setActivePage(page);
    getAllParticipants(page);
  };

  const EditableCell = (props) => {
    const [value, setValue] = useState(props.value);
    const onChange = (e) => {
      e.stopPropagation();
      setValue(e.target.value);
    };
    const onBlur = () => {
      props.updateCellData(props.row.original._id, props.column.id, value);
    };

    return (
      <div>
        {editableSection === props.row.original._id ? (
          <>
            <input
              className="editableSectionInput"
              name="input"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              onClick={(e) => e.stopPropagation()}
            />
            <img
              className="img-fluid ms-2 checked"
              src={Checked}
              onClick={(e) => {
                e.stopPropagation();
                updateCellData(
                  props.row.original._id,
                  props.row.original,
                  value
                );
              }}
              onKeyDown={(e) => handleKeyDown(e, props.row.original, value)}
            />
          </>
        ) : (
          <>
            <span>{props.row.original.title}</span>
            <img
              className="img-fluid ms-2"
              src={EditSection}
              onClick={(e) => {
                e.stopPropagation();
                setEditableSection(props.row.original._id);
              }}
            />
          </>
        )}
      </div>
    );
  };
  const updateCellData = (idx, data, value) => {
    const newData = [...SectionSummary];
    newData.filter((x) => {
      if (x._id === idx) {
        x.title = value;
      }
    });
    setSectionSummary(newData);
    setEditableSection("");
    updateSectionTitle(idx, value);
  };

  useEffect(() => {
    localStorage.removeItem("isEditQuestions");
    getQuestionSummary();
  }, []);

  useEffect(() => {
    getAllParticipants(activePage);
  }, [filter,isLms]);
  const columns = useMemo(
    () => [
      {
        Header: `SECTIONS (${SectionSummary?.length || 0})`,
        accessor: "title",
        Cell: (props) =>
          EditableCell({
            ...props,
            updateCellData,
            updateSectionTitle,
            setEditableSection,
          }),
      },
      {
        Header: "TYPE",
        accessor: (originalRow, rowIndex) => (
          <>
            {originalRow?.sectionType === "MCQ"
              ? "Multiple Choice questions"
              : "Coding"}
          </>
        ),
      },
      {
        Header: "TIME ",
        accessor: (originalRow, rowIndex) => (
          <>{timer(originalRow?.duration, "words")}</>
        ),
      },
      {
        Header: "QUESTIONS",
        accessor: "questions",
      },
      {
        Header: "SKILLS",
        accessor: (originalRow, rowIndex) => (
          <div className="skillsHld">
            {originalRow?.skills?.toString().replaceAll(",", ", ")}
          </div>
        ),
      },
      {
        Header: `SCORE (${AllSectionsSummary?.totalScore})`,
        accessor: "score",
      },
      {
        Header: "ACTIONS",
        accessor: "",
        Cell: function tableAction({ cell }) {
          return (
            <div
              key={`userDetails${cell.row.original._id}`}
              className="userAction"
            >
              <button
                key={`action${cell.row.original._id}`}
                className="tableAction"
                onClick={(e) => handleAction(e, cell)}
              >
                <img
                  src={dots}
                  className="img-fluid"
                  alt="moreOption"
                  loading="lazy"
                />
              </button>

              {open === cell.row.original._id && (
                <div
                  id={cell.row.original._id}
                  className="tableMenu menuActive"
                  ref={wrapperRef}
                >
                  {tableActions.map((actionData, index) => (
                    <div
                      key={`action${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        actionData.handleClick(cell.row.original);
                      }}
                    >
                      <p className="paragraph">{actionData.action}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [open, editableSection, SectionSummary, AllSectionsSummary]
  );
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const IndeterminateCheckbox = React.forwardRef(function hadleSelection(
    { indeterminate, ...rest },
    ref
  ) {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input
          className="styled-checkbox"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          onClick={(e) => e.stopPropagation()}
        />
      </>
    );
  });
  const deleteparticipants = async (participantsIds, type) => {
    let userIds = [];
    type !== "single"
      ? participantsIds.filter((x) => {
          userIds.push(x._id);
        })
      : userIds.push(participantsIds._id);
    try {
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/deleteLmsParticipants/${params.assessmentId}`
        : `${BootcampBackend}/enterpriseAssessment/deleteParticipants/${params.assessmentId}`;
      const deleteParticipantResponse = await axios.delete(url, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
        data: {
          participantsIds: userIds,
        },
      });
      if (deleteParticipantResponse) {
        setParticipantsList(
          participantsList.filter((o1) => !userIds.some((o2) => o1._id === o2))
        );
        setShowPublishPopup(false);
        getAllParticipants(activePage);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleTags = () => {
    if (selectedTags.viewAll) {
      setSelectedTags({ tags: [...tags], viewAll: false });
    } else {
      setSelectedTags({ tags: [...tags.slice(0, 8)], viewAll: true });
    }
  };
  const addSection = async (questionType, questionfrom) => {
    try {
      const requestBody = {
        sectionType: questionType === "coding" ? "CODING" : "MCQ",
      };
      const addSectionResponse = await axios.post(
        `${BootcampBackend}/enterpriseAssessment/createEnterpriseSection/${params.assessmentId}`,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (addSectionResponse) {
        questionfrom === "mh-library"
          ? history.push(
              `/assessments/${params.assessmentId}/${questionType}/questions-set/${addSectionResponse?.data?.result?.data?._id}`
            )
          : history.push(
              `/assessments/${params.assessmentId}/create/${questionType}/${addSectionResponse?.data?.result?.data?._id}`
            );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    pageOptions,
    pageCount,
    gotoPage,
    visibleColumns,
    globalFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: SectionSummary,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: function selectionHead({ getToggleAllRowsSelectedProps }) {
            return (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            );
          },
          Cell: function selectionCell({ row, original }) {
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const reInvite = async (participants) => {
    try {
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/lmsSendInvite/${params.assessmentId}`
        : `${BootcampBackend}/enterpriseAssessment/sendInvite/${params.assessmentId}`;
      const inviteResponse = await axios.put(
        url,
        { selectedInvites: participants },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (inviteResponse) {
        setToastMessage({
          variant: "success",
          show: true,
          message: "Invite sent successfully",
          duration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onDurationChange = (e) => {
    const regPhoneno = /^[0-9]*$/;
    setDuration(e.target.value);
    setDurationErr("");
    if (!regPhoneno.test(e.target.value.trim())) {
      setDurationErr("Time should contain only numbers");
      return;
    }
  };
  const addAdditionalTime = () => {
    currentCandidate.assessmentTime =
      currentCandidate?.assessmentTime + parseInt(duration) * 60;
    likeDislikeCandidate(currentCandidate, "time");
    setOpen("");
  };
  const renderSwitch = (param) => {
    switch (param) {
      case "questions":
        return <Questions />;
      case "participants":
        return (
          <Participants
            participantsList={participantsList}
            activePage={activePage}
            handleOnChangePagination={handleOnChangePagination}
            participantTotal={participantTotal}
            setOpen={setOpen}
            likeDislikeCandidate={likeDislikeCandidate}
            open={open}
            isLoading={isLoading}
            reInvite={reInvite}
            assessmentData={assessmentData}
            setShowPublishPopup={setShowPublishPopup}
            setParticipantsList={setParticipantsList}
            filter={filter}
            setFilter={setFilter}
            assessmentId={params.assessmentId}
            deleteparticipants={deleteparticipants}
            setCurrentCandidate={setCurrentCandidate}
            currentCandidate={currentCandidate}
            setDurationErr={setDurationErr}
            setPublishMOdal={setPublishMOdal}
            isLms={isLms}
          />
        );
      case "setting":
        return (
          <AssessmentSettings
            assessmentId={params.assessmentId}
            completeData={completeData}
            assessmentData={assessmentData}
            AllSectionsSummary={AllSectionsSummary}
            setAssessmentData={setAssessmentData}
            candidateDetailsList={candidateDetailsList}
            setCandidateDetailsList={setCandidateDetailsList}
          />
        );
      default:
        return;
    }
  };
  const Questions = () => {
    return (
      <div className="questionSummaryHld p-4">
        <div className="d-flex justify-content-between">
          <div>
            {isLoading ? (
              <LoadingShape className="loadinghead mb-3" />
            ) : (
              <h6 className="">
                {/* Test Duration ({timer(AllSectionsSummary?.totalTime, "words")}) */}
                {assessmentTitle}
              </h6>
            )}
            <div className="d-flex pb-4 pt-2">
              {!isLoading ? (
                <>
                  {/* testDuration.map((ele, index) => ( */}
                  <p className="tagBtnBordered me-3 paragraph">
                    Total Questions
                    <span className="ms-2 paragraph">
                      {AllSectionsSummary?.totalQuestions}
                    </span>
                  </p>
                  <p className="tagBtnBordered me-3 paragraph">
                    Coding
                    <span className="ms-2 paragraph">
                      {AllSectionsSummary?.totalCoding}
                    </span>
                  </p>
                  <p className="tagBtnBordered me-3 paragraph">
                    Multiple Choice Questions
                    <span className="ms-2 paragraph">
                      {AllSectionsSummary?.totalMcq}
                    </span>
                  </p>
                  <p className="tagBtnBordered me-3 paragraph">
                    Total Score
                    <span className="ms-2 paragraph">
                      {AllSectionsSummary?.totalScore}
                    </span>
                  </p>
                  <p className="tagBtnBordered me-3 paragraph">
                    Total Time
                    <span className="ms-2 paragraph">
                      {timer(AllSectionsSummary?.totalTime, "words")}
                    </span>
                  </p>
                  {/* )) */}
                </>
              ) : (
                <>
                  {Array.from({ length: 5 }, (_, i) => (
                    <LoadingShape
                      key={`taglist${i}`}
                      className="loadingButton mb-2 me-2"
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <div>
            {isLoading ? (
              <LoadingShape className="loadingButton" />
            ) : (
              <>
                {Object.keys(selectedRowIds).length > 0 ? (
                  <div className="d-flex align-items-center">
                    <p className="me-3 selectedSections">
                      {Object.keys(selectedRowIds).length} Selected
                    </p>
                    <p
                      className="me-3 selectAllBtn"
                      onClick={() => toggleAllRowsSelected(true)}
                    >
                      All
                    </p>
                    <p
                      className="me-3 selectAllBtn"
                      onClick={() => toggleAllRowsSelected(false)}
                    >
                      None
                    </p>
                    <button
                      className="AssessmentTabsTryBtn me-3 py-2 px-4"
                      onClick={() =>
                        deleteSections(
                          selectedFlatRows.filter((d) => d.original),
                          "multipleSections"
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <button
                    className="btnActive"
                    onClick={() => {
                      setOpen("addQuestion");
                      if (assessmentData) {
                        localStorage.setItem(
                          "assessment_title",
                          assessmentData.title
                        );
                      }
                    }}
                  >
                    Add Section{" "}
                    <img className="img-fluid ms-2" src={AddSection} />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {isLoading ? (
          <LoadingShape className="loadinghead mb-3" />
        ) : (
          <>
            {AllSectionsSummary?.allSkills?.length > 0 && (
              <h6 className="headingText">Skills</h6>
            )}
          </>
        )}
        <div className="d-flex justify-content-between">
          <div
            ref={skillsref}
            className={`pt-2 pb-3 ${
              selectedTags.viewAll
                ? `skillTagsExpanded`
                : `d-flex skillTagsColapsed`
            }`}
          >
            {!isLoading ? (
              AllSectionsSummary?.allSkills?.map((element, i) => {
                if (element) {
                  return (
                    <p key={`tag${i}`} className=" tagBtn paragraph mb-2 me-2 ">
                      {element}
                    </p>
                  );
                }
              })
            ) : (
              <>
                {Array.from(
                  {
                    length: 8,
                  },
                  (_, i) => (
                    <LoadingShape
                      key={`taglist${i}`}
                      className="loadingButton ms-2 mb-2 me-2"
                    />
                  )
                )}
              </>
            )}
          </div>
          {skillwidth > 1200 && (
            <>
              {selectedTags.viewAll && (
                <p
                  className="collapseBtn paragraph cursor-pointer mb-2 col-lg-1"
                  onClick={handleTags}
                >
                  Collapse
                  <img className="img-fluid ms-3" src={collapse} />
                </p>
              )}
              {!selectedTags.viewAll && !isLoading && (
                <div className="position-relative">
                  <p
                    className="tagAction paragraph cursor-pointer mt-2"
                    onClick={handleTags}
                  >
                    View All
                    <img className="img-fluid ms-3" src={viewAll} />
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        {isLoading ? (
          <table>
            <thead>
              <tr>
                {Array.from(
                  {
                    length: 7,
                  },
                  (_, i) => (
                    <th key={`loader${i}Th`}>
                      <LoadingShape className="my-2 w-100" key={i} />
                    </th>
                  )
                )}
              </tr>
            </thead>

            <tbody>
              {Array.from(
                {
                  length: 10,
                },
                (_, i) => (
                  <tr key={`trrr${i}`} className="loadingRow">
                    {Array.from(
                      {
                        length: 7,
                      },
                      (_, i) => (
                        <td key={`loader${i}TD`} className="paragraph">
                          <LoadingShape className="my-2" key={i} />
                        </td>
                      )
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <>
            {SectionSummary?.length > 0 && (
              <table {...getTableProps()}>
                <thead>
                  <>
                    {headerGroups.map((headerGroup, i) => (
                      <tr
                        key={`trr${i}`}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, index) => (
                          <th
                            key={`th${index}`}
                            {...column.getHeaderProps()}
                            className="paragraph"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </>
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr key={`tr${i}`} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={`td${index}`}
                              {...cell.getCellProps()}
                              className="tableTdText cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                oneditSetion(row?.original);
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </>
        )}
        {!isLoading && tableData?.length > 10 && (
          <div className="settingPagination my-3">
            <Pagination
              className="rainbow-m_auto"
              pages={pageCount}
              activePage={pageIndex + 1}
              onChange={(e, page) => {
                gotoPage(page - 1);
              }}
              variant="shaded"
            />
          </div>
        )}
      </div>
    );
  };
  const likeDislikeCandidate = async (participant, updateType) => {
    try {
      const requestBody = {
        liked: participant.liked,
        termsAgreed: participant?.termsAgreed,
        assessmentTime: participant?.assessmentTime,
      };
      const url = isLms
        ? `${BootcampBackend}/enterpriseAssessment/lmsUpdateShareParticipant/${participant?._id}`
        : `${BootcampBackend}/enterpriseAssessment/updateShareParticipant/${participant?._id}`;
      const updateCandidatelikeStatusResponse = await axios.put(
        url,
        requestBody,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (updateCandidatelikeStatusResponse) {
        setParticipantsList((prev) =>
          prev.filter((x) => {
            if (x._id === participant?._id) {
              Object.assign(
                x,
                updateCandidatelikeStatusResponse.data?.result?.data
              );
            }
            return prev;
          })
        );
        updateType === "time" &&
          setToastMessage({
            variant: "success",
            show: true,
            message: "Extra time added successfully.",
            duration: 3000,
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const DeleteAssessments = async () => {
    try {
      let Ids = [];
      Ids.push(params.assessmentId);
      const deleteAssessmentResponse = await axios.delete(
        `${BootcampBackend}/enterpriseAssessment/deleteAssessment`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
          data: {
            assessmentIds: Ids,
          },
        }
      );
      if (deleteAssessmentResponse) {
        setToastMessage({
          variant: "success",
          show: true,
          message: "Assessment deleted successfully.",
          duration: 3000,
        });
        setTimeout(() => {
          history.push(`/assessments`);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOrganizationDetails = async () => {
    try {
      const res = await axios.get(`${BootcampBackend}/organization_profile`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      if (res?.data.result?.data) {
        setOrganizationDetails(res?.data.result?.data);
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrganizationDetails();
  }, []);
  return (
    <>
      <Header
        heading="Question Summary"
        route={`${adminView ? "/admin/assessments" : "/assessments"}`}
      />
      <Navbar/>
      <div
        className={`AssessmentCntr ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <AssessmentTabs
          totalQuestions={AllSectionsSummary?.totalQuestions}
          totalParticipants={participantTotal}
          activeTab={location.pathname.split("/")[3]}
          assessmentId={params.assessmentId}
          setOpen={setOpen}
          assessmentData={assessmentData}
          setShowPublishPopup={setShowPublishPopup}
          DeleteAssessments={DeleteAssessments}
          setPublishMOdal={setPublishMOdal}
          publishAssessment={publishAssessment}
          organizationDetails={organizationDetails}
          isLms={isLms}
          setIsLms={setIsLms}
        />
        {renderSwitch(location.pathname.split("/")[3])}

        {open === "shareWithCandidate" && (
          <ShareWithCandidates
            open={open}
            setOpen={setOpen}
            assessmentId={params.assessmentId}
            participantsList={participantsList}
            getAllParticipants={getAllParticipants}
            reInvite={reInvite}
            deleteparticipants={deleteparticipants}
            setCurrentCandidate={setCurrentCandidate}
            isLms={isLms}
          />
        )}
        {open === "addQuestion" && (
          <AddQuestions
            show={open === "addQuestion" ? true : false}
            onClose={() => setOpen("")}
            addSection={addSection}
            originPage="question-summary"
          />
        )}
        <div
          className={`${
            props.match.path.split("/")[3] === "questions" ? "settingToast" : ""
          }`}
        >
          {toastMessage.show && (
            <ToastMessages
              toastMessage={toastMessage}
              setToastMessage={setToastMessage}
            />
          )}
        </div>
        <Modal
          centered="true"
          dialogClassName="additionalTimeModal"
          show={open === "addAdditionalTime" ? true : false}
          onHide={() => setOpen("")}
        >
          <Modal.Header className="">
            <Modal.Title className="">Add Additional time</Modal.Title>
            <img
              className="cursor-pointer"
              onClick={() => setOpen("")}
              src={closeIcon}
              alt="x"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-wrap align-items-center additionalTimeModalbody py-3">
              <label
                className="sr-only me-4"
                htmlFor="inlineFormInputGroupUsername"
              >
                Add Time
              </label>
              <div className="input-group w-50">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroupUsername"
                  placeholder="Eg: 2"
                  value={duration}
                  onChange={onDurationChange}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">mins</div>
                </div>
              </div>
              {durationErr !== "" && (
                <p className="errMessage col-12">{durationErr}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {duration && durationErr === "" ? (
              <button
                className="primaryBtnActive py-1 px-5"
                onClick={addAdditionalTime}
              >
                Submit
              </button>
            ) : (
              <button className="primaryBtnInactive py-1 px-5">Submit</button>
            )}
          </Modal.Footer>
        </Modal>
        <ConfirmationModal
          ModalHeading={publishMOdal.heading}
          ModalBody={publishMOdal.body}
          closeIcon={true}
          ActionBtnName={publishMOdal.actionBtnName}
          closeBtnName={publishMOdal.closeBtnName}
          ActionBtn={publishMOdal.actionBtn}
          showPopup={showPublishPopup}
          setShowPopup={setShowPublishPopup}
        />
      </div>
    </>
  );
}
