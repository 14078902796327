import React from "react";
import { Modal } from "react-bootstrap";
import closeModal from "../../../../assets/Icons/MyHackathons/closeModal.svg";
import submitArrow from "../../../../assets/Icons/MyHackathons/submitArrow.svg";
import errorInputMessage from "../../../../assets/Icons/MyHackathons/errorInputMessage.svg";
import "./RequestHackathon.css";

function RequestHackathon(props) {
  return (
    <div>
      <Modal
        centered={true}
        show={props.show}
        className="jobPostPreviewModal my-auto"
        onHide={() => {}}
      >
        <Modal.Header className="position-relative modalJobHeader">
          <img
            className="requestHackathonModalClose cursor-pointer"
            onClick={() => props.hideModal()}
            src={closeModal}
          />
          <div className="Header-heading">Request a Hackathon</div>
        </Modal.Header>
        <Modal.Body className="d-flex requestHackathonModalBody justify-content-between flex-wrap">
          <div className="col-md-6">
            <div className="formLabel mt-3">
              Full Name <span>*</span>
            </div>
            <input
              onChange={props.handleChange}
              name="name"
              className={`contactUsInput w-75 mt-2 ${
                props?.userError?.name ? "requestHackathonModalError" : ""
              }`}
              placeholder="Eg. Rahul"
            />
            {props?.userError?.name && (
              <div className="requestHackathonModalErrorMessage mt-1">
                <img className="me-2" src={errorInputMessage} />
                <span>Please enter a valid Name!</span>
              </div>
            )}
            <div className="formLabel mt-3">
              Official Email <span>*</span>
            </div>
            <input
              name="emailId"
              type="email"
              onChange={props.handleChange}
              className={`contactUsInput w-75 mt-2 ${
                props?.userError?.emailId ? "requestHackathonModalError" : ""
              }`}
              placeholder="Eg. rahul@gmail.com"
            />
            {props?.userError?.emailId && (
              <div className="requestHackathonModalErrorMessage mt-1">
                <img className="me-2" src={errorInputMessage} />
                <span>Please enter a valid Email!</span>
              </div>
            )}
            <div className="formLabel mt-3">
              Designation <span>*</span>
            </div>
            <input
              name="designation"
              onChange={props.handleChange}
              className={`contactUsInput w-75 mt-2 ${
                props?.userError?.designation
                  ? "requestHackathonModalError"
                  : ""
              }`}
              placeholder="Eg. Senior Data Scientist"
            />
            {props?.userError?.designation && (
              <div className="requestHackathonModalErrorMessage mt-1">
                <img className="me-2" src={errorInputMessage} />
                <span>Please enter a valid designation!</span>
              </div>
            )}
            <div className="formLabel mt-3">
              Phone <span>*</span>
            </div>
            <input
              name="phone"
              type="number"
              onChange={props.handleChange}
              className={`contactUsInput w-75 mt-2 ${
                props?.userError?.phone ? "requestHackathonModalError" : ""
              }`}
              placeholder="9875642300"
            />
            {props?.userError?.phone && (
              <div className="requestHackathonModalErrorMessage mt-1">
                <img className="me-2" src={errorInputMessage} />
                <span>Please enter a valid phone number!</span>
              </div>
            )}
          </div>
          <div className="col-md-6 ">
            <div className="formLabel mt-3">
              Organization <span>*</span>
            </div>
            <input
              style={{ background: "red !important" }}
              name="organization"
              onChange={props.handleChange}
              className={`contactUsInput w-75 mt-2 ${
                props?.userError?.organization
                  ? "requestHackathonModalError"
                  : ""
              }`}
              placeholder="Eg. ABC Infotech"
            />
            {props?.userError?.organization && (
              <div className="requestHackathonModalErrorMessage mt-1">
                <img className="me-2" src={errorInputMessage} />
                <span>Please enter a valid organization!</span>
              </div>
            )}
            <div className="formLabel mt-3">
              Description <span>*</span>
            </div>
            <textarea
              name="description"
              onChange={props.handleChange}
              rows="11"
              className="requestHackathonModalTextArea w-75 px-3 mt-2"
              placeholder="I want to host a hackathon."
            />
          </div>
          <button
            onClick={props.handleSubmit}
            className="primaryBtnActive no-wrap mx-auto requestHackathonModalSubmit no-wrap"
          >
            Submit <img className="ms-2" src={submitArrow} />
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RequestHackathon;
