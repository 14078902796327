import React, { useEffect, useRef, useState } from "react";
import "./Jobs.css";

//ICONS
import appliedIcon from "../../../assets/Icons/Jobs/AppliedIcon.svg";
import dotsThree from "../../../assets/Icons/Jobs/svg.svg";

import { LoadingShape, Pagination } from "react-rainbow-components";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import draftIcon from "assets/Icons/Jobs/draft.svg";
import editIcon from "assets/Icons/Jobs/editJobGrey.svg";
import deleteIcon from "assets/Icons/Jobs/Delete.svg";
import { useHistory } from "react-router-dom";
import { Modal } from "react-rainbow-components";



const moment = require("moment");

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [selecetedJob, setSelectedJob] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [unpublishModal, setUnpublishModal] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [jobDescription, setJobDescription] = useState([]);
  const [showJobOptions, setShowJobOptions] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const wrapperRef = useRef(null);

  const [activePage, setActivePage] = useState(1);
  const lastItem = activePage * 8;
  const firstItem = lastItem - 8;
  const handleOnChange = (event, page) => {
    setActivePage(page);
  };

  function showDeleteModal() {
    setDeleteModal(true);
  }

  function hideDeleteModal() {
    setDeleteModal(false);
  }

  function showUnpublishModal() {
    setUnpublishModal(true);
  }

  function hideUnpublishModal() {
    setUnpublishModal(false);
  }


  useOutsideAlerter(wrapperRef);
  localStorage.setItem("adminView", true);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowJobOptions(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [menu, setMenu] = useState({
    _id: null,
  });

  const formatDate = (dateTime) => {
    if (dateTime) {
      var string = dateTime;
      var temp = string.split("T");

      var strdate = temp[0].split("-");
      var year = strdate[0];
      var month = strdate[1];
      var day = strdate[2];

      var formattedDate = moment({
        year: Number(year),
        month: Number(month) - 1,
        day: Number(day),
      }).format("DD MMM YYYY");

      if (formattedDate === "Invalid date") return "";

      return formattedDate;
    }
  };

  function handleEditJob(jobId) {
    history.push(`/jobs/editJob/${jobId}`);
    setShowJobOptions(false)
  }

  function handleTableButtonClick(cell) {
    if (menu._id === null) {
      setMenu({
        _id: cell._id,
      });
    } else {
      setMenu({
        _id: null,
      });
    }
  }

  function numDifferentiation(value) {
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 100000).toFixed(0);
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(1);
    } else if (val <= 100000) {
      val = (val / 100000).toFixed(2);
    }
    return val;
  }

  async function fetchJobs() {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/getAllJobsAdminPanel?current_page=${activePage}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setIsPageLoading(false);
          setAnotherColor(res?.data?.result?.data[0]?._id);
          setSelectedJob(res?.data?.result?.data[0]);
          setJobs(res?.data?.result?.data);
          setTotalCount(res?.data?.result?.totalCount);
          fetchJobDescripation(
            res?.data?.result?.data[0]?._id,
            res?.data?.result?.data[0]?.companyId?._id
          );
        });
      return res;
    } catch (err) {
      setIsPageLoading(false);
      console.log(err);
    }
  }

  async function fetchJobDescripation(id, companyId) {
    try {
      const res = await axios
        .get(
          `${BootcampBackend}/adminPanel/jobDescriptionAdminPanel/${companyId}/${id}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((res) => {
          setJobDescription(res?.data?.result?.data);
        });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchJobs();
  }, [activePage]);

  async function fetchSelectedJob(job) {
    setSelectedJob(job);
  }

  async function deleteJob(jobId) {
    // setJobs(
    //   jobs.filter((job) => {
    //     if (job._id) {
    //       return job?._id !== selecetedJob?._id;
    //     } else {
    //       return job?.job_id !== selecetedJob?._id;
    //     }
    //   })
    // );
    try {
      const res = await axios.delete(
        `${BootcampBackend}/adminPanel/deleteJobAdmin/${jobId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );

      hideDeleteModal(true);
    } catch (err) {
      console.log(err.response);
    }
  }

  async function unpublishJob(jobId) {
    try {
      const res = await axios.put(
        `${BootcampBackend}/adminPanel/updateJobAdmin/${jobId}`,
        {
          unpublish: true,
          timeStamp: Date(),
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      // hideUnpublishModal(true);
    } catch (err) {
      console.log(err.response);
    }
  }
  const [anotherColor, setAnotherColor] = useState("");
  const changeColor = (id) => {
    setAnotherColor(id);
  };
  return (
    <div className="adminViewJobs">
      <div className="d-flex justify-content-end mb-2 me-5">
        <div className="tabsWindow d-flex  flex-nowrap">
          <div className="tabBtnGroup">
            <button className={`btn-tabs active-tab`}>
              Total Jobs &nbsp;({jobs.length})
            </button>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="postedJobs">
        {isPageLoading
          ? Array?.from(
            {
              length: 10,
            },
            (_, i) => (
              <div
                className="jobCard d-flex flex-row justify-content-between"
                style={{
                  border: "none",
                  background: "#dfe0e470",
                  overflow: "hidden",
                }}
                key={i}
              >
                <div className="d-flex">
                  <LoadingShape
                    variant="image"
                    className="hackathonImg"
                    style={{ width: "67px", height: "62px" }}
                  />
                  <div>
                    {" "}
                    <LoadingShape
                      className="m-3"
                      style={{ height: "3px", width: "100%" }}
                    />
                    <LoadingShape
                      className="m-3"
                      style={{ height: "3px", width: "50%" }}
                    />
                    <LoadingShape
                      className="m-3"
                      style={{ height: "3px", width: "50%" }}
                    />
                  </div>
                </div>
              </div>
            )
          )
          : jobs.map((job, i) => (
            <div
              className="jobCard d-flex flex-row justify-content-between"
              key={i}
              onClick={() => {
                history.push(`/jobs/${job?._id || job?.job_id}`);
              }}
            >
              <div className="d-flex">
                <img src={job?.companyId?.logo} className="companyLogo" />
                <div className="jobCardData text-left ps-4">
                  <p className="jobTitle">{job?.jobTitle}</p>
                  <p className="views">
                    <span>
                      {formatDate(job?.createdAt) ||
                        moment(job?.created).format("DD MMM YYYY")}{" "}
                      { }
                    </span>

                    <span>
                      {job?.views?.length
                        ? job?.views?.length
                        : job?.views}{" "}
                      Views
                    </span>
                  </p>
                  <p>
                    <img src={appliedIcon} />
                    {typeof job.applicants === "number" ? (
                      <span className="applyCount">
                        {job.applicants.length}{" "}
                        Applied
                      </span>
                    ) : (
                      <span className="applyCount">
                        {job.applicants.length}{" "}
                        Applied
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="dotOptions position-relative" onClick={(e) => e.stopPropagation()}>
                <img
                  src={dotsThree}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowJobOptions(job?._id || job?.job_id);
                  }}
                  className="threeDotOptions"
                />
                {showJobOptions === job?._id && <div
                  className={"jobsDropDown joblistDropDown"}
                  ref={wrapperRef}
                >
                  <p className="mb-0" onClick={() => handleEditJob(job._id)}>
                    <img
                      src={editIcon}
                      className="img-fluid me-4"
                      loading="lazy"
                      alt="icon"
                      width="15"
                      height="15"
                    />
                    Edit
                  </p>
                  {!job.unpublish && <p className="mb-0" onClick={() => { unpublishJob(job?._id) }}>
                    <img
                      src={draftIcon}
                      className="img-fluid me-4"
                      loading="lazy"
                      alt="icon"
                      width="15"
                      height="15"
                    />
                    Unpublish
                  </p>}
                </div>}
              </div>
            </div>
          ))}
      </div>
      <Modal
        centered="true"
        dialogClassName="removeUserModal"
        show={deleteModal}
        onHide={hideDeleteModal}
      >
        {" "}
        {/* reusing from IAM table */}
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="revokeUserDialog">
            <div>
              <p>{`Are you sure you want to delete this job? `}</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div>
                <button
                  onClick={() => { deleteJob(selecetedJob) }}
                  type="submit"
                  className="modalButtonSubmit mx-2"
                >
                  Confirm
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={hideDeleteModal}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        dialogClassName="removeUserModal"
        show={unpublishModal}
        onHide={hideUnpublishModal}
      >
        {" "}
        {/* reusing from IAM table */}
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="revokeUserDialog">
            <div>
              <p>{`Are you sure you want to unpublish this job? `}</p>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div>
                <button
                  onClick={() => { unpublishJob(selecetedJob) }}
                  type="submit"
                  className="modalButtonSubmit mx-2"
                >
                  Confirm
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={hideUnpublishModal}
                  className="modalButtonCancel mx-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default Jobs;
