import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import LeftNavIcon from "assets/Icons/MyHackathons/arrowLeftBlack.svg";
import StudyPlanCard from "./components/StudyPlanCard";
import { useHistory } from "react-router-dom";
import TopContentCard from "./components/TopContentCard";
import TopPerformers from "./components/TopPerformers";
import viewAllSvg from "assets/Icons/DashboardNew/contactUs.svg";
import ParticipantsStats from "./components/ParticipantsStats";
import CourseCompletion from "./components/CourseCompletion";
import { useSidebar } from "helper/SideBarContext";
import UserActivity from "./components/UserActivity";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLMSLeaderboard,
  setLMSLeaderboardCurrentPage,
  setLMSLeaderboardLoader
} from "store/reducers/lmsSlice";
import { lmsService } from "services/lms.service";
import "../studyPlan.css";

const filter = [
  {
    id: "1",
    name: "All Time",
    slug: "all"
  },
  {
    id: "2",
    name: "1 Months",
    slug: "month"
  },
  {
    id: "3",
    name: "1 Week",
    slug: "week"
  },
  {
    id: "4",
    name: "Today",
    slug: "today"
  },
];

const StudyPlan = () => {
  const { isSidebarCollapsed } = useSidebar();
  const [viewAllParticipants, setViewAllParticipants] = useState(false);
  const [durationFilter, setDurationFilter] = useState(filter[0]);
  const history = useHistory();
  const param = useParams();
  const dispatch = useDispatch();

  const fetchTopPeformerStats = async () => {
    try {
      const topPerformerResponse = await lmsService.fetchLMSLeaderboard(
        param.studyplanSlug,
        1
      );
      dispatch(setLMSLeaderboard(topPerformerResponse));
    } catch (err) {
      dispatch(setLMSLeaderboardLoader(false));
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTopPeformerStats();
    return () => {
      dispatch(setLMSLeaderboard({ data: [], totalUsersInLeaderboard: 0 }));
      dispatch(setLMSLeaderboardCurrentPage(1));
    };
  }, []);
  return (
    <>
      <Header />
      <Navbar />
      <div
        className={`body-margin-top px-4 py-3 lms-container ${
          isSidebarCollapsed ? "collapsed-content-page" : "content-page"
        }`}
      >
        <div className="lms-heading">
          <h1>
            <img
              src={LeftNavIcon}
              alt="arrow"
              className="me-2 cursor-pointer"
              width={25}
              height={25}
              onClick={() => history.push("/lms")}
            />
            LMS
          </h1>
        </div>
        <StudyPlanCard studyplanSlug={param.studyplanSlug} />
        <div className="d-flex justify-content-between align-items-center overview-heading mb-3">
          <h2>{viewAllParticipants ? "Users Activity" : "Overview"}</h2>
          <div className="d-flex">
            <div className="d-flex filterHld me-1">
              {filter.map((item, i) => (
                <p
                  className={`menuhld ${item.name === durationFilter.name ? "active-menuhld" : ""}`}
                  key={i}
                  onClick={() => setDurationFilter(item)}
                >
                  {item.name}
                </p>
              ))}
            </div>
            <div
              className="d-flex filterHld"
              onClick={() => {setViewAllParticipants(!viewAllParticipants);setDurationFilter(filter[0])}}
            >
              <p className={`menuhld`}>
                {viewAllParticipants ? "Stats View" : "Students View"}{" "}
                <img className="" src={viewAllSvg} alt="view all" />
              </p>
            </div>
          </div>
        </div>
        {viewAllParticipants ? (
          <UserActivity studyplanSlug={param.studyplanSlug} durationFilter={durationFilter} />
        ) : (
          <div className="row">
            <ParticipantsStats studyplanSlug={param.studyplanSlug} />
            <CourseCompletion studyplanSlug={param.studyplanSlug} durationFilter={durationFilter} />
            <div className="col-6">
              <TopContentCard studyplanSlug={param.studyplanSlug} durationFilter={durationFilter} />
            </div>
            <div className="col-6">
              <TopPerformers />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StudyPlan;
