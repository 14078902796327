import React, { useState, useMemo, useRef, useEffect } from "react";
import Header from "components/Header/Header";
import Navbar from "components/Navbar/Navbar";
import InviteParticipants from "../../../assets/Icons/Assessment/InviteParticipants.svg";
import LikeParticipantsRed from "../../../assets/Icons/Assessment/LikeParticipantsRed.svg";
import DownloadResume from "../../../assets/Icons/Assessment/DownloadResume.svg";
import dotsThree from "../../../assets/Icons/Jobs/svg.svg";
import LikeParticipantsGray from "../../../assets/Icons/Assessment/LikeParticipantsGray.svg";
import messageArrow from "../../../assets/Icons/MyHackathons/messageArrow.svg";
import DislikeParticipantsGray from "../../../assets/Icons/Assessment/DislikeParticipantsGray.svg";
import contactProfile from "../../../assets/Icons/MyHackathons/contactProfile.svg";
import contactEmail from "../../../assets/Icons/MyHackathons/contactEmail.svg";
import contactPhone from "../../../assets/Icons/MyHackathons/contactPhone.svg";
import profileEmailCopy from "../../../assets/Icons/MyHackathons/profileEmailCopy.svg";
import DislikeParticipantsRed from "../../../assets/Icons/Assessment/DislikeParticipantsRed.svg";
import GrayDropdownArrow from "../../../assets/Icons/Assessment/GrayDropdownArrow.svg";
import CopyLink from "../../../assets/Icons/Assessment/share.svg";
import "./participants.css";
import ParticipantsFilter from "./components/ParticipantsFilter";
import { Pagination } from "react-rainbow-components";
import success from "../../../assets/Icons/Settings/success.svg";
import moment from "moment";
import { Machinehack_Url } from "backend";

import closeIcon from "assets/Icons/Settings/closeBtn.svg";
import { useHistory, useParams } from "react-router-dom";
import InvitePartcipants from "assets/Icons/Assessment/inviteParticipant.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import { timer } from "SharedMethods/CommenMethods";

export default function Participants(props) {
  const {
    participantsList,
    activePage,
    handleOnChangePagination,
    participantTotal,
    setOpen,
    likeDislikeCandidate,
    isLoading,
    reInvite,
    assessmentData,
    setShowPublishPopup,
    setParticipantsList,
    filter,
    setFilter,
    assessmentId,
    deleteparticipants,
    setCurrentCandidate,
    setPublishMOdal,
    isLms
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(-1);
  const [isCopied, setIsCopied] = useState("");
  const [showMoreOption, setShowMoreOption] = useState("");
  const [participantsIds, setParticipantsIds] = useState([]);
  const [masterChecked, setMasterchecked] = useState(false);
  const contactRef = useRef(null);
  const wrapperRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMoreOption("");
          setIsDropdownOpen(-1);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onMasterCheck = (e) => {
    let tempList = participantsList;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));
    setMasterchecked(e.target.checked);
    setParticipantsList(tempList);
    setParticipantsIds(participantsList.filter((e) => e.selected));
  };

  const onItemCheck = (e, item) => {
    let tempList = participantsList;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = participantsList.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setMasterchecked(totalItems === totalCheckedItems);
    setParticipantsList(tempList);
    setParticipantsIds(participantsList.filter((e) => e.selected));
  };

  const getSelectedRows = () => {
    setParticipantsIds(participantsList.filter((e) => e.selected));
  };

  useEffect(() => {
    const closeDropDown = (e) => {
      if (contactRef?.current && !contactRef?.current?.contains(e.target)) {
        setIsDropdownOpen();
      }
    };
    document.body.addEventListener("mousedown", closeDropDown);
  }, []);

  const copyLink = (data) => {
    setIsCopied(data._id);
    navigator.clipboard.writeText(data?.assessmentLink);
  };

  const updateCandidateStatus = (e, data, status) => {
    e.stopPropagation();
    data.liked = status ? true : false;
    likeDislikeCandidate(data, "like");
  };

  const onInviteClick = () => {
    assessmentData.publish
      ? setOpen("shareWithCandidate")
      : setShowPublishPopup(true);
  };

  const getToTalQues = (participant) => {
    let totalQues = 0;
    let noOfCorrectAns = 0;
    let earnedScore = 0;
    let sectionScore = 0;
    participant?.EvaluationData?.filter((section) => {
      totalQues += section?.totalQuestions;
      noOfCorrectAns += section?.correctlyAnswered;
      earnedScore += section?.totalEarnedScore;
      sectionScore += section?.totalSectionScore;
    });
    return {
      totalQues: totalQues,
      noOfCorrectAns: noOfCorrectAns,
      earnedScore,
      sectionScore,
    };
  };

  const getScoreinPer = (participant) => {
    return (
      ((getToTalQues(participant)?.earnedScore /
        getToTalQues(participant)?.sectionScore) *
        100)
    || 0)?.toFixed(2) + "%"
      // ? (
      //     (getToTalQues(participant)?.noOfCorrectAns /
      //       getToTalQues(participant)?.totalQues) *
      //     100
      //   ).toFixed() + "%"
      // : "0%";
  };
  function isInt(n) {
    return n % 1 === 0;
  }
  return (
    <>
      <div className="d-flex">
        <div className="participantsTable">
          {!isLoading && participantsList.length > 0 ? (
            <>
              <div className="ParticipantsTableHeader">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="me-3 AssessmentCheckbox ParticipantsTableCheckbox"
                    checked={masterChecked}
                    onChange={onMasterCheck}
                  />
                  <div className="ParticipantsTableCandidates">CANDIDATES</div>
                  <div className="ParticipantsTableStatus">STATUS</div>
                  <div className="ParticipantsTableScore text-start">SCORE</div>
                  <div className="ParticipantsTableTimeLine">TIMELINE</div>
                  <div className="ParticipantsTableActions">Time</div>
                  <div className="ParticipantsTableActions">ACTIONS</div>
                  <div className="ms-auto me-3">
                    {participantsIds.length > 0 ? (
                      <button
                        className="AssessmentTabsTryBtn"
                        onClick={() =>
                          deleteparticipants(participantsIds, "multiple")
                        }
                      >
                        Delete
                      </button>
                    ) : (
                      <button
                        className="AssessmentParticipantsInviteBtn"
                        onClick={() => setOpen("shareWithCandidate")}
                      >
                        Invite
                        <img
                          className="ms-2 img-fluid"
                          src={InviteParticipants}
                          loading="lazy"
                          alt="invite"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="ParticipantsTableBody mb-2">
                {participantsList.map((participant, i) => (
                  <div
                    key={i}
                    className={`d-flex ${
                      i === participantsList.length - 1
                        ? ""
                        : "ParticipantsTableDataBorder"
                    } align-items-center ParticipantsTableData ${
                      participant?.userId?._id ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      participant?.userId?._id &&
                      history.push(
                        `/assessments/${assessmentId}/timeline/${participant?.userId?._id}${isLms ? "?lms=true" : ""}`
                      )
                    }
                  >
                    <div className="ParticipantsTableCheckbox">
                      <input
                        type="checkbox"
                        className="me-3 AssessmentCheckbox"
                        checked={participant.selected}
                        onChange={(e) => onItemCheck(e, participant)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <div className="ParticipantsTableCandidates participantsTableText">
                      {participant?.userId?.Name
                        ? participant?.userId?.Name
                        : participant?.email?.split("@")[0]}
                      {participant?.assessmentStatus === "Completed" && (
                        <img
                          className="ms-2 img-fluid"
                          src={success}
                          loading="lazy"
                          alt="sent"
                        />
                      )}
                    </div>
                    <div className="ParticipantsTableStatus participantsTableText">
                      {participant?.assessmentStatus}
                    </div>
                    <div className="ParticipantsTableScore participantsTableText">
                      {getScoreinPer(participant) !== "NaN%"
                        ? getScoreinPer(participant)
                        : "-"}
                    </div>
                    <div className="ParticipantsTableTimeLine participantsTableText">
                      {participant?.completedAt
                        ? "Completed"
                        : participant?.startedAt
                        ? "Started"
                        : participant?.invitedAt
                        ? "Invited at"
                        : "Created at"}{" "}
                      {participant?.completedAt
                        ? moment(participant?.completedAt).fromNow()
                        : participant?.startedAt
                        ? moment(participant?.startedAt).fromNow()
                        : participant?.invitedAt
                        ? moment(participant?.invitedAt).fromNow()
                        : moment(participant?.createdAt).fromNow()}
                      {/* <img
                    src={messageArrow}
                    className="float-right ms-2 participantsTableMessageIcon img-fluid"
                    alt="message-icon"
                    loading="lazy"
                  /> */}
                    </div>
                    <div className="ParticipantsTableActions participantsTableText cursor-pointer">
                      {participant?.assessmentTime - participant?.remainingTime === 0 ? timer(participant?.assessmentTime, "words") : timer((participant?.assessmentTime - participant?.remainingTime), "words")}
                    </div>
                    <div className="position-relative">
                      <span
                        className="ParticipantsFilterDropdown cursor-pointer position-relative d-flex align-items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          isDropdownOpen !== i
                            ? setIsDropdownOpen(i)
                            : setIsDropdownOpen(-1);
                        }}
                      >
                        <span className="me-3">Contact</span>
                        <img
                          src={GrayDropdownArrow}
                          className="ms-auto float-right"
                        />
                      </span>
                      {isDropdownOpen === i && (
                        <div
                          ref={wrapperRef}
                          className="position-absolute participantsContactDropdown"
                        >
                          <div className="assessmentParticipantDropdownList">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(
                                  `${Machinehack_Url}/user/` +
                                    participant?.userId?._id
                                );
                              }}
                            >
                              <img className="me-4 " src={contactProfile} />
                              See MH Profile
                            </span>

                            <span
                              className="d-flex"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(
                                  participant?.email
                                );
                                setIsDropdownOpen(-1);
                              }}
                            >
                              <img
                                className="me-3 img-fluid"
                                src={contactEmail}
                                loading="lazy"
                              />
                              <span className="user_Name">
                                {participant?.email}
                              </span>
                              <img className="ms-3" src={profileEmailCopy} />
                            </span>

                            {participant?.userId?.Mobile && (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(
                                    participant?.userId?.Mobile
                                  );
                                  setIsDropdownOpen(-1);
                                }}
                              >
                                <img
                                  className="me-3 ms-1 img-fluid"
                                  src={contactPhone}
                                  loading="lazy"
                                  alt="copy"
                                />
                                {participant?.userId?.Mobile}
                                <img
                                  className="img-fluid copyUserdetailsIcon"
                                  src={profileEmailCopy}
                                  loading="lazy"
                                  alt="copy"
                                />
                              </span>
                            )}
                            {participant?.userId?.Resume_key !== "" && (
                              
                                <a
                                  download
                                  href={participant?.userId?.Resume_key}
                                  target="_blank"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <img
                                    className="me-3 ms-1 img-fluid"
                                    src={DownloadResume}
                                    loading="lazy"
                                    alt="resume"
                                  />
                                  Resume
                                </a>
                              
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <img
                      src={
                        participant?.liked
                          ? LikeParticipantsRed
                          : LikeParticipantsGray
                      }
                      className="me-3 ms-auto img-fluid cursor-pointer"
                      loading="lazy"
                      alt="like"
                      onClick={(e) =>
                        updateCandidateStatus(e, participant, true)
                      }
                    />
                    <img
                      src={
                        participant?.liked === false
                          ? DislikeParticipantsRed
                          : DislikeParticipantsGray
                      }
                      className="img-fluid cursor-pointer"
                      loading="lazy"
                      alt="like"
                      onClick={(e) =>
                        updateCandidateStatus(e, participant, false)
                      }
                    />
                    <div className="position-relative ms-auto">
                      <span
                        className="px-2 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMoreOption(participant._id);
                        }}
                      >
                        <img
                          src={dotsThree}
                          className=""
                          loading="lazy"
                          alt="moreOptions"
                        />
                      </span>

                      {showMoreOption === participant._id && (
                        <div className="AssessmentTabsOptions" ref={wrapperRef}>
                          {participant.assessmentStatus !== "Completed" && (
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentCandidate(participant);
                                props?.setOpen("addAdditionalTime");
                              }}
                            >
                              Add Extra Time
                            </p>
                          )}
                          {participant.assessmentStatus !== "Completed" &&
                            participant?.assessmentStatus !== "Inprogress" && (
                              <p
                                onClick={(e) => {
                                  e.stopPropagation();
                                  reInvite([participant?.email]);
                                }}
                              >
                                Re-Invite
                              </p>
                            )}
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                              setPublishMOdal({
                                heading: "Delete Assessment",
                                body: "Are you sure you want to delete ?",
                                actionBtnName: "Delete",
                                closeBtnName: "Cancel",
                                actionBtn: () => {
                                  deleteparticipants(participant, "single");
                                },
                                show: true,
                              });
                              setShowPublishPopup(true);
                              setParticipantsIds([]);
                              setMasterchecked(false);
                            }}
                          >
                            Delete
                          </p>
                          {participant?.userId?._id && (
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push(
                                  `/assessments/${assessmentId}/timeline/${participant?.userId?._id}${isLms ? "?lms=true" : ""}`
                                );
                              }}
                            >
                              View Timeline
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {participantTotal > 10 && (
                <Pagination
                  className="rainbow-m_auto"
                  pages={
                    participantTotal > 0
                      ? Math.ceil(participantTotal / 20)
                      : 1
                  }
                  activePage={activePage}
                  onChange={handleOnChangePagination}
                  variant="shaded"
                />
              )}
            </>
          ) : (
            <>
              {!isLoading && participantsList.length === 0 && (
                <div className="text-center noParticipants d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={InvitePartcipants}
                    className="img-fluid"
                    alt="invite"
                    loading="lazy"
                  />

                  <h5 className=" text-center mt-3">
                    Go ahead invite candidates
                  </h5>
                  <p className="mt-2 mb-4 pb-2">
                    Invite candidates to asses them
                  </p>
                  <button
                    className="AssessmentParticipantsInviteBtn py-2"
                    onClick={onInviteClick}
                  >
                    Invite
                    <img
                      className="ms-2 img-fluid"
                      src={InviteParticipants}
                      loading="lazy"
                      alt="invite"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <ParticipantsFilter
          className="ParticipantsFilter ms-3"
          filter={filter}
          setFilter={setFilter}
          assessmentId={assessmentId}
          isLms={isLms}
        />
      </div>
    </>
  );
}
