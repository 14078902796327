import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import UserDetails from "./UserDetails/UserDetails";
import SelectAssessment from "pages/PrivatePages/Interview/SelectAssessment";
import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import NoResult from "assets/Icons/MyHackathons/NoResult.svg";
import Praticipant from "assets/Images/MyHackathons/Praticipant.svg";
import Calendar from "assets/Images/MyHackathons/calendar.svg"
import Export from "assets/Images/MyHackathons/export.svg";
import "./Hackathon.css";
import axios from "axios";
import { BootcampBackend, Machinehack_Url } from "../../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import CandidatesList from "../../../../components/CandidatesList";
import { useSidebar } from "helper/SideBarContext";
import moment from "moment";

export default function Hackathon(props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showShortList, SetShowShortList] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [assessmentModalData, setAssessmentModalData] = useState("");
  const [hackathonData, setHackathonData] = useState({
    name: "",
    image: null,
    registration: null,
    leaderboard: null,
    end: null,
    start: "",
    href: "",
    resume_count: 0,
    notebook: null,
  });
  const [leaderboardUsers, setLeaderboardUsers] = useState([]);
  const [leaderboardLength, setLeaderBoardLength] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isLeaderBoardLoading, setIsLeaderBoardLoading] = useState(true);
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState(false);
  const { isSidebarCollapsed } = useSidebar();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  function exportLeaderboardToCSV(
    leaderboardArray,
    fileName = "leaderboard.csv"
  ) {
    if (!leaderboardArray || leaderboardArray.length === 0) {
      console.error("Leaderboard data is empty.");
      return;
    }
    const headers = Object.keys(leaderboardArray[0]);

    const csvContent = [
      headers.join(","),
      ...leaderboardArray.map(
        (row) => headers.map((header) => `"${row[header] || ""}"`).join(",") // Escape and add rows
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, fileName);
  }
  function handleSelectUser(id) {
    setSelectedUser(
      leaderboardUsers.filter((user) => {
        return user.User_Oid === id;
      })
    );
  }
  const formatDateInMD = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("MMMM DD ");

    if (formattedDate === "Invalid date") return "";
    return formattedDate;
  };
  const shortlistCandidate = async (userId) => {
    try {
      const response = await axios.put(
        `${BootcampBackend}/v1/hackathons/${props.match.params.hackathonId}/short-list/${userId}`,{},
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      );
      if (response.data.status.code === 200) {
        setLeaderboardUsers((prevLeaderboard) =>
          prevLeaderboard.map((candidate) =>
            candidate.User_Oid === userId
              ? { ...candidate, shortlisted: !candidate.shortlisted }
              : candidate
          )
        );
      }
    } catch (error) {
      console.error("Failed to shortlist candidate:", error);
    }
  };
  async function fetchHackathonData(hackathonSlug, shortlisted = false, current_page) {
    try {
      const token = JSON.parse(localStorage.getItem("jwt"))?.token;
  
      if (!token) {
        console.error("JWT token not found in localStorage.");
        return;
      }
  
      const endpoint = `${BootcampBackend}/v1/hackathons/${hackathonSlug}${
        shortlisted ? "?item=shortlist" : ""
      }${current_page ? `${shortlisted ?"&&":"?"}current_page=${current_page}&&limit=20` : ""}`;
  
      const res = await axios.get(endpoint, {
        headers: {
          Authorization: token,
        },
      });
  
      if (res.data && res.data.result && res.data.result.hackathonData) {
        const {
          HName,
          HImage,
          HUsers,
          totalSubmissions,
          HEnddate,
          HStartDate,
          HREFName,
          HStatusFlag,
        } = res?.data.result.hackathonData;
        setHackathonData({
          name: HName,
          image: HImage,
          registration: HUsers,
          leaderboard: totalSubmissions,
          end: HEnddate,
          start: HStartDate,
          href: HREFName,
          resume_count: "",
          status: HStatusFlag,
        });
        setLeaderboardUsers(res.data.result.leaderboardData || []);
        setLeaderBoardLength(res.data.result.leaderboardTotalCount || 0);
        setSelectedUser((res.data.result.leaderboardData || []).slice(0, 1));
      }
  
      return res;
    } catch (err) {
      console.error("Error fetching hackathon data:", err.response || err);
      throw err;
    } finally {
      setIsLoading(false);
      setIsLeaderBoardLoading(false);
    }
  }
  function handleOnChangePagination(event, page) {
    setActivePage(page);
    setIsLeaderBoardLoading(true);
    fetchHackathonData(props.match.params.hackathonId, showShortList, page)
      .then((res) => {
        if (res.data && res.data.result && res.data.result.hackathonData) {
          const { HName, HImage, HUsers, totalSubmissions, HEnddate, HStartDate, HREFName, HStatusFlag } = res.data.result.hackathonData;
          setHackathonData({
            name: HName,
            image: HImage,
            registration: HUsers,
            leaderboard: totalSubmissions,
            end: HEnddate,
            start: HStartDate,
            href: HREFName,
            resume_count: "",
            status: HStatusFlag,
          });
          setLeaderboardUsers(res.data.result.leaderboardData || []);
          setLeaderBoardLength(res.data.result.leaderboardTotalCount || 0);
          setSelectedUser((res.data.result.leaderboardData || []).slice(0, 1));
        }
        setIsLeaderBoardLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    setLeaderBoardLength(0);
    setSelectedUser(null);
    setIsLoading(true);
    setIsLeaderBoardLoading(true);
    fetchHackathonData(props.match.params.hackathonId, false, 1)
      .then((res) => {
        if (res?.data?.result?.hackathonData) {
          const { HName, HImage, HUsers, totalSubmissions, HEnddate, HStartDate, HREFName, HStatusFlag } = res?.data.result.hackathonData;
          setHackathonData({
            name: HName,
            image: HImage,
            registration: HUsers,
            leaderboard: totalSubmissions,
            end: HEnddate,
            start: HStartDate,
            href: HREFName,
            resume_count: "",
            status: HStatusFlag,
          });
          setIsLoading(false);
          setIsLeaderBoardLoading(false);
          setLeaderBoardLength(res.data.result.leaderboardTotalCount);
          setLeaderboardUsers(res.data.result.leaderboardData);
          setSelectedUser(
            res.data.result.leaderboardData.filter((item, index) => {
              return index === 0;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.hackathonId]);

  const handleExportLeaderborad = () => {
    axios
      .get(
        `${BootcampBackend}/v1/hackathons/${props.match.params.hackathonId}/export`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((response) => {
        if (response.data.result.leaderboardData) {
          exportLeaderboardToCSV(response.data.result.leaderboardData);
        }
      });
  };
  return (
    <div>
      <Header
        className="header mt-5"
        heading={`${hackathonData?.name}`}
        route={`${selectedAssessment === ""
          ? "/myHackathons"
          : `/myHackathons/${props.match.params.hackathonId}`
          }`}
        isLoading={isLoading}
        showAssessmentType={selectedAssessment}
        setShowAssessmentType={setSelectedAssessment}
      />

      <div className="container-fluid px-0 mt-4">
        <div className="hackathonData">
          <Navbar />
          <div
            className={`position-relative ${isSidebarCollapsed ? "collapsed-content-page" : "content-page"
              }`}
          >
            {selectedAssessment === "" ? (
              <div className="hackathonDataContainer pt-4">
                <div className="hackathonInfoHeader">
                  <img src={hackathonData.image} className="hackathonInnerBanner" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "30px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div className="hackathonStatusContainer">
                      <div className="hStatusPill">
                        <div
                          style={{
                            height: "5px",
                            width: "5px",
                            borderRadius: "50px",
                            backgroundColor: "#2BBD10",
                            marginRight: "5px",
                          }}
                        ></div>
                        <p>{hackathonData?.status}</p>
                      </div>
                    </div>
                    <div>
                      <h4 className="hackathonTitle">{hackathonData?.name}</h4>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          marginRight: "25px",
                        }}
                      >
                        <img
                          src={Calendar}
                          style={{ marginRight: "10px" }}
                          alt="Calendar"
                        ></img>
                        <p
                          style={{
                            color: "#3C3E4D",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {hackathonData?.start && hackathonData?.end
                            ? `Duration: ${formatDateInMD(
                              hackathonData?.start
                            )} - 
                            ${formatDateInMD(hackathonData?.end)}`
                            : ""}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          marginRight: "25px",
                        }}
                      >
                        <img
                          src={Praticipant}
                          style={{ marginRight: "10px" }}
                        ></img>
                        <p
                          style={{
                            color: "#3C3E4D",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {hackathonData?.registration} Participants
                        </p>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", marginRight: "25px" }}>
                        <img src={Prize} style={{ marginRight: "10px" }}></img>
                        <p style={{
                          color: "#3C3E4D",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500
                        }}>₹ 70000</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between col">
                    <p style={{
                      textAlign: "right",
                      color: "#3C3E4D",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}>Posted on  {moment(hackathonData?.start, "DD/MM/YYYY HH:mm:ss").format("MMM Do YY")}</p>
                    <div className="d-flex justify-content-end"><span className="totalSubmissionTab d-flex"><div className="totalSubmissionTabInner me-2 ">{hackathonData?.leaderboard}</div>Total Submissions</span>
                      <div className="visitHackathonBtn ms-3" onClick={() => { window.open(`${Machinehack_Url}/hackathons/${props.match.params.hackathonId}/overview`) }}>Visit Hackathon Page</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3 d-flex hackathonDataSearch align-items-center">
                  {isLoading ? (
                    <div className="col-md-3">
                      <LoadingShape style={{ width: "40%", height: "30px" }} />
                    </div>
                  ) : (
                    <span className="col-md-2 cardHeading">
                      Leaderboard ({leaderboardLength})
                    </span>
                  )}

                  <div className="col-md-4 d-flex align-items-center shortListFont">
                    Show Shortlisted{" "}
                    <div
                      className="toogleBtn"
                      onClick={() => {
                        if (showShortList) {
                          fetchHackathonData(props.match.params.hackathonId, false, 1)
                        }
                        else {
                          fetchHackathonData(props.match.params.hackathonId, true, 1)
                        }
                        setActivePage(1);
                        SetShowShortList(!showShortList);
                      }}
                    >
                      <div
                        className={`toogleInner ${showShortList ? `ms-3` : `me-3`
                          }`}
                        style={showShortList ? { backgroundColor: "#F44D5E" } : {}} ></div>{" "}
                    </div>{" "}
                  </div>
                  <div className="col-md-6">
                    <div
                      className="exportBtn ms-auto"
                      onClick={handleExportLeaderborad}
                    >
                      <span>Export Report</span>
                      <img style={{ filter: "invert(100%)" }} className="ms-2" src={Export} alt="export" />
                    </div>
                  </div>
                </div>
                {leaderboardLength > 0 ||
                  isLeaderBoardLoading ? (
                  <div className="col-md-12 d-flex">
                    <div className="col-md-4 candidatesListComponent">
                      <CandidatesList
                        leaderboardUsers={leaderboardUsers}
                        isLoading={isLeaderBoardLoading}
                        onSelect={handleSelectUser}
                        selectedUser={selectedUser}
                        activePage={activePage}
                        leaderboardLength={leaderboardLength}
                        handleOnChangePagination={handleOnChangePagination}
                        shortlistCandidate={shortlistCandidate}
                      />
                    </div>
                    {selectedUser && (
                      <UserDetails
                        selectedUserRank={selectedUser && selectedUser[0]?.rank}
                        userInfo={selectedUser[0]}
                        selectedAssessment={selectedAssessment}
                        setSelectedAssessment={setSelectedAssessment}
                        assessmentModalData={assessmentModalData}
                        setAssessmentModalData={setAssessmentModalData}
                        isLoading={isUserDetailsLoading}
                        uId={selectedUser && selectedUser[0]?.User_Oid}
                        notebookData={selectedUser && selectedUser[0]?.notebook}
                      />
                    )}
                  </div>
                ) : (
                  <div className="candidatesListNoData">
                    <img src={NoResult} />
                    <div className="candidatesListNoDataText">
                      No results found
                    </div>
                    <div className="candidatesListNoDataDec">
                      It seems we can’t find any results based on your search.
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <SelectAssessment
                type="Assessment"
                showAssessmentType={selectedAssessment}
                selectedUserEmail={userInfo.Email}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
