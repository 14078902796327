import React, { useEffect, useState } from "react";
import { LoadingShape } from "react-rainbow-components";
import { lmsService } from "services/lms.service";
import EmptyData from "./EmptyData";
export default function TopContentCard({ studyplanSlug, durationFilter }) {
  const [contentsStats, setContentsStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchContentsStats = async () => {
    try {
      const contentsStatsStatsResponse = await lmsService.fetchContentsStats(
        studyplanSlug,
        durationFilter.slug
      );
      setContentsStats(contentsStatsStatsResponse);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContentsStats();
  }, [durationFilter?.slug]);
  return (
    <div className="study-plan-card-inner mt-4">
      {isLoading ? (
        <LoadingShape className="mb-4 w-25" />
      ) : (
        <h1 className="card-heading mb-4 pb-2">Top Content Completed</h1>
      )}
      {isLoading ? (
        <>
          {Array.from({ length: 4 }).map((_, i) => (
            <LoadingShape className="mb-4" key={i} />
          ))}
        </>
      ) : (
        <div className="top-content-hld">
          {contentsStats?.length === 0 ? (
            <EmptyData />
          ) : (
            contentsStats?.map((stats, index) => (
              <div
                className="d-flex justify-contetn-between mb-3"
                key={stats?._id}
              >
                <span className="card-text col-4">{stats?.title}</span>
                <div className="studyplan-inner-percentage-hld">
                  <div
                    className={`studyplan-inner-gradient studyplan-inner-gradient-${
                      index % 4
                    }`}
                    style={{ width: stats?.dayCompletionPercentage + "%" }}
                  ></div>
                </div>
                <span className="card-text content-percentage-width">
                  {Math.round(stats?.dayCompletionPercentage)}%
                </span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
