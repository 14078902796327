import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Logo from "../../../../../assets/Logos/MH_Logo.svg";
import "./invoicePopup.css";
import { SubscriptionPlanService } from "services/subscription.service";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const InvoicePopup = ({
  showInvoicePopup,
  setShowInvoicePopup,
  selectedPlan,
  showContactUs,
  setShowContactUs,
}) => {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const invoiceRef = useRef();
  const printDocument = (e) => {
    html2canvas(invoiceRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "px", "a4");
      let width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save(`${invoiceDetails?.companyId?.companyName}.pdf`);
    });
  };
  const getInvoiceData = async () => {
    try {
      const invoice_data_response =
        await SubscriptionPlanService.fetchInvoiceData(
          selectedPlan?.razorpay_subscription_id
        );
      setInvoiceDetails(invoice_data_response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedPlan?._id) {
      selectedPlan?.subscriptionType === "free"
        ? setInvoiceDetails(selectedPlan)
        : getInvoiceData();
    }
  }, [selectedPlan?._id]);
  return (
    <Modal
      dialogClassName={`invoicePopup`}
      show={showInvoicePopup}
      onHide={() => setShowInvoicePopup(!showInvoicePopup)}
    >
      <div ref={invoiceRef} className="invoicePopup-hld">
        <img
          src={Logo}
          className="img-fluid mb-4"
          alt="logo"
          loading="lazy"
          height="30"
        />
        <div className="row justify-content-between">
          <div className="col-5">
            <p className="light-content">
              #280, 2nd floor, 5th Main, 15th A Cross Rd, Sector 6, HSR Layout,
              Bengaluru, Karnataka 560102
            </p>
          </div>
          <div className="col-3">
            <p className="light-content dark-content">Invoice number:</p>
            <p className="light-content mb-4">{selectedPlan?.orderId}</p>
            <p className="light-content dark-content">Issued date:</p>
            <p className="light-content">
              {moment(selectedPlan?.startsAt).format("DD MMMM YYYY")}
            </p>
          </div>
        </div>
        <div className="my-4">
          <p className="light-content dark-content">
            {invoiceDetails?.companyId?.companyName}
          </p>
          <p className="light-content">
            {invoiceDetails?.companyId?.contactEmail}
          </p>
          {invoiceDetails?.companyId?.phoneNumber ? (
            <p className="light-content">
              {invoiceDetails?.companyId?.phoneNumber}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="row justify-content-around mb-5 billing-to">
          {invoiceDetails?.companyId?.adressLine1 ||
          invoiceDetails?.companyId?.adressLine2 ? (
            <div className="col-3">
              <p className="light-content dark-content">Billed to:</p>
              <p className="light-content">
                {invoiceDetails?.companyId?.adressLine1 +
                  `${invoiceDetails?.companyId?.adressLine2 ? ", " : ""}` +
                  invoiceDetails?.companyId?.adressLine2}
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="col-3">
            <p className="light-content dark-content">Paid on</p>
            <p className="light-content">
              {moment(selectedPlan?.startsAt).format("DD-MM-YYYY | hh:mm:ss A")}
            </p>
          </div>
          {invoiceDetails?.razorpay_payment_id ? (
            <div className="col-3">
              <p className="light-content dark-content">transaction ID</p>
              <p className="light-content">
                {invoiceDetails?.razorpay_payment_id}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row table mb-1">
          <div className="col-6">
            <p className="table-head">DESCRIPTION</p>
          </div>
          <div className="col-2">
            <p className="table-head text-end">PRICE</p>
          </div>
          <div className="col-2">
            <p className="table-head text-end">QTY</p>
          </div>
          <div className="col-2">
            <p className="table-head text-end">AMOUNT</p>
          </div>
        </div>
        <div className="divider" />
        <div className="row table">
          <div className="col-6 d-flex align-items-center">
            <p className="light-content text-capitalize">
              {invoiceDetails?.mh_subscription_plan_id?.name +
                " " +
                invoiceDetails?.mh_subscription_plan_id?.period}{" "}
              subscription{" "}
            </p>
            {invoiceDetails?.subscriptionType === "free" ? (
              <p className="light-content ms-1 gray-content">(Discount 100%)</p>
            ) : (
              ""
            )}
          </div>
          <div className="col-2">
            <p className="table-text text-end">
              {invoiceDetails?.mh_subscription_plan_id?.currencyType === "INR"
                ? "₹"
                : "$"}
              {invoiceDetails?.mh_subscription_plan_id?.currencyType ===
                "INR" && invoiceDetails?.subscriptionType === "paid"
                ? (
                    invoiceDetails?.mh_subscription_plan_id?.amount /
                    (1 + 18 / 100)
                  )?.toFixed(2)
                : invoiceDetails?.mh_subscription_plan_id?.amount}
            </p>
          </div>
          <div className="col-2">
            <p className="table-text text-end">1</p>
          </div>
          <div className="col-2">
            {invoiceDetails?.subscriptionType === "free" ? (
              <p className="table-text text-end">0</p>
            ) : (
              <p className="table-text text-end">
                {invoiceDetails?.mh_subscription_plan_id?.currencyType === "INR"
                  ? "₹"
                  : "$"}
                {invoiceDetails?.mh_subscription_plan_id?.currencyType === "INR"
                  ? (
                      invoiceDetails?.mh_subscription_plan_id?.amount /
                      (1 + 18 / 100)
                    )?.toFixed(2)
                  : invoiceDetails?.mh_subscription_plan_id?.amount}
              </p>
            )}
          </div>
          {invoiceDetails?.subscriptionType === "free" ||
          invoiceDetails?.mh_subscription_plan_id?.currencyType === "USD" ? (
            <></>
          ) : (
            <>
              <div className="col-6 my-3">
                <p className="light-content text-end">CGST (9%)</p>
              </div>
              <div className="col-2 my-3">
                <p className="table-text text-end">
                  ₹
                  {(
                    ((invoiceDetails?.mh_subscription_plan_id?.amount /
                      (1 + 18 / 100)) *
                      9) /
                    100
                  )?.toFixed(2)}
                </p>
              </div>
              <div className="col-2 my-3">
                <p className="table-text text-end">1</p>
              </div>
              <div className="col-2 my-3">
                <p className="table-text text-end">
                  ₹
                  {(
                    ((invoiceDetails?.mh_subscription_plan_id?.amount /
                      (1 + 18 / 100)) *
                      9) /
                    100
                  )?.toFixed(2)}
                </p>
              </div>
              <div className="col-6">
                <p className="light-content text-end">SGST (9%)</p>
              </div>
              <div className="col-2">
                <p className="table-text text-end">
                  ₹
                  {(
                    ((invoiceDetails?.mh_subscription_plan_id?.amount /
                      (1 + 18 / 100)) *
                      9) /
                    100
                  )?.toFixed(2)}
                </p>
              </div>
              <div className="col-2">
                <p className="table-text text-end">1</p>
              </div>
              <div className="col-2">
                <p className="table-text text-end">
                  ₹
                  {(
                    ((invoiceDetails?.mh_subscription_plan_id?.amount /
                      (1 + 18 / 100)) *
                      9) /
                    100
                  )?.toFixed(2)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="divider" />
        <div className="row justify-content-end table">
          <p className="col-2 text-end total-amount">Total</p>
          <p className="col-2 text-end total-amount">
            {invoiceDetails?.subscriptionType === "free"
              ? "0"
              : (invoiceDetails?.mh_subscription_plan_id?.currencyType === "INR"
                  ? "₹"
                  : "$") + invoiceDetails?.mh_subscription_plan_id?.amount}
          </p>
        </div>
        <p className="my-5 light-content">
          This is a system generated invoice and does not require a signature or
          a digital signature
        </p>
        <p className="light-content darkest-content mb-3">MachineHack Team</p>
        <p
          className="light-content cursor-pointer"
          onClick={() => {
            setShowContactUs(!showContactUs);
            setShowInvoicePopup(!showInvoicePopup);
          }}
        >
          Need help? <span className="darkest-content">Contact us</span>
        </p>
      </div>
      <button className="downloadInvoice-btn" onClick={printDocument}>
        Download Invoice
      </button>
    </Modal>
  );
};

export default InvoicePopup;
