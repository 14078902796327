import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Accordion, Modal } from "react-bootstrap";
import crown from "assets/Icons/Pricing/crown.svg";
import "./pricing.css";
import { SubscriptionPlanService } from "services/subscription.service";
import { useDispatch, useSelector } from "react-redux";
import { initPayment } from "razorpay/subscription";
import { getCurrencyTypePlans } from "SharedMethods/CommenMethods";
import { userPaymentStatus } from "store/reducers/subscriptionSlice";
import LeftNavIcon from "assets/Icons/MyHackathons/arrowLeftBlack.svg";

const freePlan = {
  _id: "freeplan",
  publish: true,
  name: "Starter",
  slug: "starter-plan",
  description:
    "<ul><li>Request for Hackathons</li><li>Unlimited Job Posting</li><li>Unlimited enterprise users</li><li>Chat support</li><li>1 Assessment / month</li></ul>",
  currencyType: "",
  amount: "FREE",
  actualPrice: 100,
  period: "monthly",
  entity: "plan",
  interval: 1,
  notes: [],
  liveInterviewCount: 0,
  assessmentCount: 1,
};
const customPlan = {
  _id: "customPlan",
  publish: true,
  name: "Expertise",
  slug: "expertise",
  description:
    "<ul><li>Everything in Professional</li><li>Custom APIs</li><li>White labeling</li><li>Custom Assessment development</li><li>Free Maintenance</li><li>24X7 Support</li></ul>",
  currencyType: "",
  amount: "Custom Pricing",
  actualPrice: 100,
  period: "",
  entity: "",
  interval: 1,
  notes: [],
  liveInterviewCount: 0,
  assessmentCount: 0,
};
export default function SubscriptionPlanScreen(props) {
  const {
    showPlan,
    setShowPlan,
    showContactUs,
    setShowContactUs,
    setPopupOriginType,
    titleType,
  } = props;
  const currentUser = useSelector((state) => state.auth.user);
  const subscriptionData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const subscriptionPlans = useSelector((state) => state.subscription.plans);
  const subscriptionPaymentStatus = useSelector(
    (state) => state.subscription.payment_status
  );
  const isSubscriptionLoaded = useSelector(
    (state) => state.subscription.isLoaded
  );
  const [subscriptionPlanPeriod, setSubscriptionPlanPeriod] = useState(
    subscriptionData?.mh_subscription_plan_id?.period
      ? subscriptionData?.mh_subscription_plan_id?.period
      : "yearly"
  );
  const [buyNowClick, setBuyNowClick] = useState(false);
  const [subscriptionplans, setSubscriptionplans] = useState([]);
  const [showFAQ, setShowFAQ] = useState(false);
  const subscriptionfaqs = useSelector((state) => state.subscription.faqs);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowPlan(false);
  };
  const buyPlan = async (plan) => {
    try {
      setBuyNowClick(true);
      const create_order_response = await SubscriptionPlanService.createOrder(
        plan?.id
      );
      setBuyNowClick(false);
      if (create_order_response.data.razorpay_subscription_id) {
        const subscriptionId = {
          razorpay_subscription_id:
            create_order_response.data.razorpay_subscription_id,
          Name: currentUser.firstName + " " + currentUser.lastName,
          Email: currentUser.emailId,
          Mobile: currentUser.phoneNumber + "",
        };
        const data = Object.assign(subscriptionId, plan);
        initPayment(data);
      }
    } catch (err) {
      setBuyNowClick(false);
      console.log(err);
    }
  };

  const getAllSubscriptionData = async () => {
    try {
      const Plans = getCurrencyTypePlans(
        JSON.parse(JSON.stringify(subscriptionPlans))
      );
      subscriptionData?.mh_subscription_plan_id?.name === "professional"
        ? setSubscriptionplans(
            Plans.filter((x) => {
              if (
                x.name === "professional" &&
                (subscriptionData?.mh_subscription_plan_id?.period === "yearly"
                  ? x.period === "yearly"
                  : x.period)
              ) {
                return x;
              }
              Plans;
            })
          )
        : subscriptionData?.mh_subscription_plan_id?.name === "basic"
        ? setSubscriptionplans(
            Plans.filter((x) => {
              if (
                (x.name === "basic" &&
                  (subscriptionData?.mh_subscription_plan_id?.period ===
                  "yearly"
                    ? x.period === "yearly"
                    : x.period)) ||
                x.name === "professional"
              ) {
                return x;
              }
              Plans;
            })
          )
        : setSubscriptionplans(Plans);
      subscriptionData?.mh_subscription_plan_id?.period &&
        setSubscriptionPlanPeriod(
          subscriptionData?.mh_subscription_plan_id?.period
        );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (subscriptionPlans?.length > 0 && isSubscriptionLoaded) {
      getAllSubscriptionData();
    }
  }, [
    subscriptionPlans?.length,
    subscriptionData?._id,
    isSubscriptionLoaded,
    currentUser?._id,
  ]);
  useEffect(() => {
    if (subscriptionPaymentStatus && showPlan) {
      setShowPlan(!showPlan);
      dispatch(userPaymentStatus(false));
    }
  }, [subscriptionPaymentStatus, showPlan]);
  return (
    <Modal
      centered="true"
      dialogClassName={`planModal ${
        subscriptionData?.mh_subscription_plan_id?.name === "basic"
          ? "basicPlanModal"
          : subscriptionData?.mh_subscription_plan_id?.name ===
              "professional" && !showFAQ
          ? "professionalPlanModal"
          : "freePlanPopup"
      }`}
      show={showPlan}
      onHide={() => setShowPlan(!showPlan)}
    >
      <Modal.Header
        className={`freePlanScreenModalBody mt-3  ${
          subscriptionData?.mh_subscription_plan_id?.name === "professional" &&
          !showFAQ
            ? "px-4 align-items-center"
            : "px-5 align-items-start"
        } ${
          subscriptionData?.mh_subscription_plan_id?.name === "professional" &&
          subscriptionData?.mh_subscription_plan_id?.period === "yearly"
            ? "p-0 mt-0 close-button-hld"
            : ""
        }`}
        closeButton
      >
        <Modal.Title className="modalHeading mb-0">
          {showFAQ ? (
            <>
              <img
                src={LeftNavIcon}
                alt="back"
                className="img-fluid left-arrow me-2"
                onClick={() => setShowFAQ(!showFAQ)}
              />
              Frequently asked questions
            </>
          ) : (
            <>
              {subscriptionData?.mh_subscription_plan_id?.name ===
              "professional" ? (
                <>
                  {subscriptionData?.mh_subscription_plan_id?.period ===
                  "monthly" ? (
                    <SubscriptionPeriods
                      subscriptionPlanPeriod={subscriptionPlanPeriod}
                      setSubscriptionPlanPeriod={setSubscriptionPlanPeriod}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  Hey, you’re on a{" "}
                  {subscriptionData?.mh_subscription_plan_id?.name || "free"}{" "}
                  plan,{" "}
                  {titleType === "generic"
                    ? "For more features, please upgrade your plan"
                    : "Upgrade your plan to use this feature"}
                </>
              )}
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        {showFAQ ? (
          <FAQ subscriptionfaqs={subscriptionfaqs} />
        ) : (
          <div
            className={`freePlanScreenModalBody  ${
              subscriptionData?.mh_subscription_plan_id?.name === "professional"
                ? "px-3"
                : "px-5"
            }`}
          >
            {(subscriptionData?.mh_subscription_plan_id?.name !==
              "professional" &&
              subscriptionData?.mh_subscription_plan_id?.period ===
                "monthly") ||
            Object.keys(subscriptionData || {}).length === 0 ? (
              <SubscriptionPeriods
                subscriptionPlanPeriod={subscriptionPlanPeriod}
                setSubscriptionPlanPeriod={setSubscriptionPlanPeriod}
              />
            ) : (
              <></>
            )}
            <div className="row py-4">
              {subscriptionData?._id ? (
                <></>
              ) : (
                <div className="col">
                  <p className="subheadingText mb-3">Starter</p>
                  <p className="mb-4 pricingText">
                    FREE <span className="subheadingText">/mth</span>
                  </p>
                  <button className="currentPlan modalBtn">Current Plan</button>
                  <div className="mt-4">
                    <div
                      className="planModalText"
                      dangerouslySetInnerHTML={{
                        __html: freePlan?.description,
                      }}
                    ></div>
                  </div>
                </div>
              )}
              {subscriptionplans?.map((plan) => (
                <React.Fragment key={plan?._id}>
                  {subscriptionPlanPeriod === plan.period ? (
                    <div className="col">
                      <p className="subheadingText text-capitalize mb-3 d-flex align-items-center">
                        {plan?.name === "professional" ? (
                          <img className="me-2 mb-1" src={crown} alt="crown" />
                        ) : (
                          <></>
                        )}
                        {plan?.name}
                        {plan?.name === "professional" ? (
                          <span className="professionalPlan tabText ms-1">
                            Most Popular
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                      <p className="mb-4 pricingText">
                        <span className="subheadingText">
                          {plan?.currencyType === "USD" ? "$" : "₹"}
                        </span>{" "}
                        {plan?.amount}
                        <span className="subheadingText">
                          /{plan?.period === "monthly" ? "mth" : "Yr"}
                        </span>
                      </p>
                      {subscriptionData?.razorpay_plan_id === plan?.id ? (
                        <button className="currentPlan modalBtn" disabled>
                          Current Plan
                        </button>
                      ) : (
                        <>
                          {subscriptionData?.mh_subscription_plan_id?.name ===
                          "professional" ? (
                            <></>
                          ) : (
                            <button
                              className={`modalBtn ${
                                plan?.name === "professional"
                                  ? "professionalPlan"
                                  : "basicPlan"
                              }`}
                              onClick={() => buyPlan(plan)}
                              disabled={buyNowClick}
                            >
                              Buy Plan
                            </button>
                          )}
                        </>
                      )}
                      <div className="mt-4">
                        <div
                          className="planModalText"
                          dangerouslySetInnerHTML={{
                            __html: plan?.description,
                          }}
                        ></div>
                      </div>
                      {subscriptionData?.mh_subscription_plan_id?.name ===
                      "professional" ? (
                        <>
                          {plan?._id ===
                          subscriptionData?.mh_subscription_plan_id?._id ? (
                            <button
                              className={`modalBtn professionalPlan w-100 px-2 mt-2`}
                              onClick={() => {
                                setPopupOriginType &&
                                  setPopupOriginType("pricing");
                                setShowContactUs(!showContactUs);
                                setShowPlan(!showPlan);
                              }}
                            >
                              For Custom Pricing - Contact us
                            </button>
                          ) : (
                            <button
                              className={`modalBtn professionalPlan w-100 mt-2`}
                              onClick={() => buyPlan(plan)}
                              disabled={buyNowClick}
                            >
                              Buy Plan
                            </button>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
              {subscriptionData?.mh_subscription_plan_id?.name !==
              "professional" ? (
                <div className="col">
                  <p className="subheadingText mb-3">
                    <img className="me-2 mb-1" src={crown} alt="crown" />
                    Expertise
                  </p>
                  <p className="mb-4 pricingText">Custom Pricing</p>
                  <button
                    className="expertisePlan modalBtn"
                    onClick={() => {
                      setPopupOriginType && setPopupOriginType("pricing");
                      setShowContactUs(!showContactUs);
                      setShowPlan(!showPlan);
                    }}
                  >
                    Contact Us
                  </button>
                  <div className="mt-4">
                    <div
                      className="planModalText"
                      dangerouslySetInnerHTML={{
                        __html: customPlan?.description,
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <button
          className={`mx-auto frequently-asked-questions-btn ${
            showFAQ ? "goBackfromfaq" : ""
          }`}
          onClick={() => setShowFAQ(!showFAQ)}
        >
          {showFAQ ? (
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="me-2 circle-check"
              fontSize={10}
            />
          ) : (
            <></>
          )}
          {showFAQ ? "Take me to plans" : "Frequently Asked Question"}
          {!showFAQ ? (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="ms-3 circle-check"
              fontSize={10}
            />
          ) : (
            <></>
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
}

const SubscriptionPeriods = (props) => {
  const { subscriptionPlanPeriod, setSubscriptionPlanPeriod } = props;
  return (
    <div
      className="subscription-period-options"
      onClick={() =>
        setSubscriptionPlanPeriod(
          subscriptionPlanPeriod === "monthly" ? "yearly" : "monthly"
        )
      }
    >
      <p
        className={`subscription-period-text ${
          subscriptionPlanPeriod === "monthly"
            ? "subscription-period-text-active-hld"
            : ""
        }`}
      >
        Monthly
      </p>
      <p
        className={`subscription-period-text ${
          subscriptionPlanPeriod === "yearly"
            ? "subscription-period-text-active-hld"
            : ""
        }`}
      >
        Annual <span className="annual-benefits">8% Off</span>
      </p>
    </div>
  );
};

const FAQ = ({ subscriptionfaqs }) => {
  return (
    <Accordion defaultActiveKey="0" className="faq-accordion px-5 mx-3">
      {subscriptionfaqs?.map((faq) => (
        <Accordion.Item eventKey={faq?._id}>
          <Accordion.Header>{faq?.question}</Accordion.Header>
          <Accordion.Body className="px-0">{faq?.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
