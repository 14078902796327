import React, { useState } from 'react'
import aiSearch from "assets/Icons/ResumeSearch/aiSearch.svg";
import StarLottie from "assets/Lotties/star.json";
import ResumeLottie from "assets/Lotties/searchResume.json";
import Lottie from "lottie-react";
export default function SearchPage({onSearchTextChange,handleSearch}) {
  const [searchText, setSearchText] = useState("");
  return (
    <div className="container resumeSearchContainer">
    <Lottie
      animationData={ResumeLottie}
      loop={true}
      style={{ width: "203px", height: "203px", margin: "auto" }}
    />
    <h1 className="headingText">
      Search Prospective candidates with AI Powered by
    </h1>
    <h1 className="headingGradientText headingText pb-5">
      MachineHack GENAI
    </h1>
    <div className="resumeSearchHld mt-2">
    <div className="resumeSearchInputHld d-flex ps-3 align-items-center">
    <img className="aiSearch me-3" src={aiSearch} alt="aiSearch" />
    <input
      className="resumeSearchInput"
      name="searchText"
      type="text"
      value={searchText}
      placeholder="Search with job role, skills"
      onChange={(e)=>{setSearchText(e.target.value),onSearchTextChange(e.target.value)}}
    />
    <button className="searchBtn d-flex justify-content-center align-items-center" onClick={handleSearch}>
      Search Resume
      <Lottie
        animationData={StarLottie}
        loop={true}
        style={{ width: "30px", height: "30px", marginLeft:"10px" }}
      />
    </button>
  </div>
    </div>
  </div>

  )
}
