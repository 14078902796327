/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import ErrorPage from "../pages/PublicPages/ErrorPage/ErrorPage";
import { isAuthenticated } from "./user";

function AdminRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const location = useLocation();

  const goToLogin = () => {
    localStorage.setItem("prevPath", location.pathname);

    history.push("/login");
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated() ? (
          <>{goToLogin()}</>
        ) : JSON.parse(localStorage.getItem("jwt")).role === "admin_panel" ? (
          <Component {...props} />
        ) : (
          <Route path="*" component={ErrorPage} />
          //   <>{goToLogin()}</>
          // <Redirect
          // 	to={{
          // 		pathname: "/login",
          // 		state: {
          // 			from: props.location,
          // 		},
          // 	}}
          // />
        )
      }
    />
  );
}

export default AdminRoute;
