import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { isAuthenticated } from "helper/user";
import ReactStars from "react-rating-stars-component";
import { BootcampBackend } from "backend";
import Cross from "../../../../assets/Icons/Interview/Cross_interview.svg";
import { useHistory } from "react-router-dom";

const customStyles = {
  option: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    color: "#3c3e4d",
    padding: 10,
    zIndex: 999,
    fontSize: "14px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    backgroundColor: "#ffffff",
    border: "solid 1px #c4c4c4",
    borderRadius: 10,
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: () => ({
    color: "#9d9fa6",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    color: "#000",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
  }),
};

const InterviewStatusForm = ({
  show,
  tabs,
  notes,
  setNotes,
  setShow,
  interviewDataMemo,
  s3url,
  savetabData,
  interviewToken,
  isLoading,
  validateFeedbackForm,
}) => {
  const [interviewer, setInterviewer] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("");
  const [selectednextInterviewer, setSelectednextInterviewer] = useState("");
  const [selfAssign, setSelfAssign] = useState([]);
  const [nextInterviewer, setnextInterviewer] = useState([]);
  const [CodeQualitynotes, setCodeQualityNotes] = useState({
    notes: "",
    ratings: 0,
    error: false,
  });
  const [LanguageProficiencynotes, setLanguageProficiencyNotes] = useState({
    notes: "",
    ratings: 0,
    error: false,
  });
  const [OverallNotes, setOverallNotes] = useState({
    notes: "",
    ratings: 0,
    error: false,
  });
  const history = useHistory();
  const onchangeInterviewNotes = (e) => {
    setNotes((prevValue) => {
      return {
        ...prevValue,
        notes: e.target.value,
        error: false,
      };
    });
  };
  const ratingCodeQualityChanged = (newRating) => {
    setCodeQualityNotes((prevValue) => {
      return {
        ...prevValue,
        ratings: newRating,
        error: false,
      };
    });
  };
  const ratingLanguageProficiencyChanged = (newRating) => {
    setLanguageProficiencyNotes((prevValue) => {
      return {
        ...prevValue,
        ratings: newRating,
        error: false,
      };
    });
  };
  const ratingOverallChanged = (newRating) => {
    setOverallNotes((prevValue) => {
      return {
        ...prevValue,
        ratings: newRating,
        error: false,
      };
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlenextInterviewerChange = (value) => {
    setSelectednextInterviewer(value);
  };

  const submitInterviewNotes = async () => {
    if (
      (notes.notes ||
        interviewDataMemo?.interviewRoundId?.interviewerFeedback
          ?.interviewFeedBack) === ""
    ) {
      setNotes((prevValue) => {
        return {
          ...prevValue,
          error: true,
        };
      });
      return;
    }
    if (tabs.name === "Coding" || tabs.name === "Whiteboard") {
      if (tabs.name === "Whiteboard") {
        const data = {
          tabID: tabs.tabID?.toString() || tabs.id,
          imgUrl: s3url,
        };
        savetabData(data);
      } else {
        const data = {
          tabID: tabs.tabID?.toString() || tabs.id,
          code: tabs.panelComponent.data,
        };
        savetabData(data);
      }
    }
    const notesData = {};
    notesData.interviewFeedBack = notes.notes;

    CodeQualitynotes.ratings !== 0 &&
      (notesData.codeQuality = CodeQualitynotes);

    LanguageProficiencynotes.ratings !== 0 &&
      (notesData.languageProficiency = LanguageProficiencynotes);
    OverallNotes.ratings !== 0 && (notesData.overAll = OverallNotes);
    const paramsData = {};
    paramsData.interviewVideoLink = "https://s3-link.com";
    paramsData.candidateInterviewStatus = candidateStatus;
    paramsData.suggestNextInterviewer =
      selectednextInterviewer && selectednextInterviewer.value;
    paramsData.interviewerFeedback = notesData;
    paramsData.sessionEnded = true;
    await axios
      .put(
        `${BootcampBackend}/interview/updateInterviewFeedBack/${interviewToken}`,
        paramsData,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then((response) => {
        setShow(false);
        history.push("/interviews");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fetchIamUserData() {
    try {
      const res = await axios.get(`${BootcampBackend}/iamUser/all`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
        },
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (isAuthenticated()) {
      fetchIamUserData()
        .then((res) => {
          let companyUsers = res.data.result.data.filter((x) => {
            x.label = x.firstName + x.lastName;
            x.value = x._id;
            return x;
          });
          setnextInterviewer(companyUsers);
          let currentUserLogin = res.data.result.data.filter((x) => {
            return isAuthenticated().userId === x._id;
          });

          setSelfAssign(currentUserLogin);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <>
      {isAuthenticated() && tabs?.name !== "Untitled" && !isLoading && (
        <div className="mainFeedbackSection px-3 py-4">
          <h5 className="scoreBoardHeading">Interview Notes</h5>
          <textarea
            type="text"
            placeholder="The candidate would not see these notes..."
            className="form-control FBtext-Area"
            rows="6"
            value={
              notes?.notes ||
              interviewDataMemo?.interviewRoundId?.interviewerFeedback
                ?.interviewFeedBack
            }
            onChange={onchangeInterviewNotes}
          ></textarea>
          <div className="FBRatingSection">
            <div className="d-flex align-items-center my-3 justify-content-between">
              <h6 className="FBRatingTitle me-2">Language Proficiency </h6>
              <div className="px-3">
                <ReactStars
                  count={5}
                  value={
                    interviewDataMemo?.interviewRoundId?.interviewerFeedback
                      ?.languageProficiency?.ratings || 0
                  }
                  onChange={ratingLanguageProficiencyChanged}
                  size={18}
                  activeColor="#FF9F4B"
                  color="#C4C4C4"
                  className=""
                />
              </div>
            </div>
            <div className="d-flex align-items-center my-3 justify-content-between">
              <h6 className="FBRatingTitle me-2">Code Quality</h6>
              <div className="px-3">
                <ReactStars
                  count={5}
                  value={
                    interviewDataMemo?.interviewRoundId?.interviewerFeedback
                      ?.codeQuality?.ratings || 0
                  }
                  onChange={ratingCodeQualityChanged}
                  size={18}
                  activeColor="#FF9F4B"
                  color="#C4C4C4"
                  className=""
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between my-3">
              <h6 className="FBRatingTitle me-2">Overall Rating</h6>
              <div className="px-3">
                <ReactStars
                  count={5}
                  value={
                    interviewDataMemo?.interviewRoundId?.interviewerFeedback
                      ?.overAll?.ratings || 0
                  }
                  onChange={ratingOverallChanged}
                  size={18}
                  activeColor="#FF9F4B"
                  color="#C4C4C4"
                  className=""
                />
              </div>
            </div>
          </div>
          {(notes.notes ||
            interviewDataMemo?.interviewRoundId?.interviewerFeedback
              ?.interviewFeedBack) === "" ? (
            <button className="interviewSubmitbtn submitInactive" type="button">
              Submit
            </button>
          ) : (
            <button
              className="interviewSubmitbtn"
              type="button"
              onClick={validateFeedbackForm}
              style={{
                background: "#e9505c",
              }}
            >
              Submit
            </button>
          )}
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered
        enforceFocus={false}
        className="finalInterviewStatus"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <img
              src={Cross}
              alt="Cross"
              loading="lazy"
              onClick={handleClose}
              className="img-fluid ms-auto closeBtn cursor-pointer"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <h5 className="d-flex justify-content-center">Interview Status</h5>
            <h6 className="notestitle mb-3">Status of the Candidate</h6>
            <div className="d-flex statusSection mb-3">
              <p
                onClick={() => setCandidateStatus("selected")}
                style={{
                  background: `${
                    candidateStatus === "selected" ? "#92929D" : ""
                  }`,
                  color: `${candidateStatus === "selected" ? "#ffffff" : ""}`,
                }}
                className="py-2 w-100"
              >
                Select
              </p>
              <p
                onClick={() => setCandidateStatus("passed")}
                style={{
                  background: `${
                    candidateStatus === "passed" ? "#92929D" : ""
                  }`,
                  color: `${candidateStatus === "passed" ? "#ffffff" : ""}`,
                }}
                className="py-2 w-100"
              >
                Pass
              </p>
              <div className="line"></div>
              <p
                onClick={() => setCandidateStatus("rejected")}
                style={{
                  background: `${
                    candidateStatus === "rejected" ? "#92929D" : ""
                  }`,
                  color: `${candidateStatus === "rejected" ? "#ffffff" : ""}`,
                }}
                className="py-2 w-100"
              >
                Reject
              </p>
            </div>
            {candidateStatus === "passed" && (
              <div className="passedStatusContainer ">
                <div className="d-flex align-items-center align-items-center justify-content-around">
                  <span className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="assignInterviewer"
                      className="me-3"
                      onClick={() => {
                        setInterviewer("self");
                        setSelectednextInterviewer();
                      }}
                    />
                    Assign to myself
                  </span>
                  <span className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="assignInterviewer"
                      className="me-3"
                      onClick={() => setInterviewer("IAM")}
                    />
                    Assign to IAM user
                  </span>
                </div>

                {interviewer === "self" && (
                  <div className="selfUserContainer p-3 mt-4">
                    <h6>
                      {selfAssign[0].firstName + " " + selfAssign[0].lastName}
                    </h6>
                    <p>{selfAssign[0].emailId}</p>
                  </div>
                )}
                {interviewer === "IAM" && (
                  <div className="mt-4">
                    <Select
                      styles={customStyles}
                      value={selectednextInterviewer}
                      onChange={handlenextInterviewerChange}
                      options={nextInterviewer}
                      placeholder="Select a user to assign"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="text-center mt-4 mb-2">
              <button
                className="submitbtn"
                type="button"
                onClick={submitInterviewNotes}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InterviewStatusForm;
