import axios from "axios";
import { BootcampBackend } from "backend";
import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../../assets/Icons/cross.svg";
import Select from "react-select";
function TagsAutoSearch({ handletagsData, data }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [tagList, setTagList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [showtagListDropdown, setShowtagListDropdown] = useState(false);
  const [allTags, setAlltags] = useState([]);
  const textInputRef = useRef();
  const [currentTagListPage, setCurrentTagListPage] = useState(1);
  const onClickDivFocusInput = () => {
    textInputRef.current.focus();
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowtagListDropdown(false);
          setSelectedValue("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const getAllTags = () => {
    axios
      .get(`${BootcampBackend}/practices/getAllPracticeTagsUser`)
      .then((response) => {
        if (response.data.status.code === 200) {
          setAlltags(response.data.result.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      getAllTags(currentTagListPage + 1, "scroll");
    }
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      setTagList([...tagList, evt.target.value]);
      handletagsData(evt.target.value);
    }
  };
  const removeTag = (index) => {
    let idList;
    const filteredList = tagList.filter((ele) => {
      if (ele != tagList[index]) {
        idList.push(ele._id);
      }
      return ele != tagList[index];
    });
    setTagList(filteredList);
    handletagsData(idList);
  };
  useEffect(() => {
    getAllTags(currentTagListPage, "");
  }, []);
  return (
    <div className="tagsHld">
      <div className="tagsSearchBar" onClick={onClickDivFocusInput}>
        {tagList &&
          tagList.map((object, index) => (
            <div className="tag" key={index}>
              {object.title}
              <img
                className="img-fluid ms-3"
                src={closeIcon}
                alt="x"
                onClick={() => removeTag(index)}
              />
            </div>
          ))}
        <input
          ref={textInputRef}
          className="tagsInput"
          type="text"
          name="search"
          placeholder=""
          value={selectedValue}
          // onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => {
            setShowtagListDropdown(true);
            setSelectedValue(e.target.value);
          }}
        />
      </div>
      {showtagListDropdown && (
        <div
          className="tagsDropdown mt-2"
          ref={wrapperRef}
          onScroll={handleScroll}
        >
          {allTags.length > 0 &&
            allTags?.map((tag) => (
              <p
                key={tag._id}
                onClick={() => {
                  setTagList([...tagList, tag]);
                  setSelectedValue("");
                  handletagsData([...data, tag._id]);
                }}
                className="mb-0"
              >
                {tag?.title}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}

export default TagsAutoSearch;
