import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import loginGif from "../../../assets/For-Side.gif";
import checked from "../../../assets/Icons/Settings/checked.svg";
import activeEmail from "../../../assets/Icons/activeEmail.svg";
import activePassword from "../../../assets/Icons/activePassword.svg";
import hidePassword from "../../../assets/Icons/hidePassword.svg";
import showPassword from "../../../assets/Icons/showPassword.svg";
import email from "../../../assets/Icons/email.svg";
import password from "../../../assets/Icons/password.svg";
import axios from "axios";
import { BootcampBackend } from "../../../backend";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "../ResetPassword/resetPassword";
import LoginSignUpHeader from "./LoginSignUpHeader.js";
import { isMobile } from "react-device-detect";

export default function Login(props) {
  const [formState, setFormState] = useState("login");
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
  const [loginError, setLoginError] = useState({
    userName: false,
    password: false,
  });

  function handleRegisterClick() {
    history.push("/signup");
  }

  function toggleForgotPassword() {
    setError("");
    setFormState("forgotPassword");
  }

  function handleChangeLogin(event) {
    setLoginError({
      userNameError: false,
      passwordError: false,
    });
    const { name, value } = event.target;
    setLoginData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function handleLogin(event) {
    event.preventDefault();

    setError("");

    axios
      .post(
        `${BootcampBackend}/iamUser/login`,
        {
          emailId: loginData.userName,
          password: loginData.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          localStorage.setItem(
            "jwt",
            JSON.stringify({
              token: res.data.result.token.token,
              eid: res.data.result.enterprise,
              role: res.data.result.role,
            })
          );

          localStorage.setItem("loginTime", Date.now());
          const prevPath = localStorage.getItem("prevPath");
          if (prevPath) {
            history.push(localStorage.getItem("prevPath"));
          } else history.push("/jobs");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status.code === 404) {
            setLoginError({
              userName: true,
            });
          }

          if (err.response && err.response.data.status.code === 403) {
            setLoginError({
              password: true,
            });
          }

          if (err.response.data.status.code === 500) {
            setError("Internal server error.Please try after sometime.");
          }
        } else {
          setError("Internal server error.Please try after sometime.");
        }
      });
  }

  // useEffect(() => {
  //   if (localStorage.getItem("jwt")) {
  //     history.push("/dashboard");
  //   }
  // }, []);
  useEffect(() => {
    if (props.match.params.userId && props.match.params.token) {
      setFormState("resetPassword");
    }
  }, [props.match.params.userId, props.match.params.token]);
  return (
    <div className="container-fluid loginSignUpHld">
      <LoginSignUpHeader />
      <div className={`${isMobile ? "d-flex flex-wrap-reverse" : "row"} `}>
        <div
          className={`${isMobile ? "col-12" : "col-6"} p-5 loginLeftContainer`}
        >
          <div className={`${isMobile ? "col-12" : "col-8 ps-2"} pt-3  m-auto`}>
            <h2 className="text-left loginHeadText mb-2 mt-2">
              Hello Recruiter!
            </h2>
            <p className="text-left loginText fontGray  pb-5">
              Login to MachineHack and start hiring the best talents.
            </p>
            <div className={`${isMobile ? "" : "mt-4"} d-flex listContent`}>
              <img
                className={`${isMobile ? "mb-auto" : ""} img-fluid`}
                src={checked}
              />
              <p className={`${isMobile ? "me-5" : "ms-3"}`}>
                Platform with 1000+ advanced assessment
              </p>
            </div>
            <div className={`${isMobile ? "" : "mt-4"} d-flex listContent`}>
              <img
                className={`${isMobile ? "mb-auto " : ""} img-fluid`}
                src={checked}
              />
              <p className={`${isMobile ? "" : "ms-3"}`}>
                Host online hackathon to reach 100k+ developers
              </p>
            </div>
            <div className={`${isMobile ? "" : ""} mt-4 d-flex listContent`}>
              <img
                className={`${isMobile ? "mb-auto me-2" : ""} img-fluid`}
                src={checked}
              />
              <p className={`${isMobile ? "" : "ms-3"}`}>
                Advanced live coding interview{" "}
              </p>
            </div>
            <div
              className={`${isMobile ? "" : ""} mt-4 mb-5 d-flex listContent`}
            >
              <img
                className={`${isMobile ? "mb-auto me-2" : ""} img-fluid`}
                src={checked}
              />
              <p className={`${isMobile ? "" : "ms-3"}`}>
                Intensive applicant tracking
              </p>
            </div>
          </div>
          <img
            className={`${
              isMobile ? "col-12" : ""
            } gifContainer mt-2 me-5 img-fluid`}
            src={loginGif}
            height="432"
            width="432"
          />
        </div>
        {formState === "login" && (
          <form className={`${isMobile ? "col-12" : "col-6"} p-0 pt-5 "`}>
            <div className={`${isMobile ? "col-12" : "col-6"} m-auto pt-3`}>
              <h3 className="formHeading mb-4 mt-2">Login</h3>
              <label htmlFor="email" className="mb-2 pt-2">
                Email
              </label>
              <div
                className={`loginInputHld ${
                  loginError.userName ? `mb-0 errorInputField` : `mb-4`
                }`}
              >
                <img
                  className="img-fluid inputLogo"
                  src={loginData.userName ? activeEmail : email}
                />
                <input
                  name="userName"
                  value={loginData.userName}
                  onChange={handleChangeLogin}
                />
              </div>
              {loginError.userName && (
                <p className="errorText">Invalid Email</p>
              )}
              <label htmlFor="password" className="mb-2 pt-2">
                Password
              </label>
              <div
                className={`loginInputHld ${
                  loginError.password ? `mb-0 errorInputField` : `mb-4`
                }`}
              >
                <img
                  className="img-fluid inputLogo"
                  src={loginData.password ? activePassword : password}
                />
                <input
                  type={viewPassword ? "text" : "password"}
                  name="password"
                  value={loginData.password}
                  onChange={handleChangeLogin}
                />
                <img
                  className={`img-fluid ms-2 ${viewPassword ? `viewEye` : ``}`}
                  src={viewPassword ? showPassword : hidePassword}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              </div>
              {loginError.password && (
                <p className="errorText">Incorrect password*</p>
              )}
              {error && <p className="errorText mt-3">{error}</p>}
              <button className="submitButton mt-4" onClick={handleLogin}>
                Login
              </button>
              <div className="d-flex justify-content-between mt-4">
                <div className="checkInputHld d-flex mb-3 align-items-center">
                  <input
                    name="remmember"
                    type="checkbox"
                    className="styledCheckBox"
                  />
                  <label htmlFor="password">Remember me</label>
                </div>
                <p className="fontGray links" onClick={toggleForgotPassword}>
                  Forgot password
                </p>
              </div>
              <div className="loginSeprator align-items-center d-flex mt-5 mb-5">
                <p className="seprator me-2"></p>
                <span>OR</span>
                <p className="seprator ms-2"></p>
              </div>
              <p className="darkText text-center mt-4">
                Don’t have an account?
              </p>
              <button
                className="submitButton registerButton mt-3"
                onClick={handleRegisterClick}
              >
                Sign up for Hiring
              </button>
              <p className="mt-5 mb-4 text-center fontGray">
                For any queries &nbsp;
                <a className="fontGray" href="mailto:support@machinehack.com">
                  support@machinehack.com
                </a>
              </p>
            </div>
          </form>
        )}
        {formState === "resetPassword" && (
          <ResetPassword
            props={props}
            handleRegisterClick={handleRegisterClick}
          />
        )}
        {formState === "forgotPassword" && (
          <ForgotPassword setFormState={setFormState} />
        )}
      </div>
    </div>
  );
}
